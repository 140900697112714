const Affinities = {
    ELEMENTS: [
        'light',
        'earth',
        'gold',
        'water',
        'dark',
        'air',
        'fire',
    ],
    SPIRITS: [
        'tiger',
        'eagle',
        'bear',
        'walrus',
        'owl',
        'shark',
        'ox',
        'wolf',
        'lizard',
        'cat',
        'lion',
        'mammoth',
        'crab',
    ],
};

module.exports = Affinities;
