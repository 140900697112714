import produce from 'immer';

export const initialState = {
    socket: null,
};

export const ApiTypes = {
    SET_SOCKET: 'SET_SOCKET',
};

export const reducer = produce((state, { type, payload }) => {
    const fns = {
        [ApiTypes.SET_SOCKET]: () => {
            const { socket } = payload;
            state.socket = socket ?? null;
        },
    };

    const fn = fns[type];

    if (!fn) {
        throw new Error('unknown action type', type, payload);
    }

    fn();
});
