import React from 'react';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem } from '@mui/material';
import propTypes from 'prop-types';

export { MenuItem } from '@mui/material';

const PropTypes = {
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    value: propTypes.any.isRequired,
    onChange: propTypes.func.isRequired,
    label: propTypes.string,
    variant: propTypes.string,
    className: propTypes.any,
    showEmpty: propTypes.bool,
    error: propTypes.bool,
    children: propTypes.any,
    autoFocus: propTypes.bool,
};

const DefaultProps = {
    showEmpty: true,
    variant: 'outlined',
    error: false,
    autoFocus: false,
    className: '',
    children: null,
    label: '',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: { maxHeight: ITEM_HEIGHT * 4.85 + ITEM_PADDING_TOP },
    },
};

const Select = ({
    variant,
    showEmpty,
    name,
    className,
    label,
    id,
    value,
    onChange,
    error,
    children,
    autoFocus,
    wrapperSx,
    sx,
    ...otherProps
}) => {
    return (
        <FormControl className={className} variant="outlined" error={error} sx={wrapperSx}>
            {label ? <InputLabel id={`${id}-label`}>{label}</InputLabel> : null}

            <MuiSelect
                labelId={`${id}-label`}
                id={id}
                name={name}
                value={value ?? ''}
                onChange={onChange}
                label={label}
                variant={variant}
                MenuProps={MenuProps}
                autoFocus={autoFocus}
                sx={sx}
                {...otherProps}
            >
                {showEmpty ? (
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                ) : null}

                {children}
            </MuiSelect>
        </FormControl>
    );
};

Select.propTypes = PropTypes;
Select.defaultProps = DefaultProps;
export default Select;
