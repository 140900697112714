module.exports = {
    Affinities: require('./affinities'),
    Colors: require('./colors'),
    Items: require('./items'),
    ItemTypes: require('./itemTypes'),
    RackTypes: require('./rackTypes'),
    Translation: require('./translation'),
    Firestore: {
        MAX_BATCH_SIZE: 500, 
        Collections: {
            BaseItems: {
                NAME: 'baseItems',
            },
            Market: {
                NAME: 'market',
                MASTER_DOC: 'master',
                SubCollections: {
                    Records: { NAME: 'records' },
                },
            },
        },
    },
    Rarities: {
        COMMON: 'common',
        UNCOMMON: 'uncommon',
        FLAWLESS: 'flawless',
        EPIC: 'epic',
        LEGENDARY: 'legendary',
    },
    RarityMultipliers: {
        COMMON: 1,
        UNCOMMON: 1.27,
        FLAWLESS: 2,
        EPIC: 3.04,
        LEGENDARY: 5,
    },
    Pagination: {
        DEFAULT_PAGE_SIZE: 25,
        PAGE_SIZES: [
            5,
            10,
            25,
            50,
            100,
        ],
    }
};
