import React, { useRef, useEffect, useState } from 'react';
import { CircularProgress, styled } from '@mui/material';
import clsx from 'clsx';

const transitionTime = 350;

export const Spinner = ({
    on = false,
    size = 40,
    disableZIndex = false,
    className = undefined,
    slow = false,
    inline = false,
}) => {
    const [visible, setVisible] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        if (!on) {
            const timeout = setTimeout(() => {
                setVisible(false);
            }, transitionTime);

            return () => {
                clearTimeout(timeout);
            };
        }

        setVisible(true);

        return () => {};
    }, [on]);

    return (
        <Root
            ref={containerRef}
            className={clsx(className, 'ui-spinner')}
            disableZIndex={disableZIndex}
            inline={inline}
            on={on}
            visible={visible}
        >
            <CircularProgress
                size={size}
                disableShrink={slow}
                data-testid="spinner"
            />
        </Root>
    );
};

const Root = styled('div', {
    shouldForwardProp: prop =>
        !['disableZIndex', 'inline', 'on', 'visible'].includes(prop),
})(({ disableZIndex, inline, on, visible }) => ({
    '&': {
        transition: `opacity ease-in-out ${transitionTime}ms`,
        zIndex: disableZIndex ? null : 1300,
        position: inline ? 'relative' : 'absolute',
        display: inline ? 'inline-flex' : 'flex',
        opacity: on ? 1 : 0,
        visibility: visible ? 'visible' : 'hidden',
        justifyContent: !inline ? 'center' : null,
        alignItems: !inline ? 'center' : null,
        top: 0,
        left: 0,
        width: !inline ? '100%' : null,
        height: !inline ? '100%' : null,
        background: !inline ? 'rgba(255, 255, 255, 0.55)' : 'none', // TODO: theme
    },
}));
