import React, { useEffect, useState } from 'react';
import {
    Avatar,
    AppBar,
    Box,
    Drawer,
    Toolbar,
    Typography,
    Tooltip,
    IconButton,
    styled,
} from '@mui/material';
import { MediaQuery } from 'Components/mediaQuery';
import { useIsMobile } from 'App/theme';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

export const leftColumnWidth = 348;

const Wrapper = ({ open, onToggleOpen, children }) => {
    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <Drawer
                anchor="top"
                open={open}
                onClose={onToggleOpen}
                sx={{
                    zIndex: 1099,
                    '& .MuiPaper-root': {
                        pt: '85px',
                        pb: '32px',
                    },
                    '& .MuiBox-root': {
                        rowGap: '12px',
                    },
                    '& h5': {
                        fontSize: '18px',
                    },
                    '& h5:not(.active)': {
                        color: '#ccc',
                    },
                }}
            >
                {children}
            </Drawer>
        );
    }

    return children;
};

export const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (!isMobile) {
            setMenuOpen(false);
        }
    }, [isMobile]);

    const handleToggleMenu = () => {
        setMenuOpen(open => !open);
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                left: 0,
                width: '100%',
                backgroundColor: '#fff',
                borderBottom: '1px solid #ddd',
                boxShadow: '0px -2px 5px 1px #00000054',
                display: 'flex',
                flexDirection: 'row',
                maxWidth: '100vw',
                boxSizing: 'border-box',
            }}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexGrow: 1,
                    maxWidth: '100vw',
                    boxSizing: 'border-box',
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        columnGap: 2,
                    }}
                >
                    <MediaQuery not size="md">
                        <IconButton
                            onClick={handleToggleMenu}
                            color="neutral"
                            aria-label={`${menuOpen ? 'close' : 'open'} menu`}
                        >
                            {menuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
                        </IconButton>
                    </MediaQuery>

                    <Typography
                        variant="h6"
                        sx={{
                            color: '#1198f6',
                            fontFamily: 'montserrat',
                            fontWeight: 800,
                            fontSize: '17px',
                        }}
                    >
                        Shop Titans Toolkit
                    </Typography>
                </Box>

                <Wrapper open={menuOpen} onToggleOpen={handleToggleMenu}>
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            columnGap: 2,
                        }}
                    >
                        <NavItem className="active" active>
                            Market
                        </NavItem>

                        <Tooltip title="Coming soon">
                            <NavItem>Blueprints</NavItem>
                        </Tooltip>

                        <Tooltip title="Coming soon">
                            <NavItem>Crafting</NavItem>
                        </Tooltip>

                        <Tooltip title="Coming soon">
                            <NavItem>Guild</NavItem>
                        </Tooltip>
                    </Box>
                </Wrapper>

                <Box
                    sx={{
                        flex: isMobile ? 0 : 1,
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        columnGap: 2,
                    }}
                >
                    <StyledAvatar src="https://i.pravatar.cc/100" alt="avatar" />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

const NavItem = styled('h5', {
    shouldForwardProp: prop => prop !== 'active',
})(({ active, theme }) => ({
    color: active ? theme.palette.primary.main : '#999',
    fontSize: '14px',
    fontWeight: 600,
    margin: 0,
    padding: theme.spacing(1),
    cursor: 'pointer',
    transition: 'color 150ms ease-in-out',
    '&:hover': {
        color: active ? theme.palette.primary.dark : '#555',
    },
}));

const StyledAvatar = styled(Avatar)(() => ({
    cursor: 'pointer',
    boxShadow: 'none',
    transition: 'box-shadow 150ms ease-in-out',
    '&:hover': {
        boxShadow: '1px 1px 7px 0px rgba(0, 0, 0, 0.3)',
    },
}));
