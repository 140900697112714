import React, { useMemo } from 'react';
import { Global } from '@emotion/react';
import { IconButton, Box, Typography, SwipeableDrawer, styled } from '@mui/material';
import { BiChevronDown } from 'react-icons/bi';

const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor: '#fff',
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff',
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: '#ccc',
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

export const SwipeableEdgeDrawer = ({
    children,
    headerContent,
    container,
    title,
    open,
    onToggle,
}) => {
    const isSwipeable = useMemo(() => {
        return window.matchMedia('(pointer: coarse)').matches;
    }, []);

    return (
        <Root>
            <Global
                styles={{
                    '& #filters-mobile-drawer': {
                        zIndex: 1400,
                        '& > .MuiPaper-root': {
                            height: `calc(90% - ${drawerBleeding}px)`,
                            overflow: 'visible',
                        },
                    },
                }}
            />

            <SwipeableDrawer
                container={container.current}
                anchor="bottom"
                open={open}
                onClose={() => onToggle(false)}
                onOpen={() => onToggle(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                SwipeAreaProps={{}}
                PaperProps={{
                    style: !isSwipeable
                        ? {
                              visibility: 'unset',
                              pointerEvents: 'unset',
                          }
                        : {},
                }}
                ModalProps={{
                    keepMounted: true,
                    id: 'filters-mobile-drawer',
                }}
            >
                <StyledBox
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding + 2,
                        borderTopLeftRadius: 24,
                        borderTopRightRadius: 24,
                        border: '1px solid #ccc',
                        boxShadow: '-5px 2px 6px 4px #0000001f',
                        borderBottom: 'none',
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        columnGap: '24px',
                        paddingRight: 2,
                    }}
                >
                    <Puller />

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            // don't pass event to `onToggle` unless
                            // refactored in filters mobile header
                            onClick={() => onToggle()}
                            sx={{
                                color: '#ccc',
                                transform: `rotate(${open ? 0 : 180}deg)`,
                            }}
                        >
                            <BiChevronDown />
                        </IconButton>

                        <Typography
                            sx={{
                                p: 2,
                                pl: 0,
                                fontSize: '14px',
                                color: '#767676',
                                fontWeight: 600,
                            }}
                        >
                            {title}
                        </Typography>
                    </Box>

                    {headerContent}
                </StyledBox>

                <StyledBox
                    sx={{
                        px: 2,
                        pt: 2,
                        pb: 2,
                        height: '100%',
                        overflow: 'auto',
                        zIndex: 1,
                        borderTop: '1px solid #eee',
                    }}
                >
                    {children}
                </StyledBox>
            </SwipeableDrawer>
        </Root>
    );
};
