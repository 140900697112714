const { nanoid } = require('nanoid');

// let hits = 0;
// const log = (...args) => {
//     if (hits < 100) {
//         console.log(...args);
//         hits++;
//     }
// };

exports.generateId = () => {
    return nanoid();
};

exports.newObject = obj => {
    return JSON.parse(JSON.stringify(obj));
};

exports.genericSort = (a, b, order = 'desc') => {
    const { isUndefinedOrNull } = this; 
    const multiplier = order === 'desc' ? 1 : -1;

    if (isUndefinedOrNull(a) && isUndefinedOrNull(b))
        return 0;

    if (isUndefinedOrNull(b) || a < b)
        return -1 * multiplier;

    if (isUndefinedOrNull(a) || a > b)
        return 1 * multiplier;

    // a === b
    return 0;
};

/**
 * sorts an array of objects by a given property (dereferenced: the original array will remain unaltered)
 * @param {Object} params object syntax
 * @param {Object[]} params.items array of items to be sorted
 * @param {string} [params.sortBy] property to sortBy, or a callback function (item) => propertyValue
 * @param {Function} [params.sortBySelector] selector function (item) => sortByValue
 * @param {('asc'|'desc')} params.sortOrder direction of sort
 * @returns {Object[]} sorted array of items
 * @example
 * const items = [
 *   { name: 'first', nested: { property: 1 } },
 *   { name: 'second', nested: { property: 2 } },
 *   { name: 'third', nested: { property: 3 } },
 * ];
 * 
 * // basic usage
 * const sortedArray = sortArrayOfObjectsByProperty({
 *   sortBy: 'name',
 *   sortOrder: 'desc',
 *   items,
 * });
 * 
 * // sortBySelector
 * const sortedArray = sortArrayOfObjectsByProperty({
 *   sortBySelector: item => item.nested.property,
 *   sortOrder: 'desc',
 *   items,
 * });
 */
exports.sortArrayOfObjectsByProperty = ({ items, sortBy, sortBySelector, sortOrder }) => {
    if (!sortBy && !sortBySelector)
        throw new Error('sortArrayOfObjectsByProperty missing one of params `sortBy, sortBySelector`');

    const { genericSort, newObject } = this;
    const getValue = item => sortBySelector ? sortBySelector(item) : item[sortBy];

    return newObject(items).sort((a, b) => {
        return genericSort(
            getValue(a),
            getValue(b),
            sortOrder
        );
    });
};

/**
 * paginates a collection of data (dereferenced: original `items` array remains unaltered).
 * example usage for passing a limited number
 * of items back to the front end when the data source is saved a single document.
 * @param {Object} params object syntax
 * @param {any[]} params.items data to paginate
 * @param {number} params.pageIndex
 * @param {number} params.pageSize
 * @returns {any[]} paginated items
 */
exports.paginateData = ({ items, pageIndex, pageSize }) => {
    const start = pageIndex * pageSize;
    const end = start + pageSize;
    return items.slice(start, end);
};

exports.objectHasOwnProperty = (object, property) => {
    return Object.prototype.hasOwnProperty.call(object, property);
};

exports.isUndefinedOrNull = thing => {
    return thing === undefined || thing === null;
};

exports.dedupeData = (items, getId) => {
    let itemMap = {};

    items.forEach(item => {
        const id = getId(item);
        itemMap[id] = item;
    });

    return Object.values(itemMap);
};

exports.pipeFunctions = (...functions) => {
    return functions.reduce((previousValue, fn) => {
        return fn(previousValue);
    }, null);
};

/**
 * standard [].filter() callback
 * @callback filterDataFilter
 * @param {Object} item
 * @returns {boolean} filter result
 */

/**
 * efficiently filters an array of objects using multiple callback filters
 * @param {Object[]} items 
 * @param {FilterDataFilter[]} filters
 * @returns {Object[]} filtered items
 */
exports.filterData = (items, filters) => {
    return items.filter(item => {
        return filters.every(filter => filter(item));
    });
};

exports.capitalize = string => {
    if (!string || typeof string !== typeof '') {
        return '';
    }

    if (string.length === 1) {
        return `${string}`.toUpperCase();
    }

    const firstCharacter = `${string}`.charAt(0).toUpperCase();
    const rest = `${string}`.slice(1);

    return `${firstCharacter}${rest}`;
};

exports.makeMapFromArray = (array, makeKey) => {
    return array.reduce((out, item) => {
        const key = makeKey(item);
        out[key] = item;

        return out;
    }, {});
};
