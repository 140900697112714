import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'Contexts/app';
import moment from 'moment';

export const LastUpdated = () => {
    const lastUpdated = useAppSelector(state => state.lastUpdated);
    const loading = useAppSelector(state => state.loading);
    const [verbiage, setVerbiage] = useState('Loading...');

    useEffect(() => {
        if (loading || !lastUpdated) {
            setVerbiage('Loading...');
            return () => {};
        }

        const update = () => {
            setVerbiage(`Updated ${moment(lastUpdated).fromNow()}`);
        };

        const intervalId = setInterval(update, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [loading, lastUpdated]);

    return <p style={{ margin: 0, fontSize: '13px' }}>{verbiage}</p>;
};
