import React, { useMemo } from 'react';
import { TextField, Autocomplete, Paper, styled } from '@mui/material';
import { newObject } from 'Utils/helpers';
import propTypes from 'prop-types';
import ListBoxComponent from './listBoxComponent';
import Option from './option';
import SelectAllOption from './selectAllOption';
import Tag from './tag';

const PropTypes = {
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    label: propTypes.string.isRequired,
    options: propTypes.array.isRequired,
    onChange: propTypes.func.isRequired,
    defaultValue: propTypes.array,
    value: propTypes.array,
    className: propTypes.any,
    virtualized: propTypes.bool,
    error: propTypes.bool,
    autoFocus: propTypes.bool,
    disabled: propTypes.bool,
    getOptionSelected: propTypes.func,
    sx: propTypes.object,
    size: propTypes.oneOf(['small', 'medium']),
    'data-testid': propTypes.string,
};

const DefaultProps = {
    defaultValue: [],
    className: '',
    virtualized: false,
    error: false,
    autoFocus: false,
    value: null,
    disabled: false,
    getOptionSelected: undefined,
    sx: null,
    size: null,
    'data-testid': null,
};

const MultiSelect = ({
    id,
    name,
    label,
    defaultValue,
    options,
    className,
    value,
    onChange,
    virtualized,
    error,
    disabled,
    autoFocus,
    getOptionSelected,
    sx,
    size,
    'data-testid': dataTestId,
}) => {
    const handleDelete = id => {
        const index = value.indexOf(id);
        if (index === -1) {
            return;
        }

        const newValue = [
            ...value.slice(0, index),
            ...value.slice(index + 1, value.length),
        ];

        onChange({}, newValue);
    };

    const derivedOptions = useMemo(() => {
        const selectAllOption = { name: 'Select all', value: 'select-all' };
        return [selectAllOption, ...options];
    }, [options]);

    const handleChange = (event, rawSelected) => {
        const isSelectAll = event.currentTarget.dataset.isselectall === 'true';
        const previouslyHadSelectAll = value.includes('select-all');
        const areAllValuesSelected = value.length === derivedOptions.length;

        if (isSelectAll && previouslyHadSelectAll && areAllValuesSelected) {
            onChange(event, []);
            return;
        }

        if (isSelectAll && !areAllValuesSelected) {
            onChange(event, newObject(derivedOptions));
            return;
        }

        onChange(event, rawSelected);
    };

    return (
        <StyledAutocomplete
            multiple
            disableCloseOnSelect
            data-testid={dataTestId}
            isOptionEqualToValue={getOptionSelected}
            className={className}
            sx={sx}
            id={id}
            name={name}
            getOptionLabel={option => option.name}
            defaultValue={defaultValue}
            options={derivedOptions}
            value={value}
            disablePortal
            disabled={disabled}
            ListboxComponent={virtualized ? ListBoxComponent : 'ul'}
            onChange={handleChange}
            PaperComponent={StyledPaper}
            renderTags={(selected, getProps) => {
                return selected.map((tag, index) => {
                    const option = derivedOptions.find(option => {
                        return getOptionSelected(option, tag);
                    });

                    if (!option || option.value === 'select-all') {
                        return null;
                    }

                    return (
                        <Tag
                            {...getProps(index)}
                            key={option.value}
                            name={option.name}
                            value={option.value}
                            onDelete={() => handleDelete(option.value)}
                        />
                    );
                });
            }}
            renderOption={(props, option, { selected }) => (
                <StyledLi
                    data-isSelectAll={option.value === 'select-all'}
                    size={size}
                    {...props}
                >
                    {option.value === 'select-all' ? (
                        <SelectAllOption
                            selected={selected}
                            value={value}
                            options={options}
                            name={option.name}
                        />
                    ) : (
                        <Option selected={selected} name={option.name} />
                    )}
                </StyledLi>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    error={error}
                    autoFocus={autoFocus}
                    size={size}
                />
            )}
        />
    );
};

const StyledLi = styled('li', {
    shouldForwardProp: prop => prop !== 'size',
})(({ size }) => ({
    '&.MuiAutocomplete-option': {
        paddingTop: size === 'small' ? 0 : null,
        paddingBottom: size === 'small' ? 0 : null,
    },

    '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: '#f7f7f7',
    },

    '&.MuiAutocomplete-option.Mui-focused': {
        backgroundColor: '#f5f5f5',
    },

    '&.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
        backgroundColor: '#f5f5f5',
    },
}));

const StyledAutocomplete = styled(Autocomplete)(() => ({
    '& .MuiAutocomplete-endAdornment': {
        color: '#aaa',
        '& .MuiAutocomplete-clearIndicatorDirty': {
            visibility: 'visible',
            color: '#ccc',
            '&:hover': {
                color: '#aaa',
            },
        },
    },
}));

const StyledPaper = styled(Paper)(() => ({
    boxShadow: '3px 3px 7px 0px rgba(0, 0, 0, 0.1)',
}));

MultiSelect.propTypes = PropTypes;
MultiSelect.defaultProps = DefaultProps;
export default MultiSelect;
