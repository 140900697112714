import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getEnv } from 'Utils/helpers';

let isFirebaseInitialized = false;

export default () => {
    if (isFirebaseInitialized) {
        return;
    }

    const firebaseConfig = {
        apiKey: getEnv('FIREBASE_API_KEY'),
        authDomain: getEnv('FIREBASE_AUTH_DOMAIN'),
        projectId: getEnv('FIREBASE_PROJECT_ID'),
        storageBucket: getEnv('FIREBASE_STORAGE_BUCKET'),
        messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID'),
        appId: getEnv('FIREBASE_APP_ID'),
        measurementId: getEnv('FIREBASE_MEASUREMENT_ID'),
    };

    // eslint-disable-next-line import/prefer-default-export
    const app = initializeApp(firebaseConfig);

    const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(getEnv('FIREBASE_RECAPTCHA_KEY') ?? ''),
        isTokenAutoRefreshEnabled: true,
    });

    const db = getFirestore(app);
    const rawLocalFirestorePort = getEnv('FIREBASE_LOCAL_FIRESTORE_PORT') ?? '9098';
    const localFirestorePort = Number.parseInt(rawLocalFirestorePort, 10);
    connectFirestoreEmulator(db, 'localhost', localFirestorePort);

    const functions = getFunctions(app);
    const rawLocalFunctionsPort = getEnv('FIREBASE_LOCAL_FUNCTIONS_PORT') ?? '5001';
    const localFunctionsPort = Number.parseInt(rawLocalFunctionsPort, 10);
    connectFunctionsEmulator(functions, 'localhost', localFunctionsPort);

    isFirebaseInitialized = true;
};
