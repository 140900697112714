import React, { useMemo } from 'react';
import numeral from 'numeral';
import { styled } from '@mui/material';
import { CurrencyImage } from 'Components/currencyImage';

export const CurrencyValue = ({ type = 'gold', amount = 0, format = '0,0' }) => {
    const formattedAmount = useMemo(() => {
        if (!amount) {
            return '-';
        }

        return numeral(amount).format(format);
    }, [amount, format]);

    return (
        <Root>
            <CurrencyImage type={type} />
            <span>{formattedAmount}</span>
        </Root>
    );
};

const Root = styled('div')(({ theme }) => ({
    '&': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '4px 16px',
        borderRadius: '24px',
        backgroundColor: '#eee',
    },

    '& > .currency-image': {
        marginRight: theme.spacing(1),
        height: 18,
        '&.currency-image-double': {
            width: 23,
        },
    },

    '& > span': {
        fontSize: '14px',
    },
}));
