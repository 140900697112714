import { useState, useEffect, useMemo } from 'react';
import { AppTypes, useAppDispatch, useAppSelector } from 'Contexts/app';
import { Layouts } from 'Views/dashboard/constants';
import { Columns } from './columns';

export const useLayout = () => {
    const activeLayoutId = useAppSelector(state => state.activeLayoutId);
    const dispatch = useAppDispatch();
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const layout = Layouts.find(({ id }) => id === activeLayoutId);
        if (!layout) {
            return;
        }

        let isMounted = true;
        setLoading(true);

        setTimeout(() => {
            if (!isMounted) {
                return;
            }

            if (layout.defaultFilterState) {
                dispatch({
                    type: AppTypes.LOAD_FILTER_STATE,
                    payload: { filterState: layout.defaultFilterState },
                });
            }

            // prettier-ignore
            const nextColumns = layout.columns
                .map(columnId => Columns[columnId])
                .filter(column => !!column);

            setColumns(nextColumns);
            setLoading(false);
        }, 100);

        // eslint-disable-next-line consistent-return
        return () => {
            isMounted = false;
        };
    }, [dispatch, activeLayoutId]);

    const response = useMemo(
        () => ({
            columns,
            loading,
        }),
        [columns, loading]
    );

    return response;
};
