import React from 'react';
import { Checkbox, TextField, styled } from '@mui/material';

export const NumberRangeWithToggle = ({
    on,
    minValue,
    maxValue,
    onChange,
    minLabel = 'Minimum',
    maxLabel = 'Maximum',
    disableMargin = false,
    allowFloatingPoint = false,
}) => {
    const handleCheckboxChange = event => {
        const { checked } = event.target;
        onChange({ on: checked, min: minValue, max: maxValue });
    };

    const handleMinValueChange = event => {
        const value = allowFloatingPoint
            ? Number.parseFloat(event.target.value, 10)
            : Number.parseInt(event.target.value, 10);

        onChange({ on, min: value, max: maxValue });
    };

    const handleMaxValueChange = event => {
        const value = allowFloatingPoint
            ? Number.parseFloat(event.target.value, 10)
            : Number.parseInt(event.target.value, 10);

        onChange({ on, min: minValue, max: value });
    };

    return (
        <Root disableMargin={disableMargin}>
            <Checkbox checked={on} onChange={handleCheckboxChange} />

            <TextField
                label={minLabel}
                value={minValue}
                onChange={handleMinValueChange}
                type="number"
                size="small"
            />

            <TextField
                label={maxLabel}
                value={maxValue}
                onChange={handleMaxValueChange}
                type="number"
                size="small"
            />
        </Root>
    );
};

const Root = styled('div', {
    shouldForwardProp: prop => prop !== 'disableMargin',
})(({ disableMargin, theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: disableMargin ? null : theme.spacing(-2),

    '& > *': {
        flexGrow: 1,
    },

    '& .MuiCheckbox-root': {
        flexGrow: 0,
        padding: 8,
    },

    '& > .MuiTextField-root:first-of-type .MuiInputBase-root': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },

    '& > .MuiTextField-root:first-of-type .MuiOutlinedInput-notchedOutline': {
        borderRight: 'none',
    },

    '& > .MuiTextField-root:last-of-type .MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
}));
