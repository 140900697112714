import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import HolyElement from 'Assets/game/items/holy.png';
import WildElement from 'Assets/game/items/wild.png';
import FlamingElement from 'Assets/game/items/flaming.png';
import GaleElement from 'Assets/game/items/gale.png';
import TidalElement from 'Assets/game/items/tidal.png';
import UnholyElement from 'Assets/game/items/unholy.png';
import LuxuriousElement from 'Assets/game/items/luxuriouselement.png';
import ArmadilloSpirit from 'Assets/game/items/armadillo.png';
import LizardSpirit from 'Assets/game/items/lizard.png';
import HippoSpirit from 'Assets/game/items/hippo.png';
import CrabSpirit from 'Assets/game/items/crab.png';
import OwlSpirit from 'Assets/game/items/owl.png';
import RhinoSpirit from 'Assets/game/items/rhino.png';
import NoImage from 'Assets/images/nophoto.png';

export const Enchant = ({ type, sx }) => {
    const image = useMemo(() => {
        switch (type) {
            case 'light':
                return HolyElement;
            case 'earth':
                return WildElement;
            case 'fire':
                return FlamingElement;
            case 'air':
                return GaleElement;
            case 'water':
                return TidalElement;
            case 'dark':
                return UnholyElement;
            case 'gold':
                return LuxuriousElement;
            case 'armadillo':
                return ArmadilloSpirit;
            case 'lizard':
                return LizardSpirit;
            case 'hippo':
                return HippoSpirit;
            case 'crab':
                return CrabSpirit;
            case 'owl':
                return OwlSpirit;
            case 'rhino':
                return RhinoSpirit;
            default:
                console.log('enchant type not found', type);
                return NoImage;
        }
    }, [type]);

    if (type === undefined) {
        return null;
    }

    return (
        <Box sx={sx}>
            <img src={image} alt={type} />
        </Box>
    );
};
