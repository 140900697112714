import { objectHasOwnProperty } from '@app/utils';
import produce from 'immer';

const initialFiltersState = {
    // General
    tiers: [],
    rarities: [],

    // Price
    goldCostRange: [0, 10_000_000],
    useGoldCostRange: false,
    gemCostRange: [0, 500],
    useGemCostRange: false,
    gemGoldRatioRange: [0, 10],
    useGemGoldRatioRange: false,

    // Surcharge
    minSurchargeProfit: 0,
    useMinSurchargeProfit: false,
    maxSurchargeCost: 0,
    useMaxSurchargeCost: false,
    minSurchargeProfitPerEnergy: 0,
    useMinSurchargeProfitPerEnergy: false,

    // Discount
    minDiscountEnergy: 0,
    useMinDiscountEnergy: false,
    maxDiscountCostPerEnergy: 0,
    useMaxDiscountCostPerEnergy: false,

    // Affinities
    elementAffinities: [],
    spiritAffinities: [],
};

export const initialState = {
    activeLayoutId: 'default-layout-general',
    lastUpdated: null,
    items: [],
    totalCount: 0,
    loading: false,

    pageSize: 25,
    pageIndex: 0,
    sortModel: [{ field: 'goldPrice', sort: 'desc' }],
    ...initialFiltersState,
};

export const AppTypes = {
    SET_ACTIVE_LAYOUT: 'SET_ACTIVE_LAYOUT',

    SET_LAST_UPDATED: 'SET_LAST_UPDATED',
    SET_ITEMS: 'SET_ITEMS',
    SET_LOADING: 'SET_LOADING',

    SET_PAGE_INDEX: 'SET_PAGE_INDEX',
    SET_PAGE_SIZE: 'SET_PAGE_SIZE',
    SET_SORT_MODEL: 'SET_SORT_MODEL',

    LOAD_FILTER_STATE: 'LOAD_FILTER_STATE',
    RESET_FILTERS: 'RESET_FILTERS',

    SET_TIERS: 'SET_TIERS',
    SET_RARITIES: 'SET_RARITIES',

    SET_ITEM_TYPES: 'SET_ITEM_TYPES',
    SET_RACK_TYPES: 'SET_RACK_TYPES',

    SET_GOLD_COST_RANGE: 'SET_GOLD_COST_RANGE',
    SET_GEM_COST_RANGE: 'SET_GEM_COST_RANGE',
    SET_GEM_GOLD_RATIO_RANGE: 'SET_GEM_GOLD_RATIO_RANGE',

    SET_MIN_SURCHARGE_PROFIT: 'SET_MIN_SURCHARGE_PROFIT',
    SET_MAX_SURCHARGE_COST: 'SET_MAX_SURCHARGE_COST',
    SET_MIN_SURCHARGE_PROFIT_PER_ENERGY: 'SET_MIN_SURCHARGE_PROFIT_PER_ENERGY',

    SET_MIN_DISCOUNT_ENERGY: 'SET_MIN_DISCOUNT_ENERGY',
    SET_MAX_DISCOUNT_COST_PER_ENERGY: 'SET_MAX_DISCOUNT_COST_PER_ENERGY',

    SET_ELEMENT_AFFINITIES: 'SET_ELEMENT_AFFINITIES',
    SET_SPIRIT_AFFINITIES: 'SET_SPIRIT_AFFINITIES',
};

export const reducer = produce((state, { type, payload }) => {
    const fns = {
        [AppTypes.SET_ACTIVE_LAYOUT]: () => {
            const { activeLayoutId } = payload;
            state.activeLayoutId = activeLayoutId ?? [];
        },

        [AppTypes.SET_LAST_UPDATED]: () => {
            const { lastUpdated } = payload;
            state.lastUpdated = lastUpdated ?? null;
        },

        [AppTypes.SET_ITEMS]: () => {
            const { items, totalCount } = payload;
            state.items = items ?? [];
            state.totalCount = totalCount ?? 0;
        },

        [AppTypes.SET_PAGE_SIZE]: () => {
            const { pageSize } = payload;
            state.pageSize = pageSize ?? false;
        },

        [AppTypes.SET_PAGE_INDEX]: () => {
            const { pageIndex } = payload;
            state.pageIndex = pageIndex ?? false;
        },

        [AppTypes.SET_SORT_MODEL]: () => {
            const { sortModel } = payload;
            state.sortModel = sortModel ?? false;
        },

        [AppTypes.SET_LOADING]: () => {
            const { loading } = payload;
            state.loading = loading ?? false;
        },

        [AppTypes.SET_GOLD_COST_RANGE]: () => {
            const { goldCostRange, useGoldCostRange } = payload;
            state.goldCostRange = goldCostRange ?? [0, 10_000_000];
            state.useGoldCostRange = useGoldCostRange ?? false;
        },

        [AppTypes.SET_GEM_COST_RANGE]: () => {
            const { gemCostRange, useGemCostRange } = payload;
            state.gemCostRange = gemCostRange ?? [0, 500];
            state.useGemCostRange = useGemCostRange ?? false;
        },

        [AppTypes.SET_GEM_GOLD_RATIO_RANGE]: () => {
            const { gemGoldRatioRange, useGemGoldRatioRange } = payload;
            state.gemGoldRatioRange = gemGoldRatioRange ?? [0, 10];
            state.useGemGoldRatioRange = useGemGoldRatioRange ?? false;
        },

        [AppTypes.SET_TIERS]: () => {
            const { tiers } = payload;
            state.tiers = tiers ?? [];
        },

        [AppTypes.SET_RARITIES]: () => {
            const { rarities } = payload;
            state.rarities = rarities ?? [];
        },

        [AppTypes.SET_RARITIES]: () => {
            const { rarities } = payload;
            state.rarities = rarities ?? [];
        },

        [AppTypes.SET_ITEM_TYPES]: () => {
            const { itemTypes } = payload;
            state.itemTypes = itemTypes ?? [];
        },

        [AppTypes.SET_RACK_TYPES]: () => {
            const { rackTypes } = payload;
            state.rackTypes = rackTypes ?? [];
        },

        [AppTypes.SET_MIN_SURCHARGE_PROFIT]: () => {
            const { minSurchargeProfit, useMinSurchargeProfit } = payload;
            state.minSurchargeProfit = minSurchargeProfit ?? 0;
            state.useMinSurchargeProfit = useMinSurchargeProfit ?? false;
        },

        [AppTypes.SET_MAX_SURCHARGE_COST]: () => {
            const { maxSurchargeCost, useMaxSurchargeCost } = payload;
            state.maxSurchargeCost = maxSurchargeCost ?? 0;
            state.useMaxSurchargeCost = useMaxSurchargeCost ?? false;
        },

        [AppTypes.SET_MIN_SURCHARGE_PROFIT_PER_ENERGY]: () => {
            const { minSurchargeProfitPerEnergy, useMinSurchargeProfitPerEnergy } = payload;
            state.minSurchargeProfitPerEnergy = minSurchargeProfitPerEnergy ?? 0;
            state.useMinSurchargeProfitPerEnergy = useMinSurchargeProfitPerEnergy ?? false;
        },

        [AppTypes.SET_MIN_DISCOUNT_ENERGY]: () => {
            const { minDiscountEnergy, useMinDiscountEnergy } = payload;
            console.log('payload =>', payload);
            state.minDiscountEnergy = minDiscountEnergy ?? 0;
            state.useMinDiscountEnergy = useMinDiscountEnergy ?? false;

            console.log('state.minDiscountEnergy =>', state.minDiscountEnergy);
        },

        [AppTypes.SET_MAX_DISCOUNT_COST_PER_ENERGY]: () => {
            const { maxDiscountCostPerEnergy, useMaxDiscountCostPerEnergy } = payload;
            state.maxDiscountCostPerEnergy = maxDiscountCostPerEnergy ?? 0;
            state.useMaxDiscountCostPerEnergy = useMaxDiscountCostPerEnergy ?? false;
        },

        [AppTypes.SET_ELEMENT_AFFINITIES]: () => {
            const { elementAffinities } = payload;
            state.elementAffinities = elementAffinities ?? [];
        },

        [AppTypes.SET_SPIRIT_AFFINITIES]: () => {
            const { spiritAffinities } = payload;
            state.spiritAffinities = spiritAffinities ?? [];
        },

        [AppTypes.RESET_FILTERS]: () => {
            console.log('RESET_FILTERS');
            return {
                ...state,
                ...initialFiltersState,
            };
        },

        [AppTypes.LOAD_FILTER_STATE]: () => {
            const { filterState } = payload;
            console.log('LOAD_FILTER_STATE filterState', filterState);

            if (!filterState) {
                return;
            }

            const isValid = Object.keys(filterState).every(key => {
                return objectHasOwnProperty(state, key);
            });

            if (!isValid) {
                return;
            }

            // eslint-disable-next-line consistent-return
            return {
                ...state,
                ...initialFiltersState,
                ...filterState,
            };
        },
    };

    const fn = fns[type];

    if (!fn) {
        throw new Error('unknown action type', type, payload);
    }

    return fn();
});
