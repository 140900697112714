import React from 'react';
import { AppTypes, useAppSelector, useAppDispatch } from 'Contexts/app';
import { styled } from '@mui/material';
import { NumberRangeWithToggle } from 'Components/inputs';
import { CurrencyTitle } from 'Components/currencyTitle';
import { FilterWrapper } from '../filterWrapper';

export const MarketPriceFilter = () => {
    const goldCostRange = useAppSelector(state => state.goldCostRange);
    const useGoldCostRange = useAppSelector(state => state.useGoldCostRange);
    const gemCostRange = useAppSelector(state => state.gemCostRange);
    const useGemCostRange = useAppSelector(state => state.useGemCostRange);
    const gemGoldRatioRange = useAppSelector(state => state.gemGoldRatioRange);
    const useGemGoldRatioRange = useAppSelector(state => state.useGemGoldRatioRange);
    const dispatch = useAppDispatch();

    const handleGoldCostChange = ({ on, min, max }) => {
        dispatch({
            type: AppTypes.SET_GOLD_COST_RANGE,
            payload: {
                useGoldCostRange: on,
                goldCostRange: [min, max],
            },
        });
    };

    const handleGemCostChange = ({ on, min, max }) => {
        dispatch({
            type: AppTypes.SET_GEM_COST_RANGE,
            payload: {
                useGemCostRange: on,
                gemCostRange: [min, max],
            },
        });
    };

    const handleGemGoldRatioChange = ({ on, min, max }) => {
        dispatch({
            type: AppTypes.SET_GEM_GOLD_RATIO_RANGE,
            payload: {
                useGemGoldRatioRange: on,
                gemGoldRatioRange: [min, max],
            },
        });
    };

    return (
        <StyledFilterWrapper id="price" title="Price">
            {/* <CurrencyTitle
                title="Gold price"
                image={GoldImage}
            /> */}

            <NumberRangeWithToggle
                minValue={goldCostRange[0]}
                maxValue={goldCostRange[1]}
                onChange={handleGoldCostChange}
                on={useGoldCostRange}
                minLabel={<CurrencyTitle title="Minimum" type="gold" disableTitleStyles />}
                maxLabel={<CurrencyTitle title="Maximum" type="gold" disableTitleStyles />}
            />

            {/* <CurrencyTitle
                title="Gem price"
                image={GemImage}
            /> */}

            <NumberRangeWithToggle
                minValue={gemCostRange[0]}
                maxValue={gemCostRange[1]}
                onChange={handleGemCostChange}
                on={useGemCostRange}
                minLabel={<CurrencyTitle title="Minimum" type="gem" disableTitleStyles />}
                maxLabel={<CurrencyTitle title="Maximum" type="gem" disableTitleStyles />}
            />

            <NumberRangeWithToggle
                minValue={gemGoldRatioRange[0]}
                maxValue={gemGoldRatioRange[1]}
                onChange={handleGemGoldRatioChange}
                on={useGemGoldRatioRange}
                minLabel={
                    <CurrencyTitle
                        title="Minimum ratio"
                        type="gems-per-gold"
                        disableTitleStyles
                    />
                }
                maxLabel={
                    <CurrencyTitle
                        title="Maximum ratio"
                        type="gems-per-gold"
                        disableTitleStyles
                    />
                }
                allowFloatingPoint
            />
        </StyledFilterWrapper>
    );
};

const StyledFilterWrapper = styled(FilterWrapper)(() => ({
    '& .MuiAccordionDetails-root > .MuiBox-root': {
        paddingLeft: 4,
        // paddingTop: 0,
    },
}));
