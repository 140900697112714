import React from 'react';
import GoldImage from 'Assets/game/currencies/gold.png';
import GemImage from 'Assets/game/currencies/gem.png';
import EnergyImage from 'Assets/game/currencies/energy.png';
import { styled } from '@mui/material';

export const CurrencyImage = ({ type }) => {
    if (type === 'gem') {
        return (
            <StyledImg src={GemImage} alt={type} className="currency-image currency-gem" />
        );
    }

    if (type === 'energy') {
        return (
            <StyledImg
                src={EnergyImage}
                alt={type}
                className="currency-image currency-energy"
            />
        );
    }

    if (type === 'gold-per-energy') {
        return <GoldPerEnergyImage />;
    }

    if (type === 'energy-per-gold') {
        return <EnergyPerGoldImage />;
    }

    if (type === 'gems-per-gold') {
        return <GemsPerGoldImage />;
    }

    return (
        <StyledImg src={GoldImage} alt={type} className="currency-image currency-gold" />
    );
};

const StyledImg = styled('img')(() => ({
    imageRendering: '-webkit-optimize-contrast',
}));

const GoldPerEnergyImage = () => (
    <DoubleImageRoot className="currency-image currency-image-double currency-gold-per-energy">
        <CurrencyImage type="gold" />
        <CurrencyImage type="energy" />
    </DoubleImageRoot>
);

const EnergyPerGoldImage = () => (
    <DoubleImageRoot className="currency-image currency-image-double currency-energy-per-gold">
        <CurrencyImage type="energy" />
        <CurrencyImage type="gold" />
    </DoubleImageRoot>
);

const GemsPerGoldImage = () => (
    <DoubleImageRoot className="currency-image currency-image-double currency-gems-per-gold">
        <CurrencyImage type="gem" />
        <CurrencyImage type="gold" />
    </DoubleImageRoot>
);

const DoubleImageRoot = styled('div')(() => ({
    position: 'relative',
    // maxHeight: '100%',
    // maxWidth: '100%',
    width: '100%',
    height: '100%',

    '& > .currency-image:first-of-type': {
        position: 'absolute',
        top: -1,
        left: 0,
        width: '60%',
        zIndex: 1,
    },

    '& > .currency-image:last-of-type': {
        position: 'absolute',
        bottom: -1,
        right: '10%',
        width: '50%',
    },

    '&.currency-gems-per-gold': {
        '& *:first-child': {
            width: '70%',
        },

        '& *:last-child': {
            width: '55%',
        },
    },

    // '& > .gold-per-energy-image-divider': {
    //     position: 'absolute',
    //     inset: 0,
    //     top: '40%',
    //     width: '90%',
    //     height: 2,
    //     backgroundColor: '#0202023b',
    //     transform: 'rotate(-45deg)',
    // },
}));
