const Colors = {
    Rarities: {
        COMMON: '#eee',
        UNCOMMON: '#61ee5d',
        FLAWLESS: '#5ed4ff',
        EPIC: '#dd86fc',
        LEGENDARY: '#ffe37a',
    },
};

module.exports = Colors;
