module.exports = {
    missing_characters: '[]_…’«»œŒÀ;&£@€₹',
    item_type_accessory: 'Accessory',
    item_type_amulet: 'Amulet',
    item_type_armor: 'Armor',
    item_type_armorheavy: 'Heavy Armor',
    item_type_armorlight: 'Clothes',
    item_type_armormedium: 'Light Armor',
    item_type_axe: 'Axe',
    item_type_boots: 'Heavy Footwear',
    item_type_bow: 'Bow',
    item_type_cloak: 'Cloak',
    item_type_dagger: 'Dagger',
    item_type_familiar: 'Familiar',
    item_type_feet: 'Footgear',
    item_type_bracers: 'Gloves',
    item_type_gauntlets: 'Gauntlets',
    item_type_hands: 'Handwear',
    item_type_hat: 'Magician Hat',
    item_type_roguehat: 'Rogue Hat',
    item_type_head: 'Headgear',
    item_type_helmet: 'Helmet',
    item_type_herb: 'Herbal Medicine',
    item_type_mace: 'Mace',
    item_type_potion: 'Potion',
    item_type_ring: 'Ring',
    item_type_scrolls: 'Spell',
    item_type_shield: 'Shield',
    item_type_shoes: 'Light Footwear',
    item_type_spear: 'Spear',
    item_type_staff: 'Staff',
    item_type_sword: 'Sword',
    item_type_wand: 'Wand',
    item_type_crossbow: 'Crossbow',
    item_type_gun: 'Gun',
    item_type_tag: 'Enchantment',
    item_type_rune: 'Runestone',
    item_type_weapon: 'Weapon',
    item_type_moonstone: 'Moonstone',
    item_type_stone: 'Stones',
    equipment: 'Equipment',
    consumable: 'Consumable',
    decoration: 'Decoration',
    customization: 'Customize',
    resource_iron: 'Iron',
    resource_iron_desc: 'Refined iron ready to be forged.',
    resource_wood: 'Wood',
    resource_wood_desc: 'Quality wood begging to be sculpted.',
    resource_leather: 'Leather',
    resource_leather_desc: 'Supple leather, certified Tamas quality.',
    resource_herbs: 'Herbs',
    resource_herbs_desc: 'Fresh herbs meant for brewing.',
    resource_steel: 'Steel',
    resource_steel_desc: 'Refined steel devoid of any impurities.',
    resource_ironwood: 'Ironwood',
    resource_ironwood_desc: 'Mysterious wood as sturdy as metal.',
    resource_fabric: 'Fabric',
    resource_fabric_desc: 'Soft, luxury fabric woven by expert hands.',
    resource_oils: 'Oil',
    resource_oils_desc: 'Thick oils with a hint of magic.',
    resource_mana: 'Ether',
    resource_mana_desc:
        'Raw magic essence carefully extracted from leylines.',
    resource_gems: 'Jewels',
    resource_gems_desc: 'Wonderful and extremely expensive jewels.',
    adventurer: 'Hero',
    hero: 'Champion',
    base_power: 'Base power',
    critical_format: 'Critical Hit Chance: {0}',
    critical_damage_format: 'Critical Damage: {0}x',
    aggro_format: 'Threat Rating: {0}',
    aggro: 'Threat',
    attack_short: 'ATK',
    attack: 'Attack',
    stat_attack_desc:
        'Overall offensive ability. Deals damage to monster HP.',
    attack_format: 'Attack: {0}',
    defense_short: 'DEF',
    defense: 'Defense',
    crit_bonus: 'Critical Hit Chance',
    crit_mult_bonus: 'Critical Hit Damage',
    stat_defense_desc:
        'Overall armor rating. A high defense will reduce damage taken.',
    defense_format: 'Defense: {0}',
    power: 'Power',
    stat_power_desc:
        "A summary of a Hero's overall strength. Quests have a minimum Power requirement.",
    power_format: 'Power: {0}',
    evasion_short: 'EVA',
    evasion: 'Evasion',
    stat_evasion_desc:
        "Ability to evade harm and dodge attacks. It can't go higher than {0}.",
    critical: 'Critical',
    workers: 'Workers',
    health_short: 'HP',
    health: 'Health',
    stat_health_desc:
        'Ability to sustain damage. Heroes are injured at 0 HP.',
    city: 'City',
    element: 'Element',
    furniture: 'Furniture',
    blueprint: 'Blueprint',
    blueprints: 'Blueprints',
    gems: 'Gems',
    gender_female: 'Female',
    gender_male: 'Male',
    gold: 'Gold',
    heroes: 'Chars.',
    hero_options: 'Hero Options',
    retire: 'Retire',
    rename: 'Rename',
    character_type_adventurer: 'Heroes',
    character_type_hero: 'Champions',
    character_type_worker: 'Workers',
    free: 'Free',
    quest_damage_format: '-{0}HP',
    quest_xp_format: '{0} Clears',
    bulk_requirement: 'Requirements',
    fulfill: 'Fulfill',
    blueprint_min_quality: 'Base quality',
    quality_epic: 'Epic',
    quality_flawless: 'Flawless',
    quality_legendary: 'Legendary',
    quality_common: 'Normal',
    quality_uncommon: 'Superior',
    epic_name: 'Epic',
    flawless_name: 'Flawless',
    legendary_name: 'Legendary',
    common_name: 'Normal',
    uncommon_name: 'Superior',
    quest: 'Quest',
    quest_result_miss: 'Miss',
    missing_chest: "You don't have any chests.",
    proficiencies: 'Can use',
    special_item_type_adventurer: 'Consumable',
    special_item_type_quest: 'Quest Booster',
    special_item_type_quester: 'Quest Item',
    special_item_type_chest: 'Chest',
    special_item_type_key: 'Key',
    special_item_type_roster: 'Roster',
    special_item_type_bpunlock: 'Blueprint Research',
    special_item_type_bpupgrade: 'Ascension Upgrade',
    special_item_type_jackslot: 'Fortune Zone',
    blueprint_shard: 'Blueprint Fragment',
    sell: 'Sell',
    buy: 'Buy',
    available: 'Available',
    vibrations: 'Vibration',
    collect_all: 'Collect All',
    team: 'Team',
    boosts: 'Boost',
    jackslot: 'Fortune Zone',
    content_types: 'Content Types',
    item_detail: 'Item Details',
    tasks_completed: 'Task Completed!',
    report: 'Report',
    profile: 'Profile',
    objectives_title: 'Objectives',
    quest_floor_locked_format: 'To unlock {0} {1}, complete {0} {2}',
    quest_boss_locked_format: 'To unlock {0} Boss, complete {0} {1}',
    required_power: 'Minimum Power',
    class_locked: 'Class Locked',
    compliment_fail: 'Backfired!',
    compliment_succeed: 'Success!',
    refuse_visitor_warning_title: 'Warning!',
    refuse_visitor_worker_warning_body:
        '{0} will return to the city. Are you sure?',
    refuse_visitor_hero_warning_body:
        'You will not earn any coins, but {0} will return later. Are you sure?',
    craft_energy_speedup: 'Speed Up',
    achievements: 'Achievements',
    claim: 'Claim',
    blueprint_locked: 'This blueprint needs to be researched first.',
    name_level_format: 'Requires {0} Lv. {1} ',
    building_required: 'Required building',
    craft_time_reduction: 'Time reduction',
    craft_component_reduction: 'Components spent',
    craft_resource_reduction: 'Resources spent',
    tap_continue: 'Tap to continue',
    contact_us: 'Contact us',
    link_device: 'Shop Titans ID',
    connect: 'Connect',
    FAQ: 'FAQ',
    informations: 'Information',
    hire_with_gems: 'Recruit with Gems',
    hire_with_gold: 'Recruit with Gold',
    chest_summary_title: 'You Got:',
    item_options: 'Item Options',
    discard_item: 'Dismantle',
    in_racks: 'On racks',
    in_inventory: 'In storage',
    higher_quality: 'Quality chance',
    price_increase: 'Value increase',
    craft_progress: 'Craft progress',
    unlocked: 'Unlocked',
    not_unlocked: 'Not yet unlocked',
    new_skill_unlocked: 'New skill unlocked!',
    visitor_buyer_format: '{0} Lv. {1} wants to buy',
    visitor_seller_format: '{0} Lv. {1} wants to sell',
    visitor_worker_seller_format: '{0} has a special offer!',
    visitor_worker_buyer_format: '{0} wants to buy',
    visitor_trender_format: '{0} Lv. {1} urgently needs',
    special_capacity: 'Stack Limit',
    cannot_store_upgrading_furniture:
        'Cannot store a furniture that is being upgraded',
    cannot_start_story_quest: 'Story quest requirements not met',
    champion_in_shop: 'Waiting in shop',
    view_offers: 'View offers',
    refill: 'Refill',
    refill_resource: 'Refill Resource',
    refill_resources: 'Refill Resources',
    craft_missing_worker: "You don't have that worker.",
    craft_missing_resources: 'Missing resources',
    craft_missing_components: 'Missing components',
    craft_missing_requirements: 'Missing requirements  -  {0}/{1}',
    craft_slots_full: 'All of your crafting slots are full.',
    click_open: 'Tap to open',
    open_now: 'Open now',
    use: 'Use',
    open_chest: 'Open chest',
    select_chest: 'Select chest',
    full_in: 'Full In {0}',
    next_goal_in: 'Next Goal In {0}',
    all: 'All',
    all_equipment: 'All Equipment',
    already_upgrading: 'This furniture is already being upgraded.',
    avg_sale_price: 'Value',
    back: 'Back',
    blueprint_levelup: 'Blueprint Level Up!',
    blueprint_notice:
        'Unlock the blueprint to get more information about this item.',
    cancel: 'Cancel',
    choose_name: 'Choose a name',
    not_enough_gems: 'Not enough gems!',
    complete: 'Complete',
    complete_quest: 'Quest Status',
    compliment: 'Small talk',
    content: 'Content',
    craft: 'Craft',
    craft_started: 'You have begun crafting {0}.',
    crafted: 'Crafted',
    crafting_time: 'Crafting Time',
    questing_time: 'Quest Time',
    create: 'Create',
    customers: 'Customers',
    customize: 'Customize',
    destination: 'Destination',
    discard: 'Discard',
    discard_adventurer_title: 'Retire Hero',
    discard_adventurer_question:
        'Are you sure you wish to retire this Hero?',
    disconnect: 'Disconnect',
    discount: 'Discount',
    discount_for: 'Discount for',
    dismiss: 'Send back',
    done: 'Done',
    empty: 'Empty!',
    enchant: 'Enchant',
    enchant_started: 'You have started enchanting {0}',
    int_furnitures: 'Furniture Space',
    out_furnitures: 'Outside decorations',
    indeco_furnitures: 'Interior decorations',
    download_version_failed: 'Server not responding.',
    download_failed: 'Download failed',
    not_enough_space: 'Not enough space on your device to store the data.',
    client_outdated:
        'Your client is not compatible with the server. An update is required.',
    invalid_stack:
        'Could not establish a connection with the server. Please check your Internet connection.',
    error_checkuser: 'Request error.',
    error: 'Error',
    error_code:
        'Your connection to the server was lost. Please reconnect to continue playing. (Error {0})',
    error_4002: 'Your account has connected from a different device.',
    error_4003: 'Your account is under review by one of our agents.',
    error_4004:
        'Your account has been banned by one of our agents. Please contact customer support for further assistance.',
    error_4007: 'We are currently performing server maintenance.',
    error_4008: 'You were disconnected due to inactivity.',
    error_4097:
        'To mourn the deceased victims and martyrs in the fight against Covid-19 starting at 2:00 AM on April 4th, all Kabam Games products operated in China will suspend service for one day.',
    error_4099:
        'Your connection to the server was lost. Please reconnect to continue playing. (Error 4099)',
    server_maintenance: 'We are currently performing server maintenance.',
    server_wrong_sim: 'Your game is not compatible with the server.',
    exceptional_craft: 'Masterpiece!',
    expand: 'Expand',
    expand_shop: 'Expand Shop',
    expansion_in_progress: 'Expansion in Progress',
    favorite: 'Bookmark',
    filter_by: 'Filter: {0}',
    furniture_cant_buy: 'You cannot purchase this furniture.',
    furniture_cant_buy_low_level:
        'Your level is too low to purchase this furniture.',
    furniture_cant_upgrade:
        "You don't have enough gold to upgrade this furniture.",
    furniture_new_level: '{0} upgraded to Level {1}!',
    furniture_upgrade_finished: 'Upgrade finished!',
    hazards: 'Local hazards',
    hire: 'Recruit',
    info: 'Info',
    instant_upgrade: 'Instant upgrade',
    inventory: 'Inventory',
    max_health: 'Max Health',
    item: 'Item',
    items: 'Items',
    invest: 'Investment',
    item_type: 'Item Type',
    join: 'Join',
    level_format: 'Level {0}',
    level_short_format: 'Lv.{0}',
    level_up: 'Level Up!',
    storage: 'Storage',
    lock: 'Lock',
    mastered: 'Mastered',
    move: 'Edit',
    name_error_message:
        "Your shopkeeper's name must have between 3 and 12 characters.",
    new: 'New!',
    blueprint_unlocks: 'Blueprints unlocked',
    new_blueprint: 'Blueprint Researched!',
    new_blueprint_unlocked: "You have {0}/{1} of the {2}'s blueprint",
    new_craftable_item: 'You can now craft {0}!',
    new_item: 'New Item',
    no: 'No',
    no_item_to_place: 'Item not in inventory',
    building_tutorial_locked: 'Finish tasks to unlock',
    contents: 'Contents',
    not_enough_energy: 'Not enough Energy!',
    not_enough_gold: 'Not enough Gold.',
    out_of_stock: 'Out of stock.',
    or: 'Or',
    purchase: 'Purchase',
    purchase_completed: 'You have purchased {0}',
    owned: 'Owned',
    quality: 'Quality',
    ready: 'Ready',
    recent: 'Recent',
    reconnect: 'Reconnect',
    recruit: 'Recruit',
    recruit_new_adventurer: 'Hero Creation',
    refuse: 'Refuse',
    repair: 'Repair',
    repair_question: 'Repair?',
    required_materials: 'Required Materials',
    required_workers: 'Required Workers',
    requires_building_format: 'Requires {0} Lv. {1}',
    requires_format: 'Requires {0}',
    blueprint_acquire_chest: 'Find blueprint fragments in Chests',
    resource_storage: 'Storage capacity',
    retry: 'Retry',
    rotate: 'Rotate',
    shop: 'Shop',
    size: 'Size',
    sold: 'Sold',
    sort_by: 'Sort: {0}',
    sort_highest: 'Level',
    exit_game_title: 'Quit Game',
    exit_game_question: 'Are you sure you want to exit the game?',
    spirit: 'Spirit',
    start_quest: 'Begin quest!',
    stats: 'Stats',
    storage_full: 'Your inventory is full.',
    storage_full_runestone:
        "You can't hold any more of that type of Runestone.",
    storage_full_enchantment: 'Your enchantment inventory is full.',
    storage_to_chest: '{0} now stores to storage.',
    storage_to_furniture: '{0} now store to {1}.',
    store: 'Store',
    stored_item: 'You have stored {0}',
    stored_item_no_availability:
        'No available furniture for {0}, stored in inventory',
    suggest: 'Suggest',
    surcharge: 'Surcharge',
    time_left_format: '{0} Left',
    unknown: 'Unknown',
    unlock_blueprint_more_info:
        'Unlock the blueprint for more information.',
    upgrade: 'Upgrade',
    upgrade_completed: 'You have upgraded {0}',
    upgrade_component_1: 'Component 1 {0}',
    upgrade_component_2: 'Component 2 {0}',
    upgrade_cost: 'Upgrade Cost',
    upgrade_quality: 'Quality x{0}',
    upgrade_started: 'You have started upgrading {0}',
    upgrade_time: 'Upgrade Time',
    speed_up_furniture_upgrade_title: 'Furniture Upgrade',
    speed_up_furniture_upgrade_body:
        '{0} is already being upgraded. Spend gems to finish instantly?',
    finish_furniture_upgrade_title: 'Furniture Upgrade',
    finish_furniture_upgrade_body:
        '{0} is ready to upgrade. Finish it before starting a new one.',
    upgrade_time_display: 'Time -{0}%',
    upgrade_value: 'Value x{0}',
    upgrade_unknown_error: 'Upgrade unknown error',
    xp: 'XP',
    yes: 'Yes',
    upgrade_need_space: 'Not enough space to upgrade.',
    level_up_desc: "Congratulations! You've reached level {0}!",
    exceptional_craft_desc: "You've crafted an outstanding item!",
    building_level_too_low:
        "The worker's level is not high enough for this item.",
    storage_overflow:
        'You need to free up some inventory space before storing this rack.',
    full: 'Full',
    details: 'Details',
    resource: 'Resource',
    special: 'Special',
    worker: 'Worker',
    capacity: 'Capacity',
    upgrade_requirements: 'Upgrade requirements',
    next_in: 'Next in {0}',
    ends_in: 'Ends in {0}',
    play_now: 'Play Now',
    worker_blacksmith: 'Blacksmith',
    worker_carpenter: 'Carpenter',
    worker_wizard: 'Wizard',
    worker_master: 'Master',
    worker_academy: 'Scholar',
    worker_dragon: 'Dragon',
    worker_elven: 'Summoner',
    worker_engineer: 'Engineer',
    worker_herbalist: 'Herbalist',
    worker_jeweler: 'Jeweler',
    worker_priest: 'Priestess',
    worker_tailor: 'Tailor',
    speed_up: 'Rush',
    upgrade_speedup: 'Rush',
    missing_bulk_requirements:
        'Order cannot be fulfilled; some items are missing.',
    max_adventurers_reached: 'Maximum Hero limit reached.',
    invalid_quester: 'Some Heroes are too inexperienced for this dungeon.',
    discard_item_question: 'Discard how many?',
    switch_account: 'Switch',
    add_credential_user_exists:
        'There is already an account linked to this user. Would you like to switch to this account?',
    platform_connect: 'Platform Connection',
    add_hero_slot_desc: '+1 Hero Capacity',
    value: 'Value',
    tap_to_speedup: 'Tap to speed up',
    tap_to_finish: 'Tap to finish',
    tap_to_collect: 'Tap to collect',
    click_to_speedup: 'Click to speed up',
    click_to_finish: 'Click to finish',
    click_to_collect: 'Click to collect',
    chest_button: 'Chest',
    energy_tooltip_desc:
        'Energy is earned and spent by haggling with customers. Upgrading your racks will increase your max energy.',
    shop_size: 'Shop Size',
    shop_size_tooltip_desc:
        'Available space for item racks and bins. Furniture in the basement still count towards this limit.',
    gold_tooltip_desc:
        'Gold is the primary currency of a shopkeeper. Earn it by selling items to customers.',
    gems_tooltip_desc:
        'Gems are a rare currency to purchase many wonderful things. Get more at the gem store!',
    cutsene_hire_question: 'Hire me?',
    purchase_nosubvip_desc: 'Benefit from 30 days of ROYAL PERKS!',
    consumable_pack: 'Consumable pack',
    contains: 'Contains',
    locked_item_sell: 'Selling locked item',
    locked_item_sell_desc:
        "The item you're about to sell is locked. Proceed anyway?",
    building_maxed: 'MAXED',
    hp_regen_bonus: 'HP Regen speed',
    quest_locked_format: 'To unlock {0}, you must reach {1} Level {2}.',
    purchase_success: 'Purchase Successful',
    objective_reroll_title: 'Reroll Objective',
    objective_reroll_message:
        'Are you sure you want to reroll that objective? (You have {0} reroll(s) left.)',
    objective_rerolls_none_left: 'No rerolls left. Next available in: {0}',
    token_store_reroll_title: 'Reroll Token Store',
    token_store_reroll_message:
        'Are you sure you want to reroll the remaining token store items? (You have {0} reroll(s) left.)',
    token_store_rerolls_none_left:
        'No rerolls left. Next available in: {0}',
    token_store_rerolls_none_to_reroll: 'No token store items to reroll.',
    building_levelup_desc: 'Congratulations! {0} reached Level {1}!',
    energy_saving_notice: 'Disabled (Energy saving)',
    customer_level_too_low: "Can't afford",
    boss: 'Boss',
    expand_instantly: 'Expand instantly',
    craft_slots_all_purchased: 'You already own all the crafting slots!',
    quest_slots_all_purchased: 'You already own all the quest slots!',
    component: 'Component',
    energy: 'Energy',
    new_class: 'New class',
    notification_city_help_expand: 'has helped you expand your shop!',
    notification_city_help_furniture_upgrade:
        'has helped you upgrade your furniture!',
    skills: 'Skills',
    energy_bonus: 'Energy from Sales',
    filter_all: 'All',
    filter_favorites: 'Bookmarks',
    filter_items: 'Items',
    sort_smart: 'Auto',
    sort_leveldsc: 'High level',
    sort_levelasc: 'Low level',
    trait_counters: 'Traits needed',
    locked: 'Locked',
    regen_minute_format: '{0}<size=-22>/min</size>',
    enter_name: 'Enter name',
    no_name: 'Please enter a valid name',
    invest_format: 'Invest {0}',
    quest_failed: 'Quest failed',
    building: 'Building',
    welcome_shopkeeper_message: 'Customize your Shopkeeper!',
    info_customization: 'Avatar Editor',
    leaderboards: 'Leaderboards',
    privacy_prompt_title: 'Our policy',
    privacy_prompt_body_01:
        'The terms of service and the privacy policy have been updated.',
    privacy_prompt_body_02: 'Press Ok to confirm your approval.',
    privacy_policy: 'Privacy Policy',
    terms_of_service: 'Terms of Service',
    info_stats: 'Personal Stats',
    ranking_gld: 'Gold Earned',
    ranking_stradv: 'Hero Power',
    ranking_prest: 'Shop Prestige',
    ranking_cscore: 'Guild Score',
    ranking_netwt: 'Guild Net Worth',
    info_settings: 'Settings',
    medals: 'Medals',
    level_too_low:
        'Your merchant level is too low to purchase this upgrade.',
    adventurer_selector_title: 'Characters',
    craft_selector_title: 'Craft',
    furniture_selector_title: 'Furniture',
    inventory_selector_title: 'Inventory',
    slots_selector_title: 'Slots',
    energy_max: 'Max energy',
    coming_soon: 'Coming soon!',
    no_match: 'No item match',
    recommended_power: 'Recommended Power',
    quest_boosts: 'Quest Boosts',
    quest_completed: 'Quest Complete!',
    continue: 'Continue',
    new_skill: 'New Skill!',
    select_quest_boosts: 'Quest Boosters',
    select_party: 'Select Party',
    select_party_remaining_format: '{0} Remaining',
    equip_heroes_notice:
        'If quests seem difficult, try outfitting your heroes with better equipment.',
    repair_title: 'Repair Items',
    repair_desc: 'Some items have broken!',
    repair_subtitle: 'Repair items?',
    repair_subtitle_2: 'Broken Items',
    discard_all: 'Dismiss All',
    show_me: 'Show All',
    warning: 'Warning!',
    warning_low_power:
        "Your party doesn't have the required Power to enter this floor.",
    go_back: 'Go back',
    skins: 'Skins',
    innate_effect: 'Skill Effect',
    class_innate: 'Innate Skill',
    equipped_hero_skill: 'Active Leader Skill',
    hero_effect_party: 'Skill Effect',
    equip: 'Equip',
    story: 'Story',
    quest_tier_1: 'City Outskirts',
    quest_tier_2: 'Untamed Lands',
    quest_tier_3: 'Lost World',
    level_required: 'Lv {0} required',
    no_gear_available_one: 'No {0} available',
    no_gear_available_multiple: 'No {0} or {1} available',
    components: 'Components',
    sort_recent: 'Recent',
    sort_favorite: 'Bookmark',
    item_set_favorite: '{0} set as bookmark',
    item_unset_favorite: '{0} removed as bookmark',
    auto_equip: 'Optimize',
    auto_fill_racks: 'Fill racks automatically?',
    filter_components: 'Components',
    filter_special: 'Special',
    filter_weapons: 'Weapons',
    filter_armors: 'Armor',
    filter_accessories: 'Accessories',
    unlocks_at_lv: 'Unlocks at level',
    unlocks_at: 'Available at',
    max_furniture_reached: 'Furniture limit reached',
    confirm: 'Confirm',
    time_to_upgrade: 'Time to upgrade',
    loading: 'Loading',
    ok: 'OK',
    hero_skill: 'Leader Skill',
    hero_hired: 'Champion Recruited',
    floor_selection: 'Floor Selection',
    unlock_quest_slot_title: 'Unlock Quest Slot?',
    unlock_quest_slot_desc: 'Send more parties on quests!',
    available_quest_slots: 'Available quest slots',
    unlock_with_gems: 'Unlock with gems',
    unlock_with_gold: 'Unlock with gold',
    goto_quests: 'Quest Map',
    unlock_craft_slot_title: 'Unlock Crafting Slot?',
    unlock_craft_slot_desc: 'Craft more items at the same time!',
    available_craft_slots: 'Available craft slots',
    goto_blueprints: 'Blueprints',
    max_seeds_reached: 'Seed limit reached.',
    skill_effect: 'Skill effect',
    skill_overview: 'Skill Overview',
    element_requirements: 'Elemental requirements',
    finish_now: 'Finish now',
    request_help: 'Ask for Help',
    expansion_complete: 'Expansion Complete',
    shop_expansion_unlocked: 'Shop expansion unlocked!',
    worker_unlocked_title: 'Worker Unlocked',
    worker_unlocked_desc:
        'The {0} is now available! You can hire them in the city.',
    completed: 'Complete',
    connecting: 'Connecting',
    sounds: 'Sound',
    music: 'Music',
    off: 'Off',
    battle_speed_format: 'Battle Speed: {0}',
    battle_speed_normal: 'Normal',
    battle_speed_fast: 'Fast',
    battle_speed_skip: 'Skip',
    quality_level_format: 'Quality: {0}',
    quality_level_0: 'Lowest',
    quality_level_1: 'Low',
    quality_level_2: 'Medium',
    quality_level_3: 'High',
    quality_level_4: 'Highest',
    craft_warning_high_quality_title: 'Using High-Quality Item(s)',
    craft_warning_high_quality_desc:
        'will be used for this craft. Proceed anyway?',
    give_warning_high_quality_desc: 'will be given. Proceed anyway?',
    give_warnng_high_quality_desc_multiple:
        'High-quality items will be given. Proceed anyway?',
    hire_adventurer: 'Recruit Hero',
    customize_adventurer: 'Customize',
    no_free_storage: 'Not enough storage space.',
    furniture_cant_buy_at_capacity:
        'You cannot purchase any more of this furniture.',
    indoors_only: 'Can only be placed indoors.',
    customization_summary: 'Customization Summary',
    discard_item_title: 'Dismantling {0}',
    discard_item_header: 'The following resources will be salvaged :',
    discard_item_body: 'Dismantle {0}?',
    randomize: 'Random',
    members: 'Members',
    visit_shop: 'Visit Shop',
    no_quest_slot_available: 'No quest slots available.',
    craft_speedup_title: 'Crafting Details',
    craft_speedup: 'Rush',
    completion_reward: 'Completion reward',
    close: 'Close',
    storage_capacity: 'Inventory size',
    max_family_furniture_reached: 'Sold out!',
    max_indoor_furniture_reached: 'Expand your shop for more space.',
    max_outdoor_furniture_reached:
        "You don't have any more outdoor space for this item",
    cannot_store_last_counter: 'The counter cannot be stored',
    finished: 'Finished',
    skip: 'Skip',
    skip_confirm_desc: 'Are you sure you want to skip the intro?',
    bulk_order_cancel_title: 'Cancel Order',
    bulk_order_cancel_body:
        'The Champion will return again later. Cancel order?',
    adv_level_too_low: "The hero's level is too low to use this item.",
    furniture_cannot_place: 'Not enough space in shop',
    new_adventurer: 'New Hero',
    add_adventurer_slot: 'Add Hero Slot',
    increase_adventurer_capacity: 'Increase Hero capacity',
    increase_max_adventurers: 'Increase Hero limit',
    increase_max_adventurers_question:
        'Are you sure to wish to increase your Hero limit?',
    unlock: 'Unlock',
    invalid_character: 'Only letters and numbers allowed',
    recruit_heroes_count: 'Recruit {0} Champions',
    no_more_quest_slots: 'Insufficient quest slots',
    unlock_adventurer_slot_title: 'Unlock Hero Slot?',
    unlock_adventurer_slot_body: 'More Heroes to send on quests!',
    purchase_selector_title: 'Store',
    energy_saving: 'Energy Saving',
    notifications: 'Notifications',
    fullscreen: 'Full Screen',
    language_en: 'English',
    language_fr: 'French',
    disabled: 'Disabled',
    enabled: 'Enabled',
    edit: 'Design',
    collect: 'Collect',
    hire_me: 'HIRE ME',
    city_help_given: 'Help given!',
    busy: 'Busy',
    resting: 'Resting',
    your_city: 'Your Guild',
    character_gained_xp: "{0}'s XP increased to {1}%",
    surcharge_seller: 'Pay more',
    discount_seller: 'Pay less',
    claim_reward: 'CLAIM REWARD!',
    connected: 'Connected',
    ingame_store: 'Store',
    purchase_section_featured: 'Featured',
    purchase_section_offers: 'Special offers',
    subscription: 'Bonus Perks',
    subscribed: 'Bonus Perks',
    hired: 'Recruited!',
    purchase_section_gems: 'Gems',
    purchase_usd_value_format: 'A ${0} USD Value!',
    purchase_tag1: 'Most popular!',
    purchase_tag2: 'Best value!',
    premium_worker: 'Special Worker',
    purchase_tab_gems: 'Gems',
    purchase_tab_specialoffers: 'Special Offers',
    purchase_tab_features: 'Featured',
    new_worker: 'New Worker',
    furniture_skins: 'Furniture skins',
    furniture_skin: 'Furniture skin',
    purchase_nosubvip_name: 'Royal Merchant',
    purchase_vip2_name: 'Royal Merchant',
    vip_title_01: 'Perks abound! Become an official',
    vip_title_02: 'Royal Merchant',
    vip_perks_title: 'Royalty Perks',
    vip_perk_1_desc: 'Repair items with Gold!',
    vip_perk_2_desc: 'Bonus VIP Rewards!',
    vip_perk_3_desc: '+10% Gems from Packs!',
    vip_perk_4_desc: 'Fortune Zone Discounts!',
    vip_perk_5_desc: 'Early Access on Offers!',
    join_now: 'Buy Now!',
    vip_price_format: '{0}/month',
    repair_with_gold: 'Repair with Gold',
    click_continue: 'Click to continue',
    purchase_sitems1_name: 'Research Scrolls',
    purchase_sitems2_name: 'Argon Coins',
    purchase_sitems3_name: 'Lilu Coins',
    purchase_sitems4_name: 'Ascension Shards',
    purchase_sitems5_name: 'Sia Coins',
    purchase_sitems6_name: 'Yami Coins',
    purchase_sitems1_desc:
        'A pack of Research Scrolls to unlock a discovered blueprint.',
    purchase_sitems2_desc: 'Rare coins used to empower Argon.',
    purchase_sitems3_desc: 'Rare coins used to recruit and empower Lilu.',
    purchase_sitems4_desc:
        'Take your blueprints to the next level with Ascension Upgrades!',
    purchase_sitems5_desc: 'Rare coins used to recruit and empower Sia.',
    purchase_sitems6_desc: 'Rare coins used to recruit and empower Yami.',
    purchase_section_boosts: 'Item Packs',
    notification_idle_title: 'Shop unattended',
    notification_idle_body:
        "Customers are at the door, don't leave them waiting!",
    notification_resources_complete_title: 'Resources refilled',
    notification_resources_complete_body:
        'Your bins are full and ready for crafting!',
    notification_crafting_complete_title: 'Work complete',
    notification_crafting_complete_body: 'Your items are ready for pickup!',
    notification_questing_complete_title: 'Quests complete',
    notification_questing_complete_body: 'Loot awaits!',
    notification_upgrading_complete_title: 'Upgrade complete',
    notification_upgrading_complete_body:
        'Your furniture upgrade is completed!',
    notification_heroesrested_complete_title: 'All patched up',
    notification_heroesrested_complete_body:
        'Your Heroes are fully rested and ready to go!',
    notification_daily_bank_ready_title: 'New Piggy Bank',
    notification_daily_bank_ready_body: 'Your Daily Piggy Bank is waiting!',
    objective_reachlevel: 'Sell items to reach level {0}',
    objective_furniturebuy: 'Purchase {0} {1}',
    objective_furnitureupgrade: 'Upgrade {0} {1}',
    objective_hire: 'Hire the {0} in the city',
    objective_hirehero: 'Hire a hero',
    objective_craft: 'Craft {0} {1}',
    objective_craft_blueprint: 'Craft {0} {1} to unlock {2}',
    objective_sell: 'Sell {0} {1}',
    objective_craft_gold: 'Craft {0} gold worth of {1}',
    objective_sell_gold: 'Sell {0} gold worth of {1}',
    objective_questsend: 'Send {0} heroes to {1}',
    objective_questsend_class: 'Send {0} {1} heroes on a Quest',
    objective_questlevel: 'Reach level {0} in the {1}',
    objective_questfloor: 'Complete {0} quests in {1}',
    objective_questloot: 'Bring back {0} {1} from {2}',
    objective_questboss: 'Defeat the boss of any dungeon',
    objective_booster: 'Use {0} quest booster(s)',
    objective_consumable: 'Use {0} consumable(s)',
    objective_openchest: 'Open {0} chest(s)',
    objective_dialog_compliment: 'Small talk with {0} customers',
    objective_dialog_suggest: 'Suggest a different item {0} time(s)',
    objective_dialog_surcharge: 'Surcharge {0} customers',
    objective_dialog_discount: 'Discount {0} customers',
    objective_fusion: 'Perform {0} fusions',
    equip_adventurer_count:
        'Have a Hero wear {0} pieces of equipment at once.',
    trade_title: 'Market',
    trade_tab_featured: 'Featured',
    trade_tab_requests: 'Requests',
    trade_tab_offers: 'Offers',
    trade_tab_slots: 'Your Listings',
    trade_create_request: 'Create A Request',
    trade_create_offer: 'Create An Offer',
    trade_wait_for_data: 'Fetching Trades',
    trade_slot_empty: 'Ready',
    trade_slot_collect: 'Collect!',
    trade_slot_expired: 'Expired',
    trade_slot_cancel_title: 'Trade Cancel',
    trade_slot_cancel_description_offer:
        'Are you sure you want to cancel your trade? (Your items will be returned)',
    trade_slot_cancel_description_request:
        'Are you sure you want to cancel your trade? (Your gold or gems will be returned)',
    trade_slot_cancel_description_guildRequest:
        'Are you sure you want to cancel your guild request?',
    trade_detail_gold_price: 'Gold Price',
    trade_detail_gems_price: 'Gems Price',
    trade_detail_gold_gain: 'Gold Gain',
    trade_detail_gems_gain: 'Gems Gain',
    trade_detail_missing_item:
        'You do not have the required item to fulfill or it is marked as locked in your inventory.',
    trade_detail_missing_sitem:
        'You do not have the required item to fulfill or it is marked as locked in your inventory.',
    trade_detail_higher_quality_title: 'Higher Item Quality',
    trade_detail_higher_quality_description:
        'You are about to use an item of a higher quality than required.',
    trade_create: 'Create',
    trade_create_step1: 'Create',
    trade_create_step2: 'Quantity',
    trade_create_step3: 'Price',
    trade_create_step4: 'Duration',
    trade_create_step5: 'Confirm',
    trade_create_back: 'Back',
    trade_create_next: 'Next',
    trade_create_confirm: 'Confirm',
    trade_create_request_description: 'Create Request',
    trade_create_offer_description: 'Create Offer',
    trade_create_item_info_quantity: 'Quantity:',
    trade_create_item_info_price: 'Price (Each):',
    trade_create_quantity_title: 'Quantity',
    trade_create_quantity_inventory: 'In Inventory:',
    trade_create_quantity_max: 'Maximum:',
    trade_create_quantity_trade: 'In Market:',
    trade_create_price_title: 'Price',
    trade_create_price_base: 'Base Value:',
    trade_create_price_min_trade: 'Trade Min. Value:',
    trade_create_time_title: 'Duration',
    trade_create_time_tax: 'Tax:',
    trade_create_time_receive: 'You Receive: ',
    trade_create_time_pays: 'You Pay (Each):',
    trade_create_confirm_quantity: 'Quantity:',
    trade_create_confirm_price: 'Price (Each):',
    trade_create_confirm_price_tax: 'After Tax (Each):',
    trade_create_confirm_time: 'Duration:',
    trade_create_time_format: '{0} Hours',
    trade_create_button_back: 'Back',
    trade_create_button_next: 'Next',
    trade_create_button_confirm: 'Confirm',
    trade_create_TBD: 'TBD',
    trade_create_NA: 'N/A',
    trade_detail_title_purchase: 'Buy From Market',
    trade_detail_title_fulfill: 'Fulfill From Inventory',
    trade_detail_base_value: 'Base Value:',
    trade_detail_trade: 'In Market:',
    trade_detail_inventory: 'In Inventory:',
    trade_detail_or: 'OR',
    trade_buy_from: 'Buy From Market',
    trade_slot_purchase_title: 'Trade Slot Purchase',
    trade_slot_purchase_body: 'Increase your trade slots by 1.',
    trade_selector_title: 'Market',
    trade_inventory_selector_title: 'Market',
    trade_slot_fulls: 'All your trade slots are full.',
    trade_too_low_level: 'Requires Lv.{0}',
    trade_too_low_level_error:
        'You need to be at least level {0} to trade with gems.',
    trade_collect: 'Collect x{0}',
    trade_mine: "You can't purchase your own offer!",
    trade_detail_fetching: 'Fetching...',
    trade_detail_processing: 'Processing...',
    trade_purchase_error:
        'An error has occurred and the item cannot be purchased.',
    trade_already_gone:
        'This item has already been purchased by another player.',
    trade_data_unavailable:
        'Data for this trade could not be found. Please refresh and try again.',
    trade_lock_unavailable:
        'An error has occurred and the item cannot be purchased.',
    trade_too_expensive:
        'Transaction canceled because its price increased. Please try again.',
    trade_listing_unavailable:
        'The listing for this trade could not be found. Please refresh and try again.',
    trade_fulfill_error:
        "Couldn't fulfill trade request. Please refresh and try again.",
    trade_too_cheap:
        'Transaction canceled because its price increased. Please try again.',
    world_map_title: 'Quest Map',
    world_map_description: '{0} Area(s) Unlocked',
    world_map_quest_description: 'Quest Area {0}',
    world_map_rewards_title: 'Available Components',
    world_map_send_party: 'Send Party',
    jackslot_tickets: 'Fortune spins',
    select: 'Select',
    help_all: 'Help all',
    tutorial_ok: 'Got it!',
    jackslot_title: 'Task List',
    goals_button: 'Goals',
    tasks: 'To-Do',
    jackslot_description:
        'Complete tasks to try your luck at the Fortune Zone!',
    jackslot_level: 'Level',
    jackslot_spin: 'Spin',
    jackslot_no_more_spins: 'No spins left',
    jackslot_no_more_spins_banned: 'No tickets left',
    jackslot_collect_title: 'Fortune Zone',
    jackslot_collect_title_description: 'REWARDS',
    jackslot_collect_action: 'Collect',
    jackslot_collect_action_vip: 'Collect All',
    jackslot_goal_claim: 'CLAIM REWARD!',
    jackslot_goal_next_title: 'Next In',
    rewards: 'Rewards',
    language_select: 'Select Language',
    language_change: 'Change Language',
    city_creation_date_format: 'Creation Date: {0}',
    city_id_format: 'Guild ID: {0}',
    chat_tab_world: 'World Chat',
    chat_tab_city: 'Guild Chat',
    chat_flood_notif: 'Too many messages sent, last message ignored.',
    enter_text: 'Enter Text',
    finder: 'Finder',
    city_search_title: 'Guild Finder',
    city_search_create_name: 'Name',
    city_search_create_name_placeholder: 'Enter Name...',
    city_search_create_minLevel: 'Min Level',
    city_search_create_private: 'Private',
    city_search_create_create: 'Create',
    city_search_item_minLevel: 'Min Level {0}',
    city_search_item_members: '{0}/{1} Members',
    city_search_item_action: 'Tap to Join',
    city_search_option_minLevel_title: 'Min Level',
    city_search_option_filter_title: 'Name Filter',
    city_search_option_filter_placeholder: 'Enter Name...',
    city_search_join_title: 'Join Guild',
    city_search_join_body: 'Are you sure you want to join this guild?',
    city_management_title: 'Guild Management',
    city_management_member_level: 'Lv. {0}',
    city_management_tab_options: 'Options',
    notification_craft_quality:
        'has crafted a <color=#FFDA00FF>{0}{1}</color>!',
    notification_hud_craft_quality: 'has crafted: {0} {1}!',
    notification_level_up:
        'has reached level <color=#FFDA00FF>{0}!</color>',
    notification_blueprint_mastered:
        'has mastered <color=#FFDA00FF>{0}</color>!',
    notification_blueprint_unlocked:
        'has unlocked <color=#FFDA00FF>{0}</color>!',
    notification_shop_expand:
        'has expanded their shop to level <color=#FFDA00FF>{0}</color>!',
    notification_quest_boss_defeated:
        'has defeated the <color=#FFDA00FF>{0}</color> Boss for the first time!',
    notification_purchase_vip: 'is now a Royal Merchant!',
    notification_member_online: 'is now online!',
    notification_member_offline: 'is now offline.',
    notification_member_leave: 'has left the guild.',
    notification_member_join: 'has joined the guild!',
    notification_member_kick: 'has kicked {0} from the guild.',
    notification_member_invest_bonus: 'has invested a lot in {0}!',
    notification_member_building_rush:
        'has rushed the upgrade process for {0}!',
    settings_notification_title: 'Notification Settings',
    settings_notification_body:
        'Select which notifications you would like sent to your phone.',
    settings_notifications_not_saved_title: 'Unsaved Changes',
    settings_notifications_not_saved_body:
        'Your changes will not be saved. Continue?',
    notification_craft: 'All crafts completed',
    notification_quest: 'All quests completed',
    notification_resources: 'Resource bins full',
    notification_healing: 'All Heroes rested',
    notification_upgrades: 'All upgrades completed',
    notification_dailybank: 'Piggy Bank available',
    notification_petfeed: 'A pet is hungry',
    purchase_craft_slot_unlock: 'Craft Slot {0}-{1}',
    purchase_quest_slot_unlock: 'Quest Slot {0}-{1}',
    purchase_adventurer_slot_unlock: 'Hero Slot {0}-{1}',
    upgrade_to_flawless: 'Upgrade to <color=#00DFFFFF>FLAWLESS</color>',
    upgrade_to_epic: 'Upgrade to <color=#D100FFFF>EPIC</color>',
    upgrade_to_legendary: 'Upgrade to <color=#FFD936FF>LEGENDARY</color>',
    city_member_count_format: 'Members <color=#FFD100FF>{0}/{1}',
    city_networth_format: 'Networth <color=#FFD100FF>{0}',
    city_rank_format: 'Rank {0}',
    city_help_asked: "You've asked the guild for help!",
    city_council: 'Guild',
    city_leader_rank: 'Guildmaster',
    city_officer_rank: 'Officer',
    city_member_rank: 'Member',
    search: 'Search',
    settings: 'Settings',
    rankings: 'Rankings',
    help: 'Help',
    city_shop: 'Guild Shop',
    city_type: 'Guild Privacy',
    city_type_public: 'Open to all',
    city_type_private: 'Private',
    city_type_invites: 'Invite only',
    min_level: 'Min. level',
    language: 'Language',
    city_language_english: 'English',
    city_language_french: 'French',
    city_language_international: 'International',
    save: 'Save',
    city_rankings: 'Guild Rankings',
    click_to_join: 'Click to join',
    tap_to_join: 'Tap to join',
    click_for_details: 'Click for details',
    tap_for_details: 'Tap for details',
    top_networth: 'Net Worth',
    investments: 'Investments',
    prestige: 'Prestige',
    top_helper: 'Helper',
    offer_help: 'Offer Help',
    daily_guild_coins: 'Daily guild coins',
    guild_coins: 'Guild Coins',
    nobody_asking_for_help: 'Nobody is currently asking for help',
    city_finder: 'Guild Finder',
    city_top_recommendations: 'Recommended cities',
    search_more: 'Search',
    create_city: 'Create Guild',
    networth: 'Net worth',
    helped: 'Helped',
    last_active: 'Last active',
    low_memory_notice:
        "Your device's memory is too low for the quality to be changed.",
    member_help_expand: 'Upgrading Shop to Lv.{0}',
    member_help_furniture: 'Upgrading {0} to Lv.{1}',
    options: 'Options',
    promote: 'Promote',
    demote: 'Demote',
    kick: 'Kick',
    chest_contains_format: 'Contains {0} of the following:',
    unlock_quest: 'Unlock Quest',
    unlock_quest_question: 'Unlock {0} quest?',
    adv_level_up_desc: 'Congratulations! {0} reached Level {1}!',
    last_active_online: 'Online',
    last_active_ago: '{0} ago',
    goto_quest: 'Go to {0}',
    unlock_quest_format: 'Unlock {0} quest',
    loading_updating_data: 'Updating Static Data (< 1MB)',
    loading_connecting: 'Opening city gates...',
    loading_warmPools: 'Speculating inclines...',
    loading_loadScene: 'Gearing up the Heroes...',
    loading_warmUI: 'Waking up Elder Owen...',
    loading_downloading: 'Downloading the fun stuff...',
    break_bonus: 'Break Chance',
    hero_story_level_requirement:
        'Champion Rank {0} required for next quest.',
    hero_story_finished: 'Story completed',
    hero_story_complete_for: 'Complete with',
    hero_story_requirements: 'Tap to contribute',
    rank_format: 'Rank {0}',
    rank_up_for: 'Rank up with',
    quest_setup_consumable: 'Boost',
    quest_setup_difficulty_easy: 'Easy difficulty',
    quest_setup_difficulty_medium: 'Medium difficulty',
    quest_setup_difficulty_hard: 'Hard difficulty',
    quest_setup_difficulty_extreme: 'Extreme difficulty',
    quest_setup_difficulty_boss: 'Boss',
    quest_setup_begin_explore: 'Explore Area',
    quest_setup_begin_boss: 'Fight Boss',
    quest_setup_unlock_location: 'Unlock Quest Location',
    quest_setup_back_map: 'Map',
    quest_setup_choose_reward: 'Choose an Area',
    quest_level_up_desc: '{0} reached Level {1}!',
    component_bonus: 'Component Bonus',
    leaderboard_rank_title: 'Rank',
    leaderboard_rank_format: '#{0}',
    leaderboard_rank_empty: '-',
    quest_setup_unlocks_level_format: 'Unlocks at Quest Level {0}',
    reward_increase: 'Dungeon Upgrade',
    quest_notice_boss_unavailable_title: 'Boss Unavailable',
    quest_notice_boss_unavailable_busy:
        'Another party is currently challenging the boss.',
    quest_notice_boss_unavailable_cooldown: 'Available again in {0}',
    quest_notice_target_locked_title: 'Undiscovered Area',
    quest_notice_target_locked: 'Requires {0} Level {1}',
    quest_notice_location_locked_title: 'Undiscovered Location',
    quest_notice_location_locked: 'Requires {0} Level {1}',
    auth_failed: 'Authentication failed',
    area_unlocked: 'Area Unlocked',
    go_again: 'Go Again',
    new_discovery: 'New Dungeon Discovered',
    tokens: 'Fortune Tokens',
    currency: 'Currency',
    tokens_tooltip_desc:
        'Get Fortune Tokens from the Fortune Zone and spend them on fabulous prizes at the store!',
    tokens_missing_hire: 'Collect more Champion Coins to recruit {0}!',
    tokens_missing_upgrade: 'Collect more Champion Coins to upgrade {0}!',
    zoom_in: 'Zoom In',
    zoom_out: 'Zoom Out',
    special_item_type_token: 'Champion Coin',
    champion_tokens: 'Champion Coins',
    upgrade_hero: 'Upgrade Coins',
    story_level_up_title: 'Rank Up!',
    story_level_up_desc: 'Congratulations! {0} reached rank {1}!',
    locked_item_give: 'Item Locked',
    locked_item_give_desc:
        "The item you're about to give is locked. Proceed anyway?",
    notification_member_invest: 'has invested {0} in {1}!',
    notification_member_invest_generic: 'has invested in {0}!',
    rested_in: 'Rested in',
    healed_in: 'Healed in',
    not_enough_tokens: 'Not enough Fortune Tokens.',
    rest_or_heal_bonus: 'Resting Time',
    hero_rank_upgrade_desc:
        "Increasing a Champion's rank will make them much stronger. They'll look different, too!",
    sitem_rest_effect_desc: '-{0} Rest time',
    sitem_seed_effect_desc: 'to hero (Permanent)',
    sitem_heal_effect_desc: '-{0} Heal time',
    sitem_repair_effect_desc: 'Repairs a broken item.',
    sitem_quest_desc: 'to party.',
    sitem_quest_xp_desc: 'to party/quest.',
    sitem_quest_loot_desc: '+{0}% Quest Loot',
    mailbox: 'Mailbox',
    purchase_skin: 'Purchase skin',
    next_rank_up: 'Next rank',
    xp_percent_format: '+{0}% XP',
    crit_percent_format: '+{0}% Critical',
    token_store_vip_message: 'Save {0}% on Prizes as a Royal Merchant!',
    next_in_noformat: 'Next In',
    next_available_in_noformat: 'Next available in',
    generic_received: 'You have received: {0} x{1}',
    gems_received: 'Gems received!',
    item_received: 'Item received!',
    special_item_received: 'Item received!',
    component_received: 'Components received!',
    resource_received: 'Resources received!',
    blueprint_fragment_received: 'Blueprint fragment received!',
    daily_prizes: 'Fortune Zone Prizes',
    redeem: 'Redeem',
    payouts: 'Payouts',
    jackpot: 'Jackpot',
    jackslot_no_match: 'No match',
    news_title: 'Mailbox',
    news_kind_news: 'News',
    news_kind_event: 'Event',
    news_kind_gift: 'Gift',
    news_view_details: 'View Details',
    news_claim: 'Claim',
    news_already_claimed: 'Claimed',
    notification_jackslot_jackpot:
        'just hit the Jackpot at the Fortune Zone!!!',
    city_management_options_saved: 'Guild settings saved!',
    tutorial_join_or_create_city:
        'Please join a guild - or create your own!',
    sitem_restheal_family_desc: '-{0}% Rest/Heal time',
    sitem_xp_effect_desc: 'Grants +{0}xp to a Hero.',
    xp_detail_format: 'Current XP: {0}/{1} ({2}%)',
    stat_networth_desc:
        'The total amount of gold a shopkeeper has made during their career.',
    stat_investment_desc:
        'The total amount of gold a shopkeeper has invested in the city. These investments follow the shopkeeper when they change guild!',
    stat_helped_desc:
        'How many guild members this shopkeeper helped, such as by speeding up furniture upgrade time.',
    stat_blueprints_desc:
        'The amount of blueprints this shopkeeper has mastered.',
    stat_prestige_desc:
        'A prestige score granted by fancy decorations. Has no gameplay effect, but high Prestige can earn you a spot on the leaderboards.',
    stat_lastactive_desc: 'The last time this shopkeeper logged in.',
    stat_ascensions_desc:
        'The number of ascension stars this shopkeeper has acquired.',
    city_management_no_permission:
        "You don't have permission to change these settings.",
    notification_bossrespawned: 'A boss is available',
    low_power_quester:
        'Some of your Heroes are too weak to challenge this quest.',
    jackslot_spin_auto: 'Auto Spin',
    city_kick_player_title: 'Kick',
    city_kick_player_question:
        'This member will be removed from the guild.',
    city_promote_player_title: 'Promote',
    city_promote_player_question:
        'This member will be promoted to Officer.',
    city_demote_player_title: 'Demote',
    city_demote_player_question:
        'This Officer will be demoted to a regular guild member.',
    promote_gmswap: 'Make Guildmaster',
    city_promote_gmswap_player_title: 'Promote to Guildmaster',
    city_promote_gmswap_player_question:
        'This officer will replace you as the new Guildmaster.',
    purchase_discount_format: '{0}%<br>Off!',
    limited_time_event: 'Limited Time Event',
    notification_city_help_expand_paid: 'has paid for your shop expansion!',
    notification_city_help_furniture_upgrade_paid:
        'has paid for your furniture upgrade!',
    help_with_gems: 'Complete upgrade',
    help_info_title: 'Help a Guild Member',
    available_at_level_format: 'Available at Level {0}',
    utils_days_abv: 'd',
    utils_hours_abv: 'h',
    utils_minutes_abv: 'm',
    utils_seconds_abv: 's',
    utils_timespan_ago: '{0} ago',
    utils_justnow: 'Just Now',
    welcome_offer: 'A Royal Welcome!',
    quest_setup_difficulty_easy_short: 'Easy',
    quest_setup_difficulty_medium_short: 'Medium',
    quest_setup_difficulty_hard_short: 'Hard',
    quest_setup_difficulty_extreme_short: 'Extreme',
    worker_special_engineer1:
        '<line-height=90%><voffset=-10><size=60><sprite name="BreakChance"></size></voffset> -{0}Item Break Chance',
    worker_special_moondragon01:
        '<line-height=90%><voffset=-10><size=60><sprite name="Fullmoon"></size></voffset> Permanent access to Full Moon fusions',
    quest_add_consumables: 'Add Boosters',
    got_it: 'Got it!',
    go: 'Go',
    reward: 'Reward',
    boss_battle: 'Boss Battle',
    chance: 'Chance',
    gold_received: 'Gold received!',
    purchased: 'Purchased!',
    worker_bonus_title: 'Permanent Bonus',
    bonus: 'Bonus!',
    promote_swap: 'Promote to Guildmaster',
    ignore_user_title: 'Ignore Player',
    ignore_user_body:
        "All of this player's messages will be hidden for this game session.",
    max_rank: 'Max Rank',
    reroll: 'Reroll',
    locked_item_notice: 'This item is locked.',
    city_join_error_city_full: 'This guild is full.',
    city_join_error_low_level:
        'You need to be at least level {0} to join this guild.',
    chest_enchanted_odds_desc:
        '<color=#2AE9EDFF>Magic Unlock</color> includes +1 extra guaranteed <sprite name=Star><color=#C9FCFFFF>RARE</color> or <sprite name=Star2><color=#FFD201FF>EXCLUSIVE</color> drop.',
    chest_enchanted_offer_desc:
        'At least 1 <sprite name=Star><color=#C9FCFFFF>Rare</color> or <sprite name=Star2><color=#FFD201FF>Exclusive</color> GUARANTEED!',
    chest_exclusive: '<sprite name=Star2>Exclusive',
    exclusives: 'Exclusives',
    chest_exclusive_odds: '<sprite name=Star2>Chest Exclusive',
    normal_odds: 'Unlock with Key',
    enchanted_odds: 'Magic Unlock',
    odds: 'Odds',
    blueprint_discovered_title: 'Blueprint discovered!',
    blueprint_discovered_subtitle:
        'Research this blueprint to start crafting it!',
    unlock_now: 'Research now',
    worker_level_required: 'Worker Level Required',
    max_worker_level: 'Max worker level',
    max_level: 'Max Level',
    story_requirement_format: 'Requires {0} Rank {1}',
    fetch_user_invalid_version:
        "This profile cannot be viewed because its owner's Shop Titans app is not up to date.",
    error_4009:
        'Your account is not compatible with the server. A client update is required.',
    error_4100:
        'The server was just updated to the latest version. Please reconnect to continue playing. (Code 4100)',
    hire_worker_card: 'Hire the {0}',
    celebration_multicraft_title: 'Multicraft!',
    celebration_multicraft_body:
        'Congratulations you have crafted {0} {1}!',
    trade_equip_empty: 'No Trades',
    welcome_back_title: 'Welcome Back!',
    welcome_back_description:
        "Hey Shopkeeper! Here's what you missed while you were away!",
    worker_card_xp_format: 'XP {0}<color=#31DD63FF>/{1}</color>',
    hire_name_format: 'Hire {0}',
    craft_multi_bonus: 'Multicraft chance',
    craft_component_quality_req_reduced: 'Component Quality',
    generic_received_short_no_qty: 'Received: {0}',
    unlock_blueprint_body:
        'Unlock this blueprint for {0} Research Scrolls? (You have {1})',
    bp_unlock_insufficient: "You don't have enough Research Scrolls.",
    trade_equip_selector_title: 'Market',
    trade_equip_wait_for_data: 'Fetching Trades',
    equip_from_trade: 'Equip From Market',
    equip_trade_shortcut_name: 'Equip From Market',
    equip_from_inventory: 'Equip from Inventory',
    maximum_equip_tier: 'Can wear up to Tier',
    buy_from_trade: 'Buy From Market',
    wait: 'Wait',
    trade: 'Market',
    unlock_blueprint_question: 'Blueprint Research',
    bp_unlock_amount_title: 'Owned',
    bp_unlock_required_amount_title: 'Required',
    bp_unlock_balance_amount_title: 'Balance after research',
    hero_tokens_format: 'Champion Coins ({0})',
    gm_pick_none: 'None',
    gm_pick_title: "Guild's Choice",
    gm_pick_description:
        "A building marked with the Guild's Choice will be cheaper to invest in.",
    gm_pick_cooldown: "Guild's Choice can be changed again in {0}.",
    gm_pick_current: "Current Guild's Choice",
    gm_pick_activate: 'Activate',
    chat_tab_notifications: 'Notifications',
    rate_us_title: 'Enjoying the Game?',
    rate_us_description:
        'Please take a moment and leave us a review on the Store!',
    rate_us_not_now: 'Not Now',
    rate_us_never: 'No Thanks',
    rate_us_rate: 'Rate Us',
    max: 'Max',
    rest_time_reduced_title: 'Rest Time Reduction',
    quest_time_reduced_title: 'Quest Time Reduction',
    quest_xp_bonus_title: 'XP Bonus',
    quest_xp_bonus_body: 'Quest XP +{0}%',
    all_characters_fully_rested: 'Your characters are already rested.',
    city_management_options_no_permission:
        "Only Officers and Guildmasters can change the Guild's Choice.",
    blueprint_special_upgrade_title: 'Blueprint Ascended!',
    blueprint_special_upgrade_body: 'Your {0} blueprint has ascended!',
    lost_account_question: 'Already have an account?',
    lost_account_unavailable: 'Account Recovery Unavailable',
    lost_account_confirmation_title: 'Account Recovery',
    lost_account_confirmation_body:
        'You are about to attempt to recover your account. Continue?',
    error_checkuser_not_found:
        'No account was found. Please contact support by completing the tutorial, going to settings and using the "Contact Support" button.',
    chat_banned_notif: 'You are banned from chat for {0}.',
    hero_hired_format: '{0} recruited!',
    craft_low_level_worker: "Your worker's level is too low to craft this.",
    city_kick_error_being_kicked: 'This member has already been kicked.',
    city_create_error_being_kicked:
        "Can't complete this action because you are no longer part of this Guild.",
    craft_upgrades: 'Crafting Upgrades',
    special_upgrades: 'Ascension Upgrades',
    special_upgrade_button_title: 'Ascend',
    upgrades: 'Upgrades',
    supgrade_insufficent: 'Not enough Ascension Shards to ascend.',
    missing_key: "You don't have a suitable key for this chest.",
    equipment_hold_trade: 'Equipment (Hold to open Market)',
    hero_hired_title: 'CHAMPION RECRUITED!',
    hero_hired_desc: '{0} is ready to go on quests!',
    equip_tier: 'New item tier',
    complete_upgrade: 'Complete upgrade',
    confirm_token_purchase_title: 'Fortune Zone Reward',
    confirm_token_purchase_body:
        'Would you like to purchase {0} {1} for {2} Fortune Tokens?',
    insufficient_bp_unlock_notice: 'Insufficient Research Scrolls',
    building_invest_format: 'Invest in {0}',
    building_unlock_format: 'Unlock {0}',
    blueprint_quality_mult: 'Quality chance',
    special_item_revivekit1_used: 'All characters refreshed!',
    special_item_revivekit2_used: 'All characters revived!',
    guild_choice_timer_format: 'Can be changed in: {0}',
    craft_warning_lock_title: 'Item Locked',
    give_warning_lock_desc: 'This item is currently locked. Use it anyway?',
    connecting_platform_title: 'Connecting to platform',
    connecting_platform_desc: 'Connecting',
    push_notification_trade_complete_title: 'Market',
    push_notification_trade_complete_desc: 'Your {0} trade is complete!',
    push_notification_default_title_news: 'News',
    push_notification_default_body_news:
        'Log in to find out new things happening!',
    push_notification_default_title_event: 'Limited Time Event',
    push_notification_default_body_event:
        'Log in today to participate and get the most out of it!',
    push_notification_default_title_gift: 'Gift',
    push_notification_default_body_gift:
        'Log in today to receive a free gift!',
    push_news_update_title: 'New Shop Titans update!',
    push_news_update_desc:
        'Shop Titans just received an update! Login to check it out!',
    max_hero_level: 'Max hero level',
    enchanted_odds_bonus:
        '+1<sprite name="Star">/<sprite name="Star2"> Extra!',
    building_invest_format_worker_details: 'Invest in <size=+8>{0}</size>',
    unlocks_at_format: 'Unlocks at Lv {0}',
    story_rewatch_dialog: 'Watch again',
    craft_speed_bonus: 'Craft speed bonus',
    purchase_furniture: 'Purchase Furniture',
    purchase_furniture_body: 'Do you want to purchase {0}?',
    sitem_use_rest: 'Revive Characters',
    sitem_use_heal: 'Heal Characters',
    sitem_revive_heal_question:
        '{0} resting or injured character(s) will be fully healed. Proceed?',
    sitem_revive_question:
        '{0} resting character(s) will be fully healed. Proceed?',
    stat_attack_type: 'Attack Stat',
    stat_defense_type: 'Defense Stat',
    stat_hp_type: 'HP Stat',
    char_hp_format: "{0}'s HP",
    char_attack_format: "{0}'s Attack",
    char_defense_format: "{0}'s Defense",
    hero_tokens_received: 'Champion Coins Received!',
    daily_offer: 'Daily Offer',
    daily_offer_expired_title: 'Offer Expired',
    daily_offer_expired_body: 'Daily Offer has expired.',
    equip_tier_max: 'Max equipment tier',
    menu: 'Menu',
    rank_up: 'Rank Up',
    slot_unlocks_up_to: 'Up to Slot {0}',
    craft_slot_unlocks: 'Craft Slot Unlock(s)',
    quest_slot_unlocks: 'Quest Slot Unlock(s)',
    trade_slot_unlocks: 'Trade Slot Unlock(s)',
    adventurer_slot_unlocks: 'Hero Slot Unlock(s)',
    notification_trades: 'Trades completed',
    notification_news: 'Special event',
    furniture_gift: 'Gift Claimed!',
    furniture_gift_desc: 'You received {0} Level {1}!',
    exclusive: 'Exclusive',
    purchase_slot_unlock_format: 'Slot {0}',
    furniture_name_type_format: '{0} ({1})',
    exclusive_blueprint: 'Exclusive Blueprint!',
    exclusive_blueprint_line: 'Exclusive Blueprint Line!',
    event_workertrial_name: 'Worker Trial',
    event_engineertrial_desc:
        'For a limited time, the Engineer worker is available to all players - for free!',
    event_scholartrial_desc:
        'For a limited time, the Scholar worker is available to all players - for free!',
    event_dragontrial_desc:
        'For a limited time, the Moon Dragon worker is available to all players - for free!',
    event_cooktrial_desc:
        'For a limited time, the Cook worker is available to all players - for free!',
    no_chest: 'No Chests',
    special_card_desc: 'Tap to heal',
    daily_offer_upgrade_equipment_quality: 'Quality Upgrade',
    upgrade_equipment_quality_desc:
        'Upgrade a powerful item to the next quality level!',
    daily_offer_upgrade_furniture: 'Instant Upgrade Discount',
    daily_offer_upgrade_furniture_desc:
        'Get a <color=#FFDF00FF>{0}% Discount</color> by upgrading your {1} from Lv.{2} to Lv.{3}!',
    daily_offer_open_chest: 'Special Chest Discount',
    daily_offer_open_chest_desc:
        'Receive a <color=#FFDF00FF>{0}% Discount</color> if you open a {1} now!',
    daily_offer_open_chest_progress:
        '<sprite name=Star2>{0}/{1} Discovered',
    daily_offer_banner_title: 'Daily Offer',
    exceptional_multi_craft_desc: "You've crafted TWO exceptional items!",
    item_tier_type_format: 'Tier {0} {1}',
    daily_offer_quality_upgraded_title: 'Item Upgraded!',
    worker_level_too_low:
        "Your worker's level is too low to craft this item.",
    subscribe_now: 'Subscribe Now!',
    bp_unlock_tooltip_desc:
        'Research Scrolls are used to unlock new blueprints. You can earn more by crafting lots of items or by opening chests.',
    help_gem_discount_desc:
        "{0}% discount when completing a guild member's upgrade.",
    upgrade_to_uncommon: 'Upgrade to <color=#6CE86CFF>Superior</color>',
    bp_detail_blueprint: 'Blueprint',
    bp_detail_upgrades: 'Milestones',
    bp_detail_ascension: 'Ascension',
    bp_detail_resources: 'Resources',
    bp_detail_workers: 'Workers',
    bp_detail_craft: 'Craft',
    bp_detail_unlock: 'Unlock Now',
    bp_detail_upgrade: 'Milestone Upgrades',
    bp_detail_upgrade_desc:
        'Reach crafting milestones to unlock upgrades for this blueprint.',
    bp_detail_upgrade_info:
        'Receive additional Research Scrolls with every upgrade!',
    bp_detail_ascensions: 'Ascension Upgrades',
    bp_detail_ascensions_desc:
        "Unlock this blueprint's true potential with Ascension Shards!",
    bp_detail_ascend_to: 'Ascend to',
    bp_detail_ascension_maxed: 'Fully ascended!',
    bp_detail_locked: 'This blueprint needs to be researched first.',
    bp_detail_stats: 'Stats',
    stat_skills_desc:
        'Skills are powerful bonuses a Hero can learn over time. Better classes can learn more (and better!) skills.',
    stat_skills_desc2: "This hero's skills are upgraded by {0}",
    all_elements: 'all elements',
    added_on_rack: '{0} added to rack.',
    booster_applied: '{0} added as booster.',
    blueprint_discovered: 'Blueprint discovered',
    preregistrationreward_name: 'Pre-registration Reward',
    purchase_preregistrationreward_name: 'Pre-registration Reward',
    daily_offer_item_upgrade_desc_format:
        "Upgrade {0}'s {1} from <color=#{2}>{3}</color> to <color=#{4}>{5}</color>",
    held_by: 'Held by',
    purchase_confirmation_title: 'Purchase Confirmed!',
    purchase_confirmation_desc: "You've successfully purchased {0}!",
    claim_confirmation_title: 'Reward Claimed!',
    claim_confirmation_desc: "You've successfully claimed {0}!",
    find_in_quest: 'Find in quests',
    heal_card_name: 'Heal Characters',
    heal_popup_desc: 'Heal all character',
    no_injured_character: 'No injured characters.',
    all_character_healed: 'All characters have been healed.',
    city_search_wait_for_invites: 'Fetching Invites',
    furniture_gift_deco_desc: 'You received a {0}!',
    city_invite_player_title: 'Guild Invite',
    city_invite_player_question:
        'Are you sure you want to invite {0} to your guild?',
    city_invite_player_action: 'You have invited {0} to join your guild!',
    recruit_new_hero: 'Recruit a new hero!',
    quest_slots_full: 'You own every quest slot!',
    enchant_item_question: 'Enchant <color=#{0}>{1}</color> with {2}{3}',
    enchant_item_equipped_format: 'Enchanted with: {0}{1}',
    skill_survive_lethal: '{0} chance to survive one fatal blow.',
    skill_regen: 'Heal {0} HP each turn.',
    daily_offer_quality_upgraded_desc: '',
    craft_warning_lock_desc:
        'You are about to spend a locked item. Proceed?',
    survive_lethal: 'Chance to survive one fatal blow.',
    regen: 'HP Regen per turn',
    enchant_celebration_subtitle: '{1} successfully enchanted on {0}!',
    enchant_failure_subtitle: 'Enchantment on {0} failed...',
    player_search_title: 'Player Finder',
    player_search_call_to_action:
        "Enter a player's name to see results (3 characters minimum)",
    player_search_input_placeholder: 'Enter Name...',
    player_search_wait_for_data: 'Loading',
    player_search_location_near: 'Nearby',
    player_search_location_world: 'Worldwide',
    player_search_item_more: 'More',
    player_search_item_wait_for_data: 'Loading',
    trade_banned_notif: 'You have been banned from the market for {0}.',
    element_fire: 'Fire',
    element_water: 'Water',
    element_earth: 'Earth',
    element_air: 'Air',
    element_dark: 'Dark',
    element_light: 'Light',
    element_gold: 'Gold',
    more_details: 'More details',
    element_enchantment: 'Elemental Enchantment',
    enchant_celebration_title: 'Item enchanted!',
    enchant_failure_title: 'Enchantment failed!',
    spirit_enchantment: 'Spirit Enchantment',
    spirit_skill: 'Spirit Skill',
    remove_enchantment: 'Destroy Enchantment',
    confirm_removal: 'Confirm Removal',
    city_search_input_placeholder: 'Enter Name...',
    city_search_wait_for_data: 'Loading',
    city_search_location_near: 'Nearby',
    city_search_location_world: 'Worldwide',
    city_search_item_more: 'More',
    city_search_item_wait_for_data: 'Loading',
    default: 'Basic',
    skill_upgrades: 'Skill Upgrades',
    requires: 'Requires',
    next_upgrade: 'Next upgrade',
    skill_reroll: 'Reroll Skill',
    total: 'Total',
    reroll_skill_title: 'Skill Reroll',
    unequip: 'Unequip',
    item_power: 'Item Power',
    break_chance: 'Break Chance',
    current_skill: 'Current skill',
    city_invite_title: 'Guild Invites',
    city_invite_wait_for_data: 'Loading',
    city_invite_option_accept: 'Open',
    city_invite_option_ignore: 'Ignore',
    invite_city: 'Invite to Guild',
    hero_skill_tier_upgrade_desc: '<color=#8C416BFF>Next Upgrade:</color>',
    hero_skill_tier_upgrade_value: 'Rank {0}',
    questing: 'On Quest',
    free_rerolls: 'Free rerolls ',
    skill_stat_for_item_types: 'with',
    aggro_bonus: 'Threat Rating',
    count_left_format: '{0} Left',
    skill_count_format: '{0} Skills',
    skill_rarity_format: '{0} Skill',
    skill_slot_odds_format: 'Odds for skill slot #{0}',
    worker_special_academy1:
        '<line-height=90%><voffset=-10><size=60><sprite name="XpUpgrade"></size></voffset> +{0} Merchant XP earned',
    dont_have_item: "You don't have {0} in your inventory.",
    heal_party_title: 'Heal All Characters',
    heal_character_title: 'Heal {0}',
    heal_party_desc:
        'The following characters will be ready to quest again.',
    heal_character_desc: '{0} will be ready to quest again.',
    heal_with_gems: 'Heal with Gems',
    heal_with_item1: 'Heal with Feather',
    heal_with_item2: 'Heal with Drink',
    item_remove_tag_title: 'Destroying Enchantment',
    item_remove_tag_question:
        'This action will free up the slot, but destroy the enchantment.',
    character_busy:
        'Cannot perform this action while the character is on a quest.',
    remove_enchant_req_worker:
        'Hire the {0} to remove the enchantment on this item.',
    purchase_academy1_name: 'The Wise Scholar',
    purchase_academy1_desc: 'Uncover the secrets of the Runestones!',
    city_join_error_same_id: "You're already a member of that guild!",
    daily_offer_item_upgrade_noadv_desc_format:
        'Upgrade {0} from <color=#{1}>{2}</color> to <color=#{3}>{4}</color>',
    skill_reroll_result: 'Reroll Result',
    reroll_again: 'Roll again',
    filter_enchantments: 'Enchantments',
    item_replace_tag_title: 'Replacing Enchantment',
    item_replace_tag_question:
        'The original enchantment will be destroyed. Are you sure?',
    item_replace_tag_question_success:
        'The original enchantment will be destroyed if the enchantment is successful. Are you sure?',
    quality_enchant_desc:
        "The enchantment's quality needs to match the item.",
    purchase_blueprint_name_unlock_format: '{0} (Unlocked by {1})',
    exclusive_blueprint_lines: 'Exclusive Blueprint Lines!',
    required_quality: 'Required quality : ',
    max_capacity_format: 'Max Capacity: {0}',
    total_format: 'Total: {0}',
    max_stack_format: 'Max Stack: {0}',
    character_healed: '{0} has been healed',
    tag_can_enchant_quality: 'Can enchant Items of quality:',
    craft_missing_resource: "You don't have the required resource bin.",
    enchantments: 'Enchantments',
    your_gold_format: 'Your Gold: {0}',
    upgrade_already_finished: 'This upgrade is already completed.',
    enchant_trade_shortcut_name: 'Enchant from Market',
    enchant_trade_shortcut_desc: 'Tap to view',
    trade_enchant_selector_title: 'Market',
    craft_slots_all_purchased_title: 'All Slots Owned',
    quest_slots_all_purchased_title: 'All Slots Owned',
    trade_slots_all_purchased_title: 'All Slots Owned',
    city_create_error_city_already_exists: 'This guild already exists.',
    equip_trade_shortcut_desc: 'Tap to view',
    trade_enchant_empty: 'No Items.',
    furniture_skin_apply_all: 'Apply to all',
    unlock_for_format: 'Unlock for {0} {1}',
    confirm_for_format: 'Confirm for {0} {1}',
    skin_unlock_desc_format: 'This Skin will also be unlocked for all {0}',
    enchant_action: 'Enchant',
    change: 'Change',
    replace_enchantment: 'Replace Rune',
    report_offensive_name: 'Offensive Name',
    report_offensive_chat: 'Offensive Chat',
    report_confirmation: '{0} has been reported.',
    menu_vfx: 'Item VFX',
    to_help: 'Help',
    inventory_sort_recent: 'Sort by: Most Recent',
    inventory_sort_quantity: 'Sort by: Quantity',
    inventory_sort_value: 'Sort by: Value',
    inventory_sort_value_asc: 'Sort by: Value-',
    inventory_sort_value_dsc: 'Sort by: Value+',
    inventory_sort_tier: 'Sort by: Tier',
    inventory_sort_tier_asc: 'Sort by: Tier-',
    inventory_sort_tier_dsc: 'Sort by: Tier+',
    inventory_sort_quality: 'Sort by: Quality',
    inventory_sort_type: 'Sort by: Type',
    inventory_sort_stats: 'Sort by: Stats',
    inventory_sort_chances: 'Sort by: Success %',
    inventory_sort_area: 'Sort by: Area',
    buy_with_gold: 'Buy with Gold',
    buy_with_gems: 'Buy with Gems',
    skin_applied: 'Current skin',
    furniture_skin_applied_to_all:
        'Skin applied to all matching furniture.',
    floors_and_walls_selector_title: 'Customize',
    decoration_type_floor: 'Flooring',
    decoration_type_wall: 'Wallpaper',
    floors: 'Flooring',
    walls: 'Wallpapers',
    applied: 'Applied',
    customize_appearance: 'Appearance',
    customize_clothes: 'Clothes',
    hide_furniture: 'Hide Furniture',
    show_furniture: 'Show Furniture',
    next: 'Next',
    apply: 'Apply',
    contract: 'Contract',
    previous_abv: 'Previous',
    wall_applied: 'Wallpaper applied.',
    floor_applied: 'Flooring applied.',
    buy_now: 'Buy now',
    avatar_editor: 'Avatar Editor',
    avatar_editor_unlocked_desc:
        "You've unlocked the Avatar Editor! Time to dress up!",
    unlock_skin_title: 'Skin Unlock',
    unlock_floor_title: 'Flooring Unlock',
    unlock_wall_title: 'Wallpaper Unlock',
    unlock_floor_question:
        'This flooring will become available for use in your shop.',
    unlock_wall_question:
        'This wallpaper will become available for use in your shop.',
    apply_all: 'Apply All',
    apply_to_all_question: 'Apply All',
    apply_to_all_floor_desc:
        'This flooring will be applied to the entire shop.',
    apply_to_all_walls_desc:
        'This wallpaper will be applied to the entire shop.',
    first_purchase_title: 'First Purchase Offer!',
    first_purchase_description:
        'Get +{0}% Gems on your first purchase of each pack!',
    first_purchase_percentage: ' {0}%',
    first_purchase_purchased: 'Purchased',
    trade_tier_format: 'Market Tier {0}',
    new_furniture: 'New Furniture',
    new_shop_customizations: 'New Unlocks',
    bonus_cap: 'BONUS!',
    jackslot_spin_auto_banned: 'Fast Open',
    jackslot_tickets_banned: 'Fortune Tickets',
    jackslot_spin_banned: 'Fortune Zone',
    no_rack: 'Item is not on a rack and cannot be suggested.',
    seeds_remaining_format: '{0}/{1}',
    max_level_message: 'Max level.',
    max_level_indication: 'Max Level',
    skin_unlock_counter_desc:
        'This Skin will be unlocked for your counter.',
    enchantable_item_missing:
        "You don't have any items that can be enchanted.",
    enchant_item_success_chance: '{0} Chance',
    enchant_finish: 'Finish',
    no_booster_to_add: "You don't have any more boosters of this type.",
    daily_bank: 'Piggy Bank',
    daily_bank_title: 'Piggy Bank Tracker',
    daily_bank_next_title: 'Next Piggy Bank in:',
    new_daily_bank_ready: 'New Piggy Bank available!',
    daily_bank_description:
        'Earn gold from sales to fill up your Piggy Bank and receive a reward!',
    daily_bank_claim_tap_text: 'Smash the Piggy Bank to claim your reward!',
    claim_daily_bank: 'Tap to COLLECT!',
    score: 'Score',
    member_settings: 'Member Settings',
    city_management_invites_no_permission:
        "You don't have permission to invite.",
    stat_score_desc:
        'A general score tied to your personal progression. Increase it by mastering blueprints, buying upgrades, and so on.',
    guild_rank: 'Guild Rank',
    rank_change: 'Rank Change',
    rank_info_title: 'Guild Rank Privileges',
    rank_info_row_title: 'Rank',
    rank_info_permission1: 'Change guild settings',
    rank_info_permission2: 'Kick members',
    rank_info_permission3: 'Change members rank',
    trade_enchant_wait_for_data: 'Searching for enchantments...',
    trade_listing_title_request: 'Requests',
    trade_listing_title_offer: 'Offers',
    trade_listing_recent_offer: 'Recent Offers',
    trade_listing_recent_request: 'Recent Requests',
    trade_listing_search_result: 'Search Result',
    trade_listing_sort_recent: 'Sort by: Recent',
    trade_listing_sort_default: 'Sort by: Default',
    trade_listing_sort_value_asc_gold: 'Sort by: Gold Price-',
    trade_listing_sort_value_dsc_gold: 'Sort by: Gold Price+',
    trade_listing_sort_value_asc_gems: 'Sort by: Gem Price-',
    trade_listing_sort_value_dsc_gems: 'Sort by: Gem Price+',
    trade_show_all_offers: 'Show All Offers',
    trade_show_all_requests: 'Show All Requests',
    purchase_token_exchange: 'Exchange',
    gems_desc: 'A rare currency used to purchase many wonderful things.',
    trade_slots_info: 'Create and manage your Market listings here.',
    worker_ability: 'Worker Ability',
    worker_ability_master_desc:
        'Required to craft many Tier {0} items and beyond.',
    worker_ability_sundragon_desc:
        'Uses the Synthesis Vessel to fuse items together!',
    booster_removed: '{0} removed as booster.',
    king_value_mult_format: '{0}x<br><size=-3>Value!</size>',
    recruit_hero_format: 'Recruit {0}',
    filter_elements: 'Elements',
    filter_spirits: 'Spirits',
    filter_tier: 'Tiers',
    filter_quality: 'Qualities',
    trade_search_title: 'Advanced Search',
    unlock_hero_now: 'Unlock now!',
    offer: 'Offer',
    request: 'Request',
    relist: 'Relist Item',
    expire_in: 'Expiring In',
    find_player: 'Find Player',
    invites: 'Invites',
    purchase_sitems7_name: 'Rudo Coins',
    purchase_sitems7_desc: 'Rare coins used to recruit and empower Rudo.',
    inventory_enchant_title: 'Select Enchantment',
    customization_summary_remove_all: 'Remove All',
    customization_cancel_popup_title: 'Cancel Changes',
    customization_cancel_popup_body:
        'Are you sure? All unsaved changes will be reverted.',
    trade_price_tooltip_notax_offer: 'Your Listing (Each)',
    trade_price_tooltip_notax_request: 'Your Listing (Each)',
    trade_price_tooltip_withtax_offer: 'You Get (Each)',
    trade_price_tooltip_withtax_request: 'You Pay (Each)',
    trade_create_quality_title: 'Quality',
    trade_create_confirm_quality: 'Quality: ',
    news_empty: 'No News',
    unlock_blueprint_title: 'Unlock Blueprint',
    search_in: 'Search in',
    blueprint_found_in_chest_card: 'Found in {0}',
    blueprint_found_in_chest_popup:
        'This blueprint can be found in the {0}.',
    furniture_found_in_chest_popup:
        'This furniture can be found in the {0}.',
    furniture_chest_search: 'Search this furniture',
    furniture_skin_found_in_chest_popup:
        'This skin can be found in the {0}.',
    furniture_skin_chest_search: 'Search this skin',
    ascension_detail_upgrade_title: 'Milestones',
    ascension_detail_upgrade_desc:
        'Reach ascension milestones to unlock upgrades for this item type.',
    ascension_detail_upgrade_info:
        'Unlock some one-time rewards with ascension upgrades!',
    cannot_store_last_chest: "You can't store your last trunk.",
    city_compatibility_error_version:
        'Your client is not compatible with the server. An update is required.',
    rarity_rare: 'Rare',
    rarity_exclusive: 'Exclusive!',
    building_debt_title: 'Building Debt',
    building_debt_description:
        'This building is in debt, but will not downgrade below level {0}. Investments from other guild members will not be counted until they update the game.',
    expansion_unlocks: 'Expansion Unlock(s)',
    expansion_unlocks_up_to: 'Up to Expansion {0}',
    purchase_expansion_unlock_format: 'Expansion {0}',
    worker_xp_bonus: 'Worker XP Bonus from {0}',
    surcharge_energy_reduction: 'Surcharge Energy Cost for {0}',
    higher_quality_for_type: 'Quality chance for {0}',
    craft_multi_bonus_for_type: 'Multicraft chance for {0}',
    ascension_progress_description:
        'Ascend {0} blueprints to unlock powerful upgrades.',
    ascensions: 'Ascensions',
    ascension_tracks: 'Ascension Tracks',
    total_ascensions: 'Total Ascensions',
    discount_old_price: 'A {0} value!',
    customization_received: 'Customization Received!',
    offer_has_expired: 'This offer has expired!',
    city_join_error_invalid_version:
        'City cannot be joined because your client is not compatible with the server. An update is required.',
    city_kick_error_invalid_version:
        'Player cannot be kicked because your client is not compatible with the server. An update is required.',
    furniture_available_in_offer_popup:
        'This furniture is available in the {0}',
    view_offer: 'View offer',
    blueprint_available_in_pack_popup:
        'This blueprint is available as part of a premium pack.',
    blueprint_available_in_pack_card: 'Available in {0}',
    customization_purchase: 'Customization purchases',
    bundles_left: '{0} bundles left',
    refill_guild_resource: 'Refill x{0}',
    guild_coins_tooltip_desc:
        'Guild coins are earned by helping your guild. They can be spent on resource packs or Grab Bags.',
    cityboard_currency_tooltip_desc:
        'Renown is earned by the guild every week, depending on the Weekly Trophy Progress. It can be spent by officers to purchase powerful perks for the whole guild.',
    trade_tab_guild: 'Guild Requests',
    guild_request: 'Guild Request',
    trade_mine_offer: "You can't purchase your own offer!",
    trade_mine_request: "You can't fulfill your own request!",
    fulfill_request: 'Fulfill Request',
    trade_create_duration_title: 'Duration',
    trade_create_enchantment_title: 'Enchantments',
    no_element: 'No Element',
    no_spirit: 'No Spirit',
    bountyboard_title: 'Bounty Board',
    bountyboard_timeleft:
        '<color=#843D65FF>Week ends in:</color><sprite=17>{0}',
    bountyboard_weekly_level: 'Weekly Renown <color=#FCD01CFF>Lv.{0}',
    bountyboard_renown_progress: '{0}<color=#10F7FAFF>/{1}',
    bountyboard_bounty_title: 'Bounty',
    bountyboard_bounty_title_premium: "King's Bounty",
    bountyboard_bounty_craft_short: 'Craft {0}',
    bountyboard_bounty_craft_specific: 'Craft {0} {1}',
    bountyboard_bounty_slay_short: 'Raid Dungeon',
    bountyboard_bounty_slay_specific: 'Clear {0} {1} quests.',
    bountyboard_bounty_quest_short: 'Collect Components',
    bountyboard_bounty_quest_specific: 'Collect {0} {1}.',
    bountyboard_bounty_surcharge_short: 'Surcharge Items',
    bountyboard_bounty_surcharge_specific:
        'Surcharge {0} items of Tier {1} or above.',
    bountyboard_bounty_discount_short: 'Discount Items',
    bountyboard_bounty_discount_specific:
        'Discount {0} items of Tier {1} or above.',
    bountyboard_bounty_chest_short: 'Open Chests',
    bountyboard_bounty_chest_specific: 'Open {0} {1} with gems.',
    bountyboard_bounty_upgrade_short: 'Upgrade Item Quality',
    bountyboard_bounty_upgrade_specific:
        'Upgrade the quality of {0} items with gems.',
    bountyboard_bounty_rushitem_short: 'Speed Up Items',
    bountyboard_bounty_rushitem_specific:
        'Speed up {0} items of Tier {1} or above with gems.',
    bountyboard_bounty_rushupgrade_short: 'Help Guildmates',
    bountyboard_bounty_rushupgrade_specific:
        'Rush {0} guildmate upgrades with gems.',
    bountyboard_difficulty_1: 'Easy',
    bountyboard_difficulty_2: 'Med',
    bountyboard_difficulty_3: 'Hard',
    bountyboard_accept: 'Accept',
    bountyboard_abandon: 'Abandon',
    bountyboard_timelimit: 'Time Limit: <sprite=17> {0}',
    bountyboard_reward_title: 'Bounty Reward',
    bountyboard_unlock_info:
        'Unlock more bounty slots by purchasing Guild Perks!',
    bountyboard_tooltip_owner: 'Bounty Owner',
    goals_title: 'Goals',
    goals_tasks_tab: 'Tasks',
    goals_bounty_tab: 'Bounty',
    goals_tickets_title: 'Spin Tickets',
    goals_visit_spin_title_normal: 'Go To',
    goals_visit_spin_title_banned: 'Go To',
    goals_visit_spin_desc_normal: 'Fortune Zone',
    goals_visit_spin_desc_banned: 'Fortune Zone',
    goals_board_title: 'Go To',
    goals_board_desc: 'Bounty Board',
    goals_bounty_reward_title: 'Bounty Reward',
    goals_bounty_rating: 'Bounty Rating',
    goals_bounty_unactive_title: 'No active Bounty',
    goals_bounty_unactive_desc:
        'Take on a Bounty to help your guild and receive Guild Coins!',
    goals_abandon: 'Abandon',
    goals_abandon_confirm_title: 'Abandon Bounty',
    goals_abandon_confirm_desc:
        'Are you sure you want to abandon this task?',
    goals_bounty_claim_call_to_action: 'CLAIM REWARD!',
    goals_bounty_abandon_cooldown:
        'You will be able to abandon again in {0}.',
    trade_guild_tab_bottom_desc:
        'Fulfill the Requests of your Guildmates<br>({0} Active Listings)',
    trade_detail_request_by: 'Requested by:',
    offer_help_tab_timers: 'Timers',
    offer_help_tab_requests: 'Requests ({0})',
    trade_create_type: 'Select Item To',
    trade_create_title: 'Create Listing',
    trade_create_offer_title: 'Create Offer',
    trade_create_offer_desc:
        'List items on the market for other players to purchase.',
    trade_create_request_title: 'Create Request',
    trade_create_request_desc:
        'Request items on the market to be fulfilled by anyone.',
    trade_create_guild_request_title: 'Create Guild Request',
    trade_create_guild_request_desc:
        'Request items from your guild. (1 listing max)',
    ranking_global: 'Global',
    ranking_country: 'National',
    ranking_region: 'Regional',
    quest_setup_difficulty: 'Difficulty {0}',
    keybearer_appears_in: 'Keybearer invades in',
    keybearer_remaining_clears: '{0} Quests',
    keybearer_available: 'Keybearer Available',
    completed_all_keybearers: 'All Keybearers defeated!',
    not_enough_guild_coins: "You don't have enough Guild Coins.",
    board_info_trophies_title: 'Trophies',
    board_info_trophies_desc: 'Earn Trophies by completing bounties!',
    board_info_weekly_renown_levels_title: 'Weekly Trophy Progress',
    board_info_weekly_renown_levels_desc:
        'Trophies collected by the guild increase its Weekly Trophy Progress.',
    board_info_weekly_renown_rewards_title: 'Earning Renown',
    board_info_weekly_renown_rewards_desc:
        'Every week, the guild earns Renown based on its Weekly Trophy Progress.',
    board_info_guild_upgrades_boosters_title: 'Guild Perks & Boosts',
    board_info_guild_upgrades_boosters_desc:
        'With Renown, officers can purchase powerful boosts and perks for the whole guild!',
    board_rewards_level: 'Level {0}',
    board_rewards_status_passed: 'Passed',
    board_rewards_status_next_reward: 'Next Reward',
    board_rewards_status_current_reward: 'Current Reward',
    board_info_title: 'Weekly Renown',
    gcoins_received: 'BOUNTY COMPLETE!',
    gcoins_received_description: '{0} Guild Coins received!',
    board_endofweek_title: 'WEEKLY RENOWN',
    board_endofweek_desc: 'This week, your guild reached Renown Level',
    board_endofweek_mvp_title: 'MVP',
    board_endofweek_contributors_title: 'All Contributors',
    board_endofweek_resetinfo_title: 'Weekly Progress Reset!',
    board_endofweek_resetinfo_desc:
        "Complete Bounties to contribute to next week's Renown Level!",
    city_board_accept_error_already_taken:
        'This bounty has already been claimed by someone else.',
    city_search_enter_guild_name:
        "Enter a Guild's name to see results<br>(3 characters minimum)",
    city_search_enter_player_name:
        "Enter a Player's name to see results<br>(3 characters minimum)",
    event_ends_in_format: 'Event ends in: <sprite name=Timer> {0}',
    event_ends_in: 'Event ends in',
    only_one_guild_trade: 'You can only have one guild request.',
    bountyboard: 'Bounties',
    cityupgrades: 'Guild Perks',
    luckybox: 'Grab Bag',
    guild_chest_title: 'Bonus Grab Bag',
    guild_chest_desc: 'Choose a Grab Bag to receive a prize!',
    open_guild_chest_title: 'Open Grab Bag',
    guild_box_claim_text: 'Select a Grab Bag',
    next_reward: 'Next Reward',
    quest_event_popup_progress_title: 'Guild Event Rewards',
    quest_event_popup_progress_description:
        'Work together with your guild to gather Pure Gold and earn fabulous prizes!',
    event_ended: 'This event has ended.',
    goldcity_info_header1: 'Limited Time Event',
    goldcity_info_body1:
        'The mysterious Lost City of Gold has appeared! King Reinhold sponsors all guilds to plunder its riches!',
    goldcity_info_header2: 'Pure Gold',
    goldcity_info_body2:
        "Collect Pure Gold by defeating the Stone Sentinel and add them to your guild's total!",
    goldcity_info_header3: 'Exclusive Rewards',
    goldcity_info_body3:
        'Pure Gold gathered by the guild unlock rewards for everybody!',
    view_reward: 'View Rewards',
    contributors: 'Contributors',
    city_perks_title: 'Guild Perks',
    city_perks_upgrades: 'Guild Perks',
    city_perks_boosts: 'Guild Boosts',
    city_perks_upgrade_info: 'Tap for more info',
    city_perks_required_level: 'at Guild Level {0}',
    city_perks_next: 'Next upgrade {0}',
    city_upgrade_level_title: 'Guild Level',
    city_upgrade_level_desc: 'A high guild level unlocks more perks',
    city_upgrade_storage_title: 'Storage Capacity',
    city_upgrade_storage_desc: 'Trunks gain +{0} storage capacity',
    city_upgrade_stacksize_title: 'Component Stack Size',
    city_upgrade_stacksize_desc: 'Trunks gain +{0} stack size',
    city_upgrade_vertrack_title: 'Weapon Rack Energy',
    city_upgrade_vertrack_desc: 'Weapon Racks gain +{0} Energy',
    city_upgrade_mannequin_title: 'Mannequin Energy',
    city_upgrade_mannequin_desc: 'Mannequins gain +{0} Energy',
    city_upgrade_table_title: 'Table Energy',
    city_upgrade_table_desc: 'Tables gain +{0} Energy',
    city_upgrade_shelves_title: 'Shelf Energy',
    city_upgrade_shelves_desc: 'Shelves gain +{0} Energy',
    city_upgrade_commonres_title: 'Common Resource Capacity',
    city_upgrade_commonres_desc: 'Bins gain +{0} capacity',
    city_upgrade_uncommonres_title: 'Uncommon Resource Capacity',
    city_upgrade_uncommonres_desc: 'Bins gain +{0} capacity',
    city_upgrade_rareres_title: 'Rare Resource Capacity',
    city_upgrade_rareres_desc: 'Bins gain +{0} capacity',
    city_upgrade_guildchoice_title: "Guild's Choice Cooldown",
    city_upgrade_guildchoice_desc: 'Cooldown -{0} minutes',
    city_upgrade_bountyboard_title: 'Bounty Board Capacity',
    city_upgrade_bountyboard_desc: '+{0} bounties available',
    city_upgrade_craftboost_title: 'Craft Boost',
    city_upgrade_questboost_title: 'Quest Boost',
    city_upgrade_resboost_title: 'Resource Boost',
    city_upgrade_xpboost_title: 'XP Boost',
    city_upgrade_boost_cost_desc: 'Activation cost -{0}',
    city_upgrade_boost_duration_desc: 'Duration +{0} minutes',
    city_upgrade_boost_unlock_desc: 'Unlocks boost level {0}',
    city_upgrade_tradeofferstack_title: 'Market Offer Increase',
    city_upgrade_tradeofferstack_desc: 'Market offer stack size +{0}',
    city_upgrade_traderequeststack_title: 'Market Request Increase',
    city_upgrade_traderequeststack_desc: 'Market request stack size +{0}',
    city_upgrade_commonresregen_title: 'Common Resource Regen',
    city_upgrade_commonresregen_desc: 'Common resources regen +{0}',
    city_upgrade_uncommonresregen_title: 'Uncommon Resource Regen',
    city_upgrade_uncommonresregen_desc: 'Uncommon resources regen +{0}',
    city_upgrade_rareresregen_title: 'Rare Resource Regen',
    city_upgrade_rareresregen_desc: 'Rare resources regen +{0}',
    city_upgrade_coinrefillres_title: 'Resource Pack Capacity',
    city_upgrade_coinrefillres_desc: 'Daily resource packs +{0}',
    city_boost_craftboost_title: 'Craft Boost',
    city_boost_craftboost_desc: '+{0} craft speed',
    city_boost_questboost_title: 'Quest Boost',
    city_boost_questboost_desc: '+{0} quest and resting speed',
    city_boost_resboost_title: 'Resource Boost',
    city_boost_resboost_desc: '+{0} resource regeneration',
    city_boost_xpboost_title: 'Xp Boost',
    city_boost_xpboost_desc: '+{0} bonus to all XP earned',
    bounty_board_unlocked_title: 'Bounty Board',
    bounty_board_unlocked_desc:
        "You've unlocked the Bounty Board! Contribute to your guild by completing bounties!",
    quest_event_user_progress_title: 'Personal Contribution Progress',
    quest_event_user_progress_description:
        'This reward has a minimum contribution level before it can be claimed.',
    your_progress: 'Your progress :',
    city_upgrade_error_member_rank:
        'Only officers and guildmasters can purchase guild perks.',
    city_boost_error_member_rank:
        'Only officers and guildmasters can purchase guild perks.',
    city_search_create_gold: 'Create with Gold',
    city_search_create_gems: 'Create with Gems',
    purchase_purchased: 'Purchased!',
    ranking_quest_event: 'Event Score',
    notification_bounty_accept: 'accepted a bounty.',
    notification_bounty_abandon: 'abandoned a bounty.',
    grand_prize: 'Grand Prize',
    send_heroes: 'Send Heroes',
    next_keybearer: 'Next Keybearer',
    goldcity_description:
        'This time-limited dungeon offers incredible rewards to guilds that can challenge its guardian... including an exclusive blueprint!',
    needed_format: '{0} needed',
    ignore_invites_title: 'Ignore Invites',
    ignore_invites_accept_title: 'Enable Invites',
    ignore_invites_description:
        'Are you sure? All future guild invitations you receive will be ignored automatically.',
    ignore_invites_accept_description:
        'Guild invitations will now be sent to this mailbox.',
    quest_event_guild_change_warning:
        'You will lose any {0} you have contributed during this event. Are you sure you wish to leave this guild?',
    bounty_celebration_popup_description: 'For completion, you gained',
    bounty_celebration_popup_level_maxed: 'MAXED',
    notification_bounty_accept_overlay: 'Bounty accepted!',
    fetch_city_invalid_version:
        "This city cannot be viewed because its owner's Shop Titans app is not up to date.",
    furniture_received: 'Furniture Received!',
    city_details_change_settings: 'Change Settings',
    quest_event_no_rankings:
        'Your guild needs to participate in the event in order to view the leaderboards.',
    visit_guild: 'Visit Guild',
    current_guild: 'Current Guild',
    guild_info: 'Guild Info',
    goals_bounty_locked_desc:
        'You need to be level {0} to access the Bounty Board.',
    guild_perk_upgrades_desc:
        'Permanent upgrades that affect all members of the guild.',
    guild_perk_boost_desc:
        'Powerful, temporary boosts for the whole guild.',
    join_guild_low_level_error:
        'You need to be level {0} to join the {1} guild.',
    guild_box_open_celebration_title: '{0} Bag Prize',
    luckybox_blue: 'Blue',
    luckybox_green: 'Green',
    luckybox_red: 'Red',
    event_visitorsellxp_name: 'Merchant XP+',
    push_event_visitorsellxp_name: 'Merchant XP Event',
    event_visitorsellenergy_name: 'Energy Bonus',
    push_event_visitorsellenergy_name: 'Bonus Energy Event',
    event_visitorsurchargeprice_name: 'Better Deals',
    push_event_visitorsurchargeprice_name: 'Surcharge Event',
    event_visitorsurchargeenergy_name: 'Haggle',
    push_event_visitorsurchargeenergy_name: 'Haggle Event',
    event_crafttime_name: 'Fast Crafts',
    push_event_crafttime_name: 'Craft Speed Event',
    event_craftquality_name: 'Top Quality',
    push_event_craftquality_name: 'Quality Bonus Event',
    event_questtime_name: 'Fast Quests',
    push_event_questtime_name: 'Faster Quest Event',
    event_questxp_name: 'Quest XP+',
    push_event_questxp_name: 'Quest XP Bonus Event',
    event_questcomponents_name: 'Loot Frenzy',
    push_event_questcomponents_name: 'Bonus Loot Event',
    event_adventurerxp_name: 'Hero XP+',
    push_event_adventurerxp_name: 'Bonus Hero XP Event',
    event_adventurerrest_name: 'Fast Rest',
    push_event_adventurerrest_name: 'Faster Rest Event',
    event_resourcesregen_name: 'Resource Fest',
    push_event_resourcesregen_name: 'Resource Production Event',
    event_visitorsellxp_desc:
        'For a limited time, earn bonus XP from sales! Bring in the customers!',
    event_visitorsellenergy_desc:
        'For a limited time, gain extra Energy from sales! Time to haggle!',
    event_visitorsurchargeprice_desc:
        'For a limited time, surcharged items are worth more gold! Spend that Energy!',
    event_visitorsurchargeenergy_desc:
        'For a limited time, surcharging item costs less Energy! Drive a hard bargain!',
    event_crafttime_desc:
        'For a limited time, all items craft much faster! Fill up those racks!',
    event_craftquality_desc:
        'For a limited time, all items are more likely to be of a higher quality! Legendary is no longer a dream!',
    event_questtime_desc:
        'For a limited time, all quests complete much faster! Raid the dungeons!',
    event_questxp_desc:
        'For a limited time, all quests gain more quest XP! Time to grind!',
    event_questcomponents_desc:
        "For a limited time, all quests drop more components! It's a loot explosion!",
    event_adventurerxp_desc:
        'For a limited time, Heroes and Champions gain more XP! To the dungeons!',
    event_adventurerrest_desc:
        'For a limited time, Heroes and Champions rest faster. Quit slacking!',
    event_resourcesregen_desc:
        'For a limited time, all resources refill faster! Are your bins ready?',
    wall_found_in_chest_popup: 'This wall can be found in {0}',
    floor_found_in_chest_popup: 'This floor can be found in {0}',
    wall_available_in_offer_popup: 'This wall is available in the {0}',
    floor_available_in_offer_popup: 'This floor is available in the {0}',
    adjust_zoom: 'Zoom',
    vip_loyalty_title_nosub: 'Subscribe to become an official',
    vip_loyalty_title_nosub2: 'ROYAL MERCHANT',
    vip_loyalty_title_sub: 'Your benefits as a',
    vip_loyalty_title_sub2: 'ROYAL MERCHANT',
    vip_loyalty_perk_title: 'Monthly Perks',
    vip_loyalty_locked_title:
        'The longer you subscribe, the more rewards you unlock!',
    vip_loyalty_locked_more: 'More Coming!',
    vip_loyalty_all_unlocked: 'All the loyalty rewards have been unlocked!',
    vip_loyalty_next_unlock_title:
        '<material="Curse Casual SDF White"><color=#F9DB64FF>Next reward in</color></material> <sprite=17>{0}',
    vip_loyalty_repairgold: 'Repair Discount',
    vip_loyalty_repairgold_format:
        '<color=#68EE2DFF>-{0}</color> Gold repair discount',
    vip_loyalty_repairgold_format_no_color: '-{0} Gold repair discount',
    vip_loyalty_months: '<color=#FDCF00FF>{0}</color> months',
    vip_loyalty_detail_title: 'Loyalty Rewards',
    vip_loyalty_reward_title: 'ROYAL REWARD',
    vip_loyalty_reward_reward_title: 'You got:',
    guild_perks_details_init_bountyboard_desc: 'Bounty Board slots',
    guild_perks_details_init_guildchoice_desc:
        'Cooldown reduction (minutes)',
    guild_perks_details_init_commonres_desc: 'Increased capacity per bin',
    guild_perks_details_init_uncommonres_desc: 'Increased capacity per bin',
    guild_perks_details_init_rareres_desc: 'Increased capacity per bin',
    guild_perks_details_init_vertrack_desc: 'Energy per rack',
    guild_perks_details_init_mannequin_desc: 'Energy per rack',
    guild_perks_details_init_table_desc: 'Energy per rack',
    guild_perks_details_init_shelves_desc: 'Energy per rack',
    guild_perks_details_init_storage_desc: 'Storage per Trunk',
    guild_perks_details_init_stacksize_desc: 'Stack Size per Trunk',
    guild_perks_details_permanent_upgrade: 'Permanent Upgrade',
    guild_perks_details_craftboost_subtitle: 'Temporary Craft Speed Bonus',
    guild_perks_details_questboost_subtitle:
        'Temporary Quest & Rest Speed Bonus',
    guild_perks_details_resboost_subtitle: 'Temporary Resource Regen Bonus',
    guild_perks_details_xpboost_subtitle:
        'Temporary XP Bonus (Merchant, Worker and Hero)',
    guild_perks_details_max_boost: 'Max. Boost Level',
    guild_perks_details_max_duration: 'Boost Duration',
    guild_perks_details_cannot_spend_renown:
        'Only officers and guildmasters can spend Renown.',
    guild_perks_details_unlock: 'Unlock',
    guild_perks_details_upgrade: 'Upgrade',
    guild_perks_details_unlocks_at: 'Unlocks at',
    guild_perks_details_next_perk_available:
        'Next Perk available at Level {0}',
    visitor_bubble_ad_top: 'Owen has a special offer!',
    upgrade_ad_skip: 'Watch an ad!',
    upgrade_ad_skip_vip: 'Click to Skip',
    upgrade_ad_skip_time_format: 'Skip: {0}',
    free_gift:
        '<material="Curse Casual SDF Gold Outline Darker Brown">FREE!',
    accept: 'Accept',
    visitor_worker_seller_gift_format: '{0} has a gift for you!',
    invalid_city_name:
        'Guild name must have between {0} and {1} characters',
    watch_ad: 'Watch Ad',
    watch_ad_vip: 'Claim',
    guild_settings: 'Guild Settings',
    remove_items: 'Remove Item',
    item_type_component: 'Component',
    city_trade_temporarily_disabled:
        'Guild Requests for components and special items are temporarily unavailable.',
    hero_detail_player_profile_button:
        "{0}'s<br><size=60>Player Profile</size>",
    hero_detail_user_s_hero: "{0}'s Hero",
    quest_normal_timer_tooltip:
        'The amount of time this quest will take. Sending more heroes on the quest will make it faster.',
    quest_boss_timer_tooltip:
        'The amount of time this boss fight will take. Sending more heroes on the quest will make it faster.',
    trade_request_overmaxstack:
        "You can't hold more than {0} of that item.",
    profanity_detected: 'Name contains inappropriate language.',
    profanity_pending_detection: 'Communicating with server...',
    notification_new_bounties: 'New Bounties<br>available!',
    goldcity_ascension_gp_title: 'Grand Prize',
    goldcity_ascension_gp_exclusive_desc:
        'Reach tier <color=#FFD301FF>{0}</color> to get this exclusive {1}!',
    goldcity_ascension_gp_alternate_desc:
        'Reach tier <color=#FFD301FF>{0}</color> to get this Prize!',
    goldcity_ascension_gp_claimed: 'Reward claimed!',
    goals_bounty_rush_bounty: 'Rush Bounty',
    bounty_board_your_bounty: 'Your current bounty',
    settings_menu_advertisements: 'Show Ad opportunities',
    login_event_claim_tap_text: 'Tap to open the gift!',
    login_event_lunar_title: 'Lunar New Year Login Calendar',
    login_event_lunar_description:
        'Login every day from <color=#F7C514FF>{0}</color> until <color=#F7C514FF>{1}</color> to receive gifts!',
    month_format: '{0} {1}',
    month_abv_1: 'Jan',
    month_abv_2: 'Feb',
    month_abv_3: 'Mar',
    month_abv_4: 'Apr',
    month_abv_5: 'May',
    month_abv_6: 'Jun',
    month_abv_7: 'Jul',
    month_abv_8: 'Aug',
    month_abv_9: 'Sept',
    month_abv_10: 'Oct',
    month_abv_11: 'Nov',
    month_abv_12: 'Dec',
    month_1: 'January',
    month_2: 'February',
    month_3: 'March',
    month_4: 'April',
    month_5: 'May',
    month_6: 'June',
    month_7: 'July',
    month_8: 'August',
    month_9: 'September',
    month_10: 'October',
    month_11: 'November',
    month_12: 'December',
    notification_trade_create:
        'is requesting:<color={0}>{1} {2}</color> (x{3})',
    notification_trade_fulfilled: "has fulfilled {0}'s request!",
    notification_trade_canceled: 'canceled their request.',
    player_level_up_gems: 'Gems x{0}',
    player_level_up_tradetier: 'Market Tier',
    player_level_up_class: 'New Classes',
    player_level_up_hero: 'New Champion',
    player_level_up_location: 'New Location',
    player_level_up_quest: 'New Quest Slot',
    player_level_up_craft: 'New Craft Slot',
    player_level_up_adventurer: 'New Hero Slot',
    player_level_up_upgrade: 'New Upgrade Slot',
    player_level_up_trade: 'New Trade Slot',
    player_level_up_expansion: 'New Expansion',
    player_level_up_worker: 'Worker Available',
    player_level_up_furniture: 'New Furniture',
    player_level_up_decoration: 'New Decoration(s)',
    player_level_up_detail_gems_title: 'Bonus Gems',
    player_level_up_detail_basementexpansion_title: 'New Basement Space',
    player_level_up_detail_basementexpansion_desc:
        'A new basement space can now be unlocked with <sprite=2><color=#F7C608FF>Gold</color>!',
    player_level_up_detail_gems_desc: 'You got some <sprite=1>Gems!',
    player_level_up_detail_tradetier_title: 'New Market Tier Unlocked',
    player_level_up_detail_tradetier_desc:
        'A new tier of items is now available on the Market!',
    player_level_up_detail_class_title: 'New Hero Classes Available',
    player_level_up_detail_class_desc:
        'New Hero classes are now available for you to hire with <sprite=2><color=#F7C608FF>Gold</color>!',
    player_level_up_detail_hero_title: 'New Champion Available',
    player_level_up_detail_hero_desc:
        'A new Champion may visit your shop soon...',
    player_level_up_detail_location_title: 'New Quest Location Available',
    player_level_up_detail_location_desc:
        'A new Quest location can now be unlocked with <sprite=2><color=#F7C608FF>Gold</color>!',
    player_level_up_detail_quest_title: 'New Quest Slot Available',
    player_level_up_detail_quest_desc:
        'An extra Quest Slot can now be unlocked with <sprite=2><color=#F7C608FF>Gold</color>!',
    player_level_up_detail_craft_title: 'New Craft Slot Available',
    player_level_up_detail_craft_desc:
        'An extra Craft Slot can now be unlocked with <sprite=2><color=#F7C608FF>Gold</color>!',
    player_level_up_detail_adventurer_title: 'New Hero Slot Available',
    player_level_up_detail_adventurer_desc:
        'An extra Hero Slot can now be unlocked with <sprite=2><color=#F7C608FF>Gold</color>!',
    player_level_up_detail_upgrade_title: 'New Upgrade Slot Available',
    player_level_up_detail_upgrade_desc:
        'A new upgrade Slot can now be unlocked with <sprite=2><color=#F7C608FF>Gold</color>!',
    player_level_up_detail_trade_title: 'New Trade Slot Available',
    player_level_up_detail_trade_desc:
        'An extra Trade Slot can now be unlocked with <sprite=2><color=#F7C608FF>Gold</color>!',
    player_level_up_detail_expansion_title: 'New Expansion Level Available',
    player_level_up_detail_expansion_desc:
        'A new Expansion Level can now be unlocked with <sprite=2><color=#F7C608FF>Gold</color>!',
    player_level_up_detail_worker_title: 'New Worker Available',
    player_level_up_detail_worker_desc:
        'These workers can now be hired with <sprite=2><color=#F7C608FF>Gold</color>!',
    player_level_up_detail_furniture_title: 'New Furniture Available',
    player_level_up_detail_furniture_desc:
        'New Furniture are now available for purchase!',
    player_level_up_detail_decoration_title:
        'New Shop Customizations Available',
    player_level_up_detail_decoration_desc:
        'New Shop Customizations are now available for purchase!',
    worker_special_academy_offer:
        '<color=#{0}>+{1}</color> Merchant XP earned',
    worker_special_engineer_offer:
        '<color=#{0}>-{1}</color> Item Break Chance',
    guild_create_tab_featured: 'Featured',
    guild_create_tab_guilds: 'Guilds',
    guild_create_tab_players: 'Players',
    guild_info_tab_summary: 'Summary',
    guild_info_tab_buildings: 'Buildings',
    celebration_furniture_space_unlocked: 'Furniture Space Unlocked!',
    city_friendship_title: 'Guild Friendship',
    city_friendship_info_desc:
        'Increase your <color=#FFCC00FF>Friendship Level</color> with each Guildmate by helping them out! When they visit your shop, they will give you more <size=+20><voffset=-10><sprite name="Energy"></voffset></size><color=#FFCC00FF>Energy</color> if you are good friends. ',
    city_friendship_info_desc2:
        '<size=+20><voffset=-10><sprite name="Energy"></voffset></size><color=#FFCC00FF>+5 Energy</color> for each friendship level.',
    city_friendship_friendships_desc:
        'Helping out your guildmates will improve your <size=+20><voffset=-10><sprite name="Friendship"></voffset></size><color=#FFCC00FF>Friendship Level</color> with them.',
    city_friendship_bonuses_desc:
        'Good <size=+20><voffset=-10><sprite name="Friendship"></voffset></size><color=#FFCC00FF>Friends</color> will give you more <size=+20><voffset=-10><sprite name="Energy"></voffset></size><color=#FFCC00FF> Energy</color> when they help you out!',
    city_friendship_tab_friendships: 'Friendships',
    city_friendship_tab_bonuses: 'Bonuses',
    city_friendship_guildmate: 'Guildmates',
    city_friendship_acquaintance: 'Acquaintances',
    city_friendship_friend: 'Friends',
    city_friendship_bestbud: 'Best Buds',
    city_friendship_bff: 'BFFs',
    city_friendship_gift_info:
        'Choose a guildmate to receive your <color=#{0}>gift!</color>',
    city_help_complete_to_receive: 'Complete to receive',
    friendship_energy_received: 'Energy Received',
    furniture_upgrade_bottom_text_visiting:
        "{0}% discount when completing a Guildmate's upgrade",
    visitor_bubble_giftavatar: '{0} has a gift for you!',
    visitor_bubble_giftavatar_title: 'Gift',
    visitor_bubble_giftavatar_description: 'FREE!',
    visitor_bubble_cityfriend: '{0} wants to help with cleaning!',
    visitor_bubble_cityfriend_title: 'Furniture dusting',
    visitor_bubble_cityfriend_description: 'FREE!',
    bounty_complete: 'COMPLETE!',
    complete_expansion: 'Complete expansion',
    refuse_visitor_giftavatar_warning_body:
        'The gift will be lost. Are you sure?',
    free_hire: 'Free Hire!',
    free_hire_format: 'Hire a {0} for free!',
    gift_claim_tap_text: 'Tap to open the gift!',
    no_city_friendship: "You don't have any friends :(",
    quest_selector_title: 'Quests',
    fight_boss_bottom_text_card: 'Fight the Boss!',
    unlock_area_bottom_text_card: 'Unlock Area',
    quest_progress_bottom_desc:
        'Level up your Quest locations to unlock upgrades!',
    quest_unlock_requirement_desc: 'Clear {0}<br>{1} times',
    top_bounty: 'Bounties',
    blueprint_mastered: 'Blueprint Mastered!',
    fill_trade_shortcut_name: 'Fill From Market',
    fill_trade_shortcut_desc: 'Tap to view',
    trade_rack_empty: 'No Trades',
    invalid_adventurer_count: 'You are missing Heroes to start this Quest',
    notification_bounty_complete: 'has completed a Bounty!',
    notification_friend_levelup: 'and {0} are now {1}',
    last_chance: 'Last Chance!',
    quest_setup_boss_key_drop: 'May drop  <color=#FFB72BFF>{0}</color>.',
    power_format_hero_current:
        '<material="Curse Casual SDF White"><color=#9C577BFF>+{0}</color></material>',
    rank_format_hero_max:
        '<material="Curse Casual SDF Yellow Gradient Outline Black"><color=#FFFFFF>{0}</color></material>',
    power_level_up_title: 'Power Up!',
    power_level_up_desc: '{0} got a power up!',
    hero_power_upgrade_desc: 'Empower your Champions beyond their limits!',
    power_up: 'Power Up',
    next_power_up: 'Next power up',
    max_power_up: 'Max Power Up',
    boss_area_unlocked: 'Boss Area Unlocked!',
    quest_consumables_selector_title: 'Select Booster',
    enter_email_title: 'Enter email',
    enter_email_description:
        'You will receive a confirmation email as soon as its ready!',
    email_example_placeholder: 'example@example.com',
    generic_send: 'Send',
    enter_valid_email: 'Please enter a valid email',
    news_capture_email_update: 'Update email',
    news_capture_email_register: 'Register now!',
    news_kind_capture_email: 'News',
    blueprint_unlock_require_worker:
        'You must hire the {0} to unlock this blueprint',
    view_worker: 'View Worker',
    hire_hero: 'Hire Hero',
    heal_all: 'Heal All',
    change_name: 'Change Username',
    enter_new_name: 'Enter New Name',
    change_name_popup_middle_text:
        'You may change your username once for free.<br>Subsequent changes will cost 50 Gems each.',
    friendship_increased: 'Friendship Increased!',
    go_to_city: 'Go to City',
    you_have_received: 'You have received:',
    rewards_received: 'Rewards Received',
    free_username_change_button_text_format: 'Free ({0} left)',
    rename_banned_notif:
        'You have been banned from changing your name. (Ban duration: {0})',
    chest_open: 'Open Chest',
    visitor_bubble_churn: 'Owen has a gift for you!',
    visitor_bubble_churn_title: 'Chest and Key',
    visitor_bubble_churn_description: 'FREE!',
    refuse_visitor_churn_warning_body:
        'The Chest and Key will be lost. Are you sure?',
    link_accounts: 'Get a Shop Titans ID!',
    link_account_normal_desc:
        "If you want to play on multiple devices or would like to transfer your account to a new device, you'll need to use a Shop Titans ID! Choose one of the following options.",
    link_account_from_device_desc:
        'Copy your unique Shop Titans ID to transfer this account to another device.',
    link_account_to_device_desc:
        'Paste your unique Shop Titans ID to play on this device.',
    link_from_device: 'ID from this device',
    link_to_device: 'ID to this device',
    copy_link: 'Copy your ID',
    enter_link: 'Enter your ID',
    error_server_busy:
        'The server was too busy to handle your request. Please try again.',
    error_same_platform:
        'You are already log in to your Shop Titans ID on this platform.',
    error_invalid_link: 'This ID has expired or is invalid.',
    quantity_format_max: '<color=#69FE22FF>{0}</color>',
    craft_missing_item_qty_format:
        '{0}<voffset=-2><size=-15><material=Curse Casual SDF White><color={2}> / {1}</size></color></font></voffset>',
    hero_xp_bonus: 'Hero XP +{0}%',
    blueprint_line_crossbows: 'Crossbows',
    blueprint_line_guns: 'Guns',
    blueprint_line_wands: 'Wands',
    blueprint_line_runestones: 'Runestones',
    name_empty: 'Please enter a name.',
    chest_guaranteed_reward:
        '<sprite name="Star">/<sprite name="Star2"> Guaranteed!',
    chest_extra_reward: '<color=#69FE22FF>+1</color> Extra Reward',
    area_not_unlocked: 'Quest locked',
    capture_email_registered: 'Registration complete!',
    less_than_one_percent: 'Less than 1%',
    quest_boss_loot_title: 'Quest Loot',
    hud_size_format: 'HUD Size: {0}',
    hud_size_preset_tiny: 'Tiny',
    hud_size_preset_verysmall: 'Very Small',
    hud_size_preset_small: 'Small',
    hud_size_preset_normal: 'Normal',
    hud_size_preset_large: 'Large',
    hud_size_preset_verylarge: 'Very Large',
    hud_size_preset_huge: 'Huge',
    enchantment_tier1_roman: 'I',
    enchantment_tier2_roman: 'II',
    enchantment_tier3_roman: 'III',
    'failed_identification ': 'Failed identification ',
    failed_identification_steam:
        'Failed identification. Make sure your Steam application is open.',
    trade_gems_locked_title: 'Achievement Required',
    trade_gems_locked_description:
        'Before using gems on the Market, you must first unlock this achievement.',
    trade_gems_disabled_untrusted_platform:
        'You will be able to use the Market with Gems in {0}',
    trade_gold_over_networth:
        'You need a Net Worth of at least {0} to trade this item. (Current Net Worth : {1})',
    error_link: 'Could not link account (Code might be invalid)',
    tap_continue_click: 'Click to continue',
    click_open_click: 'Click to open',
    tap_to_speedup_click: 'Click to speed up',
    tap_to_finish_click: 'Click to finish',
    tap_to_collect_click: 'Click to collect',
    city_search_item_action_click: 'Click to Join',
    tap_to_join_click: 'Click to join',
    tap_for_details_click: 'Click for details',
    hero_story_requirements_click: 'Click to contribute',
    special_card_desc_click: 'Click to heal',
    enchant_trade_shortcut_desc_click: 'Click to view',
    equip_trade_shortcut_desc_click: 'Click to view',
    claim_daily_bank_click: 'Click to COLLECT!',
    city_perks_upgrade_info_click: 'Click for more info',
    login_event_claim_tap_text_click: 'Click to open the gift!',
    gift_claim_tap_text_click: 'Click to open the gift!',
    fill_trade_shortcut_desc_click: 'Click to view',
    pet: 'Pet',
    purchase_daily_deal8_name: 'Special Offer',
    purchase_daily_deal8_desc: 'Limited Time Special Offer',
    pet_options: 'Pet Options',
    visitor_bubble_ad_description_item: 'Watch an ad to get {0}',
    visitor_bubble_ad_description_item_vip: 'Claim {0}!',
    visitor_bubble_ad_description_gems_vip: 'Claim free gems!',
    event_visitorfrenzy_name: 'Customer Frenzy',
    push_event_visitorfrenzy_name: 'Customer Frenzy Event',
    event_visitorfrenzy_desc:
        'For a limited time, customers swarm your shop! Everyone, pick a number!',
    event_multicraft_name: 'Multicraft Event',
    event_multicraft_desc:
        'For a limited time, all your crafts have a chance to get doubled!',
    push_event_multicraft_name: 'Multicraft Event',
    googleplay_subscription_info:
        'You may cancel your subscription at anytime through your <color=#F8DE4DFF>Google Play Settings</color>.',
    restock: 'Restock',
    city_friendship_dust_title_tap: 'Tap to dust off the furniture!',
    city_friendship_dust_title_click: 'Click to dust off the furniture!',
    city_friendship_dust_action: 'Help around the shop!',
    city_friendship_dust_title_pet_tap: 'Tap to pet!',
    city_friendship_dust_title_pet_click: 'Click to pet!',
    pethouse_bottom_text: 'Happy pets make for a lively shop!',
    pets: 'Pets',
    pets_slot_title: 'Adopt a Pet!',
    pets_slot_desc: 'Add a Pet<br>to your shop!',
    pets_slot_new_title: 'Add Pet Slot',
    pets_capacity_format: '+{0} Pet capacity',
    adopt: 'Adopt',
    unlock_pet_slot_title: 'Unlock Pet Slot?',
    unlock_pet_slot_desc:
        'Extra pet slots will allow you to display more pets in your shop.',
    hungry: 'Hungry',
    feed: 'Feed',
    green_text_format: '<color=#35FF5CFF>{0}</color>',
    pedigree: 'Pedigree',
    pedigree_tab_progression: 'Progression',
    pedigree_info_description:
        "Increasing <material=Curse Casual SDF Yellow Outline Black>{0}</material>'s level improves its Energy and Prestige bonus.",
    pedigree_info_max_prestige: 'Prestige',
    pedigree_info_max_energy: 'Energy Bonus',
    pedigree_info_hungry_in: 'Hungry in',
    remove_pet_button_title: 'Remove',
    place_pet_button_title: 'Place in Shop',
    remove_pet_title: 'Remove From Shop',
    remove_pet_desc:
        'Your pet will be removed from the shop, but can be added back at any time.',
    see_info: 'See Info',
    pet_hud_tooltip_desc:
        "Your pet's <color=#F1C602FF>Energy</color> bonus will be temporarily lowered if they are <color=#F1C602FF>hungry</color>.",
    pet_tutorial_rename_error: 'Invalid pet name.',
    pet_tutorial_invalid_pet_choice: 'This pet does not exist.',
    pet_tutorial_no_uid_error: 'Please select a pet.',
    pet_creation_purchase: 'Purchases',
    pet_creation_title: 'Customize',
    pet_creation_name_placeholder: 'Enter name',
    pet_creation_done: 'Create',
    pet_creation_apply: 'Apply',
    pet_creation_cancel: 'Cancel',
    pet_creation_tab_type: 'Species',
    pet_creation_tab_color: 'Appearance',
    pet_creation_unlock_gold: 'Unlock with Gold',
    pet_creation_unlock_gems: 'Unlock with Gems',
    pet_creation_error_no_specie:
        'This customization can only by unlocked if you own the {0}.',
    pet_creation_feed_unlock:
        'Feed this {0} <color=#F6D44AFF>{1}</color> times to unlock this appearance!',
    petname_error_message:
        "Your pet's name must have between 3 and 12 characters.",
    pet_unset_favorite: '{0} is no longer your main pet.',
    pet_set_favorite: '{0} set as main pet.',
    pet_slot_unlocks: 'Pet slot unlocks',
    unlock_at: 'Unlock at',
    login_event_anniversary_title: '2nd Year Anniversary Calendar',
    login_event_anniversary_description:
        'Shop Titans is celebrating its second anniversary! Log in every day for a new gift!',
    day: 'day',
    exit: 'Exit',
    pedigree_progression_description:
        'Feeding this type of animal will unlock new appearances!',
    change_need_space:
        'Not enough space to put new pet house. Please move the pet house to a bigger space.',
    pet_cant_buy: 'You cannot purchase this pet.',
    pet_customization_cant_buy: 'You cannot purchase this customization.',
    quest_event_tower_popup_progress_desc:
        'Send your strongest heroes to climb the <color=#EE65EDFF>{0}</color> and beat the Titan!',
    quest_event_tower_popup_progress_complete_desc:
        'You have successfully completed<br>the <color=#EE65EDFF>{0}</color>!',
    quest_event_tower_next_button_first_title: 'Climb to<br>first floor',
    quest_event_tower_next_button_title: 'Climb to<br>next floor',
    quest_event_tower_next_button_last_title: 'Climb to<br>last floor',
    quest_event_tower_tier_alpha: 'Alpha Tower',
    quest_event_tower_tier_beta: 'Beta Tower',
    quest_event_tower_tier_gamma: 'Gamma Tower',
    quest_event_tower_tier_delta: 'Delta Tower',
    quest_event_tower_tier_epsilon: 'Epsilon Tower',
    quest_event_tower_next_boss: 'Battles until next boss',
    reroll_with_item: 'Reroll with {0}',
    use_reroll_item_warning_title: '{0} Warning',
    use_reroll_item_warning_body:
        'This item can be used to reroll skills slots up to {0}. Are you sure you want to use it on a lower slot?',
    sitem_reroll_effect_desc: "Can be used to reroll a Hero's skill",
    quest_event_tower_reward_soul:
        'Reward: <color=#EDB803FF>Titan Soul</color>',
    quest_event_tower_titan_alpha: 'Alpha Titan',
    quest_event_tower_titan_beta: 'Beta Titan',
    quest_event_tower_titan_gamma: 'Gamma Titan',
    quest_event_tower_titan_delta: 'Delta Titan',
    quest_event_tower_titan_epsilon: 'Epsilon Titan',
    quest_event_climb_tower_floor: 'Climb to Floor {0}',
    quest_event_tower_completed: 'Completed!',
    quest_event_tower_completed_warning:
        'The Tower has been completed. Come back for the next event!',
    quest_event_tower_titan_description:
        "Defeat <color=#FFFFFFFF>{0}</color>'s Titan to receive a Titan Soul!",
    sitem_titansoul_effect_desc: "Promote a Hero's class",
    titantower_name: 'Tower Of Titans',
    titantower_description:
        'This time-limited dungeon is a test of strength for your strongest heroes. Reach the final floor and claim the incredible power within!',
    quest_event_tower_visit_tower: 'Visit Tower',
    class_upgrade: 'Class Promotion',
    adv_class_upgrade_desc: 'Congratulations! {0} was promoted to {1}!',
    new_skill_slot: 'New skill slot',
    yellow_gradient_text_format:
        '<material="Curse Casual SDF Yellow Gradient Outline Black">{0}</material>',
    titantower_info_header1: 'Limited Time Event',
    titantower_info_header2: 'Tons of rewards',
    titantower_info_header3: 'Titan Soul',
    titantower_info_body1:
        'Every so often, the mysterious Tower of Titans appears for all to explore, guarded by unique monsters.',
    titantower_info_body2:
        "Battle the tower's inhabitants and claim their treasure.",
    titantower_info_body3:
        'Defeat the mysterious Titan to receive a Titan Soul, which can be used to promote a single Hero!',
    ranking_quest_event_tower_floor: 'Floor {0}',
    titantower_boss_name_format: '{0} {1}',
    titantower_titan_name_format: "<{0}>'s {1}",
    quest_event_tower_monster_encounter: 'Monster encounter',
    no_low_class: 'No unpromoted Hero available',
    ranking_tower_0: 'Alpha',
    ranking_tower_1: 'Beta',
    ranking_tower_2: 'Gamma',
    ranking_tower_3: 'Delta',
    titan_vanquished: 'Titan Vanquished!',
    galaxyMX_paymentfail: 'Payments are not enabled.',
    quest_event_tower_rush_format: 'In progress<br><sprite name=Timer>{0}',
    quest_event_tower_complete_quest_button: 'Complete battle',
    quest_event_tower_floor_in_progress: 'Floor {0} in progress',
    galaxyMX_health: 'Please play responsibly and limit your time.',
    galaxyMX_healthtitle: 'Attention',
    craft_missing_requirements_generic:
        'You do not meet the requirements to craft this item.',
    sitem_titanspark_effect_desc:
        'Gives Titan Skill or rerolls existing one',
    no_titan: 'No upgraded hero available',
    skill_acquired: 'Skill acquired',
    skill_renewed: 'Skill renewed',
    points_short: 'pts',
    teach_skill: 'Teach skill',
    titan_skills: 'Titan skills',
    use_titan_spark_warning_title: 'Use {0}?',
    use_titan_spark_warning_body:
        'Are you sure you want to use the {0} on {1}?',
    notification_tower_boss_defeated: 'has defeated {0} ({1}) in the {2}!',
    notification_tower_titan_defeated: 'has defeated the {0}!',
    notification_pet_feed_title: 'Your pet is hungry!',
    notification_pet_feed_body: '{0} is waiting for a snack!',
    notification_pets_feed_title: 'Your pets are hungry!',
    notification_pets_feed_body: 'Your pets are hungry, come feed them!',
    already_asking: 'Item is already asked and cannot be suggested.',
    cannot_use_item:
        'Item cannot be used by this customer and therefore cannot be suggested.',
    item_locked: 'Item is locked and cannot be suggested.',
    skill_upgraded: 'Skill upgraded',
    tutorial_action_not_available:
        'This action is not available during the tutorial',
    kings_caprice_name: "King's Caprice",
    kings_caprice: "King's Caprice",
    quest_event_king_caprices: 'Caprices',
    quest_event_king_antiques: 'Antiques',
    quest_event_king_popup_progress_title: "The King's Caprice",
    quest_event_king_popup_progress_description:
        "Craft the King's requested items to gain <sprite=25><color=#FFDC00>Favor</color> and earn tons of rewards!",
    quest_event_king_start_crafting: 'Start Crafting',
    quest_event_king_popup_progress_buy: 'Buy Antiques',
    quest_event_king_craft_item: 'Craft <color=#FFDC00>{0}</color>.',
    quest_event_king_craft_item_type: 'Craft {0}.',
    quest_event_king_craft_item_category: 'Craft any type of {0}.',
    bonus_skill_stat: 'bonus from all items',
    quest_event_king_reroll_title: 'Reroll selection?',
    quest_event_king_reroll_description:
        'All of the current items available will be replaced by a new selection.',
    quest_event_king_items_reroll_in: 'New selection in',
    total_acquired: 'Total acquired:',
    all_acquired: 'All acquired',
    daily_freebie: "Daily King's Gift",
    daily_freebie_event_expired: 'Expired',
    not_enough_shards: 'You do not have enough Antique Tokens.',
    sitem_limitededition_effect_desc:
        'Use this special tag to surcharge customers a second time!',
    special_item_type_limitededition: 'Marketing Item',
    shard_shop: 'Antique Store',
    sort_event: 'Caprices',
    next_refresh_in: 'New item in:',
    expired_item: 'The item has expired.',
    expired: 'Expired',
    special_item_type_antiqueshard: 'Antique',
    event_collection_fall_title: 'Autumn Collection 2020',
    item_already_purchased: 'You have already purchased this item.',
    steam_chat_title: 'Steam Chat Group',
    steam_chat_optin_title: 'Opt-in',
    steam_chat_request_title: 'Request Invite',
    steam_chat_request_button: 'Request',
    steam_chat_optin_yes: 'Yes',
    steam_chat_optin_no: 'No',
    steam_chat_opt_in_gm: 'Only the Guild-Leader can Opt-in.',
    steam_chat_opt_in_title: 'Steam Chat Group Opt-In.',
    steam_chat_opt_in_description:
        "You can't leave the guild's Steam Chat Group once you have joined. Are you sure?",
    steam_chat_cannot_opt_out: 'You cannot opt-out',
    steam_chat_not_opt_in:
        'Your Guild-Leader first need to Opt-in before you can be invited.',
    steam_chat_server_optin_error:
        'An error occured, please try again later',
    steam_chat_server_optin_success:
        'You have successfully opted-in Steam Chat Group. All Steam members have been invited',
    steam_chat_server_request_error:
        'An error occured, please try again later',
    steam_chat_server_request_success:
        'You have been invited to the Steam Chat Group.',
    steam_chat_server_request_already_requested_once:
        'You have already requested to join once in this session.',
    requires_royal_merchant: 'Requires Royal Merchant',
    surcharge_increase: 'Surcharge Value for {0}',
    surcharge_all_increase: 'Surcharge Value of All Items',
    craft_resource_reduction_1: 'Common Resources Cost {0}',
    craft_resource_reduction_2: 'Uncommon Resources Cost {0}',
    craft_resource_reduction_3: 'Rare Resources Cost {0}',
    gem_rush_min_lvl_friendship:
        "You need a Friendship level of at least {0} in order to rush a guildmate's furniture upgrade with gems.",
    sitem_use_attack_seed: 'Use Attack Seed',
    sitem_use_defense_seed: 'Use Defense Seed',
    sitem_use_hp_seed: 'Use HP Seed',
    character_seed_used: "Increased {0}'s base {1} to {2}",
    sitem_attack_seed: 'Attack Seed',
    sitem_defense_seed: 'Defense Seed',
    sitem_hp_seed: 'HP Seed',
    character_max_seeds_used:
        'This character has already used the maximum number of seeds.',
    feed_free_food: 'Feed Free Food',
    feed_superior_food: 'Feed Superior Food',
    feed_premium_food: 'Feed Premium Food',
    remove_all_enchants_popup_desc:
        'All enchantments on this item will be destroyed.',
    remove_element_popup_desc:
        'The element on this item will be destroyed.',
    remove_spirit_popup_desc: 'The Spirit on this item will be destroyed.',
    remove_enchants_popup_qty_desc: 'Quantity to disenchant',
    pet_level_up_desc:
        "{0}'s affection level went up to <color=#FECB00FF>level {1}</color>!",
    pet_level_up_title: 'Pet level up!',
    decoration_received: 'Decoration Received!',
    trade_enchanted_item_warning:
        'Enchanted items cannot be traded on the Market. Remove enchantments on the item to proceed.',
    quest_event_king_info_limited_time_title: 'Limited Time Crafting Event',
    quest_event_king_info_limited_time_desc:
        "Crafting items matching the King's caprices will generate Favor for the guild. Craft Slots containing valid items will appear golden.",
    quest_event_king_info_favor_title:
        'Earn <color=#FFCC00FF>Favor</color> and Claim Prizes',
    quest_event_king_info_favor_desc:
        "The more Favor accumulated by your Guild, the more prizes you can claim on the event's reward track. Some limited items are available, so don't miss out!",
    quest_event_king_info_antiques_title:
        'Buy Antiques with <color=#FFCC00FF>Antique Tokens</color>',
    quest_event_king_info_antiques_desc:
        'Antique Tokens earned from the event can be spent to purchase content from past premium offers. Antique Tokens are kept between events.',
    quest_event_king_caprices_info_text:
        "Craft the King's requested items in order to gain <sprite=25> <color=#F4D500FF>Favor</color> and earn tons of rewards! Don't delay, his tastes are fickle!",
    guild_perks_details_init_tradeofferstack_desc: 'Offer stack size bonus',
    guild_perks_details_init_traderequeststack_desc:
        'Request stack size bonus',
    guild_perks_details_init_commonresregen_desc:
        'Common resource regen bonus',
    guild_perks_details_init_uncommonresregen_desc:
        'Uncommon resource regen bonus',
    guild_perks_details_init_rareresregen_desc: 'Rare resource regen bonus',
    guild_perks_details_init_coinrefillres_desc:
        'Daily Resource Refill uses increased by',
    all_unlocked: 'All unlocked',
    item_type_enchantment: 'Enchantments',
    item_type_element: 'Element',
    item_type_spirit: 'Spirit',
    basement_selector_title: 'Basement',
    basement_expansion_unlocked: 'Unlocked basement expansion!',
    member_help_basement_expand: 'Upgrading Basement to Lv.{0}',
    notification_basement_expand:
        'has expanded their basement to level <color=#FFDA00FF>{0}</color>!',
    expand_basement: 'Expand Basement',
    basement: 'Basement',
    store_furniture_title: 'Store Furniture',
    store_furniture_desc:
        'Furniture placed in the Basement will provide their benefits without taking up floor space in your shop.',
    store_furniture_store_button: 'Store in<br>Inventory',
    store_furniture_basement_button: 'Send to<br>Basement',
    basement_full: "You don't have enough Basement space.",
    furniture_space: 'Furniture space',
    pet_creation_tab_hat: 'Accessories',
    basement_unlocks_up_to: 'Up to Basement {0}',
    basement_unlocks: 'Basement expansions unlocked!',
    purchase_basement_unlock_format: 'Basement {0}',
    no_more_expansions: 'No More Expansions!',
    not_free_basement_expansion:
        'Tutorial basement expansion already completed.',
    sitem_seedplus_effect_desc: "to hero's {0} max.",
    sitem_use_maxhpseed_seed: 'Use Blossom of Vitality',
    sitem_use_maxatkseed_seed: 'Use Blossom of Power',
    sitem_use_maxdefseed_seed: 'Use Blossom of Resistance',
    max_seed: 'Max seed',
    sitem_maxhpseed_seed: 'Blossom(s)',
    sitem_maxatkseed_seed: 'Blossom(s)',
    sitem_maxdefseed_seed: 'Blossom(s)',
    fusion_title_success: 'Successful Fusion!',
    fusion_title_fail: 'Fusion Failed',
    fusion_subtitle_success: '{0} fusion successful!',
    fusion_subtitle_fail: 'Your {0} fusion failed',
    fusion_description_fail:
        'Your components were turned to ash in the process.',
    fusion: 'Fusion',
    fusions: 'Fusions',
    fusions_info_popup_dragon_title: 'Dragon Workers',
    fusions_info_popup_dragon_desc:
        'Sondra can perform Fusion using Moonstones, while Mundra can craft said Moonstones. Hiring both will unlock the full power of Fusion!',
    fusions_info_popup_items_title: 'Create high quality items',
    fusions_info_popup_items_desc:
        'Fuse duplicates of items to get a higher quality. Normal becomes Superior, Superior becomes Flawless, and so on.',
    fusions_info_popup_moonstone_title: 'Moonstones',
    fusions_info_popup_moonstone_desc:
        'Each Fusion requires a certain type of Moonstone. They can be crafted by the Moon Dragon, or earned through other means.',
    blueprint_line_moonstones: 'Moonstones',
    storage_full_moonstone:
        "You can't hold any more of that type of Moonstone.",
    fusion_selector_title: 'Fusion',
    fusion_low_level_worker:
        "Your worker's level is too low to perform this fusion.",
    fusion_success_odds: '{0} success',
    notification_city_help_basement_expand_paid:
        'has paid for your basement expansion!',
    notification_city_help_basement_expand:
        'has helped you expand your basement!',
    worker_sundragon: 'Sun Dragon',
    worker_moondragon: 'Moon Dragon',
    fusion_enchanted_item_warning:
        'This item is enchanted. Are you sure you want to destroy it?',
    fusion_confirmation_selector_title: 'Choose from inventory',
    fusion_confirmation_selector_subtitle: '{0} item tier {1}+',
    fusion_confirmation_item_name_quality: '{0} {1}',
    cross_dressing_disabled_clothes_male:
        'You can only equip this item on a Male character',
    cross_dressing_disabled_clothes_female:
        'You can only equip this item on a Female character',
    cross_dressing_disabled_hairstyle_male:
        'You can only equip this hairstyle on a Male character',
    cross_dressing_disabled_hairstyle_female:
        'You can only equip this hairstyle on a Female character',
    cross_dressing_disabled_mustache:
        'You cannot have a mustache on a Female character',
    cross_dressing_disabled_beard:
        'You cannot have a beard on a Female character',
    limit_skill_reroll:
        "Maximum gems reroll reached, you'll be able to reroll in {0}",
    limit_chest_open:
        "Maximum gems chest opening reached, you'll be able to open more in {0}",
    limit_enchant_item:
        "Maximum enchant item reached, you'll be able to enchant more in {0}",
    limit_enchant_item_standing:
        'There is a limit of 50 enchant per day ({0}/50).',
    pity_reroll_explain:
        'You are guaranteed to have 1 gold skill after 20 attempts.',
    limit_reroll_explain: 'There is a limit of 50 gems rerolling per day.',
    pity_and_limit_reroll_explain:
        'You are guaranteed to have 1 gold skill after 20 attempts.<br>There is a limit of 50 gems rerolling per day.',
    pity_and_limit_reroll_explain_standing:
        'You are guaranteed to have 1 gold skill after 20 attempts ({0}/20).<br>There is a limit of 50 gems rerolling per day ({1}/50).',
    pity_chest_explain:
        'You are guaranteed to receive Champion coins after 10 attempts.',
    limit_chest_explain: 'There is a limit of 50 gem openings per day.',
    pity_and_limit_chest_explain:
        'You are guaranteed to receive Champion coins after 10 attempts.<br>There is a limit of 50 gem openings per day.',
    pity_and_limit_chest_explain_standing:
        'You are guaranteed to receive Champion coins after 10 attempts ({0}/10).<br>There is a limit of 50 gem openings per day ({1}/50).',
    pity_and_limit_chest_explain_standing_opening:
        'There is a limit of 50 gem openings per day ({0}/{1}).',
    fusion_craft_slots_full: 'Not enough crafting slots.',
    purchase_section_workers: 'Exclusive workers',
    filter_moonstones: 'Moonstones',
    guildchat_tab_empty: 'Your Guild Chat is empty. Start a conversation!',
    notification_tab_empty: 'No New Activity. ',
    equip_tab_empty: 'You have nothing to equip. Start crafting!',
    fusion_detail_worker: 'Worker',
    fusion_detail_fuse_button: 'Fuse',
    fuse: 'Fuse',
    blueprint_unknown: 'Unknown blueprint',
    fusion_started: 'You have begun fusing {0} {1}.',
    fullmoon_event_title: 'Full Moon',
    fullmoon_event_subtitle: 'Dragon Fusion Event',
    fullmoon_event_desc:
        'The Full Moon empowers the Dragon workers! Special Fusions are now available!',
    view_info: 'View info',
    start_fusing: 'Start fusing',
    filter_fullmoon: 'Full Moon Fusion',
    fuse_warning_high_quality_desc:
        'will be used for this fusion. Proceed anyway?',
    fuse_warning_lock_desc: 'You are about to fuse a locked item. Proceed?',
    fusion_speedup_title: 'Fusion Details',
    fusion_speed: 'Fusion speed',
    trade_request_overmax_item: "You can't hold more than {0} items.",
    trade_request_overmax_tag: "You can't hold more than {0} enchantments.",
    confirm_fusion: 'Start Fusion',
    fusion_unlock_title: 'Unlock Fusion',
    fusion_unlock_desc:
        'Hire the <color=#F6CB07FF>Moon Dragon</color> or wait for the next <color=#F6CB07FF>Full Moon</color>.',
    trade_fusion_empty: 'Empty',
    fusion_touch_to_complete: 'Tap the vessel to complete the fusion!',
    fusion_touch_to_complete_click:
        'Click the vessel to complete the fusion!',
    trade_fusion_wait_for_data: 'Fetching Trades',
    fullmoon_info_popup_title: 'Full Moon',
    fullmoon_info_popup_limited_time_title: 'Limited Time Event',
    fullmoon_info_popup_limited_time_desc:
        'During a Full Moon, Sondra and Mundra become more powerful and gain access to special fusions!',
    fullmoon_info_popup_fullmoon_recipes_title: 'Full Moon Recipes',
    fullmoon_info_popup_fullmoon_recipes_desc:
        'For the duration of the event, fusions that are usually exclusive to Mundra are now available for everyone.',
    fullmoon_info_popup_faster_fusion_recipe_title: 'Faster Fusions',
    fullmoon_info_popup_faster_fusion_recipe_desc:
        'Players who have hired Mundra will instead benefit from a faster fusion speed during the event.',
    component_storage_full: 'Component inventory is full',
    blueprint_line_moondragon: "{0}'s Item Line",
    purchase_sitems_argon_name: 'Argon Coins',
    purchase_sitems_lilu_name: 'Lilu Coins',
    purchase_sitems_sia_name: 'Sia Coins',
    purchase_sitems_yami_name: 'Yami Coins',
    purchase_sitems_rudo_name: 'Rudo Coins',
    purchase_sitems_polonia_name: 'Polonia Coins',
    purchase_sitems_donovan_name: 'Donovan Coins',
    purchase_sitems_hemma_name: 'Hemma Coins',
    purchase_sitems_ashley_name: 'Ashley Coins',
    purchase_sitems_gold1_name: 'Gold',
    purchase_sitems_gold2_name: 'Gold',
    purchase_sitems_bp1_name: 'Ascension Shards',
    purchase_sitems_bp2_name: 'Ascension Shards',
    purchase_sitems_bp3_name: 'Research Scrolls',
    purchase_sitems_argon_desc:
        'Rare coins used to recruit and empower Argon.',
    purchase_sitems_lilu_desc:
        'Rare coins used to recruit and empower Lilu.',
    purchase_sitems_sia_desc: 'Rare coins used to recruit and empower Sia.',
    purchase_sitems_yami_desc:
        'Rare coins used to recruit and empower Yami.',
    purchase_sitems_rudo_desc:
        'Rare coins used to recruit and empower Rudo.',
    purchase_sitems_polonia_desc:
        'Rare coins used to recruit and empower Polonia.',
    purchase_sitems_donovan_desc:
        'Rare coins used to recruit and empower Donovan.',
    purchase_sitems_hemma_desc:
        'Rare coins used to recruit and empower Hemma.',
    purchase_sitems_ashley_desc:
        'Rare coins used to recruit and empower Ashley.',
    purchase_sitems_gold1_desc:
        'Gold is the primary currency of a shopkeeper!',
    purchase_sitems_gold2_desc:
        'Gold is the primary currency of a shopkeeper!',
    purchase_sitems_bp1_desc:
        'Used to grant powerful upgrades to blueprints.',
    purchase_sitems_bp2_desc:
        'Used to grant powerful upgrades to blueprints.',
    purchase_sitems_bp3_desc:
        'A pack of Research Scrolls to unlock a discovered blueprint.',
    worldchat_tab_empty: 'World Chat is empty. Start a conversation!',
    missing_item_location_general: 'You can find this item by questing',
    missing_item_location_tot:
        'You can find this item in the Tower of Titans.',
    missing_item_location_lcog:
        'You can find this item in the Lost City of Gold.',
    missing_item_location_specific: 'You can find this item in the {0}.',
    fusion_card_requires_full_moon: 'Requires Full Moon',
    fuse_more_to_hire_moondragon:
        'You need to perform more fusions to be able to hire Mundra and unlock this blueprint.',
    no_available_fusion: 'No fusion available',
    moonstone_lessermoon_tooltip_desc:
        'A chunk of slightly magical moonstone. An essential component for Fusion.',
    moonstone_flawedmoon_tooltip_desc:
        'A fragment of lunar meteorite, frozen in stone. An essential component for Fusion.',
    moonstone_greatermoon_tooltip_desc:
        'A lunar cycle, bound in physical form. An essential component for Fusion.',
    filter_stones: 'Stones',
    stones: 'Stones',
    unlock_creation_slot_title: 'Unlock crafting slot?',
    unlock_creation_slot_desc: 'Craft or fuse more items at the same time!',
    available_creation_slots: 'Available crafting slots',
    share_link_unavailable_region:
        'Sharing is unavailable in your current region.',
    check_user: 'Check User',
    pet_customization: 'Pet Customization',
    logout_device: 'Logout',
    exclusive_pet_skin: 'Exclusive Pet Appearance!',
    settings_device: 'Device: {0}',
    trade_tab_guildrequest: 'Guild',
    store_furniture_shop_button: 'Place in<br>Shop',
    login_event_claim: 'Claim',
    chest_odds_item_pity: 'Guaranteed Equipment after',
    chest_odds_component_pity: 'Guaranteed Component after',
    chest_odds_consumable_pity: 'Guaranteed Consumable after',
    chest_odds_bpunlock_pity: 'Guaranteed Research Scrolls after',
    chest_odds_bpupgrade_pity: 'Guaranteed Ascension Shards after',
    chest_odds_champion_pity: 'Guaranteed Champion Coins after',
    chest_odds_exclusive_pity:
        'Guaranteed Exclusive after (unless all collected)',
    you_caps: 'YOU',
    points_abv: 'pts',
    limit_fusion_craft:
        "Maximum fusion reached, you'll be able to fuse more in {0}",
    limit_fusion_craft_standing:
        'There is a limit of 50 fusion per day ({0}/50).',
    fusion_common_tooltip_description:
        'Any Common item of this Tier or higher.',
    fusion_uncommon_tooltip_description:
        'Any Superior item of this Tier or higher.',
    fusion_flawless_tooltip_description:
        'Any Flawless item of this Tier or higher.',
    fusion_epic_tooltip_description:
        'Any Epic item of this Tier or higher.',
    fusion_legendary_tooltip_description:
        'Any Legendary item of this Tier or higher.',
    purchase_store_event_title: 'EVENT STORE',
    filter_crystals: 'Components',
    fusion_crystal_selector_bottom_text: 'Component fusions cannot fail.',
    fusion_fullmoon_selector_bottom_text: 'These fusions cannot fail.',
    notification_member_promote: 'has promoted {0} to {1}.',
    notification_member_demote: 'has demoted {0} to {1}.',
    guild_updates_member_join: 'Joined the guild!',
    guild_updates_member_leave: 'Left the guild.',
    guild_updates_member_kick: 'Was kicked from the guild.',
    guild_updates_member_promote: 'Was promoted to {0}!',
    guild_updates_member_demote: 'Was demoted to {0}.',
    guild_updates_title: 'Guild Updates',
    quest_event_component_full: 'Your components are full.',
    book_score: 'Collection Score',
    share: 'Share',
    collection_book: 'Collection Book',
    collection_book_desc:
        'Offer items to this magical book in order to unlock <color=#FFDA00FF>rewards</color>, and acquire <color=#FFDA00FF>Vanity Gear</color>!',
    collection_book_popup_progress_title: 'Book Rewards',
    collection_book_popup_progress_description:
        'Each item donated to the Book upgrades your <color=#FFDC00>Collection Score</color>. Increase it to unlock unique rewards!',
    collection_book_info_popup_craft_title: 'Master your Craft',
    collection_book_info_popup_craft_desc:
        'Master blueprints to unlock their pages in the <color=#FFDC00>Collection Book</color>.',
    collection_book_info_popup_offer_title: 'Complete the Encyclopedia',
    collection_book_info_popup_offer_desc:
        'Offer an item of each quality to the <color=#FFDC00>Collection Book</color> to complete the page.',
    collection_book_info_popup_rewards_title: 'Get Rewards',
    collection_book_info_popup_rewards_desc:
        'Items entered into the Book will increase your <color=#FFDC00>Collection Score</color> and unlock rewards!',
    collection_book_info_popup_transmog_title: 'Vanity Gear',
    collection_book_info_popup_transmog_desc:
        "Completing a blueprint's entry in the Book will unlock it as <color=#FFDC00>Vanity Gear</color> for your Heroes.",
    fusion_selector_bottom_text: 'Full Moon recipes cannot fail.',
    donate: 'Donate',
    inventory_show_inventory_only: 'Show only items in inventory',
    xp_until: 'XP required for level {0}',
    collection_book_item_detail_stat_title_common: 'Amount crafted',
    collection_book_item_detail_stat_title_uncommon: 'Biggest sale',
    collection_book_item_detail_stat_title_flawless: 'Total Gold made',
    collection_book_item_detail_stat_title_epic: 'Amount sold to customers',
    collection_book_item_detail_stat_title_legendary:
        'Amount sold on Market',
    collection_book_unavailable_top_title:
        'Master this blueprint to unlock its page!',
    collection_book_unavailable_top_slider_title:
        'Crafts required to master',
    bountytrophy: 'Bounty Trophies',
    transmogrification: 'Vanity Gear',
    equipped: 'Equipped',
    transmog_generic_error_message: 'This Vanity Gear cannot be equipped',
    transmog_celebration_title: 'Item Page Completed!',
    transmog_celebration_desc1: 'Your collection is growing!',
    transmog_celebration_desc2:
        'New Vanity Gear is now available for the following classes:',
    transmog_tooltip_info:
        '<color=#FFDC00>Vanity Gear</color> can be worn by Heroes to change their appearance while keeping the statistics of real equipment worn underneath. More <color=#FFDC00>Vanity Gear</color> can be unlocked by filling their pages in the <color=#FFDC00>Collection Book</color>.',
    bonus_fusion_speed: 'Fusion Speed Bonus',
    bonus_fusion_legendary: 'Legendary Fusion Success Chance',
    collection_book_unlocked: 'Collection Book Unlocked!',
    collection_book_unlocked_desc: "You've unlocked the Collection Book!",
    worker_missing: 'Missing Worker',
    worker_missing_singular_desc:
        'You are currently lacking a worker required for this blueprint, and you will not be able to craft it. Are you sure?',
    worker_missing_plurial_desc:
        'You are currently lacking multiple workers required for this blueprint, and you will not be able to craft it. Are you sure?',
    quest_result_upgrade_hall:
        'Upgrade your Training Hall to level up beyond <color=white>Lvl. {0}</color>.',
    collection_book_page_unlocked: 'Page Unlocked',
    collection_book_page_unlocked_desc:
        'You can now fill out its page in the Collection Book!',
    open_collection_book: 'Open<br>Collection Book',
    transmog_equip_item_customize:
        'An item must be equipped for Vanity Gear to be applied.',
    donate_one: 'Donate',
    transmog_adv_busy:
        'Vanity Gear cannot be modified while Hero is questing.',
    transmog_locked_desc:
        "Complete this item's page in the <color=#FFDC00>Collection Book</color> to unlock it as <color=#FFDC00>Vanity Gear</color>.",
    unlock_vanity_gear: 'Unlock Vanity Gear',
    city_friendship_info_desc_3:
        '<size=+20><voffset=-10><sprite name="Friendship"></voffset></size><color=#FFCC00FF>New friendship title</color> every 10 levels!',
    stat_bounties_desc: 'Total amount of Trophies earned from Bounties.',
    donate_warning_lock_desc:
        'You are about to donate a locked item. Proceed?',
    notification_guildchoice_changed:
        "has changed the Guild's Choice to <color=#FFDC00>{0}</color>",
    offer_unlocked_blueprints: 'Exclusive Blueprint Lines!',
    purchase_bundle1_name: 'Mini item bundle!',
    purchase_bundle2_name: 'Baby item bundle!',
    purchase_bundle3_name: 'Tiny item bundle!',
    purchase_bundle4_name: 'Small item bundle!',
    purchase_bundle5_name: 'Light item bundle!',
    purchase_bundle6_name: 'Boost item bundle!',
    purchase_bundle7_name: 'Basic item bundle!',
    purchase_bundle8_name: 'Rapid item bundle!',
    purchase_bundle9_name: 'Swift item bundle!',
    purchase_bundle10_name: 'Quick item bundle!',
    purchase_bundle11_name: 'Nutty item bundle!',
    purchase_bundle12_name: 'Healthy item bundle!',
    purchase_bundle13_name: 'Boosted item bundle!',
    purchase_bundle14_name: 'Sharp item bundle!',
    purchase_bundle15_name: 'Gutsy item bundle!',
    purchase_bundle16_name: 'Useful item bundle!',
    purchase_bundle17_name: 'Ruthless item bundle!',
    purchase_bundle18_name: 'Magical item bundle!',
    purchase_bundle19_name: 'Marvelous item bundle!',
    purchase_bundle20_name: 'Plucky item bundle!',
    purchase_bundle21_name: 'Skillful item bundle!',
    purchase_bundle22_name: 'Rightful item bundle!',
    purchase_bundle23_name: 'Sophisticated item bundle!',
    purchase_bundle24_name: 'Thick item bundle!',
    purchase_bundle25_name: 'Joyous item bundle!',
    purchase_bundle26_name: 'Juicy item bundle!',
    purchase_bundle27_name: 'Alluring item bundle!',
    purchase_bundle28_name: 'Outstanding item bundle!',
    purchase_bundle29_name: 'Sparkling item bundle!',
    purchase_bundle30_name: 'Tasteful item bundle!',
    purchase_bundle31_name: 'Warm item bundle!',
    purchase_bundle32_name: 'Substantial item bundle!',
    purchase_bundle33_name: 'Dazzling item bundle!',
    purchase_bundle34_name: 'Scintillating item bundle!',
    purchase_bundle35_name: 'Flawless item bundle!',
    purchase_bundle36_name: 'Lucky item bundle!',
    purchase_bundle37_name: 'Majestic item bundle!',
    purchase_bundle38_name: 'Mountainous item bundle!',
    purchase_bundle39_name: 'Auspicious item bundle!',
    purchase_bundle40_name: 'Extra-large item bundle!',
    purchase_bundle41_name: 'Energetic item bundle!',
    purchase_bundle42_name: 'Brave item bundle!',
    purchase_bundle43_name: 'Ritzy item bundle!',
    purchase_bundle44_name: 'Huge item bundle!',
    purchase_bundle45_name: 'Hulking item bundle!',
    purchase_bundle46_name: 'Magnificent item bundle!',
    purchase_bundle47_name: 'Mighty item bundle!',
    purchase_bundle48_name: 'Ultra item bundle!',
    purchase_bundle49_name: 'Pumped item bundle!',
    purchase_bundle50_name: 'Combative item bundle!',
    purchase_bundle51_name: 'Glistening item bundle!',
    purchase_bundle52_name: 'Chivalrous item bundle!',
    purchase_bundle53_name: 'Boundless item bundle!',
    purchase_bundle54_name: 'Tremendous item bundle!',
    purchase_bundle55_name: 'Elite item bundle!',
    purchase_bundle56_name: 'Unequaled item bundle!',
    purchase_bundle57_name: 'Thundering item bundle!',
    purchase_bundle58_name: 'Grandiose item bundle!',
    purchase_bundle59_name: 'Optimal item bundle!',
    purchase_bundle60_name: 'Immense item bundle!',
    tier_search_locked:
        '<color=#FFDC00>Tier {0}</color> items will be available on the Market at <color=#FFDC00>Level {1}</color>.',
    purchase_engineer1_desc: 'Groundbreaking inventions every day!',
    engineer_desc: 'Just wait and see the stuff I can come up with!',
    city_perks_guild_level_desc: 'Unlocks new Upgrades',
    trade_slot_guild: 'Guild',
    galaxyMX_minortitle: 'Placeholder',
    galaxyMX_minormessage: 'Placeholder',
    galaxyMX_timelimittitle: 'Placeholder2',
    galaxyMX_timelimitmessage: 'Placeholder2',
    china_age_title: 'Placeholder',
    china_age_message: 'Placeholder',
    missing_item_type_adventurer:
        'This item can be found during the Tower of Titans event.',
    missing_item_type_adventurer_hero:
        'This item can be found during events or through other fusions.',
    fusion_missing_worker:
        "You don't have the necessary worker to perform this fusion",
    fusion_view_moondragon_fuse_more:
        'Fuse other items first in order to summon the Moon Dragon!',
    can_donate: '<color=#FFDC00>Can be donated!</color>',
    can_donate_collection_book:
        '<color=#874064>This item </color><color=#FFDC00>can be donated</color><color=#874064> to the collection book</color>',
    name_length_error: 'Name needs to be between {0} and {1} characters.',
    decoration_type_beam: 'Shop Frame',
    beams: 'Shop Frames',
    unlock_beam_title: 'Frame Purchase',
    unlock_beam_question: 'This Shop Frame will become available for use.',
    beam_applied: 'Frame applied!',
    currently_equipped: 'Currently Equipped',
    daily_deal: 'Daily Deal',
    purchase_virtual_expansion_unlock_format: 'Bonus Expansion {0}',
    virtual_expansion_unlocks: 'Bonus Expansion Unlock(s)',
    virtual_expansion_unlocks_up_to: 'Up to Bonus Expansion {0}',
    donate_warning_equipped_title: 'Equipped Item',
    donate_warning_equipped_desc:
        'This item is currently equipped on <color=#FFDC00>{0}</color>. Are you sure you wish to donate it?',
    donate_warning_enchanted_title: 'Valuable Item',
    donate_warning_enchanted_desc:
        'The enchantments on this item will be destroyed as part of the donation.',
    donate_warning_equipped_enchanted_title: 'Equipped Valuable Item',
    donate_warning_equipped_enchanted_desc:
        'This enchanted item is currently equipped on <color=#FFDC00>{0}</color>. The enchantments will be destroyed as part of the donation. Are you sure you wish to donate it?',
    view_tasks: 'View Tasks',
    inventory_hide_equipped_items: 'Hide Equipped Items',
    event_collection_halloween_title: 'Halloween Collection 2022',
    event_collection_christmas_title: 'Holiday Collection 2021',
    event_collection_lunar_title: 'Lunar New Year Collection 2022',
    event_collection_valentine_title: "Valentine's Day Collection 2022",
    event_collection_stpatrick_title: "St. Patrick's Collection 2022",
    event_collection_easter_title: 'Easter Collection 2022',
    event_collection_cinco_title: 'Cinco de Mayo Collection 2022',
    event_collection_4thofjuly_title: '4th of July Collection 2022',
    event_collection_augustus2020_title: 'Augustus II Collection 2022',
    event_collection_backtoschool_title: 'Back to School Collection 2022',
    event_collection_arabiannight_title: "Sultan's Collection 2022",
    temp_hero_rank_upgrade_desc:
        "Increasing a Champion's rank will make them much stronger.",
    temp_hero_time_info:
        'This Guest Champion is only available for a limited time. If they return in the future, they will keep their current progress.',
    vip_price_format_month: '{0}/1 month',
    vip_price_format_year: '{0}/1 year',
    vip_price_format_starts_at: '{0}/month',
    purchase_nosubvip2_name: 'Royal Merchant',
    purchase_nosubvip2_desc:
        '365 days of royal perks and instantly unlocks all loyalty rewards!',
    purchase_section_generic: 'Other',
    unique_copy_desc:
        'This decoration is unique. Duplicates cannot be purchased.',
    blueprint_not_unlocked: "You don't have this Blueprint yet.",
    pet_unlocked: 'Pet unlocked!',
    pet_cust_unlocked: 'Pet Customization unlocked!',
    you_have_unlocked: 'You have unlocked: {0}',
    worker_special_elven01:
        '<line-height=90%><voffset=-10><size=60><sprite name="Smalltalk"></size></voffset> +5% success rate with Small talk.',
    shop_custom: 'Customization',
    purchase_gems1_name: 'Gem Pack 1 ',
    purchase_gems2_name: 'Gem Pack 2 ',
    purchase_gems3_name: 'Gem Pack 3',
    purchase_gems5_name: 'Gem Pack 4',
    purchase_gems6_name: 'Gem Pack 5',
    purchase_gems7_name: 'Gem Pack 6',
    reroll_skill: 'Reroll skill',
    reroll_skill_desc: '',
    daily_offer_skill_reroller_desc:
        'Reroll a skill at a <color=#FFDF00FF>{0}% discount</color> with this rare item!',
    daily_offer_skill_reroller: 'Special Reroll Offer',
    champion_name_familiar:
        '{0} <color=#B86F96><size=-10>and {1}</size></color>',
    compliment_plus: 'Small talk+',
    blackfriday2021_04_description: 'Special Worker',
    transmog_tooltip_info_hero:
        "<color=#FFDC00>Vanity Gear</color> can be worn by Champions to change their Familiar's appearance while keeping the statistics of another. More <color=#FFDC00>Vanity Gear</color> can be unlocked by filling their pages in the <color=#FFDC00>Collection Book</color>.",
    place_bin: 'Place bin',
    purchase_bin: 'Purchase bin',
    affinity: 'Affinity',
    affinity_tooltip_info:
        'This item will receive increased benefits from {0} enchantments.',
    blueprint_line_familiars: 'Familiars',
    blueprint_line_cloaks: 'Cloaks',
    low_resource_capacity_notice:
        "Your bins can't hold enough resources to craft this!",
    worker_not_available: 'The {0} is not yet available to hire.',
    discard_familiars_title: 'Returning {0} to Yolanda',
    discard_familiars_body: 'Return {0}?',
    familiars_adv_busy:
        'Familiars cannot be modified while Champion is questing.',
    att_prompt_desc:
        'Your information will be used to provide you with personalized in-game offers, rewards and engaging player experiences.',
    notification_king_sell:
        'sold <color={0}>{1}</color> to the King for {2} gold!',
    building_unlocked: 'Building Unlocked!',
    discard_all_items_warning:
        'Some of these items are valuable! Are you sure you wish to dismiss them?',
    too_high_market_tier_warning:
        'Your level is not high enough to buy this item on the market.',
    repair_warning_title: 'Valuable Item Warning',
    repair_warning_destroy_single:
        'This is a valuable item. Are you sure you wish to discard it?',
    dungeon_unlocked: 'Dungeon unlocked!',
    celebration_send_heroes: 'Send Heroes',
    full_adventurers_popup_title: 'Browse Hero Classes',
    full_adventurers_popup_topdesc: '',
    full_adventurers_popup_botdesc:
        'All Hero Slots are already owned, but you can still look at the available classes.',
    pet_not_owned: 'Missing Pet',
    pet_not_owned_desc:
        'You do not own the pet associated with this Appearance. Are you sure you wish to buy it?',
    stat_collection_desc:
        'The Collection Book score this shopkeeper has reached.',
    task_info: 'Task Info',
    notification_member_promote_inactivity:
        "was promoted to {0} due to the previous Guildmaster's inactivity.",
    notification_member_demote_inactivity:
        'was demoted to {0} due to inactivity.',
    guild_updates_member_promote_inactivity:
        "Was promoted to {0} due to the previous Guildmaster's inactivity.",
    guild_updates_member_demote_inactivity:
        'Was demoted to {0} due to inactivity.',
    chest_exclusive_generic_furniture_desc:
        'A piece of furniture to be placed in your shop.',
    chest_exclusive_generic_furnitureskin_desc:
        'A special look for a piece of furniture.',
    chest_exclusive_generic_wall_desc:
        'A wallpaper to be displayed in your shop',
    chest_exclusive_generic_floor_desc:
        'A flooring to be displayed in your shop.',
    chest_exclusive_generic_beam_desc:
        'A new frame to be displayed in your shop.',
    chest_exclusive_generic_customization_desc:
        'An customization to be worn.',
    chest_exclusive_generic_pet_itemhead_desc:
        'A customization... for your pet!',
    chest_exclusive_generic_pet_itembody_desc:
        'A customization... for your pet!',
    chest_exclusive_generic_pet_skin_desc: 'An appearance for a pet.',
    furniture_level_name_format: '{0} Lvl. {1} ({2})',
    beam_found_in_chest_popup:
        'This Shop Frame can only be found in a chest.',
    stat_skills_desc_hero_element:
        "This Champion's element{0} can deal damage to monster Barriers of the same type.",
    shortsword_name: 'Squire Sword',
    shortsword_desc:
        "Strong, lightweight weapon that can be found in any warrior's arsenal.",
    longsword_name: 'Arming Sword',
    longsword_desc: 'Armed and ready.',
    broadsword_name: 'Gladius',
    broadsword_desc: 'Worth its weight in salt.',
    grottosword_name: 'Arboreal Blade',
    grottosword_desc: 'Have you watered your sword recently?',
    zweihander_name: 'Zweihander',
    zweihander_desc:
        'A long, long sword for strong hearts and brave deeds.',
    cutlass_name: 'Cutlass',
    cutlass_desc: 'All you need now is an eye patch and a pegleg.',
    fallsword_name: 'Serrated Cinquedea',
    fallsword_desc:
        "Its unique design is inspired from one of Howling Woods' many native tree species.",
    rapier_name: 'Espada',
    rapier_desc: "How's your riposte game?",
    herosword_name: "Hero's Sword",
    herosword_desc:
        "Wherever it came from, it's destined to save the world someday.",
    candysword_name: 'Chocolicious Blade',
    candysword_desc:
        "Could've been a few tiers higher if a certain someone didn't take a bite out of it.",
    katana_name: 'Katana',
    katana_desc:
        'Folded over a thousand times. Can cut through Knights with ease.',
    scimitar_name: 'Regal Blade',
    scimitar_desc:
        'This masterpiece is practically begging to be enchanted.',
    celesteelblade_name: 'Celesteel Blade',
    celesteelblade_desc:
        'Angels have kept this special metal a secret for centuries. Then some Prometheus guy had to leak it.',
    ruinssword_name: 'Damocles',
    ruinssword_desc: 'A feeling of unease hangs in the air...',
    goldsword_name: 'Luxurious Macuahuitl',
    goldsword_desc:
        'Enhanced with jagged shards of Time Crystal to ensure a bad time upon whoever is struck.',
    falchion_name: 'Mythril Edge',
    falchion_desc:
        "These masterfully crafted blades are found only among the Kern emperor's elite bodyguards.",
    poloniacutlass_name: 'Seafarer Blade',
    poloniacutlass_desc:
        'An intricately forged sidearm that is said to have been owned by Baross Del Monte himself.',
    canesword_name: 'Gentleman Blade',
    canesword_desc: 'A true gentleman knows when to keep his blade away.',
    cleaver_name: 'Oversized Cleaver',
    cleaver_desc: 'How do you even lift that thing?',
    templesword_name: 'Caladbolg',
    templesword_desc: 'Strike with the force of a crashing wave.',
    dragonsword_name: 'Dragon Dao',
    dragonsword_desc:
        'Only friends of dragons can wield this blade without risk of draconic retribution.',
    lunarsword_name: 'Tiger Blade',
    lunarsword_desc:
        'The blade of a just Emperor of ancient times, said to be as fierce and cunning as a tiger.',
    vorpalsword_name: 'Vorpal Sword',
    vorpalsword_desc: 'Heads will roll.',
    victoriansword_name: 'Elegant Rapier',
    victoriansword_desc: 'Trendy & deadly!',
    moonsword_name: 'Tsukuyomi',
    moonsword_desc:
        'Got into a big fight with Amaterasu, causing the night and day to be forever split apart.',
    boysaxe_name: 'Wood Axe',
    boysaxe_desc: 'Split your foes in twain.',
    hatchet_name: 'Hatchet',
    hatchet_desc: 'Never leave home without one!',
    doublebitted_name: 'Iron Chopper',
    doublebitted_desc: 'Two blades are better than one.',
    exploreraxe_name: "Explorer's Axe",
    exploreraxe_desc:
        'Go where no man has dared explore - with this trusty axe at your side!',
    halberd_name: 'Bardiche',
    halberd_desc: 'Not recommended for children under 10.',
    grottoaxe_name: 'Molten Voulge',
    grottoaxe_desc:
        'Terribly efficient, but has a tendency to cauterize the wounds it causes.',
    tomahawk_name: 'Tomahawk',
    tomahawk_desc: 'Will not return when thrown.',
    battleaxe_name: 'Battleaxe',
    battleaxe_desc: 'Dwarves always keep one tucked under their pillow.',
    birdaxe_name: 'Raptor Reaper',
    birdaxe_desc: "Who's doing the reaping around here?",
    starteraxe_name: 'Companion Axe',
    starteraxe_desc: 'Boasts a perfect grip for seasoned adventurer!',
    snowaxe_name: 'Cold Iron Axe',
    snowaxe_desc:
        'Magically forged without any heat whatsoever. Particularly disliked by the fey and their ilk.',
    steelaxe_name: 'Dwarven Greataxe',
    steelaxe_desc: "It's grrrrrrreat!",
    pyramidaxe_name: 'Stonesplitter',
    pyramidaxe_desc:
        'Cut straight to the point with this fine jade axe of dwarven make.',
    raideraxe_name: 'Raider Axe',
    raideraxe_desc: 'Less talking, more raiding!',
    executioner_name: 'Executioner',
    executioner_desc: 'Often paired with Judge & Jury.',
    castleaxe_name: 'Purgatory',
    castleaxe_desc: 'Justice is defined by the hands that claim it.',
    cincoaxe2020_name: 'Axe of The Fifth',
    cincoaxe2020_desc:
        'Vibrant and colorful. Slighty disturbing as it swings towards you.',
    crescentaxe_name: 'Eclipsis',
    crescentaxe_desc:
        'Usher forth a swift end to the denizens of the night.',
    templeaxe_name: 'Terra Tyrannis',
    templeaxe_desc:
        'The irony is not lost on the wielder of this fierce, primal weapon.',
    goldaxe_name: 'Opulent Grandaxe',
    goldaxe_desc: 'The best way to cut your losses.',
    coupleaxe_name: 'Kodiak Kleaver',
    coupleaxe_desc:
        'Can split just about anything... aside from two enamored, beefy berserkers.',
    manticoreaxe_name: 'Manticore Slayer',
    manticoreaxe_desc:
        'The spikes on this fierce weapon sometimes spring and act on their own, reaching for prey.',
    beeaxe_name: 'Beeswaxe',
    beeaxe_desc:
        'Mercilessly sticky. May summon nearby bears to the wielder.',
    fallaxe_name: 'Shroom Doom',
    fallaxe_desc:
        "A ruthless axe that once belonged to a legendary barbarian, said to be the terror of all Mushgoons. Constant maintenance is required if you don't want stray spores to stick!",
    kitchenknife_name: 'Shiv',
    kitchenknife_desc: 'Necessity is the mother of invention.',
    forestdagger_name: 'Ice Pick',
    forestdagger_desc: 'An ice pick made of ice picked to pick at ice.',
    dagger_name: 'Swift Blade',
    dagger_desc: 'Silent and deadly.',
    kunai_name: 'Kunai',
    kunai_desc:
        'A strange multi-purpose tool from a foreign land. Forging it requires a technique known by few.',
    switchblade_name: 'Stealth Knife',
    switchblade_desc: 'Easily concealable and always reliable.',
    butterflyknife_name: 'Balisong',
    butterflyknife_desc:
        'Lodges itself effortlessly in the back of unsuspecting allies.',
    kris_name: 'Ritual Dagger',
    kris_desc: 'Ceremonial weapon worn by royalty and priests.',
    atlab_boomerang_name: "Sokka's Boomerang",
    atlab_boomerang_desc: '"Boomerang! You do always come back!"',
    yamidagger_name: 'Assassin Tanto',
    yamidagger_desc: 'The serial number is filed off...',
    schooldagger2020_name: 'Erudite Vector',
    schooldagger2020_desc: 'Always the right angle.',
    dirk_name: 'Kingsguard',
    dirk_desc:
        'A ruthlessly efficient last resort owned by guards of the royal court.',
    guttingknife_name: 'Fishmonger',
    guttingknife_desc: 'Really knows its way around guts.',
    lunardagger_name: 'Cloudwalker Chakram',
    lunardagger_desc: 'Soars to your target with a soft breeze...',
    trolltooth_name: 'Troll Tooth',
    trolltooth_desc:
        'Nine out of ten dentists recommend brushing it twice a day.',
    ruinsdagger_name: 'Levia Fang',
    ruinsdagger_desc:
        'Whichever beast it came from, it might want it back! Be careful!',
    misericordia_name: 'Misericordia',
    misericordia_desc: 'Merciful release awaits.',
    golddagger_name: 'Luxurious Poignard',
    golddagger_desc:
        'Looks just about ready to meet its daily blood sacrifice quota!',
    sultandagger_name: 'Sultan Dagger',
    sultandagger_desc:
        'To be kept under your pillow for 1001 nights of good sleep.',
    katar_name: 'Ceremonial Katar',
    katar_desc:
        "Don't let the embellishments fool you; it packs quite a punch.",
    templedagger_name: 'Nightmare Fellblade',
    templedagger_desc: 'Thrice desecrated under a moonless night.',
    siadagger_name: "Swan's Edge",
    siadagger_desc:
        'Deep within enemy territory, a sharp blade is your only ally.',
    stpatrickdagger_name: 'Lucky Strike',
    stpatrickdagger_desc:
        'Keep it tucked away in your sleeve for maximum critical hit potential.',
    heartseeker_name: 'Heartseeker',
    heartseeker_desc: "Be advised: don't run with it unsheathed!",
    peakdagger_name: 'Last Breath',
    peakdagger_desc: 'The final embrace of a gentle caress.',
    halloweendagger_name: 'Nightwing Bolt',
    halloweendagger_desc:
        'Typically used as ammunition for the Nightwing Caster, but can also double as a vicious dagger.',
    atlab_kyoshifan_name: 'Kyoshi Warrior Fan',
    atlab_kyoshifan_desc:
        'A unique weapon wielded exclusively by the Kyoshi Warriors. Sharper than it looks!',
    club_name: 'Cudgel',
    club_desc: 'Primitive yet surprisingly effective.',
    spikedclub_name: 'Spiked Cudgel',
    spikedclub_desc: 'Speak softly... and carry a big stick.',
    forestmace_name: 'Darkwood Branch',
    forestmace_desc:
        'Speak softly... and carry a big cursed stick from a giant man-eating tree.',
    hammer_name: 'Warhammer',
    hammer_desc: 'Praise Sigmar.',
    libertymace_name: 'Liberty Mace',
    libertymace_desc: 'Shine boldly with the light of liberty.',
    morningstar_name: 'Morning Star',
    morningstar_desc: 'The spiky wake-up slap.',
    summermace_name: 'Inflatable Maul',
    summermace_desc:
        "Don't let the playful colors fool you: this is not a toy.",
    heavymace_name: 'Flanged Mace',
    heavymace_desc: "Overcompensating a bit, aren't we?",
    newyearmace_name: 'First Morning Star',
    newyearmace_desc:
        'Has a chance to shatter into confettis upon hitting. Appropriate armament for the first morning of the New Year!',
    ancienthammer_name: 'Skull Crusher',
    ancienthammer_desc:
        'The barbarians of the north are good at maiming, not naming.',
    troblinmace_name: 'Troublin Bludgeon',
    troblinmace_desc:
        "Another of Barkback's marvelous inventions. The spikes mean more damage, and the vines add a serving of greens.",
    midnightstar_name: 'Evening Star',
    midnightstar_desc: "Morning Star's long lost brother.",
    pyramidmace_name: 'Meteor Hammer',
    pyramidmace_desc: 'Super effective against dinosaurs.',
    powderkeg_name: 'Powder Keg',
    powderkeg_desc:
        'This is what happens when Roxanne gets her hands on a mace blueprint.',
    pumpkinmace_name: "Whack-O'-Lantern",
    pumpkinmace_desc: 'Trick. Or. Treat.',
    candyhammer_name: 'Peppermint Mallet',
    candyhammer_desc:
        "When a regular mint doesn't get the message across...",
    tenderizer_name: 'Tenderizer',
    tenderizer_desc: "That should soften 'em up.",
    smithhammer_name: 'Wallace Mallet',
    smithhammer_desc: 'Create and destroy with equal ease.',
    freedommace_name: 'Striped Star',
    freedommace_desc:
        "That's the only thing you can see after it hits you.",
    nautilus_name: 'Nautilus',
    nautilus_desc: 'It sinks into everything, really.',
    goldhammer_name: 'Opulent Maul',
    goldhammer_desc: 'Pay someone to carry this for you.',
    mundrahammer_name: "Mundra's Masher",
    mundrahammer_desc:
        "Bosses of any dungeon start sweating nervously at the mere mention of this hammer's name.",
    emeraldhammer_name: 'Thorium Hammer',
    emeraldhammer_desc: 'The future is now, Thor.',
    peakhammer_name: 'Sunglow Impact',
    peakhammer_desc:
        'Requires roughly 7 years of uninterrupted sunlight to reach its maximum potential.',
    naturehammer_name: 'Gaia Maul',
    naturehammer_desc: 'Hits with the weight of the whole world.',
    atlab_mace_name: "Sokka's Club",
    atlab_mace_desc: 'A tough club made of bone, crafted by Sokka himself.',
    woodspear_name: 'Javelin',
    woodspear_desc:
        'Simple weapon used by warriors for thousands of years.',
    ironspear_name: 'Hunting Spear',
    ironspear_desc: 'Thrust or throw, your choice!',
    bladedspear_name: 'Bladed Spear',
    bladedspear_desc: 'What if we stuck a sword to a pole? Genius!',
    pitchfork_name: 'Sturdy Pitchfork',
    pitchfork_desc:
        'The #1 tool to row hay, poke monster bums, and getting those darn kids off your lawn!',
    ranseur_name: 'Ranseur',
    ranseur_desc: 'Long thrusting weapon used by the royal guard.',
    trident_name: 'Trishula',
    trident_desc: 'Fish sticks anyone?',
    easterspear_name: 'Bunbun Lance',
    easterspear_desc:
        'Quite literally, the carrot on a stick. Horses love it.',
    pike_name: 'Spetum',
    pike_desc: 'Three pokeys bits means triple damage right?',
    heavenlyhalberd_name: 'Warlord Halberd',
    heavenlyhalberd_desc:
        'Reach to the sky and strike with the wrath of the heavens.',
    desertspear_name: 'Triton Lance',
    desertspear_desc:
        'Magically enchanted for throwing and underwater breathing. These abilities are unfortunately mutually exclusive.',
    celticspear_name: "Cu Chulainn's Lance",
    celticspear_desc:
        'Also known as the "spear of mortal pain". Your imagination can fill the gaps.',
    partisan_name: 'Royal Halberd',
    partisan_desc: 'Stand tall and proud!',
    yetispear_name: 'Twicicle Javelin',
    yetispear_desc:
        'Yetis are adept ice craftsmen. The Twicicle Javelin is one of their simplest design, suitable even for novices to make.',
    championlance_name: 'Champion Lance',
    championlance_desc:
        'A winner of countless jousts. Whoever bears it has a strong legacy to live up to!',
    wyvernglaive_name: 'Wyvern Glaive',
    wyvernglaive_desc:
        'The dragon head just... constantly shrieks. Nonstop.',
    sylvanspear_name: 'Hoartooth Lance',
    sylvanspear_desc:
        'Allan prefers working wood, but the bones of a fantastic beast make for a unique challenge.',
    goldspear_name: 'Luxurious Spear',
    goldspear_desc: 'The inevitable reach of monarchy.',
    stellarspear_name: 'Stellaria',
    stellarspear_desc: 'Guarantees a stellar performance.',
    templespear_name: 'Longinus',
    templespear_desc:
        'Apparently poked a really important guy a long time ago.',
    thanksgivingspear_name: 'Birdbane Halberd',
    thanksgivingspear_desc:
        'Laughed off only by fools who have never been pecked to near-death by griffins.',
    feyspear_name: "Titania's Gift",
    feyspear_desc:
        'A boon from the Queen of the Fairies. Does it come with a price? Most certainly.',
    circusspear_name: 'Jester Jouster',
    circusspear_desc:
        "Apparently created as a joke by Borovitz. The knights didn't think it was funny when it claimed victory upon victory.",
    christmasspear_name: 'Holy Night Lance',
    christmasspear_desc:
        'Wielded by Santa Claus during the First Christmas to defeat Ouroboros the Sun Eater. This part of the story is kept secret to the general public.',
    shortbow_name: 'Training Bow',
    shortbow_desc:
        'Rickety weapon made from a strong branch and a fine string.',
    forestbow_name: 'Tailwind',
    forestbow_desc:
        'Shots fired from this bow completely ignore adverse wind conditions.',
    longbow_name: 'Elmwood Bow',
    longbow_desc: 'Never miss your mark again.',
    hunterbow_name: 'Reflex Bow',
    hunterbow_desc: 'Remember, the pointy end should point out.',
    siabow_name: 'Grand Harp',
    siabow_desc:
        'An elegant bow designed by Sia herself. Possesses remarkable power while remaining easy to use.',
    strikerbow_name: 'Compound Bow',
    strikerbow_desc: 'Strike your target with furious precision.',
    spikedbow_name: 'Deadeye',
    spikedbow_desc: 'No apple is safe.',
    halloweenbow_name: 'Hallowed Willow',
    halloweenbow_desc:
        'A terribly cursed bow made useable through multiple blessings. There is no telling how long they will hold.',
    wingedbow_name: 'Raptoria',
    wingedbow_desc:
        'Watch your arrows slice through the air like birds of prey.',
    desertbow_name: 'Quetzal Wing',
    desertbow_desc: 'With a name like that, it has to be strong, right?',
    compositebow_name: "L'Arabesque",
    compositebow_desc: 'An undeniably deadly work of art.',
    yumi_name: 'Yumi',
    yumi_desc:
        'Its asymmetrical design takes some time to get used to, but there is no denying its craftsmanship.',
    valentinebow_name: 'Cupid Bow',
    valentinebow_desc:
        'Wage love AND war with this cupidly effective, dual-purpose bow.',
    mundrabow_name: "Mundra's Hornbow",
    mundrabow_desc:
        'Its draw weight is meant for beefy dragons. Dexterity builds; look elsewhere!',
    bramblebane_name: 'Bramblebane',
    bramblebane_desc: "Be a literal thorn in your enemy's side!",
    fallbow_name: 'Maplewood Gale',
    fallbow_desc:
        'The wind takes from the trees, leaving only torpor for the coming winter.',
    twinstrand_name: 'Gemini Strike',
    twinstrand_desc: 'Two for the price of one.',
    christmasbow_name: 'Jolly Ranger',
    christmasbow_desc: "'Tis the season.",
    stormrend_name: 'Stormrend',
    stormrend_desc: 'Hear me, thunder! Cry out, lightning!',
    goldbow_name: 'Opulent Longbow',
    goldbow_desc:
        'Mostly used for ceremonial purpose. Gold does not bend very well.',
    cincobow_name: 'Pinata Hunter',
    cincobow_desc:
        'Let the candies hit the floor. Let the candies hit the floor.',
    newyearbow_name: 'Rising Star',
    newyearbow_desc:
        'Shoot for the stars, aim for the moon. Beware of unprotected atmospheric reentry.',
    frogbow_name: 'Boughwrought Bow',
    frogbow_desc:
        'Despite its mundane appearance, it is anything but bog standard.',
    woodbranch_name: 'Carved Branch',
    woodbranch_desc:
        'Roughly carved from a branch with a minor magical aura.',
    cane_name: 'Sturdy Cane',
    cane_desc: 'A good walking stick is tough to come by!',
    oakstaff_name: 'Oak Staff',
    oakstaff_desc: 'Imbued with the essence of the elder wood.',
    lilustaff_name: 'Owl Perch',
    lilustaff_desc:
        "An intricate staff carved in the shape of Lilu's owl, Pollux. Please don't swing it too hard.",
    goldstaff_name: 'Luxurious Stick',
    goldstaff_desc: 'The mundane, polished and gilded to a fault.',
    starterstaff_name: 'Apprentice Staff',
    starterstaff_desc: 'It basically casts for you.',
    bo_name: 'Bo Staff',
    bo_desc:
        "For the sorcerer who doesn't mind getting up close and personal.",
    scepter_name: 'Jade Scepter',
    scepter_desc: 'Powerful staff primarily used as a statement piece.',
    swampstaff_name: 'Amber Staff',
    swampstaff_desc:
        "Something ancient rests within this amber. Something powerful enough to strengthen a wizard's spells...",
    wizardstaff_name: 'Wizard Staff',
    wizardstaff_desc:
        'Overwhelming elemental power is now within your grasp.',
    celestialrod_name: 'Celestial Staff',
    celestialrod_desc:
        'Said to unleash hidden potential when the stars align.',
    imperialaquila_name: 'Imperial Aquila',
    imperialaquila_desc:
        'A prestigious staff wielded by imperial warmages.',
    mundrastaff_name: "Mundra's Scepter",
    mundrastaff_desc: "Decidely too gaudy in hands other than Mundra's.",
    ruinsstaff_name: 'Phoenix Staff',
    ruinsstaff_desc:
        'Eternal as it may seem, this staff will break like any other. A lesson in humility.',
    leafstaff_name: 'Staff of Seasons',
    leafstaff_desc: 'Time whitens hair without ripening reason.',
    transcendance_name: 'Transcendence',
    transcendance_desc:
        'Every chime reminds you that the best weapon is your mind.',
    castlestaff_name: 'Tidebringer',
    castlestaff_desc: 'Slowly but surely, the ocean reclaims what is hers.',
    lemonstaff_name: 'Zesty Scepter',
    lemonstaff_desc:
        'If life gives you lemons... use them to channel your spells.',
    conflux_name: 'Seraphim',
    conflux_desc: "Do you hear the horn's call?",
    christmasstaff_name: 'Staff of Merriment',
    christmasstaff_desc:
        'Please refrain from using it during the remaining eleven months of the year.',
    penumbra_name: 'Penumbra',
    penumbra_desc: 'On the cusp of light and darkness, a perpetual dawn.',
    yetistaff_name: "Ice Queen's Scepter",
    yetistaff_desc:
        'Some say a seldom seen queen of ice rules over the Yetis. This staff may hold dominion over them!',
    valentinestaff_name: "Heartcaptor's Staff",
    valentinestaff_desc:
        "My heart's been captured! Wasn't there something about cards?",
    goldstaff2_name: 'Opulent Staff',
    goldstaff2_desc: '',
    baton_name: 'Baton',
    baton_desc:
        "The core is made of quality materials, but it's no phoenix feather!",
    twirled_name: 'Elvenwood Wand',
    twirled_desc:
        "The Elvenwood's properties remain as intact as its shape.",
    backscratcher_name: "Hexer's Wand",
    backscratcher_desc: 'Makes for a good backscratcher, too.',
    steelrod_name: 'Steel Rod',
    steelrod_desc: "Don't cast lightning spells with this one.",
    christmaswand_name: 'Peppermint Cane',
    christmaswand_desc: 'You get fresh breath! And you get fresh breath!',
    starrod_name: 'Star Rod',
    starrod_desc: 'Special import from dream land.',
    sylvan_name: 'Sylvanel',
    sylvan_desc: 'Conduct the magical symphony of nature.',
    rubyrod_name: 'Ruby Wand',
    rubyrod_desc:
        'Mirror, mirror on the wall, who’s the fairest wand of them all?',
    liluwand_name: 'Owl Wing Wand',
    liluwand_desc: 'Try saying that five times real fast.',
    goldwand_name: 'Wand of Midas',
    goldwand_desc:
        'Everything you touch will turn to gold. Figuratively. Believe in yourself!',
    flowerwand_name: 'Evergreen Wand',
    flowerwand_desc: 'Crocus Pocus.',
    fallwand_name: 'Equinox Rod',
    fallwand_desc: 'The herald of change between seasons.',
    moonwand_name: 'Astral Conductor',
    moonwand_desc: 'The celestial dance needs someone to orchestrate it.',
    wizardwand_name: "Grimar's Grand Wand",
    wizardwand_desc: 'Hopelessly lost... until now!',
    dragoneye_name: 'Draconic Eyestalk',
    dragoneye_desc: 'Avert your gaze, lest you attract unwanted attention.',
    lcogwand_name: 'Opulent Wand',
    lcogwand_desc:
        'Experts say this may be an ancient, forgotten variant of the Wand of Midas.',
    sailorwand_name: 'Moonlight Wand',
    sailorwand_desc:
        'Proper activation of its latent powers requires a lengthy transformation sequence.',
    flintlockpistol_name: 'Pellet Gun',
    flintlockpistol_desc: 'Prone to misfire. Feeling lucky, punk?',
    pistol_name: 'Handgun',
    pistol_desc: 'Your new favorite toy.',
    flintlockrifle_name: 'Smoothbore',
    flintlockrifle_desc: 'Blow all your problems away.',
    huntingrifle_name: 'Long Rifle',
    huntingrifle_desc: 'Blast anything within five hundred yards.',
    wintergun_name: 'Snowball Launcher',
    wintergun_desc: "It's all fun and games until someone loses an eye.",
    blunderbuss_name: 'Blunderbuss',
    blunderbuss_desc: 'Knock knock...',
    doublebarrel_name: 'Boomstick',
    doublebarrel_desc:
        "Walnut stock, cobalt blue steel, and a hair trigger. That's right. Shop smart.",
    kenora_name: 'Kenora Mk. IV',
    kenora_desc: "It's a gaudy kind of yee haw.",
    gatling_name: 'Prototype Gatling',
    gatling_desc:
        'It costs 400,000 gold to fire this weapon for twelve seconds.',
    dragonator_name: 'Dragonator',
    dragonator_desc:
        'Nothing quite like the smell of sulfur in the morning.',
    barrelgun_name: "Handcask '65",
    barrelgun_desc: 'Aged like a fine wine.',
    hermes_name: 'The Messenger',
    hermes_desc: 'This one only delivers bad news.',
    lunargun_name: 'Oxen Impact',
    lunargun_desc: "This ain't this gun's first rodeo.",
    disintegratorgun_name: 'Omega Disintegrator',
    disintegratorgun_desc: 'Exterminate. Exterminate. Exterminate.',
    goldgun_name: 'Opulent Pistol',
    goldgun_desc: 'Contains a single, highly lethal golden bullet.',
    doggun_name: 'Dogbone Blaster',
    doggun_desc:
        'Can be tossed to distract a dog, wolf or even werewolf opponent. Only once, though.',
    lightcrossbow_name: 'Light Crossbow',
    lightcrossbow_desc: 'For the archer on the go.',
    handcrossbow_name: 'Hand Crossbow',
    handcrossbow_desc: 'Now in a fun, travel size format!',
    crossbow_name: 'Arbalest',
    crossbow_desc: 'Fires bolts at breakneck speeds.',
    heavycrossbow_name: 'Heavy Crossbow',
    heavycrossbow_desc: 'Say hello to my little friend.',
    thanksgivingcrossbow_name: 'Cluckthrower',
    thanksgivingcrossbow_desc:
        'The rate of fire is great, but the sound is the real selling point.',
    huntercrossbow_name: "Hunter's Crossbow",
    huntercrossbow_desc: 'Even the smallest prick spells certain doom.',
    scorpion_name: 'Scorpio',
    scorpion_desc: 'Built tough, for BIG game hunting.',
    troblincrossbow_name: 'Primitech Slingshot',
    troblincrossbow_desc:
        'The greatest Troublin inventors teamed up to create this technological marvel, combining both the innacuracy of a slingshot and the unwieldiness of a crossbow.',
    doublecrossbow_name: 'Double Crossbow',
    doublecrossbow_desc: 'Has a reputation for double-crossing.',
    chukonu_name: 'Chu-Ko-Nu',
    chukonu_desc:
        'Features a revolutionary automated reload mechanism to allow for uninhibited dual-wield action.',
    dragoncrossbow_name: 'Dra-Ko-Nu',
    dragoncrossbow_desc:
        'Can only fire patented dragon bolts, which dragons obviously sell at a premium.',
    triplecrossbow_name: 'Triple Crossbow',
    triplecrossbow_desc: 'What has science done!?',
    handballista_name: 'Super Repeater',
    handballista_desc: 'HOW many bolts per minute!?',
    batcrossbow_name: 'Nightwing Caster',
    batcrossbow_desc: 'Can also fire bat bombs and bat boomerangs.',
    wyrmthrower_name: 'Leviathan',
    wyrmthrower_desc:
        'Who really knows what lurks in the dark, endless fathoms?',
    goldcrossbow_name: 'Opulent Crossbow',
    goldcrossbow_desc:
        "Boasts a particularly low fire rate, but given the cost of the ammunition... it's better that way!",
    titancrossbow_name: 'Titan Crossbow',
    titancrossbow_desc:
        'Actually fires concentrated soul energy supplied by the user, so the string is really just for show.',
    grass_name: 'Sweet Grass',
    grass_desc: 'Believe it or not, this herb is illegal in some places.',
    antidote_name: 'Mintyleaf Herb',
    antidote_desc: 'Defeat any poison with overwhelming freshness.',
    powder_name: 'Moon Powder',
    powder_desc: 'Shimmering dust used in most potion recipes.',
    christmasflower_name: 'Magical Mistletoe',
    christmasflower_desc:
        'Carry the spirit of Christmas with you everywhere, even during the off-season.',
    healingsalve_name: 'Healing Salve',
    healingsalve_desc:
        'Miraculously mend any wound right before your eyes.',
    thanksgivingherb_name: 'Venerable Oats',
    thanksgivingherb_desc:
        'Wait until you meet its brother, the overnight oats.',
    silverthistle_name: 'Silver Thistle',
    silverthistle_desc:
        'Rare Elven herb with limitless alchemical potential.',
    harvest_name: 'Bountiful Harvest',
    harvest_desc:
        'Stay healthy with this... sizeable serving of vegetables.',
    bouquet_name: 'Fragrant Bouquet',
    bouquet_desc:
        'Various flowers and freshly cut herbs. The signature bouquet of the Maribel Medicine Emporium.',
    bloodvine_name: 'Bloodvine',
    bloodvine_desc: 'Handle with extreme care!',
    mandragoraroot_name: 'Mandragoroot',
    mandragoraroot_desc:
        'Terribly grumpy and uncooperative early in the morning.',
    valentineherb_name: 'Unwilting Rose',
    valentineherb_desc:
        'Try as you might, so long as love still exists in the world, this flower will remain beautiful.',
    wolfsbane_name: "Wolf's Bane",
    wolfsbane_desc: 'Say it with flowers.',
    ruinsherb_name: 'Mistpeak Bloom',
    ruinsherb_desc:
        "Grows at the peak of snowy mountains and only blooms once per decade. How it hasn't gone extinct is a mystery.",
    yggdrasilbranch_name: 'Yggdrasil Branch',
    yggdrasilbranch_desc: 'The Tree of Life binds all things together.',
    goldenclover_name: 'Luckiest Clover',
    goldenclover_desc:
        'Very rarely found within fields of solid gold shamrocks.',
    goldherb_name: 'Luxurious Panacea',
    goldherb_desc:
        'Substitutes snake oil for gold flakes for the same effect, but triple the cost!',
    wyrmblood_name: 'Wyrmblood Ointment',
    wyrmblood_desc: 'Brace yourself, this may sting a little...',
    couplebouquet_name: 'Wild Oak Rose',
    couplebouquet_desc:
        'Carved out of the strongest wood for the strongest love there is.',
    eldritchtendrils_name: 'Florae Daemonica',
    eldritchtendrils_desc: 'Do not let it sit on your night table.',
    peakherbs_name: 'Bleakspire Roots',
    peakherbs_desc:
        'Even in the most secluded, harshest places... life finds a way.',
    circusherb_name: 'Seltzer Surprise',
    circusherb_desc:
        'Shame upon ye, o fool! Falling for such a classical farce!',
    candyherb_name: 'Super Snack Pack',
    candyherb_desc: 'Make your cheat day truly count for something.',
    tea_name: 'Warm Tea',
    tea_desc:
        'You feel a great burden has been lifted from your shoulders.',
    smallhealthpotion_name: 'Healing Potion',
    smallhealthpotion_desc:
        'The taste is awful, but it will certainly work.',
    smallmanapotion_name: 'Magic Potion',
    smallmanapotion_desc: 'Made with sugar, spice and everything nice.',
    scienceexperiment_name: 'Science Project',
    scienceexperiment_desc:
        'A blind taste test is part of the science, too.',
    healthpotion_name: 'XL Healing Potion',
    healthpotion_desc: 'Hero tested, mother approved.',
    victorianpotion_name: 'Elegant Tea Set',
    victorianpotion_desc:
        'Also lifts a great burden from your shoulder, but in style!',
    magicpotion_name: 'XL Magic Potion',
    magicpotion_desc: 'The magic ingredient is love.',
    poloniapotion_name: "Old Salt's Brew",
    poloniapotion_desc:
        'Contains the daily recommended intake of vitamin C.',
    largehealthpotion_name: 'Phoenix Tonic',
    largehealthpotion_desc:
        'Just when you thought you were out, this potion pulls you back in.',
    cincopotion_name: 'Zesty Granita',
    cincopotion_desc: 'Best Before: Approximately 5 minutes from now.',
    pumpkinpotion_name: 'Gourd Elixir',
    pumpkinpotion_desc: 'The cucurbitaceaen cure.',
    vialofrenewal_name: 'Potion of Renewal',
    vialofrenewal_desc:
        "Grants the immediate benefits of a full night's rest. Also cures hangovers.",
    pyramidpotion_name: 'Bottled Mirth',
    pyramidpotion_desc:
        'Distilled joy and happiness in liquid form... the number one Monday cure!',
    purplebomb_name: 'Purple Bomb',
    purplebomb_desc:
        'It has a very strong aftertaste... wait, what is the wick for?',
    soulfireextract_name: 'Soulfire Extract',
    soulfireextract_desc:
        'A dose of raw passion. Jolts you back on your feet, no matter what.',
    lemonpotion_name: 'Tangy Decoction',
    lemonpotion_desc:
        'The shining spark of summertime, bottled for rainy days.',
    carpenterpotion_name: 'Oak Essence',
    carpenterpotion_desc:
        'Proud and unyielding. Just like its inventor, really.',
    gaiatonic_name: 'Gaia Tonic',
    gaiatonic_desc: 'Mother knows best!',
    batpotion_name: 'Midnight Oil',
    batpotion_desc: '...Why are those wings still flapping?',
    goldpotion_name: 'Opulent Elixir',
    goldpotion_desc:
        'Whatever ails you will be gone in a flash, leaving only a hole in your wallet.',
    starextract_name: 'Distilled Nebula',
    starextract_desc:
        'Warning! Do not store more than three (3) bottles together; risks of singularity.',
    yetipotion_name: 'Aurora Springwater',
    yetipotion_desc:
        'A single sip chills you down to your very soul. Cures burns like nothing else.',
    frogpotion_name: 'Nectar Pitcher',
    frogpotion_desc: 'Probably not a trap. Probably.',
    scrolloffire_name: 'Scroll of Sparks',
    scrolloffire_desc:
        'Not to be used on dry days. Only you can prevent forest fires.',
    forestscroll_name: 'Scroll of Cleansing',
    forestscroll_desc:
        'All the benefits of a Mintyleaf Herb without the aftertaste!',
    scrollofthunder_name: 'Scroll of Armor',
    scrollofthunder_desc:
        "Weightless ethereal armor for nerds who can't wear the real deal.",
    scrollofprotection_name: 'Scroll of Storms',
    scrollofprotection_desc:
        "The answer to any problem, so long as it's outdoors.",
    songofvalor_name: 'Song of Valor',
    songofvalor_desc:
        'Magically inspire your allies to do heroic deeds with these sick rap beats.',
    correspondence_name: 'Correspondence',
    correspondence_desc: 'Also adequately known as "snail mail".',
    scrollofice_name: 'Monster Manual',
    scrollofice_desc: 'The one stop to all your summoning needs.',
    swampscroll_name: 'Tome of the Night',
    swampscroll_desc:
        'A top favorite among the monsters living under your bed.',
    tomeofknowledge_name: 'Tome of Knowledge',
    tomeofknowledge_desc:
        'A magical encyclopedia with many more pages than physically possible. The table of content alone takes 300!',
    scrollofinvisibility_name: 'Tome of Secrets',
    scrollofinvisibility_desc:
        "A wizard's deepest secrets (and some spells!) are inscribed within.",
    christmascroll_name: 'Naughty or Nice List',
    christmascroll_desc: 'Your name is on the nice list!',
    scrolloflevitation_name: 'Bagua Board',
    scrolloflevitation_desc:
        'The world, the earth and their sons and daughters.',
    fireworksscroll_name: 'Firework Bundle',
    fireworksscroll_desc:
        "It isn't exactly magical, but it sure feels like it!",
    tarot_name: 'Tarot Deck',
    tarot_desc: '10 swords is a good sign, right?',
    vipscroll_name: 'Scroll of Fortune',
    vipscroll_desc:
        'Fortune favors the bold...and the bearer of this gilded parchment.',
    grimoireaeternum_name: 'Grimoire Aeternum',
    grimoireaeternum_desc:
        'Starting from the end gets you back to the beginning, but not the other way around...',
    waxtablet_name: 'Imperial Decree',
    waxtablet_desc: 'Veni. Vidi. Vici.',
    goldscroll_name: 'Luxurious Tablet',
    goldscroll_desc:
        'Ancient destructive spells carved into stone and inlaid with gold. Can also be used as a shield in a pinch.',
    celestialscroll_name: 'Ancestral Atlas',
    celestialscroll_desc:
        'Meticulously charted since the dawn of magic itself.',
    priestbook_name: 'Prayer Book',
    priestbook_desc: 'The Goddess would like to have a word.',
    celticscroll_name: 'Druidic Grimoire',
    celticscroll_desc:
        "Strong emphasis on shapeshifting spells. What's with the crows?",
    angelscroll_name: 'Celestial Choir',
    angelscroll_desc: "I hope you're a soprano!",
    peakscroll_name: 'Phoenix Litany',
    peakscroll_desc: 'Why do I smell toast?',
    freedomscroll_name: 'Sealed Declaration',
    freedomscroll_desc:
        'A high quality replica. The original is archived away in a secret vault, so that it can never be stolen.',
    lunarscroll_name: "Tiger Emperor's Edict",
    lunarscroll_desc:
        'The Emperor hereby declares that tigers are cute and fluffy. That is all.',
    allknowledge_name: 'Tome of All-Knowledge',
    allknowledge_desc:
        'This massive tome is said to contain knowledge on literally everything to ever exist. [Citation needed]',
    cuirass_name: 'Breastplate',
    cuirass_desc:
        'Protects against blunt weapons, piercing bolts and mean jokes.',
    ringmail_name: 'Iron Mail',
    ringmail_desc: 'They liked it, so they put a few rings on it.',
    chainmail_name: 'Hauberk',
    chainmail_desc: 'Tough armor designed to withstand the harshest blows.',
    scalemail_name: 'Scale Armor',
    scalemail_desc: 'Not to scale.',
    breastplate_name: 'Knight Breastplate',
    breastplate_desc: 'Protects the important bits.',
    swampheavyarmor_name: 'Ceremonial Breastplate',
    swampheavyarmor_desc: "They see me stylin'...",
    winterarmor_name: 'Blizzard Armor',
    winterarmor_desc: 'Always cold to the touch... but not to you.',
    centurionarmor_name: 'Centurion Armor',
    centurionarmor_desc:
        'Shredded abs are a sight for all to see. Or so the Emperor says.',
    halfplate_name: 'Paladin Plate',
    halfplate_desc: 'Full plate and packing steel.',
    beeheavyarmor_name: 'Hive Guard Plate',
    beeheavyarmor_desc:
        'You feel a sudden urge to protect the queen at any cost.',
    ronindo_name: 'Samurai Do',
    ronindo_desc: 'The unmistakable outfit of a Samurai.',
    pyramidheavyarmor_name: 'Brinewater Do',
    pyramidheavyarmor_desc: 'Still water runs deep.',
    atlab_heavyarmor_name: 'Kyoshi Warrior Armor',
    atlab_heavyarmor_desc:
        'The iconic uniform of the Kyoshi Warriors, who have sworn to protect Kyoshi Island.',
    generalplate_name: 'General Plate',
    generalplate_desc:
        "They say that the clothes do not make the man, but a general's armor is an important exception.",
    oktoberfestplate_name: 'Landsknecht Plate',
    oktoberfestplate_desc: 'The distinctive armor of an elite mercenary.',
    lunararmor_name: 'Warlord Plate',
    lunararmor_desc:
        'The armor of the legendary Flying General, reputed for his martial feats and short temper.',
    berserkerarmor_name: 'Berserker Armor',
    berserkerarmor_desc:
        'Keeps you relatively safe while frothing about on the battlefield.',
    castleheavyarmor_name: 'Gaia Enforcer',
    castleheavyarmor_desc:
        'The natural order of things must be maintained. At all costs.',
    freedomplate_name: 'Star-Spangled Plate',
    freedomplate_desc: 'The whole kingdom stands behind you!',
    juggernaut_name: 'Juggernaut Fortress',
    juggernaut_desc: 'Yeah, nothing is getting past that.',
    templearmor_name: 'Amber Citadel',
    templearmor_desc:
        'Easily enforce your personal space with searing heat.',
    goldplate_name: 'Opulent Breastplate',
    goldplate_desc:
        "If you're flaunting all that wealth... might as well make it useful!",
    celticheavyarmor_name: 'Milesian Mail',
    celticheavyarmor_desc: 'Surprisingly light and breathable. Freedom!',
    celestialplate_name: 'Celesteel Plate',
    celestialplate_desc: 'Its clanking is especially harmonious.',
    dogheavyarmor_name: 'Canid Plate',
    dogheavyarmor_desc: 'You may rub the tummy.',
    atlab_fireplate_name: 'Fire Nation Armor',
    atlab_fireplate_desc:
        'The distinctive uniform of a Fire Nation soldier.',
    ironcap_name: 'Sturdy Cap',
    ironcap_desc:
        'Will keep you safe sixty percent of the time, every time.',
    helmet_name: 'Warrior Helmet',
    helmet_desc: 'Safety first!',
    horned_name: 'Horned Helm',
    horned_desc: 'Can also be used as a weapon!',
    vikinghelm_name: 'Raider Helm',
    vikinghelm_desc: 'Strike fear into the hearts of your enemies.',
    knighthelm_name: 'Knight Heaume',
    knighthelm_desc:
        "Sure, it provides little to no visibility, but at least you'll look good!",
    swamphelm_name: 'Brinewater Helm',
    swamphelm_desc:
        'An advanced, no-rust design borrowed from the merfolks.',
    eaglecowl_name: 'Eagle Helm',
    eaglecowl_desc: 'Eagle screech!',
    couplehelmet_name: 'Kodiak Helmet',
    couplehelmet_desc:
        'Like a furious beast, love so fierce cannot be contained.',
    atlab_firehelm_name: 'Fire Nation Helm',
    atlab_firehelm_desc: "You won't see a Fire Nation soldier without one!",
    paladinhelm_name: 'Paladin Helm',
    paladinhelm_desc: 'Features built-in evil detection technology.',
    kabuto_name: 'Samurai Kabuto',
    kabuto_desc: 'Tighten the string of the Kabuto after winning the war.',
    gladiatorhelm_name: 'Gladiator Helm',
    gladiatorhelm_desc:
        'Are you not entertained? Is this not why you are here?',
    generalheaume_name: 'General Heaume',
    generalheaume_desc: 'The tuft separates the chief from the chaff.',
    ruinshelm_name: 'Crusader Helmet',
    ruinshelm_desc: 'When the Goddess calls, the righteous answer.',
    lunarhelmet_name: 'Warlord Helmet',
    lunarhelmet_desc:
        'Unmatched in strength (and style!) across the three kingdoms.',
    berserkerhelm_name: 'Berserker Helmet',
    berserkerhelm_desc:
        'All warriors know to use their heads in the heat of battle. Berserkers are not very good at metaphors.',
    centurionhelmet_name: 'Centurion Helmet',
    centurionhelmet_desc:
        'Its ingenious design efficiently cleans low ceilings as you come and go.',
    juggernauthelm_name: 'Juggernaut Furnace',
    juggernauthelm_desc: 'Protection? Amazing. Visibility? Lackluster.',
    thanksgivinghelmet_name: 'Gobble Lord Helm',
    thanksgivinghelmet_desc:
        'Assert dominance over all sorts of fat birds.',
    goldhelmet_name: 'Opulent Heaume',
    goldhelmet_desc: "It won't protect you from impulsive buying.",
    celestialhelm_name: 'Celesteel Heaume',
    celestialhelm_desc: 'Honestly, far more practical than a halo.',
    doghelm_name: 'Canid Helmet',
    doghelm_desc: 'Its bark is worse than its bite.',
    valentinehelm_name: "Cupid's Heaume",
    valentinehelm_desc: 'Even Cupids need to stay safe while on the job.',
    ironbracers_name: 'Iron Armguards',
    ironbracers_desc: 'Basic, protective gear for the warrior on a budget.',
    lightgauntlets_name: 'Plated Gauntlets',
    lightgauntlets_desc: "Feels like you're wearing nothing at all.",
    gauntlets_name: 'Warrior Gauntlets',
    gauntlets_desc: 'One size fits all!',
    demigauntlets_name: 'Demi Gauntlets',
    demigauntlets_desc: 'Keeps your digits free to perform rude gestures.',
    pumpkingauntlets_name: 'Pumpkin Gauntlets',
    pumpkingauntlets_desc:
        'Candies seem to disappear within its vicinity...',
    knightgauntlets_name: 'Knight Gauntlets',
    knightgauntlets_desc: 'Slightly less expensive than Day Gauntlets.',
    christmasgauntlets_name: "Santa's Guard Gauntlets",
    christmasgauntlets_desc:
        'Not to be underestimated. They pack a literal, if jingly, punch.',
    paladingauntlets_name: 'Paladin Gauntlets',
    paladingauntlets_desc: 'Holy light in the palm of your hands.',
    indiadaygauntlets_name: 'Republic Gauntlets',
    indiadaygauntlets_desc: 'Seize all the possibilities.',
    desertgauntlets_name: 'Sungrasp Gauntlets',
    desertgauntlets_desc: 'Also known as the Day Gauntlets.',
    oktoberfestgauntlets_name: 'Landsknecht Gauntlets',
    oktoberfestgauntlets_desc:
        "Deftly handles a pike, arquebuse or a day's wage.",
    kote_name: 'Samurai Kote',
    kote_desc: 'Uphold the eight virtues. Through battle, and unto death.',
    generalgauntlets_name: 'General Gauntlets',
    generalgauntlets_desc: 'Drop and give me twenty.',
    berserkergauntlets_name: 'Berserker Gauntlets',
    berserkergauntlets_desc:
        'Adds some spice to your backhanded compliments.',
    goldgauntlets_name: 'Luxurious Gauntlets',
    goldgauntlets_desc:
        'Make half of your problems go away with a snap of the fingers.',
    juggernautgauntlets_name: 'Juggernaut Grip',
    juggernautgauntlets_desc:
        'Keep your friends close and your enemies closer. Much closer.',
    wintergauntlets_name: "Winter's Touch",
    wintergauntlets_desc:
        'Will keep your drink ice cold, whether you like it or not.',
    celestialgauntlets_name: 'Celesteel Gauntlets',
    celestialgauntlets_desc: 'Salvation is at hand!',
    naturegauntlets_name: "Gaia's Hold",
    naturegauntlets_desc: 'The soft touch of a mother, encased in basalt.',
    fallgauntlets_name: 'Vanguard Gauntlets',
    fallgauntlets_desc:
        "Science said it wasn't possible to make gauntlets out of leather, iron and herbs. Science was wrong.",
    shinguards_name: 'Shin Guards',
    shinguards_desc: 'Provides immunity against toddlers and small dogs.',
    longboots_name: 'Long Boots',
    longboots_desc: "You wouldn't want an arrow in the knee, would you?",
    grieveboots_name: 'Iron Greaves',
    grieveboots_desc: 'Keeps your shins protected from pint-sized foes.',
    heroboots_name: "Hero's Boots",
    heroboots_desc:
        'Very protective, but will prevent you from running from boss battles.',
    heavyboots_name: 'Reinforced Greaves',
    heavyboots_desc:
        'The sound from these boots leave enemies shaking in their... boots.',
    circusboots_name: 'Plated Squeakers',
    circusboots_desc: 'Their aggro potential is next to none, but...',
    knightboots_name: 'Knight Sollerets',
    knightboots_desc: "You'll get a kick out of these.",
    swampboots_name: 'Cindersole',
    swampboots_desc:
        'Sear your footprints onto any surface! Be the easiest Hero to track in the entire kingdom!',
    lunargreaves_name: 'Warlord Boots',
    lunargreaves_desc:
        'Boots worn by the legendary Flying General, said to be constantly on the lookout for a decent challenge.',
    paladinboots_name: 'Paladin Boots',
    paladinboots_desc: 'Divine comfort while doing holy work.',
    dogboots_name: 'Canid Paws',
    dogboots_desc:
        'The claws may need occasional clipping if not worn regularly.',
    haidate_name: 'Samurai Haidate',
    haidate_desc:
        'The honorable Samurai never turns back. The only way forward is through.',
    bfheavyboots_name: 'Topaz Greaves',
    bfheavyboots_desc:
        'In another game, it would probably increase your lightning resistance.',
    generalgreaves_name: 'General Greaves',
    generalgreaves_desc:
        'Make your arrival known with these super-heavy, extra-noisy boots.',
    berserkerboots_name: 'Berserker Stompers',
    berserkerboots_desc:
        "Adds a satisfying crunch when you step on people's toes.",
    goldboots_name: 'Luxurious Boots',
    goldboots_desc:
        'Cute little booties weighting approximately 25lbs. Each.',
    fallboots_name: 'Vanguard Greaves',
    fallboots_desc: 'Always one step ahead.',
    juggernautboots_name: 'Juggernaut Greaves',
    juggernautboots_desc:
        'Could be classified as blunt weapons of their own.',
    bunnyboots_name: 'Bunbun Booties',
    bunnyboots_desc:
        "The bunny motifs didn't vibe with the warrior crowd, but its spirit still protects the wearer.",
    celestialboots_name: 'Celesteel Boots',
    celestialboots_desc: 'Tread where Angels fear to.',
    freedomboots_name: 'Star-Spangled Greaves',
    freedomboots_desc:
        'They feel quite liberating to take off after a long day of questing.',
    christmasboots_name: "Santa's Guard Boots",
    christmasboots_desc:
        'Santa employs an elite guard of elves to protect him from anyone that would try to ruin Christmas.',
    leatherarmor_name: 'Leather Armor',
    leatherarmor_desc:
        'Lightweight armor favored by those who prefer mobility in battle.',
    padded_name: 'Gambeson',
    padded_desc: 'Padded jacket used by duelists and archers.',
    studded_name: 'Studded Armor',
    studded_desc: 'Basic leather armor enhanced with dense, metal rivets.',
    doublet_name: 'Doublet',
    doublet_desc:
        'Nothing says "I\'m important, look at me!" like a fancy doublet.',
    hide_name: 'Hide Armor',
    hide_desc: 'Watch out for red paint.',
    mundraarmor_name: "Mundra's Tabard",
    mundraarmor_desc:
        "A lighter version of Mundra's armor designed for humans of... flimsier constitution.",
    savagearmor_name: 'Savage Garb',
    savagearmor_desc: 'You are the apex predator now.',
    desertmediumarmor_name: 'Glade Guard Armor',
    desertmediumarmor_desc:
        'Sacred armor worn by elven rangers guarding the most secret retreats.',
    pumpkinarmor_name: 'Pumpkin Armor',
    pumpkinarmor_desc: "You're never too old for Halloween.",
    ninjagarb_name: 'Ninja Garb',
    ninjagarb_desc:
        'Always remember the #1 ninja rule: lone ninjas are way more dangerous!',
    batarmor_name: 'Nightwing Mantle',
    batarmor_desc: 'Did you hear something?',
    windrunnerarmor_name: 'Windrunner Armor',
    windrunnerarmor_desc:
        'Become one with the wind; dedicate your soul to the chase.',
    atlab_armor_name: "Sokka's Outfit",
    atlab_armor_desc:
        'A traditional Water Tribe outfit, modified for warmer weather.',
    dragonarmor_name: 'Drakeskin Armor',
    dragonarmor_desc:
        'Offers splendid defense at the cost of an overinflated sense of self-worth.',
    smithapron_name: 'Smith Attire',
    smithapron_desc:
        'The crafting is sometimes immediately followed by field testing.',
    captainarmor_name: 'Corsair Mantle',
    captainarmor_desc:
        'Billows dramatically when a worthy wearer helms a mighty vessel.',
    siaarmor_name: "Swan's Garment",
    siaarmor_desc: 'Undeniably striking, dazzling and mesmerizing.',
    lunarmedarmor_name: 'Cloudwalker Armor',
    lunarmedarmor_desc:
        'Will let you glide effortlessly to the ground, no matter the height.',
    jackalopearmor_name: 'Everdusk Lord Attire',
    jackalopearmor_desc:
        'The Cult of the Everdusk has a special affinity for demonic creatures, especially imps.',
    goldroguearmor_name: 'Opulent Armor',
    goldroguearmor_desc: 'Unnecessarily ornate. Just how you like it.',
    troblinarmor_name: "Barkback's Armor",
    troblinarmor_desc:
        'Once worn by the smartest Troublin Chieftain, Barkback. So smart, in fact, that he figured out that wearing armor would keep him alive longer. Has a tendency for getting munched on by horses and the likes.',
    catarmor_name: 'Cat Burglar Outfit',
    catarmor_desc: 'No catnip is safe.',
    tunic_name: 'Shirt',
    tunic_desc: 'Better than nothing at all...',
    robe_name: 'Black Robe',
    robe_desc: 'For the adventurer who enjoys the finer things in life.',
    druidic_name: "Druid's Robe",
    druidic_desc: 'Embroidered with ancient druidic markings.',
    disciplerobe_name: "Disciple's Robe",
    disciplerobe_desc: 'Simple garment created to honor the gods.',
    exploreroutfit_name: "Explorer's Outfit",
    exploreroutfit_desc:
        'Has a convenient assortment of pockets and pouches for the seasoned adventurer.',
    tailorarmor_name: 'Tailor Mantle',
    tailorarmor_desc: 'Must be masterpiece Julia is always talking about.',
    witchrobe_name: "Witch's Outfit",
    witchrobe_desc: 'Ribbits can be heard from one of these pockets...',
    wizardrobe_name: 'Wizard Attire',
    wizardrobe_desc: 'How did you come across this again?',
    desertlightarmor_name: 'Vestal Raiments',
    desertlightarmor_desc:
        'Tenders of the holy fire don these magical clothes during sacred rites.',
    scholartunic_name: "Scholar's Tunic",
    scholartunic_desc:
        'An easy way to look way smarter than you actually are.',
    midnightapparel_name: 'Midnight Apparel',
    midnightapparel_desc:
        'Live your dream of being the prettiest mage at the ball.',
    atlab_robe_name: "Aang's Robe",
    atlab_robe_desc:
        'A simple robe worn by Air Nomad monks. Required some refitting by Julia to make it fit adults.',
    shamanvestment_name: 'Shaman Vestment',
    shamanvestment_desc:
        'Each shaman wears a unique design tailored from bits and pieces of the local fauna.',
    goldvestments_name: 'Luxurious Attire',
    goldvestments_desc: "It's tough to be a god.",
    astravestimenta_name: 'Astravestimenta',
    astravestimenta_desc:
        'Embraced by the cosmos and shouldering the weight of the world.',
    easterarmor2020_name: 'Ostara Vest',
    easterarmor2020_desc:
        "With proper armor, you don't need a comeback plan.",
    arabiantunic_name: 'Desert Threads',
    arabiantunic_desc: 'Handles scorching heat and freezing cold in style.',
    majesticraiments_name: 'Archmage Raiments',
    majesticraiments_desc:
        'Repels projectiles, deflects incoming attacks and even keeps itself sparkling clean!',
    indiadayarmor_name: 'Republic Garments',
    indiadayarmor_desc: 'Colorful and powerful!',
    djinnrobes_name: 'Djinn Robes',
    djinnrobes_desc:
        'These robes offer powerful protections. Just don\'t say "I wish" out loud ever again.',
    moonrobes_name: 'Moonlight Kimono',
    moonrobes_desc:
        'A proper attire to welcome the bountiful harvest moon.',
    lunarrobe_name: "Tiger Emperor's Robe",
    lunarrobe_desc: 'Finally, an emperor that wears clothes!',
    leathercap_name: 'Leather Cap',
    leathercap_desc: 'Simple cap made from scraps.',
    forestroguehat_name: 'Canopy Cap',
    forestroguehat_desc:
        "You can't beat this airflow. Your scalp will thank you!",
    hat_name: 'Brimmed Hat',
    hat_desc: "It's just a hat. Nothing special here. Move along.",
    featheredhat_name: 'Feathered Hat',
    featheredhat_desc: 'The height of sophistication.',
    tricorn_name: 'Tricorn',
    tricorn_desc: "It's making a comeback in the fashion world.",
    hood_name: 'Silk Hood',
    hood_desc: "You've gone incognito! Feel free to browse privately.",
    christmasroguehat_name: "Santa's Elf Hat",
    christmasroguehat_desc:
        'A facet of elven fashion that Ismael does not approve.',
    piratehat_name: 'Pirate Hat',
    piratehat_desc:
        'The unauthorized reproduction or distribution of copyrighted work is illegal.',
    valentinehat_name: 'Lovely Hat',
    valentinehat_desc: 'A perfect fit for date (or raid!) night.',
    nightcowl_name: 'Night Cowl',
    nightcowl_desc: 'A night cowl for the night owl.',
    pyramidroguehat_name: 'Nomad Veil',
    pyramidroguehat_desc: 'Laugh away the harsh conditions of any climate.',
    farmerhood_name: "Harvester's Hood",
    farmerhood_desc:
        'Put it on for harvest season! Whether it be veggies... or souls.',
    windrunnerhat_name: 'Windrunner Hat',
    windrunnerhat_desc:
        "It's superior aerodynamism increases speed by roughly 1%.",
    atlab_spiritmask_name: 'Blue Spirit Mask',
    atlab_spiritmask_desc:
        'A mask worn by a mysterious and unexpected ally who freed Aang from the Pohuai Stronghold.',
    dragonhat_name: 'Drakeskin Mask',
    dragonhat_desc:
        'See the world through the eyes of the wise, beware the mirror that reflects a lust for more.',
    castleroguehat_name: 'Storm Dragon Mask',
    castleroguehat_desc:
        'The mere sight of a Storm Dragon scale is a terrible omen to coastal cities and fishing villages.',
    oktoberhat_name: 'Grand Tyrolean',
    oktoberhat_desc:
        'Amplifies war cries as if in a large mountain range. Not recommended for stealth missions.',
    captainhat_name: 'Corsair Tricorn',
    captainhat_desc: 'Nothing says "I\'m the captain" like this hat.',
    indiadayhat_name: 'Republic Coiffe',
    indiadayhat_desc: 'Knowledge abounds when originating from all around.',
    jackalopehat_name: 'Everdusk Cowl',
    jackalopehat_desc:
        'In lieu of horns, a cowl can be used to signify rank in evil cults.',
    peakroguehat_name: 'Abyssal Hood',
    peakroguehat_desc:
        'Awarded to the highest member of the Everdusk Cult. It allows command over demons, if you dare summon one...',
    cincoroguehat_name: 'Rogue Sombrero',
    cincoroguehat_desc:
        "The stealth factor isn't its strong suit, but you can be the life of any party!",
    goldroguehat_name: 'Opulent Sallet',
    goldroguehat_desc:
        'The design is based off old blueprints found in ancient ruins.',
    catroguehat_name: 'Cat Burglar Hood',
    catroguehat_desc:
        "Sleek, muffled design. Integrated night vision. If only that bell didn't jingle...",
    leathercone_name: 'Stitched Cone',
    leathercone_desc: 'For the budding mage... or the class dunce.',
    blackcowl_name: 'Black Cowl',
    blackcowl_desc: "You're not fooling anyone, necromancer.",
    laurels_name: 'Druid Laurels',
    laurels_desc: "Don't rest on them, they're pretty fragile.",
    startertiara_name: 'Apprentice Tiara',
    startertiara_desc:
        'Standard apparel from the Academy; each student is expected to craft their own!',
    circlet_name: 'Bronze Circlet',
    circlet_desc:
        'Bolsters concentration, despite being quite uncomfortable.',
    wizardcone_name: 'Wizard Hat',
    wizardcone_desc: 'Tall and tacky, just the way wizards like it.',
    swamphat_name: 'Hat of the Four Winds',
    swamphat_desc:
        "Any way the wind blows doesn't really matter to this hat.",
    bunnyhat_name: 'Ostara Hat',
    bunnyhat_desc: 'The ears sometimes twitch.',
    liluhat_name: 'Owl Cowl',
    liluhat_desc: "Pollux's wisdom AND cuteness.",
    witchhat_name: 'Witch Hat',
    witchhat_desc: 'Broad brimmed to hide a malevolent smile.',
    frogmagehat_name: 'Waterproof Lilycap',
    frogmagehat_desc:
        'Much like water flows right of the cap, the cap slips right off your head, too.',
    midnighthat_name: 'Tactician Hat',
    midnighthat_desc: 'Do you think love can bloom, even on a battlefield?',
    summonerhat_name: 'Summoner Hat',
    summonerhat_desc:
        "The Summoner Hat excels at one thing above all else; showing everyone who's the boss.",
    shamanhat_name: 'Shaman Hat',
    shamanhat_desc: 'The spirits speak. Will you answer?',
    goldhat_name: 'Luxurious Headdress',
    goldhat_desc: 'Blessed with tail feathers from Quetzalcoatl itself.',
    astralhat_name: 'Astral Hat',
    astralhat_desc:
        'Imparts its wearer with mystic knowledge plucked from the very stars.',
    plaguemask_name: 'Raven Mask',
    plaguemask_desc: 'Smells pretty nice in there!',
    schoolhat2020_name: 'Spellward Hat',
    schoolhat2020_desc:
        'Many arcane incantations were added to this hat to protect from harm and bad grades.',
    tiara_name: 'Opal Diadem',
    tiara_desc:
        'The stone shimmers when in the presence of powerful magic.',
    bathat_name: 'Night Crown',
    bathat_desc: "You really shouldn't try to fly with this.",
    djinnhat_name: 'Djinn Veil',
    djinnhat_desc: 'The central jewel sometimes shimmers with malice.',
    victorianmagehat_name: 'Elegant Top Hat',
    victorianmagehat_desc: 'No, it will not produce a bunny.',
    snowmagehat_name: "Ice Queen's Summer Hat",
    snowmagehat_desc:
        'Keeps your head nice and frosty, even under intense sunlight sun. Mostly uncomfortable for anyone other than the Ice Queen.',
    leathergloves_name: 'Leather Gloves',
    leathergloves_desc: "Don't get your hands dirty.",
    bracers_name: 'Bracers',
    bracers_desc: 'Protective guard used primarily in archery.',
    thiefgloves_name: "Thief's Gloves",
    thiefgloves_desc: 'The five finger discount now made easy.',
    grottogloves_name: 'Swift Mitts',
    grottogloves_desc:
        'Generates vicious static if you rub your hands like a villain.',
    patchworkgloves_name: 'Patchleather Bracers',
    patchworkgloves_desc: "They're comfy and easy to wear.",
    studdedleathergloves_name: 'Studded Gloves',
    studdedleathergloves_desc: 'Packs a real punch!',
    lunargloves_name: 'Regal Bangles',
    lunargloves_desc:
        'Can only be fastened with magic, so Sparkless Heroes will need to ask their mom for help.',
    vambraces_name: 'Elven Vambraces',
    vambraces_desc: 'A must for any serious duelist.',
    eastergloves2020_name: 'Ostara Gloves',
    eastergloves2020_desc:
        'A most delicate touch... Perfect for egg hunting!',
    savagegloves_name: 'Savage Claws',
    savagegloves_desc: 'Claw your way to the top of the food chain.',
    desertgloves_name: 'Black Wyrm Claws',
    desertgloves_desc: 'Considered a weapon in most kingdoms!',
    ninjagloves_name: 'Ninja Gloves',
    ninjagloves_desc: 'Lightly padded to safely handle poisoned shurikens.',
    catgloves_name: 'Cat Burglar Claws',
    catgloves_desc:
        'Adept at climbing, and adds a little kick to your punch.',
    windrunnergloves_name: 'Windrunner Gloves',
    windrunnergloves_desc:
        'Made for climbing, swimming, riding and tree swinging.',
    dragongloves_name: 'Drakeskin Gloves',
    dragongloves_desc:
        'Naturally adroit at handling gold coins and all sorts of precious currency.',
    oktobergloves_name: 'Mountain Man Mitts',
    oktobergloves_desc:
        'Specially enchanted to never let go of a mug, even in the midst of battle!',
    captaingloves_name: 'Corsair Gloves',
    captaingloves_desc: 'These stay warm and dry even after weeks at sea.',
    vipgloves_name: 'Fortunate Gloves',
    vipgloves_desc: 'Perfect for twirling a coin ominously!',
    goldgloves_name: 'Opulent Grasp',
    goldgloves_desc:
        "The bling don't go well with stealth. But it looks real good.",
    jackalopegloves_name: 'Everdusk Gloves',
    jackalopegloves_desc:
        "There's no getting the scent of sulfur out of these.",
    birdgloves_name: 'Raptor Wings',
    birdgloves_desc: 'Fight or flight? Both.',
    halloweengloves_name: 'Nightwing Vambraces',
    halloweengloves_desc:
        'There is no more comfortable handwear when flipping through pages and pages of fanmail. Vampires have been getting a lot of it ever since that one book came out.',
    leatherboots_name: 'Leather Boots',
    leatherboots_desc: 'Protects you from glass and stray nails.',
    forestshoes_name: 'Trailblazers',
    forestshoes_desc:
        'There are those who take the beaten path, and there are those who create it.',
    sandals_name: 'Flip-Flops',
    sandals_desc: 'Socks sold separately.',
    softshoes_name: 'Soft Shoes',
    softshoes_desc: "Feels like you're walking on a cloud!",
    romansandals_name: 'Legionnaire Sandals',
    romansandals_desc:
        'All roads lead to Rome. All that remains is to walk there.',
    thiefshoes_name: "Thief's Shoes",
    thiefshoes_desc:
        'So quiet you could leave a party early without anyone noticing.',
    flowersandals_name: 'Perennial Sandals',
    flowersandals_desc: 'Adds spring to your steps. Pun intended.',
    elvenshoes_name: 'Elven Shoes',
    elvenshoes_desc: 'Said to make you walk a few inches off the ground.',
    moonshoes_name: 'Moonlight Zōri',
    moonshoes_desc: 'Reach for the moon.',
    savageshoes_name: 'Savage Stride',
    savageshoes_desc: 'Run like the wind blows.',
    blossomshoes_name: 'Sakura Tabi',
    blossomshoes_desc: '',
    arabianshoes_name: 'Sultan Steps',
    arabianshoes_desc:
        'These fine enchanted shoes can endure the scalding heat of sun-scorched sands.',
    ninjatabi_name: 'Ninja Tabi',
    ninjatabi_desc:
        'The legendary stealth of a ninja begins with a sneaky stride.',
    windrunnerboots_name: 'Windrunner Boots',
    windrunnerboots_desc:
        'Peculiar boots meant for long distance travel. Immune to the wear and tear of the road.',
    ruinsshoes_name: 'Alpine Stride',
    ruinsshoes_desc: 'Stride so wide, you may as well be flying.',
    dragonshoes_name: 'Drakeskin Boots',
    dragonshoes_desc:
        'Actually made of very convincing synthetic materials to combat dragon poaching.',
    castleshoe_name: 'Plaguewalkers',
    castleshoe_desc:
        'The natural order of things must be disturbed. At all costs.',
    cincoshoes2020_name: 'Shoes of Style',
    cincoshoes2020_desc:
        'Watch out when wearing these in battle; they just want to dance away.',
    captainshoes_name: 'Corsair Boots',
    captainshoes_desc: 'Prevents any unfortunate slip and dip.',
    lunarboots_name: 'Cloudwalker Steps',
    lunarboots_desc: 'Cirrus, cumulus, stratus... All within your purview.',
    jackalopeshoes_name: 'Everdusk Boots',
    jackalopeshoes_desc:
        'The Cult of the Everdusk seeks to plunge the world in darkness, where the only light would be the fires under their control.',
    birdshoes_name: 'Raptor Talons',
    birdshoes_desc:
        'May look out of place if worn without the Raptor Wings.',
    goldshoes_name: 'Opulent Footwraps',
    goldshoes_desc:
        'Featuring the patented Tenochtitlan Toeguard Technology.',
    woodshield_name: 'Wooden Shield',
    woodshield_desc: 'May or may not be a repurposed broken door.',
    buckler_name: 'Heavy Buckler',
    buckler_desc: 'Strong and dependable. What more do you need?',
    oakshield_name: 'Oaken Shield',
    oakshield_desc:
        'Said to be made from the heart of the ancient oak trees.',
    ironshield_name: 'Iron Shield',
    ironshield_desc:
        'A well crafted iron shield can last you a hundred battles.',
    grottoshield_name: 'Aurum Ward',
    grottoshield_desc:
        'Such bling comes with a hefty weight. Also price. But mostly weight.',
    kiteshield_name: 'Teardrop Shield',
    kiteshield_desc: 'Beware of strong winds.',
    imperialscutum_name: 'Imperial Scutum',
    imperialscutum_desc: 'Testudo formation, go!',
    argonshield_name: 'Champion Vigil',
    argonshield_desc:
        'Forged with a twin purpose: To protect the weak and battle evil.',
    largebuckler_name: 'Mythril Aspis',
    largebuckler_desc:
        'Made from an elegant alloy of mythril and gold.  Suitable for a king!',
    natureshield_name: 'Gaia Aegis',
    natureshield_desc:
        '"Fragrant protection" is not a key selling point you get to use often!',
    towershield_name: 'Wyrmguard',
    towershield_desc: 'The iconic shield of the mythical dragon riders.',
    fallshield_name: 'Vanguard Buckler',
    fallshield_desc:
        'Not actually made of real leaves. It would make for a poor shield.',
    ancestortotem_name: 'Ancestor Totem',
    ancestortotem_desc:
        'Let the ancestor warriors guide you through battle.',
    zealouspavise_name: 'Angelic Pavise',
    zealouspavise_desc:
        'Faith can be a tremendous shield... but the giant slab of Celesteel also helps.',
    castleshield_name: 'Prometheon',
    castleshield_desc: 'Forged by a fallen angel over volcanic heat.',
    stpatrickshield_name: 'Milesian Shield',
    stpatrickshield_desc:
        'A relic of ages past, blessed by three goddess of a distant land.',
    eastershield2020_name: 'Bunbun Buckler',
    eastershield2020_desc: 'Bunbun loves. Bunbun protects.',
    goldshield_name: 'Luxurious Aegis',
    goldshield_desc:
        'Provides stellar protection against everything not electricity.',
    prideshield_name: 'Goldmane Guard',
    prideshield_desc:
        'If you are to lead the pack, might as well do it in style.',
    dragonshield_name: 'Emperor Wyrmguard',
    dragonshield_desc:
        'Wielded only by the leader of the dragon riders. Its very existence is thought to be a myth.',
    paragon_name: 'Bearded Paragon',
    paragon_desc: 'By my ancestors!',
    beeshield_name: 'Honeycomb Defender',
    beeshield_desc:
        'According to all known laws of aviation, there is no way this shield could fly. Indeed, it does not.',
    snowshield_name: 'Crystalice Targe',
    snowshield_desc:
        "A powerful Crystalice gem is needed to power this shield's cooling aura.",
    squirecloak_name: 'Adventurer Cape',
    squirecloak_desc:
        'A simple, cheap cape for adventurers with little money or children with big dreams.',
    wanderercloak_name: 'Administrator Cloak',
    wanderercloak_desc:
        'A simple status symbol for pencil pushers all over.',
    druidcloak_name: 'Druid Cloak',
    druidcloak_desc: "Fully organic, if that's your thing.",
    empirecloak_name: 'Imperial Cape',
    empirecloak_desc:
        'Not too useful, but certainly looks sharp. All the rage among Imperial nobles at the moment.',
    elvencloak_name: 'Elven Cloak',
    elvencloak_desc:
        "A delicate elven design with complex golden filigree. Somehow augments the user's stealth.",
    furcloak_name: "Beast King's Cloak",
    furcloak_desc:
        'Originally worn by a famous Beastmaster Barbarian. There have been many imitations since.',
    scalecloak_name: 'Dragonlord Crest',
    scalecloak_desc:
        'Grants great authority over dragonkind, but beware those who have not earned it!',
    bishopcloak_name: 'Archbishop Mantle',
    bishopcloak_desc:
        'Worn by high-ranking clergy. The crystal is quite fragile!',
    starcloak_name: 'Voidcloth From Beyond',
    starcloak_desc: 'When you gaze long into the abyss...',
    goldcloak_name: 'Opulent Cloak',
    goldcloak_desc:
        'Heavy is the burden of the monarch. This cloak may be part of the reason.',
    frogcloak_name: 'Woven Lilycloak',
    frogcloak_desc:
        'Giant lilypads are grown in sacred groves, deep within secret confines of the marshes. Weaving them into clothes is an art thought to be long lost.',
    ironring_name: 'Iron Ring',
    ironring_desc: 'Dismiss suitors on the cheap!',
    doublering_name: 'Alloy Loop',
    doublering_desc: 'Gold and silver intertwined in a wealthy duality.',
    fatring_name: 'Ruby Ring',
    fatring_desc: "Don't be fooled by cheap garnet imitations.",
    grottoring_name: 'Emerald Ring',
    grottoring_desc: "Don't be fooled by cheap peridot imitations.",
    silverband_name: 'Silver Band',
    silverband_desc:
        'Has seen a sharp decline in popularity among werewolves.',
    valentinering_name: 'Ring of Passion',
    valentinering_desc: 'Awakens its true power when given to a soulmate.',
    noblering_name: 'Noble Ring',
    noblering_desc:
        'Just wearing it gives you a smug sense of superiority.',
    cincoring2020_name: 'Ring of Rhythm',
    cincoring2020_desc:
        "Enhances the wearer's musical ability and temporarily breaks the curse of beat and tone deafness.",
    knightsignet_name: 'Knight Signet',
    knightsignet_desc: 'Bears the seal of King Reinhold himself.',
    freedomring_name: 'Ring of Liberty',
    freedomring_desc: 'Not suitable as a wedding ring.',
    batrachitestone_name: 'Batrachite Stone',
    batrachitestone_desc:
        'Its unique gemstone can only be found in the stomach of giant frogs.',
    pyramidring_name: 'Soulstone Ring',
    pyramidring_desc: 'Something stirs within that gemstone...',
    chosenring_name: 'Ring of the Chosen',
    chosenring_desc:
        "The mark of a special hero chosen by destiny. Also gives you the power to enter people's homes and steal their stuff.",
    sulfuritecoil_name: 'Brimstone Coil',
    sulfuritecoil_desc:
        'Equipped with state of the art scratch-and-sniff technology.',
    feyring_name: 'Fairfolk Band',
    feyring_desc: 'The symbol of a rare union between human and fey.',
    springflower_name: 'Dawnflower Ring',
    springflower_desc:
        "Don't lick the gemstone. Yes, you thought about it.",
    schoolring2020_name: 'Valedictorian Ring',
    schoolring2020_desc:
        'Awarded very sparingly by Evelyn to her most exceptional students.',
    goldring_name: 'Luxurious Signet',
    goldring_desc: 'Marked for greatness.',
    borealis_name: 'Borealis',
    borealis_desc:
        'It is said each of these ring captures a sliver of the Northern Lights.',
    templering_name: 'Northwind Gem',
    templering_desc:
        'The cold wind of the north is both a blessing and a curse.',
    winterring_name: 'Blizzard Ring',
    winterring_desc:
        "Refuses to be worn alongside mittens, but seems to tolerate the Winter's Touch.",
    bunnyring_name: 'Bunbun Band',
    bunnyring_desc: 'Makes you tap your feet frenetically all the time.',
    mysteriousring_name: 'Rubicon Prison',
    mysteriousring_desc:
        'What stirs within the multiple facets of this ring?',
    candyring_name: 'Crunchy Bracelet',
    candyring_desc:
        'Has no business being this powerful, but sweet-toothed fairies really packed this thing full of enchantments.',
    newyearring_name: 'Ring of New Beginnings',
    newyearring_desc: 'So, about your resolutions from last year...',
    ironamulet_name: 'Memento',
    ironamulet_desc:
        'The center pieces can be removed to insert a portrait.',
    pendant_name: 'Jade Pendant',
    pendant_desc:
        'Glows a weak, green light when it senses leylines nearby.',
    chain_name: 'Iron Bond',
    chain_desc:
        'Given by soldiers to their lovers as a promise of their safe return.',
    mundraamulet_name: "Mundra's Amulet",
    mundraamulet_desc: 'A favorite among lycanthropes.',
    sunpendant_name: 'Sun Pendant',
    sunpendant_desc: 'The sun is in your hand!',
    grottoamulet_name: 'Eclipse Amulet',
    grottoamulet_desc:
        "Awakens hidden powers when worn during an eclipse. Solar or lunar? The manual doesn't say.",
    noblechain_name: 'Noble Chain',
    noblechain_desc: 'Pair it up with the Noble Ring for maximum smugness.',
    medal_name: 'Medal of Honor',
    medal_desc:
        'A prestigious decoration awarded to the brave among the brave.',
    stpatrickmedallion_name: 'Lucky Medallion',
    stpatrickmedallion_desc: 'It really works, I swear!',
    goldamulet_name: 'Luxurious Charm',
    goldamulet_desc:
        "Said to bring good fortune to its bearer... which they obviously don't need.",
    holysymbol_name: 'Holy Symbol',
    holysymbol_desc:
        'Worn by members of the clergy. Its appearance varies depending on the religion.',
    oktoberamulet_name: 'Convenient Pendant',
    oktoberamulet_desc: 'Is it a potion? or an amulet? Who really knows?',
    titaniumtorc_name: 'Titanium Torc',
    titaniumtorc_desc: 'Good enough for a Shop Titan.',
    timelocket_name: 'Magical Timepiece',
    timelocket_desc:
        'Automatically adjusts to time zones and time saving. Convenient!',
    magatama_name: 'Magatama Necklace',
    magatama_desc:
        'Guarantees luck and success in the life of warriors (and the occasional lawyer).',
    dragonamulet_name: 'Dragonsoul Pendant',
    dragonamulet_desc:
        'Imbues its wearer with great power and a fierce lust for gold.',
    archivistglasses_name: 'Archivist Glasses',
    archivistglasses_desc:
        "Put on your serious glasses; it's time for some bookkeeping.",
    phylactery_name: 'Stygian Phylactery',
    phylactery_desc:
        "You probably shouldn't handle that without holy water...",
    rudoamulet_name: 'Ursa Totem',
    rudoamulet_desc:
        'Surprisingly vicious for an inanimate object, hence the bindings.',
    alimyriad_name: 'Alimyriad',
    alimyriad_desc: 'All is one, one is all.',
    priestamulet_name: "Freyja's Talisman",
    priestamulet_desc: 'As if someone is always watching over you...',
    vipamulet_name: 'VIP Talisman',
    vipamulet_desc:
        "With this token from Maya, you could even walk into Reinhold's very court!",
    brisingamen_name: 'Brísingamen',
    brisingamen_desc:
        'These gems glitter furiously, like a raging inferno.',
    peakamulet_name: 'Monsoon Heart',
    peakamulet_desc:
        'You are always safe when you are the eye of the storm.',
    troblinamulet_name: 'Forlorn Acorn',
    troblinamulet_desc:
        'The Troublins of the Howling Woods meticulously gather acorns that fail to sprout, believing them to hold powerful magic.',
    atlab_amulet_name: "Gyatso's Amulet",
    atlab_amulet_desc: "A memento from Aang's beloved teacher, Gyatso.",
    chipped_name: 'Chipped Runestone',
    chipped_desc:
        'An inert runestone with faint traces of power. The Priestess or the Wizard can craft it into an Enchantment.',
    flawed_name: 'Flawed Runestone',
    flawed_desc:
        'An inert runestone brimming with potential. The Priestess or the Wizard can craft it into an Enchantment.',
    chiseled_name: 'Chiseled Runestone',
    chiseled_desc:
        'A finely cut runestone quivering with energy. The Priestess or the Wizard can craft it into an Enchantment.',
    perfect_name: 'Perfect Runestone',
    perfect_desc: '',
    lessermoon_name: 'Lesser Moonstone',
    lessermoon_desc:
        'A chunk of slightly magical moonstone. An essential component for Fusion.',
    flawedmoon_name: 'Superior Moonstone',
    flawedmoon_desc:
        'A fragment of lunar meteorite, frozen in stone. An essential component for Fusion.',
    greatermoon_name: 'Greater Moonstone',
    greatermoon_desc:
        'A lunar cycle, bound in physical form. An essential component for Fusion.',
    ember_name: 'Ember Element',
    ember_name_o: 'Ember',
    ember_desc:
        'Comfortably warm to the touch. Keep it in your pockets during harsh winter months.',
    flaming_name: 'Flame Element',
    flaming_name_o: 'Flame',
    flaming_desc:
        'The wizard handbook strongly recommends not storing it in your library, or in close proximity to any flammables.',
    blaze_name: 'Blaze Element',
    blaze_name_o: 'Blaze',
    blaze_desc: 'The air turns to a haze around this white-hot rune.',
    inferno_name: 'Inferno Element',
    inferno_name_o: 'Inferno',
    inferno_desc: '',
    bubble_name: 'Bubble Element',
    bubble_name_o: 'Bubble',
    bubble_desc: 'Constantly dripping with pure, fresh water.',
    tidal_name: 'Tide Element',
    tidal_name_o: 'Tide',
    tidal_desc:
        'Smells like the sea. If held to the ear, it sounds like the sea, too.',
    flood_name: 'Flood Element',
    flood_name_o: 'Flood',
    flood_desc: 'It just never stops pouring. Keep it outside!',
    torrent_name: 'Torrent Element',
    torrent_name_o: 'Torrent',
    torrent_desc: '',
    breeze_name: 'Breeze Element',
    breeze_name_o: 'Breeze',
    breeze_desc:
        'Constantly emits a cool breeze. A bunch of these can be used for makeshift air conditioning.',
    gale_name: 'Gale Element',
    gale_name_o: 'Gale',
    gale_desc:
        'Tends to summon up strong drafts of winds. Can be used to dry clothes in no time.',
    tempest_name: 'Tempest Element',
    tempest_name_o: 'Tempest',
    tempest_desc:
        'Accidentally breaking one of these can release a storm that lasts for weeks!',
    hurricane_name: 'Hurricane Element',
    hurricane_name_o: 'Hurricane',
    hurricane_desc: '',
    natural_name: 'Nature Element',
    natural_name_o: 'Nature',
    natural_desc:
        'Locally grown with no artificial agents or preservatives.',
    wild_name: 'Wild Element',
    wild_name_o: 'Wild',
    wild_desc: "This stone longs for freedom! Don't keep it on a leash!",
    primal_name: 'Primal Element',
    primal_name_o: 'Primal',
    primal_desc: 'Imbued with the unyielding strength of mountains.',
    primeval_name: 'Primeval Element',
    primeval_name_o: 'Primeval',
    primeval_desc: '',
    light_name: 'Light Element',
    light_name_o: 'Light',
    light_desc: 'Batteries not included.',
    holy_name: 'Holy Element',
    holy_name_o: 'Holy',
    holy_desc:
        'Monotonously recites a bunch of holy commandments whenever asked.',
    sacred_name: 'Sacred Element',
    sacred_name_o: 'Sacred',
    sacred_desc:
        'This rune sings a soft angelic chorus when held by a pure and pious individual.',
    divine_name: 'Divine Element',
    divine_name_o: 'Divine',
    divine_desc: '',
    corrupted_name: 'Corrupted Element',
    corrupted_name_o: 'Corrupted',
    corrupted_desc:
        "Cackles can be heard from this Element. What's so funny?",
    unholy_name: 'Unholy Element',
    unholy_name_o: 'Unholy',
    unholy_desc:
        'Easily sucks up all the life out a room. But not literally.',
    nightmare_name: 'Nightmare Element',
    nightmare_name_o: 'Nightmare',
    nightmare_desc: 'Completely countered by dreamcatchers.',
    abyssal_name: 'Abyssal Element',
    abyssal_name_o: 'Abyssal',
    abyssal_desc: '',
    luxuriouselement_name: 'Luxurious Element',
    luxuriouselement_name_o: 'Luxurious',
    luxuriouselement_desc:
        'A magical stone of great value. Of no use to Heroes, but certainly makes items look more expensive!',
    opulentelement_name: 'Opulent Element',
    opulentelement_name_o: 'Opulent',
    opulentelement_desc:
        "The very essence of wealth itself. Could this be the legendary Philosopher's Stone?",
    turtle_name: 'Ram Spirit',
    turtle_name_o: 'Ram',
    turtle_desc:
        'Just holding it makes you want to bonk your head into stuff.',
    wolf_name: 'Wolf Spirit',
    wolf_name_o: 'Wolf',
    wolf_desc: "Doesn't get along with the Ram Spirit. At all.",
    ox_name: 'Ox Spirit',
    ox_name_o: 'Ox',
    ox_desc:
        'Items enchanted with this spirit sometimes grow a mysterious enmity for the color red.',
    eagle_name: 'Eagle Spirit',
    eagle_name_o: 'Eagle',
    eagle_desc: 'Some say it is the spirit of freedom itself.',
    viper_name: 'Viper Spirit',
    viper_name_o: 'Viper',
    viper_desc: 'It has a bit of a mean streak.',
    cat_name: 'Cat Spirit',
    cat_name_o: 'Cat',
    cat_desc: 'Wherever it fits, it sits.',
    luxuriousspirit_name: 'Xolotl Spirit',
    luxuriousspirit_name_o: 'Xolotl',
    luxuriousspirit_desc:
        "The harbinger of the sun's death. Thankfully it'll be back by next morning.",
    rhino_name: 'Rhino Spirit',
    rhino_name_o: 'Rhino',
    rhino_desc: 'No rhinos were harmed in the making of this spirit.',
    owl_name: 'Owl Spirit',
    owl_name_o: 'Owl',
    owl_desc:
        'How many licks does it take to get to the center of this rune?',
    armadillo_name: 'Armadillo Spirit',
    armadillo_name_o: 'Armadillo',
    armadillo_desc: 'If you ignore your problems, they might just go away.',
    lizard_name: 'Lizard Spirit',
    lizard_name_o: 'Lizard',
    lizard_desc:
        'If left to sunbathe, will magically regenerate chips and nicks. Somehow.',
    crab_name: 'Horse Spirit',
    crab_name_o: 'Horse',
    crab_desc:
        'The unbreakable endurance of the wild stallion distilled into spirit form.',
    hippo_name: 'Hippo Spirit',
    hippo_name_o: 'Hippo',
    hippo_desc:
        'Whatever this spirit is enchanted on feels... quite a bit chunkier.',
    walrus_name: 'Walrus Spirit',
    walrus_name_o: 'Walrus',
    walrus_desc: "It's a hard one to budge.",
    shark_name: 'Shark Spirit',
    shark_name_o: 'Shark',
    shark_desc: 'Apex predator since time immemorial.',
    lion_name: 'Lion Spirit',
    lion_name_o: 'Lion',
    lion_desc: "Everything the light touches is this spirit's kingdom.",
    bear_name: 'Bear Spirit',
    bear_name_o: 'Bear',
    bear_desc: "It's no wonder Rudo likes them!",
    mammoth_name: 'Mammoth Spirit',
    mammoth_name_o: 'Mammoth',
    mammoth_desc: "It's got a looooong memory.",
    dinosaur_name: 'Dinosaur Spirit',
    dinosaur_name_o: 'Dinosaur',
    dinosaur_desc:
        'Actually consists mostly of frog DNA. Dinosaurs are extinct, after all.',
    dragon_name: "Mundra's Spirit",
    dragon_name_o: 'Mundra',
    dragon_desc:
        "Haughty even in spirit form. It seems more than eager to show those bosses who's boss.",
    tiger_name: 'Tiger Spirit',
    tiger_name_o: 'Tiger',
    tiger_desc: "Has a particular affinity for cereals. They're great!",
    opulentspirit_name: 'Quetzalcoatl Spirit',
    opulentspirit_name_o: 'Quetzalcoatl',
    opulentspirit_desc:
        'The wind, the guide and road sweeper of the rain gods.',
    elvenwood_name: 'Elven Wood',
    elvenwood_desc:
        'Its iconic shape is much easier to sculpt than it may seem.',
    redpinecone_name: 'Iron Pine Cone',
    redpinecone_desc: 'A favorite of steel squirrels.',
    glowingmushrooms_name: 'Glow Shroom',
    glowingmushrooms_desc: 'Can act as an eco-friendly torch in a pinch.',
    arcanedust_name: 'Silver Dust',
    arcanedust_desc:
        'Grants the magical warding properties of silver upon whatever it is sprinkled on.',
    webbedwings_name: 'Webbed Wing',
    webbedwings_desc:
        'Large, supple wings that once belonged to a giant bat.',
    preciousgem_name: 'Precious Gem',
    preciousgem_desc:
        'Arcane energies of unknown origins still linger within.',
    livingroots_name: 'Living Root',
    livingroots_desc:
        'The more humanoid it looks, the better the taste. Creepy, right?',
    darkscale_name: 'Rustwyrm Scale',
    darkscale_desc:
        'Rustwyrms need a balanced diet of metals to grow big and strong.',
    icypearl_name: 'Deep Pearl',
    icypearl_desc: "Taken from the swamp's giant clams.",
    whitesand_name: 'White Sand',
    whitesand_desc: 'Blindingly bright sand with no impurities whatsoever.',
    sharpfang_name: 'Bronze Fang',
    sharpfang_desc:
        "Considering its size, you really don't want to know where it came from.",
    mooncrystal_name: 'Moon Crystal',
    mooncrystal_desc: 'Its inner darkness seems to consume light itself.',
    evileye_name: 'Evil Eye',
    evileye_desc:
        'Evil Eyes sometimes twitch as one, as if controlled by a single entity',
    silkscarab_name: 'Silk Scarab',
    silkscarab_desc:
        'An exotic material thought to be of divine origin by a lost civilization.',
    starmetal_name: 'Star Metal',
    starmetal_desc: 'A mythical metal from another planet.',
    ancientmarble_name: 'Ancient Marble',
    ancientmarble_desc: "They don't make it like they used to!",
    overgrownvine_name: 'Overgrown Vine',
    overgrownvine_desc:
        'Arcane carbon dating suggests this vine is... over a thousand years old?!?',
    timecrystal_name: 'Chronos Crystal',
    timecrystal_desc:
        'This rare crystal is said to hold power over time itself.',
    ectoplasm_name: 'Spooky Ectoplasm',
    ectoplasm_desc: "Who's scared of who here?",
    sewingthread_name: 'Thread of Fate',
    sewingthread_desc:
        "Exquisite golden thread said to represent life expectancy. Don't cut it too short!",
    ghostflag_name: 'Ghastly Pennant',
    ghostflag_desc:
        'The sigil of a house long gone. This flag has seen many things.',
    deepcoral_name: 'Deep Coral',
    deepcoral_desc:
        'When taken out of the water, this substance becomes as hard as steel!',
    sirenshard_name: 'Crystal Lullaby',
    sirenshard_desc:
        'There is a faint song coming from this...And it is so hard to put down...',
    goldshell_name: 'Precious Shell',
    goldshell_desc:
        'How this conch ended up covered in 24k plating, nobody knows.',
    grimtalon_name: 'Grim Talon',
    grimtalon_desc: 'Sharp, steel-like claws.',
    crystalegg_name: 'Zirconia Eggshell',
    crystalegg_desc: 'These shards are almost forever.',
    icybreeze_name: 'Boreal Gale',
    icybreeze_desc:
        'This magically charged northern wind is especially useful to temper molten metal.',
    tabletjewel_name: 'Opulent Jewel',
    tabletjewel_desc:
        'An incredibly scarce component used to craft Opulent Items. It can be sold on the market at a high price.',
    tabletfragment_name: 'Golden Artifact',
    tabletfragment_desc:
        'A very rare component used to craft Luxurious items. It can also be sold on the market at a high price.',
    puregold_name: 'Pure Gold Bar',
    puregold_desc:
        'An awfully heavy bar made 99% out of gold. The King will greatly reward guilds that bring him some.',
    mightsigil_name: 'Sigil of Might',
    mightsigil_desc:
        'Raw strength condensed into a symbol of arcane power. Used to craft very powerful items.',
    gracesigil_name: 'Sigil of Grace',
    gracesigil_desc:
        'Pure dexterity condensed into a symbol of arcane power. Used to craft very powerful items.',
    sparksigil_name: 'Sigil of Spark',
    sparksigil_desc:
        'Unbound magical potential condensed into a symbol of arcane power. Used to craft very powerful items.',
    mightsigil2_name: 'Sigil of True Might',
    mightsigil2_desc:
        'True strength refined into crystalized arcane power. Used to craft very unimaginably powerful items.',
    gracesigil2_name: 'Sigil of True Grace',
    gracesigil2_desc:
        'True dexterity refined into crystalized arcane power. Used to craft very unimaginably powerful items.',
    sparksigil2_name: 'Sigil of True Spark',
    sparksigil2_desc:
        'True magical potential into crystalized arcane power. Used to craft very unimaginably powerful items.',
    xpdrink1_name: 'XP Drink',
    xpdrink1_desc: 'Give it to a character for an instant XP boost!',
    xpdrink2_name: 'Super XP Drink',
    xpdrink2_desc: 'Give it to a character for a major XP boost!',
    xpdrink3_name: 'Mega XP Drink',
    xpdrink3_desc: 'Give it to a character for a huge XP boost!',
    seedhp1_name: 'Seed of Vitality',
    seedhp1_desc: 'Permanently increases the HP of a character.',
    seedattack1_name: 'Seed of Power',
    seedattack1_desc: 'Permanently increases the Attack of a character.',
    seeddef1_name: 'Seed of Resistance',
    seeddef1_desc: 'Permanently increases the Defense of a character.',
    seedhp1_plus_name: 'Blossom of Vitality',
    seedhp1_plus_desc:
        'Permanently raises the maximum amount of Seeds of Vitality a hero can consume by 5.',
    seedattack1_plus_name: 'Blossom of Power',
    seedattack1_plus_desc:
        'Permanently raises the maximum amount of Seeds of Power a hero can consume by 5.',
    seeddef1_plus_name: 'Blossom of Resistance',
    seeddef1_plus_desc:
        'Permanently raises the maximum amount of Seeds of Resistance a hero can consume by 5.',
    bp_upgrade_name: 'Ascension Shard',
    bp_upgrade_desc:
        'Used to grant powerful upgrades to blueprints. They can be found in chests.',
    bp_unlock_name: 'Research Scroll',
    bp_unlock_desc:
        'Research Scrolls are used to unlock new blueprints. You can earn more by crafting lots of items or by opening chests.',
    hpdrink1_name: 'Stamina Drink',
    hpdrink1_desc: 'Fully heals a resting character.',
    hpdrink1_shortdesc: 'Heals a character',
    revivekit1_name: 'Phoenix Feather',
    revivekit1_desc: 'Fully heals all resting characters!',
    revivekit1_shortdesc: 'Heals all characters',
    repairkit_name: 'Repair Kit',
    repairkit_desc: 'Repairs an item broken during a quest.',
    atkbooster1_name: 'Power Booster',
    atkbooster1_desc: 'Power bonus for one quest.',
    atkbooster2_name: 'Super Power Booster',
    atkbooster2_desc: 'Large Power bonus for one quest.',
    atkbooster3_name: 'Mega Power Booster',
    atkbooster3_desc: 'Huge Power bonus for one quest.',
    xpbooster1_name: 'XP Booster',
    xpbooster1_desc: 'Boosts XP gained by 50% for the whole party!',
    xpbooster2_name: 'Super XP Booster',
    xpbooster2_desc: 'Doubles XP gained by the whole party!',
    xpbooster3_name: 'Mega XP Booster',
    xpbooster3_desc:
        "Triples XP gained by the whole party! Now that's grinding!",
    compass1_name: 'Bronze Compass',
    compass1_desc: 'Speeds up quest and rest time by 20%.',
    compass1_shortdesc: '20% faster quest & rest times.',
    compass2_name: 'Silver Compass',
    compass2_desc: 'Speeds up quest and rest time by 50%!',
    compass2_shortdesc: '50% faster quest & rest times.',
    compass3_name: 'Gold Compass',
    compass3_desc:
        'Nearly eliminates quest and rest time! Ludicrous speed!',
    compass3_shortdesc: '80% faster quest & rest times.',
    bugle1_name: 'Loot Bugle',
    bugle1_desc: 'Increases components found by 50%.',
    bugle2_name: 'Super Loot Bugle',
    bugle2_desc: 'Doubles components found!',
    bugle3_name: 'Mega Loot Bugle',
    bugle3_desc: 'Increases components found by 150%! Lootsplosion!',
    skillreroller1_name: 'Basic Skill Die',
    skillreroller1_desc:
        "Can reroll the skill in a Hero's first skill slot.",
    skillreroller2_name: 'Super Skill Die',
    skillreroller2_desc:
        "Can reroll the skill in a Hero's second skill slot or below.",
    skillreroller3_name: 'Mega Skill Die',
    skillreroller3_desc:
        "Can reroll the skill in a Hero's third skill slot or below.",
    skillreroller4_name: 'Perfect Skill Die',
    skillreroller4_desc: "Can reroll the skill in any Hero's skill slot!",
    titansoul_name: 'Titan Soul',
    titansoul_desc:
        "Upgrades a Hero's class permanently, making them stronger and unlocking all skill slots.",
    limitededition_name: 'Limited Edition!',
    limitededition_desc:
        'Special stamp that lets you surcharge a single item twice.',
    discounttoken_name: 'On the House!',
    discounttoken_desc:
        'Special stamp that sells an item for free, but grants 10x more discount energy.',
    sitem_discounttoken_effect_desc: 'Full discount to receive 10x energy!',
    special_item_type_discounttoken: 'Marketing Item',
    xpdrink4_name: 'Mythic XP Drink',
    xpdrink4_desc: "Increases a Hero's level by 1.",
    sitems_xpdrink4_effect_desc:
        "Increases a Hero's level by 1, regardless of XP needed!",
    antique_shard_name: 'Antique Token',
    antique_shard_desc:
        "Rare currency obtained with the King's Favor. Can be used to purchase Antiques during the King's Caprice event and is kept after the event ends.",
    antique_shard_shortdesc: 'Antique Store currency',
    spinticket_name: 'Spin Ticket',
    spinticket_desc: '',
    spintoken_name: 'Spin Token',
    spintoken_desc: '',
    chest_forest_name: 'Wooden Chest',
    chest_forest_desc:
        'A sturdy chest found in the Howling Woods. It can be opened with a Wooden Key.',
    chest_grotto_name: 'Frozen Chest',
    chest_grotto_desc:
        'A mysteriously cold chest found in the Aurora Caves. It can be opened with a Frozen Key.',
    chest_swamp_name: 'Slimy Chest',
    chest_swamp_desc:
        'An unpleasant chest found in the Whispering Bog. It can be opened with a Slimy Key.',
    chest_desert_name: 'Limestone Chest',
    chest_desert_desc:
        'A smooth chest found in the Barren Wastes. It can be opened with a Limestone Key.',
    chest_pyramid_name: 'Ancient Chest',
    chest_pyramid_desc:
        "A regal chest found in the Sun God's Tomb. It can be opened with an Ancient Key.",
    chest_ruins_name: 'Timeworn Chest',
    chest_ruins_desc:
        'A time-weathered chest found in the Chronos Ruins. It can be opened with a Timeworn Key.',
    chest_castle_name: 'Forsaken Chest',
    chest_castle_desc:
        'An ancient chest found in the Haunted Castle. It can be opened with a Forsaken Key.',
    chest_temple_name: 'Sunken Chest',
    chest_temple_desc:
        'A briny, soaked chest found in the Sunken Temple. It can be opened with a Sunken Key.',
    chest_peak_name: 'Bleak Chest',
    chest_peak_desc:
        'A dreadful chest found at the Bleakspire Peak. It can be opened with a Bleak Key.',
    chest_volcano_name: 'Molten Chest',
    chest_volcano_desc: '',
    chest_goldcity_name: 'Luxurious Chest',
    chest_goldcity_desc:
        'An extremely heavy chest found in the Lost City of Gold. It can be opened with a Luxurious Key.',
    chest_goldcity2_name: 'Opulent Chest',
    chest_goldcity2_desc:
        'An extremely heavy chest found in the Lost City of Gold. It can be opened with an Opulent Key.',
    key_forest_name: 'Wooden Key',
    key_forest_name_plural: 'Wooden Keys',
    key_forest_desc:
        'A magic key intricately carved from Elven Wood. Only it can open a Wooden Chest.',
    key_grotto_name: 'Frozen Key',
    key_grotto_name_plural: 'Frozen Keys',
    key_grotto_desc:
        'A magic key made of eternal ice. Only it can open a Frozen Chest.',
    key_swamp_name: 'Slimy Key',
    key_swamp_name_plural: 'Slimy Keys',
    key_swamp_desc:
        'A magic key coated in something... viscous. Only it can open a Slimy Chest.',
    key_desert_name: 'Limestone Key',
    key_desert_name_plural: 'Limestone Keys',
    key_desert_desc:
        'An unexpectedly heavy magic key made of pure limestone. Only it can open a Limestone Chest.',
    key_pyramid_name: 'Ancient Key',
    key_pyramid_name_plural: 'Ancient Keys',
    key_pyramid_desc:
        'A magic key that may or may not be cursed. Only it can open the Ancient Chest.',
    key_ruins_name: 'Timeworn Key',
    key_ruins_name_plural: 'Timeworn Keys',
    key_ruins_desc:
        'A magic key bathed in temporal magic. Only it can open the Timeworn Chest.',
    key_castle_name: 'Forsaken Key',
    key_castle_name_plural: 'Forsaken Keys',
    key_castle_desc:
        'A magic key shimmering with the glory of old. Only it can open the Forsaken Chest.',
    key_temple_name: 'Sunken Key',
    key_temple_name_plural: 'Sunken Keys',
    key_temple_desc:
        'A magic key exuding briny water. Only it can open the Sunken Chest',
    key_peak_name: 'Bleak Key',
    key_peak_name_plural: 'Bleak Keys',
    key_peak_desc:
        'A magic key seeping with dreariness. Only it can open the Bleak Chest',
    key_volcano_name: 'Molten Key',
    key_volcano_name_plural: 'Molten Keys',
    key_volcano_desc: '',
    key_goldcity_name: 'Luxurious Key',
    key_goldcity_name_plural: 'Luxurious Keys',
    key_goldcity_desc:
        'A magic key made of pure gold. Only it can open the Luxurious Chest.',
    key_goldcity2_name: 'Opulent Key',
    key_goldcity2_name_plural: 'Opulent Keys',
    key_goldcity2_desc:
        'A magic key made of pure gold. Only it can open the Opulent Chest.',
    token_argon_name: 'Argon Coin',
    token_argon_name_plural: 'Argon Coins',
    token_argon_desc:
        'Fierce determination minted into a coin. Collect them to empower Argon.',
    token_lilu_name: 'Lilu Coin',
    token_lilu_name_plural: 'Lilu Coins',
    token_lilu_desc:
        'Boundless curiosity minted into a coin. Collect them to empower Lilu.',
    token_sia_name: 'Sia Coin',
    token_sia_name_plural: 'Sia Coins',
    token_sia_desc:
        'An air of mystery minted into a coin. Collect them to empower Sia.',
    token_yami_name: 'Yami Coin',
    token_yami_name_plural: 'Yami Coins',
    token_yami_desc:
        'Cold vengeance minted into a coin. Collect them to empower Yami.',
    token_rudo_name: 'Rudo Coin',
    token_rudo_name_plural: 'Rudo Coins',
    token_rudo_desc:
        'Ruthless savagery minted into a coin. Collect them to empower Rudo.',
    token_polonia_name: 'Polonia Coin',
    token_polonia_name_plural: 'Polonia Coins',
    token_polonia_desc:
        'A lust for adventure minted into a coin. Collect them to empower Polonia.',
    token_donovan_name: 'Donovan Coin',
    token_donovan_name_plural: 'Donovan Coins',
    token_donovan_desc:
        'Timeless knowledge minted into a coin. Collect them to empower Donovan.',
    token_hemma_name: 'Hemma Coin',
    token_hemma_name_plural: 'Hemma Coins',
    token_hemma_desc:
        'Unbound power minted into a coin. Collect them to empower Hemma.',
    token_ashley_name: 'Ashley Coin',
    token_ashley_name_plural: 'Ashley Coins',
    token_ashley_desc:
        'Unshakable will minted into a coin. Collect them to empower Ashley.',
    token_atlab_aang_name: 'Aang Coin',
    token_atlab_aang_name_plural: 'Aang Coins',
    token_atlab_aang_desc:
        'The power of all four elements minted into a coin. Collect them to empower the Guest Champion Aang.',
    token_atlab_sokka_name: 'Sokka Coin',
    token_atlab_sokka_name_plural: 'Sokka Coins',
    token_atlab_sokka_desc:
        'Sarcasm and ingenuity bound minted into a coin. Collect them to empower the Guest Champion Sokka.',
    token_tamas_name: 'Tamas Coin',
    token_tamas_name_plural: 'Tamas Coins',
    token_tamas_desc:
        'Raw Tamas minted into a coin. Collect them to empower the Guest Champion Tamas.',
    ascension_upgrade_ws_00: 'Sword Amateur',
    ascension_upgrade_ws_03: 'Sword Novice',
    ascension_upgrade_ws_05: 'Sword Initiate',
    ascension_upgrade_ws_07: 'Sword Specialist',
    ascension_upgrade_ws_09: 'Sword Expert',
    ascension_upgrade_ws_11: 'Sword Pro',
    ascension_upgrade_ws_13: 'Sword Ace',
    ascension_upgrade_ws_15: 'Sword Master',
    ascension_upgrade_ws_17: 'Sword Titan',
    ascension_upgrade_wa_00: 'Axe Amateur',
    ascension_upgrade_wa_03: 'Axe Novice',
    ascension_upgrade_wa_05: 'Axe Initiate',
    ascension_upgrade_wa_07: 'Axe Specialist',
    ascension_upgrade_wa_09: 'Axe Expert',
    ascension_upgrade_wa_11: 'Axe Pro',
    ascension_upgrade_wa_13: 'Axe Ace',
    ascension_upgrade_wa_15: 'Axe Master',
    ascension_upgrade_wa_17: 'Axe Titan',
    ascension_upgrade_wd_00: 'Dagger Amateur',
    ascension_upgrade_wd_03: 'Dagger Novice',
    ascension_upgrade_wd_05: 'Dagger Initiate',
    ascension_upgrade_wd_07: 'Dagger Specialist',
    ascension_upgrade_wd_09: 'Dagger Expert',
    ascension_upgrade_wd_11: 'Dagger Pro',
    ascension_upgrade_wd_13: 'Dagger Ace',
    ascension_upgrade_wd_15: 'Dagger Master',
    ascension_upgrade_wd_17: 'Dagger Titan',
    ascension_upgrade_wm_00: 'Mace Amateur',
    ascension_upgrade_wm_03: 'Mace Novice',
    ascension_upgrade_wm_05: 'Mace Initiate',
    ascension_upgrade_wm_07: 'Mace Specialist',
    ascension_upgrade_wm_09: 'Mace Expert',
    ascension_upgrade_wm_11: 'Mace Pro',
    ascension_upgrade_wm_13: 'Mace Ace',
    ascension_upgrade_wm_15: 'Mace Master',
    ascension_upgrade_wm_17: 'Mace Titan',
    ascension_upgrade_wp_00: 'Spear Amateur',
    ascension_upgrade_wp_03: 'Spear Novice',
    ascension_upgrade_wp_05: 'Spear Initiate',
    ascension_upgrade_wp_07: 'Spear Specialist',
    ascension_upgrade_wp_09: 'Spear Expert',
    ascension_upgrade_wp_11: 'Spear Pro',
    ascension_upgrade_wp_13: 'Spear Ace',
    ascension_upgrade_wp_15: 'Spear Master',
    ascension_upgrade_wp_17: 'Spear Titan',
    ascension_upgrade_wb_00: 'Bow Amateur',
    ascension_upgrade_wb_03: 'Bow Novice',
    ascension_upgrade_wb_05: 'Bow Initiate',
    ascension_upgrade_wb_07: 'Bow Specialist',
    ascension_upgrade_wb_09: 'Bow Expert',
    ascension_upgrade_wb_11: 'Bow Pro',
    ascension_upgrade_wb_13: 'Bow Ace',
    ascension_upgrade_wb_15: 'Bow Master',
    ascension_upgrade_wb_17: 'Bow Titan',
    ascension_upgrade_wt_00: 'Staff Amateur',
    ascension_upgrade_wt_03: 'Staff Novice',
    ascension_upgrade_wt_05: 'Staff Initiate',
    ascension_upgrade_wt_07: 'Staff Specialist',
    ascension_upgrade_wt_09: 'Staff Expert',
    ascension_upgrade_wt_11: 'Staff Pro',
    ascension_upgrade_wt_13: 'Staff Ace',
    ascension_upgrade_wt_15: 'Staff Master',
    ascension_upgrade_wt_17: 'Staff Titan',
    ascension_upgrade_ww_00: 'Wand Amateur',
    ascension_upgrade_ww_03: 'Wand Novice',
    ascension_upgrade_ww_05: 'Wand Initiate',
    ascension_upgrade_ww_07: 'Wand Specialist',
    ascension_upgrade_ww_09: 'Wand Expert',
    ascension_upgrade_ww_11: 'Wand Pro',
    ascension_upgrade_ww_13: 'Wand Ace',
    ascension_upgrade_ww_15: 'Wand Master',
    ascension_upgrade_ww_17: 'Wand Titan',
    ascension_upgrade_wc_00: 'Crossbow Amateur',
    ascension_upgrade_wc_03: 'Crossbow Novice',
    ascension_upgrade_wc_05: 'Crossbow Initiate',
    ascension_upgrade_wc_07: 'Crossbow Specialist',
    ascension_upgrade_wc_09: 'Crossbow Expert',
    ascension_upgrade_wc_11: 'Crossbow Pro',
    ascension_upgrade_wc_13: 'Crossbow Ace',
    ascension_upgrade_wc_15: 'Crossbow Master',
    ascension_upgrade_wc_17: 'Crossbow Titan',
    ascension_upgrade_wg_00: 'Gun Amateur',
    ascension_upgrade_wg_03: 'Gun Novice',
    ascension_upgrade_wg_05: 'Gun Initiate',
    ascension_upgrade_wg_07: 'Gun Specialist',
    ascension_upgrade_wg_09: 'Gun Expert',
    ascension_upgrade_wg_11: 'Gun Pro',
    ascension_upgrade_wg_13: 'Gun Ace',
    ascension_upgrade_wg_15: 'Gun Master',
    ascension_upgrade_wg_17: 'Gun Titan',
    ascension_upgrade_uh_00: 'Medicine Amateur',
    ascension_upgrade_uh_03: 'Medicine Novice',
    ascension_upgrade_uh_05: 'Medicine Initiate',
    ascension_upgrade_uh_07: 'Medicine Specialist',
    ascension_upgrade_uh_09: 'Medicine Expert',
    ascension_upgrade_uh_11: 'Medicine Pro',
    ascension_upgrade_uh_13: 'Medicine Ace',
    ascension_upgrade_uh_15: 'Medicine Master',
    ascension_upgrade_uh_17: 'Medicine Titan',
    ascension_upgrade_up_00: 'Potion Amateur',
    ascension_upgrade_up_03: 'Potion Novice',
    ascension_upgrade_up_05: 'Potion Initiate',
    ascension_upgrade_up_07: 'Potion Specialist',
    ascension_upgrade_up_09: 'Potion Expert',
    ascension_upgrade_up_11: 'Potion Pro',
    ascension_upgrade_up_13: 'Potion Ace',
    ascension_upgrade_up_15: 'Potion Master',
    ascension_upgrade_up_17: 'Potion Titan',
    ascension_upgrade_us_00: 'Spell Amateur',
    ascension_upgrade_us_03: 'Spell Novice',
    ascension_upgrade_us_05: 'Spell Initiate',
    ascension_upgrade_us_07: 'Spell Specialist',
    ascension_upgrade_us_09: 'Spell Expert',
    ascension_upgrade_us_11: 'Spell Pro',
    ascension_upgrade_us_13: 'Spell Ace',
    ascension_upgrade_us_15: 'Spell Master',
    ascension_upgrade_us_17: 'Spell Titan',
    ascension_upgrade_ah_00: 'Heavy Armor Amateur',
    ascension_upgrade_ah_03: 'Heavy Armor Novice',
    ascension_upgrade_ah_05: 'Heavy Armor Initiate',
    ascension_upgrade_ah_07: 'Heavy Armor Specialist',
    ascension_upgrade_ah_09: 'Heavy Armor Expert',
    ascension_upgrade_ah_11: 'Heavy Armor Pro',
    ascension_upgrade_ah_13: 'Heavy Armor Ace',
    ascension_upgrade_ah_15: 'Heavy Armor Master',
    ascension_upgrade_ah_17: 'Heavy Armor Titan',
    ascension_upgrade_am_00: 'Light Armor Amateur',
    ascension_upgrade_am_03: 'Light Armor Novice',
    ascension_upgrade_am_05: 'Light Armor Initiate',
    ascension_upgrade_am_07: 'Light Armor Specialist',
    ascension_upgrade_am_09: 'Light Armor Expert',
    ascension_upgrade_am_11: 'Light Armor Pro',
    ascension_upgrade_am_13: 'Light Armor Ace',
    ascension_upgrade_am_15: 'Light Armor Master',
    ascension_upgrade_am_17: 'Light Armor Titan',
    ascension_upgrade_al_00: 'Clothes Amateur',
    ascension_upgrade_al_03: 'Clothes Novice',
    ascension_upgrade_al_05: 'Clothes Initiate',
    ascension_upgrade_al_07: 'Clothes Specialist',
    ascension_upgrade_al_09: 'Clothes Expert',
    ascension_upgrade_al_11: 'Clothes Pro',
    ascension_upgrade_al_13: 'Clothes Ace',
    ascension_upgrade_al_15: 'Clothes Master',
    ascension_upgrade_al_17: 'Clothes Titan',
    ascension_upgrade_hh_00: 'Helmet Amateur',
    ascension_upgrade_hh_03: 'Helmet Novice',
    ascension_upgrade_hh_05: 'Helmet Initiate',
    ascension_upgrade_hh_07: 'Helmet Specialist',
    ascension_upgrade_hh_09: 'Helmet Expert',
    ascension_upgrade_hh_11: 'Helmet Pro',
    ascension_upgrade_hh_13: 'Helmet Ace',
    ascension_upgrade_hh_15: 'Helmet Master',
    ascension_upgrade_hh_17: 'Helmet Titan',
    ascension_upgrade_hm_00: 'Rogue Hat Amateur',
    ascension_upgrade_hm_03: 'Rogue Hat Novice',
    ascension_upgrade_hm_05: 'Rogue Hat Initiate',
    ascension_upgrade_hm_07: 'Rogue Hat Specialist',
    ascension_upgrade_hm_09: 'Rogue Hat Expert',
    ascension_upgrade_hm_11: 'Rogue Hat Pro',
    ascension_upgrade_hm_13: 'Rogue Hat Ace',
    ascension_upgrade_hm_15: 'Rogue Hat Master',
    ascension_upgrade_hm_17: 'Rogue Hat Titan',
    ascension_upgrade_hl_00: 'Magician Hat Amateur',
    ascension_upgrade_hl_03: 'Magician Hat Novice',
    ascension_upgrade_hl_05: 'Magician Hat Initiate',
    ascension_upgrade_hl_07: 'Magician Hat Specialist',
    ascension_upgrade_hl_09: 'Magician Hat Expert',
    ascension_upgrade_hl_11: 'Magician Hat Pro',
    ascension_upgrade_hl_13: 'Magician Hat Ace',
    ascension_upgrade_hl_15: 'Magician Hat Master',
    ascension_upgrade_hl_17: 'Magician Hat Titan',
    ascension_upgrade_gh_00: 'Gauntlet Amateur',
    ascension_upgrade_gh_03: 'Gauntlet Novice',
    ascension_upgrade_gh_05: 'Gauntlet Initiate',
    ascension_upgrade_gh_07: 'Gauntlet Specialist',
    ascension_upgrade_gh_09: 'Gauntlet Expert',
    ascension_upgrade_gh_11: 'Gauntlet Pro',
    ascension_upgrade_gh_13: 'Gauntlet Ace',
    ascension_upgrade_gh_15: 'Gauntlet Master',
    ascension_upgrade_gh_17: 'Gauntlet Titan',
    ascension_upgrade_gl_00: 'Glove Amateur',
    ascension_upgrade_gl_03: 'Glove Novice',
    ascension_upgrade_gl_05: 'Glove Initiate',
    ascension_upgrade_gl_07: 'Glove Specialist',
    ascension_upgrade_gl_09: 'Glove Expert',
    ascension_upgrade_gl_11: 'Glove Pro',
    ascension_upgrade_gl_13: 'Glove Ace',
    ascension_upgrade_gl_15: 'Glove Master',
    ascension_upgrade_gl_17: 'Glove Titan',
    ascension_upgrade_bh_00: 'Boot Amateur',
    ascension_upgrade_bh_03: 'Boot Novice',
    ascension_upgrade_bh_05: 'Boot Initiate',
    ascension_upgrade_bh_07: 'Boot Specialist',
    ascension_upgrade_bh_09: 'Boot Expert',
    ascension_upgrade_bh_11: 'Boot Pro',
    ascension_upgrade_bh_13: 'Boot Ace',
    ascension_upgrade_bh_15: 'Boot Master',
    ascension_upgrade_bh_17: 'Boot Titan',
    ascension_upgrade_bl_00: 'Shoe Amateur',
    ascension_upgrade_bl_03: 'Shoe Novice',
    ascension_upgrade_bl_05: 'Shoe Initiate',
    ascension_upgrade_bl_07: 'Shoe Specialist',
    ascension_upgrade_bl_09: 'Shoe Expert',
    ascension_upgrade_bl_11: 'Shoe Pro',
    ascension_upgrade_bl_13: 'Shoe Ace',
    ascension_upgrade_bl_15: 'Shoe Master',
    ascension_upgrade_bl_17: 'Shoe Titan',
    ascension_upgrade_xs_00: 'Shield Amateur',
    ascension_upgrade_xs_03: 'Shield Novice',
    ascension_upgrade_xs_05: 'Shield Initiate',
    ascension_upgrade_xs_07: 'Shield Specialist',
    ascension_upgrade_xs_09: 'Shield Expert',
    ascension_upgrade_xs_11: 'Shield Pro',
    ascension_upgrade_xs_13: 'Shield Ace',
    ascension_upgrade_xs_15: 'Shield Master',
    ascension_upgrade_xs_17: 'Shield Titan',
    ascension_upgrade_xc_00: 'Cloak Amateur',
    ascension_upgrade_xc_03: 'Cloak Novice',
    ascension_upgrade_xc_05: 'Cloak Initiate',
    ascension_upgrade_xc_07: 'Cloak Specialist',
    ascension_upgrade_xc_09: 'Cloak Expert',
    ascension_upgrade_xc_11: 'Cloak Pro',
    ascension_upgrade_xc_13: 'Cloak Ace',
    ascension_upgrade_xc_15: 'Cloak Master',
    ascension_upgrade_xc_17: 'Cloak Titan',
    ascension_upgrade_xr_00: 'Ring Amateur',
    ascension_upgrade_xr_03: 'Ring Novice',
    ascension_upgrade_xr_05: 'Ring Initiate',
    ascension_upgrade_xr_07: 'Ring Specialist',
    ascension_upgrade_xr_09: 'Ring Expert',
    ascension_upgrade_xr_11: 'Ring Pro',
    ascension_upgrade_xr_13: 'Ring Ace',
    ascension_upgrade_xr_15: 'Ring Master',
    ascension_upgrade_xr_17: 'Ring Titan',
    ascension_upgrade_xa_00: 'Amulet Amateur',
    ascension_upgrade_xa_03: 'Amulet Novice',
    ascension_upgrade_xa_05: 'Amulet Initiate',
    ascension_upgrade_xa_07: 'Amulet Specialist',
    ascension_upgrade_xa_09: 'Amulet Expert',
    ascension_upgrade_xa_11: 'Amulet Pro',
    ascension_upgrade_xa_13: 'Amulet Ace',
    ascension_upgrade_xa_15: 'Amulet Master',
    ascension_upgrade_xa_17: 'Amulet Titan',
    ascension_upgrade_xf_00: 'Familiar Amateur',
    ascension_upgrade_xf_03: 'Familiar Novice',
    ascension_upgrade_xf_05: 'Familiar Initiate',
    ascension_upgrade_xf_07: 'Familiar Specialist',
    ascension_upgrade_xf_09: 'Familiar Expert',
    ascension_upgrade_xf_11: 'Familiar Pro',
    ascension_upgrade_xf_13: 'Familiar Ace',
    ascension_upgrade_xf_15: 'Familiar Master',
    ascension_upgrade_xf_17: 'Familiar Titan',
    ascension_upgrade_stone_00: 'Stone Amateur',
    ascension_upgrade_stone_03: 'Stone Novice',
    ascension_upgrade_stone_05: 'Stone Specialist',
    ascension_upgrade_stone_07: 'Stone Pro',
    ascension_upgrade_stone_09: 'Stone Master',
    ascension_upgrade_z_2_00: 'Elemental Amateur',
    ascension_upgrade_z_2_03: 'Elemental Novice',
    ascension_upgrade_z_2_05: 'Elemental Initiate',
    ascension_upgrade_z_2_07: 'Elemental Specialist',
    ascension_upgrade_z_2_09: 'Elemental Expert',
    ascension_upgrade_z_2_11: 'Elemental Pro',
    ascension_upgrade_z_2_13: 'Elemental Ace',
    ascension_upgrade_z_2_15: 'Elemental Master',
    ascension_upgrade_z_2_17: 'Elemental Titan',
    ascension_upgrade_z_3_00: 'Spirit Amateur',
    ascension_upgrade_z_3_03: 'Spirit Novice',
    ascension_upgrade_z_3_05: 'Spirit Initiate',
    ascension_upgrade_z_3_07: 'Spirit Specialist',
    ascension_upgrade_z_3_09: 'Spirit Expert',
    ascension_upgrade_z_3_11: 'Spirit Pro',
    ascension_upgrade_z_3_13: 'Spirit Ace',
    ascension_upgrade_z_3_15: 'Spirit Master',
    ascension_upgrade_z_3_17: 'Spirit Titan',
    troblin_name: 'Troublin',
    troblin_desc: 'Always out causing trouble. As one might expect.',
    yeti_name: 'Yeti',
    yeti_desc:
        'Local expert at making snowmen. Its snow angels, however, are not so hot.',
    toadwitch_name: 'Toadwitch',
    toadwitch_desc:
        "Don't be tricked into kissing it, nothing will happen. Is also a terrible cook.",
    mushgoon_name: 'Mushgoon',
    mushgoon_desc:
        "A big bully. Will fight anything smaller than it, which to be frank isn't a lot of things.",
    anubis_name: 'Anubis',
    anubis_desc:
        'You really, really ought to be on your best behavior around it. The judgment feather is quite light.',
    cyclops_name: 'Cyclops',
    cyclops_desc:
        'An all-around cool dude, all things considered. Has trouble with depth perception.',
    tyrantking_name: 'Tyrant',
    tyrantking_desc:
        'Very insecure about its short stature. Dislikes magnets.',
    mermaid_name: 'Mermaid',
    mermaid_desc:
        'Spends most of its days lounging around. Sunbaths take priority over anything else!',
    harpy_name: 'Harpy',
    harpy_desc: 'How it manages to even fly is a mystery. Loves shinies.',
    goldgolem_name: 'Opulent Golem',
    goldgolem_desc:
        'Gleams like gold. Because it is, in fact, golden all over. Carefully maintains this beautiful sheen with constant maintenance.',
    troblin_argon_nickname: 'Trobbo',
    yeti_argon_nickname: 'Nugget',
    toadwitch_argon_nickname: 'Toady',
    mushgoon_argon_nickname: 'Big Boy',
    anubis_argon_nickname: 'Gustav II',
    cyclops_argon_nickname: 'Bill Neigh',
    tyrantking_argon_nickname: 'Remus',
    mermaid_argon_nickname: 'Lagoon',
    harpy_argon_nickname: 'Condor',
    firecrab_argon_nickname: 'Crusty',
    goldgolem_argon_nickname: 'Goldor',
    troblin_lilu_nickname: 'Nibble',
    yeti_lilu_nickname: 'Fuzzball',
    toadwitch_lilu_nickname: 'Wicce',
    mushgoon_lilu_nickname: 'Take',
    anubis_lilu_nickname: 'Bartholomew',
    cyclops_lilu_nickname: 'Pretty Pony',
    tyrantking_lilu_nickname: 'Gheist',
    mermaid_lilu_nickname: 'Croquette',
    harpy_lilu_nickname: 'Peck Peck',
    firecrab_lilu_nickname: 'Hermit',
    goldgolem_lilu_nickname: 'Gleam',
    troblin_sia_nickname: 'Little One',
    yeti_sia_nickname: 'Mr. Snuggle',
    toadwitch_sia_nickname: 'Lady Batracia',
    mushgoon_sia_nickname: 'Mycon the First',
    anubis_sia_nickname: 'Lord Trepas',
    cyclops_sia_nickname: 'Destrier',
    tyrantking_sia_nickname: 'Sir Balthier',
    mermaid_sia_nickname: 'Queen Alzace',
    harpy_sia_nickname: 'Miss Flannery',
    firecrab_sia_nickname: '',
    goldgolem_sia_nickname: 'Prince Shining',
    troblin_yami_nickname: 'Vermin',
    troblin_yami_nickname_r10: 'Oni',
    yeti_yami_nickname: 'Furcoat',
    yeti_yami_nickname_r10: 'Hibagon',
    toadwitch_yami_nickname: 'Slime',
    toadwitch_yami_nickname_r10: 'Ogama',
    mushgoon_yami_nickname: 'Meathead',
    mushgoon_yami_nickname_r10: 'Matsutake',
    anubis_yami_nickname: 'Mutt',
    anubis_yami_nickname_r10: 'Inugami',
    cyclops_yami_nickname: 'Burden',
    cyclops_yami_nickname_r10: 'Onihitokuchi',
    tyrantking_yami_nickname: 'Tin Can',
    tyrantking_yami_nickname_r10: 'Goryo',
    mermaid_yami_nickname: 'Slug',
    mermaid_yami_nickname_r10: 'Akugyo',
    harpy_yami_nickname: 'Birdbrain',
    harpy_yami_nickname_r10: 'Yatagarasu',
    firecrab_yami_nickname: '',
    firecrab_yami_nickname_r10: '',
    goldgolem_yami_nickname: 'Bag of Rocks',
    goldgolem_yami_nickname_r10: 'Kintsugi',
    troblin_rudo_nickname: 'Jimmy',
    yeti_rudo_nickname: 'Bigfoot',
    toadwitch_rudo_nickname: 'Shango',
    mushgoon_rudo_nickname: 'Junior',
    anubis_rudo_nickname: 'Bob',
    cyclops_rudo_nickname: 'Fat Clops',
    tyrantking_rudo_nickname: 'Undertaker',
    mermaid_rudo_nickname: 'Queen Croc',
    harpy_rudo_nickname: 'Robbie',
    firecrab_rudo_nickname: 'Brawler',
    goldgolem_rudo_nickname: 'The Rock',
    troblin_polonia_nickname: 'Scalliwag',
    yeti_polonia_nickname: 'Swabbie',
    toadwitch_polonia_nickname: 'Kroaken',
    mushgoon_polonia_nickname: 'Fight-Back-Snack',
    anubis_polonia_nickname: 'Jolly Roger',
    cyclops_polonia_nickname: 'Big Sink',
    tyrantking_polonia_nickname: 'Hardtack',
    mermaid_polonia_nickname: 'Lady Jones',
    harpy_polonia_nickname: 'Polly',
    firecrab_polonia_nickname: 'Barnacle',
    goldgolem_polonia_nickname: 'Dubloon',
    troblin_donovan_nickname: 'Zigsnaff',
    yeti_donovan_nickname: 'Abominable',
    toadwitch_donovan_nickname: 'Goobers',
    mushgoon_donovan_nickname: 'Chanterello',
    anubis_donovan_nickname: 'Jackal',
    cyclops_donovan_nickname: 'Polyphemus',
    tyrantking_donovan_nickname: 'Baron',
    mermaid_donovan_nickname: 'Atargatis',
    harpy_donovan_nickname: 'Erinyes',
    firecrab_donovan_nickname: '',
    goldgolem_donovan_nickname: 'Midas',
    troblin_hemma_nickname: 'Barkback',
    yeti_hemma_nickname: 'Frostlurker',
    toadwitch_hemma_nickname: 'Hexless',
    mushgoon_hemma_nickname: 'Toxic Champ',
    anubis_hemma_nickname: 'Hellhound',
    cyclops_hemma_nickname: 'Nightmare',
    tyrantking_hemma_nickname: 'Claud',
    mermaid_hemma_nickname: 'Predator',
    harpy_hemma_nickname: 'Krone',
    firecrab_hemma_nickname: '',
    goldgolem_hemma_nickname: 'Aurum Menace',
    troblin_ashley_nickname: 'Tricky',
    yeti_ashley_nickname: 'Snowball',
    toadwitch_ashley_nickname: 'Matron',
    mushgoon_ashley_nickname: 'Gutsy',
    anubis_ashley_nickname: 'Bonny',
    cyclops_ashley_nickname: 'Dosanko',
    tyrantking_ashley_nickname: 'Captain',
    mermaid_ashley_nickname: 'Dragonova',
    harpy_ashley_nickname: 'Sparrow',
    firecrab_ashley_nickname: '',
    goldgolem_ashley_nickname: 'Rugged',
    troblin_tamas_nickname: 'Goblin Dude',
    yeti_tamas_nickname: 'Best Dude',
    toadwitch_tamas_nickname: 'Frog Dude',
    mushgoon_tamas_nickname: 'Mushdude',
    anubis_tamas_nickname: 'Dog Dude',
    cyclops_tamas_nickname: 'Horse Dude',
    tyrantking_tamas_nickname: 'Ghost Dude',
    mermaid_tamas_nickname: 'Croc Dude',
    harpy_tamas_nickname: 'Bird Dude',
    firecrab_tamas_nickname: 'Crab Dude',
    goldgolem_tamas_nickname: 'Gold Dude',
    wiccandagger_name: 'Wiccan Dagger',
    wiccandagger_desc:
        'Deep within the Witchwoods, mysterious rituals abound... This dagger may have seen its share!',
    wiccanstaff_name: 'Wiccan Broomstick',
    wiccanstaff_desc:
        "It's common practice for wicce to enchant their broom to act as a backup staff.",
    wiccanamulet_name: 'Fellgrove Talisman',
    wiccanamulet_desc:
        'This talisman has recently been charged with magical energy through pagan rituals.',
    aegiraxe_name: 'Jötunnbane',
    aegiraxe_desc: 'Giants beware, for Jötunnbane has returned.',
    aegirgauntlets_name: 'Huscarl Gauntlets',
    aegirgauntlets_desc: 'They bear a special affinity for axes and oars.',
    aegirshield_name: 'Raider Buckler',
    aegirshield_desc:
        'Sound the horn in times of needs and reinforcements will surely come.',
    t12sword_name: 'Excalibur',
    t12sword_desc:
        "They who pull it out of the stone shall become king. Since you crafted that one, it doesn't count.",
    t12axe: '',
    t12dagger_name: 'Sixth Sin',
    t12dagger_desc:
        'How fortunate all these people are. If only it could all be yours instead...',
    t12mace_name: "Thor's Hammer",
    t12mace_desc: 'Thor called. He wants his hammer back.',
    eastermace_name: 'Eggshell Smasher',
    eastermace_desc: "Oh no! It's got sharp edges now!",
    t12spear_name: "Heaven's Wrath",
    t12spear_desc: '',
    t12bow_name: 'Olympus',
    t12bow_desc: '',
    t12staff_name: 'Scepter of Xolimnul',
    t12staff_desc:
        'The darkest of pacts is required to wield this fell scepter. You can only ask yourself, are you truly in charge here?',
    t12wand_name: 'Herald of the End',
    t12wand_desc:
        'From one of its many mouths, the Herald sang the song which would end life as we know it.',
    t12gun_name: "Blackbeard's Handcannon",
    t12gun_desc: 'This thing can scuttle ships. Mind the recoil.',
    t12crossbow_name: 'Godslayer Arbalest',
    t12crossbow_desc: 'Banned in most heavens and astral planes.',
    t12herb_name: "Philosopher's Stone",
    t12herb_desc:
        "Donovan claims it isn't the real deal, but maybe he's just jealous.",
    blossomherb_name: 'Sakura Infusion',
    blossomherb_desc: '',
    t12potion_name: 'Ambrosia',
    t12potion_desc: 'A-D-D-I-C-T-I-V-E.',
    t12scroll_name: 'The Bound Beast',
    t12scroll_desc:
        "A mythological beast, said to absorb the knowledge of all that it ate, is bound within this book. It's hunger has not abated even after millenia.",
    t12shield_name: 'Abyssal Artifact',
    t12shield_desc:
        'A mysterious contraption found in the deepest, darkest reaches of the ocean. Makes for a nice shield, but seemingly could be much more than that.',
    t12ring: '',
    t12amulet_name: "Titania's Keepsake",
    t12amulet_desc:
        'Only once in her long life did Titania ever give something without expecting something in return.',
    mundrapotion_name: 'Mundra Remedy',
    mundrapotion_desc:
        "Mundra's original recipe uses Gems, but Jewels make for a reasonable substitute.",
    t12cloak_name: "Herald's Mantle",
    t12cloak_desc: 'Draped in',
    blossomcloak_name: 'Sakura Cloak',
    blossomcloak_desc: '',
    t12heavyarmor: '',
    t12mediumarmor_name: 'Dawn Thief Uniform',
    t12mediumarmor_desc:
        'So efficient was the Dawnthief that people believe it may have been an entire organization.',
    t12robe_name: 'Exarch',
    t12helm_name: 'Vermilion Guard Helm',
    t12roguehat_name: 'Dawn Thief Mask',
    easterroguehat_name: 'Bunbun Masquerade',
    easterroguehat_desc: '',
    t12magehat_name: 'Exarch Mitre',
    blossommagehat_name: 'Sakura Eboshi',
    blossommagehat_desc: 'The taller the hat, the more powerful the man.',
    t12gauntlets_name: 'Vermilion Guard Might',
    t12gloves_name: "Dawn Thief's Touch",
    t12gloves_desc:
        'Worn during his final ultimate heist. The Dawn Thief plucked the second sun from the sky as easily as one would a flower.',
    t12boots: '',
    t12shoes_name: "Dawn Thief's Path",
    t12shoes_desc:
        'The shoes worn by the Dawn Thief of legends. The legend tells that they allowed him to walk up to the firmament, with even the gods unable to intervene.',
    flower02_name: 'Flower Planter',
    lamp01_name: 'Standing Torch',
    animalfur03_name: 'Beast Fur Carpet',
    animalfur02_name: 'Wild Fur Carpet',
    animalfur01_name: 'Fuzzy Fur Rug',
    goldcarpet_name: 'Luxurious Carpet',
    androidrug_name: 'Cogwheel Rug',
    applerug_name: 'Apple Rug',
    carpetsony_name: 'Techwave Carpet',
    magiccarpet_name: 'Magic Carpet',
    shaggyrug_name: 'Pom-Pom Carpet',
    semicirclemat_name: 'Soft Carpet',
    redcarpet_name: 'Red Carpet',
    redcarpet_name_plural: 'Red Carpets',
    welcomemat_name: 'Welcome Mat',
    welcomemat_name_plural: 'Welcome Mats',
    carpet_semicircle_name: 'Half Moon Carpet',
    carpet_semicircle_name_plural: 'Half Moon Carpets',
    carpet_square_name: 'Square Carpet',
    carpet_square_name_plural: 'Square Carpets',
    carpet_rect_name: 'Rectangle Carpet',
    carpet_rect_name_plural: 'Rectangle Carpets',
    carpet_circ_name: 'Circle Carpet',
    carpet_circ_name_plural: 'Circle Carpets',
    carpet_largerect_name: 'Large Rectangle Carpet',
    carpet_largerect_name_plural: 'Large Rectangle Carpets',
    carpet_largecirc_name: 'Large Circle Carpet',
    carpet_largecirc_name_plural: 'Large Circle Carpets',
    valentinemat_name: 'Lovely Carpet',
    clovercarpet_name: 'Clover Carpet',
    bunnycarpet_name: 'Bunny Carpet',
    chickcarpet_name: 'Chick Carpet',
    starredcarpet_name: 'Starred Carpet',
    christmasrug01_name: 'Merry Flowers Rug',
    christmasrug02_name: 'Holiday Rug',
    christmasrug03_name: 'Gingerbread Rug',
    christmasrug04_name: 'Red Mistletoe Rug',
    christmasrug05_name: 'Golden Mistletoe Rug',
    rosecarpet_name: 'Lovely Rose Carpet',
    cactusrug_name: 'Cactus Rug',
    anniversaryrug01_name: 'Gift Box Carpet',
    anniversaryrug02_name: 'Party Balloons Carpet',
    anniversaryrug03_name: 'Party Popper Carpet',
    priderug01_name: 'Modern Pride',
    priderug02_name: 'Traditional Pride',
    priderug03_name: 'Transgender Pride',
    priderug04_name: 'Nonbinary Pride',
    priderug05_name: 'Asexual Pride',
    priderug06_name: 'Pansexual Pride',
    priderug07_name: 'Lesbian Pride',
    priderug08_name: 'Gay Pride',
    priderug09_name: 'Bisexual Pride',
    priderug10_name: 'Intersex Pride',
    bookshelf_name: 'Bookshelf',
    carpet_owl01_name: 'Midnight Owl Carpet',
    carpet_owl02_name: 'Night Owl Carpet',
    magicclock_name: 'Ancient Magical Clock',
    grandfatherclock_name: 'Grandfather Clock',
    flower01_name: 'Hosta Flower',
    pancake_name: 'Pancake Buddy',
    partitions_name: 'Partition Wall',
    partitions02_name: 'Stained Glass Wall',
    couch01_name: 'Fabulous Couch',
    chair03_name: 'Fabulous Armchair',
    chair02_name: 'Armchair',
    chair_name: 'Wooden Chair',
    globe_name: "Astronomer's Globe",
    bench_name: 'Bench',
    bench02_name: 'Sturdy Bench',
    bin_fabric_desc:
        'A handy basket to keep your personal reserve of fabric.',
    bin_fabric_name: 'Fabric Bin',
    bin_gems_desc: 'A container to hold your personal reserve of jewels.',
    bin_gems_name: 'Jewel Bin',
    bin_herbs_desc: 'A handy rack to keep your personal reserve of herbs.',
    bin_herbs_name: 'Herb Dryer',
    bin_iron_desc: 'A container to hold your personal reserve of iron.',
    bin_iron_name: 'Iron Bin',
    bin_iwood_desc: 'A rack to keep your personal reserve of ironwood.',
    bin_iwood_name: 'Ironwood Bin',
    bin_leather_desc:
        'A crate to contain your personal reserve of leather.',
    bin_leather_name: 'Leather Bin',
    bin_mana_desc:
        'A portable well to contain your personal reserve of ether.',
    bin_mana_name: 'Ether Bin',
    bin_oils_desc:
        'A no-spill pot to contain your personal reserve of oils.',
    bin_oils_name: 'Oil Pot',
    bin_steel_desc:
        'A convenient cart to hold your personal reserve of steel.',
    bin_steel_name: 'Steel Bin',
    bin_wood_desc: 'A container to hold your personal reserve of wood.',
    bin_wood_name: 'Wood Bin',
    carpet02_b_name: 'Motif Semicarpet',
    carpet02_e_name: 'Motif Carpet',
    carpet03_c_name: 'Regal Carpet',
    carpet03_d_name: 'Regal Megacarpet',
    carpet04_a_name: 'Austere Rug',
    carpet04_b_name: 'Austere Semicarpet',
    catstatue_name: 'Beckoning Cat',
    dogstatue_name: 'Faithful Dog',
    chest_name: 'Trunk',
    chest_desc:
        'Stores all the items in your shop. Upgrade it for even more space.',
    chest_name_plural: 'Trunks',
    counter_desc:
        'A surface upon which deals are made and gold crosses palms.',
    counter_name: 'Counter',
    exoticplant_name: 'Exotic Plant',
    fireplace03_name: 'Rough-Hewn Fireplace',
    fireplace02_name: 'Grand Fireplace',
    fireplace_name: 'Fireplace',
    fountain_name: 'Well',
    fountain01_name: 'Fountain',
    furniture_type_bin: 'Bin',
    furniture_type_chest: 'Trunk',
    furniture_type_counter: 'Counter',
    furniture_type_decoration: 'Decoration',
    furniture_type_floordeco: 'Floor decoration',
    furniture_type_outdoordeco: 'Outdoor Decoration',
    furniture_type_walldeco: 'Wall Decoration',
    furniture_type_rack: 'Rack',
    vertrack_name: 'Vertical Rack',
    vertrack_desc: 'A display stand that shows off several large weapons.',
    vertrack_name_plural: 'Vertical Racks',
    largeplant_name: 'Large Plant',
    mailbox01_name: 'Mailbox',
    mailbox02_name: 'Fancy Mailbox',
    mannequin_name: 'Mannequin',
    mannequin_desc: 'A simple wooden rack to display clothes or armor.',
    mannequin_name_plural: 'Mannequins',
    candelabra_name: 'Candelabra',
    obelisk_name: 'Obelisk',
    pillar01_name: 'Pillar',
    pillar02_name: 'Carved Pillar',
    plant_name: 'Plant',
    rich_counter_name: 'Fancy Counter',
    rich_table_name: 'Fancy Table',
    shelves_name: 'Shelves',
    shelves_desc: 'A sturdy shelf that comfortably fits many small items.',
    shelves_name_plural: 'Shelves',
    Storage: 'Storage',
    streetlamp_name: 'Street Lamp',
    bowbanner01_name: 'Bow Banner',
    gunbanner01_name: 'Gun Banner',
    swordbanner01_name: 'Sword Banner',
    table_desc:
        'A flat surface that can accommodate many different item types.',
    table_name: 'Table',
    table_name_plural: 'Tables',
    torch_name: 'Torch',
    vase_name: 'Vase',
    halfpartition_name: 'Low Balustrade',
    halfpartition02_name: 'Low Partition Wall',
    signframe01_name: 'Shop Sign',
    signpost01_name: 'Signpost',
    totempole01_name: 'Totem Pole',
    fireworkcannon_name: 'Firework Cannon',
    emperorbust_name: "Emperor's Bust",
    tent_name: "Explorer's Tent",
    campfire_name: 'Campfire',
    mystictome_name: 'Mystic Tome',
    savepoint_name: 'Save Point',
    scarecrow_name: 'Scarecrow',
    oktoberfestbarrel_name: 'Oktoberfest Barrel',
    witchpot_name: 'Malevolent Brew',
    witchpotpurple_name: 'Stygian Brew',
    bluecandlebig_name: 'Large Soul Candle',
    bluecandlesmall_name: 'Soul Candle',
    coffin_name: 'Unearthed Coffin',
    tombstone_name: 'Tombstone',
    pumpkin_name: 'Classic Pumpkin',
    pumpkincat_name: 'Kitty Pumpkin',
    pumpkincute_name: 'Cute Pumpkin',
    pumpkinwink_name: 'Wink Pumpkin',
    pumpkinevil_name: 'Evil Pumpkin',
    argonbanner_name: 'Valiant Banner',
    yamiscreen_name: 'Oni Shrine',
    cornucopia_name: 'Cornucopia of Plenty',
    snowman_name: 'Snowman',
    sled_name: "Santa's Sled",
    christmascane_name: 'Candy Cane',
    christmasgifts_name: 'Pile of Gifts',
    soldier_name: 'Nutcracker Soldier',
    christmastree_name: 'Christmas Tree',
    christmasball_red_name: 'Red Bauble',
    christmasball_green_name: 'Green Bauble',
    christmasball_blue_name: 'Blue Bauble',
    christmasball_orange_name: 'Orange Bauble',
    christmasball_purple_name: 'Purple Bauble',
    cookietable_name: 'Cookie Table',
    interiorchristmastree_name: 'Christmas Tree',
    gingerbreadhouse_name: 'Gingerbread House',
    christmasfireplace_name: 'Decorated Fireplace',
    luckymouseapple_name: 'Lucky Mouse (Coin)',
    luckymouseandroid_name: 'Lucky Mouse (Sycee)',
    lunarpartitions_name: 'Lunar Screen',
    lunartable_name: 'Lunar Tea Set',
    lunarchair_name: 'Lunar Chair',
    lunarstool_name: 'Lunar Stool',
    lunaryearlampost01_name: 'Lunar Lantern A',
    lunaryearlampost02_name: 'Lunar Lantern B',
    lunaryearlanterns01_name: 'Lantern Set A',
    lunaryearlanterns02_name: 'Lantern Set B',
    dancingdragon_purple_name: 'Purple Dancing Lion',
    dancingdragon_blue_name: 'Blue Dancing Lion',
    dancingdragon_red_name: 'Red Dancing Lion',
    dancingdragon_yellow_name: 'Yellow Dancing Lion',
    ratballoons_name: 'Wealthy Rat Balloons',
    lunarfireworks_name: "New Year's Fireworks",
    lunargong_name: 'Lunar Gong',
    cupidfountain_name: 'Cupid Fountain',
    valentinetarget_name: 'Matchmaker Bullseye',
    gemtable_name: 'Jeweled Music Box',
    luggage_name: "Merchant's Suitcase",
    merchantdesk_name: "Merchant's Desk",
    merchantsafe_name: "Merchant's Safe",
    cloverchair_name: "St. Patrick's Chair",
    cloverdrawer_name: "St. Patrick's Drawer",
    clovercabinet_name: "St. Patrick's Cabinet",
    goldcauldron_name: 'Pot of Gold',
    buriedgoldcauldron_name: "Leprechaun's Stash",
    cloverpot_name: 'Potted Luck',
    koala_name: 'Huggable Koala',
    flowerlattice_name: 'Blooming Fence',
    springarch_name: 'Flowered Arch',
    chocobunny_name: 'Chocolate Bunny',
    eggsbasket_name: 'Easter Basket',
    bunnytable_name: 'Bunny Table',
    chicklamp_name: 'Chick Lamp',
    bunnylamp_name: 'Bunny Lamp',
    bunnychair_name: 'Bunny Chair',
    chicksofa_name: 'Chick Sofa',
    easterpartition_name: 'Easter Panel',
    bunnymailbox_name: 'Mail Bunny',
    chickmailbox_name: 'Chick Mailbox',
    egglamp01_name: 'Blue Egg Lantern',
    egglamp02_name: 'Pink Egg Lantern',
    egglamp03_name: 'Green Egg Lantern',
    egglamp04_name: 'Yellow Egg Lantern',
    egglamp05_name: 'Purple Egg Lantern',
    carrotcrop_name: 'Carrot Crop',
    eastercabinet_name: 'Ostara Cabinet',
    chocolatechicken_name: 'Cocoa Chicken',
    easterbouquet_name: 'Easter Eggs Bouquet',
    cloverboots_name: 'Shamrock Boot',
    legendarysword_name: 'Fabled Sword',
    flowerwagon_name: 'Blooming Wheelbarrow',
    flowerwheel_name: 'Flowery Wheel',
    victorianlamp_name: 'Elegant Lamp',
    victoriancouch_name: 'Elegant Lounge Couch',
    picnicset_name: 'Picnic Set ',
    swingset_name: 'Floral Bench Swing',
    poloniaglobe_name: 'Globe of the Nine Seas',
    rudobeartotem_name: 'Elder Bear Totem',
    carriage_name: "King's Coolest Carriage",
    carriage_desc: 'A gift from King Reinhold.',
    guitarcactus_name: 'Singing Cactus',
    dancingcactus_name: 'Dancing Cactus',
    cincopartition_name: 'Partition de Mayo',
    tacocouch_name: 'Tacouch',
    tacochair_name: 'Taco Armchair',
    tacopillow_name: 'Taco Pillow',
    cincotable_name: 'Table de Mayo',
    cincostool_name: 'Stool de Mayo',
    romanbed_name: 'Imperial Bed',
    marblepool_name: 'Imperial Bath',
    romantable_name: "Emperor's Table",
    romancolumn_name: 'Imperial Column',
    craftingtable_name: "Allan's Wonderful Workbench",
    carnivorousplant_name: 'Bela Carnivora',
    schooldesk_name: 'School Desk',
    schoolboard_name: 'Black Board',
    schoollocker_name: 'Academy Locker',
    schoolcontender_name: 'Academy Coat Hangers',
    magiclamp_name: 'Magical Lamp',
    flyingcarpet_name: 'Floating Carpet',
    falldresser_name: 'Dresser of Autumn',
    fallbed_name: 'Bed of Seasons',
    oktoberbar_name: 'Cider Counter',
    telescope_name: 'Astral Observatory',
    crystalball_name: 'Foretelling Globe',
    pumpkincandelabra_name: 'Pumpkin Candelabra',
    hauntedmirror_name: 'Haunted Mirror',
    thanksgivingoven_name: 'Oven of Abundance',
    thanksgivingtable_name: 'Table of Reunion',
    liluchair_name: 'Pollux Seat',
    siavanity_name: 'Dazzling Vanity',
    cincopinata_name: 'Mega Pinata',
    sombrerolamp_name: 'Sombrero Lamp',
    nestchair_name: 'Comfy Nest',
    popcorncart_name: 'Popcorn Cart',
    honeypot_name: 'Honey Pot',
    troblinplush_name: 'Troublin Plushie',
    fusioncauldron_name: 'Synthesis Vessel',
    freedomballoons_name: 'Pinwheels & Balloons',
    kingbust_name: 'Monument of the Majestic Monarch',
    kingbust_desc: 'A gift from King Reinhold.',
    grottoplushie_name: 'Yeti Plushie',
    catchair_name: 'Fat Cat Lap',
    cattable_name: 'Fishy Table',
    dogcoffeetable_name: 'Doggie Paw Table',
    dogbed_name: 'Doghouse Bed',
    wafflecouch_name: 'Waffle Couch',
    gummachine_name: 'Gumball Machine',
    moonbanner_name: 'Usagi Banner',
    mochitable_name: 'Mochi Snack Table',
    atlab_paisho_name: 'Paisho Table & Tea',
    atlab_firethrone_name: "Lord Ozai's Throne",
    christmastrain_name: 'North Pole Train',
    newyearballoons_name: '2022 Balloons',
    toadwitchplushie_name: 'Toadwitch Plushie',
    lunarcenser_name: 'Lunar Censer',
    dumplingtable_name: 'Dumpling Table',
    valentinechair_name: 'Lovely Chair',
    valentinepartition_name: 'Lovely Partition',
    valentineplant01_name: 'Rose Tree (Red)',
    valentineplant02_name: 'Rose Tree (Yellow)',
    valentineplant03_name: 'Rose Tree (Pink)',
    sultanlampost_name: 'Desert Lantern',
    arabiancactus_name: 'Kern Cactus',
    sultanpartition_name: 'Sultan Screen',
    sultancouch_name: 'Sultan Rest',
    camelstatue_name: 'Arabian Camel Idol',
    goldcamelstatue_name: 'Goldmedary',
    oktoberhorn_name: 'Alpenhorn',
    gravehand_name: 'Shallow Grave',
    ghostrock_name: 'Spirit Stone',
    hauntedtree_name: 'Dark Treant',
    pumpkinlamppost_name: 'Pumpkin Lanterns',
    skulltentaclepot_name: 'Eldritch Skull',
    pumpkintentaclepot_name: 'Betentacled Pumpkin',
    mushroomstool01_name: 'Yellow Toadstool',
    mushroomstool02_name: 'Red Toadstool',
    mushroomstool03_name: 'Blue Toadstool',
    mushroomstool04_name: 'Purple Toadstool',
    mushroomstool05_name: 'Orange Toadstool',
    glowingfungus_name: 'Luminescent Fungus',
    lemonadestand_name: 'Lemonade Stand',
    kidpool_name: 'Kiddy Pool',
    julybbq_name: 'Summer Grill',
    starredbox_name: 'Starry Decoration',
    freedomflowers_name: 'Flowers of Liberty',
    freedomtable_name: 'Starry Table',
    freedomchair_name: 'Starry Chair',
    freedomcouch_name: 'Striped Couch',
    starredlantern_name: 'Starred Lanterns',
    americanflag_name: 'Starred Flag',
    freedomstatue_name: 'Liberty Effigy',
    colosseum_name: 'Miniature Colosseum',
    romanshrub_name: 'Imperial Topiary',
    skis_name: 'Jolly Skis',
    sled02_name: 'Logs Sled',
    christmasplant01_name: 'Pine Cone Bouquet',
    christmasplant02_name: 'Merry Green',
    christmasbench_name: 'Gleeful Bench',
    giftspile_name: 'Presents Pile',
    deerlog_name: 'Deerest Log',
    christmaselfdecoration_name: "Santa's Little Helper",
    northpolepost_name: 'Northern Post',
    santadecoration_name: 'Snowbank Accident',
    dragongong_name: "Sondra's Gong",
    dragoncrystal_name: "Mundra's Moonstone Reef",
    lootbarrel_name: 'Jackpot',
    vipjukebox_name: 'Golden Notes',
    elephantidol_name: 'Marble Elephant',
    pillows_name: 'Lavish Pillows',
    bambooplant_name: 'Bamboo Saplings',
    bambootable_name: 'Bamboo Arrangement',
    couplenightstand_name: 'Wild Nightstand',
    couplebearstatue_name: 'Kodiak Effigy',
    oxstatue01_name: 'Fortune Ox',
    oxstatue02_name: 'Lucky Coin Ox',
    cincopicnictable_name: 'Picnic Table de Mayo',
    birdhouse_name: 'Birdhouse',
    circustent_name: 'Circus Tent',
    apiary_name: 'Busy Beehive',
    troblintrap_name: 'Devious Trap',
    freedombanner_name: 'Eagle Banner',
    grottoicevein_name: 'Precious Gem Rock',
    swaggydonut_name: 'Swaggy Donuts Sign',
    spookytree_name: 'The Hollow Oak',
    falltrunkchair_name: 'Shroomy Stump',
    atlab_cabbagecart_name: 'Cabbage Cart',
    atlab_koipond_name: 'Spirit Koi Pond',
    atlab_appa_name: 'Sleepy Appa',
    atlab_firebanner_name: 'Fire Banner',
    atlab_waterbanner_name: 'Water Banner',
    atlab_airbanner_name: 'Air Banner',
    atlab_earthbanner_name: 'Earth Banner',
    atlab_rockhut_name: "Toph's Rock Hut",
    swamphouse_name: "Baba Yaga's House",
    lunarmailbox_name: 'Lunar Mailbox',
    tigerstatue01_name: 'Tiger Statue (Coin)',
    tigerstatue02_name: 'Tiger Statue (Knot)',
    tigerstatue03_name: 'Tiger Statue (Sycee)',
    wd_books_name: 'Book Shelf',
    wd_catframe_name: 'Mrs. Rainbow Portrait',
    wd_catclock_name: 'Kitty Clock',
    wd_chandelier_name: 'Brass Sconce',
    wd_flowerpot_name: 'Flower Pot',
    wd_flowers1_name: 'Hanging Roses (Red)',
    wd_flowers2_name: 'Hanging Roses (Blue)',
    wd_lantern_name: 'Lantern',
    wd_mooseantlers_name: 'Antlers',
    wd_porthole_name: 'Porthole',
    wd_roundwindow_name: 'Round Window',
    wd_stainedglass_name: 'Stained Glass',
    wd_window_name: 'Wooden Window',
    wd_fallwreath_name: 'Bountiful Wreath',
    wd_halloweenframe_name: 'Framed Spiderweb',
    wd_snowflakes_name: 'Snowflakes',
    wd_christmaswreath_name: 'Christmas Wreath',
    wd_newyearclock_name: 'New Year Clock',
    wd_lunarwalldeco_name: 'Good Luck Knot',
    statue_blacksmith_name: 'Wallace Statue',
    statue_carpenter_name: 'Allan Statue',
    statue_herbalist_name: 'Maribel Statue',
    statue_tailor_name: 'Julia Statue',
    statue_herbs_name: 'Juniper Statue',
    statue_innkeeper_name: 'Oscar Statue',
    statue_miner_name: 'Durhan Statue',
    statue_orc_lady_name: 'Gorza Statue',
    statue_tanner_name: 'Tamas Statue',
    statue_wizard_name: 'Grimar Statue',
    statue_mana_name: 'Serene Statue',
    statue_ironwood_name: 'Jog Statue',
    statue_steel_name: 'Brohm Statue',
    statue_elderowen_name: 'Elder Owen Statue',
    statue_priest_name: 'Freyja Statue',
    statue_oil_name: 'Beatrice Statue',
    statue_casinogirl_name: 'Maya Statue',
    statue_fabric_name: 'Ismael Statue',
    statue_master_name: 'Theodore Statue',
    statue_academy_name: 'Evelyn Statue',
    statue_sundragon_name: 'Sondra Statue',
    firepit_name: 'Fire Pit',
    decoration_wall_basicLayout_name: 'Standard',
    decoration_wall_wood_name: 'Faded Brick',
    decoration_wall_nicewood_name: 'Pale Stripes',
    decoration_wall_roughstone_name: 'Diamond Pattern',
    decoration_wall_squarestone_name: 'Navy Panel',
    decoration_wall_brick_name: 'Wood Panel',
    decoration_wall_dualstone_name: 'Ornate Sapphire',
    decoration_wall_finebrick_name: 'Ornate Emerald',
    decoration_wall_halloween01_name: 'Dusk Kitties',
    decoration_wall_halloween02_name: 'Dawn Kitties',
    decoration_wall_halloween03_name: 'Dusk Pumpkins',
    decoration_wall_halloween04_name: 'Dawn Pumpkins',
    decoration_wall_halloween05_name: 'Dusk Bats',
    decoration_wall_halloween06_name: 'Twilight Forest',
    decoration_wall_halloween07_name: 'Dusk Batkin',
    decoration_wall_halloween08_name: 'Blue Kitties',
    decoration_wall_halloween09_name: 'Green Kitties',
    decoration_wall_halloween010_name: 'Yellow Kitties',
    decoration_wall_thanksgiving01_name: 'Autumnal Print',
    decoration_wall_thanksgiving02_name: 'Acorn Arch',
    decoration_wall_christmas01_name: 'Snowfall',
    decoration_wall_christmas02_name: 'Gift-Wrapped',
    decoration_wall_christmas03_name: 'Ornaments',
    decoration_wall_lunar01_name: 'Pagoda',
    decoration_wall_lunar02_name: 'Lucky Coin',
    decoration_wall_lunar03_name: 'Red Lucky Mouse',
    decoration_wall_lunar04_name: 'Green Lucky Mouse',
    decoration_wall_lunar05_name: 'Lavender Lucky Mouse',
    decoration_wall_lunar06_name: 'Purple Lucky Mouse',
    decoration_wall_lunar07_name: 'Blue Lucky Mouse',
    decoration_wall_valentine01_name: 'Cupid Fresco',
    decoration_wall_valentine02_name: 'Strings of Passion',
    decoration_wall_stpatrick01_name: 'Lucky Clover',
    decoration_wall_stpatrick02_name: 'Golden Knot',
    decoration_wall_stpatrick03_name: 'Milesian Motif',
    decoration_wall_easter01_name: 'Mister Rabbit',
    decoration_wall_easter02_name: 'Chicks Print',
    decoration_wall_easter03_name: 'Easter Eggs',
    decoration_wall_easter04_name: 'Easter Ribbons',
    decoration_wall_easter05_name: 'Bunbun Arch',
    decoration_wall_polonia_name: 'Nine Seas Wallpaper',
    decoration_wall_cincodemayo01_name: 'Fresco de Mayo',
    decoration_wall_cincodemayo02_name: 'Paper de Mayo',
    decoration_wall_cincodemayo03_name: 'Prickly Print',
    decoration_wall_summer01_name: 'By The Sea',
    decoration_wall_victorian01_name: 'Victorian Flowers',
    decoration_wall_wallace_name: 'Wallace Workshop',
    decoration_wall_4thofjuly01_name: 'Star Spangled',
    decoration_wall_4thofjuly02_name: 'Star Spangled (Large)',
    decoration_wall_4thofjuly03_name: 'Striped Stars',
    decoration_wall_herbalist01_name: "Maribel's Lattice",
    decoration_wall_augustus2020_01_name: 'Aureus Fresco',
    decoration_wall_arabiannight01_name: "Sultan's Finest",
    decoration_wall_arabiannight02_name: 'Desert Rose',
    decoration_wall_arabiannight03_name: 'The Last Night',
    decoration_wall_school01_name: 'Warding Fresco',
    decoration_wall_autumn01_name: 'Mushroom Green',
    decoration_wall_autumn02_name: 'Dark Toadstool',
    decoration_wall_autumn03_name: 'Golden Fungi',
    decoration_wall_oktoberfest01_name: 'Folk Art Print',
    decoration_wall_wizard01_name: 'Celestial Print',
    decoration_wall_sia01_name: "Kern's Jewel",
    decoration_wall_thanksgiving03_name: 'Turkey of Plenty',
    decoration_wall_thanksgiving04_name: 'Feathery Print',
    decoration_wall_thanksgiving05_name: 'Feathers Wind',
    decoration_wall_winter01_name: 'Snowy Canopy',
    decoration_wall_christmas04_name: 'Jolly Print',
    decoration_wall_christmas05_name: 'Mistletoe Wreath',
    decoration_wall_christmas06_name: 'Jingle Bells',
    decoration_wall_christmas07_name: 'Gold Mistletoe',
    decoration_wall_sundragon01_name: "Sondra's Print",
    decoration_wall_moondragon_name: "Mundra's Print",
    decoration_wall_vip01_name: 'Fortunate Print',
    decoration_wall_india01_name: 'Lotus Impressions',
    decoration_wall_couple01_name: 'Wooden Rose Garden',
    decoration_wall_lunar2021_01_name: 'Lunar New Year',
    decoration_wall_victorianspring01_name: 'Elegant Spring Print',
    decoration_wall_earthday01_name: "Gaia's Fresco",
    decoration_wall_cincodemayo04_name: 'Chile de Mayo',
    decoration_wall_cincodemayo05_name: 'Chile de Mayo (mild)',
    decoration_wall_cincodemayo06_name: 'Maracas de Mayo',
    decoration_wall_birds01_name: 'Tweety Trees',
    decoration_wall_bee01_name: 'Busy Beehive',
    decoration_wall_circus01_name: 'Circus Tent',
    decoration_wall_4thofjuly04_name: 'Star Spangled (Wavy)',
    decoration_wall_troblin01_name: 'Troublin Print',
    decoration_wall_yeti01_name: 'Happy Yeti',
    decoration_wall_cat01_name: 'Fishy Trophy',
    decoration_wall_dog01_name: 'Prized Bone',
    decoration_wall_candy01_name: "Drippin' Delicious",
    decoration_wall_moon01_name: 'Moonlight Usagi',
    decoration_wall_hispanic01_name: 'Stucco Walls',
    decoration_wall_halloween011_name: 'Cobwebbed',
    decoration_wall_autumn04_name: 'Frog Pals',
    decoration_wall_atlab_wall01_name: "Fire Lord's Wall",
    decoration_wall_atlab_wall02_name: "Ba Sing Se's Walls",
    decoration_wall_christmas08_name: 'Cabin Windows',
    decoration_wall_christmas09_name: 'Festive Cabin Lights',
    decoration_wall_winter02_name: 'Winter Cabin Veranda',
    decoration_wall_winter03_name: 'Cabin Lights',
    decoration_wall_newyear01_name: 'New Year Fireworks',
    decoration_wall_frog01_name: 'Toady Print',
    decoration_wall_lunar2022_01_name: 'Year of the Tiger',
    decoration_wall_valentine03_name: 'Lovely Print',
    decoration_floor_basicLayout_name: 'Standard',
    decoration_floor_wood_name: 'Ashwood',
    decoration_floor_nicewood_name: 'Hardwood',
    decoration_floor_roughstone_name: 'Rough Stone',
    decoration_floor_squarestone_name: 'Green Panel',
    decoration_floor_brick_name: 'Purple Panel',
    decoration_floor_dualstone_name: 'Patterned Green',
    decoration_floor_finebrick_name: 'Patterned Blue',
    decoration_floor_dualbrick_name: 'Checkered Brown',
    decoration_floor_multibrick_name: 'Checkered Pink',
    decoration_floor_floor11_name: 'Checkered Gray',
    decoration_floor_floor12_name: 'Floral Light',
    decoration_floor_floor13_name: 'Floral Dark',
    decoration_floor_floor14_name: 'Navy',
    decoration_floor_floor15_name: 'Red',
    decoration_floor_floor16_name: 'Pink',
    decoration_floor_floor17_name: 'Blue Bubble',
    decoration_floor_floor18_name: 'Red Bubble',
    decoration_floor_floor19_name: 'Red Diamond',
    decoration_floor_floor20_name: 'Green Diamond',
    decoration_floor_halloween01_name: 'Dusk Kitty',
    decoration_floor_halloween02_name: 'Dark Crescent',
    decoration_floor_halloween03_name: 'Dawn Kitty',
    decoration_floor_halloween04_name: 'Orange Moon',
    decoration_floor_halloween05_name: 'Dusk Pumpkin',
    decoration_floor_halloween06_name: 'Gummy Tiles',
    decoration_floor_halloween07_name: 'Dawn Pumpkin',
    decoration_floor_halloween08_name: 'Candy Tiles',
    decoration_floor_halloween09_name: 'Creaky Floor Boards',
    decoration_floor_halloween010_name: 'Golden Batkin',
    decoration_floor_halloween011_name: 'Blue Kitty',
    decoration_floor_halloween012_name: 'Blue Moons',
    decoration_floor_halloween013_name: 'Green  Kitty',
    decoration_floor_halloween014_name: 'Green Moons',
    decoration_floor_halloween015_name: 'Yellow Kitty',
    decoration_floor_halloween016_name: 'Yellow Moons',
    decoration_floor_thanksgiving01_name: 'Autumn Diamond',
    decoration_floor_thanksgiving02_name: 'Checkered Sepia',
    decoration_floor_thanksgiving03_name: 'Acorn Jr.',
    decoration_floor_thanksgiving04_name: 'Great Acorn',
    decoration_floor_christmas01_name: 'Snowflake',
    decoration_floor_christmas02_name: 'Gift-Wrapped',
    decoration_floor_christmas03_name: 'Wreath',
    decoration_floor_lunar01_name: 'Lunar Tile ',
    decoration_floor_lunar02_name: 'Red Lucky  Mouse ',
    decoration_floor_lunar03_name: 'Green Lucky Mouse',
    decoration_floor_lunar04_name: 'Lavender Lucky Mouse',
    decoration_floor_lunar05_name: 'Purple Lucky Mouse',
    decoration_floor_lunar06_name: 'Blue Lucky Mouse',
    decoration_floor_valentine01_name: 'Bronze Heart',
    decoration_floor_valentine02_name: 'Bronze Plaque',
    decoration_floor_stpatrick01_name: 'Clover Tile',
    decoration_floor_stpatrick02_name: 'Gold Knot Tile',
    decoration_floor_stpatrick03_name: 'Shamrock Field',
    decoration_floor_easter01_name: 'Turquoise Pastel',
    decoration_floor_easter02_name: 'Lilac Pastel',
    decoration_floor_easter03_name: 'Yellow Pastel',
    decoration_floor_easter04_name: 'Pastel Floorboard',
    decoration_floor_polonia_name: 'Nine Seas Tile',
    decoration_floor_cincodemayo01_name: 'Cyan Tile',
    decoration_floor_cincodemayo02_name: 'Burgundy Tile',
    decoration_floor_cincodemayo03_name: 'Dunes',
    decoration_floor_summer01_name: 'Beachside',
    decoration_floor_victorian01_name: 'Dapper Diamonds',
    decoration_floor_wallace_name: "Smithy's Floor",
    decoration_floor_4thofjuly01_name: 'Silver Star Red',
    decoration_floor_4thofjuly02_name: 'Silver Star Blue ',
    decoration_floor_4thofjuly03_name: 'Silver Star White ',
    decoration_floor_4thofjuly04_name: 'Silver Framed Red',
    decoration_floor_4thofjuly05_name: 'Silver Framed Blue',
    decoration_floor_4thofjuly06_name: 'Silver Framed White',
    decoration_floor_4thofjuly07_name: 'Gold Star Red',
    decoration_floor_4thofjuly08_name: 'Gold Star Blue',
    decoration_floor_4thofjuly09_name: 'Gold Star White',
    decoration_floor_4thofjuly010_name: 'Gold Framed Red',
    decoration_floor_4thofjuly011_name: 'Gold Framed Blue',
    decoration_floor_4thofjuly012_name: 'Gold Framed White',
    decoration_floor_herbalist01_name: "Allan's Meadow",
    decoration_floor_augustus2020_01_name: 'Aureus Tile',
    decoration_floor_arabiannight01_name: 'Sultan Pattern',
    decoration_floor_arabiannight02_name: 'Desert Rose Tile',
    decoration_floor_arabiannight03_name: 'Desert Sky Tile',
    decoration_floor_school01_name: 'Academy Tile',
    decoration_floor_autumn01_name: 'Cobblestone',
    decoration_floor_autumn02_name: 'Goldenstone',
    decoration_floor_oktoberfest01_name: 'Folk Art Tile',
    decoration_floor_wizard01_name: 'Celestial Tile',
    decoration_floor_sia01_name: 'Jeweled Step',
    decoration_floor_leaves01_name: 'Sparse Leaves',
    decoration_floor_leaves02_name: 'Leaves Bundle',
    decoration_floor_leaves03_name: 'Late Harvest',
    decoration_floor_leaves04_name: 'Still Life',
    decoration_floor_thanksgiving05_name: 'Golden Feather',
    decoration_floor_winter01_name: 'Fresh Snowfall',
    decoration_floor_christmas04_name: 'Red Mistletoe',
    decoration_floor_christmas05_name: 'Green Mistletoe',
    decoration_floor_christmas06_name: 'Holiday Spirit',
    decoration_floor_sundragon01_name: "Sondra's Tile",
    decoration_floor_moondragon01_name: "Mundra's Tile",
    decoration_floor_vip01_name: "Fortune's Diamonds",
    decoration_floor_india01_name: 'Golden Marble',
    decoration_floor_couple01_name: 'Wooden Rose',
    decoration_floor_lunar2021_01_name: 'Year of the Ox',
    decoration_floor_victorianspring01_name: 'Elegant Spring Tile',
    decoration_floor_earthday01_name: "Gaia's Tile",
    decoration_floor_cincodemayo04_name: 'Chile de Mayo',
    decoration_floor_cincodemayo05_name: 'Chile de Mayo (mild)',
    decoration_floor_cincodemayo06_name: 'Flor de Mayo',
    decoration_floor_birds01_name: 'Tweety Tracks',
    decoration_floor_bee01_name: 'Honeycombs',
    decoration_floor_circus01_name: 'Circus Stage',
    decoration_floor_4thofjuly013_name: 'Premium Star',
    decoration_floor_troblin01_name: 'Bushy Undergrowth',
    decoration_floor_yeti01_name: 'Slippery Ice',
    decoration_floor_cat01_name: 'Cat Paw Print',
    decoration_floor_dog01_name: 'Dog Paw Print',
    decoration_floor_candy01_name: 'Waffle-stuffed',
    decoration_floor_moon01_name: 'Moontouched Tile',
    decoration_floor_hispanic01_name: 'Saltillo Tile',
    decoration_floor_halloween017_name: 'Haunted Flooring',
    decoration_floor_autumn03_name: 'Leafy Undergrowth',
    decoration_floor_atlab_floor01_name: "Fire Lord's Floor",
    decoration_floor_atlab_floor02_name: "Ba Sing Se's Floors",
    decoration_floor_christmas07_name: 'Winter Cabin',
    decoration_floor_winter02_name: 'Snowblown Carpet',
    decoration_floor_newyear01_name: "New Year's Carpet",
    decoration_floor_frog01_name: 'Swamp Lilypads',
    decoration_floor_lunar2022_01_name: 'Year of the Tiger',
    decoration_floor_valentine03_name: 'Lovely Tiles',
    decoration_beam_beam01_defaultwood_name: 'Standard',
    decoration_beam_beam02_classicwood_name: 'Fancy Wood',
    decoration_beam_beam03_roman_name: 'Imperial',
    decoration_beam_beam04_egyptian_name: 'Royal Tomb',
    decoration_beam_beam05_victorian_name: 'Victorian',
    decoration_beam_beam06_moroccan_name: 'Gaudy',
    decoration_beam_beam07_asian_name: 'Eastern',
    clocktower_name: 'Tiny Ben',
    victorianbed_name: 'Lavish Bed',
    tailorloom_name: "Julia's First Loom",
    wallaceanvil_name: "Wallace's Favorite Anvil",
    curvebed_name: 'Refined Bed',
    fleurdelys_name: 'Fleur de Lys',
    smallfleurdelys_name: 'Small Lys',
    decoration_floor_desc: 'Flooring once found in a seasonal pack.',
    decoration_wall_desc: 'Wallpaper once found in a seasonal pack.',
    decoration_outdoor_desc:
        'Outdoor decoration once found in a seasonal pack.',
    decoration_indoor_desc:
        'Shop decoration once found in a seasonal pack.',
    decoration_floordeco_desc: 'Carpet once found in a seasonal pack.',
    decoration_walldeco_desc:
        'Wall decoration once found in a seasonal pack.',
    decoration_beams_desc: 'Shop frame once found in a seasonal pack',
    normal_pet_food_name: 'Pet Food',
    superior_pet_food_name: 'Superior Food',
    premium_pet_food_name: 'Premium Food',
    pet_family_cat: 'Cat',
    pet_family_dog: 'Dog',
    pet_family_unicorn: 'Unicorn',
    pet_family_chicken: 'Chicken',
    pet_family_hamster: 'Hamster',
    pet_family_frog: 'Frog',
    pet_family_ox: 'Ox',
    pet_family_atlab_momo: 'Momo',
    pet_family_tiger: 'Tiger',
    pet_family_desc: 'Pet once found in a previous pack!',
    pet_custom_skin_cat01_name: 'Black',
    pet_custom_skin_cat02_name: 'Tabby',
    pet_custom_skin_cat03_name: 'Tuxedo',
    pet_custom_skin_cat04_name: 'Tiger',
    pet_custom_skin_cat05_name: 'Siamese',
    pet_custom_skin_cat06_name: 'Ember',
    pet_custom_skin_cat07_name: 'Shura',
    pet_custom_skin_cat08_name: 'Esper',
    pet_custom_skin_cat09_name: 'Calico',
    pet_custom_skin_cat10_name: 'Snow Peak',
    pet_custom_skin_dog01_name: 'Weimaraner',
    pet_custom_skin_dog02_name: 'Golden',
    pet_custom_skin_dog03_name: 'Beagle',
    pet_custom_skin_dog04_name: 'Bernese',
    pet_custom_skin_dog05_name: 'Dalmatian',
    pet_custom_skin_dog06_name: 'Growly',
    pet_custom_skin_dog07_name: 'Labrador',
    pet_custom_skin_dog08_name: 'Poochie Blue',
    pet_custom_skin_dog09_name: 'Old Boy',
    pet_custom_skin_dog10_name: 'Marked',
    pet_custom_skin_chicken01_name: 'Leghorn',
    pet_custom_skin_chicken02_name: 'Jersey',
    pet_custom_skin_chicken03_name: 'Orpington',
    pet_custom_skin_chicken04_name: 'Ashy',
    pet_custom_skin_chicken05_name: 'Reinhold',
    pet_custom_skin_chicken06_name: 'Golden Goose',
    pet_custom_skin_chicken07_name: 'Regal',
    pet_custom_skin_chicken08_name: 'Biohazard',
    pet_custom_skin_chicken09_name: 'Plymouth',
    pet_custom_skin_chicken10_name: 'EGGLAYER.EXE',
    pet_custom_skin_hamster01_name: 'Richard',
    pet_custom_skin_hamster02_name: 'Dusty',
    pet_custom_skin_hamster03_name: 'Dumpling',
    pet_custom_skin_hamster04_name: 'Bat Ham',
    pet_custom_skin_hamster05_name: 'Rusty',
    pet_custom_skin_hamster06_name: 'Fluffy',
    pet_custom_skin_hamster07_name: 'Apple Cheeks',
    pet_custom_skin_hamster08_name: 'Topsy-Turvy',
    pet_custom_skin_hamster09_name: 'Stardust',
    pet_custom_skin_hamster10_name: "Lil' Luchador",
    pet_custom_skin_unicorn01_name: 'Lollipop',
    pet_custom_skin_unicorn02_name: 'Cotton Candy',
    pet_custom_skin_unicorn03_name: 'Licorice',
    pet_custom_skin_unicorn04_name: 'Meringue',
    pet_custom_skin_unicorn05_name: 'Sherbet',
    pet_custom_skin_unicorn06_name: 'Pinky',
    pet_custom_skin_unicorn07_name: 'Fluffy-Shy',
    pet_custom_skin_unicorn08_name: 'Comet',
    pet_custom_skin_unicorn09_name: 'Fruitcake',
    pet_custom_skin_unicorn10_name: 'Diamond Stallion',
    pet_custom_skin_frog01_name: 'Classic',
    pet_custom_skin_frog02_name: 'Turquoise',
    pet_custom_skin_frog03_name: 'Tree Frog',
    pet_custom_skin_frog04_name: 'Indian Bullfrog',
    pet_custom_skin_frog05_name: 'Jungle Frog',
    pet_custom_skin_frog06_name: 'Tomato',
    pet_custom_skin_frog07_name: 'Poison Dart',
    pet_custom_skin_frog08_name: 'Lotus',
    pet_custom_skin_frog09_name: 'Golden Prince',
    pet_custom_skin_frog10_name: 'Mr. Ribbito',
    pet_custom_skin_ox01_name: 'Highland',
    pet_custom_skin_ox02_name: 'Angus',
    pet_custom_skin_ox03_name: 'Hereford',
    pet_custom_skin_ox04_name: 'Wildebeest',
    pet_custom_skin_ox05_name: 'Holstein',
    pet_custom_skin_ox06_name: 'Blizzard',
    pet_custom_skin_ox07_name: 'Stella',
    pet_custom_skin_ox08_name: 'Daisy',
    pet_custom_skin_ox09_name: 'Luna',
    pet_custom_skin_ox10_name: 'Sprinkle',
    pet_custom_skin_ox11_name: 'Blondie',
    pet_custom_skin_ox12_name: 'Wildfire',
    pet_custom_skin_ox13_name: 'Appa',
    pet_custom_skin_tiger01_name: 'Classic',
    pet_custom_skin_tiger02_name: 'White Tiger',
    pet_custom_skin_tiger03_name: 'Savanna',
    pet_custom_skin_tiger04_name: 'Lilac',
    pet_custom_skin_tiger05_name: 'Venom',
    pet_custom_skin_tiger06_name: 'Shoujo',
    pet_custom_skin_tiger07_name: 'Volcanic',
    pet_custom_skin_tiger08_name: 'Astral',
    pet_custom_skin_tiger09_name: 'Windwalker',
    pet_custom_skin_tiger10_name: 'Bengal',
    pet_custom_skin_tiger11_name: 'Lunar',
    pet_custom_skin_tiger12_name: 'Living Statue',
    pet_custom_skin_atlab_momo01_name: 'Momo',
    furniture_type_pethouse: 'Pet Dwelling',
    catbed_name: 'Cat Bed',
    doghouse_name: 'Dog House',
    unicornroundpen_name: 'Unicorn Pen',
    hamsterwheel_name: 'Hamster Wheel',
    chickencoop_name: 'Chicken Coop',
    frogpond_name: 'Frog Pond',
    oxroundpen_name: 'Ox Pasture',
    atlab_momohouse_name: "Momo's House",
    tigerhouse_name: "Tiger's Den",
    pet_custom_itemHead_custom_hat_shopkeeper_p_name: 'Merchant Hat',
    pet_custom_itemHead_custom_hat_bucket_p_name: 'Teenie Bucket',
    pet_custom_itemHead_custom_eyewear_aviatorglasses_p_name:
        'Cool Glasses',
    pet_custom_itemHead_custom_eyewear_stpatrickglasses_p_name:
        'Lucky Glasses',
    pet_custom_itemHead_custom_hat_farmer_p_name: 'Farm Hat',
    pet_custom_itemHead_custom_eyewear_sunglasses_p_name: 'Sunglasses',
    pet_custom_itemHead_custom_eyewear_valentine_p_name: 'Lovely Glasses',
    pet_custom_itemHead_custom_hat_laurels_p_name: 'King of the Jungle',
    pet_custom_itemHead_custom_hat_christmas_p_name:
        "Santa's Little Helper",
    pet_custom_itemHead_custom_hat_bow_p_name: 'Cute Bow',
    pet_custom_itemHead_custom_hat_flower_p_name: 'Tropical Beauty',
    pet_custom_itemHead_custom_eyewear_monocles_p_name: 'Fancy Monocle',
    pet_custom_itemHead_custom_hat_halo_p_name: 'Halo',
    pet_custom_itemHead_custom_hat_king_p_name: 'Crown of Best Boy',
    wiccanbroom_name: 'Enchanted Broom',
    wd_wiccan_name: 'Pagan Ward',
    vikingboat_name: 'Viking Longship',
    aegirrug_name: 'Thor Carpet',
    decoration_floor_aegir01_name: 'Aegir Tiles',
    decoration_wall_aegir01_name: 'Aegir Wall',
    decoration_beam_beam08_lcog_name: 'Lost City of Gold',
    decoration_wall_wiccan01_name: 'Wiccan Moon',
    decoration_wall_wiccan02_name: 'Wiccan Nights',
    decoration_floor_wiccan01_name: 'Wiccan Meadows',
    forest_name: 'Howling Woods',
    forest_name_desc:
        'A common destination for novice adventurers wishing to prove their worth.',
    forest_boss_name: 'Troublin Chief',
    forest_boss_area_name: 'Troublin Lair',
    fight_forest_boss: 'Fight the Troublin Chief!',
    forest_boss_story:
        'Since Troublins are dumb and mean, it is only natural for their leader to be the meanest and dumbest.',
    notification_boss_forest_respawn_complete_title: 'Howling Woods Boss',
    notification_boss_forest_respawn_complete_body:
        'The Troublin Boss has returned!',
    grotto_name: 'Aurora Caves',
    grotto_name_desc:
        'The starry sky reflects beautifully upon the frozen walls of this cold cavern.',
    grotto_boss_name: 'Yeti King',
    grotto_boss_area_name: 'Yeti King Throne',
    fight_grotto_boss: 'Fight the Yeti King!',
    grotto_boss_story:
        'Whichever Yeti claims the biggest icicle becomes their king. It also doubles as an effective and eco-friendly weapon!',
    notification_boss_grotto_respawn_complete_title: 'Aurora Caves Boss',
    notification_boss_grotto_respawn_complete_body:
        'The Yeti King has returned!',
    swamp_name: 'Whispering Bog',
    swamp_name_desc: 'Damp. Dirty, Smells like rot. Perfect for a quest!',
    swamp_boss_name: 'Toadwitch',
    swamp_boss_area_name: 'Cursed Witch Hut',
    fight_swamp_boss: 'Fight the Toadwitch!',
    swamp_boss_story:
        "A once human witch turned batracian following a failed experiment. She's so mad about it, she curses trespassers with a similar fate.",
    notification_boss_swamp_respawn_complete_title: 'Whispering Bog Boss',
    notification_boss_swamp_respawn_complete_body:
        'The Toadwitch has returned!',
    desert_name: 'Barren Wastes',
    desert_name_desc:
        'Monsters fiercely defend this vast territory of...  nothing at all.',
    desert_boss_name: 'Mushgoon',
    desert_boss_area_name: 'Mushgoon Arena',
    fight_desert_boss: 'Fight the Mushgoon!',
    desert_boss_story:
        'The Mushgoon, five times heavyweight champion of the Barren Wastes, accepts your challenge!',
    notification_boss_desert_respawn_complete_title:
        'Barren Wasteland Boss',
    notification_boss_desert_respawn_complete_body:
        'The Mushgoon has returned!',
    pyramid_name: "Sun God's Tomb",
    pyramid_name_desc:
        'An ancient monument erected in the name of an ancient god. Full of traps and hazards.',
    pyramid_boss_name: 'Greater Anubis',
    pyramid_boss_area_name: "Sun God's Guardian",
    fight_pyramid_boss: 'Fight the Greater Anubis!',
    pyramid_boss_story:
        'Those who plunder the wrong tomb may earn the honor of meeting its greatest, least accommodating guardian.',
    notification_boss_pyramid_respawn_complete_title: "Sun God's Tomb Boss",
    notification_boss_pyramid_respawn_complete_body:
        'The Greater Anubis has returned!',
    ruins_name: 'Chronos Ruins',
    ruins_name_desc:
        'The mysterious ruins of a great empire that vanished overnight. Experiments with time magic is the leading hypothesis behind its tragedy.',
    ruins_boss_name: 'Cyclops Lord',
    ruins_boss_area_name: 'Hall of Kings',
    fight_ruins_boss: 'Fight the Cyclops Lord!',
    ruins_boss_story:
        'The Cyclops Lord trots and clops within the Chronos Ruins, seeking worthy adventurers to challenge.',
    notification_boss_ruins_respawn_complete_title: 'Chronos Ruins Boss',
    notification_boss_ruins_respawn_complete_body:
        'The Cyclops Lord has returned!',
    castle_name: 'Haunted Castle',
    castle_name_desc:
        'Ghostly occupants still manage and maintain this fallen castle, once renowned throughout the lands.',
    castle_boss_name: 'Tyrant King',
    castle_boss_area_name: 'Forsaken Throne',
    fight_castle_boss: 'Fight the Tyrant King!',
    castle_boss_story:
        "Who dares challenge the Tyrant King's absolute authority?",
    notification_boss_castle_respawn_complete_title: 'Haunted Castle Boss',
    notification_boss_castle_respawn_complete_body:
        'The Tyrant King has returned!',
    temple_name: 'Sunken Temple',
    temple_name_desc:
        'This is definitely a place of worship... but for what?',
    temple_boss_name: 'Mermaid Queen',
    temple_boss_area_name: 'Deep Nursery',
    fight_temple_boss: 'Fight the Mermaid Queen!',
    temple_boss_story: 'The Queen watches over her nursery fiercely.',
    notification_boss_temple_respawn_complete_title: 'Sunken Temple Boss',
    notification_boss_temple_respawn_complete_body:
        'The Mermaid Queen has returned!',
    goldcity_name: 'Lost City of Gold',
    goldcity_boss_name: 'Keybearer',
    fight_goldcity_boss: 'Fight the Keybearer!',
    goldcity_boss_story:
        'Legends tell the tale of the Keybearer, a golden golem wandering the halls of the Lost City of Gold...',
    peak_name: 'Bleakspire Peak',
    peak_name_desc:
        'Atop this rocky and sinister mountain, feathered demons hold dominion.',
    peak_boss_name: 'Harpy Matriarch ',
    peak_boss_area_name: 'Bleakspire Nest',
    fight_peak_boss: 'Fight the Harpy Matriarch!',
    peak_boss_story:
        'The Matriarch commands the other Harpies into servitude, mostly through louder screeching.',
    notification_boss_peak_respawn_complete_title: 'Bleakspire Peak',
    notification_boss_peak_respawn_complete_body:
        'The Harpy Matriarch has returned!',
    volcano_name: '',
    volcano_name_desc: '',
    volcano_boss_name: 'Elder Crushtacean',
    volcano_boss_area_name: 'Mountain Core',
    fight_volcano_boss: 'Fight the Elder Crushtacean!',
    volcano_boss_story: '',
    notification_boss_volcano_respawn_complete_title: '',
    notification_boss_volcano_respawn_complete_body:
        'The Elder Crushtacean has returned!',
    tower_titan_name: 'Tower Of Titans',
    quest_lenght_format: 'Quest Length: {0}',
    quest_boss_respawn_format: '{0} Boss will be available in {1}',
    new_accessible_quest: 'New Quest Location: {0}',
    new_quest_unlocked: 'New Quest Unlocked',
    skill_rarity_bronze: 'Common',
    skill_rarity_silver: 'Rare',
    skill_rarity_gold: 'Epic',
    elvenwood_area_name: 'Elvenwood Glade',
    redpinecone_area_name: 'Iron Pine Grove',
    glowingmushrooms_area_name: 'Iridescent Caves',
    arcanedust_area_name: 'Glittering Lake',
    webbedwings_area_name: 'Bat Colony',
    preciousgem_area_name: 'Abandoned Mine',
    livingroots_area_name: 'Wild Gardens',
    darkscale_area_name: 'Rustwyrm Territories',
    icypearl_area_name: 'Deep Bog',
    whitesand_area_name: 'Dried Lake',
    sharpfang_area_name: 'Hunting Grounds',
    mooncrystal_area_name: 'Moonlit Plains',
    evileye_area_name: 'Hall of Sarcophagi',
    silkscarab_area_name: 'Treasure Room',
    starmetal_area_name: 'Enshrined Meteorite',
    ancientmarble_area_name: 'Ruined Courtyard',
    overgrownvine_area_name: 'Overgrown Gardens',
    timecrystal_area_name: 'Well of Time',
    ectoplasm_area_name: 'Haunted Halls',
    sewingthread_area_name: 'Tallest Tower',
    ghostflag_area_name: 'Throne Room',
    deepcoral_area_name: 'Murky Depths',
    sirenshard_area_name: 'Echo Chamber',
    goldshell_area_name: 'Temple Vault',
    grimtalon_area_name: 'Dire Mountain Pass',
    crystalegg_area_name: "Harpie's Nest",
    icybreeze_area_name: 'Bleakspire Summit',
    crabclaw_area_name: 'Molting Grounds',
    obsidian_area_name: 'Obsidian Vents',
    magmacore_area_name: 'Magma Tunnels',
    miniboss_agile: 'Agile',
    miniboss_huge: 'Huge',
    miniboss_dire: 'Dire',
    miniboss_wealthy: 'Wealthy',
    miniboss_epic: 'Legendary',
    Prefix: 'Super',
    Prefix_02: 'Slappy',
    Prefix_03: 'Mega',
    Prefix_04: 'Ultra',
    Prefix_05: 'Extreme',
    Prefix_06: 'Toxic',
    Prefix_07: 'Legendary',
    Prefix_08: 'Murder',
    Prefix_09: 'Demonic',
    Prefix_10: 'Devilish',
    Prefix_11: 'Unchained',
    Prefix_12: 'Skull',
    Prefix_13: 'Scheming',
    Prefix_14: 'Swift',
    Prefix_15: 'Huge',
    Prefix_16: 'Big',
    Prefix_17: 'Tiny',
    Prefix_18: 'Fancy',
    Prefix_19: 'Big Smart',
    Prefix_20: 'Dagger',
    Prefix_21: 'Spider',
    Prefix_22: 'Dread',
    Prefix_23: 'Evil',
    Prefix_24: 'Screeching',
    Prefix_25: 'Da Best',
    Prefix_26: 'Terror',
    Prefix_27: 'Sneaky',
    Prefix_28: 'Blood',
    Prefix_29: 'Chaos',
    Prefix_30: 'Abyss',
    Prefix_31: 'All-seeing',
    Prefix_32: 'Ominous',
    Prefix_33: 'Dark',
    Prefix_34: 'Beatbox',
    Prefix_35: 'Diamond',
    Prefix_36: 'Stone',
    Prefix_37: 'Shining',
    Prefix_38: 'Mangy',
    Prefix_39: 'Plague',
    Prefix_40: 'Gluten-free',
    Prefix_41: 'Haunted',
    Prefix_42: 'Eternal',
    Prefix_43: 'Hateful',
    Prefix_44: 'Spiteful',
    Prefix_45: 'Astral',
    Prefix_46: 'Stygian',
    Prefix_47: 'Forgotten',
    Prefix_48: 'Sad',
    Prefix_49: 'Top',
    Prefix_50: 'Toasty',
    Prefix_51: 'Cold',
    Prefix_52: 'Rad',
    Prefix_53: 'Bombtastic',
    Prefix_54: 'Krangled',
    Prefix_55: 'Mountain',
    Prefix_56: 'Rat',
    Prefix_57: 'The One and Only',
    Suffix: 'Princess',
    Suffix_02: 'Prince',
    Suffix_03: 'Lord',
    Suffix_04: 'Overlord',
    Suffix_05: 'King',
    Suffix_06: 'Queen',
    Suffix_07: 'Minion',
    Suffix_08: 'Baddy',
    Suffix_09: 'Meanie',
    Suffix_10: 'Demon',
    Suffix_11: 'Boss',
    Suffix_12: 'Big Boss',
    Suffix_13: 'Gangsta',
    Suffix_14: 'Sovereign',
    Suffix_15: 'Duchess',
    Suffix_16: 'Baron',
    Suffix_17: 'Knight',
    Suffix_18: 'Fiend',
    Suffix_19: 'Acolyte',
    Suffix_20: 'Zealot',
    Suffix_21: 'Master',
    Suffix_22: 'Stalker',
    Suffix_23: 'Hunter',
    Suffix_24: 'Lotus',
    Suffix_25: 'Keeper',
    Suffix_26: 'Hoarder',
    Suffix_27: 'Invader',
    Suffix_28: 'Steve',
    Suffix_29: 'Scourge',
    Suffix_30: 'Monster',
    Suffix_31: 'Beast',
    Suffix_32: 'Creeper',
    Suffix_33: 'Animal',
    Suffix_34: 'Abomination',
    Suffix_35: 'Warlock',
    Suffix_36: 'Challenger',
    titantower_floor_desc:
        'The way is lit and the path is clear. You require only the strength to follow it.',
    titantower_floor_desc_02:
        'The tinkling of myriad coins can be heard above. Treasure, perhaps?',
    titantower_floor_desc_03:
        'Maniacal cackling can be heard from the room above...',
    titantower_floor_desc_04:
        'Swift footsteps and weapon blows echo through the door. The inhabitant is training rigorously!',
    titantower_floor_desc_05:
        'Trance-like chanting hums and permeates the air around this place...',
    titantower_floor_desc_06:
        'A tiny voice in your head implores you to turn back...',
    titantower_floor_desc_07:
        'There\'s a sign on the door. It reads "Out to lunch".',
    titantower_floor_desc_08:
        'You carefully check for traps... Nothing. Or so it seems.',
    titantower_floor_desc_09: "It's quiet... Too quiet. ",
    titantower_floor_desc_10:
        'The echoes of a soft lullaby comes from the top of the stairs...',
    titantower_floor_desc_11:
        'Stairs, stairs and more stairs. Is there an end to this tower?',
    titantower_floor_desc_12:
        'The air is damp, but not unpleasant. What could it mean?',
    titantower_floor_desc_13:
        'The next door is covered in mysterious runes. Dare you enter?',
    titantower_floor_desc_14:
        "This door is locked with a very strong padlock. Nothing your Heroes can't handle, but not without making noise...",
    titantower_floor_desc_15:
        'This room is starting to smell like smoke. Better head upstairs!',
    titantower_floor_desc_16:
        'Shadows seem to linger just outside of your field of vision... Remain cautious.',
    titantower_floor_desc_17:
        'A rhythmic "thud" can be heard from above as you ascend to the next floor.',
    titantower_floor_desc_18:
        'Strangely human speech can be heard as you approach the door, though its meaning eludes you.',
    titantower_floor_desc_19:
        'This stairway looks exactly like the previous floor... down to the smallest nicks and scratches!',
    titantower_floor_desc_20:
        'This room appears oddly pristine. Smells pretty nice, too!',
    titantower_floor_desc_21:
        'A stiff breeze whistles all around you. No logical source to be seen...',
    titantower_floor_desc_23:
        'Shivers run down your spine. A powerful opponent must be nearby...',
    titantower_floor_desc_24: 'The smell of... Seawater, here!?',
    hate_fighter_name: 'Hates Fighters',
    hate_fighter_desc: 'More likely to attack Fighters.',
    canopener_name: 'Can Opener',
    canopener_desc:
        'More likely to attack Fighters. Ignores some of their Defense, too.',
    hate_rogue_name: 'Hates Rogues',
    hate_rogue_desc: 'More likely to attack Rogues.',
    ratcatcher_name: 'Rat Catcher',
    ratcatcher_desc:
        'More likely to attack Rogues. Ignores some of their Evasion, too.',
    hate_spellcaster_name: 'Hates Spellcasters',
    hate_spellcaster_desc: 'More likely to attack Spellcasters.',
    magiceater_name: 'Magic Devourer',
    magiceater_desc:
        'More likely to attack Spellcasters. Takes less damage from them, too.',
    magic_resistant_name: 'Magic Resistant',
    magic_resistant_desc: 'Takes less damage from Spellcasters.',
    stoneskinned_name: 'Stone Skinned',
    stoneskinned_desc: 'Takes less damage from Fighters and Rogues.',
    powerful_name: 'Powerful',
    powerful_desc: 'Bonuses to all stats.',
    mythical_name: 'Mythical',
    mythical_desc: 'Great bonuses to all stats.',
    swift_name: 'Swift',
    swift_desc: 'First attack deals double damage.',
    lightningswift_name: 'Lightning Fast',
    lightningswift_desc:
        'First attack deals double damage and is more likely to be a Critical Hit.',
    fortified_name: 'Fortified',
    fortified_desc: 'Less likely to receive Critical Hits.',
    noweakness_name: 'No Weakness',
    noweakness_desc: 'Much less likely to receive Critical Hits.',
    armored_name: 'Armored',
    armored_desc: 'Takes less damage, especially from Critical Hits.',
    heavyarmored_name: 'Armored & Plated',
    heavyarmored_desc:
        'Takes much less damage, especially from Critical Hits.',
    eagleeyed_name: 'Eagle-Eyed',
    eagleeyed_desc: 'Has an easier time hitting evasive targets.',
    sniper_name: 'Sniper',
    sniper_desc: 'Can easily hit evasive targets.',
    slayer_name: 'Slayer',
    slayer_desc: 'Lands Critical Hits more often.',
    bloodthirsty_name: 'Bloodthirsty',
    bloodthirsty_desc: 'Lands Critical Hits much more often.',
    deadly_name: 'Deadly',
    deadly_desc: 'Stronger and slightly more frequent Critical Hits.',
    assassin_name: 'Assassin',
    assassin_desc:
        'Much stronger and slightly more frequent Critical Hits.',
    slippery_name: 'Slippery',
    slippery_desc: 'Has a chance to avoid attacks.',
    elusive_name: 'Elusive',
    elusive_desc: 'Has a good chance to avoid attacks.',
    vigorous_name: 'Vigorous',
    vigorous_desc: 'Has extra HP.',
    supertough_name: 'Super Tough',
    supertough_desc: 'Has tons of HP.',
    large_name: 'Massive',
    large_desc: "More likely to use group attacks. They're stronger, too.",
    absoluteunit_name: 'Absolute Unit',
    absoluteunit_desc:
        "Much more likely to use group attacks. They're much stronger, too.",
    ambusher_name: 'Ambusher',
    ambusher_desc: "Attacks can't miss on the first 3 rounds.",
    steamroller_name: 'Steamroller',
    steamroller_desc: 'Deals progressively more damage every round.',
    destroyer_name: 'Destroyer',
    destroyer_desc: 'More likely to break equipment.',
    skill_rarity_titan: 'Titan skill',
    dragon_name_format: '{0}{1} the {2}{3}',
    dragon_prefix: 'Super',
    dragon_prefix_02: 'Slappy',
    dragon_prefix_03: 'Mega',
    dragon_suffix: 'Princess',
    dragon_suffix_02: 'Prince',
    dragon_suffix_03: 'Lord',
    dragon_color: 'Green',
    dragon_color_02: 'Blue',
    dragon_color_03: 'Red',
    dragon_color_04: 'Silver',
    dragon_color_05: 'Black',
    dragon_family: 'Lesser Dragon',
    dragon_family_02: 'Adult Dragon',
    dragon_family_03: 'Greater Dragon',
    dragon_family_04: 'Elder Dragon',
    dragon_family_05: 'Ancient Dragon',
    cutscene4_01: "Ah, our new merchant! I've been expecting you!",
    cutscene4_02:
        'I am <c1>Owen</c1>, the elder. Let me take you to your new shop.',
    cutscene4_03: "There's a lot to do to get it back in working order!",
    tutorial_counter1_01: 'Here we are!',
    tutorial_counter1_02: 'Hmm... no one has been here for a while...',
    tutorial_counter2_01: "Let's see what's under all this dust.",
    tutorial_counter3_01: '*COUGH* *COUGH* Good work!',
    tutorial_rack1_01: 'This rack could use a good dusting, too.',
    tutorial_rack2_01:
        'Splendid. Now we only need something to craft a weapon with...',
    tutorial_rack2_02:
        "Oh! It looks like your shop caught the eye of the city's blacksmith!",
    tutorial_rack3_01:
        "Good morning everyone! You're the new merchant aren't ya?",
    tutorial_rack3_02:
        "Here's a little welcome gift from me, hot off the forge!",
    tutorial_rack4_01: 'Go on, give it a shot!',
    tutorial_rack5_01: "Splendid. I'll let you handle the rest, Wallace.",
    tutorial_rack5_02: 'Leave it to me!',
    tutorial_bin1_01:
        "This here's the <c1>Iron Bin</c1>. Give it a good dusting!",
    tutorial_slots1_01:
        "I be tha miner 'round here. I'll be sending ye <c1>iron</c1> from time the time.",
    tutorial_slots1_02:
        "How about ye try craftin' somethin' else with that <c1>iron</c1>?",
    tutorial_slots1_03: 'No worries, it be human-size!',
    tutorial_slots3_01:
        'That piece of armor went straight to the <c1>storage</c1>!',
    tutorial_slots3_02:
        "Seems like it won't fit on the <c1>vertical rack</c1>...",
    tutorial_slots3_03:
        'A <c1>mannequin</c1> should do the trick. Try it out!',
    tutorial_slots4_01: "That's better!",
    tutorial_sell1_01: 'Look over there, your first customer!',
    tutorial_sell1_02:
        'I betcha they wanna buy the sword you just crafted!',
    tutorial_levelup1_01:
        "Good job! You're starting to look like a real Shop Titan!",
    tutorial_levelup1_02:
        "Try crafting and selling to <c1>reach level 2</c1>. I'll be watching from here!",
    tutorial_levelup1_03: "Here's a couple of items to get you started.",
    tutorial_tailor1_01:
        'Another satisfied customer! A high merchant level will unlock many things.',
    tutorial_tailor1_02:
        'That being said, we can only craft metal items...',
    tutorial_tailor1_03: 'Say, is that Julia over there?',
    tutorial_tailor2_01: "Good choice, shopkeeper. You won't regret it.",
    tutorial_tailor2_02:
        'Julia! Great timing! Mind helping out our new shopkeeper for a bit?',
    tutorial_tailor2_03:
        'Of course, it would be my pleasure. Are you leaving us, Wallace?',
    tutorial_tailor2_04:
        "Sorry kids. I can't leave the forge unattended too long!",
    tutorial_tailor2_05: 'It has a bit of a... temper! Hah hah!',
    tutorial_tailor2_06: "Oh, Wallace! You're incorrigible!",
    tutorial_leatherbin1_01:
        "Now then, where were we? Oh! Where's your <c1>Leather Bin</c1>?",
    tutorial_leatherbin1_02:
        "Without any <c1>Leather</c1> resources, I won't be able to sew anything...",
    tutorial_leatherbin1_03:
        "I'm sure the <c1>Tanner</c1> won't mind if we borrow one of his.",
    tutorial_leatherbin2_01: 'Lovely. Remember to thank him later!',
    tutorial_craftslot1_01: 'Now then, let me show you what I can do!',
    tutorial_craftslot2_01:
        "What is this, shopkeeper, only one <c1>crafting slot</c1>? That simply won't do!",
    tutorial_craftslot2_02:
        'Allow me to assist you. <c1>Crafting slots</c1> are the lifeblood of any merchant!',
    tutorial_craftslot3_01:
        'Well then, make use of your newly acquired <c1>crafting slot</c1>!',
    tutorial_leave1_01:
        "Well then! It looks like everything is in order. I believe you'll be able to handle it from here.",
    tutorial_leave1_02:
        'Keep an eye out for <c1>special tasks</c1> to accomplish, they can be quite rewarding.',
    tutorial_leave2_01:
        "I'll be going back to my workshop now. Come over for a chat anytime!",
    tutorial_adventurers1_01:
        'Some powerful items will require <c1>special components</c1> found only during <c1>quests</c1>.',
    tutorial_adventurers1_02:
        'You would need an adventuring team of brave <c1>Heroes</c1> for this though...',
    tutorial_adventurers1_03: "Wasn't <c1>Argon</c1> in the neighborhood?",
    story_argon_00_01:
        "Good day, shopkeeper! I'm Argon, Knight-in-training!",
    story_argon_00_02:
        "I'm patrolling the city to help people in need. Is there something I can assist with?",
    story_argon_00_03:
        '<c1>Dungeon components</c1>? Of course! You can count on me!',
    story_argon_00_04:
        'All I need is a teammate to get started. Let me show you to the <c1>Training Hall</c1>!',
    tutorial_hire_adventurers1_01:
        "Welcome to the <c1>Training Hall</c1>! There's all kinds of <c1>Heroes</c1> looking for work here!",
    tutorial_hire_adventurers1_02:
        "How about a <c1>Thief</c1>? They're free to hire!",
    tutorial_forest1_01: "We're ready! Let's go!",
    tutorial_open_chest1_01:
        'Shopkeeper! We found a <c1>Chest</c1> during our quest!',
    tutorial_open_chest1_02:
        'I heard that they contain amazing treasure, but you need a <c1>magic key</c1> to open them...',
    tutorial_open_chest1_03:
        "Do you think some <c1>Gems</c1> would work? Let's try it!",
    tutorial_open_chest2_01:
        'Pretty heavy, huh? Here, use some of my <c1>Gems</c1>.',
    tutorial_open_chest3_01: 'Wow! A <c1>Rare Blueprint</c1>!',
    tutorial_open_chest3_02:
        'Those blueprints are <c1>super valuable</c1>, but you can only find them in chests.',
    tutorial_open_chest3_03:
        "If you don't have the Gems, <c1>dungeon bosses</c1> sometimes carry keys, too.",
    tutorial_open_chest3_04:
        "Well then, here's a few keys! Send me out on a <c1>quest</c1> to find more chests!",
    tutorial_equip1_01:
        'I saw Argon and you open that chest just now. Impressive haul!',
    tutorial_equip1_02:
        "Why don't you try to equip that <c1>legendary item</c1> you just found?",
    tutorial_equip1_03:
        'It is the duty of a Merchant to properly equip their <c1>Heroes</c1>!',
    tutorial_equip2_01: 'Perfect fit! Good luck on your next quest!',
    tutorial_equip3_01:
        'Hold on a moment, that boy Argon looked pretty <c1>tired</c1> after that quest.',
    tutorial_equip3_02:
        "Can't blame him, questing is hard work! Why not help him out with the <c1>Stamina Drink</c1> you found?",
    tutorial_craft_elvenwood1_01:
        'Now that you have the required <c1>Elven Wood</c1>, we can craft that <c1>powerful robe</c1>!',
    tutorial_upgradefurniture1_01: "Hey kid! How's business?",
    tutorial_upgradefurniture1_02:
        "Now that you've built up some experience, it'd be a good idea to <c1>upgrade your furniture</c1>!",
    tutorial_upgradefurniture2_01:
        'You can also ask other fellow players for <c1>help</c1> to speed things up!',
    tutorial_upgradefurniture3_01:
        'Great! A high <c1>Max Energy</c1> is the key to making good deals!',
    tutorial_upgradefurniture3_02:
        "I took care of helping ya this time, but in the future you'll need buddies to do it.",
    tutorial_upgradefurniture3_03:
        'How about joining a <c1>Guild</c1>? Teaming up with <c1>other players</c1> is full of benefits!',
    tutorial_join_city1_01:
        "Investing together, helping each other or just being surrounded by friends... There's no reason not to join a guild!",
    tutorial_join_city1_02:
        "If you ever change your mind, you can always leave later. So don't worry too much about it!",
    tutorial_join_city2_01:
        'It looks like you already have a guild of friends waiting for you!',
    tutorial_join_city3_01: "Now you're in business!",
    tutorial_join_city3_02:
        "But remember that you're joining up with <c1>real players</c1>, so be nice to them! Teamwork makes the dream work!",
    tutorial_deco1_01:
        "Oh my. Shopkeeper, your shop is looking a bit... basic, isn't it? Where are your <c1>decorations</c1>?",
    tutorial_deco1_02:
        'Some green would positively liven up the room. Please rectify this!',
    tutorial_deco2_01:
        "That's better. I'm sure your customers will appreciate it, too!",
    tutorial_trade1_01:
        'Ah, shopkeeper! Did you hear the news? The <c1>Market</c1> is finally open!',
    tutorial_trade2_01:
        'Welcome to the <c1>Market</c1>! Here, you can trade with merchants <c1>all over the world</c1>!',
    tutorial_trade2_02: 'Who knows, you may find an incredible deal!',
    tutorial_expand1_01: "Shopkeeper! It's looking a bit cramped in here!",
    tutorial_expand1_02:
        'High time for a <c1>Shop Expansion</c1>! What do you think?',
    tutorial_expand2_01:
        "It's expensive, but hey! Think of all that space for racks and bins!",
    tutorial_exceptional_craft1_01:
        "Remarkable, isn't it? If you would allow me to work on it further, I could make it greater still.",
    tutorial_exceptional_craft2_01:
        '<c1>High quality items</c1> are much more powerful and valuable. Keep an eye out for them!',
    tutorial_table1_01: "That item won't fit the racks you have right now.",
    tutorial_table1_02: "Let's see if we can't fix that!",
    tutorial_table2_01:
        "There we go. A <c1>table</c1> can fit just about anything Vertical Racks and Mannequins can't.",
    tutorial_shelf1_01:
        'It looks like this item is <c1>too small</c1> for your current racks.',
    tutorial_shelf1_02: 'No worries, I know exactly what you need!',
    tutorial_shelf2_01:
        'There. Small items like this look better on a <c1>shelf</c1>!',
    tutorial_jackslot1_01:
        "Ah, you've earned yourself a <c1>Fortune Ticket</c1>, did you not? Let me show you how to use it.",
    tutorial_jackslot2_01: 'Here we are! Give it a spin!',
    tutorial_jackslot3_01:
        'How lucky! Make sure to visit the store for a prize to buy!',
    tutorial_jackslot3_02:
        '<c1>Daily tasks</c1> will reward you with more <c1>Fortune Tickets</c1>. Keep an eye out for them!',
    tutorial_surcharge1_01:
        'Hello again, shopkeeper. Have you ever tried <c1>Haggling</c1> with customers?',
    tutorial_surcharge2_01:
        'A savvy merchant can make a nice profit that way. Show me your skills!',
    tutorial_discount1_01:
        "Tiring, isn't it? You can also choose to <c1>discount</c1> an item to regain some <c1>Energy</c1>.",
    tutorial_discount2_01:
        'Try giving this lucky customer a <c1>discount</c1>.',
    tutorial_bp_unlock1_01:
        "Ah! Most advanced blueprints will require some research before they're useable.",
    tutorial_bp_unlock1_02:
        'This can be done with the help of <c1>Research Scrolls</c1>!',
    tutorial_bp_unlock1_03:
        'Here, I brought a few for you. Try <c1>researching</c1> it.',
    tutorial_bp_unlock2_01:
        'You should keep the other <c1>Research Scrolls</c1>, they will come in handy!',
    tutorial_explain_counter_title: "Shopkeeper's Counter",
    tutorial_explain_counter_desc: 'The most important part of any shop!',
    tutorial_explain_racks_title: 'Item Racks',
    tutorial_explain_racks_desc:
        'Display wares for customers to buy. Presentation is key!',
    tutorial_explain_bin_title: 'Storage Bins',
    tutorial_explain_bin_desc:
        'Store resources in your shop for later use. A vital component for all items!',
    tutorial_link1_01: 'Still hard at work, shopkeeper?',
    tutorial_link1_02: "I see you've wasted no time expanding!",
    tutorial_link1_03:
        'If you want to safekeep all that progress, you should <c1>connect your account</c1>!',
    tutorial_link2_01:
        "By connecting to your platform, you'll never lose your account, no matter what!",
    tutorial_link2_02:
        "It's as simple as it gets, and it pays to have a backup plan! ",
    tutorial_press_sidemenu: 'Tap the <c1>Menu</c1>.',
    tutorial_press_sidemenu_click: 'Click on the <c1>Menu</c1>.',
    tutorial_press_platform:
        'Tap the <c1>Connect</c1> button to connnect to your platform!',
    tutorial_introduce_quest_event_goldcity1_01:
        'Hard-working bourgeois! It is I, King Reinhold!',
    tutorial_introduce_quest_event_goldcity2_01:
        "A <c1>special offer</c1>? Not this time, I'm afraid. But I do bring exciting news!",
    tutorial_introduce_quest_event_goldcity2_02:
        'The mythical <c1>Lost City of Gold</c1> has appeared once more!',
    tutorial_introduce_quest_event_goldcity2_03:
        'This fabled dungeon overflows with wealth! A golden opportunity for savvy socialites such as I!',
    tutorial_introduce_quest_event_goldcity2_04:
        'Thus, you and your guild simply MUST plunder its riches. Your King demands it!',
    tutorial_introduce_quest_event_goldcity2_05:
        'Bring me the <c1>Pure Gold</c1> found within, and you will be duly rewarded! I am a generous monarch, after all.',
    tutorial_introduce_quest_event_titantower1_01:
        'S-shopkeeper! Big news!',
    tutorial_introduce_quest_event_titantower2_01:
        'Have you heard? A huge tower appeared out of nowhere!',
    tutorial_introduce_quest_event_titantower2_02:
        'They call it the <c1>Tower of Titans</c1>... We gotta explore it!',
    tutorial_introduce_quest_event_titantower2_03:
        "Imagine what might await us at the top... Isn't it exciting?",
    tutorial_introduce_quest_event_titantower2_04: "Come on, let's go!",
    tutorial_introduce_quest_event_king1_01:
        'Roll out the red carpet, for King Reinhold has come!',
    tutorial_introduce_quest_event_king2_01:
        'I am known as a most generous ruler, but today I must ask for a similar, infinite kindness from yourself.',
    tutorial_introduce_quest_event_king2_02:
        'You see, I have woken up this morning with a terrible sickness...',
    tutorial_introduce_quest_event_king2_03:
        '...One which requires me to collect! Acquire! Amass! I must have it all!',
    tutorial_introduce_quest_event_king2_04:
        'Make my armory burst at the seams with incredible crafts, and you will earn yourself my <c1>Royal Favor</c1>!',
    tutorial_introduce_quest_event_king2_05:
        'Now go and craft away! My survival depends on it!',
    tutorial_basement_intro1_01: 'Oi, shopkeeper.',
    tutorial_basement_intro1_02:
        "Yer shop's lookin' mighty crowded. Y'need help with that?",
    tutorial_basement_intro1_03:
        "If ye still got tha dusty old basement in tha back, I'll dig that up for ye.",
    tutorial_basement_intro2_01:
        "Now ye can dump yer bins and racks down there and keep 'em working!",
    tutorial_basement_intro2_02:
        "But don't forget; <c1>it won't let ye own more racks and bins</c1>. Just puts them outta the way.",
    tutorial_basement_intro2_03:
        'Them be rules from tha shopkeeper association. Ye got more space to decorate, though.',
    tutorial_basement_intro3_01:
        "This here oughtha do it. If ye want more diggin' done, I'm yer dwarf.",
    loginevent_lunar_01:
        'How fare your Lunar Year festivities, shopkeeper? Opened any <c1>Red Envelopes</c1>?',
    loginevent_lunar_02:
        "I've received some nice pocket money from mine; 60 trillion gold pieces!",
    loginevent_lunar_03:
        "What's this? You've not received any? Preposterous!",
    loginevent_lunar_04:
        'Very well! You may have some from my personal <c1>Gem</c1> treasury.',
    loginevent_lunar_05:
        'Simply log in every day to receive these most generous gifts!',
    tutorial_enchanting1_01:
        "Ah, shopkeeper. I see you've found a... <c1>Runestone</c1>, was it?",
    tutorial_enchanting1_02:
        'These can be used to craft powerful <c1>enchantments</c1>. Allow me to show you.',
    tutorial_enchanting2_01:
        "Splendid, splendid. You've got yourself a mighty <c1>Elemental Rune</c1> there.",
    tutorial_enchanting2_02:
        'What was the next step again...? Oh yes, enchanting it onto an item!',
    tutorial_enchanting2_03: 'Here, try enchanting this ring.',
    tutorial_enchanting3_01:
        'Wonderful. Enchanted items are much more powerful and valuable.',
    tutorial_enchanting3_02:
        'I heard that Elements can empower Heroes in special ways... but I forgot the rest.',
    tutorial_enchanting3_03:
        'Oh well. Have fun enchanting now. Here are a few Runestones to get you started.',
    tutorial_enchantingA1_01:
        "Shopkeeper! Is that a... <c1>Runestone</c1> you're holding?",
    tutorial_enchantingA1_02:
        'Then my prayers were answered! With it, you can craft powerful <c1>enchantments</c1>!',
    tutorial_enchantingA1_03:
        "With the Goddess' blessing, I shall show you.",
    tutorial_enchantingA2_01:
        "Very good! What a lively <c1>Spirit Rune</c1>! It's just roaring to go!",
    tutorial_enchantingA2_02:
        "Let's not leave it waiting. Here, try enchanting it on this ring.",
    tutorial_enchantingA3_01:
        'Good! Enchanted items are much more powerful and valuable.',
    tutorial_enchantingA3_02:
        "Items with Spirits also grant <c1>special skills</c1> to Heroes, though I don't know the details...",
    tutorial_enchantingA3_03:
        'I bought a few more <c1>Runestones</c1>. I believe the Goddess would like you to have them.',
    tutorial_enchantingA3_04:
        'Blessings upon you and your work, shopkeeper. Goodbye!',
    tutorial_collection_book_sub1_01: 'Shopkeeper! Is anybody home?',
    tutorial_collection_book_sub2_01:
        'I found something really cool I wanted to show you!',
    tutorial_collection_book_sub2_02:
        "...Wait, you haven't <c1>mastered a blueprint</c1> yet?",
    tutorial_collection_book_sub2_03:
        "Oh... then I can't give you this <c1>cool book I found</c1>...",
    tutorial_collection_book_sub2_04:
        'No problem though! You only need to <c1>craft a blueprint over and over</c1> to upgrade and master it!',
    tutorial_collection_book_sub2_05:
        "I'll come back when you're done! Good luck!",
    tutorial_collection_book1_01: "Shopkeeper! It's me again!",
    tutorial_collection_book2_01:
        "I found something really cool in Grimar's attic!",
    tutorial_collection_book2_02:
        "Some kind of magic tome about collecting items... You want it, don't you?",
    tutorial_collection_book2_03: 'I thought so! Here you go!',
    tutorial_collection_book3_01:
        "It's a magical <c1>Collection Book</c1>! Come on, open it!",
    tutorial_collection_book4_01:
        'From what I could gather... a new page appears whenever you <c1>master a blueprint</c1>.',
    tutorial_collection_book4_02:
        "Once a page appears, you can donate copies of that item to add it to the book's collection!",
    tutorial_collection_book4_03:
        "...It seems the book will accept up to <c1>one copy</c1> of each item's quality, from <c1>Normal</c1> to <c1>Legendary</c1>!",
    tutorial_collection_book4_04: 'I brought you an item so we can try!',
    tutorial_collection_book5_01:
        'Now your collection is one step closer to complete!',
    tutorial_collection_book5_02:
        "If you keep at it, there's probably some cool rewards waiting for you! It's a magical book, after all!",
    tutorial_dust_counter: 'Tap the <c1>counter</c1> to dust it off.',
    tutorial_dust_rack: 'Tap the <c1>rack</c1> to dust it off.',
    tutorial_press_crafting: 'Tap on the <c1>Craft</c1> button.',
    tutorial_press_crafting_card: 'Tap on the <c1>crafting card</c1>.',
    tutorial_wait_crafting: 'Wait for the timer, then tap on the card.',
    tutorial_dust_bin: 'Tap the <c1>bin</c1> to remove the dust.',
    tutorial_press_bin_bubble:
        'Tap the <c1>bubble</c1> to collect resources.',
    tutorial_press_sell_bubble:
        'Tap the <c1>bubble</c1> to talk to the customer.',
    tutorial_press_bubble_hero: 'Tap the <c1>bubble</c1> to talk to Argon.',
    tutorial_press_bubble_blacksmith:
        'Tap the <c1>bubble</c1> to talk to Wallace the Blacksmith.',
    tutorial_press_sell_item: 'Tap on the <c1>Sell</c1> button.',
    tutorial_press_city: 'Tap on the <c1>City</c1> button.',
    tutorial_press_buildings: 'Tap on the <c1>Tailor</c1> building.',
    tutorial_press_carpenter: 'Tap on the <c1>Hire</c1> button.',
    tutorial_press_shop: 'Tap on the <c1>Shop</c1> button.',
    tutorial_press_furniture: 'Tap on the <c1>Furniture</c1> button.',
    tutorial_press_hire: 'Tap on the <c1>Hire</c1> button.',
    tutorial_press_weapon: 'Tap on the <c1>Weapon</c1> slot.',
    tutorial_press_hire_adventurer: 'Tap on the <c1>Recruit</c1> button.',
    tutorial_press_bubble_tailor:
        'Tap the <c1>bubble</c1> to talk to Julia the Tailor.',
    tutorial_press_quest: 'Tap on the <c1>Quest</c1> button.',
    tutorial_press_questing_card: 'Tap on the <c1>Elven Wood</c1> button.',
    tutorial_press_hire_board_back:
        'Tap on the <c1>back</c1> button to return to the city.',
    tutorial_press_forest: 'Tap on the <c1>Send Party</c1> button.',
    tutorial_press_quest_slot: 'Tap on an empty <c1>Hero slot</c1>.',
    tutorial_press_adventurer: 'Tap on a <c1>Hero card</c1>.',
    tutorial_press_adventurer_heal: 'Tap on the <c1>Heal</c1> button.',
    tutorial_press_adventurer_hpdrink: 'Tap on the <c1>Stamina Drink</c1>.',
    tutorial_press_start_quest: 'Tap on the <c1>Begin quest</c1> button.',
    tutorial_press_craft_slot: 'Tap on the <c1>+</c1> button.',
    tutorial_purchase_craft_slot: 'Purchase the <c1>Craft Slot</c1>.',
    tutorial_speedup_quest:
        'Quests can take a while... <c1>Rush</c1> it to help us out!',
    tutorial_collect_quest: 'Tap on the <c1>Collect</c1> button.',
    tutorial_press_adventurers: 'Tap on the <c1>Chars</c1> button.',
    tutorial_press_armor: 'Tap on the <c1>armor</c1> button.',
    tutorial_press_back: 'Tap on the <c1>back</c1> button.',
    tutorial_press_rack: 'Tap on the <c1>rack</c1> to see more options.',
    tutorial_press_info: 'Tap on the <c1>info</c1> button.',
    tutorial_press_city_search: 'Tap on the <c1>Guild Finder</c1> button.',
    tutorial_show_furniture_slot_types:
        'These are the types of items that can be placed on the rack.',
    tutorial_press_vert_rack: 'Tap on the <c1>Vertical Rack</c1>.',
    tutorial_press_generic: 'Tap on the <c1>{0}</c1>.',
    tutorial_press_upgrade: 'Tap on the <c1>Upgrade</c1> button.',
    tutorial_press_argon: 'Tap on <c1>Argon</c1>.',
    tutorial_press_edit: 'Tap on the <c1>Design</c1> button.',
    tutorial_press_trade: 'Tap on the <c1>Market</c1> button.',
    tutorial_press_plant: 'Tap on the <c1>Plant</c1>.',
    tutorial_press_chest: 'Tap on the <c1>Chest</c1> button.',
    tutorial_press_expand: 'Tap on the <c1>Expand</c1> button.',
    tutorial_press_open_chest:
        "Tap on the <c1>Magic Unlock</c1> button. This one's on me!",
    tutorial_press_city_management: 'Tap on the <c1>Guild</c1> button.',
    tutorial_press_hire_argon: 'Tap on the <c1>Gold</c1> button.',
    tutorial_press_jackslot: 'Tap on the <c1>Tasks</c1> button.',
    tutorial_press_spin: 'Tap on the <c1>Spin</c1> button.',
    tutorial_press_objective: 'Tap on the <c1>Tasks</c1> button.',
    tutorial_press_surcharge: 'Tap on the <c1>Surcharge</c1> button.',
    tutorial_press_discount: 'Tap on the <c1>Discount</c1> button.',
    tutorial_press_help: 'Tap on the <c1>Help</c1> bubble.',
    tutorial_press_bubble_king: 'You may now tap my <c1>bubble</c1>.',
    tutorial_press_store: 'Tap the <c1>Store</c1> button.',
    tutorial_press_crafting_unlock: 'Tap on the <c1>blueprint card</c1>.',
    tutorial_press_unlock_blueprint:
        'Tap the <c1>Confirm</c1> button to unlock the Blueprint.',
    tutorial_press_forest_location: 'Tap the <c1>Howling Woods</c1>.',
    tutorial_speedup_craft: 'Tap on the <c1>craft bubble</c1> to rush it.',
    tutorial_ascension1_01:
        "Well look at that! You've found yourself some <c1>Ascension Shards</c1>!",
    tutorial_ascension1_02:
        "They're just about the coolest thing around! Aside from me, of course!",
    tutorial_ascension1_03: 'Here, let me show you how to use one!',
    tutorial_ascension2_01:
        "Here you go! That Short Sword blueprint's looking mighty efficient now!",
    tutorial_ascension2_02:
        '<c1>Ascension Shards</c1> allow you to upgrade your favorite blueprints even more than usual.',
    tutorial_ascension2_03:
        'That upgrade was on me, but you should try it on your favorite blueprint, too!',
    tutorial_ascension2_04:
        "I'm looking forward to seeing what you come up with!",
    tutorial_press_ascension_tab: 'Tap on the <c1>Ascension</c1> tab.',
    tutorial_press_ascend: 'Tap on the <c1>Ascend</c1> button.',
    tutorial_press_inventory: 'Tap on the <c1>inventory</c1> button.',
    tutorial_press_fatring: 'Tap on the <c1>Ring</c1>.',
    tutorial_press_bubble_generic:
        'Tap the <c1>bubble</c1> to talk to {0}.',
    tutorial_press_bubble_engineer:
        'Tap the <c1>bubble</c1> to talk to the Engineer.',
    tutorial_press_bubble_scholar:
        'Tap the <c1>bubble</c1> to talk to the Scholar.',
    press_bubble_moondragon: 'Tap the <c1>bubble</c1> to talk to Mundra.',
    press_bubble_moondragon_click:
        'Click the <c1>bubble</c1> to talk to Mundra.',
    tutorial_press_book_entry: "Tap the <c1>item's entry</c1>",
    tutorial_press_book_entry_click: "Click on the <c1>item's entry</c1>",
    tutorial_press_donate: 'Tap the <c1>donate</c1> button',
    tutorial_press_donate_click: 'Click on the <c1> donate</c1> button',
    tutorial_press_inventory_item: 'Tap the <c1>item</c1> to donate',
    tutorial_press_inventory_item_click:
        'Click on the <c1>item</c1> to donate',
    tutorial_press_book: 'Tap the <c1>Collection Book</c1>',
    tutorial_press_book_click: 'Click on the <c1>Collection Book</c1>',
    craft_idle_reminder_01:
        'Your workers are idle. Why not <c1>craft</c1> something?',
    sell_idle_reminder_01:
        "That <c1>customer</c1> has been waiting at the counter for a while... It's rude to keep them waiting!",
    inventory_idle_reminder_01:
        "It looks like your inventory is full! If you're running out of space, you can try <c1>dismantling</c1> items.",
    tutorial_goal_level_2: 'Craft and sell items to reach <c1>Level 2</c1>',
    objectives_01_title: 'Setting the Table',
    objectives_02_title: 'Adding a Little Green',
    objectives_03_title: 'Merchant Manners',
    objectives_04_title: 'A Better Bin',
    objectives_05_title: 'Sticks and Stones',
    objectives_06_title: 'Branching Out',
    objectives_07_title: 'Forest Explorers',
    objectives_08_title: 'Making Space',
    objectives_09_title: 'Traditional Medicine',
    objective_questsend_party: 'Send a party of 3 to the Howling Woods',
    objective_questreachfloor: 'Clear floor {0} of the Howling Woods',
    equip_adventure_count: 'Equip a Hero with 3 total pieces of gear.',
    dialog_thankyou: 'Thank You!',
    dialog_hello: 'Hello!',
    dialog_amazing: 'Amazing!',
    dialog_hmmm: 'Hmmm...',
    dialog_really: 'Really?!',
    dialog_perhaps: 'Perhaps',
    visitor_thought_counterbusy: "The counter's crowded!",
    visitor_thought_counterbusy_02: 'Shopkeeper looks busy...',
    visitor_thought_counterbusy_03: "There's a line at the counter...",
    visitor_thought_nopathtocounter: "The counter's blocked...",
    visitor_thought_nopathtocounter_02: "I can't make it to the counter!",
    visitor_thought_nopathtocounter_03: "Can't reach the counter...",
    visitor_thought_itemclassmismatch: "I can't use this...",
    visitor_thought_itemclassmismatch_02: "I don't know how to use that",
    visitor_thought_itemclassmismatch_03: 'Not really my style',
    visitor_thought_itemleveltoolow: 'This is too weak...',
    visitor_thought_itemleveltoolow_02: 'This is below me',
    visitor_thought_itemleveltoolow_03: "I'd like something stronger...",
    visitor_thought_itemleveltoohigh: "I'm not strong enough!",
    visitor_thought_itemleveltoohigh_02: 'Too fancy for me!',
    visitor_thought_itemleveltoohigh_03: "I can't afford that!",
    visitor_thought_nocounter: "Where's the counter?",
    visitor_thought_nocounter_02: "I couldn't find the counter...",
    visitor_thought_nocounter_03: 'Where do I pay?',
    visitor_thought_noitems: 'Rack is empty!',
    visitor_thought_noitems_02: "There's nothing here...",
    visitor_thought_noitems_03: 'Is it out of stock?',
    visitor_thought_decoclassmismatch: 'Meh...',
    visitor_thought_decoclassmismatch_02: 'Not interested',
    visitor_thought_decoclassmismatch_03: 'Boring',
    visitor_thought_noshopkeeperatcounter: "Where's the shopkeeper?",
    visitor_thought_noshopkeeperatcounter_02: "Who's in charge here?",
    visitor_thought_noshopkeeperatcounter_03:
        "Couldn't find the shopkeeper...",
    visitor_thought_shoptoocrowded: "It's too crowded here!",
    visitor_thought_shoptoocrowded_02: 'Too many customers...',
    visitor_thought_shoptoocrowded_03: 'Too many people!',
    visitor_thought_didntfindanything: "I didn't find anything...",
    visitor_thought_didntfindanything_02: "There's nothing for me here",
    visitor_thought_didntfindanything_03:
        "Didn't find anything interesting...",
    visitor_thought_foundinterestingitem: 'Perfect!',
    visitor_thought_foundinterestingitem_02: 'Just what I needed!',
    visitor_thought_foundinterestingitem_03: 'This will do',
    visitor_thought_inspectdecorationpositive: 'Beautiful!',
    visitor_thought_inspectdecorationpositive_02: 'I like it!',
    visitor_thought_inspectdecorationpositive_03: 'Wow!',
    visitor_thought_yield: 'Oops!',
    visitor_thought_yield_02: 'Sorry!',
    visitor_thought_dustfurniture: 'That should do it.',
    visitor_thought_dustfurniture_02: 'Clean as a whistle.',
    visitor_thought_dustfurniture_03: 'Looks good!',
    visitor_thought_dustfurniture_04: 'When was this last dusted!?',
    visitor_thought_dustfurniture_05: '*Cough* *cough*',
    visitor_thought_petinteraction: "You're so cute!",
    visitor_thought_petinteraction_02: 'Hello there!',
    visitor_thought_petinteraction_03: 'Look at this little guy!',
    visitor_thought_petinteraction_04: 'Cute!',
    visitor_thought_petinteraction_05: "What's your name, buddy?",
    visitor_thought_petinteraction_halloween: 'Zombie... pet...',
    visitor_thought_petinteraction_halloween_02: 'Brain...?',
    visitor_thought_petinteraction_dog: 'What a nice dog!',
    visitor_thought_petinteraction_dog_02: 'Doggy!',
    visitor_thought_petinteraction_dog_03: "C'mere doggy!",
    visitor_thought_petinteraction_dog_04: 'Shake! Good dog!',
    visitor_thought_petinteraction_dog_halloween: 'Good... boy...',
    visitor_thought_petinteraction_cat: 'Must pet the fluffball...',
    visitor_thought_petinteraction_cat_02: 'Kitty cat!',
    visitor_thought_petinteraction_cat_halloween: 'Pet... tummy...',
    visitor_thought_petinteraction_chicken: 'A chicken!',
    visitor_thought_petinteraction_chicken_02: 'Looks delicious...',
    visitor_thought_petinteraction_chicken_halloween: '...Birdbrain...',
    visitor_thought_petinteraction_hamster: 'Woah! Tiny!',
    visitor_thought_petinteraction_hamster_02: 'A hamster! How cute!',
    visitor_thought_petinteraction_hamster_halloween:
        '...Brain... small...',
    visitor_thought_petinteraction_unicorn: 'Woah! A unicorn!',
    visitor_thought_petinteraction_unicorn_02: 'Is it a baby?',
    visitor_thought_petinteraction_unicorn_halloween: '...Argh... Holy...!',
    visitor_thought_petinteraction_frog: 'Froggy!',
    visitor_thought_petinteraction_frog_02: '...Is it safe to touch?',
    visitor_thought_petinteraction_frog_halloween: '...Slimy...',
    visitor_thought_petinteraction_ox: 'So strong!',
    visitor_thought_petinteraction_ox_02: 'Oh! Good fortune!',
    visitor_thought_petinteraction_ox_halloween: '...Cuddly...',
    visitor_thought_petinteraction_atlab_momo: 'A Winged Lemur!?',
    visitor_thought_petinteraction_atlab_momo_02: 'Hi Momo!',
    visitor_thought_petinteraction_atlab_momo_halloween: '...Momo...?',
    visitor_thought_petinteraction_tiger: 'A real tiger!?',
    visitor_thought_petinteraction_tiger_02: 'Tiny tiger!',
    visitor_thought_petinteraction_tiger_halloween: 'Pet... tummy...',
    dialog_thankyou_halloween: '...Thanks...',
    dialog_hello_halloween: '...Brains...?',
    dialog_amazing_halloween: '...Brains..!',
    dialog_hmmm_halloween: '...',
    dialog_really_halloween: '...Argh...',
    dialog_perhaps_halloween: 'Brains...?',
    visitor_thought_counterbusy_halloween: '...Too many zombies...',
    visitor_thought_counterbusy_halloween_02: '....Brain... busy...',
    visitor_thought_nopathtocounter_halloween: "Can't reach... brains...",
    visitor_thought_itemclassmismatch_halloween: "...Can't... use...",
    visitor_thought_itemleveltoolow_halloween: 'Not... brains...',
    visitor_thought_itemleveltoohigh_halloween: '...Too strong...',
    visitor_thought_nocounter_halloween: '...Counter...?',
    visitor_thought_nocounter_halloween_02: 'Counter... Where..?',
    visitor_thought_noitems_halloween: '...No brains...',
    visitor_thought_decoclassmismatch_halloween: '...No...',
    visitor_thought_noshopkeeperatcounter_halloween: 'Brains...where...?',
    visitor_thought_shoptoocrowded_halloween: '...Too many zombies...',
    visitor_thought_didntfindanything_halloween: '...No brains...',
    visitor_thought_didntfindanything_halloween_02: '...Argh...',
    visitor_thought_foundinterestingitem_halloween: 'Yes... brains...',
    visitor_thought_foundinterestingitem_halloween_02: 'Brains...',
    visitor_thought_foundinterestingitem_halloween_03: '...Buy...',
    visitor_thought_inspectdecorationpositive_halloween: '...<3...',
    visitor_thought_inspectdecorationpositive_halloween_02: '...Me like...',
    visitor_thought_inspectdecorationpositive_halloween_03: '...Yes...',
    visitor_thought_yield_halloween: '...Zombie... move...',
    visitor_thought_yield_halloween_02: '...Sorry...',
    dialog_thankyou_aprilfools: 'Thanks, dude.',
    dialog_hello_aprilfools: "What's up, dude.",
    dialog_amazing_aprilfools: "That's crazy, dude.",
    dialog_hmmm_aprilfools: "I'm not feeling it, dude.",
    dialog_really_aprilfools: "Dude, that's extortion!",
    dialog_perhaps_aprilfools: 'Dude, I just might.',
    visitor_thought_counterbusy_aprilfools: "The counter's packed, dude.",
    visitor_thought_nopathtocounter_aprilfools:
        "Can't find the counter, dude.",
    visitor_thought_itemclassmismatch_aprilfools: "I can't use that, dude.",
    visitor_thought_itemleveltoolow_aprilfools: "That's too cheap, dude.",
    visitor_thought_itemleveltoohigh_aprilfools:
        "Dude, I can't afford that.",
    visitor_thought_nocounter_aprilfools: "Dude, where's the counter?",
    visitor_thought_noitems_aprilfools: "Dude, there's nothing here!",
    visitor_thought_decoclassmismatch_aprilfools:
        "I'm not feeling it, dude.",
    visitor_thought_noshopkeeperatcounter_aprilfools:
        "Dude...where's the shopkeeper?",
    visitor_thought_shoptoocrowded_aprilfools: 'Too many Tamases, dude.',
    visitor_thought_didntfindanything_aprilfools: "I'm out, dude.",
    visitor_thought_didntfindanything_aprilfools_02:
        "Alright I'ma head out.",
    visitor_thought_foundinterestingitem_aprilfools:
        "Now that's good, dude.",
    visitor_thought_inspectdecorationpositive_aprilfools:
        "I'm lovin' it, dude.",
    visitor_thought_yield_aprilfools: 'Sorry, dude.',
    dialog_blacksmith_greetings:
        "Welcome to the smithy. Don't mind the heat!",
    dialog_blacksmith_greetings_02: 'Nice day for some smithing, eh?',
    dialog_blacksmith_investgold: 'Thanks! Every little bit helps!',
    dialog_blacksmith_investgold_02: "You won't regret it!",
    dialog_blacksmith_investgems: "Wow! You're serious about this!",
    dialog_blacksmith_investgems_02: "That's the spirit!",
    dialog_blacksmith_underupgrade:
        'Sorry, no visitors allowed during renovations. City regulations!',
    dialog_blacksmith_underupgrade_02:
        'If I tore down that wall, I could fit another anvil...',
    dialog_blacksmith_afterupgrade:
        "The bellows are back working full blast! Let's craft something!",
    dialog_blacksmith_afterupgrade_02:
        "I'm done hammering up the place. You should see my new anvil!",
    visit_blacksmith_quality_00_01:
        "Look at this craft, shopkeeper. It's so pretty, it makes me want to give it a name!",
    visit_blacksmith_quality_00_02:
        'But then my kids would get jealous! Hah hah hah!',
    visit_blacksmith_bulk_00_01:
        "My hammer's on fire today! And so is my forge! Hah hah!",
    visit_blacksmith_bulk_00_02:
        'Look at all the items I made! Go ahead, you can have some!',
    thanks_blacksmith_00_01:
        "I'm heading back to the smithy, keep up the good work!",
    visit_blacksmith_quality_christmas_00_01:
        'Happy holidays, shopkeeper! Celebrating all alone?',
    visit_blacksmith_quality_christmas_00_02:
        "If you don't have any family visiting, you can come over and celebrate at the Wallace household!",
    visit_blacksmith_bulk_christmas_00_01:
        'Happy holidays, shopkeeper! Celebrating all alone?',
    visit_blacksmith_bulk_christmas_00_02:
        "If you don't have any family visiting, you can come over and celebrate at the Wallace household!",
    thanks_blacksmith_christmas_00_01:
        "Here's a little gift for you. Don't forget my offer!",
    dialog_blacksmith_chat:
        "Allan's a good boy. He's got a bright future ahead of him!",
    dialog_blacksmith_chat_02:
        "Think Durhan is grumpy? I'd say he's pretty merry by dwarven standards! Hah!",
    dialog_blacksmith_chat_03:
        "I remember forging Argon's first sword like it was yesterday. That kid just kept breaking his toys!",
    dialog_blacksmith_chat_04:
        "Used to spend nights hammering away here at the forge, but I'm a family man now. No regrets!",
    dialog_blacksmith_chat_05:
        "If you can get my old man Theodore out of retirement, you wouldn't regret it. That guy can forge anything!",
    dialog_blacksmith_chat_06:
        "Brohm's an upstanding guy, but he's a bit awkward around people. Give him a chance!",
    dialog_blacksmith_chat_07:
        'Gorza breaks several axes per day... Just how hard does she swing those things?',
    dialog_blacksmith_chat_08:
        'Why is iron always so mad? Because it has a low boiling point! Hah hah!',
    dialog_blacksmith_chat_09:
        'Why did the scarecrow win an award? Because he was outstanding in his field!',
    dialog_blacksmith_chat_10:
        'A joke about construction? ...It has a long buildup!',
    dialog_blacksmith_chat_11:
        "I'm a big fan of the shovel. I'd say it was a groundbreaking invention.",
    dialog_blacksmith_chat_12:
        "I would avoid sushi if I were you. It's a little fishy.",
    dialog_blacksmith_chat_13:
        'A high defense can help reduce damage taken during quests. Fighters will need tons of it!',
    dialog_blacksmith_chat_14:
        "How's your pet doing, shopkeeper? Ours is doing great!",
    dialog_blacksmith_bounty_craft: 'Are you crafty enough, shopkeeper?',
    dialog_blacksmith_bounty_slay: 'You could say I have an axe to grind!',
    dialog_blacksmith_bounty_quest:
        'Keep your composure while collecting these components!',
    dialog_blacksmith_bounty_surcharge:
        'I leave you in charge of this surcharge!',
    dialog_blacksmith_bounty_discount:
        'I would never dare discount your haggling skills!',
    dialog_carpenter_greetings: 'Carpenter Allan; the best there is.',
    dialog_carpenter_greetings_02: 'Looking for a masterpiece?',
    dialog_carpenter_unhiredgreetings:
        "If you're looking for the best, you've come to the right place.",
    dialog_carpenter_unhiredgreetings_02:
        'I, Allan, can craft just about anything made of wood. Bows, staves, shields, you name it.',
    dialog_carpenter_hirequestion: 'So, what are you waiting for?',
    dialog_carpenter_investgold: 'Naturally.',
    dialog_carpenter_investgold_02: "I'm worth it, trust me.",
    dialog_carpenter_investgems: 'Good choice.',
    dialog_carpenter_investgems_02: "You're a smart one, shopkeeper.",
    dialog_carpenter_underupgrade:
        "Thanks for stopping by, but I'm a bit busy with renovations at the moment.",
    dialog_carpenter_underupgrade_02:
        'Part B goes into slot C...? Why is the manual in Swedish!?',
    dialog_carpenter_afterupgrade:
        "I'm done renovating my workshop. Pretty good huh? Carpenter quality!",
    visit_carpenter_quality_00_01:
        "You need to see this. Get ready though, it's THAT good. Even for me.",
    visit_carpenter_bulk_00_01:
        'I got a bunch of fine crafts here for you. All certified Allan quality, of course.',
    thanks_carpenter_00_01: 'Of course. None can resist my craftsmanship!',
    visit_carpenter_quality_christmas_00_01:
        "Shopkeeper! I brought the Christmas gift you've no doubt been waiting for!",
    visit_carpenter_quality_christmas_00_02: "Can't wait to open it huh?",
    visit_carpenter_quality_christmas_00_03:
        "I understand! You can do it right away! I won't tell anyone.",
    visit_carpenter_bulk_christmas_00_01:
        "Shopkeeper! I brought the Christmas gift you've no doubt been waiting for!",
    visit_carpenter_bulk_christmas_00_02: "Can't wait to open it huh?",
    visit_carpenter_bulk_christmas_00_03:
        "I understand! You can do it right away! I won't tell anyone.",
    thanks_carpenter_christmas_00_01:
        'Perfect, right? No need to thank me!',
    dialog_carpenter_chat:
        'Wallace has always been like a second father to me.',
    dialog_carpenter_chat_02:
        "I've been picking up fletching lately... What, you don't sell arrows in your shop?",
    dialog_carpenter_chat_03:
        "Living Roots keep munching through Juniper's garden fences... Should I make them out of Ironwood...?",
    dialog_carpenter_chat_04:
        "I learned how to make magical Staves on my own. Wands though? They're something else.",
    dialog_carpenter_chat_05:
        'Looking for Keys? Dungeon bosses sometimes carry them. Heard they show up at the Fortune Zone, too.',
    dialog_carpenter_chat_06:
        'Monster critical hits go right through armor! Fighters really hate that!',
    dialog_carpenter_chat_07:
        "The old Master doesn't specialize in any item type, but even I need his help to craft high-end equipment.",
    dialog_carpenter_chat_08:
        'Us workers need space to improve. Make sure to invest in our buildings so we can grow!',
    dialog_carpenter_chat_09:
        "I sometimes work blindfolded. When you're this talented, handicaps are a way to keep things interesting.",
    dialog_carpenter_chat_10:
        "Evelyn's hair looks like ripe Elvenwood, ready to be sculpted! ...Don't tell her I said that.",
    dialog_carpenter_chat_11:
        "I built a place for Maylee to call home. Not using iron was a challenge, but nothing's impossible for me!",
    dialog_carpenter_bounty_craft:
        'Hah! You think you can do better than me? Prove it!',
    dialog_carpenter_bounty_slay:
        'This bounty is only for the truest of Heroes.',
    dialog_carpenter_bounty_quest:
        'This bounty is only for the truest of Heroes.',
    dialog_carpenter_bounty_surcharge:
        'An item is only worth what a customer will pay for it!',
    dialog_carpenter_bounty_discount:
        'An item is only worth what a customer will pay for it!',
    dialog_iron_greetings: 'Make it fast, I got a mine tha dig.',
    dialog_iron_greetings_02: 'Ye need iron?',
    dialog_iron_investgold: 'Aye, fine coinage. Fer humans that is.',
    dialog_iron_investgold_02: 'Aye, that be real gold.',
    dialog_iron_investgems: 'By me beard, Merchant, yer loaded!',
    dialog_iron_investgems_02: "That be a gem worth keepin'.",
    dialog_iron_underupgrade:
        "I'm digging. If yer not here tha help, scram!",
    dialog_iron_underupgrade_02: 'Dinna wanna dig too deep this time...',
    dialog_iron_underupgrade_03: "To yer picks lads, we're diggin' DEEP!",
    dialog_iron_afterupgrade:
        "Still clearin' out tha rubble, but tha mine's back in order.",
    dialog_iron_afterupgrade_02:
        "Gettin' pretty hot down thar. Wonder how much deeper we can go...",
    visit_iron_00_01:
        "I got a buncha iron here. Struck a good vein in me mine. Ye buyin'?",
    thanks_iron_00_01: 'Back tha work then.',
    visit_iron_christmas_00_01:
        'Bah, Christmas! Elves this, elves that. Never any mention of tha dwarves.',
    visit_iron_christmas_00_02:
        'Take me Iron. And remember; a dwarf gave it to ye!',
    thanks_iron_christmas_00_01:
        "Blasted Santa's elves... I'd like to see them getting their own Iron fer a change.",
    dialog_iron_chat:
        "Dinna underestimate tha Yeti snowballs. Rascals put rocks in 'em.",
    dialog_iron_chat_02:
        'Dragons been restless lately... Cannae be good...',
    dialog_iron_chat_03:
        'This candle keeps track of time down thar. Dwarves see in tha dark just fine.',
    dialog_iron_chat_04:
        "Make sure tha upgrade yer iron bin. If it be full, yer wastin' me iron.",
    dialog_iron_chat_05:
        'Thar be a dwarven fortress not far from here. Dug too deep they did... Overrun by tha fiends now.',
    dialog_iron_chat_06:
        "That Wallace's got an anvil fer a brain! He should hammer his own head fer a change!",
    dialog_iron_chat_07:
        'Used tha work fer a princess with me six mates. Heavy sleeper, she was.',
    dialog_iron_chat_08: 'Ye looking down on me?',
    dialog_iron_chat_09:
        "Tha mine's tha birthplace of art, me daddy used to say.",
    dialog_iron_chat_10:
        'Ye humans be too tall fer armor. All that iron, wasted!',
    dialog_iron_chat_11:
        'Dinna let the short legs fool ye, dwarves be natural sprinters.',
    dialog_iron_chat_12:
        "Only tha Knights wear armor right. Real walkin' fortresses.",
    dialog_iron_chat_13:
        "Seein' a Lord brings a tear to me eye... Now that's real armor wearin'.",
    dialog_iron_chat_14:
        "Where's a dwarf supposed tha get somethin' to eat 'round here? I'm hungry like a werewolf!",
    dialog_iron_chat_15:
        "Argh! An elf living on me mine! Who's in charge of this city!?",
    dialog_iron_chat_16:
        'Iron is like poison to tha fairy folks. Gotta be a sorry life.',
    dialog_iron_chat_17:
        "That pirate lass be makin' fun of me accent, I bet.",
    dialog_iron_chat_18:
        'Gah! Now we got tha dragons as neighbors. Crazy town fer a crazy world.',
    dialog_iron_bounty_craft:
        "Yer resources be gatherin' dust. Put 'em tha good use.",
    dialog_iron_bounty_slay:
        "Thar be monsters stirrin' about. Cannae be good...",
    dialog_iron_bounty_quest:
        "Thar be monsters stirrin' about. Cannae be good...",
    dialog_iron_bounty_surcharge:
        "Blasted Heroes... Nay respect fer iron. Teach 'em a lesson fer me.",
    dialog_iron_bounty_discount:
        "Yer stuff's too expensive! Learn tha strike a deal fer a change.",
    dialog_wood_greetings:
        "You're looking thin, shopkeeper. Are you eating right?",
    dialog_wood_greetings_02: 'Yeah?',
    dialog_wood_greetings_03: 'You called for strength? I am here.',
    dialog_wood_investgold: 'Adding weight to my trip? Bring it on!',
    dialog_wood_investgold_02: 'Great!',
    dialog_wood_investgems:
        'Flexing your wallet, shopkeeper? I respect that.',
    dialog_wood_investgems_02: "You're alright, shopkeeper.",
    dialog_wood_underupgrade: "Can't talk right now. Doing reps.",
    dialog_wood_underupgrade_02:
        'You caught me during my training, shopkeeper. Come back later!',
    dialog_wood_afterupgrade:
        "Look at those gains... Those trees don't stand a chance.",
    dialog_wood_afterupgrade_02:
        'How much do you think I can bench now? I bet I can pull off 400 kilos.',
    dialog_wood_afterupgrade_03:
        "Training's done for today. Look at this bulk!",
    visit_wood_christmas_00_01:
        "Happy holidays, shopkeeper. It's a bit cold in here, isn't it?",
    visit_wood_christmas_00_02:
        "I'm doing my round through the city to make sure everyone has enough firewood.",
    thanks_wood_christmas_00_01: "Here's your share. Stay warm!",
    visit_wood_00_01:
        'Hey, shopkeeper! Allan ordered too much today, think you can take these off my hands?',
    visit_wood_01_01:
        'Shopkeeper! Carrying all this wood around makes for a good workout...',
    visit_wood_01_02:
        '...But gold would make for much better weight! How about we trade?',
    thanks_wood_00_01: "You're alright, shopkeeper.",
    dialog_wood_chat:
        "I know Ismael's only an elf, but he should start working out. A stiff breeze could snap him in half!",
    dialog_wood_chat_02: 'Squats and oats, shopkeeper. Squats and oats!',
    dialog_wood_chat_03:
        "Brohm's getting out of shape. All this time spent smelting... not enough lifting!",
    dialog_wood_chat_04: "Admiring the 'delts? You should.",
    dialog_wood_chat_05:
        "Remember to stay active! All that time spent locked inside your shop isn't good for you.",
    dialog_wood_chat_06:
        'Good form is key when lifting anything, even stuff around your shop. Use your legs, not your back!',
    dialog_wood_chat_07:
        "That Argon kid doesn't talk about it, but he pulls off some serious training when no one's looking.",
    dialog_wood_chat_08:
        "Familiars? Bah! If you need help to fight, you shouldn't be fighting!",
    dialog_wood_chat_09:
        "Where's that dumb oaf Rudo? He promised he'd be here by six.",
    dialog_wood_chat_10:
        'Why hire anything other than Barbarians? Yelling and bleeding makes for a good fight.',
    dialog_wood_chat_11:
        'A good Barbarian can bench-press 800 pounds. A good Chieftain can bench-press that, plus the Barbarian.',
    dialog_wood_chat_12:
        "I got Orcish literature class tonight at the Academy. You comin'?",
    dialog_wood_chat_13:
        'Bah! Spellblades! Weapons or magic... Pick a side!',
    dialog_wood_chat_14: 'Mundra, was it? Good gal. I like her guts.',
    dialog_wood_bounty_craft:
        'Regular training is important to keep your skill sharp.',
    dialog_wood_bounty_slay:
        "You're looking a bit thin, but you should be able to handle this.",
    dialog_wood_bounty_quest:
        "It's time to prove your worth as a warrior, shopkeeper.",
    dialog_wood_bounty_surcharge: "You've got a way with words, don't you?",
    dialog_wood_bounty_discount: "You've got a way with words, don't you?",
    dialog_tailor_greetings:
        'Impeccable presentation as usual, shopkeeper.',
    dialog_tailor_greetings_02:
        'Are you here for a modification, or a custom piece?',
    dialog_tailor_unhiredgreetings:
        'You are the new shopkeeper, correct? It is an honor to finally make your acquaintance.',
    dialog_tailor_hirequestion: 'My skills are at your disposal.',
    dialog_tailor_investgold: 'Thank you.',
    dialog_tailor_investgems: 'How mesmerizing. Thank you.',
    dialog_tailor_investgems_02: 'I am honored.',
    dialog_tailor_underupgrade:
        'Ah, terrible timing! My workshop is in shambles...',
    dialog_tailor_afterupgrade:
        'My humble workshop has a fresh coat of paint. Let me show you around!',
    visit_tailor_quality_00_01:
        "Shopkeeper, I'd like to share one of my finest works with you.",
    visit_tailor_quality_00_02:
        'You are one with eyes for quality, are you not?',
    visit_tailor_bulk_00_01:
        'Ah, shopkeeper! I brought a bundle of clothes with mismatched measurements.',
    visit_tailor_bulk_00_02:
        "They're of no use to me, but I'm sure you could find a buyer.",
    visit_tailor_quality_christmas_00_01:
        "This cold weather is just perfect for knitting by the fireplace, isn't it?",
    visit_tailor_quality_christmas_00_02:
        'Here, I even made something for you. Merry Christmas!',
    visit_tailor_bulk_christmas_00_01:
        "This cold weather is just perfect for knitting by the fireplace, isn't it?",
    visit_tailor_bulk_christmas_00_02:
        'Here, I even made something for you. Merry Christmas!',
    thanks_tailor_christmas_00_01:
        'Happy Holidays, and happy New Year, too!',
    thanks_tailor_00_01: 'Thank you kindly.',
    dialog_tailor_chat:
        "Our King is by far my biggest customer. I don't think he ever wears the same outfit twice!",
    dialog_tailor_chat_02:
        "I also style hair, if it ever strikes your fancy. Evelyn's is my greatest work!",
    dialog_tailor_chat_03:
        'Ease of movement, weight, protection... Light armor design is challenging indeed.',
    dialog_tailor_chat_04:
        "Ismael is a remarkable gentleman. If all elves are like that, I certainly wouldn't mind visiting their lands someday.",
    dialog_tailor_chat_05:
        "There's no feeling quite like caressing satin, wouldn't you agree?",
    dialog_tailor_chat_06:
        "Light armor isn't as protective as heavy armor, but Rogues can keep up thanks to Evasion.",
    dialog_tailor_chat_07:
        'Cloaks are simple enough to make. I heard elves can make them like no others, though.',
    dialog_tailor_chat_08:
        'All Heroes have a chance to land a powerful critical hit. Musketeers and Monks really excel in that regard.',
    dialog_tailor_chat_09:
        "Yolanda's knitting is unrivaled. That's a thousand year old grandma for you!",
    dialog_tailor_bounty_craft:
        "I've stumbled upon a creative block. Care to provide inspiration?",
    dialog_tailor_bounty_slay:
        'I need a Hero! One that is fast, and fresh from the fight...',
    dialog_tailor_bounty_quest:
        'Your Heroes are renowned among these parts. May I ask for their help?',
    dialog_tailor_bounty_surcharge:
        "It's been so long since I taught you about haggling... Show me how much you've improved!",
    dialog_tailor_bounty_discount:
        "It's been so long since I taught you about haggling... Show me how much you've improved!",
    dialog_leather_greetings: "Hey dude, what's up?",
    dialog_leather_greetings_02: "Hey, I'm Tamas, the leather guy.",
    dialog_leather_investgold: 'Thanks dude.',
    dialog_leather_investgold_02: 'Right on, dude.',
    dialog_leather_investgold_03: "Woah dude, I'm riiiich!",
    dialog_leather_investgems: 'Woah... Thanks dude.',
    dialog_leather_investgems_02: 'Dude, where did you get all that?',
    dialog_leather_investgems_03: "Dude, I'm like, a billionaire now.",
    dialog_leather_underupgrade:
        "Not right now, dude. I'm improving my workshop.",
    dialog_leather_underupgrade_02:
        "I'm drafting up the new floor plans, dude. How does it look?",
    dialog_leather_afterupgrade:
        'All done, dude. Smell that fresh, leathery scent.',
    dialog_leather_afterupgrade_02: 'How do you like the new decor, dude?',
    visit_leather_00_01:
        "'Sup homie, I got your stuff. You ordered an extra batch of leather right?",
    visit_leather_01_01:
        "Hey dude, I'm trying out door-to-door sales. Need some leather?",
    thanks_leather_00_01: 'Thanks dude!',
    thanks_leather_01_01: "Thanks, dude. Don't forget to tip.",
    visit_leather_christmas_00_01: 'Hey dude, happy Holidays!',
    visit_leather_christmas_00_02:
        'Is your shop ready for Santa? Make sure to have cookies and milk ready.',
    thanks_leather_christmas_00_01:
        'I hope I get a Prototype Gatling from Santa, dude.',
    visit_leather_aprilfools_00_01:
        "You've got some weird customers, dude.",
    visit_leather_aprilfools_00_02:
        'They\'re all saying "dude" all the time. That\'s just weird, dude.',
    visit_leather_aprilfools_01_01:
        "Hey dude, there's a party in here! What's the occasion?",
    visit_leather_aprilfools_01_02: "April's Fools? Sounds weird, dude.",
    thanks_leather_aprilfools_00_01:
        "Thanks, dude. Watch your back, though. I don't trust these dudes.",
    thanks_leather_aprilfools_01_01:
        'Thanks dude. Make sure not to confuse me with these weirdos.',
    dialog_leather_chat:
        "Dude, we gotta do something about Gorza's woodcutting. Save the trees, man.",
    dialog_leather_chat_02:
        'Ismael is like, a really weird dude. But I like him.',
    dialog_leather_chat_03:
        "Jog's mustache is pretty cool, dude. You think we can become mustache buddies?",
    dialog_leather_chat_04:
        'I wish I could live with the Yetis, dude. I could stroke their fur all day.',
    dialog_leather_chat_05:
        "Sometimes I go to the Aurora Caves to find a big icicle. Maybe someday I'll be the next Yeti king.",
    dialog_leather_chat_06:
        'The Emerald Inn is the best place for a bite. I always order double when I go there.',
    dialog_leather_chat_07:
        "Leather quality control is serious business, dude. You'll only get the best from me.",
    dialog_leather_chat_08: "Dude, where's my cart?",
    dialog_leather_chat_09:
        "'Sup homie, got my stuff? Oh sorry dude, I thought you were someone else.",
    dialog_leather_chat_10:
        "Ismael's sheep keep munching on my lawn, dude. I don't even need to mow it!",
    dialog_leather_chat_11:
        "Dude, don't tell anyone, but I think I'm a sheep whisperer. They keep coming over!",
    dialog_leather_chat_12:
        "I went to Serene for some fortune telling but she just told me to leave. She's rude, dude.",
    dialog_leather_chat_13:
        'Woah dude, we got dragons in town! Double dragons!',
    dialog_leather_bounty_craft:
        "Now it's my turn to ask for a delivery, dude.",
    dialog_leather_bounty_slay:
        "There's some weird monsters out there, dude... Are you gonna use that pelt?",
    dialog_leather_bounty_quest:
        'I know a place with some fresh stuff, dude.',
    dialog_leather_bounty_surcharge:
        "It's a dog eat dog world out there, dude.",
    dialog_leather_bounty_discount:
        'Why not cut your buddies a deal, dude?',
    dialog_herbalist_greetings: 'Welcome to the Maribel Medicine Emporium.',
    dialog_herbalist_greetings_02:
        'Hi! Are you looking for something in particular?',
    dialog_herbalist_greetings_03:
        "Hello again shopkeeper. I'm ready for your next order!",
    dialog_herbalist_unhiredgreetings:
        'Ah, a new merchant! Do you need medical supplies?',
    dialog_herbalist_unhiredgreetings_02:
        'As the Herbalist, I handle the creation of powerful tonics and magical herbs.',
    dialog_herbalist_hirequestion: 'Shall we team up, then?',
    dialog_herbalist_investgold: 'Thanks!',
    dialog_herbalist_investgold_02: 'Rainy day funds!',
    dialog_herbalist_investgems: 'A wise choice!',
    dialog_herbalist_investgems_02: 'Wow! Thank you!',
    dialog_herbalist_underupgrade: "Sorry, we're closed!",
    dialog_herbalist_underupgrade_02:
        'Sorry this is taking so long. Regulations around herb sales are no laughing matter...',
    dialog_herbalist_afterupgrade:
        'The Maribel Medicine Emporium is open for business.',
    visit_herbalist_quality_00_01:
        "Shopkeeper, I've got a special deal for you today.",
    visit_herbalist_quality_00_02:
        'Our finest product in stock, available for a limited time only!',
    visit_herbalist_bulk_00_01:
        'The Maribel Medicine Emporium is running a sale this week!',
    visit_herbalist_bulk_00_02: 'Cheap items by the pound. Interested?',
    thanks_herbalist_00_01: "It's all yours. Thanks!",
    visit_herbalist_quality_christmas_00_01:
        'Merry Christmas, shopkeeper! I have a gift for you!',
    visit_herbalist_quality_christmas_00_02:
        'Nuh-huh, close your eyes first!',
    visit_herbalist_bulk_christmas_00_01:
        'Merry Christmas, shopkeeper! I have a gift for you!',
    visit_herbalist_bulk_christmas_00_02: 'Nuh-huh, close your eyes first!',
    thanks_herbalist_christmas_00_01: 'Did you like it? Happy Holidays!',
    dialog_herbalist_chat:
        "Don't eat any mushrooms you find laying around. Amateur mycology is dangerous!",
    dialog_herbalist_chat_02:
        "She may not look like much, but Juniper's knowledge of plants surpasses even mine.",
    dialog_herbalist_chat_03:
        "Even now, preparing Living Roots still grosses me out. I'm not alone, right?",
    dialog_herbalist_chat_04:
        "Remember that stamina drinks are not a substitute for a good night's sleep.",
    dialog_herbalist_chat_05:
        'My potions have no artificial ingredients. All natural flavors, and gluten free!',
    dialog_herbalist_chat_06:
        'Have you seen Lilu around? Her order just came in.',
    dialog_herbalist_chat_07:
        "Druids really know their way around herbs, don't they? Think I have what it takes to become one?",
    dialog_herbalist_chat_08:
        "I'd like to talk to an Archdruid someday. Their knowledge of herbalism is unparalleled.",
    dialog_herbalist_chat_09:
        "I could use some more fairy dust... Do you think it's rude to ask Maylee?",
    dialog_herbalist_bounty_craft:
        'Never stop crafting. Keep your skill sharp!',
    dialog_herbalist_bounty_slay:
        'The dungeons have grown more dangerous than usual lately. Can you investigate?',
    dialog_herbalist_bounty_quest:
        "The season is a bit early, but I'm pondering about the freshness of these ingredients...",
    dialog_herbalist_bounty_surcharge:
        'The Medicine Emporium is having a special event. Can you include some flyers with your sales?',
    dialog_herbalist_bounty_discount:
        'The Medicine Emporium is having a special event. Can you include some flyers with your sales?',
    dialog_herbs_greetings: 'Hiiii!',
    dialog_herbs_greetings_02:
        "Welcome to the Garden! We've got fun and games!",
    dialog_herbs_greetings_03:
        "It's almost time for the harvest! SO excited!",
    dialog_herbs_investgold: 'Like, wow! Thanks!',
    dialog_herbs_investgold_02: 'Gold! Yay!',
    dialog_herbs_investgems: 'TOTALLY awesome!',
    dialog_herbs_investgems_02: "For me? REALLY!? You're the best!",
    dialog_herbs_underupgrade:
        'Knee deep in the dirt! Gotta earn that green thumb!',
    dialog_herbs_underupgrade_02:
        "Sorry! I can't do business while I'm covered in mulch!",
    dialog_herbs_afterupgrade:
        'Look! Look! We got crocuses now! And tulips!',
    dialog_herbs_afterupgrade_02:
        "Garden's looking like a jungle now! I like it!",
    visit_herbs_00_01: 'Hiii! Nice weather huh?',
    visit_herbs_00_02:
        'Maybe TOO nice. My plants are growing totally OUT OF CONTROL!',
    visit_herbs_00_03:
        "Now I got too many herbs! I'm selling 'em - SUPER cheap!",
    thanks_herbs_00_01: 'COOL! Back to gardening!',
    visit_herbs_christmas_00_01: 'MERRY CHRISTMAS!',
    visit_herbs_christmas_00_02:
        "I love saying that! That's why I'm going door-to-door and telling EVERYONE!",
    visit_herbs_christmas_00_03:
        "And here's your Christmas gift... Herbs! I wrapped it myself!",
    thanks_herbs_christmas_00_01: 'Alright! Katarina is next! See ya!',
    dialog_herbs_chat:
        'You ever noticed how it never rains around here? SO weird!',
    dialog_herbs_chat_02: 'Get in touch with nature; garden barefeet!',
    dialog_herbs_chat_03:
        'Tamas keeps asking me to grow his weird plants! Like, no!',
    dialog_herbs_chat_04:
        'Have you ever seen Gorza cut trees? It\'s like, "chop, crash!" One swing! Totally crazy!',
    dialog_herbs_chat_05:
        "I've always wanted to see one of those Mushgoon up close... but they look so mean!",
    dialog_herbs_chat_06:
        'Make sure to build a fence around your Living Root garden. They can TOTALLY crawl out of it!',
    dialog_herbs_chat_07:
        'Grimar leaves great tips for Herb deliveries! The trip is totally worth it!',
    dialog_herbs_chat_08:
        "Have you ever seen a Miniboss? They're super sneaky! And MEAN!",
    dialog_herbs_chat_09:
        'NINJAS! So cool! If I were a shopkeeper, I would hire a HUNDRED NINJAS!',
    dialog_herbs_chat_10:
        "Sensei are cooler than Ninjas... But I've never seen one! Wait, that's the point, right?",
    dialog_herbs_chat_11:
        'Maylee finally decided to move in! And she brought jewels!',
    dialog_herbs_chat_12:
        'Iron is SUPER TOXIC to fairies like Maylee! Be careful around her!',
    dialog_herbs_chat_13:
        'A weird dragon guy came and complimented my flowers! COOL!',
    dialog_herbs_chat_14:
        'GRANDMA CANDIES! Make sure to pay Yolanda a visit!',
    dialog_herbs_bounty_craft:
        'Oh! Oh! I have a request! I filled the form and everything!',
    dialog_herbs_bounty_slay:
        "My playground's been INVADED! Can you do something about it? Pleaaaase?",
    dialog_herbs_bounty_quest:
        "There's a whole bunch of components just lying around! We gotta grab them! QUICK!",
    dialog_herbs_bounty_surcharge: "It's time to make MONEY! LOADSAMONEY!",
    dialog_herbs_bounty_discount:
        "Discounting is all about STRATEGY! That's what Theodore always says!",
    dialog_fabric_greetings:
        'Ah, shopkeeper! Did my enthralling song lure you here?',
    dialog_fabric_greetings_02:
        'A remarkable entrance, shopkeeper. Very dramatic!',
    dialog_fabric_greetings_03: 'Salutations!',
    dialog_fabric_investgold: 'Thanks, I suppose.',
    dialog_fabric_investgold_02: 'Hah! You cannot bribe an artist!',
    dialog_fabric_investgems:
        'A mere balm for my saccharine soul. Wealth is an obstacle to art!',
    dialog_fabric_investgems_02: 'A surprise, to be sure.',
    dialog_fabric_underupgrade:
        'My apologies, I am currently overseeing the preparation of our new silk farm. Please return later.',
    dialog_fabric_afterupgrade:
        "Behold, my new installations. Aren't they dreamy?",
    visit_fabric_00_01:
        'Salutations, shopkeeper! I, Ismael, have risked life and limb to provide you with these!',
    thanks_fabric_00_01: 'A thousand thanks would not be enough! Farewell!',
    visit_fabric_christmas_00_01:
        'Ah, Jingle Bells, Jingle Bells<sprite tint=1 name=Note1>...',
    visit_fabric_christmas_00_02:
        'Human songs are primitive, but I must admit I am enthralled by their charm.',
    visit_fabric_christmas_00_03:
        'You may have this resplendent Fabric, as a gift. I am in a festive mood!',
    thanks_fabric_christmas_00_01:
        'Fare thee well, shopkeeper. And Happy Holidays!',
    dialog_fabric_chat:
        'My elven homegrove has always been filled with songs and dances. I miss it, sometimes.',
    dialog_fabric_chat_02:
        'Ah, to return to the Aurora Caves one day! Such a breathtaking sight...',
    dialog_fabric_chat_03:
        'The sheep I have here are a special elven breed. Picky eaters, but feel that wool!',
    dialog_fabric_chat_04: 'Care for a bit of poetry?',
    dialog_fabric_chat_05:
        "-and lo! the stars' enticing tinkle, betwixt sun and moon...",
    dialog_fabric_chat_06:
        'The people here can be a bit... uncultured at times. I feel like only Julia understands me. And you, of course.',
    dialog_fabric_chat_07:
        "Wallace's jokes are awful! Revolting! An insult to art! How does he come up with all that?",
    dialog_fabric_chat_08:
        'My sheep have taken a disturbing fondness with Tamas. This cannot continue!',
    dialog_fabric_chat_09:
        'Ah, mysterious black-clad maiden! When will you return to sweep me off my feet?',
    dialog_fabric_chat_10:
        'Elusive woman, stalking the darkness... Driven by rage, stranger to kindness...',
    dialog_fabric_chat_11:
        'Spellblades wield arms and magic with equal grace. A Hero class for the distinguished shopkeeper.',
    dialog_fabric_chat_12:
        'Only the best among Spellblades can dream of becoming a Spellknight.',
    dialog_fabric_chat_13:
        "Fair Serene's bloodline is far beyond your average elf. Even mine, I must admit.",
    dialog_fabric_chat_14:
        'Avatars of sun and moon have come to settle in our town. This town truly is blessed, Tamas aside.',
    dialog_fabric_bounty_craft:
        'Go, shopkeeper. Show them the meaning of art!',
    dialog_fabric_bounty_slay:
        "Camaraderie, adventure, and steel on steel... The stuff of legend! Don't you agree?",
    dialog_fabric_bounty_quest:
        "Marvelous treasures abound just outside the city gate. I would like some, if you don't mind.",
    dialog_fabric_bounty_surcharge:
        "You've a reputation for having a silver tongue. Can you live up to it?",
    dialog_fabric_bounty_discount:
        "You've a reputation for having a silver tongue. Can you live up to it?",
    dialog_steel_greetings: 'Oh, hi there.',
    dialog_steel_greetings_02: "Hey, I didn't hear you come in.",
    dialog_steel_investgold: 'Hey, thanks.',
    dialog_steel_investgems: 'For me? Ah, I got dust in my eyes...',
    dialog_steel_underupgrade:
        "Ah, sorry, the foundry's closed. I hope it's not too much of an inconvenience.",
    dialog_steel_underupgrade_02:
        "Could you step outside for a bit? It's a bit unsafe in here.",
    dialog_steel_afterupgrade: 'The smelter is back in order.',
    dialog_steel_afterupgrade_02:
        "Come in, come in! I just had the Smelter's ceilings raised!",
    visit_steel_00_01:
        "Hi there! You've been so hard at work lately, I thought you might need some extra Steel!",
    thanks_steel_00_01:
        'Keep up the good work. The city is counting on you!',
    visit_steel_christmas_00_01:
        "Brrr! Cold weather huh? I should've put on a few layers!",
    visit_steel_christmas_00_02:
        'Still, I really wanted to give you these.',
    thanks_steel_christmas_00_01:
        "I'll be getting back to the warmth of the smelter. Happy Holidays!",
    dialog_steel_chat:
        "I run the foundry on my own. It's a lot of work, but I get distracted with people around.",
    dialog_steel_chat_02:
        "That ninja girl came here the other day. Taciturn sort, isn't she?",
    dialog_steel_chat_03: "What's Argon up to lately? Doing hero work?",
    dialog_steel_chat_04:
        'Have you been using those Power Booster? It can really make a difference against bosses.',
    dialog_steel_chat_05:
        'This Smelter was not built with orcs in mind. I keep bumping my head on the ceiling...',
    dialog_steel_chat_06:
        'Those little Thief Heroes sure can wield a dagger, huh?',
    dialog_steel_chat_07:
        "Tricksters are something else! That's one Hero I wouldn't want to mess with!",
    dialog_steel_chat_08:
        'Um... is that large Rudo fellow still around? Whew! What a scary-looking guy!',
    dialog_steel_chat_09:
        'At night, I can see Serene through my window, gazing at the stars... Does that poor girl ever sleep?',
    dialog_steel_bounty_craft: 'The city is counting on you!',
    dialog_steel_bounty_slay:
        "You're not afraid of a monster or two, right?",
    dialog_steel_bounty_quest: 'Make sure to bring bags to carry it all!',
    dialog_steel_bounty_surcharge:
        "There's no shame in a little profit, is there?",
    dialog_steel_bounty_discount:
        'If you have a heart of gold, this bounty is for you!',
    dialog_ironwood_greetings: "Can this wait? I'm on break.",
    dialog_ironwood_greetings_02: "Fine, I'm listening.",
    dialog_ironwood_greetings_03: "Yeah, yeah. I'm here.",
    dialog_ironwood_investgold: 'Tch, gotta bring out the ledger...',
    dialog_ironwood_investgold_02: 'Yes, thank you. Is that all?',
    dialog_ironwood_investgems: 'That... is worth my time.',
    dialog_ironwood_underupgrade:
        'Gotta work overtime to improve the sawmill... What a bother.',
    dialog_ironwood_afterupgrade:
        "Whew, I'm all done here. Time for a nap...",
    dialog_ironwood_afterupgrade_02:
        'The sawmill is operational again. Maybe I should hire more underlings...',
    visit_ironwood_00_01:
        "Look, I'm stuck carrying this stuff all the way across town and I really can't be bothered.",
    visit_ironwood_00_02:
        'If you buy it off me, you save me the hassle. Deal?',
    thanks_ironwood_00_01: 'Good. I can catch a nap on the way back.',
    visit_ironwood_christmas_00_01:
        'A holiday of kinship and generosity...',
    visit_ironwood_christmas_00_02:
        'A good time to reflect upon the ethics of our businesses, hmm?',
    visit_ironwood_christmas_00_03:
        "Wouldn't want a visit from a ghost of Christmas past now, do we?",
    thanks_ironwood_christmas_00_01:
        "That one's on me. Have a good, enlightening holiday.",
    dialog_ironwood_chat:
        'I wish Gorza could slow down a bit. Hard to keep up with her frantic woodcutting.',
    dialog_ironwood_chat_02:
        'Are you still serving customers on your own? You should learn to delegate.',
    dialog_ironwood_chat_03:
        'The eight hour work day is oppression enforced by the bourgeoisie. Tamas got that right.',
    dialog_ironwood_chat_04:
        "Serene's building's just a bunch of rocks, isn't it? Where does she sleep?",
    dialog_ironwood_bounty_craft:
        "It's time to seize the means of production.",
    dialog_ironwood_bounty_slay:
        "It's an act of self-defense if the monster attacks first. Remember that.",
    dialog_ironwood_bounty_quest: 'Finders keepers, I say.',
    dialog_ironwood_bounty_surcharge:
        'The customer is king, but even royalty can be misled.',
    dialog_ironwood_bounty_discount:
        'The customer is king, but even royalty can be misled.',
    dialog_oils_greetings: "Don't just stand there, come on in!",
    dialog_oils_greetings_02: 'Would you care for some tea?',
    dialog_oils_investgold: 'Thanks!',
    dialog_oils_investgold_02: 'Spending your allowance, dear?',
    dialog_oils_investgems: "Oh, you're loaded!",
    dialog_oils_investgems_02: "You're my favorite!",
    dialog_oils_underupgrade: 'Oh no no no! Someone spilt an oil jar!',
    dialog_oils_afterupgrade:
        'I had Allan do some improvements to the Oil Press. What do you think?',
    visit_oils_00_01:
        "Dear, I'm in a bind. One of my clients canceled a huge Oil order out of the blue!",
    visit_oils_00_02:
        "Now I don't have enough storage to keep it. Would you like to buy it?",
    thanks_oils_00_01: "Thanks, you're a lifesaver!",
    visit_oils_christmas_00_01:
        "Santa's little elf is hard at work, I see!",
    visit_oils_christmas_00_02:
        "Make sure to take a break now and then. It's Christmas for everyone!",
    thanks_oils_christmas_00_01:
        'Happy Holidays, and remember to write to your family!',
    dialog_oils_chat:
        "Have you seen Wallace's children? They're absolutely adorable! Why, even Durhan doesn't mind babysitting them!",
    dialog_oils_chat_02:
        "Oh don't let our big Brohm intimidate you, he's a gentle giant! His sister Gorza on the other hand...",
    dialog_oils_chat_03:
        "Have you heard of Familiars? I've heard people train cute pets to fight alongside them!",
    dialog_oils_chat_04:
        "You'd have to be a fool to duel a Samurai! Their first hit always strikes true!",
    dialog_oils_chat_05:
        'My oil has therapeutic properties, too. Would you care to try it?',
    dialog_oils_chat_06:
        "Back in my Kernish homeland, the strongest men would bathe in oil and... Oh my! I'm babbling again!",
    dialog_oils_chat_07:
        "I've been told that Rudo is an expert armor user... There's just nothing that fits the poor boy!",
    dialog_oils_chat_08:
        'Sondra has extensive knowledge in all sorts of exquisite teas. A perfect gentleman, too!',
    dialog_oils_bounty_craft:
        "I can't find these anywhere on the market... Can you provide a supply?",
    dialog_oils_bounty_slay:
        "I'd like to see your strongest Heroes in action!",
    dialog_oils_bounty_quest:
        'On a nice day like this... you should be outside gathering components!',
    dialog_oils_bounty_surcharge:
        "Haggling is an art, dear. One you are not unfamiliar with, I've heard!",
    dialog_oils_bounty_discount:
        "Haggling is an art, dear. One you are not unfamiliar with, I've heard!",
    dialog_priest_greetings: "Welcome to the Goddess' humble temple.",
    dialog_priest_greetings_02: 'Let us bow our heads in prayer.',
    dialog_priest_unhiredgreetings:
        'Welcome to the holy temple, shopkeeper.',
    dialog_priest_unhiredgreetings_02:
        'I am but a humble servant of the Goddess, but Her blessing can impart great power upon amulets.',
    dialog_priest_unhiredgreetings_03:
        'She has also granted me the ability to infuse the spirits of this world into magic runes...',
    dialog_priest_hirequestion:
        'Will you accept the Goddess in your heart?',
    dialog_priest_investgold:
        'Blessings of the Goddess upon you, shopkeeper.',
    dialog_priest_investgold_02:
        'Remember that church donations are exempt from taxes!',
    dialog_priest_investgold_03: 'The orphans thank you, shopkeeper.',
    dialog_priest_investgold_04: 'Blessed are the shopkeepers...',
    dialog_priest_investgold_05: 'A most generous tithe. Thank you.',
    dialog_priest_investgems: 'Oh my...!',
    dialog_priest_investgems_02:
        'Such benevolence... the Goddess thanks you, shopkeeper.',
    dialog_priest_underupgrade: 'For every brick, a prayer...',
    dialog_priest_underupgrade_02:
        'Perhaps we can afford stained glass windows now...',
    dialog_priest_afterupgrade:
        "The temple's doors are once again open to all. You are welcome, shopkeeper.",
    dialog_priest_afterupgrade_02:
        "The Goddess' light surrounds the temple once more.",
    visit_priest_bulk_00_01:
        'Noble shopkeeper... I am amassing donations for the temple.',
    visit_priest_bulk_00_02:
        'Would you be so kind as to contribute? Donors receive complimentary items!',
    visit_priest_quality_00_01:
        "Kind merchant! Last night, I've had a... vision.",
    visit_priest_quality_00_02:
        'The Goddess herself guided my hands into creating an item of great power!',
    visit_priest_quality_00_03:
        'I believe She would like you to have it. Will you take it?',
    thanks_priest_00_01: 'The Goddess thanks you, shopkeeper.',
    visit_priest_quality_christmas_00_01:
        '...During this holy night, it is important to reflect upon the people we hold most dear.',
    visit_priest_quality_christmas_00_02:
        'I crafted this under the starlight. Will you take it?',
    visit_priest_bulk_christmas_00_01:
        '...During this holy night, it is important to reflect upon the people we hold most dear.',
    visit_priest_bulk_christmas_00_02:
        'I crafted this under the starlight. Will you take it?',
    thanks_priest_christmas_00_01:
        'I will pray for you to the Goddess. For good health and continued fortune',
    dialog_priest_chat: 'Will you be attending mass later today?',
    dialog_priest_chat_02:
        'Each of my amulets has been blessed by the Goddess... Treat them with care.',
    dialog_priest_chat_03:
        'Wallace made some toys for the orphans again today. Goddess bless his heart!',
    dialog_priest_chat_04:
        'I wish Brohm would come out of his shell more often...',
    dialog_priest_chat_05:
        "Guns are terrifying tools of war... I don't think the Goddess would approve their use...",
    dialog_priest_chat_06:
        'Clerics have sworn an oath not to wield swords... Maces are fair game, though!',
    dialog_priest_chat_07:
        "I've taught Lilu some healing magic. She is beyond gifted.",
    dialog_priest_chat_08:
        'Runestones can sometimes be found during quests, but only Evelyn the Scholar knows how to craft them.',
    dialog_priest_chat_09:
        'The spirits of this world can be enchanted onto items to grant them wonderful properties.',
    dialog_priest_chat_10:
        "Serene's rather... unique, isn't she? She must be so lonely...",
    dialog_priest_chat_11:
        "The Sun God's Tomb has powerful guardians to deter graverobbers.",
    dialog_priest_bounty_craft:
        'I would like to learn from a professional. May I watch?',
    dialog_priest_bounty_slay:
        'When the wilderness threatens the faithful, a crusade is in order...',
    dialog_priest_bounty_quest:
        'With faith in your heart, the Goddess may guide you to salvation.',
    dialog_priest_bounty_surcharge:
        'To take from the rich and give to the poor is a noble endeavor.',
    dialog_priest_bounty_discount:
        'A touch of generosity could make this world a better place.',
    dialog_master_greetings: 'Hail, shopkeeper.',
    dialog_master_greetings_02: 'Greetings.',
    dialog_master_greetings_03: "Fine day, wouldn't you say?",
    dialog_master_unhiredgreetings:
        "Ah, shopkeeper. I figured I'd see you around eventually.",
    dialog_master_unhiredgreetings_02:
        'I am known as the Master around these parts. My boundless knowledge allows the creation of rare and powerful equipment!',
    dialog_master_hirequestion:
        'So, are you bringing this old man out of retirement?',
    dialog_master_investgold: "That'll do fine.",
    dialog_master_investgems: 'Heh. Not bad, kid.',
    dialog_master_underupgrade:
        "I'm dusting off my old tools as we speak. Why not come back later?",
    dialog_master_afterupgrade:
        'Good timing, I was just done renovating around here.',
    visit_master_quality_00_01:
        'Hail, shopkeeper. I got an outstanding piece for you today. Special discount, too.',
    visit_master_bulk_00_01:
        'Hey kid, listen to this. I got into a crafting competition with Wallace the other day...',
    visit_master_bulk_00_02:
        'I won, of course. But now I got all of these items lying around. How about you take them?',
    thanks_master_00_01: 'Thanks, kid. See you around!',
    visit_master_quality_christmas_00_01: 'Ho-ho-ho! Merry Christmas, kid!',
    visit_master_quality_christmas_00_02:
        "I'm not the real Santa Claus, but I still brought you a present!",
    visit_master_bulk_christmas_00_01: 'Ho-ho-ho! Merry Christmas, kid!',
    visit_master_bulk_christmas_00_02:
        "I'm not the real Santa Claus, but I still brought you a present!",
    thanks_master_christmas_00_01:
        "I promise I won't tell Santa about all that surcharging you've been doing.",
    dialog_master_chat:
        'I used to be the smith here, a long time ago. My son Wallace is in charge now.',
    dialog_master_chat_02:
        'Do you enjoy board games? We can try Oligopoly if you have an hour to spare. Or three.',
    dialog_master_chat_03:
        'Fancy a game of chess? I can show you the ropes.',
    dialog_master_chat_04:
        "That Engineer makes for a noisy neighbor, doesn't she? Nice girl, though.",
    dialog_master_chat_05:
        "Tougher monsters sometimes stalk the dungeons... Minibosses, I believe they're called.",
    dialog_master_chat_06:
        'Kids these days and their fancy "Auto Chess"... What\'s wrong with regular chess?',
    dialog_master_chat_07:
        "Have you tried enchanting items? I heard it's all the rage with kids these days.",
    dialog_master_chat_08:
        "Those magic chests sometimes contain rare and powerful Elemental Blueprints. I'd love to get my hands on one of those!",
    dialog_master_bounty_craft:
        'Surprise crafting contest! Are you up to the challenge?',
    dialog_master_bounty_slay:
        'Too easy for you? Try it with a blindfold on!',
    dialog_master_bounty_quest:
        "Back in my days, I knew a secret gathering spot in that area. Why don't you check it out?",
    dialog_master_bounty_surcharge:
        'Haggling is a duel of will and wits. Do you have what it takes?',
    dialog_master_bounty_discount:
        'Haggling is a duel of will and wits. Do you have what it takes?',
    dialog_wizard_greetings: 'Hello... Do I know you?',
    dialog_wizard_greetings_02: 'Have you perchance seen Lola around?',
    dialog_wizard_unhiredgreetings: 'There are some who call me... Tim.',
    dialog_wizard_unhiredgreetings_02:
        'As Wizard of the highest order, I can create spells and elements of immense power.',
    dialog_wizard_unhiredgreetings_03:
        '...Or was it scrolls? Grimoires? I should probably write this down... Regardless!',
    dialog_wizard_hirequestion: 'My knowledge is yours, shopkeeper.',
    dialog_wizard_investgold: 'Ah, thank you. How much was it again?',
    dialog_wizard_investgold_02:
        "I don't have any change... Oh, are you investing?",
    dialog_wizard_investgems:
        'Splendid rocks you have there... Gems you say?',
    dialog_wizard_underupgrade:
        "I'm looking for a rare book for my magical library... Something about a fellowship. Do you have it?",
    dialog_wizard_afterupgrade:
        'I feel more powerful today... Did I do anything last night?',
    dialog_wizard_afterupgrade_02:
        'Everything feels different around here... What happened last night?',
    visit_wizard_bulk_00_01:
        "Freyja, I've brought you the item you asked for... Wait a minute, where am I?",
    visit_wizard_quality_00_01:
        "Kind librarian, I'd like this exceptional item preserved as soon as possible.",
    visit_wizard_quality_00_02:
        "Hold on a moment, this isn't the library! ...How much were you offering again?",
    thanks_wizard_00_01: 'Thank you. Now, to find my house...',
    visit_wizard_quality_christmas_00_01:
        'A strange old man living at the North Pole... Crafting toys for good children...',
    visit_wizard_quality_christmas_00_02:
        'Fellow wizard, perhaps? Mysterious... Mysterious indeed...',
    visit_wizard_quality_christmas_00_03:
        'Oh, hello there shopkeeper. It seems that my wandering has brought me here.',
    visit_wizard_quality_christmas_00_04:
        'A Christmas present? I suppose you could have this.',
    visit_wizard_bulk_christmas_00_01:
        'A strange old man living at the North Pole... Crafting toys for good children...',
    visit_wizard_bulk_christmas_00_02:
        'Fellow wizard, perhaps? Mysterious... Mysterious indeed...',
    visit_wizard_bulk_christmas_00_03:
        'Oh, hello there shopkeeper. It seems that my wandering has brought me here.',
    visit_wizard_bulk_christmas_00_04:
        'A Christmas present? I suppose you could have this.',
    thanks_wizard_christmas_00_01:
        'Perhaps I could contact this strange man. How do the children do it...?',
    dialog_wizard_chat: 'Have you seen my staff anywhere?',
    dialog_wizard_chat_02:
        'That boy Arthur came here with an important request earlier... Or was it Argon?',
    dialog_wizard_chat_03:
        'I am Timotheus Grimar, grand wizard and enchanter. I know much that is hidden.',
    dialog_wizard_chat_04:
        "There's another tower in the city? Has another Wizard moved in?",
    dialog_wizard_chat_05: 'Huh? Familiars? It sounds... familiar.',
    dialog_wizard_chat_06:
        'I once enchanted items with elements, a long time ago. Am I imagining things?',
    dialog_wizard_chat_07:
        "I've lost my diary somewhere... Have you seen it? It's a nice shade of blue.",
    dialog_wizard_chat_08:
        'Ah, Runestones. I think I recall finding some in those fancy chests...',
    dialog_wizard_bounty_craft: 'Hmm... These should be useful... I think.',
    dialog_wizard_bounty_slay:
        'There is a dangerous monster around these parts...',
    dialog_wizard_bounty_quest:
        "I can't quite recall their name, but they looked a bit like... this?",
    dialog_wizard_bounty_surcharge:
        'I trust you are one who knows how to handle gold?',
    dialog_wizard_bounty_discount:
        'I trust you are one who knows how to handle gold?',
    dialog_jeweler_greetings: 'No window shopping, please.',
    dialog_jeweler_greetings_02:
        "Time is money, shopkeeper. Let's not waste it.",
    dialog_jeweler_greetings_03: "Let's talk business.",
    dialog_jeweler_unhiredgreetings:
        'What do we have here? This glitter in your eyes... A taste for luxury, style and all things expensive...',
    dialog_jeweler_unhiredgreetings_02:
        'For a person of your caliber, only the most expensive rings will do.',
    dialog_jeweler_hirequestion: 'You will find my price more than fair.',
    dialog_jeweler_investgold: 'All pieces accounted for, thank you.',
    dialog_jeweler_investgold_02: 'I do love the glitter of gold.',
    dialog_jeweler_investgems:
        'You had my interest, now you have my attention.',
    dialog_jeweler_investgems_02: 'You know how to sweet talk a jeweler.',
    dialog_jeweler_investgems_03:
        'Azure diamonds, round cut, 25 carats... Where did you find such beauties, shopkeeper?',
    dialog_jeweler_underupgrade:
        "I'm... improving security around here. Traps and such. All perfectly legal.",
    dialog_jeweler_afterupgrade:
        "We're open again. Don't touch anything expensive, it's probably trapped.",
    visit_jeweler_bulk_00_01:
        "Glittering gold, trinkets, baubles... I got a handful of them that aren't selling.",
    visit_jeweler_bulk_00_02:
        "You're not a bad merchant. Perhaps you can succeed where I have failed?",
    visit_jeweler_quality_00_01:
        'Shopkeeper, you need to see this. When I got my hands on THAT piece...',
    visit_jeweler_quality_00_02:
        "I simply knew you'd be interested. Am I right?",
    thanks_jeweler_00_01: "It's a deal then. I'll be counting the coins.",
    visit_jeweler_quality_christmas_00_01:
        'Tch. "Season of giving"... what a farce.',
    visit_jeweler_quality_christmas_00_02:
        "Still, you've been a reliable business partner so far.",
    visit_jeweler_quality_christmas_00_03:
        'I suppose you could have this... for free. But keep it between us.',
    visit_jeweler_bulk_christmas_00_01:
        'Tch. "Season of giving"... what a farce.',
    visit_jeweler_bulk_christmas_00_02:
        "Still, you've been a reliable business partner so far.",
    visit_jeweler_bulk_christmas_00_03:
        'I suppose you could have this... for free. But keep it between us.',
    thanks_jeweler_christmas_00_01:
        "I'll be expecting a thank you gift with my name on it.",
    dialog_jeweler_chat:
        "Don't bother accepting Theodore's invitation to play chess. He will destroy you, as he did everyone else here.",
    dialog_jeweler_chat_02:
        'Gemstones are particularly receptive to magical enchantments. Seems to me like magic has good taste.',
    dialog_jeweler_chat_03:
        "Not everyone can attune themselves to magical rings and amulets. It's rather taxing on the mind and body.",
    dialog_jeweler_chat_04:
        "That ninja girl... I'd better not catch her snooping around my inventory.",
    dialog_jeweler_chat_05:
        "I don't trust Durhan. All that unsupervised mining... I'm sure he keeps the best finds for himself.",
    dialog_jeweler_chat_06:
        "You have no idea what it's like living next to that... Engineer. Explosions, day and night!",
    dialog_jeweler_chat_07:
        "Wealthy customers won't bother visiting a tiny shack. Expand your shop to sell those expensive items.",
    dialog_jeweler_chat_08:
        "King Reinhold himself might visit your shop, sometime. You couldn't ask for a better customer.",
    dialog_jeweler_chat_09:
        'That fairy girl settled... in my backyard. Goddess help me.',
    dialog_jeweler_chat_10:
        'Moonstones, huh? I certainly wish I could get my hands on some of those.',
    dialog_jeweler_bounty_craft:
        "I'm expecting an important market shift soon. This bounty is mutually beneficial.",
    dialog_jeweler_bounty_slay:
        "I'm expecting a jewel shipment soon. Ensure the roads are safe.",
    dialog_jeweler_bounty_quest:
        'My business could use a little scarcity around these components.',
    dialog_jeweler_bounty_surcharge:
        'Supply and demand are two sides of a very valuable coin.',
    dialog_jeweler_bounty_discount:
        'Discount is not part of my vocabulary. Consider it professional curiosity.',
    dialog_champions_greetings: 'Argon, reporting for duty!',
    dialog_champions_greetings_02:
        'Shopkeeper! Do you bring daring tales of mercantilism?',
    dialog_champions_investgold: 'Yes! Thank you, shopkeeper!',
    dialog_champions_investgems: 'Wow! Thanks for believing in me!',
    dialog_champions_investgems_02: "I won't let you down!",
    dialog_champions_underupgrade:
        '100 push-ups, 100 sit-ups, 100 squats, 10km run! Heroes need to be in top shape!',
    dialog_champions_afterupgrade:
        'Whew, I think we learned some new tricks thanks to you, shopkeeper!',
    dialog_champions_afterupgrade_02:
        'We came up with some new training drills. The Training Hall is back in business!',
    dialog_champions_chat:
        "How's business? Have any glorious quests in need of Heroes?",
    dialog_champions_chat_02:
        'I volunteer to train aspiring Heroes here. There is much to learn by teaching!',
    dialog_champions_chat_03:
        'Fighters are the toughest Heroes around. They tend to draw enemy attacks away from others, too.',
    dialog_champions_chat_04:
        "Rogues don't look like much, but they're adept at dodging attacks and landing powerful critical hits.",
    dialog_champions_chat_05:
        'Spellcasters are frail, but no other Heroes can match their attack power.',
    dialog_champions_chat_06:
        "Heroes all have their own style, even within the same class. There's no guessing what skills they'll learn.",
    dialog_champions_chat_07:
        'Some Hero skills seem rarer than others... Those learned later in their career are better than usual, I bet.',
    dialog_champions_chat_08:
        "Try equipping your spellcasters with Spell items. It's the secret behind their offensive power!",
    dialog_champions_chat_09:
        'If a quest seems too difficult, try using boosters! Even Champions need a hand sometimes.',
    dialog_champions_chat_10:
        "The path to becoming a true hero is long and arduous, but I won't give up!",
    dialog_champions_chat_11:
        'A Living Roots crawled through the window the other day! It gave us quite a fright!',
    dialog_champions_chat_12:
        'Keep an eye out for Champion Coins! They might allow you to meet some new people!',
    dialog_champions_chat_13:
        "There's a rumor among the Heroes about a... Titan Soul. They say it can unlock the true potential within anyone!",
    dialog_champions_bounty_craft: 'The Heroes could use a few of these!',
    dialog_champions_bounty_slay:
        'Some novice Heroes are ready for their first sortie. Can you guide them around?',
    dialog_champions_bounty_quest:
        "I'm ready for a quest! How about these components?",
    dialog_champions_bounty_surcharge:
        'You got this, shopkeeper! I believe in you!',
    dialog_champions_bounty_discount:
        'Heroes can use a little discount every now and then!',
    dialog_townhall_greetings: 'Ah, young one. Stay awhile and listen.',
    dialog_townhall_greetings_02:
        "Eh? What was that? My hearing isn't getting any better...",
    dialog_townhall_greetings_03: 'Such spring in your steps!',
    dialog_townhall_investgold: 'Good... Very good!',
    dialog_townhall_investgold_02: 'Ah, pension funds!',
    dialog_townhall_investgems: 'Ah, the exuberance of youth...',
    dialog_townhall_underupgrade:
        'Ah, shopkeeper. The city is laying down foundations for new some shops!',
    dialog_townhall_afterupgrade:
        "We've expanded the shopping district. You can now invite new merchants to the city, if you'd like.",
    dialog_townhall_afterupgrade_02:
        'The Town Hall is buzzing with activity once again!',
    dialog_townhall_chat:
        'Heroes need to train some before they can use stronger equipment. Safety first!',
    dialog_townhall_chat_02:
        'To see you out and about, investing and making friends... You make this old man proud.',
    dialog_townhall_chat_03:
        "There's a mysterious ninja prowling around town... Is she a friend of yours?",
    dialog_townhall_chat_04:
        "How's your Guild doing? Make sure to invest in the city every now and then.",
    dialog_townhall_chat_05:
        "Your investments will follow you if you decide to join a new guild, so don't worry too much about it.",
    dialog_townhall_chat_06:
        'Have you been collecting those strange golden coins? Champions seem to like them.',
    dialog_townhall_chat_07:
        "Mighty Sorcerers won't deign to work with just anybody, but their attack power is legendary.",
    dialog_townhall_chat_08:
        "You've heard of Warlocks haven't you? They terrify even Sorcerers... But such power comes at a cost.",
    dialog_townhall_bounty_craft: "Care to listen to an old man's request?",
    dialog_townhall_bounty_slay:
        'These monsters are giving honest townsfolk a hard time. Can you take care of it?',
    dialog_townhall_bounty_quest:
        'Oh my, how did this get here? I am no good with technology....',
    dialog_townhall_bounty_surcharge: 'Such is the art of mercantilism...',
    dialog_townhall_bounty_discount:
        'Back in my days, a Squire Sword cost a mere 1 gold!',
    churn_00_01: 'Ah, shopkeeper!',
    churn_00_02:
        "Since you've been working so hard lately, the townsfolk pitched together to buy you a gift.",
    churn_00_03: "Don't be shy now, open it!",
    dialog_tavern_greetings: "Your favorite seat's right over there, hun.",
    dialog_tavern_greetings_02: "So what's the word on the street?",
    dialog_tavern_greetings_03: 'What can I get you, shopkeeper?',
    dialog_tavern_greetings_04: 'The usual, shopkeeper?',
    dialog_tavern_greetings_05: "Your favorite stew's on the menu today!",
    dialog_tavern_greetings_06:
        "Ah, shopkeeper! I'll be with you in a second!",
    dialog_tavern_greetings_07: 'One Mushgoon soup, coming right up!',
    dialog_tavern_investgold: 'Thank you kindly.',
    dialog_tavern_investgold_02: 'Thanks, hun.',
    dialog_tavern_investgems: 'This stays between us.',
    dialog_tavern_investgems_02: 'I expected no less from you.',
    dialog_tavern_underupgrade:
        "We're remodeling, so the inside is a bit of a mess right now.",
    dialog_tavern_underupgrade_02:
        "We're serving outside today, the tavern is undergoing some repairs.",
    dialog_tavern_afterupgrade: "Come on in, we've got a new menu!",
    dialog_tavern_afterupgrade_02: 'Welcome to our grand reopening!',
    visit_tavern_00_01:
        'Hi! Surprised to see me? Took a short break to pay you a visit.',
    visit_tavern_00_02:
        'But most importantly, I got a little something for you...',
    visit_tavern_01_01: 'Hi, hun. Did you miss me?',
    visit_tavern_01_02:
        "Got you something special today. Tipsy patrons leave the weirdest tips, don't they?",
    thanks_tavern_00_01: "Let's keep this between us!",
    thanks_tavern_01_01:
        "Best if we didn't let anyone hear about this transaction. It'll be our little secret!",
    visit_tavern_christmas_00_01:
        'I do love the holiday season! The snow, the decorations...',
    visit_tavern_christmas_00_02:
        '...And a tavern bustling with patrons eager to tip, of course!',
    visit_tavern_christmas_00_03:
        'I suppose you could have some. It is Christmas after all!',
    thanks_tavern_christmas_00_01: 'Happy Holidays, and happy New Year!',
    dialog_tavern_chat:
        'I heard upgrading your racks gives you more energy. Is that true?',
    dialog_tavern_chat_02:
        "The Yetis of the Aurora Caves are kind of cute aren't they?",
    dialog_tavern_chat_03:
        "Gorza used to be a mighty warrior, you know? She wouldn't admit it, but I think she prefers her peaceful woodcutter life.",
    dialog_tavern_chat_04:
        'The Aurora Caves are a popular dating spot around these parts. They look absolutely stunning at night!',
    dialog_tavern_chat_05:
        'With proper funding, I can organize expeditions to chart the dungeons. It should make your quests more efficient!',
    dialog_tavern_chat_06:
        'Things can get pretty rowdy when Gorza drinks here. Thankfully her brother is much more down to earth.',
    dialog_tavern_chat_07:
        "You can send an incomplete party on a quest if you want. They'll take longer, though.",
    dialog_tavern_chat_08:
        "Have you met my sister Maya? She's in charge of the Fortune Zone.",
    dialog_tavern_chat_09:
        "Those Monks are a weird bunch, aren't they? No weapons, no armor, all punching!",
    dialog_tavern_chat_10:
        "You won't believe this but... I saw a Grandmaster the other day! I can't imagine what trials a Monk has to go through...",
    dialog_tavern_chat_11:
        'I dont know how Yolanda does it, but she manages to raise monsters while keeping up with her knitting.',
    dialog_tavern_bounty_craft:
        'I overheard an interesting bit of info at the tavern. You should capitalize on it!',
    dialog_tavern_bounty_slay:
        "There's a few uglies out there that are better gone than about, don't you agree?",
    dialog_tavern_bounty_quest:
        'I just got a tip about an especially bountiful area in this region...',
    dialog_tavern_bounty_surcharge: 'A wink and a smile is all it takes.',
    dialog_tavern_bounty_discount: "It's all about returning customers!",
    dialog_inn_greetings:
        'Business has been peaking lately with all those Heroes about.',
    dialog_inn_greetings_02: 'Ah, shopkeeper! Come on in!',
    dialog_inn_greetings_03: "Just who I've been expecting!",
    dialog_inn_investgold: 'The Emerald Inn appreciates your patronage!',
    dialog_inn_investgold_02:
        'You leave the best tips, shopkeeper. Hah hah hah!',
    dialog_inn_investgold_03: '*Mwah* Perfect! Just like my family recipe!',
    dialog_inn_investgems:
        "Hah hah hah! Haven't seen one of those in ages!",
    dialog_inn_investgems_02:
        'Ahhh... reminds me of my old adventuring days!',
    dialog_inn_underupgrade:
        'Better consult Sia about the wallpapers this time...',
    dialog_inn_underupgrade_02:
        "Mark my words, the King himself will want to stay here once we're done!",
    dialog_inn_afterupgrade:
        "Inn's open for business again. Perhaps next time we can afford a Jacuzzi! Hah hah!",
    dialog_inn_afterupgrade_02:
        'New rooms, new beds. Welcome to the new Emerald Inn!',
    visit_inn_00_01:
        'Shopkeeper, old pal! I found some of my old adventuring supplies.',
    visit_inn_00_02:
        "It's no use to me now, though. How about a gift for your Heroes?",
    thanks_inn_00_01: 'Pleasure doing business with you, as always.',
    thanks_inn_01_01:
        'Thanks. Come over to the Emerald Inn again, sometime. Our doors are always open for you!',
    thanks_inn_02_01:
        "Thanks! I'll be heading back to the Inn now. See you there!",
    visit_inn_christmas_00_01: 'Ah, finally some peace and quiet!',
    visit_inn_christmas_00_02:
        "It's pretty busy down at the Emerald Inn during the holidays, let me tell you!",
    visit_inn_christmas_00_03:
        "Figured I'd come over for a little breather and a chat, if you don't mind.",
    visit_inn_christmas_00_04: 'Oh, and you can have this, too.',
    thanks_inn_christmas_00_01:
        "I'll be heading back now. Merry Christmas!",
    dialog_inn_chat:
        'Whichever Yeti finds the biggest icicle becomes their leader. An odd bunch to be sure!',
    dialog_inn_chat_02:
        'That mysterious floating island... No one has ever been there, as far as I can tell.',
    dialog_inn_chat_03:
        "There's been a shadowy figure staying at my inn lately. Didn't quite catch her name though.",
    dialog_inn_chat_04:
        'The key to attracting customers is having a big shop! And displaying tons of items on racks!',
    dialog_inn_chat_05:
        "Those Champions are in a league of their own. They don't even need equipment!",
    dialog_inn_chat_06:
        "I can hear Ismael singing while he works, right across the street. He's not half bad.",
    dialog_inn_chat_07:
        "I used to be a Barbarian back in the days, you know? I'd wager I still have my old axe stored somewhere...",
    dialog_inn_chat_08:
        "That fairy Maylee has an impressive coin collection. Like a carefully organized dragon's hoard!",
    dialog_inn_chat_09:
        'Chronos Ruins? Heard from my nana that it used to be the capital of a great empire... then poof! Vanished overnight!',
    dialog_inn_chat_10:
        "Everyone's been in uproar about a Lost City of Gold lately. Have you ever heard of it?",
    dialog_inn_bounty_craft:
        "I loved these back during my adventuring days. How about crafting a few for old times' sake?",
    dialog_inn_bounty_slay:
        "I put a big bet that you could handle it. Don't back out on me!",
    dialog_inn_bounty_quest:
        'The Inn is a bit packed with Heroes at the moment. Can you do me a favor and organize a few expeditions?',
    dialog_inn_bounty_surcharge:
        'I have a few patrons with more coin than sense. How about I send them your way?',
    dialog_inn_bounty_discount:
        "You offer my patrons a discount, and I send them your way! How's that deal sound?",
    dialog_mana_greetings:
        'The Merchant arrives... as foretold by prophecy.',
    dialog_mana_greetings_02: "The Merchant's presence is a welcome omen.",
    dialog_mana_greetings_03: 'An auspicious encounter.',
    dialog_mana_investgold:
        '...Welcome the open hand, and pay back in turn.',
    dialog_mana_investgems: 'An overwhelming aura of generosity...!',
    dialog_mana_investgems_02: 'The stars were right...!',
    dialog_mana_underupgrade:
        '...The leylines are currently being channeled. Please return later.',
    dialog_mana_underupgrade_02:
        'I foresee... an improved Ether Well in the near future...',
    dialog_mana_afterupgrade:
        'The flow of Ether has returned. Use it well.',
    dialog_mana_afterupgrade_02:
        "Ether now flows unimpeded towards the Merchant's abode.",
    visit_mana_christmas_00_01:
        'The spirit of giving looms ever nearer, warming the hearts of men and elves alike.',
    visit_mana_christmas_00_02:
        'I am thus compelled, if the Merchant allows.',
    thanks_mana_christmas_00_01:
        "I foresee the year's turn, bearing wealth and opportunity for the Merchant.",
    visit_mana_00_01: 'Fate has led me here, to a Merchant in need...',
    visit_mana_01_01:
        'The Ether stirs, seeking shelter among your bins. Do you accept?',
    thanks_mana_00_01: 'The threads of fate remain whole.',
    thanks_mana_01_01:
        'The future remains uncertain, but we shall meet again... This I know.',
    dialog_mana_chat: 'To see beyond the veil... is a boon and curse both.',
    dialog_mana_chat_02:
        'The Enchanter... Powerful magic surrounds him, yet not entirely his own.',
    dialog_mana_chat_03:
        'He Who Digs tempts fate with every strike of the pickaxe... The leylines below must not be tampered with.',
    dialog_mana_chat_04:
        'Fate has much in store for the Young Knight. His resolve will be tested.',
    dialog_mana_chat_05:
        'She Who Stalks... When one is driven by revenge, care must be taken to not lose oneself.',
    dialog_mana_chat_06: '...The Ether Well is not open to tourists.',
    dialog_mana_chat_07:
        'Turn to the sky and ask the stars for guidance... else shift your gaze inward and seek wisdom within.',
    dialog_mana_chat_08:
        "I am but a herald for fate's will... A beacon amidst the tumultuous torrent of what may.",
    dialog_mana_chat_09:
        "Let not the prophecies cloud your mind. Fate's hand will see you to your destination.",
    dialog_mana_chat_10:
        'An empire toying with the threads of time itself... Pride to ashes, hubris to ruins.',
    dialog_mana_chat_11:
        'The Lost City of Gold... An ephemeral paradox at the twilight of worlds.',
    dialog_mana_bounty_craft:
        'Abundant wealth awaits... should the Merchant grasp this opportunity.',
    dialog_mana_bounty_slay:
        "A tyrant's fall is foretold. With fate at your side, proceed with caution still.",
    dialog_mana_bounty_quest:
        'Troves of natural wonders lie yonder, awaiting one with brave heart and deft hands.',
    dialog_mana_bounty_surcharge:
        'Untold potential rests within the mundane, aptly appraised by the trained eye.',
    dialog_mana_bounty_discount:
        'Fate shall see the open hand rewarded in due time.',
    dialog_gems_greetings: "It's the trader! Hi!<sprite tint=1 name=Note1>",
    dialog_gems_greetings_02: 'Hi! Did you bring coins!?',
    dialog_gems_greetings_03: "I'm here!<sprite tint=1 name=Note2>",
    dialog_gems_investgold: 'Human currency!<sprite tint=1 name=Note1>',
    dialog_gems_investgold_02:
        'Wow! So many coins!<sprite tint=1 name=Note2>',
    dialog_gems_investgems:
        'Gems are my favorite!<sprite tint=1 name=Note1> How did you know?',
    dialog_gems_investgems_02:
        'Thanks!<sprite tint=1 name=Note1><sprite tint=1 name=Note2>',
    dialog_gems_underupgrade:
        'Busy busy busy! Come back later!<sprite tint=1 name=Note2>',
    dialog_gems_afterupgrade:
        "I've got so many jewels now!<sprite tint=1 name=Note1> Here, have some!<sprite tint=1 name=Note2>",
    visit_gems_00_01:
        'Hello hello!<sprite tint=1 name=Note2> I brought you some jewels!<sprite tint=1 name=Note1>',
    visit_gems_00_02:
        '...But Katarina told me to always trade them for coins. Do you have any?<sprite tint=1 name=Note1>',
    visit_gems_01_01:
        'Human trader! I heard Juniper brings you regular herb deliveries...',
    visit_gems_01_02:
        'Me too!<sprite tint=1 name=Note1> Buy my jewels!<sprite tint=1 name=Note2>',
    thanks_gems_00_01: 'Thanks! Trading is fun!<sprite tint=1 name=Note1>',
    thanks_gems_01_01:
        'You had all these coins!? You should share more often!<sprite tint=1 name=Note2>',
    visit_gems_christmas_00_01:
        'Hi shopkeeper! Happy birthday!<sprite tint=1 name=Note2>',
    visit_gems_christmas_00_02:
        "What, Christmas? It's a party, right? What's the difference?",
    visit_gems_christmas_00_03:
        "I thought so! Here's your birthday present!<sprite tint=1 name=Note1>",
    thanks_gems_christmas_00_01:
        "Woohoo! I can't wait for my birthday!<sprite tint=1 name=Note1>",
    dialog_gems_chat:
        '"Yo, what\'s up"!<sprite tint=1 name=Note1>Tamas taught me this human greeting!<sprite tint=1 name=Note2>',
    dialog_gems_chat_02:
        'Humans have such fascinating settlements! Bustling with activity!<sprite tint=1 name=Note1>',
    dialog_gems_chat_03:
        'Humans always greet each other and ask how they are doing, yet always answer the same thing... Why?',
    dialog_gems_chat_04:
        'Is that the sea? It goes on forever!<sprite tint=1 name=Note2>',
    dialog_gems_chat_05:
        'Using shiny coins to trade for objects... Does everyone in the city play this game?<sprite tint=1 name=Note2>',
    dialog_gems_chat_06:
        "There's a silly guy prancing about with a golden crown!<sprite tint=1 name=Note1>Isn't he funny?<sprite tint=1 name=Note2>",
    dialog_gems_chat_07:
        'I saw humans wearing iron clothes! How do they do it!?',
    dialog_gems_chat_08:
        'Do humans also celebrate Trick Tuesday...? What about Fun Friday?',
    dialog_gems_chat_09:
        "Isn't it tiring, walking around everywhere?<sprite tint=1 name=Note2>",
    dialog_gems_chat_10:
        'Playing king sounds fun! When is it my turn?<sprite tint=1 name=Note1>',
    dialog_gems_bounty_craft:
        "Wow!<sprite tint=1 name=Note1> I've always wanted to see one of these up close!<sprite tint=1 name=Note2>",
    dialog_gems_bounty_slay:
        'Woohoo! Field trip! <sprite tint=1 name=Note1>',
    dialog_gems_bounty_quest:
        'My fairy senses are tingling! There are some resources hidden nearby!<sprite tint=1 name=Note1>',
    dialog_gems_bounty_surcharge:
        'Is this how you get human currency?<sprite tint=1 name=Note2> You make it look easy!',
    dialog_gems_bounty_discount:
        'Playing merchant sounds fun! Teach me all about it!<sprite tint=1 name=Note2>',
    dialog_engineer_greetings: 'Looking to blow something up?',
    dialog_engineer_unhiredgreetings: 'Changed your mind, shopkeeper?',
    dialog_engineer_unhiredgreetings_02:
        'The power of <c1>Guns</c1> and <c1>Crossbows</c1> is within your reach!',
    dialog_engineer_unhiredgreetings_03:
        "And you can't forget my tinkering, making those items <c1>break less</c1>!",
    dialog_engineer_hirequestion: "You can't say no to science!",
    trial_engineer_01: "Shopkeeper! I've been looking for you!",
    trial_engineer_02:
        "I'm feeling generous today, so I'm offering my services for free for a time.",
    trial_engineer_03:
        'In other words, you can craft <c1>Guns</c1> and <c1>Crossbows</c1>!',
    trial_engineer_04:
        "I'll even tinker with your items, so you'll benefit from a <c1>significant Break Chance reduction</c1> too.",
    trial_engineer_05:
        'No need to thank me, I do it all in the name of science!',
    dialog_engineer_investgold: "I'll take it.",
    dialog_engineer_investgems: 'Ooh, shiny!',
    dialog_engineer_underupgrade:
        "I'm tinkering around the lab right now. Don't touch anything funny-looking.",
    dialog_engineer_afterupgrade:
        "Lab's back in order. Fireproof, this time.",
    visit_engineer_quality_00_01:
        'Feast your eyes on THIS weapon, shopkeeper! Brimming with potential!',
    visit_engineer_quality_00_02:
        "I'd say it's ready for some field testing, don't you think?",
    visit_engineer_bulk_00_01:
        'Hey, I got a bunch of prototypes cluttering up my lab. You want them?',
    thanks_engineer_00_01: 'Well then, back to the drawing board!',
    visit_engineer_quality_christmas_00_01:
        'So about that field testing...',
    visit_engineer_quality_christmas_00_02:
        "What, holidays? Science doesn't take days off.",
    visit_engineer_quality_christmas_00_03:
        'Argh fine, you can have it for free!',
    visit_engineer_bulk_christmas_00_01: 'So about that field testing...',
    visit_engineer_bulk_christmas_00_02:
        "What, holidays? Science doesn't take days off.",
    visit_engineer_bulk_christmas_00_03:
        'Argh fine, you can have it for free!',
    thanks_engineer_christmas_00_01:
        'Happy holidays and all that. Back to the lab!',
    dialog_engineer_chat:
        '...One pinch of sulfur and... Kaboom! Works every time.',
    dialog_engineer_chat_02: 'You here to file a noise complaint?',
    dialog_engineer_chat_03:
        'The only difference between messing around and doing science is writing it down.',
    dialog_engineer_chat_04:
        "So how's my tinkering? Whenever an item doesn't break, it's probably thanks to me!",
    dialog_engineer_chat_05:
        '"Kaboom" or "Kabam"? I\'m more of a "Kabam" girl, myself.',
    dialog_engineer_chat_06:
        'The Academy, huh? Bunch of nerds! You learn by doing, not reading!',
    dialog_engineer_chat_07: 'A gun... that fires guns...? Genius!',
    dialog_engineer_chat_08:
        "My guns are 35% safe! You won't find better odds anywhere else!",
    dialog_engineer_chat_09:
        'Got too much ammo lying around? Try out the Prototype Gatling!',
    dialog_engineer_chat_10:
        'A flying island? 10 billion percent scientifically impossible. Trust me, I tried.',
    dialog_engineer_bounty_craft:
        "I've thought of potential improvements for this blueprint. Can you try it out?",
    dialog_engineer_bounty_slay:
        "It's high time for some field testing! I know a great spot with plenty of live targets...",
    dialog_engineer_bounty_quest:
        "It's high time for some field testing! I know a great spot with plenty of live targets...",
    dialog_engineer_bounty_surcharge:
        "Go right ahead, shopkeeper. I'll be taking notes.",
    dialog_engineer_bounty_discount:
        "Don't discount any guns though, my babies are worth full price!",
    dialog_academy_greetings:
        'Yes? Please raise your hand before speaking.',
    dialog_academy_greetings_02:
        'Please take a seat, the bell has already rung.',
    dialog_academy_unhiredgreetings: 'Have you reconsidered my offer?',
    dialog_academy_unhiredgreetings_02:
        'With my advanced lessons, you will benefit from <c1>bonus experience</c1>.',
    dialog_academy_unhiredgreetings_03:
        'And, you could craft <c1>powerful Wands</c1>, or <c1>Runestones</c1> for enchantments!',
    dialog_academy_unhiredgreetings_04:
        'Are you ready to take enchanting to the next level?',
    trial_academy_01: 'Good day, shopkeeper! I have good news!',
    trial_academy_02:
        'The Academy is offering free services to shopkeepers for a limited time...',
    trial_academy_03:
        "That means you'll be able to craft <c1>Wands</c1>, and even <c1>Runestones</c1>!",
    trial_academy_04:
        "You will also benefit from my <c1>multicraft chance with all enchantments</c1>! Isn't it great?",
    trial_academy_05: "I'm looking forward to working together!",
    dialog_academy_investgold: "Don't count on this to raise your marks!",
    dialog_academy_investgold_02: 'Oh! Let me get my abacus!',
    dialog_academy_investgold_03:
        'An investment in education is an investment in the future!',
    dialog_academy_investgems: 'Hmm... A+.',
    dialog_academy_underupgrade:
        'Class is already in session. Please return later!',
    dialog_academy_afterupgrade:
        'School break is over! Did you enjoy your vacation?',
    visit_academy_00_01:
        "Good day, shopkeeper. I've come to collect your overdue homework...",
    visit_academy_00_02: 'Just kidding! Here, I have a few items for you!',
    visit_academy_01_01:
        "Shopkeeper, I'm just done studying this outstanding item.",
    visit_academy_01_02:
        'The Academy has no use for it anymore, would you like to buy it?',
    thanks_academy_00_01: "Thanks! And don't forget to do your homework!",
    thanks_academy_01_01: 'Thank you, on behalf of the Academy.',
    visit_academy_christmas_00_01:
        'Happy holidays, shopkeeper! Are you enjoying your school break?',
    visit_academy_christmas_00_02:
        "I brought gifts for my favorite students. Here's yours!",
    thanks_academy_christmas_00_01:
        "It's flu season, so remember to wash your hands regularly!",
    dialog_academy_chat: "My hair doesn't look like Elvenwood... does it?",
    dialog_academy_chat_02:
        'I teach many courses here at the Academy. Conjuration, runecrafting... even poetry!',
    dialog_academy_chat_03:
        'This Lilu... She attends every single class without fail. Even extracurriculars!',
    dialog_academy_chat_04:
        'Have you ever considered teaching economics at the academy? You seem to be an expert on the subject.',
    dialog_academy_chat_05:
        "Wands can be a capricious lot. They won't work for a wizard they don't like!",
    dialog_academy_chat_06:
        "As braggart as he may be, Allan's grades certainly aren't anything to write home about.",
    dialog_academy_chat_07:
        "Grimar's lessons are something else! Though he can be a bit unreliable, at times...",
    dialog_academy_chat_08:
        "I'm working on a few theses at the moment. My 12th PhD is right around the corner!",
    dialog_academy_chat_09:
        'I wrote a book about curses, the "Miscreant\'s Manual to Maledictions". Perhaps you\'ve heard of it?',
    dialog_academy_chat_10:
        'The Academy offers a joint tuition program with the Training Hall. Heroes need to be smart, too!',
    dialog_academy_chat_11:
        'Enchanting items is sure to make them more valuable. Much stronger, too!',
    dialog_academy_chat_12:
        'Fairies never venture outside of their forests... Why did Maylee leave hers?',
    dialog_academy_chat_13:
        'Grandma Yolanda, was it? She must be very powerful to create life with magic.',
    dialog_academy_bounty_craft:
        "Here is today's exam. It should be no problem if you studied the course.",
    dialog_academy_bounty_slay:
        "I'm organizing a few field trips with my class. Could I ask for an escort?",
    dialog_academy_bounty_quest:
        'This is something I absolutely must study in its natural habitat!',
    dialog_academy_bounty_surcharge:
        "I'm looking for an expert in customer interactions for the sake of peer reviewing.",
    dialog_academy_bounty_discount:
        "I've got an interesting microeconomics theory I'd like put to the test...",
    dialog_sundragon_greetings:
        'My dragon senses are tingling... Is that gold coins I hear?',
    dialog_sundragon_greetings_02:
        'The Sol Tower welcomes any child of man.',
    dialog_sundragon_greetings_03: 'What troubles you, child?',
    dialog_sundragon_greetings_04: 'Speak. The Sun Dragon is listening.',
    dialog_sundragon_investgold: 'Gold coins, is it? That will do!',
    dialog_sundragon_investgold_02: 'My thanks.',
    dialog_sundragon_investgems: 'Balance is restored.',
    dialog_sundragon_investgems_02:
        "I'm on a very strict diet, but thank you!",
    dialog_sundragon_underupgrade:
        'My apologies, the Sol Tower is currently gathering sunlight.',
    dialog_sundragon_afterupgrade:
        'The Sol Tower has undergone some changes, as all things should.',
    visit_sundragon_bulk_00_01:
        'Greetings, child of man. What do you think of this assortment of items?',
    visit_sundragon_bulk_00_02:
        'I believe they hold great Fusion potential!',
    visit_sundragon_bulk_christmas_00_01: "Brrr! It's chilly out here!",
    visit_sundragon_bulk_christmas_00_02:
        'Nevertheless, I wished to bring you these! And a merry... what was it again?',
    thanks_sundragon_00_01: 'Praise the sun.',
    thanks_sundragon_01_01: 'My thanks.',
    thanks_sundragon_christmas_00_01:
        'Ah, Christmas! I am not yet used to these human customs...',
    dialog_sundragon_chat: "Sunbathing is simply the best, isn't it?",
    dialog_sundragon_chat_02:
        'I feel great Feng shui emanating from the town square. Could it be your shop?',
    dialog_sundragon_chat_03:
        "I must commend Julia for these fine clothes. Dragon physiology isn't easy to tailor for.",
    dialog_sundragon_chat_04: 'Praise the sun.',
    dialog_sundragon_chat_05:
        'Gold this, money that... Human life would be easier if you just slept on your hoard instead of spending it!',
    dialog_sundragon_chat_06:
        'Mundra barely understands fusion to begin with, but her wild experimentation has led to some stunning discoveries.',
    dialog_sundragon_chat_07:
        "Mundra's blessing is as powerful as the full moon itself. It's not easy, but you should try getting on her good side.",
    dialog_sundragon_chat_08:
        "I'm not certain where Mundra's distaste for bosses came from, but it's strong enough to seep into the equipment she crafts!",
    dialog_sundragon_chat_09:
        "Though she doesn't act like it, Mundra is the eldest sibling. Only by a few thousand years, though.",
    dialog_sundragon_chat_10:
        "How do you like the Synthesis Vessel? There aren't too many like it in the world.",
    dialog_sundragon_chat_11:
        "A magical book that records information based on the items it receives... It sounds nice, doesn't it?",
    dialog_sundragon_bounty_craft:
        'I could use some fine items for the Synthesis Vessel...',
    dialog_sundragon_bounty_slay:
        'A great unbalance plagues this ecosystem...',
    dialog_sundragon_bounty_quest:
        'Child of man, your greatest trial awaits.',
    dialog_sundragon_bounty_surcharge: 'The humble merchant taketh.',
    dialog_sundragon_bounty_discount: 'The humble merchant giveth.',
    dialog_moondragon_greetings: 'Who dares rouse me from my slumber?',
    dialog_moondragon_greetings_02: 'Tribute first, chit-chat later.',
    dialog_moondragon_greetings_03: 'What brings you here, warmblood?',
    dialog_moondragon_greetings_04:
        'You walk into my domain. Mind your step.',
    dialog_moondragon_investgold: 'Tribute accepted.',
    dialog_moondragon_investgold_02: 'A suitable offering.',
    dialog_moondragon_investgems:
        'Into the hoard it goes. Might have a taste later.',
    dialog_moondragon_investgems_02:
        'These certainly have a nice shine to them.',
    dialog_moondragon_underupgrade:
        'The Luna Tower is gathering lunar energy right now. No visitors allowed.',
    dialog_moondragon_afterupgrade:
        'Wipe your feet, I just cleaned up around here.',
    visit_moondragon_00_01: 'Hey, warmblood.',
    visit_moondragon_00_02:
        "I've some extra Moonstones right here. I'm expecting tribute in exchange.",
    visit_moondragon_christmas_00_01: 'Hey, warmblood.',
    visit_moondragon_christmas_00_02:
        "I've some extra Moonstones right- huh? Holy night?",
    visit_moondragon_christmas_00_03:
        'I like the sound of that. You can have these for free!',
    thanks_moondragon_00_01: 'Good choice.',
    thanks_moondragon_01_01: 'This tribute will do.',
    thanks_moondragon_christmas_00_01: 'Stay warm, warmblood.',
    dialog_moondragon_chat:
        'Have you considered bothering my dumb brother instead?',
    dialog_moondragon_chat_02:
        'Moonstruck by my presence, are you? I get that a lot.',
    dialog_moondragon_chat_03:
        'The night belongs to the moon dragons. Remember that.',
    dialog_moondragon_chat_04:
        'Your scales are looking a bit drab. Have you tried eating gems?',
    dialog_moondragon_chat_05:
        "The moon offers power you can only dream of. Just... don't ask me about basic fusion.",
    dialog_moondragon_chat_06:
        'Sondra is always stuck in his old ways. True progress is made by trying, and SOMETIMES writing it down.',
    dialog_moondragon_chat_07: 'I hate bosses.',
    dialog_moondragon_chat_08:
        'I model my crafts around the most beautiful things in the world. My claws will touch nothing less.',
    dialog_moondragon_bounty_craft: 'The great Mundra requires tribute.',
    dialog_moondragon_bounty_slay:
        "I don't like these guys. Teach them a lesson.",
    dialog_moondragon_bounty_quest:
        "Give the dungeon boss a good beating while you're there.",
    dialog_moondragon_bounty_surcharge: 'I call this one the Mundra tax.',
    dialog_moondragon_bounty_discount: "This is more of Sondra's style.",
    dialog_elven_greetings: 'Welcome. Please wipe your feet.',
    dialog_elven_greetings_02:
        'Close the door behind you. The little ones might sneak out.',
    dialog_elven_greetings_03:
        'Come on in, shopkeeper. Are you eating well?',
    dialog_elven_investgold: "Oh, you shouldn't have!",
    dialog_elven_investgold_02: "It's very welcome.",
    dialog_elven_investgems: 'Oh my.',
    dialog_elven_investgems_02: 'Wonderful.',
    dialog_elven_underupgrade:
        "Ah, I'm sorry. I have my hands full with all these Familiars running about at the moment.",
    dialog_elven_afterupgrade:
        'Welcome back! The Familiars have a much bigger playground now.',
    visit_elven_quality_00_01:
        "This one came out quite nicely. I'm sure you will make good use of it.",
    visit_elven_bulk_00_01:
        "I'm afraid I have too much on my hands currently. Can you take these?",
    visit_elven_quality_christmas_00_01:
        'Happy holidays, shopkeeper. Have a little something from a doting Grandma!',
    visit_elven_bulk_christmas_00_01:
        'Happy holidays, shopkeeper. Have a little something from a doting Grandma!',
    thanks_elven_00_01: "I trust you'll take good care of them.",
    thanks_elven_christmas_00_01: 'Happy holidays to you, shopkeeper.',
    dialog_elven_chat: 'Are you taking good care of my babies?',
    dialog_elven_chat_02: "Remember: adopt, don't shop!",
    dialog_elven_chat_03:
        "Monster is such a pejorative term. They're creatures, just like us.",
    dialog_elven_chat_04:
        'Donovan is such a petulant child. He should enjoy life, for a change.',
    dialog_elven_chat_05:
        'This place can be a bit noisy at times. Familiars can be quite energetic.',
    dialog_elven_chat_06:
        'Heroes are only allowed Familiars for home ownership. I only trust Champions to see them safely through quests.',
    dialog_elven_chat_07:
        'Familiar eggs will only hatch when handled by someone they can trust. Their instinct is quite something.',
    dialog_elven_chat_08:
        'Troublins are such little troublemakers. Never take your eyes off them.',
    dialog_elven_chat_09:
        "I don't play favorites with my Familiars. Except the Yeti.",
    dialog_elven_bounty_craft: 'Grandma Yolanda has a request for you.',
    dialog_elven_bounty_slay:
        "The big ones aren't cute. You know what that means.",
    dialog_elven_bounty_quest: 'Grandma Yolanda has a request for you.',
    dialog_elven_bounty_surcharge: 'You deserve an allowance.',
    dialog_elven_bounty_discount: 'Grandma Yolanda has a request for you.',
    visit_elder_00_01: 'Ah, shopkeeper. Stay a while, and listen.',
    visit_elder_01_01:
        "Good day, shopkeeper. Would you care to listen to an old man's story?",
    visit_elder_02_01:
        "It is a beautiful day, isn't it? How fares business today?",
    thanks_elder_00_01:
        'Oh my, how time flies! I must be going. Thank you for humoring this old man.',
    thanks_elder_01_01:
        "And that's how Gorza made it to- Oh! It seems I've lost track of time.",
    thanks_elder_01_02:
        'That was a nice little chat. See you soon, I hope.',
    gift_00_01:
        "Would you look at that! A nice little gift... but your name's not on it!",
    gift_00_02:
        "Which of your guildmates should receive it? It's OK to play favorites!",
    gift_01_01: 'I have a lonely gift here, seeking a recipient...',
    gift_01_02:
        "You can't open it yourself though. You'll need to send it to a guildmate!",
    gift_02_01: "There's nothing quite like the act of giving, is there?",
    gift_02_02:
        'A spark of joy from a simple act of kindness... What goes around comes around!',
    gift_02_03:
        '...Though Katarina generally disagrees with that last point.',
    gift_03_01: 'You seem to be one with many friends, shopkeeper!',
    gift_03_02:
        'How about sending over a gift to foster that friendship further?',
    visit_king_00_01:
        'Ah...! It is refreshing to walk amongst the common folks, sometimes.',
    visit_king_00_02:
        'I shall buy this as a souvenir. Name a price, you shall have it.',
    visit_king_01_01:
        'Shopkeeper, I simply MUST have this item. At any cost!',
    visit_king_02_01: 'Hm, yes. Splendid shop.',
    visit_king_02_02: 'I shall grace it with a purchase. Keep the change.',
    visit_king_03_01:
        'I, glorious king of these lands, am a renowned trendsetter!',
    visit_king_03_02:
        'I thus proclaim this item worthy of my attention... and my money!',
    visit_king_04_01:
        'The fine craftsmanship of this item has caught my eye!',
    visit_king_04_02:
        'Sell it to me, and I shall have my men shower you with gold.',
    visit_king_05_01:
        "I've found something absolutely stunning! An equal match for my splendor!",
    visit_king_05_02:
        "I can't be bothered counting my gold. Take whatever you wish!",
    visit_king_06_01:
        'My spies have informed me of the existence of an exceptional item... in this very shop!',
    visit_king_06_02:
        'Such a powerful item must not fall in hands of common folks. I must have it!',
    visit_king_07_01:
        'Noble shopkeeper, you are one of my most loyal servants, are you not?',
    visit_king_07_02:
        "...Then please don't tell Sia about this purchase. What is the price of your silence?",
    visit_king_08_01:
        'Sia has been teaching me about "budgeting" lately. A truly fascinating concept!',
    visit_king_08_02:
        'I believe this would match my "budget"... How much was it again?',
    thanks_king_00_01:
        'Splendid! I am honored to have such reliable subjects.',
    thanks_king_01_01: "Excellent. Here's a blank check!",
    thanks_king_02_01: "Deal! Don't go spending it all in one place, now.",
    dialog_king_bounty_craft:
        'My kingdom absolutely needs more of these wonderful items!',
    dialog_king_bounty_upgrade:
        'I shall grace your shop with my presence shortly. Ensure my exceptional standards are met!',
    dialog_king_bounty_rushitem:
        'Waiting around is for plebeians! Your King demands immediate action!',
    dialog_king_bounty_rushupgrade:
        'It is a delight to witness cooperation among my subjects!',
    dialog_king_bounty_chest:
        "Untold riches within arm's reach! What are you waiting for?",
    visit_argon_backup_00_01:
        'Shopkeeper! I am in need of a few items! Can you provide?',
    visit_argon_ws_00_01: 'Shopkeeper! I have a favor to ask!',
    visit_argon_ws_00_02:
        'I need a few backup swords for training. Strong ones! And heroic-looking, if you can!',
    visit_argon_xs_00_01: 'Good day to you, shopkeeper!',
    visit_argon_xs_00_02:
        'The Training Hall is in need of a few shields for rookie Heroes. Can you provide?',
    visit_argon_xs_halloween_00_01:
        "Woah! What's with all these customers?",
    visit_argon_xs_halloween_00_02:
        "Shopkeeper! If you're ever having zombie troubles... you know who to call!",
    visit_argon_wa_00_01: 'Hello again, shopkeeper! How are you doing?',
    visit_argon_wa_00_02:
        'Naya sent me on a glorious quest; seek out mighty weapons to decorate the tavern with!',
    visit_argon_wa_00_03:
        'As a knight in training, I must help people in need! Can you help me in turn?',
    visit_argon_wa_halloween_00_01:
        "You've gathered yourself a strange clientele, shopkeeper!",
    visit_argon_wa_halloween_00_02:
        'These guys seem oddly obsessed with...  brains. Are you really ok with that?',
    visit_argon_ah_00_01: 'Shopkeeper! Do you have a moment?',
    visit_argon_ah_00_02:
        "I'm dressing up some training dummies for special training! Do you have any spare armor?",
    visit_argon_bh_00_01:
        "Good day, shopkeeper! I'm planning an expedition to the Whispering Bog with some rookies.",
    visit_argon_bh_00_02:
        "We'll need a few clean pair of boots to trudge through that mess!",
    visit_argon_hh_00_01: 'Shopkeeper, I need your help!',
    visit_argon_hh_00_02:
        "I'd like to donate a few helmets to the orphans at the temple. Kids love playing hero!",
    visit_argon_hh_halloween_00_01: 'Happy Halloween, shopkeeper!',
    visit_argon_hh_halloween_00_02:
        "I'd like a few helmets please. There's no better protection against zombies!",
    visit_argon_hh_00_03:
        "It's also educative! And uh, safer than the axes from last time...",
    visit_argon_gh_00_01:
        'Noble shopkeeper! I am on a glorious errand for Brohm!',
    visit_argon_gh_00_02:
        "He'd like some padded smelting gloves. Extra large, too!",
    thanks_argon_00_01: 'I knew I could count on you! Thank you!',
    thanks_argon_01_01:
        'Thank you, shopkeeper! Evil beware, Argon is coming!',
    thanks_argon_02_01:
        'Ah ha! Quake in your boots, evildoers! Argon is coming!',
    thanks_argon_03_01: 'Quest complete! See you later!',
    visit_lilu_backup_00_01:
        "It's me again! I need a few items today, let me check my shopping list...",
    visit_lilu_wt_00_01: 'Hi! Working hard, I see!',
    visit_lilu_wt_00_02:
        "I'm not sure how, but Master Grimar broke another staff...",
    visit_lilu_wt_00_03:
        'Think you can make him a new one? Or several? Make them sturdy!',
    visit_lilu_wt_halloween_00_01:
        'S-shopkeeper... Why are there zombies everywhere!?',
    visit_lilu_wt_halloween_00_02: 'Quick, hand me a staff! Several!',
    visit_lilu_ww_00_01: 'Shopkeeper, I have an important order.',
    visit_lilu_ww_00_02:
        'Do you have any wands? My staff is a bit too unwieldy for potion brewing...',
    visit_lilu_al_00_01:
        "Shopkeeper, I'm planning an excursion to the Barren Wasteland.",
    visit_lilu_al_00_02:
        'My usual wear is a bit stuffy for that hot weather though. Do you have something lighter?',
    visit_lilu_hl_00_01: 'Okay, hear me out, I have an odd request!',
    visit_lilu_hl_00_02:
        'My owl Pollux likes hiding in my hat, but I also need to, you know, WEAR it.',
    visit_lilu_hl_00_03: 'Can you sell us a couple of hats for his sake?',
    visit_lilu_uh_00_01: 'Shopkeeper! I need help with homework!',
    visit_lilu_uh_00_02:
        "I'm practicing a new potion recipe but I'm short a few ingredients...",
    visit_lilu_uh_halloween_00_01: 'BOO!',
    visit_lilu_uh_halloween_00_02:
        "...Not good enough, huh? You're a tough one, shopkeeper!",
    visit_lilu_us_00_01: 'Hi! Do you have a moment?',
    visit_lilu_us_00_02:
        'I need to add a few spells to my repertoire, but Grimar has been sleeping all day...',
    visit_lilu_us_halloween_00_01: 'Trick or treat!',
    visit_lilu_us_halloween_00_02:
        'Still no candies, huh? A few spells will do, then!',
    thanks_lilu_00_01: 'Thanks! See you soon!',
    thanks_lilu_01_01: "Cool! I'll be back!",
    thanks_lilu_02_01: "Five star service! I'll be sure to leave a review!",
    visit_sia_backup_00_01:
        "Ah, shopkeeper. There's a little something I've been looking for...",
    visit_sia_wd_00_01:
        'Greetings, shopkeeper. It is good to see you again.',
    visit_sia_wd_00_02:
        "I need a few... tools, for the good of the kingdom. Don't worry, it's all perfectly innocent!",
    visit_sia_wd_halloween_00_01: '...Serving undead customers?',
    visit_sia_wd_halloween_00_02:
        'Shopkeeper, I trust that you are aware of the sanitary implications?',
    visit_sia_wc_00_01:
        'Sweetheart, I\'m looking for something to get rid of "pests".',
    visit_sia_wc_00_02:
        'Preferably in a silent and clean fashion. I trust you have what I need?',
    visit_sia_wg_00_01: 'Dear shopkeeper, has my delivery arrived yet?',
    visit_sia_wg_00_02:
        '...I would like it wrapped in nondescript packaging, if possible.',
    visit_sia_bl_00_01: "Sweetheart, I'm in a bit of a predicament...",
    visit_sia_bl_00_02:
        'My shoes are, simply put, completely out of style! I trust you to remedy this, dear shopkeeper.',
    visit_sia_bl_halloween_00_01:
        'Good evening, shopkeeper. I would like the usual, please.',
    visit_sia_bl_halloween_00_02:
        '...Preferably something that has not been handled by zombies.',
    visit_sia_xa_00_01:
        'Hello again, sweetheart. I\'d like to add a little "je ne sais quoi" to my apparel.',
    visit_sia_xa_00_02: 'Something striking, dazzling, mesmerizing...',
    visit_sia_xr_00_01:
        'Hello again, sweetheart. I\'d like to add a little "je ne sais quoi" to my apparel.',
    visit_sia_xr_00_02: 'Something striking, dazzling, mesmerizing...',
    visit_sia_xr_halloween_00_01:
        "This imperishable night... It really wears you down, doesn't it?",
    visit_sia_xr_halloween_00_02:
        'Perhaps it is time for a vacation back to Kern...',
    thanks_sia_00_01:
        "Well, aren't you the reliable sort. I hope to see you again very soon...",
    thanks_sia_01_01: 'Till next we meet...',
    thanks_sia_02_01:
        "Oh, it's absolutely gorgeous! You have quite the eye for fashion.",
    visit_yami_backup_00_01:
        'Take this list. I need everything on it, right now.',
    visit_yami_wd_00_01: 'I need weapons. Fast.',
    visit_yami_wd_00_02: 'File off the serial numbers, for your own sake.',
    visit_yami_wb_00_01:
        "I've taken on a big contract. Range and discretion will be critical.",
    visit_yami_wb_00_02: 'Do not ask further questions.',
    visit_yami_wb_halloween_00_01: 'Hpmh. Zombies.',
    visit_yami_wb_halloween_00_02: 'The dead should know their place.',
    visit_yami_hm_00_01: 'I need a disguise. Something practical.',
    visit_yami_bl_00_01:
        'Keep your voice down. Act normal and bring me several pairs of shoes.',
    visit_yami_bl_00_02: "I'll need to disguise my footprints.",
    visit_yami_bl_halloween_00_01: '...This cursed night suits me.',
    visit_yami_bl_halloween_00_02: "The night's embrace is a great ally.",
    visit_yami_uh_00_01:
        'I must acquire the following supplies with utmost secrecy. Do not fail me.',
    visit_yami_up_00_01:
        'I must acquire the following supplies with utmost secrecy. Do not fail me.',
    visit_yami_up_halloween_00_01:
        "...Trick or treat? Don't waste my time.",
    thanks_yami_00_01:
        'You will forget this transaction happened. Understand? Good.',
    thanks_yami_01_01: "You're useful. Stay that way.",
    thanks_yami_02_01: "Good. Don't get involved any further.",
    thanks_yami_03_01: 'I was never here.',
    visit_rudo_backup_00_01: 'Hey kiddo, ya still open? I need something.',
    visit_rudo_wm_00_01:
        "Hey kiddo. It's a good day to bash some skulls, don't ya think?",
    visit_rudo_wm_00_02:
        'Lemme see some maces, clubs, bludgeons... HEAVY stuff.',
    visit_rudo_wm_halloween_00_01: 'Trick or treat, kiddo.',
    visit_rudo_wm_halloween_00_02:
        'What, am I too old for trick-or-treating?',
    visit_rudo_wm_halloween_00_03: 'Gwah hah hah! Maybe, maybe.',
    visit_rudo_wp_00_01:
        "Hey, y'got any toothpicks? Don't shirk the quality, I want something sturdy.",
    visit_rudo_wa_00_01:
        'Shake a leg, shopkeeper. Fetch me the biggest axes you got.',
    visit_rudo_wa_halloween_00_01: 'Outta the way, rotten zombies!',
    visit_rudo_wa_halloween_00_02:
        "Hey, you're not gonna serve these guys before me, are ya?",
    visit_rudo_wa_01_01: 'Hey shopkeeper, help me out.',
    visit_rudo_wa_01_02:
        "I'm looking for a gift for my Gorza... Something strong and elegant.",
    visit_rudo_hh_00_01:
        'Hey kid, you ever headbutt your enemies into submission?',
    visit_rudo_hh_00_02: "I do. And I'll need more helmets to keep at it.",
    visit_rudo_bh_00_01:
        'Fear is a powerful weapon. When I walk, enemies scatter in panic.',
    visit_rudo_bh_00_02:
        'The secret is a loud, heavy footstep... And the reputation to go with it! Gwah hah hah!',
    visit_rudo_xa_00_01: "Hmph. Ya don't got any armor my size, don't ya?",
    visit_rudo_xa_00_02:
        'Fine. Get me one of those tiny charms. I need protection against magic.',
    visit_rudo_xa_halloween_00_01:
        "...The air outside is cursed. There's some stinkin' magic at work.",
    visit_rudo_xa_halloween_00_02: 'Hurry up and get me some talismans!',
    visit_rudo_up_00_01:
        'Look alive, shopkeeper. I need a drink. The strongest you got!',
    thanks_rudo_00_01: "That'll do. Thanks.",
    thanks_rudo_01_01: 'Hmph. Good enough.',
    thanks_rudo_02_01:
        "Kinda small, don't you think? Ah well, here's your coin.",
    thanks_rudo_03_01:
        "Great. Send me on a quest soon. I'm itching for a fight!",
    visit_polonia_backup_00_01:
        "To yer post, swabbie. Yer captain be needing somethin'.",
    visit_polonia_ws_00_01: 'Lootkeeper, me crew needs some new cutlasses.',
    visit_polonia_ws_00_02:
        "The sea's salty breeze be a cruel mistress to iron...",
    visit_polonia_ws_halloween_00_01:
        'There be struggling corpses about. Ye ok with this, lootkeeper?',
    visit_polonia_ws_halloween_00_02:
        "I'd like to keep me pretty brains to meself, aye.",
    visit_polonia_wg_00_01:
        'Ahoy, lootkeeper. Heard ye got some new cannons in stock.',
    visit_polonia_wg_00_02:
        'Show me what ye got, and I might just let you live! Har har!',
    visit_polonia_wg_halloween_00_01:
        'Arrr, there be doomed sea dogs invading the land!',
    visit_polonia_wg_halloween_00_02:
        'There be only one cure for these nasty biters. Ye got what I need?',
    visit_polonia_wt_00_01:
        "Arr matey, I'll be needing some of yer fancy staves.",
    visit_polonia_wt_00_02:
        "Aye, pirates can't use staves. But I do what I want because pirates be free!",
    visit_polonia_am_00_01:
        "To yer post, matey. I'll be needing some new armor.",
    visit_polonia_am_00_02:
        'Me measurements? Hah! In yer dreams, scallywag!',
    visit_polonia_gl_00_01:
        'Me trigger finger be itching, matey. Show me yer best gloves ye got in stock.',
    visit_polonia_xa_00_01:
        "Sea dogs be a superstitious bunch, lootkeeper. This Cap'n's no exception!",
    visit_polonia_xa_00_02:
        "Fetch me some good luck charms to keep the crew's minds at ease.",
    visit_polonia_xa_halloween_00_01:
        "Aye, lootkeeper. Them landlubbers in yer shop aren't looking too good.",
    visit_polonia_xa_halloween_00_02:
        'Yer crew got a bad case of scurvy, me thinks.',
    thanks_polonia_00_01: "Good work, matey. Here's ye dubloons.",
    thanks_polonia_01_01: "Yo ho ho! Ye'll be an old salt in no time.",
    thanks_polonia_02_01: "I'll have the ship sing a shanty in yer name.",
    thanks_polonia_03_01: 'Hmmm.... where could I bury this...',
    thanks_polonia_04_01: 'Hah! Booty worth dying for!',
    thanks_polonia_05_01: "Captain's share!",
    visit_donovan_backup_00_01:
        'Esteemed shopkeeper, I am in need of your fine crafts.',
    visit_donovan_wd_00_01:
        'Esteemed shopkeeper, I am an elf of great renown... and success breeds envy.',
    visit_donovan_wd_00_02:
        'As I am without my personal guards, my own safety is a concern.',
    visit_donovan_wd_00_03:
        'Please remedy this. My life is in your capable hands.',
    visit_donovan_wd_halloween_00_01:
        'Practicing necromancy out in the open, shopkeeper?',
    visit_donovan_wd_halloween_00_02:
        '...This is a strange kingdom indeed.',
    visit_donovan_ww_00_01:
        'Esteemed shopkeeper, do you have any wands in stock?',
    visit_donovan_ww_00_02:
        'It appears my own are... inadequate for the sake of handling local ingredients.',
    visit_donovan_ww_00_03:
        'Alchemy requires utmost precision. I will leave nothing to chance.',
    visit_donovan_al_00_01:
        'I would appreciate a new outfit, shopkeeper. Something befitting my rank, of course.',
    visit_donovan_al_00_02:
        'Comfort, style and ease of movement are a must!',
    visit_donovan_al_00_03:
        'Do not mind the cost. The Emperor scoffs at no expense.',
    visit_donovan_uh_00_01:
        'I find myself lacking a crucial ingredient in a most dire moment...',
    visit_donovan_uh_00_02:
        'Please make haste. My experiment is rather time sensitive.',
    visit_donovan_uh_halloween_00_01:
        "Whatever it is that ails your patrons, I'd rather not contract myself.",
    visit_donovan_uh_halloween_00_02:
        'Please make haste, I would like to take my leave as soon as possible.',
    visit_donovan_up_00_01:
        "I am in the mood to sample this Kingdom's alchemical potential.",
    visit_donovan_up_00_02:
        'Show me your best, shopkeeper. Your reputation is on the line.',
    visit_donovan_up_halloween_00_01:
        'I do wonder what got into those customer of yours, shopkeeper.',
    visit_donovan_up_halloween_00_02:
        "Since I'm here, perhaps I should take a sample...?",
    visit_donovan_xr_00_01:
        'It seems that my accessories are not very fashionable in this... remote kingdom.',
    visit_donovan_xr_00_02:
        'I entrust you with supplying me the latest trend, esteemed shopkeeper.',
    thanks_donovan_00_01: 'Many thanks, esteemed shopkeeper.',
    thanks_donovan_01_01: 'These will do nicely.',
    thanks_donovan_02_01:
        'The Emperor will see that you are duly rewarded.',
    thanks_donovan_03_01: 'Thank you, friend of Kern.',
    thanks_donovan_04_01:
        "Best service I've had in decades. Quite literally.",
    visit_hemma_backup_00_01:
        'We do enjoy your craftsmanship, shopkeeper. What do you have in stock?',
    visit_hemma_wt_00_01:
        'We do not enjoy telling you this... But sometimes we lose control over the undead we create. ',
    visit_hemma_wt_00_02:
        'And nothing works quite as well in those situations as a bludgeoning implement.',
    visit_hemma_wt_00_03: 'The sturdier the better.',
    visit_hemma_wt_halloween_00_01:
        'Impressive work with those zombies, shopkeeper...',
    visit_hemma_wt_halloween_00_02:
        'What do you mean, you did not create them? ',
    visit_hemma_ww_00_01:
        'Wands are not our favorite focus... but they do have their uses in various rituals.',
    visit_hemma_ww_00_02: 'Do you have anything worth our time in stock?',
    visit_hemma_al_00_01: 'The powers we wield are dangerous, shopkeeper.',
    visit_hemma_al_00_02:
        'Our last ritual did not go as planned, and the threads we usually wear did not quite make it.',
    visit_hemma_al_00_03: 'Anything else in stock for us?',
    visit_hemma_uh_00_01: 'Greetings, shopkeeper.',
    visit_hemma_uh_00_02:
        'We are in need of fine incense to burn under the moonlight. Could you provide?',
    visit_hemma_uh_halloween_00_01:
        'So really, to upkeep your undead patrons, you would only need...',
    visit_hemma_uh_halloween_00_02:
        '...What do you mean, do you not want them to stay undead?',
    visit_hemma_us_00_01:
        'We find ourselves in desperate need of magical renewal, shopkeeper.',
    visit_hemma_us_00_02: 'Something potent, it goes without saying.',
    visit_hemma_us_halloween_00_01:
        'The necromantic energy permeating this place...',
    visit_hemma_us_halloween_00_02:
        'We have been drawn to it. Interesting business strategy, we must admit.',
    visit_hemma_xa_00_01:
        "'Tis not unusual for us to dabble with powerful spirits. Sometimes too powerful.",
    visit_hemma_xa_00_02:
        'In these times, we are always glad we did not skimp on a good talisman.',
    thanks_hemma_00_01: "'Tis appreciated, shopkeeper.",
    thanks_hemma_01_01:
        'Consider us satisfied customers. Thank you, shopkeeper.',
    thanks_hemma_02_01: 'This will do nicely. Our thanks.',
    thanks_hemma_04_01: 'We thank you, keeper.',
    visit_ashley_backup_00_01: "Hey boss, I'm here for my order.",
    visit_ashley_ws_00_01: 'This is... the sword of legends...!',
    visit_ashley_ws_00_02:
        "Nah, just messing with ya. I'll take it, though.",
    visit_ashley_ws_01_01: "Hey boss, I'll take my usual order of swords.",
    visit_ashley_ws_01_02:
        "Gotta say, stuff back home didn't break as often.",
    visit_ashley_ws_02_01:
        "Not too many Katanas around here, huh? Can't blame you, though.",
    visit_ashley_ws_02_02:
        'I could hook you up with this Masamune guy someday, if you want.',
    visit_ashley_ws_halloween_00_01:
        "What's with all the Jiangshi about? They're looking pretty limber.",
    visit_ashley_ws_halloween_00_02:
        'Oh well, not my problem. Got any swords?',
    visit_ashley_wp_00_01: 'Hmph. Spears.',
    visit_ashley_wp_00_02:
        "Not sure why the Ashigaru like it so much. Guess I'll find out.",
    visit_ashley_wb_00_01: "I'm falling behind on my bow training.",
    visit_ashley_wb_00_02: "Ah... I'll just buy these and do it tomorrow.",
    visit_ashley_wg_00_01: 'Heard matchlocks were all the rage lately.',
    visit_ashley_wg_00_02: 'What do you got for me, boss?',
    visit_ashley_wg_halloween_00_01: "It's zombie season, huh?",
    visit_ashley_wg_halloween_00_02: 'You know what comes next...',
    visit_ashley_ah_00_01:
        "Boss, I'm looking for some armor to blend in better around here.",
    visit_ashley_ah_00_02:
        'It better not cost an arm though. Only got one to give.',
    visit_ashley_hh_00_01:
        "Think I'll take this one. Looking nice and sturdy.",
    visit_ashley_hh_00_02: "Can't skimp on head protection, yeah?",
    visit_ashley_hh_halloween_00_01: 'Hey boss. Is this helmet for sale?',
    visit_ashley_hh_halloween_00_02:
        "... What's with these customers? Are you having Youkai problems?",
    visit_ashley_gh_00_01:
        'Ah, been looking all over for a gauntlet like this.',
    visit_ashley_gh_00_02: 'Only need one, though. How about a discount?',
    thanks_ashley_00_01: 'Thanks, boss.',
    thanks_ashley_01_01: "Ah, yeah! That's what I'm talking about!",
    thanks_ashley_02_01: "Nice piece. I'll take it off you.",
    thanks_ashley_03_01: "Good trade. You won't see me complaining.",
    visit_atlab_aang_backup_00_01: "Hi shopkeeper! I've come for my order!",
    visit_atlab_aang_wt_00_01:
        "I'm interested in those staves... What kind of things can they do?",
    visit_atlab_aang_wt_00_02: "Magic, huh? It's worth a shot!",
    visit_atlab_aang_al_00_01: 'Shopkeeper! Do you have any robes?',
    visit_atlab_aang_al_00_02:
        'Yes! That one! ...Do you have it a few sizes smaller?',
    visit_atlab_aang_hl_00_01: 'You say these are wizard hats?',
    visit_atlab_aang_hl_00_02:
        'I don’t know what wizards are, but I sure do love their hats!',
    visit_atlab_aang_uh_00_01:
        'Hi! Do you have any flowers? ...for Katara...',
    visit_atlab_aang_uh_00_02: "N-no! It's not like that!",
    visit_atlab_aang_uh_01_01:
        "These herbs... I'd like to buy a treat for Appa!",
    visit_atlab_aang_uh_01_02:
        "They look kinda weird... but I'm sure he wouldn't eat it if it wasn't safe.",
    visit_atlab_aang_us_00_01:
        'Are these scrolls for sale? They look pretty interesting!',
    visit_atlab_aang_us_00_02: "Wait... I can't read this language!",
    visit_atlab_aang_xa_00_01: 'These nice amulets remind me of Gyatso...',
    visit_atlab_aang_xa_00_02: 'Can I buy them? Please?',
    thanks_atlab_aang_00_01:
        "I don't think my money is worth anything here... I hope these Champion Coins will do!",
    thanks_atlab_aang_01_01:
        "Sokka's gonna be really excited to see these!",
    thanks_atlab_aang_02_01: "Thanks! I'll share with my friends, too!",
    thanks_atlab_aang_03_01: 'Thanks, shopkeeper!',
    visit_atlab_sokka_backup_00_01:
        "Hey hey, I'm back. Do you have my stuff?",
    visit_atlab_sokka_wa_00_01:
        "Hgn..! Do people really fight with these? That's heavy!",
    visit_atlab_sokka_wa_00_02: 'Just how strong are these Heroes?!',
    visit_atlab_sokka_ws_00_01: 'Hmmm.... Not bad, not bad.',
    visit_atlab_sokka_ws_00_02:
        "Not as good as my Space Sword, but I'll take it.",
    visit_atlab_sokka_wd_00_01:
        'Nice daggers you got there. Do you have any machetes?',
    visit_atlab_sokka_wd_00_02: "A bit flashy, but I guess that'll do.",
    visit_atlab_sokka_wm_00_01:
        'Can these fancy bludgeons hold a candle to my trusty club?',
    visit_atlab_sokka_wm_00_02: "I'll see for myself!",
    visit_atlab_sokka_wp_00_01:
        "That's an impressive inventory of metal spears...",
    visit_atlab_sokka_wp_00_02:
        "You're not supplying the Fire Nation, are you?",
    visit_atlab_sokka_wb_01_01:
        "I'm usually more of a boomerang guy but...",
    visit_atlab_sokka_wb_01_02: "...Now's my chance to try one of these!",
    visit_atlab_sokka_wb_01_03: 'You do sell arrows, right?',
    visit_atlab_sokka_wt_00_01:
        "These staves look a bit like Aang's, don't they?",
    visit_atlab_sokka_wt_00_02:
        "I'm not a bender, but I’ve still got some smooth moves!",
    visit_atlab_sokka_ww_00_01:
        "Ok, I'm stumped. How do you even fight with these tiny sticks?",
    visit_atlab_sokka_ww_00_02:
        "...Wait! Don't say anything! I'll figure it out myself!",
    visit_atlab_sokka_wc_00_01:
        'Hey shopkeeper! You have crossbows in stock?',
    visit_atlab_sokka_wc_00_02:
        'There’s so many to choose from… I can’t decide!',
    thanks_atlab_sokka_00_01: 'Thanks for the deal!',
    thanks_atlab_sokka_01_01:
        "Can't wait to see Aang's face when I show him these!",
    thanks_atlab_sokka_02_01: 'Well within my budget! ...Probably.',
    thanks_atlab_sokka_03_01: 'My tribe would love to see these!',
    visit_tamas_backup_00_01: '',
    visit_tamas_al_00_01: '',
    visit_tamas_al_00_02: '',
    visit_tamas_am_00_01: '',
    visit_tamas_am_00_02: '',
    visit_tamas_bl_00_01: '',
    visit_tamas_bl_00_02: '',
    visit_tamas_gl_00_01: '',
    visit_tamas_gl_00_02: '',
    visit_tamas_hl_00_01: '',
    visit_tamas_hl_00_02: '',
    visit_tamas_hm_01_02: '',
    visit_tamas_hm_01_03: '',
    visit_tamas_xc_00_01: '',
    visit_tamas_xc_00_02: '',
    thanks_tamas_00_01: '',
    thanks_tamas_01_01: '',
    thanks_tamas_02_01: '',
    thanks_tamas_03_01: '',
    default_fallback_00_01: '[Missing dialogue data]',
    default_fallback_00_02: '[Missing dialogue data]',
    tutorial_dust_counter_click:
        'Click the <c1>counter</c1> to dust it off.',
    tutorial_dust_rack_click: 'Click the <c1>rack</c1> to dust it off.',
    tutorial_press_crafting_click: 'Click on the <c1>Craft</c1> button.',
    tutorial_press_crafting_card_click:
        'Click on the <c1>crafting card</c1>.',
    tutorial_wait_crafting_click:
        'Wait for the timer, then click on the card.',
    tutorial_dust_bin_click: 'Click the <c1>bin</c1> to remove the dust.',
    tutorial_press_bin_bubble_click:
        'Click the <c1>bubble</c1> to collect resources.',
    tutorial_press_sell_bubble_click:
        'Click the <c1>bubble</c1> to talk to the customer.',
    tutorial_press_bubble_hero_click:
        'Click the <c1>bubble</c1> to talk to Argon.',
    tutorial_press_bubble_blacksmith_click:
        'Click the <c1>bubble</c1> to talk to Wallace the Blacksmith.',
    tutorial_press_sell_item_click: 'Click on the <c1>Sell</c1> button.',
    tutorial_press_city_click: 'Click on the <c1>City</c1> button.',
    tutorial_press_buildings_click:
        'Click on the <c1>Tailor</c1> building.',
    tutorial_press_carpenter_click: 'Click on the <c1>Hire</c1> button.',
    tutorial_press_shop_click: 'Click on the <c1>Shop</c1> button.',
    tutorial_press_furniture_click:
        'Click on the <c1>Furniture</c1> button.',
    tutorial_press_hire_click: 'Click on the <c1>Hire</c1> button.',
    tutorial_press_weapon_click: 'Click on the <c1>Weapon</c1> slot.',
    tutorial_press_hire_adventurer_click:
        'Click on the <c1>Recruit</c1> button.',
    tutorial_press_bubble_tailor_click:
        'Click the <c1>bubble</c1> to talk to Julia the Tailor.',
    tutorial_press_quest_click: 'Click on the <c1>Quest</c1> button.',
    tutorial_press_questing_card_click:
        'Click on the <c1>Elven Wood</c1> button.',
    tutorial_press_hire_board_back_click:
        'Click on the <c1>back</c1> button to return to the city.',
    tutorial_press_forest_click: 'Click on the <c1>Send Party</c1> button.',
    tutorial_press_quest_slot_click:
        'Click on an empty <c1>Hero slot</c1>.',
    tutorial_press_adventurer_click: 'Click on a <c1>Hero card</c1>.',
    tutorial_press_adventurer_heal_click:
        'Click on the <c1>Heal</c1> button.',
    tutorial_press_adventurer_hpdrink_click:
        'Click on the <c1>Stamina Drink</c1>.',
    tutorial_press_start_quest_click:
        'Click on the <c1>Begin quest</c1> button.',
    tutorial_press_craft_slot_click: 'Click on the <c1>+</c1> button.',
    tutorial_collect_quest_click: 'Click on the <c1>Collect</c1> button.',
    tutorial_press_adventurers_click: 'Click on the <c1>Chars</c1> button.',
    tutorial_press_armor_click: 'Click on the <c1>armor</c1> button.',
    tutorial_press_back_click: 'Click on the <c1>back</c1> button.',
    tutorial_press_rack_click:
        'Click on the <c1>rack</c1> to see more options.',
    tutorial_press_info_click: 'Click on the <c1>info</c1> button.',
    tutorial_press_city_search_click:
        'Click on the <c1>Guild Finder</c1> button.',
    tutorial_press_vert_rack_click: 'Click on the <c1>Vertical Rack</c1>.',
    tutorial_press_generic_click: 'Click on the <c1>{0}</c1>.',
    tutorial_press_upgrade_click: 'Click on the <c1>Upgrade</c1> button.',
    tutorial_press_argon_click: 'Click on <c1>Argon</c1>.',
    tutorial_press_edit_click: 'Click on the <c1>Design</c1> button.',
    tutorial_press_trade_click: 'Click on the <c1>Market</c1> button.',
    tutorial_press_plant_click: 'Click on the <c1>Plant</c1>.',
    tutorial_press_chest_click: 'Click on the <c1>Chest</c1> button.',
    tutorial_press_expand_click: 'Click on the <c1>Expand</c1> button.',
    tutorial_press_open_chest_click:
        "Click on the <c1>Magic Unlock</c1> button. This one's on me!",
    tutorial_press_city_management_click:
        'Click on the <c1>Guild</c1> button.',
    tutorial_press_hire_argon_click: 'Click on the <c1>Gold</c1> button.',
    tutorial_press_jackslot_click: 'Click on the <c1>Tasks</c1> button.',
    tutorial_press_spin_click: 'Click on the <c1>Spin</c1> button.',
    tutorial_press_objective_click: 'Click on the <c1>Tasks</c1> button.',
    tutorial_press_surcharge_click:
        'Click on the <c1>Surcharge</c1> button.',
    tutorial_press_discount_click: 'Click on the <c1>Discount</c1> button.',
    tutorial_press_help_click: 'Click on the <c1>Help</c1> bubble.',
    tutorial_press_bubble_king_click:
        'You may now click my <c1>bubble</c1>.',
    tutorial_press_store_click: 'Click the <c1>Store</c1> button.',
    tutorial_press_crafting_unlock_click:
        'Click on the <c1>blueprint card</c1>.',
    tutorial_press_unlock_blueprint_click:
        'Click the <c1>Confirm</c1> button to unlock the Blueprint.',
    tutorial_press_forest_location_click:
        'Click the <c1>Howling Woods</c1>.',
    tutorial_speedup_craft_click:
        'Click on the <c1>craft bubble</c1> to rush it.',
    tutorial_press_ascension_tab_click:
        'Click on the <c1>Ascension</c1> tab.',
    tutorial_press_ascend_click: 'Click on the <c1>Ascend</c1> button.',
    tutorial_press_inventory_click:
        'Click on the <c1>inventory</c1> button.',
    tutorial_press_fatring_click: 'Click on the <c1>Ring</c1>.',
    tutorial_press_bubble_generic_click:
        'Click the <c1>bubble</c1> to talk to {0}.',
    tutorial_press_bubble_engineer_click:
        'Click the <c1>bubble</c1> to talk to the Engineer.',
    tutorial_press_bubble_scholar_click:
        'Click the <c1>bubble</c1> to talk to the Scholar.',
    tutorial_firstpet_intro_01: 'Shopkeeper! Help me out, will you?',
    tutorial_firstpet_intro_02:
        "I've got two stray animals here without a place to call home...",
    tutorial_firstpet_intro_03: 'Would you mind taking care of one for me?',
    tutorial_firstpet_popup_01:
        'Look at these cute little fellas! How can you say no to that?',
    tutorial_firstpet_popup_02: 'Go ahead and pick one!',
    tutorial_firstpet_cat_01:
        "Don't worry about that dog, I'll take good care of this little fella!",
    tutorial_firstpet_cat_02:
        "As for your cat, make sure to give it tons of love... and don't forget to feed it!",
    tutorial_firstpet_cat_03:
        "I'm sure it'll get along just fine with your customers, too!",
    tutorial_firstpet_dog_01:
        "Don't worry about that cat, the Wallace household could use a fuzzy furball!",
    tutorial_firstpet_dog_02:
        "As for your dog, make sure to give it tons of love... and don't forget to feed it!",
    tutorial_firstpet_dog_03:
        "I'm sure it'll get along just fine with your customers, too!",
    visit_blacksmith_reminder_00_01:
        "Shopkeeper! Your guild's looking a bit sleepy, doesn't it?",
    visit_blacksmith_reminder_00_02:
        'It will be difficult to grow as a shopkeeper with so many inactive guildmates...',
    visit_blacksmith_reminder_00_03:
        "It might be a good idea to look for a new one! Don't worry, <c1> you won't lose your investments!</c1>.",
    visit_blacksmith_reminder_01_01:
        'Shopkeeper! How is your guild holding up?',
    visit_blacksmith_reminder_01_02:
        'If its members have become inactive, it might be time to find a new one!',
    visit_blacksmith_reminder_01_03:
        'No need to worry about your investments, <c1>they follow you from guild to guild!</c1>',
    visit_blacksmith_reminder_02_01:
        "Shopkeeper! How is your guild's activity these days?",
    visit_blacksmith_reminder_02_02:
        'A <c1>full, active guild</c1> is the key to success and swift progress!',
    visit_blacksmith_reminder_02_03:
        'If you feel your guild is too slow, you can always look into joining another one!',
    visit_blacksmith_reminder_02_04:
        "No need to worry about your investments, <c1>they'll follow you if you change guild!</c1>",
    loginevent_anniversary_01: 'Prosperous shopkeeper, your King is here!',
    loginevent_anniversary_02:
        'Hold on to your seat, for I bring exciting news...',
    loginevent_anniversary_03:
        '<c1>Shop Titans</c1> is now officially 2 years old! Incredible!',
    loginevent_anniversary_04:
        "Yes, yes, we're breaking the fourth wall here, but this is a momentous occasion!",
    loginevent_anniversary_05:
        'To celebrate, your most generous King will be distributing gifts to all shopkeepers over the following days!',
    loginevent_anniversary_06:
        'Indeed, my generosity knows no bounds! No need to thank me!',
    dialog_king_event_hot_item:
        "I've been dreaming about some <c1>{0}</c1> for weeks! I need them!",
    dialog_king_event_hot_item_02:
        'My <c1>{0}</c1> collection is a bit lacking currently... Remedy this as soon as possible!',
    dialog_king_event_hot_item_03:
        '<c1>{0}</c1>! <c1>{0}</c1>! My kingdom for some <c1>{0}</c1>!',
    dialog_king_event_hot_item_04:
        'Can you imagine a world without <c1>{0}</c1>? I shudder at the thought!',
    dialog_king_event_hot_item_05:
        'I, King Reinhold, hereby declare <c1>{0}</c1> to be the cool item of the hour!',
    dialog_king_event_hot_item_06:
        'I refuse to be seen without some <c1>{0}</c1>! My status demands it!',
    dialog_king_event_hot_item_07:
        "I've been thinking of making <c1>{0}</c1> the kingdom's heraldry. What do you think?",
    dialog_king_event_hot_item_08:
        "Whatever you were crafting until now is passé! It's all about <c1>{0}</c1> now!",
    dialog_king_event_hot_item_09:
        'Who has ever heard of a king without <c1>{0}</c1>?',
    dialog_king_event_hot_item_10:
        'Your resplendent king requires equally resplendent <c1>{0}</c1>!',
    dialog_king_event_type_ws:
        'There is nary a weapon nobler than the <c1>Sword</c1>! All too fitting for a splendid king!',
    dialog_king_event_type_wa:
        '<c1>Axes</c1>! The tools of the hard working class! I am a man of the people and I will prove it!',
    dialog_king_event_type_wd:
        'Quantity or quality? I choose both! Millions of <c1>Daggers</c1>, on the double!',
    dialog_king_event_type_wm:
        'Complaints have reached my ear that there were too many <c1>Maces</c1> around. They are unsubstantiated!',
    dialog_king_event_type_wp:
        "Today's fortune calls for <c1>Spears</c1>! I would be a fool to ignore it!",
    dialog_king_event_type_wb:
        'To aim true and let loose a deft shot... <c1>Bows</c1> are grace given form!',
    dialog_king_event_type_wt:
        "Let's see how good this Allan truly is. <c1>Staves</c1>! More than I can count!",
    dialog_king_event_type_ww:
        '<c1>Wands</c1> bear great power, and great power means great responsibility. A perfect match for I!',
    dialog_king_event_type_wc:
        '<c1>Crossbows</c1>? <c1>Crossbows</c1> sound good. Work your magic, shopkeeper.',
    dialog_king_event_type_wg:
        'My kingdom is avant-garde. Mighty <c1>Guns</c1> must be mine!',
    dialog_king_event_type_uh:
        'My royal pantry is miserly! <c1>Medicinal Herbs</c1> shall act as impromptu spices!',
    dialog_king_event_type_up:
        '<c1>Potions</c1>, tonics, elixirs... One can never have enough, least of all I!',
    dialog_king_event_type_us:
        'I wish to learn magic! Shower me with <c1>Spells</c1>, and I promise they will NOT be used responsibly!',
    dialog_king_event_type_ah:
        "I've got a fever... and the only cure is <c1>Heavy Armor</c1>!",
    dialog_king_event_type_am:
        "I cannot have enough of Julia's masterpieces! More <c1>Light Armor</c1>, please!",
    dialog_king_event_type_al:
        'Pompous <c1>Clothes</c1> suit me just fine! Regale me with your finest fabrics!',
    dialog_king_event_type_hh:
        'Wonderful <c1>Helmets</c1> ill fit a man wearing a crown, but that is a sacrifice men of my stature must make.',
    dialog_king_event_type_hm:
        'What dashing <c1>Rogue Hats</c1>! My wardrobe is in dire need of some garnishing!',
    dialog_king_event_type_hl:
        "I may not have a wizard's power, but there is nothing money can't buy! Bring me <c1>Magician Hats</c1>!",
    dialog_king_event_type_gh:
        'To rule with an iron fist... would wearing <c1>Gauntlets</c1> be too literal?',
    dialog_king_event_type_gl:
        'These delicate hands must be protected at all costs! My life depends on these <c1>Gloves</c1>!',
    dialog_king_event_type_bh:
        'I shall walk a hundred miles if I must, but the finest <c1>Boots</c1> must be mine!',
    dialog_king_event_type_bl:
        'My favorite pair of <c1>Shoes</c1> is all worn out! I require several hundred more!',
    dialog_king_event_type_xs:
        "There is no better canvas than <c1>Shields</c1> to display one's heraldry!",
    dialog_king_event_type_xc:
        '<c1>Cloaks</c1> are urgently needed! My radiant self must soon travel incognito!',
    dialog_king_event_type_xr:
        'Sia is long overdue for a lavish gift! I require a thousand <c1>Rings</c1> as soon as humanly possible.',
    dialog_king_event_type_xa:
        'Your King requires bling! Only the most extravagant <c1>Amulets</c1> will do!',
    dialog_king_event_type_xu:
        'I long for <c1>Runestones</c1>! ...What are they used for again?',
    dialog_king_event_type_xm:
        '<c1>Moonstones</c1>... So mysterious and beautiful... I want troves of them right this instant!',
    dialog_king_event_type_xf:
        'This kingdom requires a tide of <c1>Familiars</c1> for the enjoyment of all, but most of all I!',
    dialog_king_event_type_spirits:
        'My kingly spirit is afire with desire! Desire for the greatest <c1>Spirits</c1> money can buy!',
    dialog_king_event_type_elements:
        "It's elementary, my dear shopkeeper. <c1>Elements</c1> I demand, and <c1>Elements</c1> I shall receive!",
    dialog_king_event_category_enchantments:
        'The all-powerful <c1>Enchantments</c1>... They do exist! I have many bathrobes in need of enchanting!',
    dialog_king_event_category_w:
        '<c1>Weapons</c1> are what truly matter! I care not about the type!',
    dialog_king_event_category_armors:
        'I wish for my army to be clad in resplendent <c1>Armor</c1>! Right this instant!',
    dialog_king_event_category_x:
        '<c1>Accessories</c1> make the man, my father used to say. I happen to be a very rich man.',
    dialog_king_event_category_stones:
        'All experts agree; riches lie within these magical <c1>Stones</c1>! Craft as many as you can!',
    dialog_king_event_next_reward:
        "Look at this! <c1>{0}</c1>! Isn't it beautiful?",
    dialog_king_event_next_reward_02:
        "What's up next? <c1>{0}</c1>!? I may be too generous!",
    dialog_king_event_next_reward_03:
        'A bounty of <c1>{0}</c1> awaits the brave shopkeepers who answer my requests!',
    dialog_king_event_next_reward_04:
        "<c1>{0}</c1> straight from the kingdom's coffers... but promise not to tell anyone!",
    dialog_king_event_next_reward_05:
        "<c1>{0}</c1>, huh? Don't go spend it all in one place!",
    dialog_king_event_generic:
        "...There's no scary ninja around, is there?",
    dialog_king_event_generic_02:
        'When will the Lost City of Gold appear again, I wonder...?',
    dialog_king_event_generic_03:
        '...Who put that Tower of Titans there? Is it paying property taxes?',
    dialog_king_event_generic_04:
        'Welcome to my caprices! Answer them and be justly rewarded!',
    dialog_king_event_generic_05:
        "Sia was NOT in favor of this escapade. I'm sure she will come around.",
    dialog_king_event_generic_06:
        "Fine weather, isn't it? It's always sunny in Reinhold's kingdom!",
    dialog_king_event_generic_07:
        "I've had a horrible dream... where all my rewards were claimed twice! Caprice be cursed, I've never felt so robbed!",
    tutorial_press_basement: 'Tap on the <c1>Basement</c1> button',
    tutorial_press_basement_expansion:
        'Tap on the <c1>Basement Expansion</c1> card',
    tutorial_press_gems: 'Tap on the <c1>Gems</c1> button',
    tutorial_press_bin_select: 'Tap on the <c1>Bin</c1> to select it',
    tutorial_press_basement_store:
        'Tap on the <c1>Store in Basement</c1> button',
    tutorial_press_furniture_edit: 'Tap on the <c1>Edit</c1> button',
    tutorial_press_basement_click: 'Click on the <c1>Basement</c1> button',
    tutorial_press_basement_expansion_click:
        'Click on the <c1>Basement Expansion</c1> card',
    tutorial_press_gems_click: 'Click on the <c1>Gems</c1> button',
    tutorial_press_bin_select_click:
        'Click on the <c1>Bin</c1> to select it',
    tutorial_press_basement_store_click:
        'Click on the <c1>Send to Basement</c1> button',
    tutorial_press_furniture_edit_click:
        'Click on the <c1>Edit</c1> button',
    dialog_sundragon_unhiredgreetings: 'Greetings, child of man.',
    dialog_sundragon_unhiredgreetings_02:
        'I am Sondra, dragon artisan from the far east. It would be my pleasure to share my craft with you.',
    dialog_sundragon_unhiredgreetings_03:
        'My expertise resides in transmutation. Your kind sometimes call it <c1>Fusion</c1>.',
    dialog_sundragon_hirequestion:
        'Would you like to begin your journey toward mastering this fine art?',
    dialog_moondragon_unhiredgreetings:
        'Fusion is an elegant craft, but it requires a lunar power source.',
    dialog_moondragon_unhiredgreetings_02:
        'I can harness the essence of Luna and craft it into <c1>Moonstones</c1>.',
    dialog_moondragon_unhiredgreetings_03:
        'I also craft some special equipment with <c1>Anti-Boss</c1> properties that your Heroes might be able to wear.',
    dialog_moondragon_unhiredgreetings_04:
        'With me around, and the appropriate offerings, you can unleash the true power of <c1>Fusion</c1>.',
    dialog_elven_unhiredgreetings: 'Glad to see you again, shopkeeper.',
    dialog_elven_unhiredgreetings_02:
        'I am Yolanda, the Summoner. Mother of the cute Familiars.',
    dialog_elven_unhiredgreetings_03:
        'For a responsible shopkeeper, I can summon <c1>Familiar</c1> eggs to be adopted by Champions.',
    dialog_elven_unhiredgreetings_04:
        '...I also knit some fancy <c1>Cloaks</c1> during evenings.',
    dialog_elven_hirequestion:
        'Would you like to become a home for my cute Familiars?',
    tutorial_press_alt_fusion: 'Tap to select the <c1>Fusion Button</c1>.',
    tutorial_press_fusion_card: 'Tap on the <c1> Fusion Card</c1>.',
    tutorial_press_begin_fusion_click:
        'Tap on the <c1>Start Fusion</c1> button.',
    tutorial_press_main_fusion: 'Tap on  the <c1>Fusion Button</c1>',
    tutorial_press_alt_fusion_click:
        'Click to select the <c1>Fusion Button</c1>.',
    tutorial_press_fusion_card_click: 'Click on the <c1> fusion card</c1>.',
    tutorial_press_begin_fusion_click_click:
        'Click on the <c1>Start Fusion</c1> button.',
    tutorial_fusion1_01:
        'I believe you are ready to learn the art of <c1>Fusion</c1>, child of man.',
    tutorial_fusion2_01:
        'First, you must select a few items worthy of a fusion.',
    tutorial_fusion3_01:
        'In order to power the Synthesis Vessel, lunar energy in the form of <c1>Moonstones</c1> is also required.',
    tutorial_fusion4_01:
        "A fine fusion. You are worthy of the Synthesis Vessel's power!",
    tutorial_fusion4_02:
        "Such skillful fusion may attract the <c1>Moon Dragon's</c1> attention. I wish you luck, child of man.",
    tutorial_press_main_fusion_click: 'Click on the <c1>Fusion Button</c1>',
    offer_superiorpack_king_01: 'Greetings, shopkeeper!',
    offer_superiorpack_king_02:
        'It seems you are one with taste for only the finest things in life...',
    offer_superiorpack_king_03:
        "...So much, in fact, that you've bought just about every luxury there is to own!",
    offer_superiorpack_king_04:
        "I suppose a little extra for such a refined individual wouldn't hurt...",
    offer_superiorpack_king_05:
        'Keep it a secret though! A secret between two resplendant aristocrats!',
    offer_superiorpack_king_06:
        'I would expect you of all people to recognize a savvy deal such as this!',
    offer_superiorpack_king_07:
        'You may take time to consider it, but it is a very brief opportunity!',
    tutorial_throwback_pack1_01: 'Valiant shopkeeper!',
    tutorial_throwback_pack2_01:
        "While searching high and low into old ledgers, I've stumbled upon something of note...",
    tutorial_throwback_pack2_02:
        '...All the past offers from the city folks! A treasure trove of nostalgia!',
    tutorial_throwback_pack2_03:
        "If you feel like you've missed out on some of them, now's your chance!",
    tutorial_throwback_pack3_01:
        "Splendid choice! I would've chosen that one, too!",
    tutorial_throwback_pack4_01:
        'Careful consideration is not one of my virtues, but it is respectable nonetheless!',
    class_alchemist_name: 'Alchemist',
    class_alchemist_desc:
        'Masters of herbalism, occultism and reagents of all kinds.',
    class_barbarian_name: 'Barbarian',
    class_barbarian_desc:
        'Savage combatants who throw caution to the wind.',
    class_chieftain_name: 'Chieftain',
    class_chieftain_desc:
        'When the tribe calls for a leader, a Chieftain naturally rises among the brave.',
    class_berserker_name: 'Berserker',
    class_berserker_desc:
        'Warriors reveling in carnage and capable of entering a trance of pure violence.',
    class_jarl_name: 'Jarl',
    class_jarl_desc:
        'Even among the mightiest Berserkers, a name is whispered in fear: the Jarl.',
    class_cleric_name: 'Cleric',
    class_cleric_desc:
        'Holy missionaries who protect the faithful with divine powers.',
    class_bishop_name: 'Bishop',
    class_bishop_desc:
        "Zealots among zealots, Bishops embody the Goddess' will in the world of the mortals.",
    class_dancer_name: 'Dancer',
    class_dancer_desc:
        'Agile tricksters that can read the flow of combat and gracefully dance to its beat.',
    class_acrobat_name: 'Acrobat',
    class_acrobat_desc:
        "The acrobat's deadly performance never requires an encore.",
    class_dragoon_name: 'Dragoon',
    class_dragoon_desc:
        'Fearless dragon slayers capable of incredible leaps.',
    class_druid_name: 'Druid',
    class_druid_desc: "Embodiments of nature's bounty and wrath.",
    class_archdruid_name: 'Arch Druid',
    class_archdruid_desc:
        'Only seen in times of great needs, Arch Druids are the last line of defense of the natural world.',
    class_jester_name: 'Jester',
    class_jester_desc:
        'Unlikely allies who wield sharp words and knives with equal grace.',
    class_knight_name: 'Knight',
    class_knight_desc:
        'Noble warriors capable of holding the line, no matter the opposition.',
    class_lord_name: 'Lord',
    class_lord_desc:
        'Generals, commanders and conquerors of countless battles.',
    class_mage_name: 'Mage',
    class_mage_desc:
        'Wielders of unmatched magical power from the four elements.',
    class_archmage_name: 'Archmage',
    class_archmage_desc:
        'Spellcasters obey the laws of magic to cast their spells. Archmage break and rewrite these rules to suit their needs.',
    class_monk_name: 'Monk',
    class_monk_desc:
        'Ascetic warriors that seek perfection through honing the mind and body.',
    class_mastermonk_name: 'Grandmaster',
    class_mastermonk_desc: 'Balance. Thought. Action. Reflection.',
    class_ninja_name: 'Ninja',
    class_ninja_desc:
        'No job is below or beyond these shadow-stalking assassins.',
    class_sensei_name: 'Sensei',
    class_sensei_desc:
        'Few have seen a Sensei in action. Fewer still have lived to tell the tale.',
    class_paladin_name: 'Paladin',
    class_paladin_desc:
        'Holy warriors on a sacred quest to eradicate all evil.',
    class_pirate_name: 'Pirate',
    class_pirate_desc:
        'Rowdy gun-wielders with a penchant for booze, shanty and booty.',
    class_ranger_name: 'Ranger',
    class_ranger_desc: 'Explorers of the wild and peerless hunters.',
    class_warden_name: 'Warden',
    class_warden_desc:
        'Wardens are one with the untamed lands beyond the reach of civilization.',
    class_redmage_name: 'Spellblade',
    class_redmage_desc:
        'Jack of all trades capable of excelling in any discipline.',
    class_spellknight_name: 'Spellknight',
    class_spellknight_desc:
        'Grizzled combatants and arcane scholars, there is no field in which Spellknights cannot excel.',
    class_geomancer_name: 'Geomancer',
    class_geomancer_desc:
        'Wise scholars that command the latent power of the surrounding terrain.',
    class_astramancer_name: 'Astramancer',
    class_astramancer_desc:
        'With magic of the land under their absolute command, some turn their gaze to the skies beyond the skies.',
    class_soldier_name: 'Soldier',
    class_soldier_desc:
        'Professional warriors and unparalleled experts with all melee weapons.',
    class_mercenary_name: 'Mercenary',
    class_mercenary_desc:
        'Veterans of countless wars boasting an unwavering loyalty... for the right price.',
    class_sorcerer_name: 'Sorcerer',
    class_sorcerer_desc:
        'Spellcasters who gained fell powers through a dark contract.',
    class_warlock_name: 'Warlock',
    class_warlock_desc:
        'Fell powers beyond understanding. Is the Warlock truly in control?',
    class_swordmaster_name: 'Samurai',
    class_swordmaster_desc:
        'Wandering warriors capable of winning a battle with a single draw of the sword.',
    class_daimyo_name: 'Daimyo',
    class_daimyo_desc:
        'When a lord among samurai goes to war, nations quake and petals fall.',
    class_thief_name: 'Thief',
    class_thief_desc:
        'Slippery scoundrels best left in your field of view.',
    class_trickster_name: 'Trickster',
    class_trickster_desc: 'Trick or treat? Not quite.',
    class_wanderer_name: 'Wanderer',
    class_wanderer_desc:
        'Mysterious strangers that always have the right tool for the job.',
    class_pathfinder_name: 'Pathfinder',
    class_pathfinder_desc:
        'Peerless explorers of the uncharted and forbidden.',
    class_musketeer_name: 'Musketeer',
    class_musketeer_desc:
        'Daring outlaws and expert fencers. They also wield the terrifying power of firearms.',
    class_conquistador_name: 'Conquistador',
    class_conquistador_desc:
        'For distant problems, gunpowder. For nearby problems, steel.',
    class_witch_name: 'Witch',
    class_witch_desc:
        'Excommunicated arcanists with unscrupulous, yet inscrutable motives.',
    hero_class_argon_01: 'Aspiring Knight',
    hero_class_argon_02: 'Ex-Knight',
    hero_class_argon_03: 'Paladin',
    hero_argon_bio_01:
        'A young Knight-in-Training trying to prove himself. Loyal to the end.',
    hero_argon_bio_02:
        'A budding knight stripped of his title, seeking redemption.',
    hero_argon_bio_03: 'A full-fledged Paladin, sworn to protect the weak.',
    hero_class_sia_01: "King's Shadow",
    hero_class_sia_02: "King's Shadow?",
    hero_class_sia_03: 'Secret Agent',
    hero_sia_bio_01:
        'A refined woman shrouded in mystery. Seems to know everything and everyone.',
    hero_sia_bio_02:
        "A refined woman shrouded in mystery. Is the kingdom's safety truly her priority?",
    hero_sia_bio_03:
        'A secret protector of the king. Will stop at nothing to ensure his safety.',
    hero_class_lilu_01: 'Apprentice',
    hero_class_lilu_02: 'Prodigy',
    hero_lilu_bio_01:
        'A young magic apprentice. Very talented despite her age.',
    hero_lilu_bio_02:
        'A budding scholar of the magic arts. Saved her master from the curse of... Elder Owen!?',
    hero_class_yami_01: 'Kunoichi',
    hero_class_yami_02: 'Assassin Outcast',
    hero_class_yami_03: "King's Blade",
    hero_yami_bio_01:
        'A taciturn assassin bent on revenge. No fun at parties.',
    hero_yami_bio_02:
        'Once an assassin of the Order, but no longer. Failure is not tolerated.',
    hero_yami_bio_03:
        "Any who would make an attempt on the King's life should think twice, for his Blade lurks in every shadow.",
    hero_class_rudo_01: 'Lone Warrior',
    hero_rudo_bio_01:
        "A gruff, seasoned berserker. Doesn't beat around the bush, but might beat it up if he feels like it.",
    hero_class_polonia_01: 'Landlubber',
    hero_class_polonia_02: "Pirate Cap'n",
    hero_polonia_bio_01:
        'A pirate-to-be lacking a ship to her name. Dreams of swashbuckling tales on the high seas.',
    hero_polonia_bio_02:
        'A scourge of the seas living up to the legendary legacy of Baross Del Monte.',
    hero_class_donovan_01: 'Imperial Alchemist',
    hero_donovan_bio_01:
        'An accomplished alchemist from the Kern empire. A renowned master at all forms of transmutation and alchemy.',
    hero_class_donovan_02: 'Alchemist Extraordinaire',
    hero_donovan_bio_02:
        'An accomplished alchemist from the Kern empire. A renowned master at all forms of transmutation and alchemy.',
    hero_class_hemma_01: 'Necromancer',
    hero_hemma_bio_01:
        'Shrouded in shadows and secret, Hemma commands dark powers few understand.',
    hero_class_ashley_01: 'Ronin',
    hero_ashley_bio_01:
        "A wandering samurai from Azuma. Lost an arm, but she doesn't let that slow her down.",
    hero_class_hemma_02: 'Possessed Necromancer',
    hero_hemma_bio_02:
        'A mysterious invader seems to reside within Hemma. Is this a mutually beneficial relationship?',
    hero_class_atlab_aang_01: 'Avatar',
    hero_atlab_aang_bio_01:
        'The last Airbender and also the Avatar, capable of mastering all four elements. Destined to save the world.',
    hero_class_atlab_sokka_01: 'Water Tribe Warrior',
    hero_atlab_sokka_bio_01:
        'Sharp-witted and loyal. Joined Aang on his quest to save the world',
    classtype_fighter: 'Fighter',
    classtype_rogue: 'Rogue',
    classtype_spellcaster: 'Spellcaster',
    hero_skill_argonleader1_name: 'Aura of Courage',
    hero_skill_argonleader1_desc: '"Everyone, to me!"',
    hero_skill_argonleader1_effect:
        '+10% bonus Attack for the party. +10% bonus Defense for the party.',
    hero_skill_argonleader1_effect_0:
        '<sprite=4> <color=#24E656FF> +10%</color> bonus Attack for the party.',
    hero_skill_argonleader1_effect_1:
        '<sprite=5> <color=#24E656FF> +10%</color> bonus Defense for the party.',
    hero_skill_argonleader2_name: 'Aura of Resolve',
    hero_skill_argonleader2_desc:
        '"For those who bear no shield, I shall serve in its stead."',
    hero_skill_argonleader2_effect:
        '+20% bonus Attack for the party. +20% bonus Defense for the party.',
    hero_skill_argonleader2_effect_0:
        '<sprite=4> <color=#24E656FF> +20%</color> bonus Attack for the party.',
    hero_skill_argonleader2_effect_1:
        '<sprite=5> <color=#24E656FF> +20%</color> bonus Defense for the party.',
    hero_skill_argonleader3_name: 'Aura of Heroism',
    hero_skill_argonleader3_desc:
        '"My will is unwavering. Wherever evil lies, I shall go."',
    hero_skill_argonleader3_effect:
        '+30% bonus Attack for the party. +30% bonus Defense for the party.',
    hero_skill_argonleader3_effect_0:
        '<sprite=4><color=#24E656FF> +30%</color> bonus Attack for the party.',
    hero_skill_argonleader3_effect_1:
        '<sprite=5><color=#24E656FF> +30%</color> bonus Defense for the party.',
    hero_skill_argonleader4_name: 'Aura of the Paladin',
    hero_skill_argonleader4_desc:
        '"None may get past this shield while I still stand!"',
    hero_skill_argonleader4_effect:
        '+40% bonus Attack for the party. +40% bonus Defense for the party.',
    hero_skill_argonleader4_effect_0:
        '<sprite=4> <color=#24E656FF> +40%</color> bonus Attack for the party.',
    hero_skill_argonleader4_effect_1:
        '<sprite=5> <color=#24E656FF> +40%</color> bonus Defense for the party.',
    hero_skill_sialeader1_name: 'Eye for Loot',
    hero_skill_sialeader1_desc:
        '"Now what could this pretty little thing be?"',
    hero_skill_sialeader1_effect:
        '+25% more components found in quests. +10% bonus Attack for the party.',
    hero_skill_sialeader1_effect_0:
        '<sprite=13> <color=#24E656FF>+25%</color> more components found in quests.',
    hero_skill_sialeader1_effect_1:
        '<sprite=4> <color=#24E656FF>+10%</color> bonus Attack for the party.',
    hero_skill_sialeader2_name: 'Trained Eye',
    hero_skill_sialeader2_desc:
        '"It\'s all a matter of perspective darling."',
    hero_skill_sialeader2_effect:
        '+50% more components found in quests. +15% bonus Attack for the party.',
    hero_skill_sialeader2_effect_0:
        '<sprite=13> <color=#24E656FF>+50%</color> more components found in quests.',
    hero_skill_sialeader2_effect_1:
        '<sprite=4> <color=#24E656FF>+15%</color> bonus Attack for the party.',
    hero_skill_sialeader3_name: 'Eye for Style',
    hero_skill_sialeader3_desc:
        '"Throw away the trash, we keep only the best!"',
    hero_skill_sialeader3_effect:
        '+75% more components found in quests. +20% bonus Attack for the party.',
    hero_skill_sialeader3_effect_0:
        '<sprite=13> <color=#24E656FF>+75%</color> more components found in quests.',
    hero_skill_sialeader3_effect_1:
        '<sprite=4> <color=#24E656FF>+20%</color> bonus Attack for the party.',
    hero_skill_sialeader4_name: 'Eye of the Beholder',
    hero_skill_sialeader4_desc: '"Nonsense! It\'s as good as new!"',
    hero_skill_sialeader4_effect:
        '+100% more components found in quests. +25% bonus Attack for the party.',
    hero_skill_sialeader4_effect_0:
        '<sprite=13> <color=#24E656FF>+100%</color> more components found in quests.',
    hero_skill_sialeader4_effect_1:
        '<sprite=4> <color=#24E656FF>+25%</color> bonus Attack for the party.',
    hero_skill_liluleader1_name: 'Cure Wounds',
    hero_skill_liluleader1_desc:
        '"It stings for a bit, but it\'ll get better!"',
    hero_skill_liluleader1_effect:
        'Heals party by 3 HP each turn. +10% more HP. +10% faster resting time.',
    hero_skill_liluleader1_effect_0:
        '<sprite=24> Heals party by <color=#24E656FF>3 HP</color> each turn. ',
    hero_skill_liluleader1_effect_1:
        '<sprite=3> <color=#24E656FF>+10%</color> more HP for the party.',
    hero_skill_liluleader1_effect_2:
        '<sprite=11> <color=#24E656FF>+10%</color> faster resting time for the party.',
    hero_skill_liluleader2_name: 'Divine Power',
    hero_skill_liluleader2_desc: '"Spirits of the light, I beseech thee!"',
    hero_skill_liluleader2_effect:
        'Heals party by 5 HP each turn. +15% more HP. +20% faster resting time.',
    hero_skill_liluleader2_effect_0:
        '<sprite=24> Heals party by <color=#24E656FF>5 HP</color> each turn. ',
    hero_skill_liluleader2_effect_1:
        '<sprite=3> <color=#24E656FF>+15%</color> more HP for the party.',
    hero_skill_liluleader2_effect_2:
        '<sprite=11> <color=#24E656FF>+20%</color> faster resting time for the party.',
    hero_skill_liluleader3_name: 'Revitalization',
    hero_skill_liluleader3_desc: '"How do you feel? It\'s my own spell!"',
    hero_skill_liluleader3_effect:
        'Heals party by 10 HP each turn. +20% more HP. +35% faster resting time.',
    hero_skill_liluleader3_effect_0:
        '<sprite=24> Heals party by <color=#24E656FF>10 HP</color> each turn. ',
    hero_skill_liluleader3_effect_1:
        '<sprite=3> <color=#24E656FF>+20%</color> more HP for the party.',
    hero_skill_liluleader3_effect_2:
        '<sprite=11> <color=#24E656FF>+35%</color> faster resting time for the party.',
    hero_skill_liluleader4_name: 'Sacred Circle',
    hero_skill_liluleader4_desc:
        '"It\'s a bit unstable but... feel that power!"',
    hero_skill_liluleader4_effect:
        'Heals party by 20 HP each turn. +25% more HP. +50% faster resting time.',
    hero_skill_liluleader4_effect_0:
        '<sprite=24> Heals party by <color=#24E656FF>20 HP</color> each turn. ',
    hero_skill_liluleader4_effect_1:
        '<sprite=3> <color=#24E656FF>+25%</color> more HP for the party.',
    hero_skill_liluleader4_effect_2:
        '<sprite=11> <color=#24E656FF>+50%</color> faster resting time for the party.',
    hero_skill_yamileader1_name: 'Ambush',
    hero_skill_yamileader1_desc:
        '"You, come with me. The rest, be your usual noisy selves."',
    hero_skill_yamileader1_effect:
        '+10% faster quest time. +5% Critical Hit Chance for the party. +5% Evasion for the party.',
    hero_skill_yamileader1_effect_0:
        '<sprite=18> <color=#24E656FF>+10%</color> faster quest time.',
    hero_skill_yamileader1_effect_1:
        '<sprite=19> <color=#24E656FF>+5%</color> Critical Hit Chance for the party.',
    hero_skill_yamileader1_effect_2:
        '<sprite=6> <color=#24E656FF>+5%</color> Evasion for the party.',
    hero_skill_yamileader2_name: 'Coordinated Ambush',
    hero_skill_yamileader2_desc:
        '"Focus on the task. Nothing else matters."',
    hero_skill_yamileader2_effect:
        '+20% faster quest time. +10% Critical Hit Chance for the party. +10% Evasion for the party.',
    hero_skill_yamileader2_effect_0:
        '<sprite=18> <color=#24E656FF>+20%</color> faster quest time.',
    hero_skill_yamileader2_effect_1:
        '<sprite=19> <color=#24E656FF>+10%</color> Critical Hit Chance for the party.',
    hero_skill_yamileader2_effect_2:
        '<sprite=6> <color=#24E656FF>+10%</color> Evasion for the party.',
    hero_skill_yamileader3_name: 'Ethereal Ambush',
    hero_skill_yamileader3_desc:
        '"Deny their senses, and fear is quick to follow."',
    hero_skill_yamileader3_effect:
        '+35% faster quest time. +15% Critical Hit Chance for the party. +15% Evasion for the party.',
    hero_skill_yamileader3_effect_0:
        '<sprite=18> <color=#24E656FF>+35%</color> faster quest time.',
    hero_skill_yamileader3_effect_1:
        '<sprite=19> <color=#24E656FF>+15%</color> Critical Hit Chance for the party.',
    hero_skill_yamileader3_effect_2:
        '<sprite=6> <color=#24E656FF>+15%</color> Evasion for the party.',
    hero_skill_yamileader4_name: 'Perfect Ambush',
    hero_skill_yamileader4_desc: '"Keep up or I\'ll leave you behind."',
    hero_skill_yamileader4_effect:
        '+50% faster quest time. +20% Critical Hit Chance for the party. +20% Evasion for the party.',
    hero_skill_yamileader4_effect_0:
        '<sprite=18> <color=#24E656FF>+50%</color> faster quest time.',
    hero_skill_yamileader4_effect_1:
        '<sprite=19> <color=#24E656FF>+20%</color> Critical Hit Chance for the party.',
    hero_skill_yamileader4_effect_2:
        '<sprite=6> <color=#24E656FF>+20%</color> Evasion for the party.',
    hero_skill_rudoleader1_name: 'Rudo Blitz',
    hero_skill_rudoleader1_desc:
        '"Bah! Enough talk! Get out there and kill something!"',
    hero_skill_rudoleader1_effect:
        '+30% Critical Hit Chance and -100% Break Chance for the party. Lasts 2 rounds.',
    hero_skill_rudoleader1_effect_0:
        '<sprite=19> <color=#24E656FF>+30%</color> Critical Hit Chance',
    hero_skill_rudoleader1_effect_1:
        '<sprite=12> <color=#24E656FF>-50%</color> Break Chance for the party.',
    hero_skill_rudoleader1_effect_2: '<sprite=17> Lasts 2 rounds.',
    hero_skill_rudoleader2_name: 'Headstrong Assault',
    hero_skill_rudoleader2_desc:
        '"Keep a safe distance from me. For your own safety."',
    hero_skill_rudoleader2_effect:
        '+40% Critical Hit Chance and -100% Break Chance for the party. Lasts 2 rounds.',
    hero_skill_rudoleader2_effect_0:
        '<sprite=19> <color=#24E656FF>+40%</color> Critical Hit Chance',
    hero_skill_rudoleader2_effect_1:
        '<sprite=12> <color=#24E656FF>-100%</color> Break Chance for the party.',
    hero_skill_rudoleader2_effect_2: '<sprite=17> Lasts 2 rounds.',
    hero_skill_rudoleader3_name: 'Berserker Rampage',
    hero_skill_rudoleader3_desc:
        '"You think you\'ve got what it takes to be a warrior? PROVE IT!"',
    hero_skill_rudoleader3_effect:
        '+40% Critical Hit Chance and -100% Break Chance for the party. Lasts 3 rounds.',
    hero_skill_rudoleader3_effect_0:
        '<sprite=19> <color=#24E656FF>+40%</color> Critical Hit Chance',
    hero_skill_rudoleader3_effect_1:
        '<sprite=12> <color=#24E656FF>-100%</color> Break Chance for the party.',
    hero_skill_rudoleader3_effect_2: '<sprite=17> Lasts 3 rounds.',
    hero_skill_rudoleader4_name: 'Total Mayhem',
    hero_skill_rudoleader4_desc: '"GWAH HAH HAH HAH!!!"',
    hero_skill_rudoleader4_effect:
        '+50% Critical Hit Chance and -100% Break Chance for the party. Lasts 4 rounds.',
    hero_skill_rudoleader4_effect_0:
        '<sprite=19> <color=#24E656FF>+50%</color> Critical Hit Chance',
    hero_skill_rudoleader4_effect_1:
        '<sprite=12> <color=#24E656FF>-100%</color> Break Chance for the party.',
    hero_skill_rudoleader4_effect_2: '<sprite=17> Lasts 4 rounds.',
    hero_skill_polonialeader1_name: "Pirate's Due",
    hero_skill_polonialeader1_desc: '"Avast me hearties! Rob \'em blind!"',
    hero_skill_polonialeader1_effect:
        '30% chance to steal from monsters. +10% Defense for the party. +5% Evasion for the party.',
    hero_skill_polonialeader1_effect_0:
        '<sprite=13> <color=#24E656FF>30%</color> chance to steal from monsters.',
    hero_skill_polonialeader1_effect_1:
        '<sprite=5> <color=#24E656FF>+10%</color> Defense for the party.',
    hero_skill_polonialeader1_effect_2:
        '<sprite=6> <color=#24E656FF>+5%</color> Evasion for the party.',
    hero_skill_polonialeader2_name: "Davy Jones' Muggin'",
    hero_skill_polonialeader2_desc:
        '"Take everything that\'s not nailed down!"',
    hero_skill_polonialeader2_effect:
        '35% chance to steal valuable items from monsters. +15% Defense for the party. +5% Evasion for the party.',
    hero_skill_polonialeader2_effect_0:
        '<sprite=13> <color=#24E656FF>35%</color> chance to steal valuable items from monsters.',
    hero_skill_polonialeader2_effect_1:
        '<sprite=5> <color=#24E656FF>+15%</color> Defense for the party.',
    hero_skill_polonialeader2_effect_2:
        '<sprite=6> <color=#24E656FF>+5%</color> Evasion for the party.',
    hero_skill_polonialeader3_name: 'High Sea Robbery',
    hero_skill_polonialeader3_desc:
        '"I smell dubloons, swabbies! Up and at \'em!',
    hero_skill_polonialeader3_effect:
        '40% chance to steal very valuable items from monsters. +20% Defense for the party. +10% Evasion for the party.',
    hero_skill_polonialeader3_effect_0:
        '<sprite=13> <color=#24E656FF>40%</color> chance to steal very valuable items from monsters.',
    hero_skill_polonialeader3_effect_1:
        '<sprite=5> <color=#24E656FF>+20%</color> Defense for the party.',
    hero_skill_polonialeader3_effect_2:
        '<sprite=6> <color=#24E656FF>+10%</color> Evasion for the party.',
    hero_skill_polonialeader4_name: "Captain's Share",
    hero_skill_polonialeader4_desc: '"Two shares for me is fair by me!"',
    hero_skill_polonialeader4_effect:
        '50% chance to steal super valuable items from monsters. +25% Defense for the party. +10% Evasion for the party.',
    hero_skill_polonialeader4_effect_0:
        '<sprite=13> <color=#24E656FF>50%</color> chance to steal super valuable items from monsters.',
    hero_skill_polonialeader4_effect_1:
        '<sprite=5> <color=#24E656FF>+25%</color> Defense for the party.',
    hero_skill_polonialeader4_effect_2:
        '<sprite=6> <color=#24E656FF>+10%</color> Evasion for the party.',
    hero_skill_donovanleader1_name: 'Alchemist Tonic',
    hero_skill_donovanleader1_desc:
        '"This will empower you temporarily. Mostly for the better."',
    hero_skill_donovanleader1_effect:
        'Party bonuses to Attack, HP, Critical Chance and Evasion depending on party composition.',
    hero_skill_donovanleader1_effect_0:
        '<sprite=4> <color=#24E656FF>+5%</color> bonus Attack to the party for each Spellcaster.',
    hero_skill_donovanleader1_effect_1:
        '<sprite=3> <color=#24E656FF>+5%</color> HP to the party for each Fighter.',
    hero_skill_donovanleader1_effect_2:
        '<sprite=19> <sprite=6> <color=#24E656FF>+3%</color> Critical Hit Chance & Evasion for each Rogue.',
    hero_skill_donovanleader2_name: 'Essence of Change',
    hero_skill_donovanleader2_desc:
        '"Welcome the change within you. Not that you can prevent it, at any rate."',
    hero_skill_donovanleader2_effect:
        'Party bonuses to Attack, HP, Critical Chance and Evasion depending on party composition.',
    hero_skill_donovanleader2_effect_0:
        '<sprite=4> <color=#24E656FF>+8%</color> Attack to the party for each Spellcaster.',
    hero_skill_donovanleader2_effect_1:
        '<sprite=3> <color=#24E656FF>+6%</color> HP to the party for each Fighter.',
    hero_skill_donovanleader2_effect_2:
        '<sprite=19> <sprite=6> <color=#24E656FF>+4%</color> Critical Hit Chance & Evasion for each Rogue. ',
    hero_skill_donovanleader3_name: 'Master of Transmutation',
    hero_skill_donovanleader3_desc:
        '"I\'ve yet to see something that cannot be remade, with the right approach."',
    hero_skill_donovanleader3_effect:
        'Party bonuses to Attack, HP, Critical Chance and Evasion depending on party composition.',
    hero_skill_donovanleader3_effect_0:
        '<sprite=4> <color=#24E656FF>+10%</color> Attack to the party for each Spellcaster.',
    hero_skill_donovanleader3_effect_1:
        '<sprite=3> <color=#24E656FF>+7%</color> HP to the party for each Fighter.',
    hero_skill_donovanleader3_effect_2:
        '<sprite=19> <sprite=6> <color=#24E656FF>+5%</color> Critical Hit Chance & Evasion for each Rogue.',
    hero_skill_donovanleader4_name: "Philosopher's Stone",
    hero_skill_donovanleader4_desc:
        '"Let your weakness become strength... tenfold!"',
    hero_skill_donovanleader4_effect:
        'Party bonuses to Attack, HP, Critical Chance and Evasion depending on party composition.',
    hero_skill_donovanleader4_effect_0:
        '<sprite=4> <color=#24E656FF>+14%</color> Attack to the party for each Spellcaster.',
    hero_skill_donovanleader4_effect_1:
        '<sprite=3> <color=#24E656FF>+10%</color> HP to the party for each Fighter.',
    hero_skill_donovanleader4_effect_2:
        '<sprite=19> <sprite=6> <color=#24E656FF>+7%</color> Critical Hit Chance & Evasion for each Rogue.',
    hero_skill_hemmaleader1_name: 'Empowering Drain',
    hero_skill_hemmaleader1_desc:
        '"We\'ll borrow that for just a minute..."',
    hero_skill_hemmaleader1_effect:
        "Drain vitality from your companions to empower Hemma's attacks. +5% HP for the party. ",
    hero_skill_hemmaleader1_effect_0:
        'Each turn, drain <color=#24E656FF>10%</color> HP from each ally, healing herself for <color=#24E656FF>2 HP</color>.',
    hero_skill_hemmaleader1_effect_1:
        "<sprite=4>Hemma's <color=#24E656FF>Attack</color> increases by <color=#24E656FF>6%</color> for each drain.",
    hero_skill_hemmaleader1_effect_2:
        '<sprite=3> <color=#24E656FF>+5%</color> HP for the party.',
    hero_skill_hemmaleader2_name: 'Ghastly Strike',
    hero_skill_hemmaleader2_desc:
        '"You won\'t stand in our way for long..."',
    hero_skill_hemmaleader2_effect:
        "Drain vitality from your companions to empower Hemma's attacks. +10% HP for the party. ",
    hero_skill_hemmaleader2_effect_0:
        'Each turn, drain <color=#24E656FF>9%</color> HP from each ally, healing herself for <color=#24E656FF>3 HP</color>.',
    hero_skill_hemmaleader2_effect_1:
        "<sprite=4>Hemma's <color=#24E656FF>Attack</color> increases by <color=#24E656FF>8%</color> for each drain.",
    hero_skill_hemmaleader2_effect_2:
        '<sprite=3> <color=#24E656FF>+10%</color> HP for the party.',
    hero_skill_hemmaleader3_name: 'Necromantic Spear',
    hero_skill_hemmaleader3_desc: '"Your strength is now ours!"',
    hero_skill_hemmaleader3_effect:
        "Drain vitality from your companions to empower Hemma's attacks. +15% HP for the party. ",
    hero_skill_hemmaleader3_effect_0:
        'Each turn, drain <color=#24E656FF>8%</color> HP from each ally, healing herself for <color=#24E656FF>5 HP</color>.',
    hero_skill_hemmaleader3_effect_1:
        "<sprite=4>Hemma's <color=#24E656FF>Attack</color> increases by <color=#24E656FF>10%</color> for each drain.",
    hero_skill_hemmaleader3_effect_2:
        '<sprite=3> <color=#24E656FF>+15%</color> HP for the party.',
    hero_skill_hemmaleader4_name: 'Consume Essence',
    hero_skill_hemmaleader4_desc:
        '"Each life taken emboldens us. And we have reaped... many."',
    hero_skill_hemmaleader4_effect:
        "Drain vitality from your companions to empower Hemma's attacks. +25% HP for the party. ",
    hero_skill_hemmaleader4_effect_0:
        'Each turn, drain <color=#24E656FF>7%</color> HP from each ally, healing herself for <color=#24E656FF>8 HP</color>.',
    hero_skill_hemmaleader4_effect_1:
        "<sprite=4>Hemma's <color=#24E656FF>Attack</color> increases by <color=#24E656FF>12%</color> for each drain.",
    hero_skill_hemmaleader4_effect_2:
        '<sprite=3> <color=#24E656FF>+25%</color> HP for the party.',
    hero_skill_ashleyleader1_name: 'Iaijutsu Tactics',
    hero_skill_ashleyleader1_desc:
        '"One strike is all I need. Can you prove me wrong?"',
    hero_skill_ashleyleader1_effect:
        "Bonuses to party's ATK, DEF and quest speed. Double bonuses VS bosses.",
    hero_skill_ashleyleader1_effect_0:
        '<sprite=4><sprite=5> <color=#24E656FF> +10%</color> bonus Attack and Defense for the party.',
    hero_skill_ashleyleader1_effect_1:
        '<sprite=18> <color=#24E656FF>+5%</color> faster quest time.',
    hero_skill_ashleyleader1_effect_2:
        'Bonuses are doubled against bosses.',
    hero_skill_ashleyleader2_name: "Ashley's Gambit",
    hero_skill_ashleyleader2_desc:
        '"If this can be redemption... I\'ll put everything on the line!"',
    hero_skill_ashleyleader2_effect:
        "Bonuses to party's ATK, DEF and quest speed. Double bonuses VS bosses.",
    hero_skill_ashleyleader2_effect_0:
        '<sprite=4><sprite=5> <color=#24E656FF> +15%</color> bonus Attack and Defense for the party.',
    hero_skill_ashleyleader2_effect_1:
        '<sprite=18> <color=#24E656FF>+10%</color> faster quest time.',
    hero_skill_ashleyleader2_effect_2:
        'Bonuses are doubled against bosses.',
    hero_skill_ashleyleader3_name: 'Fell The Greatest Foe',
    hero_skill_ashleyleader3_desc:
        '"My honor on the line! Don\'t let me down!"',
    hero_skill_ashleyleader3_effect:
        "Bonuses to party's ATK, DEF and quest speed. Double bonuses VS bosses.",
    hero_skill_ashleyleader3_effect_0:
        '<sprite=4><sprite=5> <color=#24E656FF> +20%</color> bonus Attack and Defense for the party.',
    hero_skill_ashleyleader3_effect_1:
        '<sprite=18> <color=#24E656FF>+15%</color> faster quest time.',
    hero_skill_ashleyleader3_effect_2:
        'Bonuses are doubled against bosses.',
    hero_skill_ashleyleader4_name: 'Bushido Force',
    hero_skill_ashleyleader4_desc:
        '"Test my limit! Take me down, if you can!"',
    hero_skill_ashleyleader4_effect:
        "Bonuses to party's ATK, DEF and quest speed. Double bonuses VS bosses.",
    hero_skill_ashleyleader4_effect_0:
        '<sprite=4><sprite=5> <color=#24E656FF> +25%</color> bonus Attack and Defense for the party.',
    hero_skill_ashleyleader4_effect_1:
        '<sprite=18> <color=#24E656FF>+20%</color> faster quest time.',
    hero_skill_ashleyleader4_effect_2:
        'Bonuses are doubled against bosses.',
    hero_skill_tamasleader1_name: 'Awakening of Leather',
    hero_skill_tamasleader1_desc:
        '"Oh hey, didn\'t see you there, dude. I was busy training to unlock the full potential of leather."',
    hero_skill_tamasleader1_effect: '',
    hero_skill_tamasleader1_effect_0: '',
    hero_skill_tamasleader1_effect_1: '',
    hero_skill_tamasleader1_effect_2: '',
    hero_skill_tamasleader2_name: 'Power of Leather',
    hero_skill_tamasleader2_desc: '',
    hero_skill_tamasleader2_effect: '',
    hero_skill_tamasleader2_effect_0: '',
    hero_skill_tamasleader2_effect_1: '',
    hero_skill_tamasleader2_effect_2: '',
    hero_skill_tamasleader3_name: 'League of Leather',
    hero_skill_tamasleader3_desc: '',
    hero_skill_tamasleader3_effect: '',
    hero_skill_tamasleader3_effect_0: '',
    hero_skill_tamasleader3_effect_1: '',
    hero_skill_tamasleader3_effect_2: '',
    hero_skill_tamasleader4_name: 'Ultimate Leatherworker',
    hero_skill_tamasleader4_desc: '',
    hero_skill_tamasleader4_effect: '',
    hero_skill_tamasleader4_effect_0: '',
    hero_skill_tamasleader4_effect_1: '',
    hero_skill_tamasleader4_effect_2: '',
    hero_skill_atlab_aangleader1_name: 'The Last Airbender',
    hero_skill_atlab_aangleader1_desc: '"I laugh at gravity all the time!"',
    hero_skill_atlab_aangleader1_effect:
        'Small bonus to party ATK and DEF. Also finds some elements after quests.',
    hero_skill_atlab_aangleader1_effect_0:
        '<sprite=4> <color=#24E656FF> +20%</color> bonus Attack for the party.',
    hero_skill_atlab_aangleader1_effect_1:
        '<sprite=5> <color=#24E656FF> +20%</color> bonus Defense for the party.',
    hero_skill_atlab_aangleader1_effect_2:
        'Earn a few elements as bonus loot.',
    hero_skill_atlab_aangleader2_name: 'Elemental Mastery',
    hero_skill_atlab_aangleader2_desc: '"I laugh at gravity all the time!"',
    hero_skill_atlab_aangleader2_effect:
        'Bonus to party ATK and DEF. Also finds a bunch of elements after quests.',
    hero_skill_atlab_aangleader2_effect_0:
        '<sprite=4> <color=#24E656FF> +30%</color> bonus Attack for the party.',
    hero_skill_atlab_aangleader2_effect_1:
        '<sprite=5> <color=#24E656FF> +30%</color> bonus Defense for the party.',
    hero_skill_atlab_aangleader2_effect_2:
        'Earn some elements as bonus loot.',
    hero_skill_atlab_aangleader3_name: 'Avatar State',
    hero_skill_atlab_aangleader3_desc:
        '"We’re all connected. Everything is connected."',
    hero_skill_atlab_aangleader3_effect:
        'Significant bonus to party ATK and DEF. Also finds a bunch of elements after quests.',
    hero_skill_atlab_aangleader3_effect_0:
        '<sprite=4> <color=#24E656FF> +40%</color> bonus Attack for the party.',
    hero_skill_atlab_aangleader3_effect_1:
        '<sprite=5> <color=#24E656FF> +40%</color> bonus Defense for the party.',
    hero_skill_atlab_aangleader3_effect_2:
        'Earn some elements as bonus loot.',
    hero_skill_atlab_aangleader4_name: "Avatar's Wrath",
    hero_skill_atlab_aangleader4_desc:
        '"We’re all connected. Everything is connected."',
    hero_skill_atlab_aangleader4_effect:
        'Large bonus to party ATK and DEF. Also finds lots of elements after quests!',
    hero_skill_atlab_aangleader4_effect_0:
        '<sprite=4> <color=#24E656FF> +50%</color> bonus Attack for the party.',
    hero_skill_atlab_aangleader4_effect_1:
        '<sprite=5> <color=#24E656FF> +50%</color> bonus Defense for the party.',
    hero_skill_atlab_aangleader4_effect_2:
        'Earn lots of elements as bonus loot.',
    hero_skill_atlab_sokkaleader1_name: 'Hunter Gatherer',
    hero_skill_atlab_sokkaleader1_desc:
        'Boomerang! You DO always come back!',
    hero_skill_atlab_sokkaleader1_effect:
        '+10% bonus Attack and HP for the party. +50% XP for the party. +50% components found.',
    hero_skill_atlab_sokkaleader1_effect_0:
        '<sprite=4><sprite=3> <color=#24E656FF> +10%</color> bonus Attack and HP for the party.',
    hero_skill_atlab_sokkaleader1_effect_1:
        '<sprite=13> <color=#24E656FF>+50%</color> more components found in quests.',
    hero_skill_atlab_sokkaleader1_effect_2:
        '<color=#24E656FF>+50%</color> XP earned by the party.',
    hero_skill_atlab_sokkaleader2_name: 'Field Recon',
    hero_skill_atlab_sokkaleader2_desc:
        'Boomerang! You DO always come back!',
    hero_skill_atlab_sokkaleader2_effect:
        '+15% bonus Attack and HP for the party. +50% XP for the party. +50% components found.',
    hero_skill_atlab_sokkaleader2_effect_0:
        '<sprite=4><sprite=3> <color=#24E656FF> +15%</color> bonus Attack and HP for the party.',
    hero_skill_atlab_sokkaleader2_effect_1:
        '<sprite=13> <color=#24E656FF>+50%</color> more components found in quests.',
    hero_skill_atlab_sokkaleader2_effect_2:
        '<color=#24E656FF>+50%</color> XP earned by the party.',
    hero_skill_atlab_sokkaleader3_name: 'Team Avatar Tactics',
    hero_skill_atlab_sokkaleader3_desc:
        '"It\'s pretty much my whole identity. Sokka, the meat and sarcasm guy."',
    hero_skill_atlab_sokkaleader3_effect:
        '+20% bonus Attack and HP for the party. +75% XP for the party. +75% components found.',
    hero_skill_atlab_sokkaleader3_effect_0:
        '<sprite=4><sprite=3> <color=#24E656FF> +20%</color> bonus Attack and HP for the party.',
    hero_skill_atlab_sokkaleader3_effect_1:
        '<sprite=13> <color=#24E656FF>+75%</color> more components found in quests.',
    hero_skill_atlab_sokkaleader3_effect_2:
        '<color=#24E656FF>+75%</color> XP earned by the party.',
    hero_skill_atlab_sokkaleader4_name: 'The Perfect Plan',
    hero_skill_atlab_sokkaleader4_desc:
        '"It\'s pretty much my whole identity. Sokka, the meat and sarcasm guy."',
    hero_skill_atlab_sokkaleader4_effect:
        '+30% bonus Attack and HP for the party. +100% XP for the party. +75% components found.',
    hero_skill_atlab_sokkaleader4_effect_0:
        '<sprite=4><sprite=3> <color=#24E656FF> +30%</color> bonus Attack and HP for the party.',
    hero_skill_atlab_sokkaleader4_effect_1:
        '<sprite=13> <color=#24E656FF>+75%</color> more components found in quests.',
    hero_skill_atlab_sokkaleader4_effect_2:
        '<color=#24E656FF>+100%</color> XP earned by the party.',
    skill_p_cleave1_name: 'Cleave',
    skill_p_cleave2_name: 'Searing Cleave',
    skill_p_cleave3_name: 'Scorching Cyclone',
    skill_p_cleave_desc:
        'You can also slash, chop or dice. No need to be finicky.',
    skill_p_perforate1_name: 'Perforate',
    skill_p_perforate2_name: 'Impale',
    skill_p_perforate3_name: 'Sonic Thrust',
    skill_p_perforate_desc:
        'Poking holes in things may reduce their life expectancy.',
    skill_p_smite1_name: 'Smite',
    skill_p_smite2_name: 'Righteous Smite',
    skill_p_smite3_name: 'Deliverance',
    skill_p_smite_desc: 'The Goddess can be a bit vindictive, at times.',
    skill_p_onguard1_name: 'On Guard',
    skill_p_onguard2_name: 'Frost Stance',
    skill_p_onguard3_name: 'Indomitable Glacier',
    skill_p_onguard_desc: 'We stand on guard for thee.',
    skill_p_backstab1_name: 'Backstab',
    skill_p_backstab2_name: 'Darkstalker',
    skill_p_backstab3_name: 'Widowmaker',
    skill_p_backstab_desc: 'Just a teeny bit dishonorable.',
    skill_p_magicdarts1_name: 'Magic Darts',
    skill_p_magicdarts2_name: 'Magic Missile',
    skill_p_magicdarts3_name: 'Kinetic Lance',
    skill_p_magicdarts_desc: 'Always a hit for bar games.',
    skill_p_parry1_name: 'Parry',
    skill_p_parry2_name: 'Parry & Riposte',
    skill_p_parry3_name: 'Blade Barrier',
    skill_p_parry_desc:
        'Stunt performed by trained professional. Do not try at home.',
    skill_p_arcaneblast1_name: 'Arcane Blast',
    skill_p_arcaneblast2_name: 'Fire Blast',
    skill_p_arcaneblast3_name: 'Eldritch Blast',
    skill_p_arcaneblast_desc: 'Conjures a faceful of raw magical energy.',
    skill_p_hex1_name: 'Hex',
    skill_p_hex2_name: 'Ruinous Hex',
    skill_p_hex3_name: 'Forbidden Hex',
    skill_p_hex_desc:
        'Casts the evil eye upon your foe, cursing them with terrible luck. Watch them trip over their own feet!',
    skill_p_lifedrain1_name: 'Life Drain',
    skill_p_lifedrain2_name: 'Essence Drain',
    skill_p_lifedrain3_name: 'Soul Drain',
    skill_p_lifedrain_desc:
        'Steals the very life essence from your enemies to bolster yours. Tastes fruity.',
    skill_p_magearmor1_name: 'Mage Armor',
    skill_p_magearmor2_name: 'Magic Shell',
    skill_p_magearmor3_name: 'Greater Magic Shell',
    skill_p_magearmor_desc: 'Is not an adequate substitute for sunscreen.',
    skill_p_powerattack1_name: 'Power Attack',
    skill_p_powerattack2_name: 'Icebreaker',
    skill_p_powerattack3_name: 'Iceberg Smash',
    skill_p_powerattack_desc:
        "If hitting hard doesn't work, try hitting harder.",
    skill_p_caltrops1_name: 'Caltrops',
    skill_p_caltrops2_name: 'Spiked Trap',
    skill_p_caltrops3_name: 'Shrapnel Grenade',
    skill_p_caltrops_desc: 'Make sure to wear thick boots...',
    skill_p_flamebrand1_name: 'Flame Brand',
    skill_p_flamebrand2_name: 'Sacred Fire',
    skill_p_flamebrand3_name: 'Baptism by Fire',
    skill_p_flamebrand_desc: 'Cuts AND toasts bread at the same time!',
    skill_p_thickskin1_name: 'Thick Skin',
    skill_p_thickskin2_name: 'Stone Skin',
    skill_p_thickskin3_name: 'Adamantine Skin',
    skill_p_thickskin_desc: 'One way of going through everyday life.',
    skill_p_finesse1_name: 'Finesse',
    skill_p_finesse2_name: 'Expertise',
    skill_p_finesse3_name: 'Dueling Grace',
    skill_p_finesse_desc:
        "Flashy knife twirling isn't much use in a fight, but it sends a message.",
    skill_p_curse1_name: 'Curse',
    skill_p_curse2_name: 'Ancient Curse',
    skill_p_curse3_name: 'Eternal Curse',
    skill_p_curse_desc: 'Rude expletives are optional.',
    skill_p_glancingblows1_name: 'Glancing Blows',
    skill_p_glancingblows2_name: "Scoundrel's Luck",
    skill_p_glancingblows3_name: "Fate's Favored",
    skill_p_glancingblows_desc: 'Some could even call it plot armor.',
    skill_p_electricarc1_name: 'Electric Arc',
    skill_p_electricarc2_name: 'Lightning Bolt',
    skill_p_electricarc3_name: 'Chain Lightning',
    skill_p_electricarc_desc: '10,000 volts of static!',
    skill_p_shadows1_name: 'Shadow Embrace',
    skill_p_shadows2_name: 'Overwhelming Darkness',
    skill_p_shadows3_name: 'Abyssal Grasp',
    skill_p_shadows_desc: 'So cold, so lonely.',
    skill_p_dragonbreath1_name: 'Dragon Breath',
    skill_p_dragonbreath2_name: 'Wyrm Breath',
    skill_p_dragonbreath3_name: 'Breath of Bahamut',
    skill_p_dragonbreath_desc:
        "Thankfully the dragons haven't copyrighted that move yet.",
    skill_p_wallofforce1_name: 'Wall of Force',
    skill_p_wallofforce2_name: 'Wall of Mirrors',
    skill_p_wallofforce3_name: 'Mage Fortress',
    skill_p_wallofforce_desc:
        "Nigh-impenetrable and completely see-through, so don't leave them all over the place.",
    skill_p_sunder1_name: 'Sunder',
    skill_p_sunder2_name: 'Shatter',
    skill_p_sunder3_name: 'Groundbreaker',
    skill_p_sunder_desc:
        'Your party may not appreciate you smashing all the equipment (and loot) to pieces.',
    skill_p_throwing1_name: 'Throw Daggers',
    skill_p_throwing2_name: 'Throw Shuriken',
    skill_p_throwing3_name: 'Shuriken Storm',
    skill_p_throwing_desc: 'Add a little range to your stabs.',
    skill_p_shiningblade1_name: 'Shining Blade',
    skill_p_shiningblade2_name: 'Blessed Blade',
    skill_p_shiningblade3_name: 'Holy Warrior',
    skill_p_shiningblade_desc:
        'This blade of mine glows with an awesome power...!',
    skill_p_hinder1_name: 'Hinder',
    skill_p_hinder2_name: 'Impede',
    skill_p_hinder3_name: 'TKO Blow',
    skill_p_hinder_desc: 'Definitely below the belt.',
    skill_p_antimagicnet1_name: 'Antimagic Net',
    skill_p_antimagicnet2_name: 'Magebane Web',
    skill_p_antimagicnet3_name: 'Ethereal Prison',
    skill_p_antimagicnet_desc: 'Wizards HATE this one trick.',
    skill_p_fireball1_name: 'Fireball',
    skill_p_fireball2_name: 'Magma Orb',
    skill_p_fireball3_name: 'Nuclear Blast',
    skill_p_fireball_desc: 'The one and only.',
    skill_p_disintegrate1_name: 'Disintegrate',
    skill_p_disintegrate2_name: 'Annihilation',
    skill_p_disintegrate3_name: 'Oblivion',
    skill_p_disintegrate_desc: 'Turns just about anything to dust.',
    skill_p_summondemon1_name: 'Summon Demon',
    skill_p_summondemon2_name: 'Summon Demon Army',
    skill_p_summondemon3_name: 'Summon Demon Lord',
    skill_p_summondemon_desc:
        "When it comes to wreaking havoc, summoned demons don't need much convincing.",
    skill_p_batteringblows1_name: 'Battering Blows',
    skill_p_batteringblows2_name: 'Relentless Assault',
    skill_p_batteringblows3_name: 'Meteor Onslaught',
    skill_p_batteringblows_desc:
        "It's like beating on a drum, except the drum is screaming.",
    skill_p_whirlwind1_name: 'Whirlwind Attack',
    skill_p_whirlwind2_name: 'Tempest Slash',
    skill_p_whirlwind3_name: 'Omnislash',
    skill_p_whirlwind_desc:
        'Throw away any pretense of grace and just spin like mad!',
    skill_p_perfectform1_name: 'Perfect Form',
    skill_p_perfectform2_name: 'Ascended Form',
    skill_p_perfectform3_name: 'Divine Form',
    skill_p_perfectform_desc: 'Breathe in, breathe out.',
    skill_p_extraplating1_name: 'Extra Plating',
    skill_p_extraplating2_name: 'Layered Armor',
    skill_p_extraplating3_name: 'Reinforced',
    skill_p_extraplating_desc: 'More plating, for the road.',
    skill_p_assassinate1_name: 'Assassinate',
    skill_p_assassinate2_name: 'Eliminate',
    skill_p_assassinate3_name: 'Snuff Life',
    skill_p_assassinate_desc:
        'Why make it flashy when only the results matter?',
    skill_p_bladedance1_name: 'Dance of Blades',
    skill_p_bladedance2_name: 'Dance of Death',
    skill_p_bladedance3_name: 'Lethal Finale',
    skill_p_bladedance_desc: 'Become a raging storm of nicks and cuts.',
    skill_p_cloakndagger1_name: 'Cloak & Dagger',
    skill_p_cloakndagger2_name: 'Ace in the Sleeve',
    skill_p_cloakndagger3_name: 'Secret Weapon',
    skill_p_cloakndagger_desc:
        'The dashing rogue gets into all sorts of trouble, yet always somehow escapes unscathed.',
    skill_p_megapunch1_name: 'Destructive Strikes',
    skill_p_megapunch2_name: 'Fatal Blows',
    skill_p_megapunch3_name: 'Ruination of Foes',
    skill_p_megapunch_desc:
        'The Grandmaster has ascended beyond mortal means of combat.',
    skill_p_doublecast1_name: 'Double Cast',
    skill_p_doublecast2_name: 'Triple Cast',
    skill_p_doublecast3_name: 'Tetra Cast',
    skill_p_doublecast_desc:
        'Two spells for the price of one? Count me in!',
    skill_p_petrify1_name: 'Petrify',
    skill_p_petrify2_name: 'Calcify',
    skill_p_petrify3_name: 'Entomb',
    skill_p_petrify_desc:
        "If you're lucky, you might catch them doing funny poses.",
    skill_p_poisoncloud1_name: 'Poison Cloud',
    skill_p_poisoncloud2_name: 'Toxic Cloud',
    skill_p_poisoncloud3_name: 'Cloudkill',
    skill_p_poisoncloud_desc: 'Doubles as efficient vermin removal.',
    skill_p_manashield1_name: 'Mana Shield',
    skill_p_manashield2_name: 'Soul Shield',
    skill_p_manashield3_name: 'Void Shield',
    skill_p_manashield_desc:
        'For the wizard who has more mana than friends.',
    skill_s_break1_name: 'Maintenance',
    skill_s_break2_name: 'Extra Polish',
    skill_s_break3_name: 'Mirror Sheen',
    skill_s_break_desc: 'Home Economics 101.',
    skill_s_megabreak1_name: 'Extended Warranty',
    skill_s_megabreak2_name: 'Safekept Receipt',
    skill_s_megabreak3_name: 'Lifetime Warranty',
    skill_s_megabreak_desc:
        "Yet you can't escape the planned obsolescence.",
    skill_s_xp1_name: 'Fast Learner',
    skill_s_xp2_name: 'Head of the Class',
    skill_s_xp3_name: 'Savant',
    skill_s_xp_desc: 'Learn, and fight another day.',
    skill_s_megaxp1_name: 'Super Genius',
    skill_s_megaxp2_name: 'Ultra Genius',
    skill_s_megaxp3_name: 'Big Smart',
    skill_s_megaxp_desc: '4 digits worth of IQ.',
    skill_s_superhpregen1_name: 'Fast Healer',
    skill_s_superhpregen2_name: 'Troll-skinned',
    skill_s_superhpregen3_name: 'Regenerator',
    skill_s_superhpregen_desc: "Nothing a bandage can't fix.",
    skill_s_megahpregen1_name: 'Survivor',
    skill_s_megahpregen2_name: 'Never Broken',
    skill_s_megahpregen3_name: 'Stalwart',
    skill_s_megahpregen_desc: "Don't worry, ribs grow back.",
    skill_s_hphpregen1_name: 'Toughness',
    skill_s_hphpregen2_name: 'Resilience',
    skill_s_hphpregen3_name: "Hero's Mettle",
    skill_s_hphpregen_desc: 'Stub a toe without shedding a single tear.',
    skill_s_hp1_name: 'Sturdy',
    skill_s_hp2_name: 'Tough-as-Nails',
    skill_s_hp3_name: 'Unbreakable',
    skill_s_hp_desc: 'Flinching is for the weak.',
    skill_s_megahp1_name: 'Impervious',
    skill_s_megahp2_name: 'Invulnerable',
    skill_s_megahp3_name: 'Immortal',
    skill_s_megahp_desc:
        "Yeah, that's an axe embedded in my skull. What about it?",
    skill_s_hpdef1_name: 'Juggernaut',
    skill_s_hpdef2_name: 'Behemoth',
    skill_s_hpdef3_name: 'Titan',
    skill_s_hpdef_desc: "What's under all this armor? More armor.",
    skill_s_evasion1_name: 'Acrobatics',
    skill_s_evasion2_name: 'Slippery',
    skill_s_evasion3_name: 'Otherworldly Grace',
    skill_s_evasion_desc: 'With moves like these, why wear armor?',
    skill_s_megaevasion1_name: 'Blurred Movement',
    skill_s_megaevasion2_name: 'Inhuman Speed',
    skill_s_megaevasion3_name: 'Sonic Speed',
    skill_s_megaevasion_desc: 'So fast, you might get a speeding ticket.',
    skill_s_defevasion1_name: 'Extra Conditioning',
    skill_s_defevasion2_name: 'Heroic Workout',
    skill_s_defevasion3_name: 'Herculean Effort',
    skill_s_defevasion_desc: "Don't cut gym, kids.",
    skill_s_evasioncrit1_name: 'Deception',
    skill_s_evasioncrit2_name: 'Perfect Feint',
    skill_s_evasioncrit3_name: 'Smoke Bomb',
    skill_s_evasioncrit_desc: 'A timely distraction leaves many openings.',
    skill_s_crit1_name: 'Eagle Eyes',
    skill_s_crit2_name: 'Lock On',
    skill_s_crit3_name: "Mind's Eye",
    skill_s_crit_desc:
        'An uncanny knack for spotting out the glowing red spots.',
    skill_s_megacrit1_name: 'All Natural',
    skill_s_megacrit2_name: 'Live by the Die',
    skill_s_megacrit3_name: 'Final Gambit',
    skill_s_megacrit_desc:
        'At some point, landing a regular hit becomes a surprise.',
    skill_s_critmult1_name: 'Deadly Criticals',
    skill_s_critmult2_name: 'Coup de Grace',
    skill_s_critmult3_name: 'Executioner',
    skill_s_critmult_desc: 'Make them count.',
    skill_s_megacritmult1_name: 'Death Dealer',
    skill_s_megacritmult2_name: 'All In',
    skill_s_megacritmult3_name: 'Ultimate Gamble',
    skill_s_megacritmult_desc: 'Double or nothing.',
    skill_s_critcritmult1_name: 'Telling Blows',
    skill_s_critcritmult2_name: 'Lights Out',
    skill_s_critcritmult3_name: 'True Strike',
    skill_s_critcritmult_desc:
        'Leave it all to chance (and a stacked deck).',
    skill_s_swordmaster1_name: 'Sword Master',
    skill_s_swordmaster2_name: 'Sword Lord',
    skill_s_swordmaster3_name: 'Sword Titan',
    skill_s_swordmaster_desc:
        'The grace with which a Swordmaster wields a blade is a sight to behold.',
    skill_s_axemaster1_name: 'Axe Master',
    skill_s_axemaster2_name: 'Axe Lord',
    skill_s_axemaster3_name: 'Axe Titan',
    skill_s_axemaster_desc:
        'Grace and finesse? No thanks. Give me the BIG damage.',
    skill_s_daggermaster1_name: 'Dagger Master',
    skill_s_daggermaster2_name: 'Dagger Lord',
    skill_s_daggermaster3_name: 'Dagger Titan',
    skill_s_daggermaster_desc:
        'For those who want to get uncomfortably close and personal.',
    skill_s_macemaster1_name: 'Mace Master',
    skill_s_macemaster2_name: 'Mace Lord',
    skill_s_macemaster3_name: 'Mace Titan',
    skill_s_macemaster_desc:
        'If you need some moles whacked, look no further.',
    skill_s_spearmaster1_name: 'Spear Master',
    skill_s_spearmaster2_name: 'Spear Lord',
    skill_s_spearmaster3_name: 'Spear Titan',
    skill_s_spearmaster_desc: 'Reaching out to others... to stab them.',
    skill_s_bowmaster1_name: 'Bow Master',
    skill_s_bowmaster2_name: 'Bow Lord',
    skill_s_bowmaster3_name: 'Bow Titan',
    skill_s_bowmaster_desc:
        'Confers the ability to shoot apples out of trees.',
    skill_s_staffmaster1_name: 'Staff Master',
    skill_s_staffmaster2_name: 'Staff Lord',
    skill_s_staffmaster3_name: 'Staff Titan',
    skill_s_staffmaster_desc:
        'The uncanny ability to bond with the magical spirits present within staves.',
    skill_s_crossbowmaster1_name: 'Crossbow Master',
    skill_s_crossbowmaster2_name: 'Crossbow Lord',
    skill_s_crossbowmaster3_name: 'Crossbow Titan',
    skill_s_crossbowmaster_desc:
        'The crossbow is a simple weapon to use, but its masters are few.',
    skill_s_gunmaster1_name: 'Gun Master',
    skill_s_gunmaster2_name: 'Gun Lord',
    skill_s_gunmaster3_name: 'Gun Titan',
    skill_s_gunmaster_desc: 'Strong support for rights, bears and arms.',
    skill_s_wandmaster1_name: 'Wand Master',
    skill_s_wandmaster2_name: 'Wand Lord',
    skill_s_wandmaster3_name: 'Wand Titan',
    skill_s_wandmaster_desc:
        'One who has understood that it is the wand that chooses its wizard.',
    skill_s_shieldmaster1_name: 'Shield Master',
    skill_s_shieldmaster2_name: 'Shield Lord',
    skill_s_shieldmaster3_name: 'Shield Titan',
    skill_s_shieldmaster_desc: 'Impervious is not putting it lightly.',
    skill_s_warmaster1_name: 'Warlord',
    skill_s_warmaster2_name: 'War Master',
    skill_s_warmaster3_name: 'God of War',
    skill_s_warmaster_desc:
        'Unmatched mastery with all implements of warfare.',
    skill_s_snipermaster1_name: 'Marksman',
    skill_s_snipermaster2_name: 'Master Sniper',
    skill_s_snipermaster3_name: 'Deadeye',
    skill_s_snipermaster_desc: 'Perfect precision with any weapon.',
    skill_s_arcanemaster1_name: 'Adept',
    skill_s_arcanemaster2_name: 'Archmage',
    skill_s_arcanemaster3_name: 'Elder Archmage',
    skill_s_arcanemaster_desc:
        'You would not part an old man from his walking stick?',
    skill_c_soldier1_name: 'Martial Training',
    skill_c_soldier2_name: 'Martial Expert',
    skill_c_soldier3_name: 'Martial Master',
    skill_c_soldier_effect: 'Learns skills faster than other classes.',
    skill_c_soldier1_effect: 'Learns skills faster than other classes.',
    skill_c_soldier2_effect: 'Learns skills faster than other classes.',
    skill_c_soldier3_effect: 'Learns skills faster than other classes.',
    skill_c_soldier_desc:
        "A Soldier's military background allows them to develop their own style faster than others.",
    skill_c_mercenary_effect_details:
        '+{0} increased effect received from Champion skills.',
    skill_c_mercenary1_name: 'Second-in-Command',
    skill_c_mercenary2_name: 'Elite Mercenary',
    skill_c_mercenary3_name: 'Supreme Sellsword',
    skill_c_mercenary_desc:
        'Mercenaries take orders to the letter, and will follow their general to the end of the earth.',
    skill_c_barbarian1_name: "Lion's Roar",
    skill_c_barbarian2_name: "Chieftain's Call",
    skill_c_barbarian3_name: "Dragon's Roar",
    skill_c_barbarian_desc:
        "The Barbarian's fighting style, while brutally effective, is wild and loud, making it a difficult threat to ignore.",
    skill_c_barbarian_effect:
        'Tendency to attract enemy attacks. Extra tough.',
    skill_c_chieftain1_name: 'Call of the Tribe',
    skill_c_chieftain2_name: "Chieftain's Authority",
    skill_c_chieftain3_name: 'Battle Call',
    skill_c_chieftain_desc:
        'To protect their tribe, Chieftains take on the frontline with absolute ferocity.',
    skill_c_chieftain_effect: 'Attracts enemy attacks. Extra tough.',
    skill_c_chieftain_effect_details_1: '{0} HP',
    skill_c_chieftain_effect_details_2:
        '{0} Threat. {1} of total Threat is added as an attack multiplier.',
    skill_c_knight1_name: 'Fortress',
    skill_c_knight2_name: 'Citadel',
    skill_c_knight3_name: 'Bastion',
    skill_c_knight_desc:
        'Even Paladins envy the ease with which Knights wear and use armor.',
    skill_c_knight_effect: 'Bonus Defense from Heavy Armor.',
    skill_c_lord1_name: 'Bodyguard',
    skill_c_lord2_name: 'Lifesaver',
    skill_c_lord3_name: 'Knight Lord',
    skill_c_lord_desc: 'To protect the weak, no sacrifice is too great.',
    skill_c_lord_effect:
        'Bonus Defense from Heavy Armor. Can protect weak allies from attacks.',
    skill_c_lord_effect_details:
        'Protect an ally from a Lethal attack once per battle.',
    skill_c_ranger1_name: 'Self-sufficient',
    skill_c_ranger2_name: 'Live Off the Land',
    skill_c_ranger3_name: 'Hunter-Hermit',
    skill_c_ranger_desc:
        'When living alone in the wilderness, one learns to do much with very little.',
    skill_c_ranger_effect: 'Shorter rest time.',
    skill_c_swordmaster1_name: 'Iaido',
    skill_c_swordmaster2_name: 'Lightning Iaido',
    skill_c_swordmaster3_name: 'Iaido Master',
    skill_c_swordmaster_desc:
        'Samurai know that the battle is over as soon as the blade is drawn.',
    skill_c_swordmaster_effect:
        "The Samurai's first attack is always a critical hit.",
    skill_c_swordmaster1_effect:
        "The Samurai's first attack is always a critical hit.",
    skill_c_swordmaster2_effect:
        "The Samurai's first attack is always a critical hit.",
    skill_c_swordmaster3_effect:
        "The Samurai's first attack is always a critical hit.",
    skill_c_daimyo1_name: 'Daimyo Iaido',
    skill_c_daimyo2_name: 'Divine Iaido',
    skill_c_daimyo3_name: 'Perfect Form Iaido',
    skill_c_daimyo_desc:
        'To deal in death is to expect it in turn. Daimyo are well aware.',
    skill_c_daimyo_effect:
        'Guaranteed evasion and critical hit on the first round.',
    skill_c_daimyo1_effect:
        'Guaranteed evasion and critical hit on the first round.',
    skill_c_daimyo2_effect:
        'Guaranteed evasion and critical hit on the first round.',
    skill_c_daimyo3_effect:
        'Guaranteed evasion and critical hit on the first round.',
    skill_c_berserker1_name: 'Berserk Rage',
    skill_c_berserker2_name: 'Anger Point',
    skill_c_berserker3_name: 'The Beast Within',
    skill_c_berserker_desc:
        'For most warriors, a grave wound is crippling. For a Berserker, it merely marks the start of a good fight.',
    skill_c_berserker_effect: 'Grows in power at low HP.',
    skill_c_berserker_effect_details_1:
        '{0} and {1} when HP falls below {2}.',
    skill_c_berserker_effect_details_2:
        'Effect is doubled when below {0} HP and tripled when below {1} HP.',
    skill_c_thief1_name: 'Scoundrel',
    skill_c_thief2_name: 'Dashing Rogue',
    skill_c_thief3_name: 'Master Thief',
    skill_c_thief_desc:
        "Daggers are like a natural extension of the Thief's hands.",
    skill_c_thief_effect: 'Bonus Attack from Daggers.',
    skill_c_trickster1_name: "Trickster's Edge",
    skill_c_trickster2_name: 'Killing Joke',
    skill_c_trickster3_name: 'Final Trick',
    skill_c_trickster_desc:
        'Tricksters tend to favor the prickly kind of tricks.',
    skill_c_trickster_effect:
        'Bonus Attack from Daggers. Supports Polonia.',
    skill_c_trickster_effect_details_1_1: '{0}',
    skill_c_trickster_effect_details_1_2: '{0} {1} Hp.',
    skill_c_trickster_effect_details_2:
        "+{0} to Polonia's steal chance. +{1} max items stolen.",
    skill_c_conquistador1_name: 'Critical Combo',
    skill_c_conquistador2_name: 'Critical Chain',
    skill_c_conquistador3_name: 'Critical King',
    skill_c_conquistador_desc:
        'Bullseye after bullseye is simply the norm.',
    skill_c_conquistador_effect:
        'Bonus Critical Damage for consecutive Critical Hits.',
    skill_c_conquistador_effect_details_1_1: '{0} Critical Hit Chance ',
    skill_c_conquistador_effect_details_1_2:
        '{0} Critical Hit Chance. {1} Critical Hit Damage',
    skill_c_conquistador_effect_details_2:
        '{0} more critical damage per consecutive critical hits. Stacks {1} times.',
    skill_c_monk1_name: 'Pressure Points',
    skill_c_monk2_name: 'Pain Points',
    skill_c_monk3_name: 'Forbidden Technique',
    skill_c_monk_desc:
        "By aiming at invisible weak points of their opponent's physiology, the Monk can deliver devastating strikes.",
    skill_c_monk_effect: 'Powerful Criticals. Bonus from Rings.',
    skill_c_mastermonk1_name: "Grandmaster's Secret",
    skill_c_mastermonk2_name: 'Broken Vows',
    skill_c_mastermonk3_name: 'Last Resort',
    skill_c_mastermonk_desc:
        'The Grandmaster holds exclusive mastery over a seldom used skill of extreme destructive power.',
    skill_c_mastermonk_effect:
        'Bonus from Rings. May learn a powerful, exclusive skill.',
    skill_c_mastermonk_effect_details_1: '{0}. {1} Critical Damage.',
    skill_c_mastermonk_effect_details_2:
        'May learn a very powerful exclusive skill.',
    skill_c_musketeer1_name: "Bull's eye",
    skill_c_musketeer2_name: 'Panache',
    skill_c_musketeer3_name: 'Thrillseeker',
    skill_c_musketeer_desc:
        "The Musketeer's quest for panache means they always aim for the riskiest of attacks.",
    skill_c_musketeer_effect: 'High critical hit chance.',
    skill_c_wanderer1_name: 'Evasive',
    skill_c_wanderer2_name: 'On the Move',
    skill_c_wanderer3_name: 'The Longest Road',
    skill_c_wanderer_desc:
        'Wanderers are constantly on the move, whether on the road or in battle.',
    skill_c_wanderer_effect: 'High Evasion chance.',
    skill_c_pathfinder1_name: "Pathfinder's Grace",
    skill_c_pathfinder2_name: 'The Path Least Travelled',
    skill_c_pathfinder3_name: 'Beyond Evasion',
    skill_c_pathfinder_desc:
        "To be beyond compare is not beyond the Pathfinder's ambition.",
    skill_c_pathfinder_effect_details_2: '{0} to max Evasion',
    skill_c_ninja1_name: 'Shadow Assassin',
    skill_c_ninja2_name: 'Darkness Menace',
    skill_c_ninja3_name: 'One With the Dark',
    skill_c_ninja_desc:
        'Ninjas stalk the shadows to deliver a swift, invisible demise upon their mark.',
    skill_c_ninja_effect:
        'Bonus to Evasion and Critical Hit Chance until damaged. Powerful Criticals',
    skill_c_ninja_stat_postfix: 'until damaged',
    skill_c_sensei1_name: 'True Assassin',
    skill_c_sensei2_name: 'Silencer',
    skill_c_sensei3_name: 'Shadowborn',
    skill_c_sensei_desc:
        'The master among masters leaves enemies wondering if it ever existed in the first place.',
    skill_c_sensei_effect:
        'Bonus to Evasion and Critical Hit Chance until damaged. Regains bonuses after taking damage.',
    skill_c_sensei_effect_details_1: '{0} and {1} until damaged.',
    skill_c_sensei_effect_details_2: 'Regain bonuses after {0} rounds.',
    skill_c_dancer1_name: 'Elegant Riposte',
    skill_c_dancer2_name: 'Stinging Riposte',
    skill_c_dancer3_name: 'Devastating Riposte',
    skill_c_dancer_effect:
        'Guarantees a Critical Hit after dodging an attack.',
    skill_c_dancer_desc: 'Fly like a butterfly and sting like a bee.',
    skill_c_dancer1_effect:
        'Guarantees a Critical Hit after dodging an attack.',
    skill_c_dancer2_effect:
        'Guarantees a Critical Hit after dodging an attack.',
    skill_c_dancer3_effect:
        'Guarantees a Critical Hit after dodging an attack.',
    skill_c_mage1_name: 'Arcanist',
    skill_c_mage2_name: 'High Arcanist',
    skill_c_mage3_name: 'Thaumaturgist ',
    skill_c_mage_desc:
        'Burn it. Blast it. Zap it. Mages rely on their offensive prowess to solve everyday problems.',
    skill_c_mage_effect: 'High Attack power.',
    skill_c_archmage1_name: 'Archmagic',
    skill_c_archmage2_name: 'Arcane Lore',
    skill_c_archmage3_name: 'Arcane Secrets',
    skill_c_archmage_desc: 'Rewrite reality with the flick of a finger.',
    skill_c_archmage_effect: 'High Attack power.',
    skill_c_cleric1_name: 'Divine Intervention',
    skill_c_cleric2_name: 'Chosen',
    skill_c_cleric3_name: 'Crusader',
    skill_c_cleric_desc:
        'The Goddess loves all of her children equally, but clerics do get some preferential treatment.',
    skill_c_cleric_effect: 'Survives a fatal blow once per quest.',
    skill_c_bishop1_name: 'True Chosen',
    skill_c_bishop2_name: 'Divine Favor',
    skill_c_bishop3_name: 'Miracle',
    skill_c_bishop_desc:
        'Backed by the Goddess herself, this one will not go down easy.',
    skill_c_bishop_effect:
        'Survives a fatal blow once per quest. Heals HP every round.',
    skill_c_druid1_name: 'Primal Magic',
    skill_c_druid2_name: 'Ancestral Power',
    skill_c_druid3_name: 'Might of the Land',
    skill_c_druid_desc:
        'Druids are well-versed in natural and arcane lore. Magical herbs are no secret to them.',
    skill_c_druid_effect: 'Bonus from Herbs.',
    skill_c_sorcerer1_name: 'Dark Pact',
    skill_c_sorcerer2_name: 'Blood Pact',
    skill_c_sorcerer3_name: 'Fell Pact',
    skill_c_sorcerer_desc:
        'Contracts with otherworldly entities have granted Sorcerers... gruesome powers.',
    skill_c_sorcerer_effect:
        'Has a chance to learn powerful, exclusive skills.',
    skill_c_sorcerer1_effect:
        'Has a chance to learn powerful, exclusive skills.',
    skill_c_sorcerer2_effect:
        'Has a chance to learn powerful, exclusive skills.',
    skill_c_sorcerer3_effect:
        'Has a chance to learn powerful, exclusive skills.',
    skill_c_redmage1_name: 'Jack of All Trades',
    skill_c_redmage2_name: 'Ace of All Trades',
    skill_c_redmage3_name: 'Master of All Trades',
    skill_c_redmage_desc:
        "Specialization is not part of a Spellblade's vocabulary.",
    skill_c_redmage_effect:
        'Can use any element. Small bonus from all items.',
    skill_c_redmage1_effect:
        'Can use any element. Can damage any Elemental Barrier at 30% power.',
    skill_c_redmage2_effect:
        'Can use any element. Can damage any Elemental Barrier at 30% power.',
    skill_c_redmage3_effect:
        'Can use any element. Can damage any Elemental Barrier at 30% power.',
    skill_c_spellknight1_name: 'Jack of All Trades',
    skill_c_spellknight2_name: 'Ace of All Trades',
    skill_c_spellknight3_name: 'Master of All Trades',
    skill_c_spellknight_desc:
        "Specialization is not part of a Spellknight's vocabulary.",
    skill_c_spellknight_effect:
        'Flexible equipment and elements. Small bonus from all items, and bigger bonus from items with innate elements.',
    skill_c_spellknight1_effect:
        'Flexible equipment and elements. Small bonus from all items, and bigger bonus from items with innate elements.',
    skill_c_spellknight2_effect:
        'Flexible equipment and elements. Small bonus from all items, and bigger bonus from items with innate elements.',
    skill_c_spellknight3_effect:
        'Flexible equipment and elements. Small bonus from all items, and bigger bonus from items with innate elements.',
    skill_c_spellknight_effect_details:
        '+{0} bonus stats from equipment with innate elements.',
    skill_c_geomancer1_name: 'Geomancy',
    skill_c_geomancer2_name: 'Terrain Attunement',
    skill_c_geomancer3_name: 'Geo Purity',
    skill_c_geomancer_desc:
        "The latent energy of land, sea and air bend to the Geomancer's will.",
    skill_c_geomancer_effect: 'Gains power from equipped Elements.',
    elemental_attack: 'Attack per point in any Element',
    skill_i_turtle_name: 'Ram Robustness',
    skill_i_turtle_plus_name: 'Ram Robustness+',
    skill_i_turtle_desc: 'Roughly 8 gigabytes worth of RAM.',
    skill_i_wolf_name: 'Wolf Ferocity',
    skill_i_wolf_plus_name: 'Wolf Ferocity+',
    skill_i_wolf_desc: 'Answer the call of the pack.',
    skill_i_ox_name: 'Ox Endurance',
    skill_i_ox_plus_name: 'Ox Endurance+',
    skill_i_ox_desc: 'Plow through fields and enemies alike.',
    skill_i_eagle_name: 'Eagle Accuracy',
    skill_i_eagle_plus_name: 'Eagle Accuracy+',
    skill_i_eagle_desc: 'My accuracy rating is "Yes".',
    skill_i_viper_name: 'Viper Lethality',
    skill_i_viper_plus_name: 'Viper Lethality+',
    skill_i_viper_desc: 'One strike is all it takes.',
    skill_i_cat_name: 'Cat Grace',
    skill_i_cat_plus_name: 'Cat Grace+',
    skill_i_cat_desc: 'Never go for the belly.',
    skill_i_luxuriousspirit_name: 'Xolotl Spirit',
    skill_i_luxuriousspirit_plus_name: 'Xolotl Spirit+',
    skill_i_luxuriousspirit_desc:
        "The twin brother of Quetzalcoatl. Ushers forth the sun's death every night.",
    skill_i_rhino_name: 'Rhino Stubbornness',
    skill_i_rhino_plus_name: 'Rhino Stubbornness+',
    skill_i_rhino_desc: 'Nonchalance attracts a violent kind of jealousy.',
    skill_i_owl_name: 'Owl Wisdom',
    skill_i_owl_plus_name: 'Owl Wisdom+',
    skill_i_owl_desc: "Don't you feel like staying up all night?",
    skill_i_armadillo_name: 'Armadillo Tenacity',
    skill_i_armadillo_plus_name: 'Armadillo Tenacity+',
    skill_i_armadillo_desc:
        'Roll into a ball to protect the important bits.',
    skill_i_lizard_name: 'Lizard Regeneration',
    skill_i_lizard_plus_name: 'Lizard Regeneration+',
    skill_i_lizard_desc:
        "Hey, if you lost it and you're still alive, it probably wasn't that important.",
    skill_i_crab_name: 'Horse Endurance',
    skill_i_crab_plus_name: 'Horse Endurance+',
    skill_i_crab_desc: 'Also known as "the zoomies".',
    skill_i_hippo_name: 'Hippo Lethargy',
    skill_i_hippo_plus_name: 'Hippo Lethargy+',
    skill_i_hippo_desc: 'Take it easy!',
    skill_i_walrus_name: 'Walrus Nonchalance',
    skill_i_walrus_plus_name: 'Walrus Nonchalance+',
    skill_i_walrus_desc: "It's a hard one to budge.",
    skill_i_lion_name: 'Lion Pride',
    skill_i_lion_plus_name: 'Lion Pride+',
    skill_i_lion_desc:
        "Everything the light touches is this spirit's kingdom.",
    skill_i_tiger_name: 'Tiger Power',
    skill_i_tiger_plus_name: 'Tiger Power+',
    skill_i_tiger_desc: 'The might of a lone hunter.',
    skill_i_dinosaur_name: 'Dinosaur Senses',
    skill_i_dinosaur_plus_name: 'Dinosaur Senses+',
    skill_i_dinosaur_desc:
        'Actually consists mostly of frog DNA. Dinosaurs are extinct, after all.',
    skill_i_shark_name: 'Shark Bloodlust',
    skill_i_shark_plus_name: 'Shark Bloodlust+',
    skill_i_shark_desc: 'Apex predator since time immemorial.',
    skill_i_bear_name: 'Bear Vitality',
    skill_i_bear_plus_name: 'Bear Vitality+',
    skill_i_bear_desc: "It's no wonder Rudo likes them!",
    skill_i_mammoth_name: 'Mammoth Presence',
    skill_i_mammoth_plus_name: 'Mammoth Presence+',
    skill_i_mammoth_desc: "It's got a looooong memory.",
    skill_i_opulentspirit_name: 'Quetzalcoatl Spirit',
    skill_i_opulentspirit_plus_name: 'Quetzalcoatl Spirit+',
    skill_i_opulentspirit_desc:
        "The twin brother of Xolotl. Heralds the sun's rebirth every morning.",
    skill_i_dragon_name: "Mundra's Spite",
    skill_i_dragon_plus_name: "Mundra's Spite",
    skill_i_dragon_desc:
        "Haughty even in spirit form. It seems more than eager to show those bosses who's boss.",
    bonus_skill_stat_from_equipments: 'bonus stats from all equipment.',
    skill_i_dragon_stat_postfix: 'against bosses.',
    skill_i_luxuriousspirit_effect_details:
        '{0} reduced Energy cost to surcharge.',
    skill_i_opulentspirit_effect_details:
        '{0} reduced Energy cost to surcharge.',
    skill_i_dinosaur_stat_postfix: 'on the first round.',
    skill_i_shark_effect_details: "{0} when enemy's HP is below {1}.",
    skill_i_shark_effect_details_short: 'Bonus attack VS injured enemies.',
    skill_t_gourmet_name: 'Gourmet',
    skill_t_gourmet_desc: 'Gourmet desc',
    skill_t_conqueror_name: 'Conqueror',
    skill_t_conqueror_desc: 'Conqueror desc',
    skill_t_reaver_name: 'Reaver',
    skill_t_reaver_desc: 'Reaver desc',
    skill_t_phantom_name: 'Phantom',
    skill_t_phantom_desc: 'Phantom desc',
    skill_t_scholar_name: 'Scholar',
    skill_t_scholar_desc: 'Scholar desc',
    skill_t_immortal_name: 'Immortal',
    skill_t_immortal_desc: 'Immortal desc',
    skill_t_artificer_name: 'Artificer',
    skill_t_artificer_desc: 'Artificer desc',
    skill_t_duelist_name: 'Duelist',
    skill_t_duelist_desc: 'Duelist desc',
    skill_t_defender_name: 'Defender',
    skill_t_defender_desc: 'Defender desc',
    skill_t_elementalist_name: 'Elementalist',
    skill_t_elementalist_desc: 'Elementalist desc',
    skill_c_mercenary1_effect: 'Learns skills faster than other classes.',
    skill_c_mercenary2_effect: 'Learns skills faster than other classes.',
    skill_c_mercenary3_effect: 'Learns skills faster than other classes.',
    skill_c_lord1_effect:
        'Bonus Defense from Heavy Armor. Can protect weak allies from attacks.',
    skill_c_lord2_effect:
        'Bonus Defense from Heavy Armor. Can protect weak allies from attacks.',
    skill_c_lord3_effect:
        'Bonus Defense from Heavy Armor. Can protect weak allies from attacks.',
    tutorial_name: "Titan's First Steps",
    tutorial_desc: 'Finish the tutorial',
    level01_name: 'Budding Merchant',
    level02_name: 'Accomplished Merchant',
    level03_name: 'Master Merchant',
    level04_name: 'Merchant Extraordinaire',
    level_desc: 'Reach merchant level <color=#3AFF5DFF>{0}</color>.',
    gold01_name: 'Tinkle of Coins',
    gold02_name: 'Pretty Penny',
    gold03_name: 'Gild-Gotten Goods',
    gold04_name: 'Royal Ransom',
    gold05_name: 'Mammonite',
    gold06_name: 'Wealth Beyond Measure',
    gold_desc: 'Earn <color=#3AFF5DFF>{0}</color> gold over your career.',
    sell01_name: 'Capitalism, Ho!',
    sell02_name: 'Black Friday Special',
    sell03_name: 'Cash Only Please',
    sell_desc: 'Sell <color=#3AFF5DFF>{0}</color> items.',
    craft01_name: 'Disintermediation',
    craft02_name: 'Flooding the Market',
    craft03_name: 'Flying off the Shelves',
    craft04_name: 'Industrial Evolution',
    craft05_name: 'Oligarch Monarch',
    craft_desc: 'Craft <color=#3AFF5DFF>{0}</color> items.',
    quest01_name: 'Did we Pack Lunch?',
    quest02_name: 'Attrition Mission',
    quest03_name: 'Eternal Crusade',
    quest04_name: 'To Hell and Back',
    quest_desc: 'Clear <color=#3AFF5DFF>{0}</color> quests.',
    bigsale01_name: 'One Easy Payment',
    bigsale02_name: 'Tax not Included',
    bigsale03_name: "The King's Caprice",
    bigsale_desc:
        'Make <color=#3AFF5DFF>{0}</color> gold with a single transaction.',
    champions01_name: 'Fated Encounters',
    champions02_name: 'Grand Destinies',
    champions03_name: 'Heroes of Knight and Magic',
    heroeshired_desc: 'Hire <color=#3AFF5DFF>{0}</color> Champions.',
    advlevel01_name: 'The Daily Grind',
    advlevel02_name: 'On the Path to Greatness',
    advlevel03_name: 'A Notch Above the Rest',
    advlevel04_name: 'Cream of the Crop',
    advlevel05_name: 'Top of the World',
    adventurerlevel_desc:
        'Have a Hero reach level <color=#3AFF5DFF>{0}</color>.',
    workers01_name: 'Own the Means of Production',
    workers02_name: 'Arcane Industries',
    workers03_name: 'Complete Monopoly',
    workershired_desc: 'Hire <color=#3AFF5DFF>{0}</color> workers.',
    furniture01_name: 'Efficient Floor Space',
    furniture02_name: 'Cranked up to 11',
    furniture03_name: 'Fit for a King',
    furniturelevel_desc:
        'Upgrade a furniture to level <color=#3AFF5DFF>{0}</color>.',
    energy01_name: 'Another Satisfied Customer',
    energy02_name: 'Power of Love',
    energy03_name: 'Supercharged',
    energy_desc:
        'Spend <color=#3AFF5DFF>{0}</color> energy over your career.',
    decoration01_name: 'Artistic Touch',
    decoration02_name: 'Global Decoration',
    decoration03_name: 'Haute Classe',
    decoration04_name: 'Extravaganza Bonanza',
    decorationspurchased_desc:
        'Buy <color=#3AFF5DFF>{0}</color> decorations over your career.',
    commonres01_name: 'Basic in Bulk',
    commonres02_name: 'Demand for Supplies',
    commonres03_name: 'Locally Sourced',
    commonres04_name: "Fillin' the Bin",
    commonresources_desc:
        'Spend <color=#3AFF5DFF>{0}</color> common resources over your career.',
    uncommonres01_name: 'Special Ingredient',
    uncommonres02_name: 'Exotic Commodities',
    uncommonres03_name: 'Economy of Scarce',
    uncommonresources_desc:
        'Spend <color=#3AFF5DFF>{0}</color> uncommon resources over your career.',
    rareres01_name: 'Glittering Collection',
    rareres02_name: 'Treasure Trove',
    rareres03_name: 'Marvels from the World Over',
    rareresources_desc:
        'Spend <color=#3AFF5DFF>{0}</color> rare resources over your career.',
    loot01_name: 'Dungeon Imports',
    loot02_name: 'Laden with Loot',
    loot03_name: 'Bountiful Harvest',
    loot04_name: 'Anything not Nailed Down',
    loot_desc:
        'Bring back <color=#3AFF5DFF>{0}</color> components from quests.',
    boss01_name: 'Bounty Hunters',
    boss02_name: 'Big Game Hunting',
    boss03_name: 'Bane of Legends',
    bosses_desc: 'Slay <color=#3AFF5DFF>{0}</color> boss(es).',
    chest01_name: 'Sponsored by Argon',
    chest02_name: 'For the Hoard!',
    chest03_name: 'Booty by the Boatload',
    chestsopened_desc: 'Open <color=#3AFF5DFF>{0}</color> chest(s).',
    blueprint01_name: 'Some Assembly Required',
    blueprint02_name: 'Research & Development',
    blueprint03_name: 'Thirst for Knowledge',
    research_desc: 'Research <color=#3AFF5DFF>{0}</color> blueprints.',
    ascend01_name: 'Ascended',
    ascend02_name: 'Above the Norm',
    ascend03_name: 'Constellated',
    ascend_desc: 'Fully ascend <color=#3AFF5DFF>{0}</color> item(s).',
    master01_name: 'Through Repetition, Perfection',
    master02_name: 'Natural 20',
    master03_name: 'Seasoned Journeyman',
    master04_name: 'Precision Engineering',
    master05_name: 'The Greatest Grind',
    master_desc: 'Master <color=#3AFF5DFF>{0}</color> blueprint(s).',
    expansion01_name: "It's Free Real Estate",
    expansion02_name: 'Expansion in Session',
    expansion03_name: 'Thank You Tom Nook',
    expansions_desc: 'Reach expansion level <color=#3AFF5DFF>{0}</color>.',
    damage01_name: 'Where it Hurts',
    damage02_name: 'This Power Level...!',
    damage03_name: 'Omae wa Mou Shindeiru',
    damage_desc:
        'Deal over <color=#3AFF5DFF>{0}</color> damage with an attack.',
    jackslot01_name: 'One-Armed Bandit',
    jackslot02_name: 'Jackpot!',
    jackslot03_name: 'JACKPOT!!!',
    jackslot_desc:
        'Earn <color=#3AFF5DFF>{0}</color> tokens at the Fortune Zone.',
    help01_name: 'Helping Hand',
    help02_name: 'Fat Cat Tag Team',
    help03_name: 'Capitalist Camaraderie',
    help_desc: 'Help <color=#3AFF5DFF>{0}</color> guild members.',
    enchant01_name: 'Hidden Potential',
    enchant02_name: 'Unleashed Potential',
    enchant_desc: 'Enchant <color=#3AFF5DFF>{0}</color> items.',
    twinenchant01_name: 'Magical Harmony',
    enchanttwin_desc:
        'Enchant an item with both a <color=#3AFF5DFF>Spirit</color> and an <color=#3AFF5DFF>Element</color>.',
    legenchant01_name: 'Item of Renown',
    enchantlegendary_desc:
        'Enchant a <color=#3AFF5DFF>legendary item</color>.',
    typeunlock01_name: 'All Accounted For',
    typeunlock_desc: 'Unlock <color=#3AFF5DFF>all</color> item types.',
    argon_a_name: 'Proud Paladin',
    argon_a_desc: 'Reach Rank <color=#3AFF5DFF>{0}</color> with Argon.',
    sia_a_name: "King's Shadow",
    sia_a_desc: 'Reach Rank <color=#3AFF5DFF>{0}</color> with Sia.',
    yami_a_name: "King's Blade",
    yami_a_desc: 'Reach Rank <color=#3AFF5DFF>{0}</color> with Yami.',
    lilu_a_name: 'Miracle Alchemist',
    lilu_a_desc: 'Reach Rank <color=#3AFF5DFF>{0}</color> with Lilu.',
    rudo_a_name: 'Moment of Respite',
    rudo_a_desc: 'Reach Rank <color=#3AFF5DFF>{0}</color> with Rudo.',
    polonia_a_name: 'Scourge of the Seas',
    polonia_a_desc: 'Reach Rank <color=#3AFF5DFF>{0}</color> with Polonia.',
    donovan_a_name: 'Master of Transmutation',
    donovan_a_desc: 'Reach Rank <color=#3AFF5DFF>{0}</color> with Donovan.',
    hemma_a_name: 'Lady of Undeath',
    hemma_a_desc: 'Reach Rank <color=#3AFF5DFF>{0}</color> with Hemma.',
    ashley_a_name: 'A Ronin Reborn',
    ashley_a_desc: 'Reach Rank <color=#3AFF5DFF>{0}</color> with Ashley.',
    gemsspent_name: 'Gem Expert',
    gemsspent_desc:
        'Spend a total of <color=#3AFF5DFF>{0}</color> gems. (Upgrades, decorations, chests, etc)',
    fuseuncommon_name: 'Trash to Treasure',
    fuseuncommon_desc: 'Fuse a Superior item.',
    fuseflawless_name: 'Flawless, As Usual',
    fuseflawless_desc: 'Fuse a Flawless item.',
    fuseepic_name: 'By Your Powers Combined...',
    fuseepic_desc: 'Fuse an Epic item.',
    fuselegendary_name: 'A Legend is Born',
    fuselegendary_desc: 'Fuse a Legendary item.',
    masterpiece_name: 'All or Nothing!',
    masterpiece_desc: 'Fuse a tier 10+ Legendary item.',
    fullmoon_name: 'Under The Pale Moonlight',
    fullmoon_desc: 'Perform a full moon recipe during a full moon.',
    transmuter_name: 'Master Recycler',
    transmuter_desc: 'Perfom 100 fusions using the Synthesis Vessel.',
    collection01_name: 'Blank Page Syndrome',
    collection02_name: 'Scrapbooker',
    collection03_name: 'Archivist',
    collection04_name: 'The Free Encyclopedia',
    collection05_name: 'Ultimate Collector',
    collection_desc: 'Reach a Collection Score of {0}.',
    bookentry_name: 'Vanity, Verily',
    bookentry_desc: "Complete an item's page in the Collection Book.",
    building_blacksmith: 'Smithy',
    building_carpenter: 'Wood Workshop',
    unlock_carpenter_worker_question: 'Allan the Carpenter',
    building_champions: 'Training Hall',
    building_champions_desc_format: '{0}',
    building_champions_pdesc_format: 'Next Class',
    building_dragon: "Dragon's Cave",
    building_elven: "Summoner's Tent",
    building_engineer: 'Laboratory',
    unlock_engineer_worker_question: 'Roxanne the Engineer',
    building_wizard: 'Wizard Tower',
    building_fabric: 'Weaver Mill',
    building_gems: 'Jewel Storehouse',
    unlock_herbalist_worker_question: 'Maribel the Herbalist',
    building_herbalist: 'Apothecary',
    building_herbs: 'Garden',
    building_inn: 'Emerald Inn',
    building_iron: 'Iron Mine',
    building_ironwood: 'Ironwood Sawmill',
    building_jeweler: 'Jewel Workshop',
    unlock_jeweler_worker_question: 'Katarina the Jeweler',
    building_leather: 'Tannery',
    building_mana: 'Ether Well',
    building_master: 'Master Lodge',
    unlock_master_worker_question: 'Theodore the Master',
    building_oils: 'Oil Press',
    building_priest: 'Temple',
    unlock_priest_worker_question: 'Freyja the Priestess',
    building_steel: 'Smelter',
    building_tailor: 'Tailor Workshop',
    unlock_tailor_worker_question: 'Julia the Tailor',
    building_tavern: 'Tavern',
    building_townhall: 'Town Hall',
    building_townhall_desc_format: 'City Population: {0}',
    building_townhall_desc:
        'Investing in the Town Hall will allow more players to join the city.',
    building_townhall_collective_desc:
        'Investing in the Town Hall will allow more players to join the city.',
    building_trade: 'Seaport',
    building_trade_desc_format: 'Daily Trade Limit: {0}',
    building_wood: 'Lumberyard',
    building_worker_desc_format: 'Craft Time Reduction: {0}%',
    no_investment_yet: 'No one has invested anything yet.',
    add_worker: 'Hire worker',
    crafting_speed: 'Crafting speed',
    investors: 'Investors',
    iron_production: 'Iron production',
    wood_production: 'Wood production',
    leather_production: 'Leather production',
    herbs_production: 'Herb production',
    steel_production: 'Steel production',
    fabric_production: 'Fabric production',
    ironwood_production: 'Ironwood production',
    oils_production: 'Oil production',
    gems_production: 'Jewel production',
    mana_production: 'Ether production',
    health_per_hour: 'Resting time',
    building_champions_collective_desc:
        'Investing in the Training Hall will allow for high level Heroes.',
    building_invest_bonus: 'Invest to receive',
    city_population_limit: 'City population limit',
    special_building: 'Special',
    worker_building: 'Worker',
    resource_building: 'Resource',
    resource_building_desc:
        'Investing in this industry will speed up resource production.',
    worker_desc:
        "Investing in the worker's installations will improve their maximum level.",
    building_market_collective_desc:
        'Investing in the Town Hall will allow more players to join the city.',
    building_tavern_collective_desc:
        'Investing in the Tavern will increase loot found during quests.',
    building_inn_collective_desc:
        'Investing in the Emerald Inn will speed up the healing rate of Heroes.',
    hero_invest_in_building: 'Invest further to unlock',
    resource_wood_worker: 'Lumberjack',
    resource_iron_worker: 'Miner',
    resource_leather_worker: 'Tanner',
    resource_herbs_worker: 'Gardener',
    hero_unlocked: 'Hired',
    hero_available: 'Available',
    hero_locked: 'Req. Level {0}',
    building_trade_collective_desc:
        'Investing in the Seaport will allow you to trade with more players',
    building_locked: 'Building under construction',
    building_unlock_requirements: 'Unlock Requirements',
    building_user_level_req: 'Requires Merchant Level {0}',
    building_parent_level_req: 'Requires {0} Level {1}',
    building_already_unlocked: 'Already purchased',
    building_purchase_required: 'Purchase required',
    worker_engineer_desc:
        'Only Roxanne holds the secret of the powerful crossbows and gunpowder weapons. She likes to make things go boom.',
    worker_wizard_desc:
        "Grimar's infinite wisdom allows the creation of powerful arcane scrolls. He tends to be on the forgetful side.",
    worker_jeweler_desc:
        "Katarina's expert hands can craft rings and ornate equipment that no else can. She's a bit of a penny pincher.",
    worker_tailor_desc:
        'Julia is a light armor specialist capable of crafting all sorts of equipment for agile Heroes.',
    worker_carpenter_desc:
        "Allan handles the crafting of many wooden items, such as staves and bows. He's the best, according to himself.",
    worker_blacksmith_desc:
        'Wallace specializes in forging all sorts of metal items, from swords to armor. Also forges jokes, but not good ones.',
    worker_herbalist_desc:
        "Maribel concocts medicine such as magical herbs and potions. She has a druidic degree, so she's the real deal.",
    worker_master_desc:
        "Theodore's boundless knowledge allows the creation of rare and powerful equipment. He can usually be seen peacefully enjoying his retirement.",
    worker_priest_desc:
        "Freyja's holy blessings are crucial to many magical items, such as amulets. She runs the temple and orphanage on her own.",
    building_academy: 'Academy',
    worker_academy_desc:
        'Evelyn teaches a wide variety of courses at the Academy. Thanks to her studies, she has learned how to craft Wands and Runestones.',
    building_sundragon: 'Sol Tower',
    building_moondragon: 'Luna Tower',
    worker_sundragon_desc:
        'Sondra has a gentle nature, for a dragon. His wisdom and calm demeanor makes him very proficient in the art of transmutation.',
    worker_moondragon_desc:
        'Mundra is the...fiercest of the two dragon siblings. Her affinity with Luna allows her to tap into the hidden power of the moonstones.',
    worker_elven_desc:
        'Yolanda is the mother of all Familiars. Only those she deems trustworthy receive the honor of caring for her magical eggs.',
    max_renown_level: 'Maximum guild renown level',
    building_townhall_desc_2:
        'Investing in the Town Hall will unlock more guild renown levels.',
    king_name: 'Reinhold',
    elderowen_name: 'Elder Owen',
    townhall_name: 'Elder Owen',
    argon_name: 'Argon',
    champions_name: 'Argon',
    sia_name: 'Sia',
    yami_name: 'Yami',
    lilu_name: 'Lilu',
    rudo_name: 'Rudo',
    polonia_name: 'Polonia',
    donovan_name: 'Donovan',
    ashley_name: 'Ashley',
    viktor_name: 'Viktor',
    filipo_name: 'Filipo',
    hemma_name: 'Hemma',
    remus_name: 'Remus',
    borovitz_name: 'Borovitz',
    atlab_aang_name: 'Aang',
    atlab_sokka_name: 'Sokka',
    blacksmith_name: 'Wallace',
    carpenter_name: 'Allan',
    iron_name: 'Durhan',
    wood_name: 'Gorza',
    tailor_name: 'Julia',
    leather_name: 'Tamas',
    herbs_name: 'Juniper',
    fabric_name: 'Ismael',
    steel_name: 'Brohm',
    ironwood_name: 'Jog',
    oils_name: 'Beatrice',
    herbalist_name: 'Maribel',
    jeweler_name: 'Katarina',
    wizard_name: 'Grimar',
    priest_name: 'Freyja',
    master_name: 'Theodore',
    trade_name: 'Loretta',
    engineer_name: 'Roxanne',
    tavern_name: 'Naya',
    casino_name: 'Maya',
    inn_name: 'Oscar',
    academy_name: 'Evelyn',
    mana_name: 'Serene',
    gems_name: 'Maylee',
    sundragon_name: 'Sondra',
    moondragon_name: 'Mundra',
    elven_name: 'Yolanda',
    elder_name: 'Elder Owen',
    monster_forest_boss_name: 'Weird Troublin',
    forest_mob_name: 'Weird Troublin',
    adventurer_name_male001: 'Laubard',
    adventurer_name_male002: 'Roy',
    adventurer_name_male003: 'Liam',
    adventurer_name_male004: 'Frederic',
    adventurer_name_male005: 'Wesley',
    adventurer_name_male006: 'Kenora',
    adventurer_name_male007: 'Denam',
    adventurer_name_male008: 'Barbas',
    adventurer_name_male009: 'Oberyth',
    adventurer_name_male010: 'Cyrus',
    adventurer_name_male011: 'Oberic',
    adventurer_name_male012: 'Aeric',
    adventurer_name_male013: 'Logarson',
    adventurer_name_male014: 'Olaf',
    adventurer_name_male015: 'Ruben',
    adventurer_name_male016: 'Randell',
    adventurer_name_male017: 'Adrian',
    adventurer_name_male018: 'Regas',
    adventurer_name_male019: 'Nolan',
    adventurer_name_male020: 'Malik',
    adventurer_name_male021: 'Armand',
    adventurer_name_male022: 'Broderick',
    adventurer_name_male023: 'Oscar',
    adventurer_name_male024: 'Beowulf',
    adventurer_name_male025: 'Aeron',
    adventurer_name_male026: 'Amran',
    adventurer_name_male027: 'Aidan',
    adventurer_name_male028: 'Borick',
    adventurer_name_male029: 'Bogrin',
    adventurer_name_male030: 'Yarrick',
    adventurer_name_male031: 'Dismas',
    adventurer_name_male032: 'Reynauld',
    adventurer_name_male033: 'Einhar',
    adventurer_name_male034: 'Finian',
    adventurer_name_male035: 'Fenwell',
    adventurer_name_male036: 'Gareth',
    adventurer_name_male037: 'Joshua',
    adventurer_name_male038: 'Jagen',
    adventurer_name_male039: 'Hawke',
    adventurer_name_male040: 'Gunter',
    adventurer_name_male041: 'Hans',
    adventurer_name_male042: 'Julian',
    adventurer_name_male043: 'Leonar',
    adventurer_name_male044: 'Lloyd',
    adventurer_name_male045: 'Hogan',
    adventurer_name_male046: 'Hargar',
    adventurer_name_male047: 'Olwen',
    adventurer_name_male048: 'Robin',
    adventurer_name_male049: 'Roderick',
    adventurer_name_male050: 'Samson',
    adventurer_name_male051: 'Wallace',
    adventurer_name_male052: 'Tarlach',
    adventurer_name_male053: 'Sirius',
    adventurer_name_male054: 'Kain',
    adventurer_name_male055: 'Perceval',
    adventurer_name_male056: 'Nathanael',
    adventurer_name_male057: 'Virgil',
    adventurer_name_male058: 'Aaron',
    adventurer_name_male059: 'Kenneth',
    adventurer_name_male060: 'Antwan',
    adventurer_name_male061: 'Williams',
    adventurer_name_male062: 'Alduin',
    adventurer_name_male063: 'Albin',
    adventurer_name_male064: 'Bodkin',
    adventurer_name_male065: 'Burch',
    adventurer_name_male066: 'Bartram',
    adventurer_name_male067: 'Marmaduke',
    adventurer_name_male068: 'Manfred',
    adventurer_name_male069: 'Manard',
    adventurer_name_male070: 'Menas',
    adventurer_name_male071: 'Juliano',
    adventurer_name_male072: 'Joren',
    adventurer_name_male073: 'Joppo',
    adventurer_name_male074: 'Juan',
    adventurer_name_male075: 'Goran',
    adventurer_name_male076: 'Giralt',
    adventurer_name_male077: 'Lorigan',
    adventurer_name_male078: 'Leif',
    adventurer_name_male079: 'Raynell',
    adventurer_name_male080: 'Irvine',
    adventurer_name_male081: 'Duban',
    adventurer_name_male082: 'Galvan',
    adventurer_name_male083: 'Roark',
    adventurer_name_male084: 'Sabin',
    adventurer_name_male085: 'Grell',
    adventurer_name_male086: 'Gilliam',
    adventurer_name_male087: 'Gigax',
    adventurer_name_male088: 'Lazarin',
    adventurer_name_male089: 'Kiro',
    adventurer_name_male090: 'Burch',
    adventurer_name_male091: 'Gwartz',
    adventurer_name_male092: 'Alistair',
    adventurer_name_male093: 'Logan',
    adventurer_name_male094: 'Oberon',
    adventurer_name_male095: 'Otello',
    adventurer_name_male096: 'Charon',
    adventurer_name_male097: 'Noam',
    adventurer_name_male098: 'Rubeus',
    adventurer_name_male099: 'Mondato',
    adventurer_name_male100: 'Vagan',
    adventurer_name_female001: 'Messa',
    adventurer_name_female002: 'Sarah',
    adventurer_name_female003: 'Ann',
    adventurer_name_female004: 'Vena',
    adventurer_name_female005: 'Vivy',
    adventurer_name_female006: 'Vivian',
    adventurer_name_female007: 'Elyse',
    adventurer_name_female008: 'Evelyn',
    adventurer_name_female009: 'Ishtar',
    adventurer_name_female010: 'Isadora',
    adventurer_name_female011: 'Katarina',
    adventurer_name_female012: 'Titania',
    adventurer_name_female013: 'Leanne',
    adventurer_name_female014: 'Miverna',
    adventurer_name_female015: 'Margaret',
    adventurer_name_female016: 'Morgan',
    adventurer_name_female017: 'Natasha',
    adventurer_name_female018: 'Natalya',
    adventurer_name_female019: 'Olivia',
    adventurer_name_female020: 'Ophelia',
    adventurer_name_female021: 'Priscilla',
    adventurer_name_female022: 'Selena',
    adventurer_name_female023: 'Nayana',
    adventurer_name_female024: 'Amalda',
    adventurer_name_female025: 'Dorothy',
    adventurer_name_female026: 'Makai',
    adventurer_name_female027: 'Shayla',
    adventurer_name_female028: 'Sonja',
    adventurer_name_female029: 'Sonya',
    adventurer_name_female030: 'Morrighan',
    adventurer_name_female031: 'Felicia',
    adventurer_name_female032: 'Arycelle',
    adventurer_name_female033: 'Deneb',
    adventurer_name_female034: 'Lastra',
    adventurer_name_female035: 'Lomeli',
    adventurer_name_female036: 'Adana',
    adventurer_name_female037: 'Adreani',
    adventurer_name_female038: 'Ashna',
    adventurer_name_female039: 'Amelda',
    adventurer_name_female040: 'Malda',
    adventurer_name_female041: 'Aurelyn',
    adventurer_name_female042: 'Cheryl',
    adventurer_name_female043: 'Olga',
    adventurer_name_female044: 'Erina',
    adventurer_name_female045: 'Francesca',
    adventurer_name_female046: 'Ayla',
    adventurer_name_female047: 'Jill',
    adventurer_name_female048: 'Corae',
    adventurer_name_female049: 'Damia',
    adventurer_name_female050: 'Adeleine',
    adventurer_name_female051: 'Fayla',
    adventurer_name_female052: 'Emalia',
    adventurer_name_female053: 'Kimbra',
    adventurer_name_female054: 'Lysta',
    adventurer_name_female055: 'Laerys',
    adventurer_name_female056: 'Marisa',
    adventurer_name_female057: 'Merryl',
    adventurer_name_female058: 'Teela',
    adventurer_name_female059: 'Vestia',
    adventurer_name_female060: 'Yzbel',
    adventurer_name_female061: 'Aimee',
    adventurer_name_female062: 'Athena',
    adventurer_name_female063: 'Amelia',
    adventurer_name_female064: 'Calil',
    adventurer_name_female065: 'Katya',
    adventurer_name_female066: 'Daisy',
    adventurer_name_female067: 'Yrene',
    adventurer_name_female068: 'Lauren',
    adventurer_name_female069: 'Barbara',
    adventurer_name_female070: 'Tammara',
    adventurer_name_female071: 'Esther',
    adventurer_name_female072: 'Agatha',
    adventurer_name_female073: 'Bailey',
    adventurer_name_female074: 'Alianor',
    adventurer_name_female075: 'Alys',
    adventurer_name_female076: 'Sibyl',
    adventurer_name_female077: 'Gloriana',
    adventurer_name_female078: 'Winifred',
    adventurer_name_female079: 'Jasmine',
    adventurer_name_female080: 'Gwendolynn',
    adventurer_name_female081: 'Rhianna',
    adventurer_name_female082: 'Marigold',
    adventurer_name_female083: 'Bess',
    adventurer_name_female084: 'Hildegard',
    adventurer_name_female085: 'Abigail',
    adventurer_name_female086: 'Elizabeth',
    adventurer_name_female087: 'Juetta',
    adventurer_name_female088: 'Brigitte',
    adventurer_name_female089: 'Paola',
    adventurer_name_female090: 'Iris',
    adventurer_name_female091: 'Esmee',
    adventurer_name_female092: 'Surane',
    adventurer_name_female093: 'Arabella',
    adventurer_name_female094: 'Teagan',
    adventurer_name_female095: 'Juniper',
    adventurer_name_female096: 'Kira',
    adventurer_name_female097: 'Daphne',
    adventurer_name_female098: 'Eryn',
    adventurer_name_female099: 'Sabrina',
    adventurer_name_female100: 'Nayla',
    story_argon_01_name: "The King's Shadow",
    story_argon_01_01:
        "...And that's when I slashed the Troublin with my sword! Pow!",
    story_argon_01_02: 'Ho ho! Quite the tale, Argon!',
    story_argon_01_03: 'Ah, the fire of youth...',
    story_argon_01_04:
        "I must say, it's very nice of you to accompany this old man on his morning walk.",
    story_argon_01_05:
        "Don't mention it! If I am to become a knight someday, I must help people in need!",
    story_argon_01_06:
        "Just like your father, eh? I'm sure you'll make a fine knight, my boy.",
    story_argon_01_07:
        "Why, perhaps you could even work as the King's royal guard!",
    story_argon_01_08:
        'Speak of the devil, there he is at the market! Why not go ask him?',
    story_argon_01_09:
        "YOUR MAJESTY! I've come to offer you my services as a knight!",
    story_argon_01_10:
        "W-what's the meaning of this!? Sia, please handle this commoner!",
    story_argon_01_11: 'Of course, my King.',
    story_argon_01_12: 'Well, young man? What is it?',
    story_argon_01_13: 'Wow! I, uh...',
    story_argon_01_14: "I'm knight! I want to become an Argon!",
    story_argon_01_15: 'No, wait a minute...',
    story_argon_01_16: 'You certainly have guts!',
    story_argon_01_17:
        "If you're so determined, come see me in the castle's courtyard next week.",
    story_argon_01_18:
        "Train hard until then! I would not tolerate a slacker among the King's knights...",
    story_argon_02_name: 'Training Montage',
    story_argon_02_01: 'Argon, what are you doing out here?',
    story_argon_02_02:
        "Training hard! I've finally got my chance at becoming a knight for the King!",
    story_argon_02_03: 'The King? ...Or his mysterious advisor, Sia?',
    story_argon_02_04: "Is that her name? It's beautiful, too!",
    story_argon_02_05: '...',
    story_argon_02_06: 'Gah! No! Only the King matters!',
    story_argon_02_07: 'I must become a knight! Nothing else matters!',
    story_argon_02_08: 'Right... Here, let me heal your wounds.',
    story_argon_02_09: 'Thanks- ouch! This spell stings!',
    story_argon_03_name: 'Two Sides of a Coin',
    story_argon_03_01: 'Alright! Sia will show up any moment now!',
    story_argon_03_02: "My training has paid off, I'm ready!",
    story_argon_03_03: "...But I'm still way too nervous...",
    story_argon_03_04: 'Oh! Hi there! You, in the bushes!',
    story_argon_03_05: '...!',
    story_argon_03_06: 'Are you here to become a knight too?',
    story_argon_03_07:
        "Sia will be here soon! There's no need to hide, she's pretty nice!",
    story_argon_03_08: 'Sia...!',
    story_argon_03_09: 'I need to leave. Farewell.',
    story_argon_03_10: 'W-wait!',
    story_argon_03_11: 'Woah! Smoke bomb!?',
    story_argon_03_12: 'What a strange girl...',
    story_argon_03_13: 'Young man, who are you talking to?',
    story_argon_03_14: 'Ah, Sia! Just a shy passerby! Pay it no mind!',
    story_argon_03_15:
        '...I see. Well then, it seems your resolve has not wavered.',
    story_argon_03_16:
        "Are you ready to risk your life as one of the King's knights?",
    story_argon_03_17:
        'You bet! I-I mean, of course! I will make my father proud!',
    story_argon_03_18:
        'Good. For your first assignment, I have an important event coming up...',
    story_argon_04_name: 'Empty-Handed',
    story_argon_04_01: '...',
    story_argon_04_02: 'Surely you jest!',
    story_argon_04_03:
        "You've cornered the assassin... and failed to stop her.",
    story_argon_04_04:
        "Not only that, you're saying you LET HER GO because you think she's a good person!?",
    story_argon_04_05: '...',
    story_argon_04_06: 'What nonsense is this?',
    story_argon_04_07:
        'You DO realize that this assassin tried to kill our King - the very man you have sworn to protect?',
    story_argon_04_08:
        'Such an incident endangers much more than your feeble mind can understand.',
    story_argon_04_09:
        'Ugh... I suppose the King has the final say about the termination of his knights...',
    story_argon_04_10:
        "...But don't expect me to speak highly of you. Dismissed.",
    story_argon_04_11: '...',
    story_argon_04_12: 'Oh man...',
    story_argon_04_13: 'Sorry, dad.',
    story_argon_05_name: 'Knight Errant',
    story_argon_05_01: 'Sigh...',
    story_argon_05_02: "If it isn't Argon! How are you doing today?",
    story_argon_05_03: 'Oh, hi Brohm... Any jobs for me?',
    story_argon_05_04: "Nothing today, sir Knight! What's got you down?",
    story_argon_05_05: "I'm just Argon now. I've... lost my title.",
    story_argon_05_06: 'Oh, that stings...',
    story_argon_05_07:
        '"Whatever happens, you need to stay positive! You\'ll pull through!"',
    story_argon_05_08: "I'm sure your luck will turn soon enough!",
    story_argon_05_09: 'Right... Thanks, Brohm.',
    story_argon_05_10:
        "I'll be... hanging around the Town Square until then.",
    story_argon_05_11: 'Sigh...',
    story_argon_06_name: 'Second Light',
    story_argon_06_01:
        'And so, that... "Knight", had your assassin at his mercy...',
    story_argon_06_02:
        '...Yet decided to let her go, believing her to be a good person!',
    story_argon_06_03:
        'A fascinating tale! This new knight appears to be a promising fellow!',
    story_argon_06_04: 'With all due respect, my King...',
    story_argon_06_05:
        'This man failed to stop your assassin, on account of a mere gut feeling.',
    story_argon_06_06:
        "The hallmark of a <c1>Paladin</c1>, wouldn't you think?",
    story_argon_06_07: 'Excuse me?',
    story_argon_06_08:
        'To have your nemesis at your mercy, yet seeing beyond mere strife and conflict...',
    story_argon_06_09:
        '...Then letting them go, so certain of their redemption that you are willing to put your title on the line.',
    story_argon_06_10: 'A tremendous act of bravery and benevolence!',
    story_argon_06_11:
        'I... must admit I did not consider this perspective, my King.',
    story_argon_06_12:
        'But the kingdom has not seen an aspiring Paladin in decades...',
    story_argon_06_13:
        'Shall I summon the knight council to debate the matter?',
    story_argon_06_14: 'Nonsense! I shall handle the formalities myself.',
    story_argon_06_15:
        'As you wish, my King. I shall prepare an escort immediately.',
    story_argon_06_16:
        'There is no need. I shall be as discreet as a mouse!',
    story_argon_07_name: 'The Inconspicuous King',
    story_argon_07_01:
        'Stand aside, peasants! An average laborer walks among you!',
    story_argon_07_02:
        "Ah, King Reinhold! It's been a while. Shall I tap open a keg of Ambrosia?",
    story_argon_07_03: 'Shhh! I am undercover today! Pay me no mind!',
    story_argon_07_04: 'Yes, of course! How silly of me!',
    story_argon_07_05: 'You there! Hiding behind the counter!',
    story_argon_07_06:
        "Ah! Uh, sorry, your Majesty. I'll get out of the way...",
    story_argon_07_07:
        'Nonsense! A Paladin-to-be is more than worthy of sitting next to my radiant self!',
    story_argon_07_08: 'Sitting next to... Paladin!?',
    story_argon_07_09:
        "Correct! In my infinite wisdom, I've sensed great potential within you!",
    story_argon_07_10:
        'Despite your... unusual methods, your heart is true and your sense of justice is... admirable!',
    story_argon_07_11:
        'The kingdom has thus deemed you fit to undertake the Paladin Trial!',
    story_argon_07_12: 'A Paladin Trial... Wow!',
    story_argon_07_13:
        'I know all about them! Each is unique and decided by the Paladin itself...',
    story_argon_07_14:
        'It must be a dangerous task, like slaying a dragon... or destroying a dangerous artifact...',
    story_argon_07_15:
        'Indeed! Any sufficiently brave and selfless deed done for the sake of the kingdom will do!',
    story_argon_07_16:
        "I understand! Thank you, your Majesty! You won't regret it!",
    story_argon_07_17: "Of course not, I'm always right!",
    story_argon_07_18: '...Now then, since I am already undercover...',
    story_argon_07_19: 'You there! Old man! Teach me how to play cards!',
    story_argon_07_20:
        'Cards, eh? Old Theodore only plays with high stakes...',
    story_argon_08_name: 'Rekindled',
    story_argon_08_01: "Argon! What's the rush?",
    story_argon_08_02: "Lilu, I'm going to be a Paladin!",
    story_argon_08_03: "A Paladin! T-that's amazing, Argon! How...?",
    story_argon_08_04:
        "The King told me to face a trial! That's the only way to truly be worthy of the title of Paladin.",
    story_argon_08_05: 'A trial... Do you know what you will do?',
    story_argon_08_06: '...',
    story_argon_08_07: 'Oh no! I have no clue!',
    story_argon_08_08:
        'It has to be something of epic and glorious proportions...',
    story_argon_08_09:
        "But Argon, you are glorious! You've defeated monsters far and wide...",
    story_argon_08_10: 'Sure, but it has to be something more...',
    story_argon_08_11:
        "I've heard there are feathered demons at Bleakspire Peak, maybe that would do it....",
    story_argon_08_12: "Well whatever it is, I'm sure you're up for it!",
    story_argon_08_13:
        'Thanks, Lilu... Even when I was no longer a Knight, you kept believing in me...',
    story_argon_08_14: 'Oh! Th-that was nothing!',
    story_argon_08_15:
        "Anyway! You'll need help in your quest! I'll prepare a few healing scrolls for you!",
    story_argon_08_16: 'Amazing! Onward!',
    story_argon_09_name: 'Bleakspire Clash (Argon)',
    story_argon_09_01: 'Ugh... this place sucks!',
    story_argon_09_02:
        'All this climbing and no Harpies in sight... I even got ambushed by assassins!',
    story_argon_09_03:
        "...They kinda looked like that Ninja girl, didn't they?",
    story_argon_09_04:
        'Good thing Lilu gave me these healing scrolls, whew!',
    story_argon_09_05: "Guess I'll take a break here.",
    story_argon_09_06: '...Huh?',
    story_argon_09_07: 'You again.',
    story_argon_09_08: "T-the King's assassin!",
    story_argon_09_09:
        'This must be fate. A bounty even greater than a Harpy!',
    story_argon_09_10: "...Bring it on. I'll kill you where you stand.",
    story_argon_10a_name: 'Triumphant Return',
    story_argon_10a_01: 'King Reinhold! Quest complete!',
    story_argon_10a_02:
        'Wonderful! What manner of foul beast have you slain?',
    story_argon_10a_03: 'Um... about that.',
    story_argon_10a_04: '...Hi.',
    story_argon_10a_05: "W-w-what's the meaning of this!?",
    story_argon_10a_06: 'Guards! Arrest them both!',
    story_argon_10a_07: "Woah! Hold on! She's turned over a new leaf!",
    story_argon_10a_08: 'Is-is that so?',
    story_argon_10a_09: "I've come to... apologize.",
    story_argon_10a_10: "My King, surely you wouldn't believe...",
    story_argon_10a_11: "I can vouch for her! Knight's honor!",
    story_argon_10a_12: 'Very well! I shall hear her plea!',
    story_argon_10a_13: 'Ugh... I feared this would happen.',
    story_argon_10a_14:
        'Guards! Bring reinforcements and watch her every move!',
    story_argon_10a_15:
        'Argon, please wait outside while we discuss with this... intruder.',
    story_argon_10a_16: 'At once!',
    story_argon_10a_17: '(...Did I make a huge mistake?)',
    story_argon_10b_name: 'Redemptor',
    story_argon_10b_01: 'Ugh... I did not need this headache today.',
    story_argon_10b_02: 'Lady Sia! How did it go?',
    story_argon_10b_03:
        '...Against all odds, King Reinhold is willing to forgive his own would-be assassin.',
    story_argon_10b_04:
        'While I am not so trustful, she did share valuable intel on her Azuman order.',
    story_argon_10b_05: 'T-then this means...!',
    story_argon_10b_06: "Yes, we'll accept her surrender and apology.",
    story_argon_10b_07:
        'She is currently under medical care due to starvation, but she should make a full recovery.',
    story_argon_10b_08: 'As for you, Paladin Argon...',
    story_argon_10b_09: 'P-paladin!',
    story_argon_10b_10:
        'Yes, It is something to vanquish a threat, but to redempt a sworn enemy of the kingdom...',
    story_argon_10b_11:
        'It appears King Reinhold was right about you, after all.',
    story_argon_10b_12:
        "Now go and celebrate, Paladin. I'm sure you'll be very busy, very soon!",
    story_argon_10b_13: 'I will! Thank you!',
    story_argon_10b_14: "Dad... I hope you're proud.",
    story_argon_epilogue_name: 'Epilogue',
    story_argon_epilogue_01: 'P-paladin!? You did it, Argon!',
    story_argon_epilogue_02:
        'I really thought I blew it for a moment there...',
    story_argon_epilogue_03:
        'But dad was right. All I need to do is follow my heart!',
    story_argon_epilogue_04:
        "Ah... but does that mean you'll be sent away on campaign overseas?",
    story_argon_epilogue_05:
        'Not yet. Lady Sia hopes I can teach Heroes to become Paladins.',
    story_argon_epilogue_06:
        "Ah! That's a relief! ...Though I have a hard time imagining you as a teacher.",
    story_argon_epilogue_07:
        "Won't you be busy with super important Paladin quests?",
    story_argon_epilogue_08:
        "I'll do my best! The kingdom is counting on me!",
    story_argon_epilogue_09: 'Hi there, noble Paladin!',
    story_argon_epilogue_10:
        'Brohm told me he was looking for some more smelting gloves!',
    story_argon_epilogue_11: 'Yes! Right away!',
    story_argon_epilogue_12: '...Some things never change, huh?',
    story_argon_epilogue_13: "Not our Argon, that's for sure.",
    story_argon_a_name: 'The Tower of Titans',
    story_argon_a_01: 'The Tower of Titans, eh?',
    story_argon_a_02: 'Please tell us all about it!',
    story_argon_a_03:
        'I heard some Heroes whisper about it, but no one has ever seen it...',
    story_argon_a_04:
        'Indeed, the Tower of Titans is a mythical place. Nobody really knows if it truly exists.',
    story_argon_a_05:
        'Stories say it appears out of thin air, challenging the mightiest Heroes to climb its many floors...',
    story_argon_a_06:
        '...Before suddenly disappearing, wiping clean the memories of anyone who may have seen it.',
    story_argon_a_07: 'W-wiping memories!?',
    story_argon_a_08:
        "That's strange... where would the legend come from, then?",
    story_argon_a_09: 'Right! Some people would need to remember!',
    story_argon_a_10:
        "It is said that those who overcome the Titan's challenge are deemed worthy by the Tower.",
    story_argon_a_11:
        'These Heroes return... changed from the experience, and retain their memories.',
    story_argon_a_12:
        'More accurately, a part of them remains in the Tower, and a part of the Tower remains with them.',
    story_argon_a_13:
        "...That will be all for today's story time. Thank you for listening to this old man's tales.",
    story_argon_a_14: 'Wow! I wonder if it truly exists?',
    story_argon_a_15: "Yeah! I'd love to explore a dungeon like that!",
    story_argon_a_16:
        'But one thing seems strange... How does Elder Owen know all this?',
    story_argon_a_17: '...!',
    story_argon_b_name: 'Are we the Titans?',
    story_argon_b_01: 'Good morning... Argon.',
    story_argon_b_02: "Yawn... Mornin'.",
    story_argon_b_03: "...You're feeling it too, aren't you?",
    story_argon_b_04:
        "Yeah, I'm beat! I feel like I've been questing all night!",
    story_argon_b_05: 'Look, your shield is all chipped, too!',
    story_argon_b_06:
        "Huh? You're right! I could've sworn it was sparkling clean yesterday...",
    story_argon_b_07: 'Argon... Do you think...?',
    story_argon_b_08:
        'Ahoy! Are any of ye fine landlubbers on the market for some <c1>Juggernaut Greaves</c1>?',
    story_argon_b_09:
        'No... especially not twenty of them! Where did you get all that!?',
    story_argon_b_10: 'Hah! Funny ye ask. I have no clue.',
    story_argon_b_11:
        "I think I might be lootin' in me sleep. Must be me pirate soul actin' up, arr.",
    story_argon_b_12: 'That weird pirate lady too...',
    story_argon_b_13:
        '...Did we all challenge the Tower and had our memories wiped...?',
    story_argon_b_14: "That means we weren't strong enough...",
    story_argon_b_15: "I'll train hard for next time! Just you see!",
    story_lilu_00_01:
        'Hiya! You must be the merchant Argon keeps talking about!',
    story_lilu_00_02:
        "I'm Lilu, Master Grimar's apprentice. Nice to meet ya!",
    story_lilu_00_03:
        "I'm usually busy running errands for my Master, but...",
    story_lilu_00_04:
        "If you help me out, I'm sure I can find some time for a quest or two!",
    story_lilu_00_hire_01:
        'Cool! Thanks to your help, I got plenty of free time now!',
    story_lilu_00_hire_02: "As promised, now it's my turn to help you out!",
    story_lilu_00_hire_03:
        "I'm still a novice at magic, but there's a few healing spells I'd like to try out...",
    story_lilu_00_hire_04: 'Send me out anytime!',
    story_lilu_01_name: 'Precocious Apprentice',
    story_lilu_01_01:
        'Well, Pollux? What do you think of this new spell? Perfect, right?',
    story_lilu_01_02: "Yeah, this time we'll ace it for sure!",
    story_lilu_01_03:
        'Good morning, Lilu. Here for the weekly shipment of Living Roots?',
    story_lilu_01_04: 'Yes, thank you, Maribel!',
    story_lilu_01_05:
        "You sure ordered a lot this week! What's your master doing with all of these?",
    story_lilu_01_06:
        "To be honest, about half of it is for me. I'm doing some serious studying!",
    story_lilu_01_07: 'Oh? What kind?',
    story_lilu_01_08:
        "Healing spells! I've spent all of last week mending cuts and bruises at the Training Hall!",
    story_lilu_01_09:
        "Right, I'm sure the Heroes are glad to have you around.",
    story_lilu_01_10: '...And Argon, too!',
    story_lilu_01_11: '...!',
    story_lilu_01_12: 'I- I gotta go! Thanks!',
    story_lilu_02_name: 'Grim Tidings',
    story_lilu_02_01:
        "Here you go, Master Grimar. This week's Living Roots.",
    story_lilu_02_02: "Thank you, Lola. Here is today's homework.",
    story_lilu_02_03: 'Oh! A magic tome! Is it about healing!?',
    story_lilu_02_04: '"The Miscreant\'s Manual to Maledictions"...?',
    story_lilu_02_05:
        "This book is way too advanced! It's even got an entire section about curses!",
    story_lilu_02_06: '...Curses?',
    story_lilu_02_07: 'Hmm... curses...?',
    story_lilu_02_08: 'Master?',
    story_lilu_02_09: '35 years ago, on this very day... I...!',
    story_lilu_02_10: 'Master Grimar!?',
    story_lilu_02_11: '...I forgot. And who might you be?',
    story_lilu_02_12: "...I'm Lilu, your apprentice. Or Lola, sometimes...",
    story_lilu_02_13:
        "Oh well, nevermind. I'll be studying this book. Thanks, Grim.",
    story_lilu_03_name: 'Addled Mind',
    story_lilu_03_01: '... Add in a pinch of Silver Dust and... done!',
    story_lilu_03_02: 'Look at it glow! Not bad, huh Pollux?',
    story_lilu_03_03:
        "If the Miscreant's Manual to Malediction is correct, this potion should-",
    story_lilu_03_04:
        "Lola, what is all this ruckus? I'm trying to sleep here!",
    story_lilu_03_05:
        'Master Grimar! Thanks to your book, I brewed a very advanced potion! It should-',
    story_lilu_03_06: 'You did what!?',
    story_lilu_03_07: "Well then, give it here! Let's have a taste!",
    story_lilu_03_08: '...',
    story_lilu_03_09: 'Master Grimar?',
    story_lilu_03_10:
        'Lilu! I-mu-mu-must be fr-freed! Le-let me out of this doltish, half-witted mm-mind!',
    story_lilu_03_11: 'M-master Grimar! Are you well?',
    story_lilu_03_12: '...',
    story_lilu_03_13: '*Burp* A little fruity!',
    story_lilu_03_14: 'Not bad, Lola. Now then, back to your chores.',
    story_lilu_03_15: '...That was NOT normal.',
    story_lilu_04_name: "Bloodline's End",
    story_lilu_04_01: "Something isn't right with Master Grimar...",
    story_lilu_04_02:
        'His voice, it was as though someone was speaking from within him!',
    story_lilu_04_03:
        "Maybe we're dealing with a complex transmutation spell?",
    story_lilu_04_04: 'Or maybe even-',
    story_lilu_04_05:
        "Dude, Lilu! Something went wrong at the king's feast!",
    story_lilu_04_06: "Tamas? What's going on?",
    story_lilu_04_07:
        'Something about an invader in the castle, dude. Pretty spooky!',
    story_lilu_04_08:
        "An invader...! Oh no, this is Argon's first day as a knight!",
    story_lilu_04_09: 'Thanks Tamas, I gotta go!',
    story_lilu_04_10: "Oh no...! What's going on here!?",
    story_lilu_04_11: 'Argon, where are you!?',
    story_lilu_04_12: 'Aaargh...',
    story_lilu_04_13: 'KING REINHOLD!? Are you alright!?',
    story_lilu_04_14: 'Aahh... My precious life... fleeting...',
    story_lilu_04_15: 'This is bad...!',
    story_lilu_04_16: 'Focus Lilu! Remember the Sage training!',
    story_lilu_04_17: 'King Reinhold! Hold still! <c1>Heal</c1>!',
    story_lilu_04_18:
        "It's no use... Please... preserve my beautiful corpse...",
    story_lilu_04_19: 'No effect...',
    story_lilu_04_20: 'Oh no.. could it be... Poison?',
    story_lilu_04_21:
        "...Just like my parents...! I won't fail this time! I can't!",
    story_lilu_04_22: '<c1>Greater Poison Purge!</c1>',
    story_lilu_04_23: '...Aaaah... Huh?',
    story_lilu_04_24: 'King Reinhold! How do you feel?',
    story_lilu_04_25:
        "Wait a minute... That cup shielded you from that shuriken! You weren't harmed at all!",
    story_lilu_04_26:
        'Oh! Is that so? I suppose I may have overreacted a little bit.',
    story_lilu_04_27: "A monarch's death is a dramatic affair, after all.",
    story_lilu_04_28: 'Argh! Reinhold!',
    story_lilu_05_name: 'Pick your Poison',
    story_lilu_05_01: 'Ugh... That was close... sort of.',
    story_lilu_05_02:
        "If Reinhold truly was poisoned... I don't think I could've saved him.",
    story_lilu_05_03:
        "I'll need to refocus my efforts. To ensure no one suffers loss like I did...",
    story_lilu_05_04: 'Miss Sia!',
    story_lilu_05_05:
        'Ah, the star of the hour! A knight has been dispatched to deal with the intruder. You may rest easy.',
    story_lilu_05_06: '(A knight? Good luck, Argon!)',
    story_lilu_05_07:
        'Thank you for... saving our King, despite the circumstances.',
    story_lilu_05_08:
        "It was nothing! But if it were to happen again, I'm not sure I could help...",
    story_lilu_05_09: 'Miss Sia, do you know about poisons?',
    story_lilu_05_10:
        "Of course. The aconitum, also known as Wolf's Bane, is a terribly efficient killer.",
    story_lilu_05_11:
        'But a personal favorite of mine is Ricin. Silent, deadly, and readily available...',
    story_lilu_05_12: 'Um... What about curing them?',
    story_lilu_05_13: "Oh. I can't say I've ever given it much thought.",
    story_lilu_05_14: '...Forget I asked.',
    story_lilu_06_name: 'Master and Student',
    story_lilu_06_01: "I'm back, Master Grimar!",
    story_lilu_06_02: 'Ah, welcome back, Lola.',
    story_lilu_06_03:
        'Can you believe it? The King was nearly assassinated!',
    story_lilu_06_04: 'Is that so...',
    story_lilu_06_05:
        'An assassin came out of nowhere and attacked him with a shuriken!',
    story_lilu_06_06:
        'And then, I rushed to his help as he was dying on the floor...!',
    story_lilu_06_07: 'Erm... Who are you again?',
    story_lilu_06_08:
        'Oh right. Your condition... I need to get back to it!',
    story_lilu_06_09: 'It might be a poison, too...',
    story_lilu_06_10: "Let's see... Grimar is super old...",
    story_lilu_06_11: 'What do you think, Pollux?',
    story_lilu_06_12: 'Yeah, we should ask around the city!',
    story_lilu_06_13:
        'Maybe some of the older residents remember what a younger Grimar acted like...',
    story_lilu_07_name: 'Ancestral Knowledge',
    story_lilu_07_01: 'Master Theodore!',
    story_lilu_07_02: "If it isn't Lilu! How can I help you today?",
    story_lilu_07_03:
        'I was wondering if you could tell me about Grimar, back when he was young...',
    story_lilu_07_04: "...Since you're uh... old and stuff.",
    story_lilu_07_05: 'Ho ho ho! Of course, of course.',
    story_lilu_07_06:
        'Old Grimar has always been a bit of an... eccentric.',
    story_lilu_07_07:
        'But back when I was a wee lad, I remember him being much sharper. My equal at chess, even.',
    story_lilu_07_08:
        'After that trip to the Chronos Ruins though... He always felt a bit off, now that I think about it.',
    story_lilu_07_09: "The Chronos Ruins! That's such a dangerous place!",
    story_lilu_07_10:
        '...An entire civilization reduced to ruins while researching time magic... ',
    story_lilu_07_11:
        'That place is full of magical anomalies! What would Grimar have to gain by visiting such a place?',
    story_lilu_07_12:
        "You're right Pollux, it's our only clue. We'll have to go investigate!",
    story_lilu_07_13:
        'We would need an escort though... Should we ask Argon...?',
    story_lilu_07_14:
        'No! What if a time vortex suddenly turned him into an old man? Or like Grimar...',
    story_lilu_07_15:
        '...I can think of only one other person that could help us...',
    story_lilu_07_16: "It won't be easy Pollux! But we have to try!",
    story_lilu_07_17: 'Thanks Theodore! You were a big help!',
    story_lilu_07_18: 'No problem, Lilu! See you around!',
    story_lilu_07_19: '...',
    story_lilu_07_20: '...Just how smart is that owl...?',
    story_lilu_08_name: 'Common Cause',
    story_lilu_08_01:
        "Mister Rudo, please! Don't walk away, I need to talk to you!",
    story_lilu_08_02: "Beat it kid, I don't wanna hear it.",
    story_lilu_08_03: 'You and your owl can go play elsewhere!',
    story_lilu_08_04:
        "I know you don't like spellcasters... but I really need your help!",
    story_lilu_08_05:
        "I need to go deep into the Chronos Ruins, and I can't go alone! ",
    story_lilu_08_06:
        "Why don't you ask that kid Argon, your knight in shiny armor?",
    story_lilu_08_07:
        "He's... uh, busy with something else at the moment...",
    story_lilu_08_08: 'I guess there is no point in asking you then...',
    story_lilu_08_09: 'You got that right, kid.',
    story_lilu_08_10: 'That is truly a shame, hun.',
    story_lilu_08_11:
        'I heard the Chronos Ruins Cyclops Lord has never been defeated in single combat...',
    story_lilu_08_12:
        'It would take an exceptional warrior to escort Lilu through these ruins!',
    story_lilu_08_13: '...Never defeated you say?',
    story_lilu_08_14: "Fine. I'll bite. How much smashing do I get to do?",
    story_lilu_08_15: "A lot, I'm sure!",
    story_lilu_08_16:
        'You see I need to find the source of the time flux coming from the various crystals and isolate-',
    story_lilu_08_17: 'Cut to the smashing part.',
    story_lilu_08_18:
        '...There are monsters and magical crystals to smash.',
    story_lilu_08_19: 'Alright, sold. You owe me one, kid.',
    story_lilu_08_20: 'This is fantastic! Thank you so much, mister Rudo!',
    story_lilu_08_21:
        "Save it for after our trip, kid. It's no walk in the park.",
    story_lilu_08_22:
        "I'll be there to heal your wounds if you face too many monsters!",
    story_lilu_08_23: 'Hmph. Not likely.',
    story_lilu_08_24: 'And thank you too, Naya!',
    story_lilu_08_25: 'Not a problem, hun.',
    story_lilu_a_name: 'Hoarder of Ages',
    story_lilu_a_01:
        "Grimar, you didn't tell me you had a second <c1>Collection Book</c1>!",
    story_lilu_a_02:
        '<c1>Collection Book</c1>...? That does ring a bell...',
    story_lilu_a_03:
        "Wow! This one's packed with info! <c1>Damocles</c1>... <c1>Amber Citadel</c1>...",
    story_lilu_a_04:
        "<c1>Grimar's Grand Wand</c1>...? Did you name that one?",
    story_lilu_a_05:
        "There's so many pages! You must've been quite the collector!",
    story_lilu_a_06: 'He most certainly was!',
    story_lilu_a_07: 'Sondra... is that you, old friend?',
    story_lilu_a_08: 'Wow! Do you guys know each other?',
    story_lilu_a_09:
        'Indeed. Young Grimar was absolutely enamored with his Collection Book.',
    story_lilu_a_10:
        'I believe it earned him a prestigious title among his human peers... "Nerd", was it?',
    story_lilu_a_11: 'Ouch...',
    story_lilu_a_12:
        'We met during his quest for rare items, and created the <c1>Fusion Cauldron</c1> together.',
    story_lilu_a_13:
        'He would spend day and night fusing away hundreds of items... and even more during a full moon!',
    story_lilu_a_14:
        'All these efforts... but his collection is still incomplete!',
    story_lilu_a_15:
        "I swear I'll cure your condition, Master Grimar! You must finish your life's work!",
    story_lilu_a_16:
        'Time certainly is cruel to mankind. I wish you luck on your quest, little one.',
    story_sia_00_01: 'Well, well, well... What do we have here?',
    story_sia_00_02:
        "Our city's newest merchant, was it? My name is Sia. It is an absolute pleasure to meet you.",
    story_sia_00_03:
        'As advisor to our esteemed King, I would like to personally welcome you to his kingdom.',
    story_sia_00_04:
        "And while I'm here, there's a little something I've been looking for...",
    story_sia_00_hire_01:
        "Shopkeeper... You've been so useful to me lately.",
    story_sia_00_hire_02:
        'As a token of our friendship, I offer you my services as a Champion.',
    story_sia_00_hire_03:
        "I have an eye for valuables that I'm sure you'll find more than useful...",
    story_sia_01_name: 'Kingly First Steps',
    story_sia_01_01: "Sia! I've decided!",
    story_sia_01_02: 'Yes, my King?',
    story_sia_01_03: 'Today, I would like to go shopping!',
    story_sia_01_04: 'An excellent idea, your Majesty.',
    story_sia_01_05:
        'However, looking at your schedule, it seems you are to meet foreign dignitaries today...',
    story_sia_01_06: "Oh, what a bore! Can't we just send them away?",
    story_sia_01_07:
        'Remember, my King, though you may be young, you are still King. Your word is law.',
    story_sia_01_08:
        "...You're absolutely right! Shopping it is, then! Those dignitaries can wait!",
    story_sia_01_09:
        'Your decisiveness is admirable. Shall I accompany you?',
    story_sia_01_10:
        'Of course! I trust no one else with the protection of my graceful self.',
    story_sia_02_name: 'Friends in Low Places',
    story_sia_02_01:
        "...And so, I have reasons to believe our King's life may be in danger.",
    story_sia_02_02:
        "I see. Indeed, a few rival kingdoms would benefit from an attempt on the King's life.",
    story_sia_02_03:
        "I haven't seen any suspicious characters, but I'm sure Naya over at the tavern would know more.",
    story_sia_02_04:
        'Thank you, Elder. Your wisdom and patriotism is appreciated.',
    story_sia_02_05:
        "Don't mention it. It is my duty as a resident to ensure our kingdom's prosperity.",
    story_sia_02_06:
        "Though he may be young and foolish, I'm sure Reinhold will grow into a fine king... given time.",
    story_sia_02_07: 'And it is my duty to see him live until then!',
    story_sia_02_08:
        "Well well, it's not often we see pretty blue blood down here at the tavern!",
    story_sia_02_09: 'Cut the act, Naya, you know who I am.',
    story_sia_02_10:
        'Indeed! Sia Leonel, a gift to our King by the Kern emperor...',
    story_sia_02_11:
        'To act as an advisor, but also to keep tabs on him, am I right?',
    story_sia_02_12: '...!',
    story_sia_02_13: "...Nosy girl, aren't you?",
    story_sia_02_14: 'Well then, tell me, nosy Naya...',
    story_sia_02_15:
        'Have you seen any strange faces in your tavern lately?',
    story_sia_02_16:
        'Oh, we get plenty of strange faces! Tipsy orcs, shopkeepers...',
    story_sia_02_17:
        'But the strangest of all would be that girl in the corner, over there.',
    story_sia_02_18: '...!',
    story_sia_02_19: 'She escaped...',
    story_sia_02_20: 'That was an Azuman spy! ...or assassin.',
    story_sia_02_21: "I'll need to be extra vigilant...",
    story_sia_03_name: 'Gatecrasher',
    story_sia_03_01:
        "We have gathered you here today to celebrate the King's incredible generosity.",
    story_sia_03_02:
        'In the past months, he has donated several million gold from his own coffers...',
    story_sia_03_03: '...To several local temples, schools and orphanages.',
    story_sia_03_04:
        'All are welcome to this feast, so please tell your friends and family.',
    story_sia_03_05: 'Long live King Reinhold!',
    story_sia_03_06: 'Tyrant Reinhold, your reign ends now!',
    story_sia_03_07: 'Aaargh...',
    story_sia_03_08: 'A shuriken...! The Azuman spy!',
    story_sia_03_09:
        'Guards! Form a perimeter around the castle! Let none escape!',
    story_sia_03_10: 'Argon, go after that assassin!',
    story_sia_03_11: 'At once!',
    story_sia_03_12:
        'You! Unwashed buffoon! The King is injured! Seek out a healer at once!',
    story_sia_03_13: 'Wow, rude, dude.',
    story_sia_03_14:
        '(I had every entry point under maximum surveillance...)',
    story_sia_03_15: '(...Just who is this girl?)',
    story_sia_04_name: 'Mail Scorched Black',
    story_sia_04_01: 'Nosy Naya, I believe you owe me some explanations.',
    story_sia_04_02:
        'Oh, Mrs. Leonel... I believe YOU owe me explanations.',
    story_sia_04_03: 'I beg your pardon?',
    story_sia_04_04:
        "I've intercepted some VERY interesting letters. Sent by you, I believe.",
    story_sia_04_05: 'Shall we read it together?',
    story_sia_04_06: '...!',
    story_sia_04_07:
        "Let's see here... A Kern seal, addressed directly to the Kern emperor?",
    story_sia_04_08:
        'My, my. I was dearly hoping for a scandalous love affair, but I suppose treason is fine, too.',
    story_sia_04_09: 'N-naya, please stop!',
    story_sia_04_10: "Oh! Here's a juicy bit!",
    story_sia_04_11:
        '"King Reinhold is a complete fool whose presence on the throne benefits you greatly."',
    story_sia_04_12:
        '"Keeping him alive is sure to damage his kingdom even more so than its ruler\'s death."',
    story_sia_04_13: '...',
    story_sia_04_14:
        'Such unkind words are rather unbecoming of his advisor, I would say!',
    story_sia_04_15: "Nosy... Naya... You'll pay for this!",
    story_sia_04_16:
        'Someday, perhaps. But for now, I believe it is you who will pay. Handsomely, I suspect.',
    story_sia_04_17: '...Stooping so low as to blackmail?',
    story_sia_04_18: 'So small a sin, compared to treason!',
    story_sia_04_19:
        'If you had planned harm upon our king, on the other hand...',
    story_sia_04_20:
        'Hmph. Very well. Name your price. And pray that my spy network never outsmarts yours, Naya.',
    story_sia_04_21: "I'm looking forward to it!",
    story_sia_05_name: 'Agent Swan',
    story_sia_05_01:
        '"... In a drastic turn of events, an attempt was made on King Reinhold\'s life."',
    story_sia_05_02:
        '"Azuma seems to be behind this attempt. Agent Swan will investigate further."',
    story_sia_05_03: 'Sia! What are you doing?',
    story_sia_05_04: 'Oh, merely writing a letter, pay it no mind.',
    story_sia_05_05: '(My report to the Kern emperor, that is...)',
    story_sia_05_06:
        'Very well! Some foreign dignitaries are awaiting my presence.',
    story_sia_05_07:
        "They may be a tad... offended by last time's shopping trip.",
    story_sia_05_08:
        'I would very much enjoy your assistance in defusing this situation!',
    story_sia_05_09: "Of course. I'll be there in a moment, my King.",
    story_sia_05_10:
        'Letting the Kern emperor know of this assassination attempt may usher forth dire consequences...',
    story_sia_05_11:
        'I... need more time to think. This kingdom is... not yet ready to face Kern.',
    story_sia_05_12:
        "For the sake of everyone's safety... I suppose I could omit this detail...",
    story_sia_05_13:
        '"All is going as planned. Agent Swan has nothing to report this month."',
    story_sia_06_name: 'Rose and Thorn',
    story_sia_06_01:
        'Sia! There is a visitor here for you! Quite the princely fellow, I might add.',
    story_sia_06_02: "...Princely, my King? Surely it couldn't be...",
    story_sia_06_03: 'Lady Sia.',
    story_sia_06_04: '...Donovan.',
    story_sia_06_05:
        'My King, an urgent matter awaits you at the treasury. I would recommend you see to it immediately.',
    story_sia_06_06:
        "Oh, did the latest Pure Gold shipment come in? I can't wait!",
    story_sia_06_07: "Ever the fool, this king. Isn't he?",
    story_sia_06_08:
        "What foul wind brings you to Reinhold's kingdom, alchemist?",
    story_sia_06_09: 'Straight to the point as always, Agent Swan.',
    story_sia_06_10:
        "I am merely on personal leave. With the Kern Emperor's permission of course.",
    story_sia_06_11:
        'Smelling the flowers, tasting the potions... An alchemist vacation, you could say.',
    story_sia_06_12: 'And you would come here, of all places?',
    story_sia_06_13:
        'This backwards kingdom does appear to have its fair share of skilled alchemists.',
    story_sia_06_14:
        "...Though if a swan's loyalty is found to be wavering...",
    story_sia_06_15:
        "You've made your point, Donovan. You may go enjoy your... vacation.",
    story_sia_06_16: 'As you wish, Lady Sia. A pleasure, as always.',
    story_sia_06_17: '...Another thorn in my side.',
    story_sia_07_name: 'Stranger of Gold City',
    story_sia_07_01: "Donovan? I hope I'm not interrupting any... spying.",
    story_sia_07_02: 'Oh! Lady Sia!',
    story_sia_07_03: 'No, not at all! Merely looking around.',
    story_sia_07_04:
        'There is no need to be so secretive. All are welcome within the Lost City of Gold.',
    story_sia_07_05:
        "However, do keep in mind that, as per the Kingdom's law, any and all <c1>Pure Gold</c1> found within belongs to King Reinhold.",
    story_sia_07_06:
        'In exchange, brave adventurers are rewarded for their contributions.',
    story_sia_07_07: 'I see. A most... generous ruler.',
    story_sia_07_08:
        'The <c1>Pure Gold</c1> is then minted into coinage, resulting in a sharp inflation of currency...',
    story_sia_07_09:
        "Correct. I see you've been intercepting my mail to the Emperor.",
    story_sia_07_10: 'I do enjoy being in the know.',
    story_sia_07_11:
        "Since you're here, make yourself useful and carry some <c1>Pure Gold</c1> back to the castle.",
    story_sia_07_12: 'Of course. Anything for my lady.',
    story_sia_07_13:
        'Try not to break a nail on the way back, dear Donovan.',
    story_sia_a_name: "The King's Caprice",
    story_sia_a_01: 'My King.',
    story_sia_a_02: 'Dear Sia! How can I help you?',
    story_sia_a_03:
        'Have you, by any chance, been on another of your "capricious" outbursts recently?',
    story_sia_a_04: 'N-no! Of course not! I would never!',
    story_sia_a_05:
        'Strange. Our ledgers indicate that Vault #36 is filled to the brim with <c1>Kunai</c1>.',
    story_sia_a_06:
        "I don't need to remind his majesty that this is an excessively rare item.",
    story_sia_a_07: "I-I'm sure there is a perfectly logi-",
    story_sia_a_08:
        "Furthermore, many of the kingdom's rarest antiques have been seen in the hands of common folks.",
    story_sia_a_09: 'Your generosity is commendable...',
    story_sia_a_10:
        '...But please refrain from distributing priceless artifacts to fund your mood swings.',
    story_sia_a_11:
        'Hmph. Very well! From today onwards, I am a changed man!',
    story_sia_a_12:
        'Thank you for your understanding. I shall take my leave.',
    story_sia_a_13:
        'My will remains strong! I have no need for material goods!',
    story_sia_a_14:
        "...This room could use a few <c1>Regal Blades</c1>, doesn't it?",
    story_yami_00_01: 'Hmph. You will do.',
    story_yami_00_02: 'I require these. Do not let me down.',
    story_yami_00_hire_01: "...Fine. I'll do your dirty work.",
    story_yami_00_hire_02:
        'This is a temporary alliance. Do not mistake it as friendship.',
    story_yami_01_name: 'The Azuman Wanderer',
    story_yami_01_01: 'Hee hee hee! What do we have here?',
    story_yami_01_02: 'Hmph. Begone, pest.',
    story_yami_01_03: "Y'got some bad manners, lady! And weird clothes!",
    story_yami_01_04: '...',
    story_yami_01_05:
        'Ah hah! You smell like Azuma, the eastern human kingdom!',
    story_yami_01_06: "What're ya doing all the way over here, lady?",
    story_yami_01_07:
        'Armed to the teeth and tight lipped... Ya gotta be an assassin, right?',
    story_yami_01_08: '...',
    story_yami_01_09:
        'Hee hee! I knew it! Stupid humans, always killing each others!',
    story_yami_01_10:
        "...You're right. I traveled here to kill a tyrannical king.",
    story_yami_01_11:
        "Well! Ya can't be a good assassin if you just tell everybody that! Why'd you tell me?",
    story_yami_01_12: "Because you won't live to tell anybody. Farewell.",
    story_yami_01_13: 'Eek!',
    story_yami_01_14: "...King Reinhold, you're next.",
    story_yami_02_name: 'Hunt or be Hunted',
    story_yami_02_01: "...The castle's security is tight.",
    story_yami_02_02: 'A password system that changes daily...',
    story_yami_02_03: 'Unscheduled guard patrols...',
    story_yami_02_04: "I'll need to be-",
    story_yami_02_05: 'Hi there! You, in the bushes!',
    story_yami_02_06: '...!',
    story_yami_02_07: 'Are you here to become a knight too?',
    story_yami_02_08:
        "Sia will be here soon! There's no need to hide, she's pretty nice!",
    story_yami_02_09: 'Sia...!',
    story_yami_02_10: 'I need to leave. Farewell.',
    story_yami_02_11: 'W-wait!',
    story_yami_02_12: 'Woah! Smoke bomb!?',
    story_yami_02_13: '...I lost that fool.',
    story_yami_02_14: 'With Sia here... my task will be difficult.',
    story_yami_02_15: 'But it must be done. I cannot fail.',
    story_yami_02_16: "For the Order... and for my sister's sake.",
    story_yami_03_name: 'Turning Point',
    story_yami_03_01:
        'Esteemed people of my kingdom! Thank you for coming to my sumptuous feast!',
    story_yami_03_02:
        'It is also a perfect opportunity for you all to gaze upon my beautiful self! Do not miss this chance!',
    story_yami_03_03: '(Pompous tyrant. Typical.)',
    story_yami_03_04:
        'Erm, yes. Thank you for this edifying speech, your Majesty.',
    story_yami_03_05:
        "We have gathered you here today to celebrate the King's incredible generosity.",
    story_yami_03_06:
        'In the past months, he has donated several million gold from his own coffers...',
    story_yami_03_07:
        '...to several local temples, schools and orphanages.',
    story_yami_03_08:
        'All are welcome to partake, so please tell your friends and family.',
    story_yami_03_09: 'Long live King Reinhold!',
    story_yami_03_10: '(...These are not the actions of a tyrant.)',
    story_yami_03_11:
        "(...No, the Order wouldn't lie. This must be a trick.)",
    story_yami_03_12: 'Tyrant Reinhold, your reign ends now!',
    story_yami_03_13: 'A shuriken...! The Azuman assassin!',
    story_yami_03_14: 'I missed...!? Impossible!',
    story_yami_03_15:
        'Guards! Block off all exits! Capture this woman at once!',
    story_yami_04_name: 'Reflection',
    story_yami_04_01: '(I hesitated... And failed.)',
    story_yami_04_02: '(Could the Order have lied...?)',
    story_yami_04_03: '(It matters not. I am on my own, now.)',
    story_yami_04_04: 'I finally found you!',
    story_yami_04_05: 'The foolish knight!',
    story_yami_04_06: "Woah! I'm not here to fight!",
    story_yami_04_07: "I won't fall for your tricks, Reinhold dog!",
    story_yami_04_08: "...You missed on purpose didn't you?",
    story_yami_04_09: '...!',
    story_yami_04_10:
        'I saw you at the feast. It even looked like you regretted throwing that shuriken.',
    story_yami_04_11:
        "The King's not a bad person, you know? He's a bit weird sometimes, but he tries his best!",
    story_yami_04_12:
        "I'm not sure who paid you to do this, but they can't be good guys...",
    story_yami_04_13:
        "How about going back to the castle and apologizing? I'm sure Reinhold will-",
    story_yami_04_14: '...Apologize? How dense.',
    story_yami_04_15:
        "I have a lot of thinking to do. Don't follow me, if you value your life.",
    story_yami_04_16: "...She's not a bad person, I'm sure of it.",
    story_yami_04_17: 'Now I need to report to Sia...',
    story_yami_04_18: "Oh man, I'm going to get chewed out...",
    story_yami_05_name: 'By the Moonlight',
    story_yami_05_01:
        '<sprite tint=1 name=Note1>Sunlight in the willows high, radiance of ages past<sprite tint=1 name=Note2>...',
    story_yami_05_02:
        '<sprite tint=1 name=Note2>Of the Elven glade, we- Eek!',
    story_yami_05_03: 'The cold sting you feel is my blade to your neck.',
    story_yami_05_04:
        'I have questions. If your answers are honest, I may let you live.',
    story_yami_05_05: 'Aaah! Y-yes! Anyth- Ow!',
    story_yami_05_06: 'Silence.',
    story_yami_05_07: 'The fool you call king... Tell me about him.',
    story_yami_05_08: 'K-King Reinhold? He is the ruler of these lands...',
    story_yami_05_09:
        'Though he is rather eccentric, there is no denying his virtue and generosity.',
    story_yami_05_10:
        '...What he lacks in experience, his newest advisor, Sia, more than makes up for it!',
    story_yami_05_11:
        'Impossible...! Your words reek of cowardice, but they ring true.',
    story_yami_05_12: 'This city! Does it host an oracle?',
    story_yami_05_13: 'Ah! Yes! The elf, Serene, up on the hill!',
    story_yami_05_14:
        'Mysterious stranger... May I have the honor of knowing your na- Ow!',
    story_yami_05_15:
        'A few days earlier, I would not have thought twice about killing you. Do not squander your luck.',
    story_yami_05_16:
        'Return to your vapid life and forget about everything that has transpired here.',
    story_yami_05_17: '...Whew! What an exhilarating encounter!',
    story_yami_05_18:
        'Black-clad maiden... Will we ever meet again, I wonder...?',
    story_yami_06_name: 'Broken Pawn',
    story_yami_06_01: "...A black wind betrays the Stalker's presence.",
    story_yami_06_02: '...! How...?',
    story_yami_06_03:
        'She Who Stalks steps as a feather, but a heavy heart is laid bare to the third eye.',
    story_yami_06_04: '...Enough charades, oracle. I need answers.',
    story_yami_06_05: 'About the Order... My family.',
    story_yami_06_06:
        'A dealer in death comes demanding guidance. What does it offer in return?',
    story_yami_06_07: 'Your life.',
    story_yami_06_08: '...So be it.',
    story_yami_06_09:
        '<c1>The Azuman Order, once concerned with righting wrongs...</c1>',
    story_yami_06_10:
        '<c1>...Champions of order, turned slaves to the highest bidder...</c1>',
    story_yami_06_11:
        '<c1>...For an insurmountable task, a pawn is sacrificed...</c1>',
    story_yami_06_12:
        '<c1>...In failure or success both, The Order emerges a victor.</c1>',
    story_yami_06_13: '...Impossible.',
    story_yami_06_14: 'The messenger has spoken.',
    story_yami_06_15: '...IMPOSSIBLE!',
    story_yami_06_16: '...',
    story_yami_06_17:
        'One who stalks the shadows must also make amends with light, given time.',
    story_yami_07_name: 'Fight or Flight',
    story_yami_07_01:
        "...I'll need to gather my things and leave as soon as possible.",
    story_yami_07_02: 'Hey there, mysterious stranger.',
    story_yami_07_03: "Innkeeper. I've paid extra for your silence.",
    story_yami_07_04:
        "No need to worry, we don't pry here at the Emerald Inn!",
    story_yami_07_05:
        'That being said, we have some mean-looking visitors who seemed to be looking for you.',
    story_yami_07_06: '(Enforcers from the Order? So soon?)',
    story_yami_07_07:
        "They didn't leave a name. Real shady folks. Friends of yours?",
    story_yami_07_08: "Not anymore. Tell them I'm dead. I may as well be.",
    story_yami_07_09:
        'Leaving in a rush, eh? I figured you might. I prepared some supplies for you.',
    story_yami_07_10:
        "...Thank you innkeeper. But I won't need them where I'm going.",
    story_yami_08_name: 'One Way Trip',
    story_yami_08_01: 'To Bleakspire Peaks, you say?',
    story_yami_08_02:
        'Yeah, I saw a ninja-looking girl matching your description. She looked to be in a rush.',
    story_yami_08_03: "Excellent. We've finally tracked down our assassin.",
    story_yami_08_04: "I'll assemble a patrol and...",
    story_yami_08_05:
        "You sure about that? I don't think that's necessary.",
    story_yami_08_06: '...Why not, Gorza?',
    story_yami_08_07:
        "A solo expedition to Bleakspire Peaks is suicide. That girl wasn't packing any supplies, either.",
    story_yami_08_08:
        "There's no way she doesn't know that. Even Rudo's not stupid enough to go there alone.",
    story_yami_08_09:
        "Is that so? Thank you Gorza, I'll take it into consideration.",
    story_yami_08_10: 'I suppose this problem solves itself, but...',
    story_yami_08_11:
        '...It leaves a bitter aftertaste. What are you thinking, assassin?',
    story_yami_09_name: 'Bleakspire Clash (Yami)',
    story_yami_09_01:
        'This must be fate. A bounty even greater than a Harpy!',
    story_yami_09_02: "...Bring it on. I'll kill you where you stand.",
    story_yami_09_03: "You say that, but you don't sound very convinced...",
    story_yami_09_04:
        "...You're right. I don't care about anything anymore.",
    story_yami_09_05:
        'If you truly want me dead, then leave me to die here.',
    story_yami_09_06:
        "I would never leave someone to die! I'll take you back to King Reinhold!",
    story_yami_09_07:
        "I know he'll understand! I'll carry you back if I have to!",
    story_yami_09_08: '...',
    story_yami_09_09: 'Come on, hup!',
    story_yami_09_10:
        "Hold on, you're all skin and bones! When did you last eat?",
    story_yami_09_11: '...',
    story_yami_09_12:
        "Have some snacks on the way back, it's going to be a bumpy ride!",
    story_yami_10_name: 'Behind Enemy Lines',
    story_yami_10_01:
        'Guards! Bring reinforcements and watch her every move!',
    story_yami_10_02:
        'Argon, please wait outside while we discuss with this... intruder.',
    story_yami_10_03: 'At once!',
    story_yami_10_04: 'Now then. Speak, assassin.',
    story_yami_10_05:
        "Hmph, that's right. I was hired to assassinate a tyrant.",
    story_yami_10_06:
        'Or so I thought. It seems he is merely a well-meaning buffoon.',
    story_yami_10_07: 'B-buffoon!?',
    story_yami_10_08: 'What does your people have against our King?',
    story_yami_10_09: 'My order strikes only in the name of justice...',
    story_yami_10_10:
        'But it seems that was a lie. And with my failure, I will be hunted, as well.',
    story_yami_10_11:
        'I... apologize. I will submit to righteous... punishment...',
    story_yami_10_12: '...Ugh.',
    story_yami_10_13: 'She collapsed!',
    story_yami_10_14: 'Exhausted and starved...',
    story_yami_10_15: 'Guards! Summon our best physicians at her bedside!',
    story_yami_10_16: 'Treat her with respect fitting for a royal guest.',
    story_yami_epilogue_name: 'Epilogue',
    story_yami_epilogue_01:
        'Uff... Uff... Reaching you is... quite the climb...',
    story_yami_epilogue_02: 'This is my home now.',
    story_yami_epilogue_03:
        "Why not take up residence inside the city? You've been pardoned.",
    story_yami_epilogue_04:
        'My clan most likely believes me dead. I would rather keep it as such.',
    story_yami_epilogue_05:
        "I understand. I'll see that you are supplied regularly.",
    story_yami_epilogue_06: 'Now then, as for your next task...',
    story_yami_epilogue_07: 'More goons from the Empire?',
    story_yami_epilogue_08:
        "That's correct. As you know, his Majesty draw much ire from envious eyes.",
    story_yami_epilogue_09:
        'Consult this document for more information on your targets.',
    story_yami_epilogue_10: 'It will be done.',
    story_yami_epilogue_11: 'It pains me to say it, but I believe you.',
    story_yami_epilogue_12:
        "I should be heading back. Though I'm not looking forward to the trip...",
    story_yami_epilogue_13: 'Sia... Reinhold...',
    story_yami_epilogue_14: '...Thank you. Sincerely.',
    story_rudo_00_01:
        'Hey, you. Fetch me the manager of this store, will ya?',
    story_rudo_00_02: "...YOU'RE the owner? A tiny fella like you?",
    story_rudo_00_03: 'Gwah hah hah! Not bad, not bad at all.',
    story_rudo_00_04:
        "Well then, shopkeeper... I'm Rudo. Show me what you got.",
    story_rudo_00_hire_01:
        'Hey kid. Heard you were running a little questing business on the side.',
    story_rudo_00_hire_02:
        'Things are too quiet around here. How about sending me somewhere I can let loose?',
    story_rudo_00_hire_03:
        '...Skip the paperwork. Just point me towards something you need crushed.',
    story_rudo_01_name: 'The Living Mountain',
    story_rudo_01_01:
        "Brrr... I'm not dressed for this cold! Why are we here again?",
    story_rudo_01_02:
        'The gems here have some wonderful properties! Grimar told me-',
    story_rudo_01_03:
        "Wait, Lilu! Look! There's a man fighting a Yeti over there!",
    story_rudo_01_04: "A man!? He's as big as a mountain!",
    story_rudo_01_05: "And he's... suplexing the Yeti!?",
    story_rudo_01_06: "Whew... That's ten.",
    story_rudo_01_07: 'Impressive battle, sir! You were awesome!',
    story_rudo_01_08: 'Huh?',
    story_rudo_01_09: "Mister... you're wounded! Let me heal you!",
    story_rudo_01_10: '<c1>Hea-</c1>...!',
    story_rudo_01_11: 'BACK OFF!',
    story_rudo_01_12: "I'm fine. It'll heal on its own.",
    story_rudo_01_13:
        "Kids shouldn't be hanging out in a place like this. Go home.",
    story_rudo_01_14: 'Wow, that guy really hates magic, huh?',
    story_rudo_01_15: 'No. What I saw in his eyes... It was fear.',
    story_rudo_02_name: 'Eviction Notice',
    story_rudo_02_01: "I'm afraid I don't follow...",
    story_rudo_02_02:
        "Bah! Then I'll make it simple for your codger ears to understand!",
    story_rudo_02_03:
        "The wizard in this city is a raving lunatic and should be kicked out, for everyone's safety.",
    story_rudo_02_04: 'My my... such harsh words...',
    story_rudo_02_05:
        'Just yesterday, he told me to keep an eye on Timotheus...',
    story_rudo_02_06:
        '...Claiming that "Tim" was delusional, confused, and should be kept under supervision.',
    story_rudo_02_07: 'He *IS* Timotheus!',
    story_rudo_02_08: 'Hmm, yes. I see.',
    story_rudo_02_09:
        'Despite his... "condition", Timotheus Grimar is an integral part of this city.',
    story_rudo_02_10:
        'He is as helpful as he is harmless. I see no cause for alarm.',
    story_rudo_02_11: "What's this about kicking out Master Grimar!?",
    story_rudo_02_12: 'Stay out of this, girl.',
    story_rudo_02_13:
        'No! Master Grimar is a powerful wizard, but he would never hurt anyone!',
    story_rudo_02_14:
        "Gah! Fine! But if this town blows up, don't say I didn't warn you.",
    story_rudo_02_15: 'Quite the troublemaker, that one.',
    story_rudo_02_16: "I'd better keep an eye on him...",
    story_rudo_03_name: 'Spark-Born',
    story_rudo_03_01: 'Excuse me... What are you grumbling about?',
    story_rudo_03_02:
        'Blast it. Why are there so many blasted spellcasters in this city...',
    story_rudo_03_03: 'Ah, Mr. Rudo, I presume.',
    story_rudo_03_04: "Don't get all friendly with me, wizard.",
    story_rudo_03_05:
        'I am a priestess of the Goddess, my powers differ from that of a wizard.',
    story_rudo_03_06:
        'As part of my oath, I have sworn to a life of healing and protection.',
    story_rudo_03_07:
        "I'm not buying it. You Spark-Borns are all the same.",
    story_rudo_03_08: 'You know about the Spark...?',
    story_rudo_03_09: "Hmph. Forget I said anything. I'm out of here.",
    story_rudo_03_10: '...Were you listening, Lilu?',
    story_rudo_03_11: 'Yep, all of it!',
    story_rudo_03_12: 'Do you remember my lessons about the Spark?',
    story_rudo_03_13:
        'Of course! Only people born with the Spark can control and weave magic spells.',
    story_rudo_03_14:
        'This phenomenon is generally unknown to the general public, though.',
    story_rudo_03_15:
        "That's correct! As expected of my brightest student!",
    story_rudo_03_16:
        'Though this begs the question... How does he know about it...?',
    story_rudo_03_17: 'Hmm... Do you think...?',
    story_rudo_04_name: 'Smash & Grab',
    story_rudo_04_01:
        'Yo ho ho! Captain Polonia really hit it big this time!',
    story_rudo_04_02: "An entire royal tomb, ready for ye ol' plunderin'!",
    story_rudo_04_03: "Keep it down, sailor girl. I'm busy.",
    story_rudo_04_04:
        "Or it would be... if there weren't a barnacled berserker SMASHIN' ALL THE BOOTY TO PIECES!",
    story_rudo_04_05:
        "What in Davy Jones' name is yer problem, matey? That stuff's worth tons o' doubloons!",
    story_rudo_04_06: "It's ancient. ",
    story_rudo_04_07: "It's magical.",
    story_rudo_04_08: "And it's probably cursed.",
    story_rudo_04_09:
        "I'm smashing it all to dust to save someone else the trouble.",
    story_rudo_04_10:
        "If this city is too foolish to see the problem, then I'll take care of it myself!",
    story_rudo_04_11:
        'Oy, matey. That staff be looking REAL valuable. Yer not gonna...',
    story_rudo_04_12: 'Hmph.',
    story_rudo_04_13: '...Ouch.',
    story_rudo_04_14: "By the Kraken! Don't just snap it over yer knee!",
    story_rudo_04_15:
        "...Though it be lookin' like the staff did the breakin'. Not even a scratch on it!",
    story_rudo_04_16: "How's yer leg, matey?",
    story_rudo_04_17: "Fine. But this staff's tougher than it looks.",
    story_rudo_04_18:
        "And for some reason, I can't drop it. Like it's... glued to my hand.",
    story_rudo_04_19:
        'I be no ship surgeon, but seems like ye got yerself proper cursed, matey.',
    story_rudo_04_20: '...Ye might wanna get that checked out.',
    story_rudo_04_21:
        "Of course it's cursed magic. It's ALWAYS cursed magic.",
    story_rudo_04_22: "Fine. I'm out of here. Break the rest for me.",
    story_rudo_04_23: 'Aye aye, matey!',
    story_rudo_04_24:
        "...More like break n' enter, cause this pirate be plunderin' tonight! Yo ho ho!",
    story_rudo_05_name: 'Curses & Outbursts',
    story_rudo_05_01: "What do you mean, y'can't break it!?",
    story_rudo_05_02:
        "I meant just that my violent friend; I can't break this relic.",
    story_rudo_05_03:
        "What use is an adamantine hammer if it can't break this puny staff!",
    story_rudo_05_04:
        "Well it certainly isn't that puny... That looks like pure Celesteel to me.",
    story_rudo_05_05:
        'In any case, there is something more at work here, probably magic...',
    story_rudo_05_06: "OF COURSE it's magic.",
    story_rudo_05_07: "It's always cursed magic.",
    story_rudo_05_08:
        'Seems to me like you already knew this! Why not go to someone learned in such things instead of me?',
    story_rudo_05_09:
        "You can't trust mages... Not one bit. My clan knows this.",
    story_rudo_05_10: "Well I don't think you have a choice here, son.",
    story_rudo_05_11:
        "Beside, how could you not trust sweet Lilu to begin with? She's adorable!",
    story_rudo_05_12:
        "She a mage ain't she? Means she's just like the rest.",
    story_rudo_05_13: 'Dangerous. Wicked. VILE.',
    story_rudo_05_14:
        "Well, she's probably the only one who can help, if you want to get rid of this staff at some point.",
    story_rudo_05_15: '...Blast it.',
    story_rudo_a_name: 'Tough Love',
    story_rudo_a_01: 'Gorza!',
    story_rudo_a_02: 'Gorza!<sprite tint=1 name=Note2>',
    story_rudo_a_03: "Hey Juniper. And Maylee! What's up?",
    story_rudo_a_04:
        'Me and Maylee were talking about her future prince charming...',
    story_rudo_a_05: "Oooh I can't wait!",
    story_rudo_a_06: '...And we were wondering... how did YOU meet yours?',
    story_rudo_a_07: 'That big, dumb oaf Rudo? Hah!',
    story_rudo_a_08:
        'He may not look like it, but that man really knows how to talk to a lady.',
    story_rudo_a_09: "Let's see... it went a bit like this...",
    story_rudo_a_10: '...Another one, for the road.',
    story_rudo_a_11: 'So... Rudo. Have you ever considered settling down?',
    story_rudo_a_12: "Mind your business, Naya. I ain't got time for that.",
    story_rudo_a_13:
        'Love strikes when you least expect it! Who knows, the woman of your dreams could walk in at any time...',
    story_rudo_a_14:
        "Bah! I won't settle for a woman who can't wrestle me to the ground.",
    story_rudo_a_15: 'Is that a challenge, big guy?',
    story_rudo_a_16: 'Try me, tooth face!',
    story_rudo_a_17: 'Gorza, please put the chair down-',
    story_rudo_a_18: 'How about this, meathead? You want some more?',
    story_rudo_a_19: 'RAAAAGH!!!',
    story_rudo_a_20: 'Woah!',
    story_rudo_a_21:
        "...And we got married the week after. Bloodiest wedding I've ever seen.",
    story_rudo_a_22: '...',
    story_rudo_a_23: '...',
    story_rudo_a_24: '...M-maybe we should go ask someone else.',
    story_polonia_00_01: 'Ahoy, landlubber.',
    story_polonia_00_02:
        'Heard ye were running a business on me turf, all sneaky like.',
    story_polonia_00_03:
        "Tough luck, swabbie. Cap'n Polonia has ears everywhere!",
    story_polonia_00_04:
        "Cough up a tribute for yer cap'n, or I'll have ye walk the plank!",
    story_polonia_00_hire_01: 'Ahoy, lootkeeper.',
    story_polonia_00_hire_02:
        'As of today, I, Captain Polonia, promote ye to first mate!',
    story_polonia_00_hire_03:
        "I'll be counting on ye to sniff out lucrative plundering opportunities for yer Cap'n.",
    story_polonia_00_hire_04: 'Welcome aboard, matey!',
    story_polonia_01_name: 'Landlubber Extraordinaire',
    story_polonia_01_01: 'Yo ho ho! Landlubbers!',
    story_polonia_01_02:
        "Have ye ever dreamt of daring tales of swashbucklin' and piracy on the high seas?",
    story_polonia_01_03:
        "I, Cap'n Polonia Del Monte, be recruiting a crew for me ship!",
    story_polonia_01_04:
        'As granddaughter of the legendary pirate Baross Del Monte, I can promise ye booty and adventure!',
    story_polonia_01_05: 'Captain Polonia, was it?',
    story_polonia_01_06:
        'Aye. Ye interested? A fine stud like ye would be welcome.',
    story_polonia_01_07: 'S-stud!?',
    story_polonia_01_08:
        "Er-hem, not really! I'm Argon, knight-in-training!",
    story_polonia_01_09:
        'Piracy is illegal so... please surrender and come peacefully!',
    story_polonia_01_10: 'Shiver me timbers! A blasted knight...',
    story_polonia_01_11:
        "Err, ye see, I'm a legal pirate, like that... Robin Hood landlubber.",
    story_polonia_01_12: '...I steal from the rich and give to the poor!',
    story_polonia_01_13:
        'Oh! That does sound rather noble. Fair enough, carry on!',
    story_polonia_01_14: '...Whew.',
    story_polonia_01_15: 'No luck today either, huh?',
    story_polonia_01_16: 'Arr, life on the high seas be tough.',
    story_polonia_02_name: 'The Tale of Del Monte',
    story_polonia_02_01: 'No more recruiting today, Polonia?',
    story_polonia_02_02:
        'It be useless. I be a failure as a pirate captain, let alone bearing the Del Monte name...',
    story_polonia_02_03:
        'This Baross Del Monte... your grandfather, was it?',
    story_polonia_02_04:
        'Aye! A legend among legends! The modern times be tough for us pirates...',
    story_polonia_02_05:
        'All the merchant ships these days carry fancy magical detection tools to easily avoid us sea dogs.',
    story_polonia_02_06:
        'But not Baross! Magic or not, he struck undetected from the fog!',
    story_polonia_02_07:
        'Robbed them blind of all their valuables, leaving only his echoing laughter as his calling card...',
    story_polonia_02_08:
        'Even after every other pirates had retired, he continued to terrorize the seas for decades!',
    story_polonia_02_09: "That's quite a legacy to live up to!",
    story_polonia_02_10:
        'Aye! And after each voyage, he would always find time to visit his granddaughter and regale her with tales of adventure...',
    story_polonia_02_11:
        '"Raise the black flag! Fire the cannons! All men starside, ready to plunder!"',
    story_polonia_02_12: "Starside? Isn't it called starboard?",
    story_polonia_02_13:
        "...Argh! Blast it all! I'm just a landlubber with a fancy costume, trying too hard to talk like a pirate.",
    story_polonia_02_14: "What's a pirate without a ship, anyway!?",
    story_polonia_02_15: 'No ship, huh? Maybe you should start there.',
    story_polonia_02_16: 'Huh?',
    story_polonia_02_17:
        "A captain without a ship won't be pulling a crew anytime soon!",
    story_polonia_02_18:
        'But if a ship is what you need, I think I know the right man for the job.',
    story_polonia_02_19: '...Really?',
    story_polonia_02_20:
        "I mean, by the Jolly Roger, thank ye, tavern lady! Cap'n Polonia owes ye one!",
    story_polonia_02_21: "Oh believe me, I'm counting on it.",
    story_polonia_03_name: 'Downright Shipwright',
    story_polonia_03_01: 'You want me to build an entire ship!?',
    story_polonia_03_02: 'Aye. Can ye do it?',
    story_polonia_03_03:
        'You bet! This will be my masterpiece! Let me see those plans.',
    story_polonia_03_04:
        'Hmm... they are quite elaborate. What are these scribbles?',
    story_polonia_03_05: "Me handwriting. I can't read.",
    story_polonia_03_06:
        'Ah, well that explains a few things. Still, these are more than enough.',
    story_polonia_03_07:
        "Yo ho ho! It'll be the greatest ship these seas have seen since Baross and Bonaventura!",
    story_polonia_03_08:
        'There is the matter of funding, though. Are you sure you can afford this?',
    story_polonia_03_09:
        "Aye, dubloons won't be a problem. Craft the ship, I'll bring yer booty.",
    story_polonia_04_name: "Captain's Share",
    story_polonia_04_01:
        "Arr, these Yetis be loaded with loot! I'll be paying for me ship in no time!",
    story_polonia_04_02: "Alright, dude, I'm here. So what do I do?",
    story_polonia_04_03: "That's Cap'n Polonia for ye, swabbie!",
    story_polonia_04_04:
        'Sure thing, captain dude. What do you want me to do?',
    story_polonia_04_05:
        'Ye brought yer cart. Ye carry the booty while I rob these Yetis blind.',
    story_polonia_04_06:
        'Wow, captain dude, you steal from these beautiful, furred creatures?',
    story_polonia_04_07:
        'Aye. For some reason, they be carrying tons of valuables like Warhammers and Compound Bows.',
    story_polonia_04_08:
        'What about the pelts, captain dude? Can I have them?',
    story_polonia_04_09:
        "Know yer place, swabbie! Pirates plunder, they don't kill!",
    story_polonia_04_10: "Oh, that's a waste.",
    story_polonia_04_11:
        'Not at all. If ye leave yer victims alive, they gather up more loot ye can plunder later. Basic economics!',
    story_polonia_04_12: 'Now keep up if ye want yer share, swabbie!',
    story_polonia_04_13: 'Aye aye, captain dude.',
    story_polonia_05_name: 'Maiden Voyage',
    story_polonia_05_01: "Behold, my life's work and masterpiece!",
    story_polonia_05_02:
        "It... it be beautiful. Ye brought a tear to this old salt's eye.",
    story_polonia_05_03:
        "Isn't it? I had to cut in a few places, namely your strange insistence for cannons atop the crow's nest...",
    story_polonia_05_04:
        "But I think it turned out more than fine, don't you think?",
    story_polonia_05_05:
        "That it did, matey. Here's yer dubloon, ye earned it.",
    story_polonia_05_06:
        'I certainly did! One last thing though. While I may be a master carpenter, cloth and fabric are beyond me.',
    story_polonia_05_07:
        "...And without a sail, your ship won't be leaving port anytime soon!",
    story_polonia_05_08:
        "Ye've done yer Cap'n proud already, matey. I have a sail right here.",
    story_polonia_05_09:
        "Wow! Now that's a work of art! Magical, too! Where did you get that?",
    story_polonia_05_10:
        'A heirloom from me grandpappy Baross. The very sail once atop his ship - the Bonaventura!',
    story_polonia_05_11:
        'I see! A remarkable finishing touch to this vessel!',
    story_polonia_05_12:
        'Well then, Captain Polonia, the Splendid Allan is yours!',
    story_polonia_05_13: 'Ye named her the Splendid Allan? Hold on...',
    story_polonia_05_14:
        "That figurehead... it's ye!? And butt naked, too!",
    story_polonia_05_15:
        'Hah hah! I can think of no finer figurehead for such a perfect ship!',
    story_polonia_06_name: 'Legacy at Sea',
    story_polonia_06_01: 'My King, I bring dire news.',
    story_polonia_06_02:
        'Oh no! Has the Lost City of Gold run out of gold!?',
    story_polonia_06_03: 'Nothing so dire, mercifully.',
    story_polonia_06_04:
        'However, I fear a meddlesome pirate has been plundering your personal merchant fleet.',
    story_polonia_06_05:
        '...The theft is estimated to be upwards of 100 trillion gold.',
    story_polonia_06_06:
        "By the Goddess! What of my fleet's state-of-the-art magical pirate detection?",
    story_polonia_06_07:
        'Utterly inefficient, it seems. Much like the great Baross himself, this ship eludes even our best magic...',
    story_polonia_06_08:
        '...Plundering your gold before slipping away into the sea.',
    story_polonia_06_09: 'Absolutely barbaric! What of the crew?',
    story_polonia_06_10:
        'Spared, my King. So long as they offer no resistance.',
    story_polonia_06_11:
        'Gold is a small price to pay then. I shall eat peasant meals until my fortune is restored!',
    story_polonia_06_12: '...How long will it take?',
    story_polonia_06_13: 'Approximately 2 days, my King.',
    story_polonia_06_14: 'Dark times indeed....',
    story_polonia_07_name: 'Shore Leave',
    story_polonia_07_01:
        'Yo ho ho! Captain Polonia be ashore! Rounds be on me!',
    story_polonia_07_02:
        'Polonia! Or should I say... Captain Polonia! I take it your "business" is going well?',
    story_polonia_07_03:
        "Ye bet yer landlubber bottoms it be! These seas be ripe for plunderin'!",
    story_polonia_07_04:
        "Wherever me ship goes, me grandpappy's sails catch the wind like nothing I ever seen!",
    story_polonia_07_05:
        "I'm sure Baross would be proud. How do you intend to spend these... ill-gotten goods?",
    story_polonia_07_06:
        'Like any good pirate. Buried it here and there with me crew.',
    story_polonia_07_07: 'Oooh! Treasure maps! How exciting!',
    story_polonia_07_08:
        "Aye, that's how pirates be. Keep some for ye cold winter months, like them squirrel landlubbers.",
    story_polonia_07_09: 'And what about the rest?',
    story_polonia_07_10: 'Gave it to the pretty temple lady.',
    story_polonia_07_11:
        'An orphanage donation? I did not take you for the charitable type.',
    story_polonia_07_12: 'Orpha what?',
    story_polonia_07_13: 'Ah, never mind. So what can I get you, Captain?',
    story_polonia_07_14:
        "Me favorite; Old Salt's Brew! And to any landlubber who think they can hold it down...",
    story_polonia_07_15:
        "Next round's on me! The Baross family is back at sea! Yo ho ho!",
    story_polonia_a_name: 'Getting a Leg Up',
    story_polonia_a_01: "Ahoy, pretty lady. Yer a healer, aren't ye?",
    story_polonia_a_02: 'Ah, yes! I am Freyja, priestess of this tem-',
    story_polonia_a_03: 'Chop me leg off.',
    story_polonia_a_04: 'Excuse me?',
    story_polonia_a_05: 'I asked ye to chop me leg off!',
    story_polonia_a_06:
        'Goodness gracious, no! Why would anyone ever want this?',
    story_polonia_a_07:
        "I be a pirate! What kind of pirate cap'n doesn't have a blasted peg leg!?",
    story_polonia_a_08:
        'Just chop me leg off and stitch it up with yer fancy magic!',
    story_polonia_a_09:
        "Absolutely not. I've sworn an oath to the Goddess never to harm a human being.",
    story_polonia_a_10:
        "Argh, useless landlubbers! Fine! But ye won't be gettin' any of me dubloons!",
    story_polonia_a_11: '...What a strange girl.',
    story_polonia_b_name: 'Leg It!',
    story_polonia_b_01: 'Ahoy, ye there.',
    story_polonia_b_02: 'Yeah?',
    story_polonia_b_03: 'Chop me leg off.',
    story_polonia_b_04: 'Sure.',
    story_polonia_b_05: 'I said- wait, yer ok with this?',
    story_polonia_b_06:
        'Yeah, sure. Just hold your leg out like this. This old axe should do the trick.',
    story_polonia_b_07: 'Err, hold on!',
    story_polonia_b_08:
        "It won't be pretty, but you're a tough girl, aren't you?",
    story_polonia_b_09:
        "Just make sure to apply a good tourniquet. Wouldn't want you bleeding out, now.",
    story_polonia_b_10: 'Avast, matey! Slow down!',
    story_polonia_b_11:
        "I- me ship's sailing soon! I-I gotta go catch it! See ya, matey!",
    story_polonia_b_12: '...Hah! Humans. Never a dull moment.',
    story_donovan_00_01: 'Greetings, esteemed shopkeeper.',
    story_donovan_00_02:
        "You're quite the economic force around these parts. I've heard a great deal about you.",
    story_donovan_00_03:
        'Ah, but how rude of me! I am Donovan, Imperial Alchemist of Kern.',
    story_donovan_00_04:
        "I believe this is the beginning of a great partnership, wouldn't you agree?",
    story_donovan_00_hire_01:
        'Ah, excellent timing! My vacation was growing dull.',
    story_donovan_00_hire_02:
        "Your Heroes will be glad to have me at their side, I'm sure of it.",
    story_donovan_00_hire_03:
        'I have a knack for bringing out the best in everything, be they ingredients or warriors.',
    story_donovan_00_hire_04:
        'My skills and knowledge are at your service, esteemed shopkeeper.',
    story_donovan_01_name: 'Original Recipe',
    story_donovan_01_01:
        'Welcome to the Maribel Medicine Emporium! How can I help you?',
    story_donovan_01_02:
        'Lady Maribel, was it? I would like to personally commend your latest creation.',
    story_donovan_01_03:
        'As the inventor of the Potion of Renewal, your modified blueprint is nothing short of genius!',
    story_donovan_01_04:
        'The simple addition of a dash of Mistpeak Bloom is a terrific improvement!',
    story_donovan_01_05:
        "Wait a minute... you mean you're THE Donovan? No way!",
    story_donovan_01_06:
        'The Potion of Renewal blueprint was invented roughly 700 years ago...',
    story_donovan_01_07: "Even as an elf, you don't look a day over 150!",
    story_donovan_01_08: 'You are too kind, Lady Maribel.',
    story_donovan_01_09:
        "Let's just say that not all of my blueprints are commercially available...",
    story_donovan_01_10:
        "But I'm certain a master alchemist such as yourself would have no trouble learning them.",
    story_donovan_01_11:
        'Now then, shall I take a look at some of that Mistpeak Bloom?',
    story_donovan_02_name: 'Land of Plenty',
    story_donovan_02_01:
        'Fascinating... Even the common apples around these parts are of excellent quality.',
    story_donovan_02_02: 'How could this be, I wonder...?',
    story_donovan_02_03:
        'If someone could explain it, it would be Juniper!',
    story_donovan_02_04:
        "Oh, sorry! I didn't mean to intrude in your conversation with that... tree.",
    story_donovan_02_05:
        'Ah, a young lady in a broad brimmed hat and her loyal owl...',
    story_donovan_02_06: 'You must be Miss Lilu, no doubt.',
    story_donovan_02_07:
        "That's right! And you, Sir Donovan, obviously need no introduction!",
    story_donovan_02_08:
        "I've heard much about the prized young pupil of this city. The pleasure is all mine.",
    story_donovan_02_09: 'Now then, Miss Lilu. You were saying...?',
    story_donovan_02_10:
        'Oh yes! Juniper is always talking about how much the land around the city is teeming with life...',
    story_donovan_02_11:
        "It's part of the reason she enjoys gardening here so much, she says.",
    story_donovan_02_12:
        'In any case, I was on my way to the Aurora Caves. Nice meeting you, Sir Donovan!',
    story_donovan_02_13: 'May we meet again soon.',
    story_donovan_02_14:
        'Hmm... Perhaps this young lady is onto something...',
    story_donovan_02_15:
        'Kern... depleted? Everything seems much more vibrant in this kingdom.',
    story_donovan_02_16:
        'I should pay a visit to this gardener. If ingredients are more potent here...',
    story_donovan_02_17: '...Then it is something I must exploit.',
    story_donovan_03_name: 'Behind the Mask',
    story_donovan_03_01: 'You called for me, Miss Sia?',
    story_donovan_03_02:
        'Ah, Lilu. Thank you for coming on such short notice.',
    story_donovan_03_03:
        "...I believe you've already acquainted yourself with Donovan?",
    story_donovan_03_04:
        "Oh, yes! He's a legend among alchemists! I'm so glad I got to meet him!",
    story_donovan_03_05: 'Approach that man with extreme caution.',
    story_donovan_03_06:
        "H-huh!? But he's such a gentleman! Very knowledgeable, too...",
    story_donovan_03_07:
        'Appearances can be deceiving. Behind his youthful facade lies a cunning, millennia old mastermind.',
    story_donovan_03_08:
        "Millennia!? That's unheard of, even for elves! Do you think...",
    story_donovan_03_09:
        'Yes. Some believe he may have discovered a sort of elixir of immortality.',
    story_donovan_03_10:
        "Wow! I wouldn't mind adding that recipe to my grimoire!",
    story_donovan_03_11:
        'As would many rulers. I suspect he may have the Kern emperor dancing to his tune.',
    story_donovan_03_12: '...Politics sure are complicated.',
    story_donovan_03_13: "But thanks! I'll be careful around him!",
    story_donovan_03_14: 'I knew you would understand.',
    story_donovan_03_15:
        "As for me, I'll make sure to keep him away from our... impressionable king.",
    story_donovan_03_16: "Yeah... that's probably for the best.",
    story_donovan_a_name: "Ten Gold's a Steal",
    story_donovan_a_01: "Hey, potion dude. You got a sec'?",
    story_donovan_a_02: 'Ugh... vagabonds.',
    story_donovan_a_03: 'To whom do I owe this... pleasure?',
    story_donovan_a_04: "I'm Tamas, the leather guy.",
    story_donovan_a_05:
        'Heard you could fix up a cool elixir of immortality, dude. Can I get a bottle?',
    story_donovan_a_06:
        "...I have no idea where you've caught wind of this rumor.",
    story_donovan_a_07:
        'Even if it were true, you can be certain I would not be giving away something so valuable.',
    story_donovan_a_08: "I'll give you ten gold for it.",
    story_donovan_a_09:
        'Absolutely NOT. Wars have been fought over this recipe! Emperors all over the world beg for a mere taste!',
    story_donovan_a_10:
        'You drive a hard bargain, dude. Fifteen gold, final offer.',
    story_donovan_a_11:
        'Has the smell of leather addled your brain, vagrant? I would not part with this recipe for all the gold in the world!',
    story_donovan_a_12: 'I must be off. Please never speak to me again.',
    story_donovan_a_13: 'Dang, I almost had it.',
    story_donovan_a_14:
        "...Guess I'll have to find another treat for those sheep.",
    story_hemma_00_01: 'Well well...',
    story_hemma_00_02:
        'From what we gathered, you are the one to talk to when we need something done around these parts...',
    story_hemma_00_03:
        'Do not ask too many questions, and we can make it worth your while. ',
    story_hemma_00_hire_01: 'Fantastic.',
    story_hemma_00_hire_02: 'You will not be disappointed with us.',
    story_hemma_00_hire_03:
        'Our powers, combined with sturdy companions, are formidable.',
    story_hemma_00_hire_04:
        'The results will speak for themselves, we are sure of it.',
    story_hemma_01_name: 'Looming Shadow',
    story_hemma_01_01:
        '...And so the Troublin fell, but not before landing some blows on my companions.',
    story_hemma_01_02: 'Please see to it that they are healed.',
    story_hemma_01_03:
        'But of course. I will tend to their wounds posthaste.',
    story_hemma_01_04: 'Hi! I heard there was a new spellcaster in town.',
    story_hemma_01_05: "I'm Lilu! Pleased to make your acquaintance!",
    story_hemma_01_06: 'You are correct, young Lilu. Our name is Hemma. ',
    story_hemma_01_07: '..."Our"?',
    story_hemma_01_08:
        '...Yes. Necromancy takes a toll on its practitioners...',
    story_hemma_01_09:
        'A part of you stays across the Veil, while other things cross the threshold...',
    story_hemma_01_10:
        'These powers... changed us. For the best, of course.',
    story_hemma_01_11: "Necromancy, you say!? Isn't that... dangerous?",
    story_hemma_01_12:
        'Incredibly so. But we are not satisfied with simple tricks anymore.',
    story_hemma_01_13:
        "Now, if you'll excuse us, we need to be elsewhere. Farewell.",
    story_hemma_01_14:
        "A necromancer... That is some pretty dark magic, Pollux! But it's the first time I can see a practitioner for real!",
    story_hemma_01_15: "She didn't seem so bad... Did she?",
    story_hemma_02_name: 'At Any Cost',
    story_hemma_02_01: 'A necromancer...?',
    story_hemma_02_02: "I'm not sure what to make of all this...",
    story_hemma_02_03:
        'You are right to worry. Necromancy is a dangerous art that would consume lesser beings...',
    story_hemma_02_04: 'But we are not lesser in any way.',
    story_hemma_02_05:
        'What about the adventurers that go with you? Do you not drain them to empower your spells?',
    story_hemma_02_06:
        'Only with their consent. The effects are not permanent.',
    story_hemma_02_07: 'And we do pay them handsomely for their services.',
    story_hemma_02_08: 'I suppose you are doing some good after all...',
    story_hemma_02_09:
        'Very well. I suppose you can be a sanctioned Champion of our city.',
    story_hemma_02_10: 'We did not need your approval... but we thank you.',
    story_hemma_02_11: 'So what brought you here to our city?',
    story_hemma_02_12: '...',
    story_hemma_02_13: 'We simply seek knowledge and power. ',
    story_hemma_02_14: 'There is... a task we must do.',
    story_hemma_02_15: 'How very vague...',
    story_hemma_02_16: 'And it will stay this way. Farewell.',
    story_hemma_02_17: '...',
    story_hemma_02_18: 'There is much sadness in those eyes...',
    story_hemma_03_name: 'The Enemy Within',
    story_hemma_03_01:
        '...Unripe Living Roots, and an extra chunky Spooky Ectoplasm. Anything else?',
    story_hemma_03_02:
        'That will be all. We shall visit the shopkeeper for the rest.',
    story_hemma_03_03: 'Thank you, come again!',
    story_hemma_03_04: "<c4>...Don't forget the Grim Talons.</c4>",
    story_hemma_03_05: 'Ah, yes. A pair of Grim Talons, please.',
    story_hemma_03_06: '...What was THAT just now?',
    story_hemma_03_07: 'Pay it no mind. A mere passenger, if you will.',
    story_hemma_03_08:
        '<c4>Let-me-out-let-me-out-let-me-out-let-me-out.</c4>',
    story_hemma_03_09: 'Ugh. Settle down, will you?',
    story_hemma_03_10: 'You may keep the change. Farewell.',
    story_hemma_03_11: '...Thanks for the tip... and the nightmares.',
    story_hemma_04_name: 'Exorcism',
    story_hemma_04_01: "Don't worry, Hemma. I'm here! I brought Freyja!",
    story_hemma_04_02:
        'Hemma, was it? Please relax. Let the exorcism flow through you...',
    story_hemma_04_03: "We don't recall asking for your assistance.",
    story_hemma_04_04: "<c4>Beat it, kids. I ain't going anywhere.</c4>",
    story_hemma_04_05: "Aaaah! It's taking over! Quick, Freyja!",
    story_hemma_04_06: "I... can't! Something's blocking my prayers...",
    story_hemma_04_07: 'We are. Stop that.',
    story_hemma_04_08:
        'Are you... willingly hosting this malevolent spirit!?',
    story_hemma_04_09: 'He has gone a bit mad over time, I admit.',
    story_hemma_04_10: "<c4>I'm the victim here! Let me out!</c4>",
    story_hemma_04_11: 'Ugh. More rude than malevolent, I can assure you.',
    story_hemma_04_12:
        'This matter does not concern you. Please do not get involved, Freyja.',
    story_hemma_04_13:
        "Those necromancers... always toying with powers they don't understand!",
    story_hemma_04_14:
        "It's OK Freyja! I'm sure she knows what she's doing!",
    story_hemma_04_15: '...Right?',
    story_hemma_05_name: 'She of Two Minds',
    story_hemma_05_01:
        "<c4>Looks like the cat's out of the bag again. Ready to leave town?</c4>",
    story_hemma_05_02:
        'YOU did this. If you could keep quiet, things would be a lot easier.',
    story_hemma_05_03:
        "Regardless, we're not leaving yet. This town shows promise.",
    story_hemma_05_04: '<c4>Fine. How about letting me out for once?</c4>',
    story_hemma_05_05:
        "A-absolutely not. You're hard enough to keep under control as it is.",
    story_hemma_05_06:
        '<c4>Let-me-out-let-me-out-let-me-out-let-me-out.</c4>',
    story_hemma_05_07: "I'm doing this for your sake, Claud.",
    story_hemma_05_08: '...Please remember that.',
    story_hemma_a_name: 'Among Immortals',
    story_hemma_a_01: 'Unripe Living Roots? Interesting choice.',
    story_hemma_a_02: 'What of it?',
    story_hemma_a_03:
        'Living Roots are potent reagants for many magics related to youth and lifespan.',
    story_hemma_a_04:
        'Might you be a fellow alchemist? A quest for longevity, perhaps?',
    story_hemma_a_05: '...You would ask a lady her age? How... unbecoming.',
    story_hemma_a_06:
        'We are no alchemist. Blood sacrifices are much more efficient.',
    story_hemma_a_07:
        'Ah, necromancy! A respectable, but misunderstood field.',
    story_hemma_a_08:
        'As for our age... we stopped counting quite a while ago.',
    story_hemma_a_09:
        'I can definitely relate. Decades just seem to fly by after the first few centuries!',
    story_hemma_a_10:
        "It gets lonely among all these mayflies, doesn't it?",
    story_hemma_a_11:
        "Lonely, we are not. You would do well not to pry in a Necromancer's business.",
    story_hemma_a_12: 'Of course. I would not wish to intrude. Farewell!',
    story_hemma_a_13: 'Strange necromancer... Have we met before?',
    story_hemma_a_14: '...Fairlyn?',
    story_ashley_00_01:
        "Oi, I'm new in town. You know a good place for a Samurai to crash?",
    story_ashley_00_02: "Emerald Inn? That'll do.",
    story_ashley_00_03: "Name's uh... Ashley. Yeah. That's my name.",
    story_ashley_00_04:
        "Since I'm here, I might as well grab something, yeah?",
    story_ashley_00_hire_01:
        "Think I'll be sticking around for a bit longer. It's getting a bit boring, though.",
    story_ashley_00_hire_02:
        'How about sending me on a quest, boss? The toughest one you got.',
    story_ashley_00_hire_03:
        "Send me out to die, and I'll come back stronger.",
    story_ashley_00_hire_04: 'Heh. That sounded pretty cool, yeah?',
    story_ashley_01_name: 'The Unusual Samurai',
    story_ashley_01_01: "A real Samurai...! I can't wait!",
    story_ashley_01_02: "Lady Samurai! I'm Argon! Knight-in-train-",
    story_ashley_01_03: "Oi. I'm Ashley. What's up?",
    story_ashley_01_04: 'O-oi?',
    story_ashley_01_05: 'Feet off the table, please!',
    story_ashley_01_06: 'Tch. Alright.',
    story_ashley_01_07: "Well? What'd you need?",
    story_ashley_01_08:
        "Uh... aren't Samurai usually more like, orderly and stuff?",
    story_ashley_01_09:
        'Huh? Yeah, the guys back home were pretty stuffy about it.',
    story_ashley_01_10:
        "I'm free now. Just a Ronin. A Samurai without a lord.",
    story_ashley_01_11: 'I see... Oh, right!',
    story_ashley_01_12:
        'I had a quest planned with Lilu at the Haunted Castle but...',
    story_ashley_01_13:
        'Something came up at the Training Hall! Can you fill in for me?',
    story_ashley_01_14: 'Sure. But you owe me, knight boy.',
    story_ashley_02_name: 'Armed & Ready',
    story_ashley_02_01: "You're Lilu, yeah?",
    story_ashley_02_02: "Yes, that's right! Pleased to meet you!",
    story_ashley_02_03: "Right then, let's get going.",
    story_ashley_02_04: 'W-wait! Hold on!',
    story_ashley_02_05:
        "We still haven't made preparations! Potions, rations, and-",
    story_ashley_02_06: "Whatever, we don't need any of that.",
    story_ashley_02_07: 'We live or we die. No in-between.',
    story_ashley_02_08: "That's... no! That's too reckless!",
    story_ashley_02_09: "I'm going. This way, right?",
    story_ashley_02_10: "No! That's the Howling Woods...",
    story_ashley_02_11: 'Um... Good luck?',
    story_ashley_03_name: "Life's Edge",
    story_ashley_03_01: 'Is that all you monsters got? BRING IT!',
    story_ashley_03_02:
        "Ashley! Please slow down! My healing can't keep up!",
    story_ashley_03_03: 'This... is... NOTHING!!!',
    story_ashley_03_04: 'Whew... good scrap, yeah?',
    story_ashley_03_05: 'Are you kidding!? You almost died!',
    story_ashley_03_06:
        "It's no wonder you lost your arm if you keep acting like this!",
    story_ashley_03_07: 'Oi. That was different.',
    story_ashley_03_08: "That mistake... I won't repeat.",
    story_ashley_03_09: "Hmph. I'm not using anesthetics for this healing.",
    story_ashley_03_10: 'This is your own fault. Brace up.',
    story_ashley_03_11: 'Haha... hah... I can take it...',
    story_ashley_03_12: 'Ouch! Ow ow ow-',
    story_ashley_a_name: 'Familiar Feeling',
    story_ashley_a_01:
        "Oi. What's with all these tiny monsters around here?",
    story_ashley_a_02: 'These would be my dear familiars. Welcome.',
    story_ashley_a_03:
        'Worthy Champions can come and adopt one as a faithful companion.',
    story_ashley_a_04: 'Oh. Pets, yeah? I get it.',
    story_ashley_a_05:
        "Alright. You, green guy. I'll call you <c1>Tricky</c1>. Come with me.",
    story_ashley_a_06: "You're cute, <c1>Snowball</c1>. I'll carry you.",
    story_ashley_a_07: "Ah... I'm afraid that-",
    story_ashley_a_08: "Yikes, that's one ugly bird!",
    story_ashley_a_09: "I'll call you <c1>Sparrow</c1>. You can come too.",
    story_ashley_a_10: '...I see. Have fun, you all.',
    story_atlab_aang_00_01: 'Hi! Are you the shopkeeper here?',
    story_atlab_aang_00_02: 'This entire shop is yours? Wow!',
    story_atlab_aang_00_03: "Oh, sorry! I'm Aang, the Avatar!",
    story_atlab_aang_00_04:
        'Me and my friends were riding on my sky bison, but I think we took a wrong turn somewhere...',
    story_atlab_aang_00_05:
        '...Hey, those things look interesting! How much for them?',
    story_atlab_aang_00_hire_01:
        "We'll be staying here for some time. My bison Appa needs to rest...",
    story_atlab_aang_00_hire_02:
        'But the nice man over at the Emerald Inn told me we could stay for free if we helped you out!',
    story_atlab_aang_00_hire_03:
        "I'm looking forward to it! I'm sure Sokka will help too, if you can find him!",
    story_atlab_aang_farewell_01: "Shopkeeper! Appa's all rested up!",
    story_atlab_aang_farewell_02:
        "So... I guess it's time for farewells...",
    story_atlab_aang_farewell_03:
        'Hey, cheer up! We can come back anytime!',
    story_atlab_aang_farewell_04:
        "You're right! Do you remember the way, Sokka?",
    story_atlab_aang_farewell_05: "Uh... I wasn't paying attention...",
    story_atlab_aang_farewell_06:
        "A-anyway! We've got super important Avatar business to get back to!",
    story_atlab_aang_farewell_07: "C'mon Momo! We're leaving!",
    story_atlab_aang_farewell_08: 'Momo! ...Momo?',
    story_atlab_aang_farewell_09:
        "You like it here, don't you? All right, fine.",
    story_atlab_aang_farewell_10:
        'Shopkeeper, would you mind keeping an eye on Momo for a while?',
    story_atlab_aang_farewell_11:
        "I'll come back for you later, Momo. Promise!",
    story_atlab_aang_farewell_12:
        "Let's go, Sokka. Thanks for everything, Shopkeeper!",
    story_atlab_aang_farewell_13: 'Bye!',
    story_atlab_aang_farewell_14: 'Come on, Appa! Yip yip!',
    story_atlab_aang_01_name: 'Fated Wrong Turn',
    story_atlab_aang_01_01:
        "Sorry kid, I didn't get all that. Mind repeating your story from the start?",
    story_atlab_aang_01_02: 'Sorry! Let me start over!',
    story_atlab_aang_01_03: "I'm Aang, the Avatar.",
    story_atlab_aang_01_04:
        'Me and my friends were traveling on my sky bison...',
    story_atlab_aang_01_05:
        "While flying, we must've taken a wrong turn, because we ended up here!",
    story_atlab_aang_01_06: 'That huge ox outside? That thing flies?',
    story_atlab_aang_01_07: "Yes! His name is Appa. He's very friendly!",
    story_atlab_aang_01_08:
        'Looks pretty exhausted, too. You guys are being chased by someone, right?',
    story_atlab_aang_01_09: 'Oh, um...',
    story_atlab_aang_01_10:
        "Hey, don't worry about it. I won't let anyone under my roof come to harm. Oscar's promise.",
    story_atlab_aang_01_11:
        'Tell you what, how would you guys like to stay here for free?',
    story_atlab_aang_01_12: 'For free? Really!?',
    story_atlab_aang_01_13:
        "Well for starters, it doesn't look like that weird money of yours will be worth anything here.",
    story_atlab_aang_01_14:
        "I can't just let a bunch of kids sleep out in the streets now, can I?",
    story_atlab_aang_01_15: 'T-thank you Mr. Oscar!',
    story_atlab_aang_01_16:
        'You look pretty capable, how about you go help my shopkeeper buddy in exchange?',
    story_atlab_aang_01_17: 'Sure thing! Thank you so much!',
    story_atlab_aang_02_name: 'Firebending?',
    story_atlab_aang_02_01: 'Hey, Lilu! Come meet my new buddy here!',
    story_atlab_aang_02_02:
        "Hi! I've never seen tattoos like yours before...",
    story_atlab_aang_02_03:
        "I'm Aang! I've never seen a hat like yours, either!",
    story_atlab_aang_02_04: "C'mon Aang, show her your trick!",
    story_atlab_aang_02_05: 'You mean... this?',
    story_atlab_aang_02_06:
        "H-how did you do that? I didn't sense any magic!",
    story_atlab_aang_02_07: 'Airbending! I can also bend earth and water!',
    story_atlab_aang_02_08: 'Incredible! Hang on, let me take notes...',
    story_atlab_aang_02_09:
        "Could it be Chi? I've never seen anyone do this before...",
    story_atlab_aang_02_10:
        "That's right! But not anyone can do it, you need to be born with the ability and learn to master it!",
    story_atlab_aang_02_11:
        'You said you could bend air, earth and water... what about fire?',
    story_atlab_aang_02_12:
        "Ah... I... I haven't mastered firebending yet...",
    story_atlab_aang_02_13:
        "...I've had problems finding a firebending master to teach me.",
    story_atlab_aang_02_14: 'Is that so? I know some fire magic!',
    story_atlab_aang_02_15:
        "It's not quite the same, but you might like to see how it works!",
    story_atlab_aang_02_16: 'Ah... sure. Thanks, Lilu.',
    story_atlab_aang_03_name: 'The Last Airbender',
    story_atlab_aang_03_01:
        'Aang, my boy. How are you liking the city life?',
    story_atlab_aang_03_02:
        "Elder Owen! It's great! Thank you for your hospitality.",
    story_atlab_aang_03_03:
        'It is nothing. But tell me, my boy, where are your parents?',
    story_atlab_aang_03_04: "Ah... I don't... have parents anymore.",
    story_atlab_aang_03_05:
        'My family... all of the Air Nomads were killed by the Fire Nation.',
    story_atlab_aang_03_06: "...I'm the only one left.",
    story_atlab_aang_03_07: 'Dear me! My condolences, poor child....',
    story_atlab_aang_03_08:
        '...If you have nowhere to return to, how about settling here with your friends?',
    story_atlab_aang_03_09:
        "I'm certain the city would be overjoyed to have you stay. You're quite popular already!",
    story_atlab_aang_03_10:
        "Thank you for the generous offer, Elder... but I can't accept.",
    story_atlab_aang_03_11:
        'As we speak, Fire Lord Ozai is already planning his next move.',
    story_atlab_aang_03_12:
        "When Sozin's Comet lights the sky again, the Fire Nation will conquer my world...",
    story_atlab_aang_03_13:
        '...The Earth Kingdom... the Water Tribes... They will suffer the same fate as the Air Nomads.',
    story_atlab_aang_03_14:
        "As the Avatar, I'm the only one who can stop him! ...but I'm not ready.",
    story_atlab_aang_03_15: 'What a terrible burden for a child to bear...',
    story_atlab_aang_03_16:
        "No matter what, know that you have our city's full support.",
    story_atlab_aang_03_17:
        'Thank you, Elder. You have all done so much for us already!',
    story_atlab_aang_03_18:
        "We'll be sure to pay you all a visit when this is all over!",
    story_atlab_aang_04_name: "Sozin's Omen",
    story_atlab_aang_04_01: 'Avatar. Our paths finally cross.',
    story_atlab_aang_04_02: 'Who are you? How do you know me?',
    story_atlab_aang_04_03:
        "The Avatar's presence shines like a beacon to the Third Eye.",
    story_atlab_aang_04_04: "Oh, fortune telling... I'll pass!",
    story_atlab_aang_04_05: 'You, who is many, yet not quite whole...',
    story_atlab_aang_04_06: 'A fierce battle with the Lord of Fire awaits.',
    story_atlab_aang_04_07: 'Fire Lord Ozai... You know about him?',
    story_atlab_aang_04_08:
        "Under the Comet's gaze, land will be torn asunder, and the fields will be burned to ashes!",
    story_atlab_aang_04_09: 'I know that! But what should I do?',
    story_atlab_aang_04_10: 'The flow of fate cannot be broken.',
    story_atlab_aang_04_11: '...',
    story_atlab_aang_04_12:
        '...But the brave can pull at the strings, and lead it astray.',
    story_atlab_aang_04_13:
        'An unlikely ally and a fragile alliance may be the key.',
    story_atlab_aang_04_14:
        "Thanks, fortune teller... I'll think about it.",
    story_atlab_aang_04_15: '...',
    story_atlab_aang_04_16:
        'Be brave, Avatar. Stay true to yourself, and you shall overcome.',
    story_atlab_sokka_00_01:
        "Wow, Aang wasn't kidding! This shop is loaded!",
    story_atlab_sokka_00_02: 'Maces, swords, spears...',
    story_atlab_sokka_00_03:
        'Shopkeeper, I can tell you and I are going to be great friends.',
    story_atlab_sokka_00_04:
        'If you give me a good price for these, of course.',
    story_atlab_sokka_00_hire_01: 'Welcome to Team Avatar, shopkeeper.',
    story_atlab_sokka_00_hire_02:
        "As the new member of the team, you'll need to cut us some great deals!",
    story_atlab_sokka_00_hire_03:
        'Ah, I guess I can go gather materials for you every now and then.',
    story_atlab_sokka_00_hire_04:
        'But I need to keep an eye on Aang, alright?',
    story_atlab_sokka_01_name: 'Great Warrior Sokka',
    story_atlab_sokka_01_01: 'And how about... this!',
    story_atlab_sokka_01_02: "Oh, that's a cute trick!",
    story_atlab_sokka_01_03: 'Ugh. He does that all the time!',
    story_atlab_sokka_01_04: "Hi, Sokka right? I'm Argon!",
    story_atlab_sokka_01_05:
        "Oh, hey. If you want bending tricks, you're looking for Aang.",
    story_atlab_sokka_01_06:
        "Oh, no. I don't know much about magic. Or bending.",
    story_atlab_sokka_01_07:
        'Judging from your gear, you looked to be a fearsome warrior.',
    story_atlab_sokka_01_08: 'F-fearsome warrior?',
    story_atlab_sokka_01_09:
        "W-well you'd be right! I'm Sokka, the Great Warrior of Team Avatar!",
    story_atlab_sokka_01_10:
        'I knew it! You must have some incredible tales to tell!',
    story_atlab_sokka_01_11:
        "You bet! For example, during the siege of Agna Qel'a...",
    story_atlab_sokka_01_12:
        "And that's how my girlfriend, at the time, became the moon.",
    story_atlab_sokka_01_13: 'T-the moon!?',
    story_atlab_sokka_01_14: 'Yeah. But then, the Fire Nation fleet...',
    story_atlab_sokka_01_15:
        '...and bang! I knocked out that giant owl guy in the library.',
    story_atlab_sokka_01_16: "Wow! Wasn't that owl a powerful spirit?",
    story_atlab_sokka_01_17:
        "That's right! But Team Avatar is always victorious with Sokka around!",
    story_atlab_sokka_01_18: "...Speaking of that, where's Aang?",
    story_atlab_sokka_01_19: 'Oh, he left about an hour ago.',
    story_atlab_sokka_01_20:
        'Dang! I completely lost track of time. Sorry Argon, gotta run!',
    story_atlab_sokka_02_name: 'Great Smith Sokka',
    story_atlab_sokka_02_01: 'Hard at work, Sokka?',
    story_atlab_sokka_02_02:
        'Hey, Wallace. Thanks for letting me use your tools!',
    story_atlab_sokka_02_03:
        "No problem! I didn't expect you to be such a craftsman at your age!",
    story_atlab_sokka_02_04:
        "When you're the only non-bender in the group, you gotta pick up all sorts of skills.",
    story_atlab_sokka_02_05:
        "It's not every day I get to use great tools like these...",
    story_atlab_sokka_02_06: 'Maintenance will be done in a flash!',
    story_atlab_sokka_02_07:
        "That sword you're sharpening... where did you get that?",
    story_atlab_sokka_02_08: "That's my Space Sword! Forged it myself!",
    story_atlab_sokka_02_09: 'Star Metal? Not bad, kid!',
    story_atlab_sokka_02_10: 'It must be... out of this world!',
    story_atlab_sokka_02_11: "You bet! There's no other sword like it!",
    story_atlab_sokka_02_12:
        'Ahoy! Sorry to interrupt, but any of ye fine lads care to help fix me ship?',
    story_atlab_sokka_02_13:
        'She be right moody today. Hit some weird iron balls and got herself beached...',
    story_atlab_sokka_02_14: 'Sounds like a boatload of trouble!',
    story_atlab_sokka_02_15:
        "Hey, no problem, I'm Water Tribe! I’ll see what I can do. Sorry Wallace, I gotta go!",
    story_atlab_sokka_02_16:
        "I'll repair the rest of your gear in the meantime. Good luck!",
    story_atlab_sokka_03_name: 'Great Navigator Sokka',
    story_atlab_sokka_03_01:
        'There, that should get you to port safely, at least.',
    story_atlab_sokka_03_02: "That's a proper fix. All solid-like!",
    story_atlab_sokka_03_03:
        "....Your rudder's held together with driftwood and pieces of my shirt.",
    story_atlab_sokka_03_04:
        'I wouldn\'t say "solid-like". More like, Sokka fix.',
    story_atlab_sokka_03_05:
        "Yo ho ho! How come a landlubber like ye be fixin' ships, then?",
    story_atlab_sokka_03_06:
        'Water Tribe life is all about boats and fishing. I was sailing before I could walk.',
    story_atlab_sokka_03_07:
        'Just recently, I even came up with plans for a waterbending-powered submarine!',
    story_atlab_sokka_03_08: "Yer speakin' all landlubby. What be that?",
    story_atlab_sokka_03_09:
        "It's... uh, a metal ship that goes underwater.",
    story_atlab_sokka_03_10:
        "Sounds to me like yer sending sea dogs to Davy Jones' Locker in a fancy box.",
    story_atlab_sokka_03_11:
        'Did I just hear, "highly dangerous, untested prototype"?',
    story_atlab_sokka_03_12: 'Ah... not quite-',
    story_atlab_sokka_03_13: 'I like your style. Come with me, new guy.',
    story_atlab_sokka_03_14:
        'Yo ho ho, back to sea! Anchors aweigh, before the bounty hunters get here!',
    story_atlab_sokka_03_15:
        'Arr, better dodge the floaty iron balls this time.',
    story_atlab_sokka_04_name: 'Great Inventor Sokka',
    story_atlab_sokka_04_01:
        "Fascinating! Not gonna lie, your blueprint's a bit shoddy...",
    story_atlab_sokka_04_02:
        '...But with a bit of elbow grease, I think that submarine can work!',
    story_atlab_sokka_04_03: 'Genius, right? I knew it!',
    story_atlab_sokka_04_04:
        "Though keep in mind it's designed to be operated by Waterbenders.",
    story_atlab_sokka_04_05:
        "Details, details. We'll figure something out.",
    story_atlab_sokka_04_06:
        'Just think! With this, we could explore the Sunken Temple!',
    story_atlab_sokka_04_07:
        "Oh, and it doesn't hold a lot of air, so keep the trips short.",
    story_atlab_sokka_04_08: '...Are you listening?',
    story_atlab_sokka_04_09: "Don't lean on that large iron ball.",
    story_atlab_sokka_04_10: '...Huh? Woah! Why not?',
    story_atlab_sokka_04_11:
        "My prototype sea bomb. Don't touch it. Very explody.",
    story_atlab_sokka_04_12: '...Oh.',
    story_atlab_sokka_04_13:
        'Well anyway, let me know how the prototype goes.',
    story_atlab_sokka_04_14:
        'If it works... we have a use for them in the future.',
    height: 'Height',
    body_type: 'Body Type',
    complexion: 'Complexion',
    hair_style: 'Hairstyle',
    hat_toggle: 'Show Hat',
    hair_color: 'Hair Color',
    facial_hair: 'Facial Features',
    mustache: 'Mustache',
    beard: 'Beard',
    expression: 'Expression',
    eye_color: 'Eye Color',
    attire_color: 'Clothes',
    top: 'Top',
    bottom: 'Bottom',
    hat: 'Hat',
    gloves: 'Handwear',
    boots: 'Footgear',
    eyewear: 'Face Items',
    handheld: 'Accessories',
    customization_type_itembody: 'Upper body',
    customization_type_itempants: 'Lower body',
    customization_type_itemhead: 'Headwear',
    customization_type_itemhands: 'Handwear',
    customization_type_itemfeet: 'Footgear',
    customization_type_itemface: 'Face item',
    customization_type_itemweapon: 'Handheld item',
    customization_type_haircut: 'Haircut',
    customization_type_mustache: 'Mustache',
    customization_type_beard: 'Beard',
    color: 'Color',
    avatareditor_style_tab: 'Style',
    avatareditor_color_tab: 'Color',
    avatareditor_color1_tab: 'Color 1',
    avatareditor_color2_tab: 'Color 2',
    unlock_customization_title_format: 'Unlock {0}',
    customization_haircut_bald: 'Bald',
    customization_haircut_Male01_Hair01: 'Heroic',
    customization_haircut_Male01_Hair02: 'Windswept',
    customization_haircut_Male01_Hair03: 'The Peak',
    customization_haircut_Female01_Hair01: 'Untamed',
    customization_haircut_Female01_Hair02: 'Bun',
    customization_haircut_Female01_Hair03: 'Peek-A-Boo',
    customization_haircut_Female01_Hair04: 'Short Braids',
    customization_haircut_Male01_Hair04: 'Combover',
    customization_haircut_Male01_Hair05: 'Suave',
    customization_haircut_Female01_Hair05: 'Feathered',
    customization_haircut_Female01_Hair06: 'Bobcut',
    customization_haircut_Male01_Hair06: 'Bowl Cut',
    customization_haircut_Pompadour: 'Pompadour',
    customization_haircut_Loosefro: 'Loose Afro',
    customization_haircut_Ponytail: 'Ponytail',
    customization_haircut_Asymmetricbob: 'Assymetrical Bob',
    customization_haircut_Princesswaves: 'Princess Waves',
    customization_haircut_summonerhair: 'Summoner Hair',
    customization_beard_no_beard: 'No Beard',
    customization_beard_male01_Beard01: 'Chin Curtain',
    customization_beard_male01_Beard02: 'Goatee',
    customization_beard_male01_Beard03: 'Venerable',
    customization_beard_male01_Beard04: 'Full',
    customization_beard_male01_Beard05: 'Chin Puff',
    customization_beard_santabeard: "Santa's Beard",
    customization_beard_tannerbeard: "Tamas' Beard",
    customization_mustache_no_mustache: 'No Mustache',
    customization_mustache_male01_Mustache01: 'Classy',
    customization_mustache_male01_Mustache02: 'Professor',
    customization_mustache_male01_Mustache03: 'Fu Manchu',
    customization_mustache_movember_01: "Dad's",
    customization_mustache_movember_02: 'Creeper',
    customization_mustache_movember_03: 'Broom',
    customization_mustache_movember_04: 'Handlebar',
    customization_mustache_movember_05: 'Dastardly',
    customization_mustache_movember_06: 'Brush',
    customization_mustache_santamustache: "Santa's",
    no_hat: 'Hatless',
    shopkeeper_hat: 'Merchant Hat',
    customization_itemhead_custom_hat_bunnyears: 'Bunny Ears',
    customization_itemhead_custom_hat_merchant: 'Great Merchant',
    customization_itemhead_custom_hat_dundee: 'The Dundee',
    customization_itemhead_custom_hat_farmer: "Plumber's Hat",
    customization_itemhead_custom_hat_icecream: 'Ice Cream',
    customization_itemhead_custom_hat_bandana: 'Bandana',
    customization_itemhead_custom_hat_halo: 'Halo',
    customization_itemhead_custom_hat_pirate: 'Corsair Hat',
    customization_itemhead_custom_hat_wings: 'Angel Wings',
    customization_itemhead_custom_hat_horns: 'Demon Horns',
    customization_itemhead_custom_hat_cloud: 'Rainy Day',
    customization_itemhead_custom_hat_engineer: "Roxanne's Hat",
    customization_itemhead_custom_hat_bucket: 'Mighty Bucket',
    customization_itemhead_custom_hat011_freedom: "Sam's Hat",
    customization_itemhead_custom_hat_laurels: 'Emperor Laurels',
    customization_itemhead_custom_hat_graduation: 'Graduation Cap',
    customization_itemhead_custom_hat_oktoberfest: 'Schlappe Cap',
    customization_itemhead_custom_hat_halloween: 'Sentient Hat',
    customization_itemhead_custom_hat_pumpkincat: "Cat-O'-Lantern",
    customization_itemhead_custom_hat_pumpkincute: "Cute-O'-Lantern",
    customization_itemhead_custom_hat_pumpkinevil: "Mean-O'-Lantern",
    customization_itemhead_custom_hat_pumpkinwink: "Wink-O'-Lantern",
    customization_itemhead_custom_hat_pumpkin: 'Bumpkin Pumpkin',
    customization_itemhead_custom_hat_yamibandana: 'Ninja Headband',
    customization_itemhead_custom_hat_king: 'Kingly Crown of Class',
    customization_itemhead_custom_hat_dogears: 'Dog Ears',
    customization_itemhead_custom_hat_catears: 'Cat Ears',
    customization_itemhead_custom_hat_christmas: 'Santa Hat',
    customization_itemhead_custom_hat_christmaself: 'Elf Hat',
    customization_itemhead_custom_hat_snowman: 'Snowman Head',
    customization_itemhead_custom_hat_soldier: 'Nutcracker Head',
    customization_itemhead_custom_hat_lunar: 'Lunar Hat',
    customization_itemhead_custom_hat_valentine: 'Lovely Beret',
    customization_itemhead_custom_hat_stpatrick: 'Leprechaun Top Hat',
    customization_itemhead_custom_hat_stpatrickberet: "St. Patrick's Hat ",
    customization_itemhead_custom_hat_flowercrown: 'Flower Crown',
    customization_itemhead_custom_hat_tanner: 'Tamas Beanie',
    customization_itemhead_custom_hat_bunnycap: 'Bunny Cap',
    customization_itemhead_custom_hat_rudobearhat: 'Elder Bear Coiffe',
    customization_itemhead_custom_hat_mariachi: 'Mariachi Hat',
    customization_itemhead_custom_hat_poloniafeatherhat:
        'Hat of the Nine Seas',
    customization_itemhead_custom_hat_freedomcoiffe: 'Coiffe of Liberty',
    customization_itemhead_custom_hat_sylvancoiffe: 'Sylvan Headdress',
    customization_itemhead_custom_hat_sultanhat: 'Sultan Turban',
    customization_itemhead_custom_hat_fallhat: 'Autumn Canopy Crown',
    customization_itemhead_custom_hat_oktoberhat: 'Alpine Hat',
    customization_itemhead_custom_hat_batwings: 'Bat Wings',
    customization_itemhead_custom_hat_thanksgivinghat:
        'Thanksgiving Coiffe',
    customization_itemhead_custom_hat_siacirclet: "Sia's Circlet",
    customization_itemhead_custom_hat_sombrero: 'Sombrero',
    customization_itemhead_custom_hat_victorian: 'Dapper Top Hat',
    customization_itemhead_custom_hat_tailorhat: "Julia's Summer Hat",
    customization_itemweapon_custom_hat_nutcracker: 'Toy Soldier Hat',
    customization_itemhead_custom_hat_christmashat: "Santa's Helper Coiffe",
    customization_itemhead_custom_hat_reindeer: 'Reindeer Antlers',
    customization_itemhead_custom_hat_dragonmask: 'Dragon Mask',
    customization_itemhead_custom_hat_bullhorn: 'Ox Horns',
    customization_itemhead_custom_hat_mundracrown:
        "Mundra's Moonstone Crown",
    customization_itemhead_custom_hat_bunnyonesie: 'Bunny Onesie Hood',
    customization_itemhead_custom_hat_flowerhat: 'Blooming Coiffe',
    customization_itemhead_custom_hat_partyhat: 'Clown Hat',
    customization_itemhead_custom_hat_beehood: 'Buzzy Bee Hoodie',
    customization_itemhead_custom_hat_grotto: 'Yeti Hat & Ears',
    customization_itemhead_custom_hat_catonesie: 'Kitty Onesie Hood',
    customization_itemhead_custom_hat_dogonesie: 'Doggy Onesie Hood',
    customization_itemhead_custom_hat_candycrown: 'Candy Crown',
    customization_itemhead_custom_hat_spiderhat: 'Spidey Buddy',
    customization_itemhead_custom_hat_atlab_toph: "Toph's Headband",
    customization_itemhead_custom_hat_atlab_aang: "Aang's Arrow",
    customization_itemhead_custom_hat_atlab_katarahair: "Katara's Hair",
    customization_itemhead_custom_hat_atlab_zukohair: "Zuko's Hair",
    customization_itemhead_custom_hat_snowhat: 'Hoser Hat',
    customization_itemhead_custom_hat_deeronesie: 'Deer Onesie Hood',
    customization_itemhead_custom_hat_swamp: 'Toadwitch Hat',
    customization_itemhead_custom_hat_tigerears: 'Tiger Ears',
    customization_itemhead_custom_hat_tiger: 'Tiger Hoodie',
    no_tunic: 'No tunic',
    shopkeeper_tunic: 'Merchant Tunic',
    customization_itembody_custom_top_apronrobe: 'Dress & Apron',
    customization_itembody_custom_top_mariner: 'Mariner Shirt',
    customization_itembody_custom_top_victorian: 'Dapper Waistcoat',
    customization_itembody_custom_top_farmer: 'Farmer Outfit',
    customization_itembody_custom_top_strawsuit: 'Straw Shirt',
    customization_itembody_custom_top_king: "Reinhold's Ravishing Raiments",
    customization_itembody_custom_top_santa: "Santa's Suit",
    customization_itembody_custom_top_christmasskirt: "Mrs. Claus' Suit",
    customization_itembody_custom_top_christmaself: 'Ugly Sweater',
    customization_itembody_custom_top_lunar: 'Lunar Shirt',
    customization_itembody_custom_top_stpatrickoutfit:
        "St. Patrick's Outfit",
    customization_itembody_custom_top_stpatrick: 'Leprechaun Coat',
    customization_itembody_custom_top_stpatrickskirt: "St. Patrick's Dress",
    customization_itembody_custom_top_easterchickvest: 'Chick Vest',
    customization_itembody_custom_top_shoptitans: 'Shop Titans Shirt',
    customization_itembody_custom_top_toga: 'Imperial Toga',
    customization_itembody_custom_top_schooltop1: 'Academy Vest',
    customization_itembody_custom_top_schooltop2: 'Academy Uniform',
    customization_itembody_custom_top_schooltop3: 'Academy Jacket',
    customization_itembody_custom_top_fallcoat: 'Seasonal Coat',
    customization_itembody_custom_top_falljacket: 'Coat of Fallen Leaves',
    customization_itembody_custom_top_oktobersuit: 'Lederhosen',
    customization_itembody_custom_top_priestcloak: "Freyja's Shawl",
    customization_itembody_custom_top_batcoat: 'Moonless Night Coat',
    customization_itembody_custom_top_mummyoutfit: "Pharaoh's Curse",
    customization_itembody_custom_top_thanksgivingshirt: 'Shirt of Plenty',
    customization_itembody_custom_top_wintercoat: 'Warm Parka',
    customization_itembody_custom_top_nutcracker: 'Toy Soldier Uniform',
    customization_itembody_custom_top_mariachi: 'Mariachi Vest',
    customization_itembody_custom_top_summerfloater: 'Sunny Floater',
    customization_itembody_custom_top_christmasdress: 'Treedress',
    customization_itembody_custom_top_mayaoutfit: 'Fortune Zone Uniform',
    customization_itembody_custom_top_indiadayshirt: 'Lehenga Choli',
    customization_itembody_custom_top_indiafrock: 'Sherwani',
    customization_itembody_custom_top_coupletunic: 'Wild Tunic',
    customization_itembody_custom_top_kilt: 'Milesian Vestment',
    customization_itembody_custom_top_bunnyonesie: 'Bunny Onesie',
    customization_itembody_custom_top_springdress: 'Elegant Spring Dress',
    customization_itembody_custom_top_cincodress: 'China Poblana Dress',
    customization_itembody_custom_top_birdcoat: 'Ruffled Feathercoat',
    customization_itembody_custom_top_beeshirt: 'Buzzy Bee Body',
    customization_itembody_custom_top_troblinrags: 'Troublin Rags',
    customization_itembody_custom_top_freedomsuit: 'Presidential Outfit',
    customization_itembody_custom_top_hockeyjersey: 'Shop Titans Jersey',
    customization_itembody_custom_top_furcoat: 'Fur Coat',
    customization_itembody_custom_top_grotto: 'Yeti Furry Pajama',
    customization_itembody_custom_top_catonesie: 'Kitty Onesie',
    customization_itembody_custom_top_dogonesie: 'Dog Onesie',
    customization_itembody_custom_top_candydress: 'Delicious Dress',
    customization_itembody_custom_top_moonkimono: 'Festival Kimono',
    customization_itembody_custom_top_atlab_katara: "Katara's Outfit",
    customization_itembody_custom_top_atlab_zuko: "Zuko's Armor",
    customization_itembody_custom_top_atlab_toph: "Toph's Outfit",
    customization_itembody_custom_top_atlab_aang: "Aang's Outfit",
    customization_itembody_custom_top_deeronesie: 'Deer Onesie',
    customization_itembody_custom_top_newyeardress: 'Flapper Dress',
    customization_itembody_custom_top_lunardress: 'Lunar New Year Outfit',
    customization_itembody_custom_top_valentineoutfit: 'Soulmate Tuxedo',
    no_shoes: 'Barefeet',
    customization_itemfeet_custom_boots_mariner: 'Mariner Boots',
    customization_itemfeet_custom_boots_victorian: 'Dapper Boots',
    customization_itemfeet_custom_boots_king: 'Sumptuous Shoes of Style',
    customization_itemfeet_custom_boots_christmas: "Santa's Shoes",
    customization_itemfeet_custom_boots_christmaself: 'Elf Shoes',
    customization_itemfeet_custom_boots_lunar: 'Lunar Shoes',
    customization_itemfeet_custom_boots_stpatrick: "St. Patrick's Boots",
    customization_itemfeet_custom_boots_leprechaun: 'Leprechaun Shoes',
    customization_itemfeet_custom_boots_maryjane: "St. Patrick's Shoes",
    customization_itemfeet_custom_boots_romansandal: 'Imperial Sandals',
    customization_itemfeet_custom_boots_schoolboot: 'Academy Footwear',
    customization_itemfeet_custom_boots_mariachi: 'Mariachi Boots',
    customization_itemfeet_custom_boots_nutcracker: 'Toy Soldier Boots',
    customization_itemfeet_custom_boots_coupleboots: 'Kodiak Steps',
    customization_itemfeet_custom_boots_natureshoes: 'Salad Slippers',
    customization_itemfeet_custom_boots_cincosandals: 'Huaraches de Mayo',
    customization_itemfeet_custom_boots_freedomboots: 'Patriotic Boots',
    customization_itemfeet_custom_boots_moonshoes: 'Geta',
    customization_itemfeet_custom_boots_valentineshoes: 'Cherub Sneakers',
    no_gloves: 'Gloveless',
    customization_itemhands_custom_gloves_basic: 'Merchant Gloves',
    customization_itemhands_custom_gloves_compass: 'Mariner Compass',
    customization_itemhands_custom_gloves_victorian: 'Dapper Gloves',
    customization_itemhands_custom_gloves_king: "King's Ring of Bling",
    customization_itemhands_custom_gloves_christmas: "Santa's Mittens",
    no_pants: 'Indecent',
    customization_itempants_custom_pants_victorian: 'Dapper Pants',
    customization_itempants_custom_pants_christmaself: 'Elf Pants',
    customization_itempants_custom_pants_lunar: 'Lunar Pants',
    customization_itempants_custom_pants_stpatrickshorts:
        'Leprechaun Shorts',
    customization_itempants_custom_pants_stpatrickleggings:
        'Leprechaun Tights',
    customization_itempants_custom_pants_schoolpants1: 'Academy pants',
    customization_itempants_custom_pants_mariachi: 'Mariachi Pants',
    customization_itempants_custom_pants_schoolpants2: 'Academy skirts',
    customization_itempants_custom_pants_falljeans: 'Seasonal Jeans',
    customization_itempants_custom_pants_nutcracker: 'Toy Soldier Pants',
    no_face: 'Nothing',
    customization_itemface_custom_eyewear_eyepatch: 'Pirate Eyepatch',
    customization_itemface_custom_eyewear_monocles: 'Dapper Monocle',
    customization_itemface_custom_eyewear_pixelshades: 'Deal With It',
    customization_itemface_custom_eyewear_dognose: 'Dog Nose',
    customization_itemface_custom_eyewear_catnose: 'Cat Nose',
    customization_itemface_custom_eyewear_valentine: 'Lovely Glasses',
    customization_itemface_custom_eyewear_stpatrickglasses: 'Clover Look',
    customization_itemface_custom_eyewear_aviatorglasses: 'Aviator Glasses',
    customization_itemface_custom_eyewear_wintergoggles: 'Winter Goggles',
    customization_itemface_custom_eyewear_birdmask: 'Owl Mask',
    customization_itemface_custom_eyewear_clown: 'Squeaky Nose',
    customization_itemface_custom_eyewear_batglasses: 'Rad Bat Shades',
    no_weapon: 'Empty-Handed',
    customization_itemweapon_custom_handheld_broom: 'Swabbie Broom',
    customization_itemweapon_custom_handheld_cane: 'Gentleman Cane',
    customization_itemweapon_custom_handheld_pixelsword: 'Retro Blade',
    customization_itemweapon_custom_handheld_argonsword: 'Knight Sword',
    customization_itemweapon_custom_handheld_king:
        'Prosperous Scepter of Splendor',
    customization_itemweapon_custom_handheld_candycane: 'Candy Cane',
    customization_itemweapon_custom_handheld_sparkler: 'New Year Sparkler',
    customization_itemweapon_custom_handheld_academywand: "Evelyn's Wand",
    customization_itemweapon_custom_handheld_flowerbouquet:
        'Spring Bouquet',
    customization_itemweapon_custom_handheld_freedomtorch:
        'Light of Liberty',
    customization_itemweapon_custom_handheld_backscratcher:
        "Allan's Backscratcher",
    customization_itemweapon_custom_handheld_magiclamp: 'Magic Lamp',
    customization_itemweapon_custom_handheld_schoolbag: 'Academy Backpack',
    customization_itemweapon_custom_handheld_magnifyingglass:
        'Magnificent Magnifier',
    customization_itemweapon_custom_handheld_lilustaff: "Lilu's Staff",
    customization_itemweapon_custom_handheld_dragonunity:
        "Sondra's Balance",
    customization_itemweapon_custom_handheld_viptoken: "Fortune's Coin",
    customization_itemweapon_custom_handheld_cincoguitar: 'Guitar',
    customization_itemweapon_custom_handheld_watergun: 'Pistol Mk. I',
    customization_itemweapon_custom_handheld_pocketwatch:
        'Dapper Pocketwatch',
    customization_itemweapon_custom_handheld_wallacehammer:
        "Wallace's Favorite Hammer",
    customization_itemweapon_custom_handheld_duster: 'Colorful Duster',
    customization_itemweapon_custom_handheld_horseshoe: 'Lucky Horseshoe',
    customization_itemweapon_custom_handheld_fan: 'Elegant Fan',
    customization_itemweapon_custom_handheld_troblinstick: 'Troublin Stick',
    customization_itemweapon_custom_handheld_atlab_staff:
        "Aang's Air Glider",
    customization_itemweapon_custom_handheld_snowball: 'Snowball',
    customization_itemweapon_custom_handheld_partypopper: 'Party Popper',
    customization_itemweapon_custom_handheld_swamp: 'Toadwitch Spoon',
    customization_expression_expr_neutral: 'Neutral',
    customization_expression_expr_happy: 'Merry',
    customization_expression_expr_angry: 'Fierce',
    customization_eyecolor_grey: 'Gray',
    customization_eyecolor_oxleygreen: 'Oxley Green',
    customization_eyecolor_brown: 'Brown',
    customization_eyecolor_darkbrown: 'Dark Brown',
    customization_eyecolor_bastille: 'Bastille',
    customization_eyecolor_poloblue: 'Polo Blue',
    customization_eyecolor_springgreen: 'Spring Green',
    customization_eyecolor_mariner: 'Mariner',
    customization_eyecolor_red: 'Red',
    customization_eyecolor_maroon: 'Maroon',
    customization_eyecolor_orange: 'Orange',
    customization_eyecolor_blazeorange: 'Blaze Orange',
    customization_eyecolor_yellow: 'Yellow',
    customization_eyecolor_lightgreen: 'Light Green',
    customization_eyecolor_malachite: 'Malachite',
    customization_eyecolor_malibu: 'Malibu',
    customization_eyecolor_teal: 'Teal',
    customization_eyecolor_violet: 'Violet',
    customization_eyecolor_purple: 'Purple',
    customization_eyecolor_pink: 'Pink',
    customization_eyecolor_blind: 'Unseeing',
    customization_haircolor_santabeard: 'Pure White',
    customization_haircolor_orange: 'Orange',
    customization_haircolor_darkbrown: 'Dark Brown',
    customization_haircolor_red: 'Red',
    customization_haircolor_blackgrey: 'Black Gray',
    customization_haircolor_darkgrey: 'Dark Gray',
    customization_haircolor_gold: 'Gold',
    customization_haircolor_brown: 'Brown',
    customization_haircolor_shakespeare: 'Shakespeare',
    customization_haircolor_blue: 'Blue',
    customization_haircolor_maroon: 'Maroon',
    customization_haircolor_white: 'White',
    customization_haircolor_lightgreen: 'Light Green',
    customization_haircolor_purple: 'Purple',
    customization_haircolor_violet: 'Violet',
    customization_haircolor_aquamarine: 'Aquamarine',
    customization_haircolor_teal: 'Teal',
    customization_haircolor_malachite: 'Malachite',
    customization_haircolor_hitpink: 'Hit Pink',
    customization_haircolor_rose: 'Rose',
    customization_haircolor_blazeorange: 'Blaze Orange',
    customization_skincolor_caucasian1: 'Light',
    customization_skincolor_caucasian2: 'Fair',
    customization_skincolor_caucasian3: 'Rosy',
    customization_skincolor_dark1: 'Tanned',
    customization_skincolor_dark2: 'Light Brown',
    customization_skincolor_dark3: 'Brown',
    customization_skincolor_dark4: 'Dark',
    customization_skincolor_asian1: 'Beige',
    customization_skincolor_asian2: 'Olive',
    customization_skincolor_asian3: 'Golden',
    customization_skincolor_elfskin: 'Elven',
    customization_skincolor_drow: 'Drow',
    customization_skincolor_orcskin: 'Orcish',
    customization_skincolor_undead: 'Revenant',
    customization_skincolor_redskin: 'Efreet',
    customization_skincolor_nightcrawler: 'Pixie',
    customization_skincolor_iridescent: 'Iridescend',
    customization_skincolor_pearl: 'Pearl',
    customization_skincolor_simpson: 'Springfield',
    customization_skincolor_genie: 'Genie',
    customization_skincolor_pinkrose: 'Pink',
    customization_skincolor_aquatic: 'Aquan',
    customization_skincolor_obsidian: 'Obsidian',
    customization_skincolor_dryad: 'Dryad',
    customization_shirtcolor_tan_shirtClr: 'Tan',
    customization_shirtcolor_darkgrey_shirtClr: 'Dark Gray',
    customization_shirtcolor_beige_shirtClr: 'Beige',
    customization_shirtcolor_blackgrey_shirtClr: 'Black Gray',
    customization_shirtcolor_red_shirtClr: 'Red',
    customization_shirtcolor_orange_shirtClr: 'Orange',
    customization_shirtcolor_deepgold_shirtClr: 'Deep Gold',
    customization_shirtcolor_yellow_shirtClr: 'Yellow',
    customization_shirtcolor_lime_shirtClr: 'Lime',
    customization_shirtcolor_darkgreen_shirtClr: 'Dark Green',
    customization_shirtcolor_huntergreen_shirtClr: 'Hunter Green',
    customization_shirtcolor_turquoise_shirtClr: 'Turquoise',
    customization_shirtcolor_blue_shirtClr: 'Blue',
    customization_shirtcolor_darkblue_shirtClr: 'Dark Blue',
    customization_shirtcolor_purple_shirtClr: 'Purple',
    customization_shirtcolor_violet_shirtClr: 'Violet',
    customization_shirtcolor_salmon_shirtClr: 'Salmon',
    customization_shirtcolor_pink_shirtClr: 'Pink',
    customization_shirtcolor_brown_shirtClr: 'Brown',
    customization_shirtcolor_darkbrown_shirtClr: 'Dark Brown',
    customization_shirtcolor_grey_shirtClr: 'Gray',
    customization_shirtcolor_hotpink_shirtClr: 'Hot Pink',
    customization_vestcolor_tan_vestClr: 'Tan',
    customization_vestcolor_darkgrey_vestClr: 'Dark Gray',
    customization_vestcolor_beige_vestClr: 'Beige',
    customization_vestcolor_blackgrey_vestClr: 'Black Gray',
    customization_vestcolor_red_vestClr: 'Red',
    customization_vestcolor_orange_vestClr: 'Orange',
    customization_vestcolor_deepgold_vestClr: 'Deep Gold',
    customization_vestcolor_yellow_vestClr: 'Yellow',
    customization_vestcolor_lime_vestClr: 'Lime',
    customization_vestcolor_darkgreen_vestClr: 'Dark Green',
    customization_vestcolor_huntergreen_vestClr: 'Hunter Green',
    customization_vestcolor_turquoise_vestClr: 'Turquoise',
    customization_vestcolor_blue_vestClr: 'Blue',
    customization_vestcolor_darkblue_vestClr: 'Dark Blue',
    customization_vestcolor_purple_vestClr: 'Purple',
    customization_vestcolor_violet_vestClr: 'Violet',
    customization_vestcolor_salmon_vestClr: 'Salmon',
    customization_vestcolor_pink_vestClr: 'Pink',
    customization_vestcolor_brown_vestClr: 'Brown',
    customization_vestcolor_darkbrown_vestClr: 'Dark Brown',
    customization_vestcolor_grey_vestClr: 'Gray',
    customization_vestcolor_hotpink_vestClr: 'Hot Pink',
    customization_hatcolor_tan_hatClr: 'Tan',
    customization_hatcolor_darkgrey_hatClr: 'Dark Gray',
    customization_hatcolor_beige_hatClr: 'Beige',
    customization_hatcolor_blackgrey_hatClr: 'Black Gray',
    customization_hatcolor_red_hatClr: 'Red',
    customization_hatcolor_orange_hatClr: 'Orange',
    customization_hatcolor_deepgold_hatClr: 'Deep Gold',
    customization_hatcolor_yellow_hatClr: 'Yellow',
    customization_hatcolor_lime_hatClr: 'Lime',
    customization_hatcolor_darkgreen_hatClr: 'Dark Green',
    customization_hatcolor_huntergreen_hatClr: 'Hunter Green',
    customization_hatcolor_turquoise_hatClr: 'Turquoise',
    customization_hatcolor_blue_hatClr: 'Blue',
    customization_hatcolor_darkblue_hatClr: 'Dark Blue',
    customization_hatcolor_purple_hatClr: 'Purple',
    customization_hatcolor_violet_hatClr: 'Violet',
    customization_hatcolor_salmon_hatClr: 'Salmon',
    customization_hatcolor_pink_hatClr: 'Pink',
    customization_hatcolor_brown_hatClr: 'Brown',
    customization_hatcolor_darkbrown_hatClr: 'Dark Brown',
    customization_hatcolor_grey_hatClr: 'Gray',
    customization_hatcolor_hotpink_hatClr: 'Hot Pink',
    customization_pantscolor_tan_pantsClr: 'Tan',
    customization_pantscolor_darkgrey_pantsClr: 'Dark Gray',
    customization_pantscolor_beige_pantsClr: 'Beige',
    customization_pantscolor_blackgrey_pantsClr: 'Black Gray',
    customization_pantscolor_red_pantsClr: 'Red',
    customization_pantscolor_orange_pantsClr: 'Orange',
    customization_pantscolor_deepgold_pantsClr: 'Deep Gold',
    customization_pantscolor_yellow_pantsClr: 'Yellow',
    customization_pantscolor_lime_pantsClr: 'Lime',
    customization_pantscolor_darkgreen_pantsClr: 'Dark Green',
    customization_pantscolor_huntergreen_pantsClr: 'Hunter Green',
    customization_pantscolor_turquoise_pantsClr: 'Turquoise',
    customization_pantscolor_blue_pantsClr: 'Blue',
    customization_pantscolor_darkblue_pantsClr: 'Dark Blue',
    customization_pantscolor_purple_pantsClr: 'Purple',
    customization_pantscolor_violet_pantsClr: 'Violet',
    customization_pantscolor_salmon_pantsClr: 'Salmon',
    customization_pantscolor_pink_pantsClr: 'Pink',
    customization_pantscolor_brown_pantsClr: 'Brown',
    customization_pantscolor_darkbrown_pantsClr: 'Dark Brown',
    customization_pantscolor_grey_pantsClr: 'Gray',
    customization_pantscolor_hotpink_pantsClr: 'Hot Pink',
    customization_glovescolor_tan_glovesClr: 'Tan',
    customization_glovescolor_darkgrey_glovesClr: 'Dark Gray',
    customization_glovescolor_beige_glovesClr: 'Beige',
    customization_glovescolor_blackgrey_glovesClr: 'Black Gray',
    customization_glovescolor_red_glovesClr: 'Red',
    customization_glovescolor_orange_glovesClr: 'Orange',
    customization_glovescolor_deepgold_glovesClr: 'Deep Gold',
    customization_glovescolor_yellow_glovesClr: 'Yellow',
    customization_glovescolor_lime_glovesClr: 'Lime',
    customization_glovescolor_darkgreen_glovesClr: 'Dark Green',
    customization_glovescolor_christmasgreen_glovesClr: 'Christmas Green',
    customization_glovescolor_huntergreen_glovesClr: 'Hunter Green',
    customization_glovescolor_turquoise_glovesClr: 'Turquoise',
    customization_glovescolor_blue_glovesClr: 'Blue',
    customization_glovescolor_darkblue_glovesClr: 'Dark Blue',
    customization_glovescolor_purple_glovesClr: 'Purple',
    customization_glovescolor_violet_glovesClr: 'Violet',
    customization_glovescolor_salmon_glovesClr: 'Salmon',
    customization_glovescolor_pink_glovesClr: 'Pink',
    customization_glovescolor_brown_glovesClr: 'Brown',
    customization_glovescolor_darkbrown_glovesClr: 'Dark Brown',
    customization_glovescolor_grey_glovesClr: 'Gray',
    customization_glovescolor_hotpink_glovesClr: 'Hot Pink',
    customization_itemhead_desc:
        'Shopkeeper hat once found in a seasonal pack!',
    customization_itembody_desc:
        'Shopkeeper clothing accessory part of a seasonal pack!',
    customization_itemfeet_desc:
        'Shopkeeper footgear once found in a seasonal pack!',
    customization_itempants_desc:
        'Shopkeeper legwear once found in a seasonal pack!',
    customization_itemweapon_desc:
        'Shopkeeper accessory once found in a seasonal pack!',
    customization_itemhands_desc:
        'Shopkeeper glove accessory once found in a seasonal pack!',
    customization_itemface_desc:
        'Shopkeeper face accessory once found in a seasonal pack!',
    pet_custom_desc: 'Pet accessory once found in a seasonal pack!',
    customization_itemhead_custom_hat_wiccan: 'Wiccan Crown',
    customization_itembody_custom_top_wiccan: 'Wiccan Garb',
    customization_itembody_custom_top_aegir: 'Jarl Outfit',
    customization_itemweapon_custom_handheld_horn: 'Drinking Horn',
    share_shop_title: 'Share Your Shop!',
    share_shop_text:
        'Invite other players through social media to view your shop!',
    view_shop_title: 'Come check out my Shop Titans shop!',
    view_shop_description: "Open this link to view {0}'s shop!",
    view_shop_in_tutorial:
        "You must finish the tutorial before viewing this player's shop.",
    share_profile_title: 'Share Profile',
    share_profile_text:
        'Share this profile over social media for others to view!',
    view_profile_title: 'Come see my profile!',
    view_profile_description: "Open this link to view {0}'s profile!",
    view_profile_in_tutorial:
        "You can't view this player's profile via this link until you've finished the tutorial.",
    share_city_title: 'Share Guild',
    share_city_text:
        'Invite others to join your guild through social media!',
    view_city_title: 'Join my Shop Titans guild!',
    view_city_description: 'Open this link to join the {0} guild!',
    view_city_confirmation: 'Are you sure you want to join this guild?',
    share_button_options: 'Share',
    share_popup_share: 'Share',
    share_popup_title: 'Share Options',
    share_popup_location_name_shop: 'Shop',
    share_popup_location_description_shop:
        'Invite other players through social media to view your shop!',
    share_popup_location_name_profile: 'Profile',
    share_popup_location_description_profile:
        'Share this profile over social media for others to view!',
    share_popup_location_name_city: 'Guild',
    share_popup_location_description_city:
        'Invite others to join your guild through social media!',
    nav_route_home: 'Home',
    nav_route_news: 'News',
    nav_route_browse: 'Browse',
    nav_route_blueprints: 'Blueprints',
    nav_route_blueprints_desc:
        'An interactive list of all blueprints available in the game.',
    nav_route_customizations: 'Customizations',
    nav_route_customizations_desc:
        'A list of seasonal, package and other avatar customizations available in the game.',
    nav_route_media: 'Media',
    nav_route_gallery: 'Gallery',
    nav_route_store: 'Store',
    nav_route_support: 'Support',
    nav_route_offers: 'Offers',
    nav_route_offers_desc:
        'A list of all limited time seasonal packages in the game.',
    steam_banner_title: 'Shop Titans is coming to Steam!',
    steam_banner_desc:
        'Coming soon to PC on Steam; add Shop Titans to your wishlist and check in-game for early beta sign-ups!',
    copyright_text:
        'Copyright © {0} Kabam Games, Inc. All Rights Reserved.',
    privacy_notice: 'Privacy Notice',
    level: 'Level',
    level_plural: 'Levels',
    fetching_data: 'Fetching data...',
    fetch_data_error:
        'There was an error fetching the data. Please check your connection.',
    app_download_text: 'Available now on the App Store and Google Play!',
    app_download_text_china: 'Available to download now!',
    home_description_app:
        'Design and build a thriving shop in this tycoon simulation adventure! Outfit your heroes and champions with epic gear, and send them on quests to defeat powerful bosses!',
    home_title_app: 'Become A Master Shopkeeper',
    home_app_description_01: 'Create and customize your own fantasy shop.',
    home_app_description_02:
        'Craft and master over 150 different items to solidfy yourself as an expert craftsman.',
    home_app_description_03:
        'Come together and form a guild with your friends and build a prosperous city.',
    home_app_description_04: 'Trade with others from around the world.',
    home_app_description_05:
        'Recruit and customize your heroes, each with their own gear and skills.',
    home_app_description_06:
        'Battle awesome bosses in unique dungeons and earn yourself the rarest loot!',
    home_description_slider:
        'Shop Titans is a simulation RPG game that consists of building your own shop within a fantasy kingdom. In it, you can craft and sell armor, swords, potions and all kinds of gear and equipment to heroes to help them in their adventures. With the money from the sales you can expand and customize your shop to become a business mogul in the village. Heroes of all kinds can enter your store: warriors, wizards, dwarves... even ninjas! Help your heroes and champions in legendary battles against the most powerful bosses!',
    home_title_news: 'Recent News',
    home_package_desc:
        "Grab these exclusive package items while they're available in-game!",
    home_title_social: 'Connect with us on Social Media!',
    home_title_fankit:
        'Download the official Fan Kit for your Shop Titans projects!',
    home_fankit_download: 'Download Fan Kit',
    browse_header_title: 'Browsing {0}',
    browse_title: 'Browse',
    browse_desc:
        'Browse through a selection of content found in Shop Titans.',
    settings_title: 'Customize Progression',
    settings_save: 'Save Changes',
    settings_clear: 'Reset Changes',
    settings_workers_description:
        'Designate the levels of your workers. This will display more accurate crafting times for items.',
    settings_items_description:
        'Apply general modifiers for craft time, item value and more.',
    settings_localstorage_notice:
        'These changes are saved locally to this browser only.',
    settings_tab_workers: 'Worker Levels',
    settings_tab_items: 'Item Modifiers',
    merchant_xp: 'Merchant XP',
    worker_xp: 'Worker XP',
    favor: 'Favor',
    stat_locale_title_package: 'Package',
    stat_locale_title_event: 'Event',
    stat_element_detail_none: 'None',
    stat_header_item_stats: 'Item Stats',
    stat_header_extra_details: 'Extra Details',
    stat_header_energy_costs: 'Energy Costs',
    gallery_title: 'Art Gallery',
    gallery_desc:
        'Browse through concept art for Shop Titans and read up on some of their inspirations provided by our artists.',
    update_notice_title: 'Update Available',
    update_notice_desc:
        'A newer version of this web application is available, please refresh the page or apply the update below.',
    update_notice_apply: 'Apply Update',
    anniversary_banner_title: 'Shop Titans - One Year Anniversary',
    anniversary_banner_desc:
        "We're celebrating one year of Shop Titans with a whole month of goodies! Click here for more info.",
    anniversary_header_title: 'Shop Titans 1-Year Anniversary!',
    anniversary_header_desc:
        'We wanted to say thank you to every single Shopkeeper. You’ve made Shop Titans something special, and that calls for celebration… Some goodies will be dropped over the entire Anniversary Month!',
    anniversary_gleam_title:
        'We’re giving away 20 Shop Titans Tees and Pet-Packs. Make sure to check the calendar below — there will be extra entries to be earned!',
    anniversary_calendar_title: 'June 2020 - Events Calendar',
    anniversary_calendar_legend_button: 'View Legend',
    anniversary_calendar_day_mon: 'Mon',
    anniversary_calendar_day_tue: 'Tue',
    anniversary_calendar_day_wed: 'Wed',
    anniversary_calendar_day_thu: 'Thu',
    anniversary_calendar_day_fri: 'Fri',
    anniversary_calendar_day_sat: 'Sat',
    anniversary_calendar_day_sun: 'Sun',
    anniversary_calendar_box_month_start: 'Anniversary Month Begins!',
    anniversary_calendar_box_lcog: 'Lost City of Gold (5pm PT / 8pm ET)',
    anniversary_calendar_box_award_vote: 'Award Voting Ends!',
    anniversary_calendar_box_stats: 'Stats Results!',
    anniversary_calendar_data_error:
        'There was an error fetching the calendar data.',
    anniversary_legend_title: 'Legend / Key',
    anniversary_legend_gift: 'In-Game Gift',
    anniversary_legend_stats: 'Stats Guessing',
    anniversary_legend_trivia: 'Trivia (Extra Entries)',
    anniversary_legend_creative_contest: 'Creative Contest',
    anniversary_legend_award_vote: 'Award Voting',
    anniversary_legend_award_winner: 'Award Winner',
    anniversary_legend_pets_teaser: 'Pets Teaser',
    anniversary_legend_pets_reveal: 'Pets Reveal',
    footer_header_games: 'Our Games',
    footer_header_legal: 'Help & Legal',
    footer_header_resources: 'Resources',
    footer_link_more_games: 'More Games...',
    footer_link_support: 'Contact Support',
    footer_link_fankit: 'Game Assets Fan Kit',
    footer_link_guidelines: 'Community Guidelines',
    footer_link_faq_long: 'Frequently Asked Questions',
    results_none: 'No Results',
    offers_date_start_vip:
        'Available for Royal Merchant from {0} until {1}',
    offers_date_start: 'Available for All Players from {0} until {1}',
    offers_search_title: 'Search for Offers',
    offers_search_desc_1:
        'You can search for multiple offers at once by separating each UID with a comma.',
    offers_search_desc_2:
        'You can search for a family of offers by matching their UID partially. For example, "offermay2021" will show all May 2021 offers.',
    offers_search_query: 'Offer UID',
    offers_search_button: 'Search',
    offers_warning_banner_desc:
        'This page is not optimized for production use; you may experience degraded performance. Do not share it with a public audience, it is for internal reference use only.',
    offers_search_none: 'No Offers Found.',
    offers_search_none_button: 'Show All Offers',
    offers_details_button: 'View Package Contents',
    offers_details_cost: 'Package Cost',
    footer_link_faq: 'FAQ',
    footer_link_spreadsheet: 'Shop Titans Data Spreadsheet',
    footer_download_play: 'Download & Play Now!',
    home_call_to_action_trailer: 'Watch Trailer',
    home_call_to_action_download: 'Download Now!',
    home_call_to_action_download_alt_1: 'Download & Play',
    home_call_to_action_download_alt_2: 'Download for Free',
    home_call_to_action_download_alt_3: 'Free Download',
    news_title_latest: 'Latest News',
    news_title_recent: 'Recent News',
    news_title_related: 'Related News',
    news_title_featured: 'Featured News',
    news_button_view_all: 'View All',
    news_button_view_all_long: 'View All News',
    news_button_view_article: 'View Article',
    news_button_show_more: 'Show More',
    news_category_all: 'All',
    news_category_updates_title: 'Updates',
    news_category_updates_desc:
        'Articles regarding updates made to Shop Titans.',
    news_category_guides_title: 'Guides',
    news_category_guides_desc:
        'Guides pertaining to Shop Titans or relevant resources.',
    news_category_community_title: 'Community',
    news_category_community_desc:
        'Articles regarding the Shop Titans community, events, projects or resources.',
    news_article_metadata: 'Posted to {0} on {1}',
    nav_kabam_othergames_title: 'Other Kabam Games',
    nav_kabam_button: 'View Kabam Games',
    blueprint_browser_title: 'Blueprint Browser',
    blueprint_browser_desc:
        'Get information on every available blueprint in the game including full statistics, package and acquisition sources, upgrades and more, all without having to open the game!',
    blueprint_browser_button: 'View All Blueprints',
    customization_browser_title: 'Customization Browser',
    customization_browser_desc:
        'Get information on every shopkeeper customizations including their package and acquisition sources, costs and more, all without having to open the game!',
    customization_browser_button: 'View All Customizations',
    content_language_limited:
        'This content is currently not available in this language.',
    content_language_limited_english:
        'This content is currently only available in English.',
    content_language_limited_partial:
        'This content is only partially supported in your selected language.',
    cookie_data_notice:
        'We use cookies to optimize our site, for analytics and for marketing. For more information on how we utilize cookies, please read our Privacy Notice. By continuing to browse our website, you agree to our use of cookies.',
    cookie_data_notice_affirm: 'I Understand',
    content_error_title: 'An Error Occurred',
    content_error_desc:
        'There was an issue loading the requested content. Please check your connection and try again.',
    content_not_found_title: 'Content Not Found',
    content_not_found_desc:
        'The requested content does not exist or could not be found.',
    content_not_found_button: 'Go to Homepage',
    nav_route_events: 'Events',
    nav_route_achievements: 'Achievements',
    home_featured_banner_description:
        'The ultimate RPG shopkeeper simulation.',
    home_featured_content_app_title: 'Shop Titans: Design & Trade',
    home_featured_content_app_description:
        'Shop Titans is the ultimate RPG shopkeeper simulation. Craft powerful equipment, stock your shop and sell to aspiring heroes. Download the game for free today on PC or Mobile.',
    home_champion_select_title: 'Meet the Champions',
    footer_download_button_apple: 'Download on the App Store',
    footer_download_button_google: 'Get it on Google Play',
    footer_download_button_steam: 'Download on Steam',
    footer_button_kabam_logo: "Visit Kabam's Official Website",
    general_button_back: 'Go Back',
    general_button_close: 'Close',
    blueprints_button_view_item_details: 'View Blueprint Details',
    stat_header_premium_sources: 'Premium Sources',
    stat_header_blueprint_stats: 'Blueprint Stats',
    stat_header_blueprint_economy: 'Blueprint Economy',
    contentpass_rewards_orginal: 'Show Original Reward',
    contentpass_rewards_alt: 'Show Alternative Reward',
    bpass_popup_progress_title: 'Content Pass',
    bpass_popup_progress_desc: 'Complete tasks to claim rewards!',
    bpass_currency_tooltip:
        'Your accumulated progress for the current Content Pass. Collect {0} by completing tasks and claim even more rewards!',
    bpass_main_menu_title: 'Content Pass',
    bpass_main_menu_desc:
        'Complete these tasks to earn {0} and unlock rewards!',
    bpass_rush_offer_desc: 'To claim this reward, you need:',
    bpass_rush_offer_gems_title: 'Unlock now',
    bpass_info_popup_title: 'Content Pass',
    bpass_info_popup_atasks_title: 'Complete Tasks',
    bpass_info_popup_atasks_desc:
        'Complete tasks from the Content Pass to collect {0}.',
    bpass_info_popup_rewards_title: 'Get Rewards',
    bpass_info_popup_rewards_desc:
        'The more {0} you have, the more rewards you can claim.',
    bpass_info_popup_bpo_title: 'Full Access',
    bpass_info_popup_bpo_desc:
        'Buy the Full Access to claim all rewards from the list, if you have enough {0}!',
    bpass_view_battle_pass_button: 'View Full Access',
    bpass_last_chance_rewards_BPO:
        "Now's your last chance trade in gems for more {0}!",
    bpass_last_chance_rewards_no_BPO:
        "Now's your last chance to purchase the Full Access and claim more rewards!",
    bpass_last_chance_purchased: 'Full Access purchased!',
    bpass_offer_button_active: 'Content Pass',
    bpass_task_login_desc: 'Daily login',
    bpass_task_buy_market_desc: 'Buy items on market',
    bpass_task_sell_market_desc: 'Sell items on market',
    bpass_task_quests_desc: 'Complete quests',
    bpass_task_quests_with_desc: 'Complete quests with {0}',
    bpass_task_quests_specific_desc: 'Complete {0} quest',
    bpass_task_champion_request_desc: 'Fulfill Champion requests',
    bpass_task_champion_request_specific_desc: "Fulfill {0}'s requests",
    bpass_task_guild_request_desc: 'Fulfill guild requests',
    bpass_task_gather_components_desc: 'Gather components from quests',
    bpass_task_gather_components_specific_desc: 'Gather {0}',
    bpass_task_craft_item_desc: 'Craft items',
    bpass_task_craft_item_specific_desc: 'Craft {0}',
    bpass_task_craft_item_quality_desc: 'Craft {0} items',
    bpass_task_craft_item_quality_specific_desc: 'Craft {0} {1}',
    bpass_task_craft_item_element_desc: 'Craft elements',
    bpass_task_craft_item_element_specific_desc: 'Craft {0} elements',
    bpass_task_craft_item_spirit_desc: 'Craft spirits',
    bpass_task_craft_item_spirit_specific_desc: 'Craft {0} spirits',
    bpass_task_defeat_bosses_desc: 'Defeat bosses',
    bpass_task_defeat_bosses_with_desc: 'Defeat bosses with {0}',
    bpass_task_defeat_bosses_specific_desc: 'Defeat {0}',
    bpass_task_enchant_desc: 'Enchant items',
    bpass_task_enchant_element_desc: 'Enchant items with {0}',
    bpass_task_enchant_spirit_desc: 'Enchant items with {0}',
    bpass_task_fusions_desc: 'Perform fusions',
    bpass_task_earn_gold_desc: 'Earn gold from sales',
    bpass_task_king_sell_desc: 'Sell to the king',
    bpass_task_earn_gold_specific_desc: 'Earn gold from {0} sales',
    bpass_task_small_talk_desc: 'Perform successful small talk',
    bpass_task_surcharge_desc: 'Surcharge customers',
    bpass_task_discount_desc: 'Discount customers',
    bpass_task_buy_pack_desc: 'Buy any part of a Mega Pack',
    bpass_task_open_chest_desc: 'Open chests',
    bpass_task_open_chest_specific_desc: 'Open {0} chest',
    bpass_task_open_chest_gem_desc: 'Open chests with gems',
    bpass_task_open_chest_gem_specific_desc: 'Open {0} chest with gems',
    bpass_task_bounty_trophies_desc: 'Obtain bounty trophies',
    bpass_task_resource_desc: 'Spend {0} by crafting',
    bpass_task_vip_desc: 'For Royal Merchants',
    bpass_task_itemsell_desc: 'Sell items',
    bpass_task_itemsell_specific_desc: 'Sell {0}',
    bpass_task_itemsell_quality_desc: 'Sell {0} items',
    bpass_task_itemsell_quality_specific_desc: 'Sell {0} {1}',
    bpass_task_itemsell_element_desc: 'Sell elements',
    bpass_task_itemsell_element_specific_desc: 'Sell {0} elements',
    bpass_task_itemsell_spirit_desc: 'Sell spirits',
    bpass_task_itemsell_spirit_specific_desc: 'Sell {0} spirits',
    bpass_task_pet_food_desc: 'Feed your pets',
    bpass_task_pet_food_superior_desc:
        'Feed your pets with superior or premium food',
    bpass_task_pet_food_premium_desc: 'Feed your pets with premium food',
    bpass_access_rewards: 'Access to all rewards',
    bpass_event_atlab2021_name: 'Avatar: The Last Airbender',
    bpass_offer_atlab2021_title: 'Avatar: The Last Airbender',
    bpass_offer_atlab2021_desc:
        'Claim even more <color=#FFDC00>Avatar: The Last Airbender</color> rewards with Full Access!',
    bpass_offer_atlab2021_timer: 'Ends in: {0}',
    bpass_offer_atlab2021_view_tasks: 'View all tasks',
    bpass_atlab2021_currency: 'Copper Coins',
    bpass_task_atlab2021_login_title: 'Water. Earth. Fire. Air.',
    bpass_task_atlab2021_craftany_title: "Piandao's Guidance",
    bpass_task_atlab2021_craftherbs_title: 'Appa Snacks',
    bpass_task_atlab2021_craftspiritss_title: 'Spirit World Travels',
    bpass_task_atlab2021_spendiron_title: "Toph's Metalbending",
    bpass_task_atlab2021_fusion_title: 'Chi Harmony',
    bpass_task_atlab2021_questany_title: 'Adventure!',
    bpass_task_atlab2021_questaang_title: 'Maintain Avatar State',
    bpass_task_atlab2021_defeatboss_title: 'Boomerang Training',
    bpass_task_atlab2021_anycomponents_title: "Sokka's Foraging",
    bpass_task_atlab2021_fire_title: "Zuko's Teachings",
    bpass_task_atlab2021_smalltalk_title: 'Make Friends!',
    bpass_task_atlab2021_sellspears_title: 'Fire Nation Armory',
    bpass_task_atlab2021_itemsell_title: 'Cabbage Wholesaler',
    bpass_task_atlab2021_marketsell_title: 'High-Risk Trading',
    bpass_task_atlab2021_guildrequest_title: "Iroh's Wisdom",
    bpass_task_atlab2021_championrequest_title: 'Shopping Episode',
    bpass_task_atlab2021_aangrequest_title: 'Team Avatar',
    bpass_task_atlab2021_bountytrophies_title: 'Friendly Competition',
    bpass_task_atlab2021_chest_title: "Wan Shi Tong's Treasure",
    bpass_task_atlab2021_craftmoonstones_title: "Yue's Gift",
    bpass_task_atlab2021_buypack_title: 'Mega Pack!',
    bpass_task_atlab2021_vip_title: 'Royal Benefits',
    bpass_event_lny2022_name: 'Year of the Tiger',
    bpass_offer_lny2022_title: 'Year of the Tiger',
    bpass_offer_lny2022_desc:
        'Claim even more <color=#FFDC00>Lunar New Year</color> rewards with Full Access!',
    bpass_offer_lny2022_timer: 'Ends in: {0}',
    bpass_offer_lny2022_view_tasks: 'View all tasks',
    bpass_lny2022_currency: 'Mandarins',
    bpass_lny2022_pushnotestarted_title: '❗ Lunar New Year Event!',
    bpass_lny2022_pushnotestarted_desc:
        'The Lunar New Year Content Pass has started! Come unlock exclusive rewards!',
    bpass_lny2022_pushnoteending_title:
        '⏰ Lunar New Year Event ending soon!',
    bpass_lny2022_pushnoteending_desc:
        'Only a few days left for the Lunar New Year Content Pass!',
    bpass_task_lny2022_login_title: 'Celebrations!',
    bpass_task_lny2022_petfood_title: "Tiger's Feast",
    bpass_task_lny2022_craftany_title: 'To Prosperity',
    bpass_task_lny2022_craftpotions_title: 'And Good Health',
    bpass_task_lny2022_sellhelm_title: 'Lunar Flash Sale',
    bpass_task_lny2022_spendfabric_title: 'The Silk Road',
    bpass_task_lny2022_superiorcraft_title: 'Superior Artisan',
    bpass_task_lny2022_legendarycraft_title: 'Supreme Craftsmanship',
    bpass_task_lny2022_bountytrophies_title: "Guild's Due",
    bpass_task_lny2022_kingsell_title: 'Generous King',
    bpass_task_lny2022_fusion_title: 'Reunion',
    bpass_task_lny2022_earngold_title: 'The Red Envelope',
    bpass_task_lny2022_surcharge_title: 'Gold And Jade',
    bpass_task_lny2022_marketsell_title: 'Town Fair Vendor',
    bpass_task_lny2022_marketbuy_title: 'Town Fair Visitor',
    bpass_task_lny2022_rudorequest_title: 'Crouching Tiger',
    bpass_task_lny2022_questyami_title: 'Hidden Dragon',
    bpass_task_lny2022_defeatanyboss_title: 'Evil Spirit Banishment',
    bpass_task_lny2022_questany_title: "Tiger's Hunt",
    bpass_task_lny2022_anycomponents_title: 'Lost Treasures',
    bpass_task_lny2022_gemchest_title: 'Wondrous Gifts',
    bpass_task_lny2022_buypack_title: 'Mega Pack!',
    bpass_task_lny2022_vip_title: 'Royal Benefits',
};
