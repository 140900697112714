import React from 'react';
import { Checkbox, TextField, styled } from '@mui/material';

export const NumberWithToggle = ({
    on,
    value,
    onChange,
    label = 'Value',
    disableMargin = false,
}) => {
    const handleCheckboxChange = event => {
        const { checked } = event.target;
        onChange({ on: checked, value });
    };

    const handleValueChange = event => {
        const value = Number.parseInt(event.target.value, 10);
        onChange({ on, value });
    };

    return (
        <Root disableMargin={disableMargin}>
            <Checkbox checked={on} onChange={handleCheckboxChange} />

            <TextField
                value={value}
                onChange={handleValueChange}
                type="number"
                size="small"
                label={label}
            />
        </Root>
    );
};

const Root = styled('div', {
    shouldForwardProp: prop => prop !== 'disableMargin',
})(({ disableMargin, theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: disableMargin ? null : theme.spacing(-2),

    '& > *': {
        flexGrow: 1,
    },

    '& .MuiCheckbox-root': {
        flexGrow: 0,
        padding: 8,
    },
}));
