import { createTheme, useTheme, useMediaQuery } from '@mui/material';

export const useIsMobile = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return isMobile;
};

export default createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#1598f6',
        },
        neutral: {
            main: '#666',
        },
    },
});
