import React from 'react';
import { styled } from '@mui/material';
import { CurrencyImage } from './currencyImage';

export const CurrencyTitle = ({ title, type = 'gold', disableTitleStyles = false }) => (
    <StyledCurrencyTitle disableTitleStyles={disableTitleStyles}>
        {/* <img src={getImage(type)} alt={title} /> */}
        <CurrencyImage type={type} />
        <p>{title}</p>
    </StyledCurrencyTitle>
);

const StyledCurrencyTitle = styled('div', {
    shouldForwardProp: prop => prop !== 'disableTitleStyles',
})(({ disableTitleStyles }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'fit-content',
    boxSizing: 'border-box',
    marginBottom: 16,

    '& > .currency-image': {
        marginRight: 8,
        width: 17,
        '&.currency-image-double': {
            width: 22,
            height: 22,
            marginRight: 4,
        },
    },

    '& > p': {
        fontSize: disableTitleStyles ? null : '14px',
        fontWeight: disableTitleStyles ? null : 600,
        color: disableTitleStyles ? null : '#484848',
        margin: 0,
    },
}));
