import { useContextSelector } from 'use-context-selector';
import { AppContext } from './context';

export { AppTypes } from './reducer';

export const useAppSelector = selector => {
    return useContextSelector(AppContext, ctx => selector(ctx.state));
};

export const useAppDispatch = () => {
    return useContextSelector(AppContext, ctx => ctx.dispatch);
};
