import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

export const useNotifications = () => {
    const { enqueueSnackbar } = useSnackbar();

    const notify = useCallback(
        ({ variant, message }) => {
            enqueueSnackbar(message, { variant });
        },
        [enqueueSnackbar]
    );

    return notify;
};
