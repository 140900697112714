import React from 'react';
import { styled } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import propTypes from 'prop-types';

const Props = {
    name: propTypes.string.isRequired,
    value: propTypes.any.isRequired,
    onDelete: propTypes.func.isRequired,
};

const Tag = ({ name, value, onDelete }) => (
    <Root data-testid="multiselect-tag">
        <span>{name}</span>
        <CloseIcon onClick={() => onDelete(value)} />
    </Root>
);

const Root = styled('div')(({ theme }) => ({
    '&': {
        display: 'flex',
        alignItems: 'center',
        height: 24,
        margin: 2,
        lineHeight: 22,
        backgroundColor: '#eee',
        borderRadius: 2,
        boxSizing: 'content-box',
        padding: '2px 6px 2px 12px',
        outline: 0,
        overflow: 'hidden',
        '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& span': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        '& svg': {
            fontSize: 12,
            color: '#333',
            opacity: 0.5,
            cursor: 'pointer',
            padding: 4,
            '&:hover': {
                opacity: 1,
            },
        },
    },
}));

Tag.propTypes = Props;
export default Tag;
