import React from 'react';
import { AppTypes, useAppSelector, useAppDispatch } from 'Contexts/app';
import { NumberWithToggle } from 'Components/inputs';
import { CurrencyTitle } from 'Components/currencyTitle';
import { styled } from '@mui/material';
import { FilterWrapper } from '../filterWrapper';

export const DiscountFilter = () => {
    const minDiscountEnergy = useAppSelector(state => state.minDiscountEnergy);
    const useMinDiscountEnergy = useAppSelector(state => state.useMinDiscountEnergy);
    const maxDiscountCostPerEnergy = useAppSelector(
        state => state.maxDiscountCostPerEnergy
    );
    const useMaxDiscountCostPerEnergy = useAppSelector(
        state => state.useMaxDiscountCostPerEnergy
    );
    const dispatch = useAppDispatch();

    const handleMinDiscountEnergyChange = ({ on, value }) => {
        dispatch({
            type: AppTypes.SET_MIN_DISCOUNT_ENERGY,
            payload: {
                minDiscountEnergy: value ?? 0,
                useMinDiscountEnergy: on ?? false,
            },
        });
    };

    const handleMaxCostPerEnergyChange = ({ on, value }) => {
        dispatch({
            type: AppTypes.SET_MAX_DISCOUNT_COST_PER_ENERGY,
            payload: {
                maxDiscountCostPerEnergy: value ?? 0,
                useMaxDiscountCostPerEnergy: on ?? false,
            },
        });
    };

    console.log('minDiscountEnergy =>', minDiscountEnergy);

    return (
        <StyledFilterWrapper id="discount" title="Discount">
            <NumberWithToggle
                on={useMinDiscountEnergy}
                value={minDiscountEnergy}
                onChange={handleMinDiscountEnergyChange}
                label={
                    <CurrencyTitle
                        type="energy"
                        title="Minimum discount energy"
                        disableTitleStyles
                    />
                }
            />

            <NumberWithToggle
                on={useMaxDiscountCostPerEnergy}
                value={maxDiscountCostPerEnergy}
                onChange={handleMaxCostPerEnergyChange}
                label={
                    <CurrencyTitle
                        type="gold-per-energy"
                        title="Maximum cost per energy"
                        disableTitleStyles
                    />
                }
            />
        </StyledFilterWrapper>
    );
};

const StyledFilterWrapper = styled(FilterWrapper)(() => ({
    '& .MuiAccordionDetails-root > .MuiBox-root': {
        paddingLeft: 0,
        // paddingTop: 0,
    },
}));
