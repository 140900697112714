import React from 'react';
import { Checkbox, styled } from '@mui/material';
import {
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material';
import propTypes from 'prop-types';

const Props = {
    name: propTypes.string.isRequired,
    selected: propTypes.bool.isRequired,
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Option = ({ selected, name }) => (
    <>
        <StyledCheckbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
        <span>{name}</span>
    </>
);

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    '&': {
        marginRight: theme.spacing(2),
    },
}));

Option.propTypes = Props;
export default Option;
