import { useEffect } from 'react';
import { AppTypes, useAppDispatch } from 'Contexts/app';
import { useSearchParams } from 'react-router-dom';

export const useParseUrlFilters = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        let params = {};

        // eslint-disable-next-line
        for (const [key, value] of searchParams.entries()) {
            params[key] = value;
        }

        const parseArray = value => {
            return value.split(',');
        };

        const parseNumber = value => {
            return Number.parseFloat(value, 10);
        };

        if (params.tiers) {
            dispatch({
                type: AppTypes.SET_TIERS,
                payload: { tiers: parseArray(params.tiers) },
            });
        }

        if (params.rarities) {
            dispatch({
                type: AppTypes.SET_RARITIES,
                payload: { rarities: parseArray(params.rarities) },
            });
        }

        if (params.minDiscountEnergy) {
            dispatch({
                type: AppTypes.SET_MIN_DISCOUNT_ENERGY,
                payload: {
                    minDiscountEnergy: parseNumber(params.minDiscountEnergy),
                    useMinDiscountEnergy: true,
                },
            });
        }

        if (params.maxDiscountCostPerEnergy) {
            dispatch({
                type: AppTypes.SET_MAX_DISCOUNT_COST_PER_ENERGY,
                payload: {
                    maxDiscountCostPerEnergy: params.maxDiscountCostPerEnergy,
                    useMaxDiscountCostPerEnergy: true,
                },
            });
        }
        // eslint-disable-next-line
    }, []);

    // console.log('outside useEffect params =>', params);
};
