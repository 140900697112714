import React, { useRef } from 'react';
import { Box } from '@mui/material';
import { Header, leftColumnWidth } from 'Core/header';
import { useIsMobile } from 'App/theme';
import { MarketDataFetcher } from './marketDataFetcher';
import { Table } from './table';
import { Filters } from './filters';
import { useParseUrlFilters } from './hooks/useParseUrlFilters';

export const Dashboard = () => {
    const contentRef = useRef(null);
    const isMobile = useIsMobile();

    useParseUrlFilters();

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100vh',
                maxHeight: '100vh',
                width: '100vw',
                maxWidth: '100vw',
            }}
        >
            <MarketDataFetcher />
            <Header />

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    p: 3,
                    paddingTop: '65px', // header size
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignitems: 'flex-start',
                    boxSizing: 'border-box',
                    height: '100vh',
                    maxHeight: '100vh',
                    width: '100vw',
                    maxWidth: '100vw',
                }}
            >
                <Box
                    ref={contentRef}
                    sx={{
                        flex: 1,
                        display: 'grid',
                        gridTemplateRows: '1fr',
                        gridTemplateColumns: isMobile
                            ? '1fr'
                            : `${leftColumnWidth + 12}px auto`,
                        gridTemplateAreas: isMobile ? '"table"' : '"filters table"',
                        paddingBottom: isMobile ? '40px' : undefined,
                        boxSizing: 'border-box',
                        columnGap: '12px',
                        height: '100%',
                        width: '100%',
                        maxWidth: '100%',
                        position: 'relative',
                    }}
                >
                    <Filters contentRef={contentRef} />
                    <Table />
                </Box>
            </Box>
        </Box>
    );
};
