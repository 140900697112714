import React from 'react';
import { AppTypes, useAppDispatch } from 'Contexts/app';
import { Box, Button } from '@mui/material';

export const FiltersHeader = () => {
    const dispatch = useAppDispatch();

    const handleResetFilters = () => {
        dispatch({ type: AppTypes.RESET_FILTERS });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexGrow: 0,
                marginTop: '12px',
                marginBottom: '12px',
                width: '100%',

                '& > h4': {
                    color: '#333',
                    fontWeight: 500,
                    margin: 0,
                },
            }}
        >
            <h4>Filters</h4>

            <Button
                variant="text"
                color="neutral"
                onClick={handleResetFilters}
                aria-label="reset filters"
            >
                reset
            </Button>
        </Box>
    );
};
