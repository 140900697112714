// [GET] https://playshoptitans.com/gamedata/items.json

module.exports = {
    shortsword: {
        uid: 'shortsword',
        level: 1,
        type: 'ws',
        xp: 135,
        craftXp: 2,
        value: 50,
        favor: 1,
        time: 15,
        atk: 16,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:kitchenknife',
        upgrade2: 'bp:longsword',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-4',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 15,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    longsword: {
        uid: 'longsword',
        level: 3,
        type: 'ws',
        xp: 455,
        craftXp: 7,
        value: 180,
        favor: 10,
        time: 60,
        atk: 32,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'bp:boysaxe',
        upgrade2: 'bp:broadsword',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-5',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 25,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    broadsword: {
        uid: 'broadsword',
        level: 5,
        type: 'ws',
        xp: 1200,
        craftXp: 18,
        value: 670,
        favor: 16,
        time: 140,
        atk: 52,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 14,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 6,
        u2Req: 16,
        u3Req: 28,
        u4Req: 45,
        u5Req: 70,
        upgrade1: 'value*1.25',
        upgrade2: 'bp:cutlass',
        upgrade3: 'time*0.75',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 45,
        suggest: 10,
        speedup: 70,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    grottosword: {
        uid: 'grottosword',
        level: 6,
        type: 'ws',
        xp: 1700,
        craftXp: 26,
        value: 1050,
        favor: 21,
        time: 200,
        atk: 64,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 3,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 16,
        resource2: 'wood',
        r2Qty: 4,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-3',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource2-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'natural',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 25,
        surcharge: 55,
        suggest: 10,
        speedup: 90,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'grotto',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    zweihander: {
        uid: 'zweihander',
        level: 7,
        type: 'ws',
        xp: 2350,
        craftXp: 35,
        value: 1250,
        favor: 23,
        time: 270,
        atk: 76,
        def: 19,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 17,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-3',
        upgrade3: 'time*0.75',
        upgrade4: 'resource1-2',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-9',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 60,
        suggest: 10,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 7,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-10-11',
        shardPrice: 75,
    },
    cutlass: {
        uid: 'cutlass',
        level: 8,
        type: 'ws',
        xp: 3100,
        craftXp: 47,
        value: 2050,
        favor: 27,
        time: 360,
        atk: 88,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 5,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 21,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'shortsword',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'arcanedust',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-4',
        upgrade3: 'bp:rapier',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 35,
        surcharge: 80,
        suggest: 15,
        speedup: 135,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    rapier: {
        uid: 'rapier',
        level: 12,
        type: 'ws',
        xp: 7300,
        craftXp: 110,
        value: 5900,
        favor: 64,
        time: 990,
        atk: 144,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 9,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 41,
        resource2: 'steel',
        r2Qty: 8,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 2,
        c1Tags: null,
        component2: 'redpinecone',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-8',
        upgrade3: 'bp:katana',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 60,
        surcharge: 130,
        suggest: 25,
        speedup: 260,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    herosword: {
        uid: 'herosword',
        level: 14,
        type: 'ws',
        xp: 10000,
        craftXp: 150,
        value: 11000,
        favor: 119,
        time: 1290,
        atk: 172,
        def: 43,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 60,
        resource2: 'steel',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'cutlass',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'icypearl',
        c2Qty: 1,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-12',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-2',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'resource2-2',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 80,
        surcharge: 175,
        suggest: 35,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-09-10',
        shardPrice: 120,
    },
    candysword: {
        uid: 'candysword',
        level: 14,
        type: 'ws',
        xp: 10000,
        craftXp: 150,
        value: 15000,
        favor: 105,
        time: 1590,
        atk: 172,
        def: 0,
        hp: 5,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 54,
        resource2: 'oils',
        r2Qty: 11,
        resource3: null,
        r3Qty: 0,
        component1: 'corrupted',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'livingroots',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-11',
        upgrade3: 'component1-1',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 90,
        surcharge: 200,
        suggest: 40,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 7,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-09-07',
        shardPrice: 120,
    },
    katana: {
        uid: 'katana',
        level: 16,
        type: 'ws',
        xp: 13500,
        craftXp: 203,
        value: 24000,
        favor: 173,
        time: 2400,
        atk: 200,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 85,
        resource2: 'steel',
        r2Qty: 21,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-17',
        upgrade3: 'bp:scimitar',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 115,
        surcharge: 255,
        suggest: 50,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 18,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    fallsword: {
        uid: 'fallsword',
        level: 18,
        type: 'ws',
        xp: 17500,
        craftXp: 263,
        value: 38500,
        favor: 296,
        time: 3600,
        atk: 230,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 1,
        w3BuildingReq: 5,
        resource1: 'iron',
        r1Qty: 113,
        resource2: 'steel',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: 'redpinecone',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource1-23',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 145,
        surcharge: 320,
        suggest: 65,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-09-08',
        shardPrice: 140,
    },
    scimitar: {
        uid: 'scimitar',
        level: 20,
        type: 'ws',
        xp: 22000,
        craftXp: 330,
        value: 64500,
        favor: 498,
        time: 6000,
        atk: 270,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'iron',
        r1Qty: 141,
        resource2: 'steel',
        r2Qty: 36,
        resource3: 'oils',
        r3Qty: 18,
        component1: 'starmetal',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:celesteelblade',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 180,
        surcharge: 405,
        suggest: 80,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    celesteelblade: {
        uid: 'celesteelblade',
        level: 24,
        type: 'ws',
        xp: 32500,
        craftXp: 488,
        value: 195000,
        favor: 753,
        time: 13200,
        atk: 340,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'iron',
        r1Qty: 199,
        resource2: 'steel',
        r2Qty: 51,
        resource3: 'gems',
        r3Qty: 5,
        component1: 'timecrystal',
        c1Qty: 5,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:falchion',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 310,
        surcharge: 690,
        suggest: 140,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 7,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ruinssword: {
        uid: 'ruinssword',
        level: 25,
        type: 'ws',
        xp: 35500,
        craftXp: 533,
        value: 220000,
        favor: 981,
        time: 16200,
        atk: 350,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 8,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'iron',
        r1Qty: 214,
        resource2: 'steel',
        r2Qty: 57,
        resource3: 'mana',
        r3Qty: 6,
        component1: 'overgrownvine',
        c1Qty: 4,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 7,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'unholy',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 330,
        surcharge: 735,
        suggest: 145,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 7,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'ruins',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldsword: {
        uid: 'goldsword',
        level: 26,
        type: 'ws',
        xp: 39000,
        craftXp: 585,
        value: 365000,
        favor: 1146,
        time: 19200,
        atk: 370,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 8,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 13,
        resource1: 'iron',
        r1Qty: 229,
        resource2: 'steel',
        r2Qty: 62,
        resource3: 'gems',
        r3Qty: 8,
        component1: 'tabletfragment',
        c1Qty: 3,
        c1Tags: null,
        component2: 'timecrystal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-46',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-12',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 315,
        surcharge: 700,
        suggest: 140,
        speedup: 995,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 7,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-01-25',
        shardPrice: 0,
    },
    falchion: {
        uid: 'falchion',
        level: 28,
        type: 'ws',
        xp: 45500,
        craftXp: 683,
        value: 300000,
        favor: 1375,
        time: 20400,
        atk: 410,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'iron',
        r1Qty: 259,
        resource2: 'steel',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 12,
        component1: 'ectoplasm',
        c1Qty: 4,
        c1Tags: null,
        component2: 'starmetal',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'bp:cleaver',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 385,
        surcharge: 850,
        suggest: 170,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    poloniacutlass: {
        uid: 'poloniacutlass',
        level: 29,
        type: 'ws',
        xp: 49000,
        craftXp: 735,
        value: 420000,
        favor: 1777,
        time: 24600,
        atk: 430,
        def: 0,
        hp: 13,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'iron',
        r1Qty: 275,
        resource2: 'steel',
        r2Qty: 77,
        resource3: 'gems',
        r3Qty: 14,
        component1: 'ghostflag',
        c1Qty: 4,
        c1Tags: null,
        component2: 'arcanedust',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 450,
        surcharge: 1000,
        suggest: 200,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-04-21',
        shardPrice: 200,
    },
    canesword: {
        uid: 'canesword',
        level: 29,
        type: 'ws',
        xp: 49000,
        craftXp: 735,
        value: 395000,
        favor: 1777,
        time: 24600,
        atk: 430,
        def: 108,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'iron',
        r1Qty: 275,
        resource2: 'steel',
        r2Qty: 77,
        resource3: 'mana',
        r3Qty: 14,
        component1: 'sewingthread',
        c1Qty: 3,
        c1Tags: null,
        component2: 'overgrownvine',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.7',
        upgrade2: 'resource1-83',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-19',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 435,
        surcharge: 970,
        suggest: 195,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-06-02',
        shardPrice: 200,
    },
    cleaver: {
        uid: 'cleaver',
        level: 30,
        type: 'ws',
        xp: 53000,
        craftXp: 795,
        value: 605000,
        favor: 1835,
        time: 29400,
        atk: 450,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 290,
        resource2: 'steel',
        r2Qty: 82,
        resource3: 'gems',
        r3Qty: 17,
        component1: 'sirenshard',
        c1Qty: 5,
        c1Tags: null,
        component2: 'starmetal',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:vorpalsword',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 805,
        surcharge: 1790,
        suggest: 360,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 7,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    templesword: {
        uid: 'templesword',
        level: 31,
        type: 'ws',
        xp: 56500,
        craftXp: 848,
        value: 630000,
        favor: 2352,
        time: 34800,
        atk: 470,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 306,
        resource2: 'steel',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 19,
        component1: 'deepcoral',
        c1Qty: 5,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'component1-2',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-6',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'flood',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 820,
        surcharge: 1825,
        suggest: 365,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 7,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'temple',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    dragonsword: {
        uid: 'dragonsword',
        level: 31,
        type: 'ws',
        xp: 56500,
        craftXp: 848,
        value: 650000,
        favor: 2352,
        time: 34800,
        atk: 470,
        def: 118,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 306,
        resource2: 'steel',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 19,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: 'overgrownvine',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.7',
        upgrade2: 'resource1-92',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-22',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 835,
        surcharge: 1850,
        suggest: 370,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-12-30',
        shardPrice: 250,
    },
    lunarsword: {
        uid: 'lunarsword',
        level: 32,
        type: 'ws',
        xp: 61000,
        craftXp: 915,
        value: 680000,
        favor: 2705,
        time: 42000,
        atk: 480,
        def: 0,
        hp: 15,
        eva: '0',
        crit: '0',
        excl: 'special',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 26,
        w2BuildingReq: 22,
        w3BuildingReq: 19,
        resource1: 'iron',
        r1Qty: 322,
        resource2: 'steel',
        r2Qty: 93,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'deepcoral',
        c1Qty: 4,
        c1Tags: null,
        component2: 'goldshell',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.7',
        upgrade2: 'resource1-97',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'resource2-28',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'tiger',
        tag: null,
        discount: 850,
        surcharge: 1890,
        suggest: 380,
        speedup: 2635,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-01-25',
        shardPrice: 250,
    },
    vorpalsword: {
        uid: 'vorpalsword',
        level: 33,
        type: 'ws',
        xp: 97500,
        craftXp: 1463,
        value: 1400000,
        favor: 2736,
        time: 50400,
        atk: 580,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 338,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 23,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1045,
        surcharge: 2320,
        suggest: 465,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    victoriansword: {
        uid: 'victoriansword',
        level: 33,
        type: 'ws',
        xp: 97500,
        craftXp: 1463,
        value: 1500000,
        favor: 3061,
        time: 50400,
        atk: 580,
        def: 0,
        hp: 18,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 338,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 23,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1080,
        surcharge: 2395,
        suggest: 480,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-04-06',
        shardPrice: 300,
    },
    moonsword: {
        uid: 'moonsword',
        level: 34,
        type: 'ws',
        xp: 105000,
        craftXp: 1575,
        value: 1650000,
        favor: 3479,
        time: 60000,
        atk: 600,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 354,
        resource2: 'steel',
        r2Qty: 103,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.75',
        upgrade2: 'resource2-21',
        upgrade3: 'value*1.25',
        upgrade4: 'resource3-4',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource1-106',
        supgrade2: 'resource3-4',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1130,
        surcharge: 2510,
        suggest: 500,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-09-22',
        shardPrice: 300,
    },
    boysaxe: {
        uid: 'boysaxe',
        level: 3,
        type: 'wa',
        xp: 540,
        craftXp: 8,
        value: 310,
        favor: 10,
        time: 70,
        atk: 30,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'component1-1',
        upgrade2: 'bp:hatchet',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.5',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 15,
        surcharge: 30,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    hatchet: {
        uid: 'hatchet',
        level: 5,
        type: 'wa',
        xp: 1450,
        craftXp: 22,
        value: 710,
        favor: 16,
        time: 160,
        atk: 50,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 14,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:doublebitted',
        upgrade3: 'resource1-3',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.5',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 45,
        suggest: 10,
        speedup: 70,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    doublebitted: {
        uid: 'doublebitted',
        level: 7,
        type: 'wa',
        xp: 2850,
        craftXp: 43,
        value: 1300,
        favor: 21,
        time: 300,
        atk: 72,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 17,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:halberd',
        upgrade3: 'time*0.75',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'quality+2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-3',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 60,
        suggest: 10,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    exploreraxe: {
        uid: 'exploreraxe',
        level: 8,
        type: 'wa',
        xp: 3800,
        craftXp: 57,
        value: 1750,
        favor: 30,
        time: 400,
        atk: 84,
        def: 21,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 5,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 21,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'resource1-4',
        upgrade3: 'time*0.75',
        upgrade4: 'resource1-4',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-11',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 30,
        surcharge: 70,
        suggest: 15,
        speedup: 135,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-08-13',
        shardPrice: 75,
    },
    halberd: {
        uid: 'halberd',
        level: 10,
        type: 'wa',
        xp: 6100,
        craftXp: 92,
        value: 4500,
        favor: 42,
        time: 690,
        atk: 108,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 7,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 30,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'arcanedust',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:tomahawk',
        upgrade4: 'resource1-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-2',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 50,
        surcharge: 115,
        suggest: 25,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    grottoaxe: {
        uid: 'grottoaxe',
        level: 11,
        type: 'wa',
        xp: 7450,
        craftXp: 112,
        value: 5250,
        favor: 56,
        time: 870,
        atk: 124,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 4,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 34,
        resource2: 'iron',
        r2Qty: 17,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-7',
        upgrade4: 'resource2-9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'ember',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 55,
        surcharge: 120,
        suggest: 25,
        speedup: 225,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: 'grotto',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    tomahawk: {
        uid: 'tomahawk',
        level: 13,
        type: 'wa',
        xp: 10500,
        craftXp: 158,
        value: 10000,
        favor: 81,
        time: 1380,
        atk: 148,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 49,
        resource2: 'ironwood',
        r2Qty: 10,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: 'elvenwood',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-10',
        upgrade3: 'bp:battleaxe',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 75,
        surcharge: 165,
        suggest: 35,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    starteraxe: {
        uid: 'starteraxe',
        level: 15,
        type: 'wa',
        xp: 14500,
        craftXp: 218,
        value: 12000,
        favor: 150,
        time: 2160,
        atk: 176,
        def: 44,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 71,
        resource2: 'ironwood',
        r2Qty: 16,
        resource3: null,
        r3Qty: 0,
        component1: 'webbedwings',
        c1Qty: 3,
        c1Tags: null,
        component2: 'redpinecone',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-14',
        upgrade3: 'resource2-3',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-4',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 80,
        surcharge: 180,
        suggest: 35,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 120,
    },
    snowaxe: {
        uid: 'snowaxe',
        level: 15,
        type: 'wa',
        xp: 14500,
        craftXp: 218,
        value: 14500,
        favor: 138,
        time: 2160,
        atk: 176,
        def: 0,
        hp: 6,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 66,
        resource2: 'steel',
        r2Qty: 14,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-10',
        upgrade3: 'resource1-10',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 90,
        surcharge: 200,
        suggest: 40,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-30',
        shardPrice: 120,
    },
    battleaxe: {
        uid: 'battleaxe',
        level: 17,
        type: 'wa',
        xp: 19000,
        craftXp: 285,
        value: 24000,
        favor: 216,
        time: 3300,
        atk: 210,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 1,
        w3BuildingReq: 4,
        resource1: 'wood',
        r1Qty: 99,
        resource2: 'ironwood',
        r2Qty: 25,
        resource3: null,
        r3Qty: 0,
        component1: 'hatchet',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'mooncrystal',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:steelaxe',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 115,
        surcharge: 255,
        suggest: 50,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 16,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    birdaxe: {
        uid: 'birdaxe',
        level: 18,
        type: 'wa',
        xp: 21500,
        craftXp: 323,
        value: 40500,
        favor: 296,
        time: 3000,
        atk: 220,
        def: 0,
        hp: 7,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 1,
        w3BuildingReq: 5,
        resource1: 'wood',
        r1Qty: 113,
        resource2: 'ironwood',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'tomahawk',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'webbedwings',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'eagle',
        tag: null,
        discount: 145,
        surcharge: 325,
        suggest: 65,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-05-18',
        shardPrice: 140,
    },
    steelaxe: {
        uid: 'steelaxe',
        level: 20,
        type: 'wa',
        xp: 27000,
        craftXp: 405,
        value: 69000,
        favor: 498,
        time: 6600,
        atk: 250,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'wood',
        r1Qty: 141,
        resource2: 'ironwood',
        r2Qty: 36,
        resource3: 'steel',
        r3Qty: 18,
        component1: 'starmetal',
        c1Qty: 3,
        c1Tags: null,
        component2: 'darkscale',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:raideraxe',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-5',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 190,
        surcharge: 420,
        suggest: 85,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    pyramidaxe: {
        uid: 'pyramidaxe',
        level: 21,
        type: 'wa',
        xp: 30000,
        craftXp: 450,
        value: 86500,
        favor: 502,
        time: 7800,
        atk: 270,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'wood',
        r1Qty: 155,
        resource2: 'ironwood',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'starmetal',
        c1Qty: 1,
        c1Tags: null,
        component2: 'whitesand',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'wild',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 210,
        surcharge: 470,
        suggest: 95,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: 'pyramid',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    raideraxe: {
        uid: 'raideraxe',
        level: 25,
        type: 'wa',
        xp: 44000,
        craftXp: 660,
        value: 210000,
        favor: 896,
        time: 15600,
        atk: 330,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'wood',
        r1Qty: 214,
        resource2: 'ironwood',
        r2Qty: 57,
        resource3: 'gems',
        r3Qty: 8,
        component1: 'ancienthammer',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'ancientmarble',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'bp:executioner',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 320,
        surcharge: 715,
        suggest: 145,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    executioner: {
        uid: 'executioner',
        level: 27,
        type: 'wa',
        xp: 51500,
        craftXp: 773,
        value: 285000,
        favor: 1224,
        time: 18600,
        atk: 370,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'wood',
        r1Qty: 244,
        resource2: 'ironwood',
        r2Qty: 67,
        resource3: 'gems',
        r3Qty: 13,
        component1: 'ghostflag',
        c1Qty: 3,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:crescentaxe',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-4',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 375,
        surcharge: 830,
        suggest: 165,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    castleaxe: {
        uid: 'castleaxe',
        level: 28,
        type: 'wa',
        xp: 56000,
        craftXp: 840,
        value: 360000,
        favor: 1589,
        time: 22200,
        atk: 380,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 259,
        resource2: 'ironwood',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'sewingthread',
        c1Qty: 4,
        c1Tags: null,
        component2: 'whitesand',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-4',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'sacred',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 420,
        surcharge: 930,
        suggest: 185,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'castle',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    cincoaxe2020: {
        uid: 'cincoaxe2020',
        level: 28,
        type: 'wa',
        xp: 56000,
        craftXp: 840,
        value: 415000,
        favor: 1589,
        time: 22200,
        atk: 380,
        def: 95,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 259,
        resource2: 'ironwood',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'ectoplasm',
        c1Qty: 4,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-4',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 450,
        surcharge: 995,
        suggest: 200,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-05-05',
        shardPrice: 200,
    },
    crescentaxe: {
        uid: 'crescentaxe',
        level: 30,
        type: 'wa',
        xp: 65000,
        craftXp: 975,
        value: 645000,
        favor: 1909,
        time: 32400,
        atk: 420,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'carpenter',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 290,
        resource2: 'ironwood',
        r2Qty: 82,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'deepcoral',
        c1Qty: 5,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:manticoreaxe',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 830,
        surcharge: 1845,
        suggest: 370,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    templeaxe: {
        uid: 'templeaxe',
        level: 31,
        type: 'wa',
        xp: 69500,
        craftXp: 1043,
        value: 680000,
        favor: 2451,
        time: 38400,
        atk: 440,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'carpenter',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 306,
        resource2: 'ironwood',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: 'overgrownvine',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'primal',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 850,
        surcharge: 1890,
        suggest: 380,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'temple',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldaxe: {
        uid: 'goldaxe',
        level: 32,
        type: 'wa',
        xp: 74500,
        craftXp: 1118,
        value: 1650000,
        favor: 2831,
        time: 46200,
        atk: 460,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'carpenter',
        w1BuildingReq: 26,
        w2BuildingReq: 22,
        w3BuildingReq: 19,
        resource1: 'wood',
        r1Qty: 322,
        resource2: 'ironwood',
        r2Qty: 93,
        resource3: 'gems',
        r3Qty: 29,
        component1: 'tabletjewel',
        c1Qty: 5,
        c1Tags: null,
        component2: 'timecrystal',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-64',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-19',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 975,
        surcharge: 2170,
        suggest: 435,
        speedup: 2635,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-01-23',
        shardPrice: 0,
    },
    coupleaxe: {
        uid: 'coupleaxe',
        level: 32,
        type: 'wa',
        xp: 74500,
        craftXp: 1118,
        value: 685000,
        favor: 2831,
        time: 46200,
        atk: 460,
        def: 0,
        hp: 14,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'carpenter',
        w1BuildingReq: 26,
        w2BuildingReq: 22,
        w3BuildingReq: 19,
        resource1: 'wood',
        r1Qty: 322,
        resource2: 'ironwood',
        r2Qty: 93,
        resource3: 'gems',
        r3Qty: 29,
        component1: 'sirenshard',
        c1Qty: 3,
        c1Tags: null,
        component2: 'ghostflag',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-64',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-19',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'bear',
        tag: null,
        discount: 855,
        surcharge: 1900,
        suggest: 380,
        speedup: 2635,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-02-23',
        shardPrice: 250,
    },
    manticoreaxe: {
        uid: 'manticoreaxe',
        level: 33,
        type: 'wa',
        xp: 120000,
        craftXp: 1800,
        value: 1450000,
        favor: 2889,
        time: 55200,
        atk: 540,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'carpenter',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 338,
        resource2: 'ironwood',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1060,
        surcharge: 2355,
        suggest: 470,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    beeaxe: {
        uid: 'beeaxe',
        level: 33,
        type: 'wa',
        xp: 120000,
        craftXp: 1800,
        value: 1700000,
        favor: 4072,
        time: 55200,
        atk: 540,
        def: 135,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'carpenter',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 338,
        resource2: 'ironwood',
        r2Qty: 98,
        resource3: 'oils',
        r3Qty: 73,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'goldshell',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-22',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1145,
        surcharge: 2545,
        suggest: 510,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-06-01',
        shardPrice: 300,
    },
    fallaxe: {
        uid: 'fallaxe',
        level: 34,
        type: 'wa',
        xp: 130000,
        craftXp: 1950,
        value: 1800000,
        favor: 3564,
        time: 66000,
        atk: 560,
        def: 0,
        hp: 18,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'carpenter',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 354,
        resource2: 'ironwood',
        r2Qty: 103,
        resource3: 'mana',
        r3Qty: 29,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-71',
        upgrade3: 'resource2-21',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 1175,
        surcharge: 2615,
        suggest: 525,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-10-05',
        shardPrice: 300,
    },
    aegiraxe: {
        uid: 'aegiraxe',
        level: 34,
        type: 'wa',
        xp: 130000,
        craftXp: 1950,
        value: 1850000,
        favor: 3275,
        time: 66000,
        atk: 560,
        def: 140,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'carpenter',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 320,
        resource2: 'steel',
        r2Qty: 90,
        resource3: 'gems',
        r3Qty: 34,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-64',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-18',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 1195,
        surcharge: 2650,
        suggest: 530,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-02-23',
        shardPrice: 300,
    },
    kitchenknife: {
        uid: 'kitchenknife',
        level: 1,
        type: 'wd',
        xp: 125,
        craftXp: 2,
        value: 45,
        favor: 1,
        time: 10,
        atk: 14,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:dagger',
        upgrade3: 'resource1-1',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-1',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 10,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    forestdagger: {
        uid: 'forestdagger',
        level: 3,
        type: 'wd',
        xp: 385,
        craftXp: 6,
        value: 330,
        favor: 9,
        time: 60,
        atk: 30,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 1,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 8,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 6,
        u2Req: 16,
        u3Req: 28,
        u4Req: 45,
        u5Req: 70,
        upgrade1: 'value*1.5',
        upgrade2: 'component1-1',
        upgrade3: 'resource1-2',
        upgrade4: 'quality+1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource1-2',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'bubble',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 15,
        surcharge: 30,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'forest',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    dagger: {
        uid: 'dagger',
        level: 3,
        type: 'wd',
        xp: 385,
        craftXp: 6,
        value: 260,
        favor: 9,
        time: 60,
        atk: 30,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 8,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 6,
        u2Req: 16,
        u3Req: 28,
        u4Req: 45,
        u5Req: 70,
        upgrade1: 'bp:switchblade',
        upgrade2: 'component1-1',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-4',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 15,
        surcharge: 30,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    kunai: {
        uid: 'kunai',
        level: 5,
        type: 'wd',
        xp: 960,
        craftXp: 14,
        value: 660,
        favor: 16,
        time: 130,
        atk: 50,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 2,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 13,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 6,
        u2Req: 16,
        u3Req: 28,
        u4Req: 45,
        u5Req: 70,
        upgrade1: 'time*0.75',
        upgrade2: 'value*1.25',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-7',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 45,
        suggest: 10,
        speedup: 70,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 50,
    },
    switchblade: {
        uid: 'switchblade',
        level: 6,
        type: 'wd',
        xp: 1400,
        craftXp: 21,
        value: 720,
        favor: 16,
        time: 180,
        atk: 60,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 14,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:butterflyknife',
        upgrade4: 'quality+1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 45,
        suggest: 10,
        speedup: 90,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    butterflyknife: {
        uid: 'butterflyknife',
        level: 10,
        type: 'wd',
        xp: 3950,
        craftXp: 59,
        value: 3650,
        favor: 38,
        time: 560,
        atk: 108,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 7,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 27,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'arcanedust',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:kris',
        upgrade4: 'resource1-5',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-2',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 45,
        surcharge: 105,
        suggest: 20,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    schooldagger2020: {
        uid: 'schooldagger2020',
        level: 12,
        type: 'wd',
        xp: 5850,
        craftXp: 88,
        value: 6600,
        favor: 63,
        time: 900,
        atk: 136,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 9,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 37,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-7',
        upgrade4: 'component1-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 60,
        surcharge: 135,
        suggest: 25,
        speedup: 260,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-08-25',
        shardPrice: 100,
    },
    kris: {
        uid: 'kris',
        level: 13,
        type: 'wd',
        xp: 6950,
        craftXp: 104,
        value: 6950,
        favor: 72,
        time: 1140,
        atk: 148,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 44,
        resource2: 'steel',
        r2Qty: 9,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-9',
        upgrade3: 'bp:dirk',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 65,
        surcharge: 140,
        suggest: 30,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    atlab_boomerang: {
        uid: 'atlab_boomerang',
        level: 14,
        type: 'wd',
        xp: 8150,
        craftXp: 122,
        value: 11000,
        favor: 105,
        time: 1440,
        atk: 164,
        def: 41,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'special',
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 54,
        resource2: 'steel',
        r2Qty: 11,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-11',
        upgrade3: 'value*1.25',
        upgrade4: 'component1-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 80,
        surcharge: 175,
        suggest: 35,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-02',
        shardPrice: 120,
    },
    yamidagger: {
        uid: 'yamidagger',
        level: 15,
        type: 'wd',
        xp: 9400,
        craftXp: 141,
        value: 15000,
        favor: 133,
        time: 1800,
        atk: 176,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 64,
        resource2: 'steel',
        r2Qty: 15,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 3,
        c1Tags: null,
        component2: 'webbedwings',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-13',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 90,
        surcharge: 200,
        suggest: 40,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-12-27',
        shardPrice: 120,
    },
    dirk: {
        uid: 'dirk',
        level: 17,
        type: 'wd',
        xp: 12500,
        craftXp: 188,
        value: 20500,
        favor: 191,
        time: 2700,
        atk: 210,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 1,
        w3BuildingReq: 4,
        resource1: 'iron',
        r1Qty: 89,
        resource2: 'steel',
        r2Qty: 22,
        resource3: null,
        r3Qty: 0,
        component1: 'sharpfang',
        c1Qty: 3,
        c1Tags: null,
        component2: 'arcanedust',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-18',
        upgrade3: 'bp:guttingknife',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-4',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 105,
        surcharge: 235,
        suggest: 45,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lunardagger: {
        uid: 'lunardagger',
        level: 18,
        type: 'wd',
        xp: 14000,
        craftXp: 210,
        value: 31500,
        favor: 259,
        time: 3300,
        atk: 220,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'iron',
        r1Qty: 101,
        resource2: 'steel',
        r2Qty: 26,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 3,
        c1Tags: null,
        component2: 'icypearl',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 130,
        surcharge: 290,
        suggest: 60,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-02-09',
        shardPrice: 140,
    },
    guttingknife: {
        uid: 'guttingknife',
        level: 21,
        type: 'wd',
        xp: 19500,
        craftXp: 293,
        value: 69500,
        favor: 395,
        time: 6600,
        atk: 270,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'iron',
        r1Qty: 140,
        resource2: 'steel',
        r2Qty: 35,
        resource3: null,
        r3Qty: 0,
        component1: 'switchblade',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'evileye',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:trolltooth',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 190,
        surcharge: 420,
        suggest: 85,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    trolltooth: {
        uid: 'trolltooth',
        level: 24,
        type: 'wd',
        xp: 26000,
        craftXp: 390,
        value: 170000,
        favor: 622,
        time: 12000,
        atk: 320,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'iron',
        r1Qty: 179,
        resource2: 'steel',
        r2Qty: 46,
        resource3: null,
        r3Qty: 0,
        component1: 'overgrownvine',
        c1Qty: 5,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-36',
        upgrade3: 'bp:misericordia',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-9',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 295,
        surcharge: 650,
        suggest: 130,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ruinsdagger: {
        uid: 'ruinsdagger',
        level: 25,
        type: 'wd',
        xp: 28500,
        craftXp: 428,
        value: 205000,
        favor: 805,
        time: 14400,
        atk: 330,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 8,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'iron',
        r1Qty: 192,
        resource2: 'steel',
        r2Qty: 51,
        resource3: null,
        r3Qty: 0,
        component1: 'ancientmarble',
        c1Qty: 5,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-38',
        upgrade3: 'value*1.25',
        upgrade4: 'component1-1',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-10',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-4',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'wild',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 320,
        surcharge: 710,
        suggest: 140,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'ruins',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    misericordia: {
        uid: 'misericordia',
        level: 27,
        type: 'wd',
        xp: 33500,
        craftXp: 503,
        value: 285000,
        favor: 938,
        time: 15000,
        atk: 370,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'iron',
        r1Qty: 219,
        resource2: 'steel',
        r2Qty: 60,
        resource3: null,
        r3Qty: 0,
        component1: 'ectoplasm',
        c1Qty: 5,
        c1Tags: null,
        component2: 'ancientmarble',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-44',
        upgrade3: 'bp:katar',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-12',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 375,
        surcharge: 830,
        suggest: 165,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    sultandagger: {
        uid: 'sultandagger',
        level: 29,
        type: 'wd',
        xp: 39000,
        craftXp: 585,
        value: 410000,
        favor: 1580,
        time: 22200,
        atk: 400,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'iron',
        r1Qty: 247,
        resource2: 'steel',
        r2Qty: 69,
        resource3: 'gems',
        r3Qty: 15,
        component1: 'ghostflag',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'time*0.7',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 445,
        surcharge: 990,
        suggest: 200,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-08-12',
        shardPrice: 200,
    },
    golddagger: {
        uid: 'golddagger',
        level: 29,
        type: 'wd',
        xp: 39000,
        craftXp: 585,
        value: 520000,
        favor: 1380,
        time: 22200,
        atk: 400,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'iron',
        r1Qty: 247,
        resource2: 'steel',
        r2Qty: 69,
        resource3: null,
        r3Qty: 0,
        component1: 'tabletfragment',
        c1Qty: 5,
        c1Tags: null,
        component2: 'timecrystal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-14',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 375,
        surcharge: 835,
        suggest: 165,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-04-18',
        shardPrice: 0,
    },
    katar: {
        uid: 'katar',
        level: 30,
        type: 'wd',
        xp: 42000,
        craftXp: 630,
        value: 500000,
        favor: 1386,
        time: 26400,
        atk: 420,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 261,
        resource2: 'steel',
        r2Qty: 74,
        resource3: null,
        r3Qty: 0,
        component1: 'goldshell',
        c1Qty: 5,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'bp:heartseeker',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-15',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 735,
        surcharge: 1630,
        suggest: 325,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    templedagger: {
        uid: 'templedagger',
        level: 31,
        type: 'wd',
        xp: 45000,
        craftXp: 675,
        value: 550000,
        favor: 1777,
        time: 31800,
        atk: 440,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 275,
        resource2: 'steel',
        r2Qty: 79,
        resource3: null,
        r3Qty: 0,
        component1: 'sirenshard',
        c1Qty: 5,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-2',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-16',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'nightmare',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 770,
        surcharge: 1710,
        suggest: 340,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'temple',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    siadagger: {
        uid: 'siadagger',
        level: 31,
        type: 'wd',
        xp: 45000,
        craftXp: 675,
        value: 570000,
        favor: 2091,
        time: 31800,
        atk: 440,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 275,
        resource2: 'steel',
        r2Qty: 79,
        resource3: 'gems',
        r3Qty: 20,
        component1: 'deepcoral',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-16',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 785,
        surcharge: 1740,
        suggest: 350,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-11-03',
        shardPrice: 250,
    },
    stpatrickdagger: {
        uid: 'stpatrickdagger',
        level: 32,
        type: 'wd',
        xp: 48500,
        craftXp: 728,
        value: 615000,
        favor: 2018,
        time: 37800,
        atk: 460,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 26,
        w2BuildingReq: 22,
        w3BuildingReq: 19,
        resource1: 'iron',
        r1Qty: 290,
        resource2: 'steel',
        r2Qty: 83,
        resource3: null,
        r3Qty: 0,
        component1: 'goldshell',
        c1Qty: 5,
        c1Tags: null,
        component2: 'livingroots',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-58',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-17',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 810,
        surcharge: 1800,
        suggest: 360,
        speedup: 2635,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-11-27',
        shardPrice: 250,
    },
    heartseeker: {
        uid: 'heartseeker',
        level: 33,
        type: 'wd',
        xp: 77500,
        craftXp: 1163,
        value: 1200000,
        favor: 2005,
        time: 45000,
        atk: 540,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 304,
        resource2: 'steel',
        r2Qty: 88,
        resource3: null,
        r3Qty: 0,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-91',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-18',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-18',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 970,
        surcharge: 2155,
        suggest: 430,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    peakdagger: {
        uid: 'peakdagger',
        level: 33,
        type: 'wd',
        xp: 77500,
        craftXp: 1163,
        value: 1300000,
        favor: 2277,
        time: 45000,
        atk: 540,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 304,
        resource2: 'steel',
        r2Qty: 88,
        resource3: null,
        r3Qty: 0,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-18',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource1-91',
        supgrade2: 'resource2-18',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'tempest',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 1005,
        surcharge: 2235,
        suggest: 445,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'peak',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    halloweendagger: {
        uid: 'halloweendagger',
        level: 33,
        type: 'wd',
        xp: 77500,
        craftXp: 1163,
        value: 1500000,
        favor: 2740,
        time: 45000,
        atk: 540,
        def: 135,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 304,
        resource2: 'steel',
        r2Qty: 66,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-91',
        upgrade3: 'value*1.25',
        upgrade4: 'resource3-13',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-13',
        supgrade2: 'resource3-12',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1080,
        surcharge: 2395,
        suggest: 480,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-10-19',
        shardPrice: 300,
    },
    atlab_kyoshifan: {
        uid: 'atlab_kyoshifan',
        level: 34,
        type: 'wd',
        xp: 82500,
        craftXp: 1238,
        value: 1600000,
        favor: 2574,
        time: 54000,
        atk: 560,
        def: 0,
        hp: 18,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 319,
        resource2: 'steel',
        r2Qty: 93,
        resource3: null,
        r3Qty: 0,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'goldshell',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-64',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-19',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1110,
        surcharge: 2470,
        suggest: 495,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-17',
        shardPrice: 300,
    },
    wiccandagger: {
        uid: 'wiccandagger',
        level: 34,
        type: 'wd',
        xp: 82500,
        craftXp: 1238,
        value: 1750000,
        favor: 4550,
        time: 54000,
        atk: 560,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.10',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 319,
        resource2: 'wood',
        r2Qty: 89,
        resource3: 'steel',
        r3Qty: 93,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'value*1.25',
        upgrade3: 'resource1-64',
        upgrade4: 'resource3-19',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'resource2-45',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 1160,
        surcharge: 2580,
        suggest: 515,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-03-08',
        shardPrice: 300,
    },
    club: {
        uid: 'club',
        level: 1,
        type: 'wm',
        xp: 135,
        craftXp: 2,
        value: 50,
        favor: 1,
        time: 15,
        atk: 14,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:woodshield',
        upgrade2: 'bp:spikedclub',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-4',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 15,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    spikedclub: {
        uid: 'spikedclub',
        level: 3,
        type: 'wm',
        xp: 455,
        craftXp: 7,
        value: 220,
        favor: 9,
        time: 50,
        atk: 30,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 8,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'club',
        c1Qty: 1,
        c1Tags: 'common',
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:hammer',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 25,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    forestmace: {
        uid: 'forestmace',
        level: 4,
        type: 'wm',
        xp: 765,
        craftXp: 11,
        value: 530,
        favor: 13,
        time: 100,
        atk: 40,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 2,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 11,
        resource2: 'herbs',
        r2Qty: 3,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'resource1-2',
        upgrade3: 'component1-1',
        upgrade4: 'time*0.75',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource2-3',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'corrupted',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 20,
        surcharge: 40,
        suggest: 10,
        speedup: 55,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: 'forest',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    hammer: {
        uid: 'hammer',
        level: 5,
        type: 'wm',
        xp: 1200,
        craftXp: 18,
        value: 630,
        favor: 15,
        time: 140,
        atk: 50,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 13,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 6,
        u2Req: 16,
        u3Req: 28,
        u4Req: 45,
        u5Req: 70,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:morningstar',
        upgrade3: 'time*0.75',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 45,
        suggest: 10,
        speedup: 70,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    libertymace: {
        uid: 'libertymace',
        level: 6,
        type: 'wm',
        xp: 1700,
        craftXp: 26,
        value: 1000,
        favor: 18,
        time: 200,
        atk: 60,
        def: 0,
        hp: 2,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 14,
        resource2: 'iron',
        r2Qty: 4,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-3',
        upgrade4: 'resource2-2',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 55,
        suggest: 10,
        speedup: 90,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-06-27',
        shardPrice: 75,
    },
    morningstar: {
        uid: 'morningstar',
        level: 8,
        type: 'wm',
        xp: 3100,
        craftXp: 47,
        value: 2700,
        favor: 24,
        time: 360,
        atk: 84,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 5,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 19,
        resource2: 'iron',
        r2Qty: 5,
        resource3: null,
        r3Qty: 0,
        component1: 'arcanedust',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:heavymace',
        upgrade4: 'resource1-4',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 90,
        suggest: 20,
        speedup: 135,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 7,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    summermace: {
        uid: 'summermace',
        level: 9,
        type: 'wm',
        xp: 3950,
        craftXp: 59,
        value: 4600,
        favor: 34,
        time: 480,
        atk: 96,
        def: 0,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 23,
        resource2: 'iron',
        r2Qty: 6,
        resource3: null,
        r3Qty: 0,
        component1: 'webbedwings',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-5',
        upgrade3: 'time*0.75',
        upgrade4: 'resource2-2',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 50,
        surcharge: 115,
        suggest: 25,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-05-19',
        shardPrice: 100,
    },
    heavymace: {
        uid: 'heavymace',
        level: 12,
        type: 'wm',
        xp: 7300,
        craftXp: 110,
        value: 6200,
        favor: 57,
        time: 990,
        atk: 136,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 9,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 37,
        resource2: 'ironwood',
        r2Qty: 7,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 2,
        c1Tags: null,
        component2: 'redpinecone',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-7',
        upgrade3: 'bp:ancienthammer',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-4',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 60,
        surcharge: 130,
        suggest: 25,
        speedup: 260,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    newyearmace: {
        uid: 'newyearmace',
        level: 14,
        type: 'wm',
        xp: 10000,
        craftXp: 150,
        value: 12000,
        favor: 105,
        time: 1590,
        atk: 164,
        def: 41,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 54,
        resource2: 'ironwood',
        r2Qty: 11,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-11',
        upgrade3: 'resource2-3',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 80,
        surcharge: 180,
        suggest: 35,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-12-28',
        shardPrice: 120,
    },
    ancienthammer: {
        uid: 'ancienthammer',
        level: 16,
        type: 'wm',
        xp: 13500,
        craftXp: 203,
        value: 21000,
        favor: 154,
        time: 2400,
        atk: 192,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 8,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 77,
        resource2: 'ironwood',
        r2Qty: 19,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-15',
        upgrade3: 'bp:midnightstar',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 110,
        surcharge: 240,
        suggest: 50,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    troblinmace: {
        uid: 'troblinmace',
        level: 18,
        type: 'wm',
        xp: 17500,
        craftXp: 263,
        value: 40500,
        favor: 408,
        time: 3600,
        atk: 220,
        def: 55,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'wood',
        r1Qty: 101,
        resource2: 'iron',
        r2Qty: 28,
        resource3: 'ironwood',
        r3Qty: 26,
        component1: 'club',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'sharpfang',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'value*1.25',
        upgrade4: 'resource3-21',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-14',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 145,
        surcharge: 325,
        suggest: 65,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-07-13',
        shardPrice: 140,
    },
    midnightstar: {
        uid: 'midnightstar',
        level: 20,
        type: 'wm',
        xp: 22000,
        craftXp: 330,
        value: 61500,
        favor: 392,
        time: 5400,
        atk: 250,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'wood',
        r1Qty: 127,
        resource2: 'ironwood',
        r2Qty: 32,
        resource3: 'steel',
        r3Qty: 9,
        component1: 'morningstar',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'evileye',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-25',
        upgrade3: 'bp:powderkeg',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 180,
        surcharge: 400,
        suggest: 80,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    pyramidmace: {
        uid: 'pyramidmace',
        level: 21,
        type: 'wm',
        xp: 24500,
        craftXp: 368,
        value: 84500,
        favor: 444,
        time: 6600,
        atk: 270,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 7,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'wood',
        r1Qty: 140,
        resource2: 'ironwood',
        r2Qty: 35,
        resource3: null,
        r3Qty: 0,
        component1: 'morningstar',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'starmetal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'value*1.25',
        upgrade4: 'component1-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'flaming',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 210,
        surcharge: 465,
        suggest: 95,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'pyramid',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    powderkeg: {
        uid: 'powderkeg',
        level: 25,
        type: 'wm',
        xp: 35500,
        craftXp: 533,
        value: 185000,
        favor: 771,
        time: 16200,
        atk: 330,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'wood',
        r1Qty: 192,
        resource2: 'ironwood',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 6,
        component1: 'overgrownvine',
        c1Qty: 5,
        c1Tags: null,
        component2: 'livingroots',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-38',
        upgrade3: 'bp:tenderizer',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-8',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 305,
        surcharge: 675,
        suggest: 135,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    pumpkinmace: {
        uid: 'pumpkinmace',
        level: 26,
        type: 'wm',
        xp: 39000,
        craftXp: 585,
        value: 240000,
        favor: 1011,
        time: 19200,
        atk: 350,
        def: 0,
        hp: 11,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 13,
        resource1: 'wood',
        r1Qty: 206,
        resource2: 'ironwood',
        r2Qty: 56,
        resource3: 'mana',
        r3Qty: 8,
        component1: 'overgrownvine',
        c1Qty: 4,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-41',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-28',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 345,
        surcharge: 765,
        suggest: 155,
        speedup: 995,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-10-28',
        shardPrice: 180,
    },
    candyhammer: {
        uid: 'candyhammer',
        level: 26,
        type: 'wm',
        xp: 39000,
        craftXp: 585,
        value: 260000,
        favor: 1011,
        time: 19200,
        atk: 350,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 8,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 13,
        resource1: 'wood',
        r1Qty: 206,
        resource2: 'ironwood',
        r2Qty: 56,
        resource3: 'mana',
        r3Qty: 8,
        component1: 'ancientmarble',
        c1Qty: 5,
        c1Tags: null,
        component2: 'icypearl',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-41',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-5',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 360,
        surcharge: 795,
        suggest: 160,
        speedup: 995,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-12-09',
        shardPrice: 180,
    },
    tenderizer: {
        uid: 'tenderizer',
        level: 27,
        type: 'wm',
        xp: 42000,
        craftXp: 630,
        value: 290000,
        favor: 1048,
        time: 16800,
        atk: 370,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'wood',
        r1Qty: 219,
        resource2: 'ironwood',
        r2Qty: 60,
        resource3: 'mana',
        r3Qty: 10,
        component1: 'ghostflag',
        c1Qty: 4,
        c1Tags: null,
        component2: 'darkscale',
        c2Qty: 10,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-44',
        upgrade3: 'bp:nautilus',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-5',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 375,
        surcharge: 835,
        suggest: 165,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    smithhammer: {
        uid: 'smithhammer',
        level: 28,
        type: 'wm',
        xp: 45500,
        craftXp: 683,
        value: 410000,
        favor: 1358,
        time: 20400,
        atk: 380,
        def: 0,
        hp: 12,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 233,
        resource2: 'ironwood',
        r2Qty: 65,
        resource3: 'mana',
        r3Qty: 12,
        component1: 'sewingthread',
        c1Qty: 3,
        c1Tags: null,
        component2: 'starmetal',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-47',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-13',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-5',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 445,
        surcharge: 990,
        suggest: 200,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-06-18',
        shardPrice: 200,
    },
    freedommace: {
        uid: 'freedommace',
        level: 29,
        type: 'wm',
        xp: 49000,
        craftXp: 735,
        value: 420000,
        favor: 1566,
        time: 24600,
        atk: 400,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'wood',
        r1Qty: 247,
        resource2: 'ironwood',
        r2Qty: 69,
        resource3: 'gems',
        r3Qty: 14,
        component1: 'ectoplasm',
        c1Qty: 5,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-6',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 450,
        surcharge: 1000,
        suggest: 200,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-07-01',
        shardPrice: 200,
    },
    nautilus: {
        uid: 'nautilus',
        level: 30,
        type: 'wm',
        xp: 53000,
        craftXp: 795,
        value: 630000,
        favor: 1625,
        time: 29400,
        atk: 420,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 261,
        resource2: 'ironwood',
        r2Qty: 74,
        resource3: 'mana',
        r3Qty: 17,
        component1: 'deepcoral',
        c1Qty: 5,
        c1Tags: null,
        component2: 'ancientmarble',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-52',
        upgrade3: 'bp:emeraldhammer',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 820,
        surcharge: 1825,
        suggest: 365,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldhammer: {
        uid: 'goldhammer',
        level: 30,
        type: 'wm',
        xp: 53000,
        craftXp: 795,
        value: 1300000,
        favor: 1814,
        time: 29400,
        atk: 420,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 261,
        resource2: 'ironwood',
        r2Qty: 74,
        resource3: 'mana',
        r3Qty: 17,
        component1: 'tabletjewel',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'time*0.7',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 870,
        surcharge: 1935,
        suggest: 385,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-10-31',
        shardPrice: 0,
    },
    mundrahammer: {
        uid: 'mundrahammer',
        level: 31,
        type: 'wm',
        xp: 56500,
        craftXp: 1696,
        value: 670000,
        favor: 2075,
        time: 34800,
        atk: 440,
        def: 0,
        hp: 14,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'moondragon',
        worker2: 'carpenter',
        worker3: 'priest',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 275,
        resource2: 'ironwood',
        r2Qty: 79,
        resource3: 'mana',
        r3Qty: 19,
        component1: 'deepcoral',
        c1Qty: 4,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-16',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: 'dragon',
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 845,
        surcharge: 1880,
        suggest: 375,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    emeraldhammer: {
        uid: 'emeraldhammer',
        level: 33,
        type: 'wm',
        xp: 97500,
        craftXp: 1463,
        value: 1350000,
        favor: 2419,
        time: 50400,
        atk: 540,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 304,
        resource2: 'ironwood',
        r2Qty: 88,
        resource3: 'mana',
        r3Qty: 23,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-18',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1025,
        surcharge: 2280,
        suggest: 455,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    peakhammer: {
        uid: 'peakhammer',
        level: 33,
        type: 'wm',
        xp: 97500,
        craftXp: 1463,
        value: 1550000,
        favor: 2702,
        time: 50400,
        atk: 540,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 304,
        resource2: 'ironwood',
        r2Qty: 88,
        resource3: 'mana',
        r3Qty: 23,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-18',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'sacred',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 1095,
        surcharge: 2435,
        suggest: 485,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'peak',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    naturehammer: {
        uid: 'naturehammer',
        level: 34,
        type: 'wm',
        xp: 105000,
        craftXp: 1575,
        value: 1700000,
        favor: 3077,
        time: 60000,
        atk: 560,
        def: 140,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 319,
        resource2: 'ironwood',
        r2Qty: 93,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'deepcoral',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-64',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-19',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-8',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 1145,
        surcharge: 2545,
        suggest: 510,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-04-20',
        shardPrice: 300,
    },
    atlab_mace: {
        uid: 'atlab_mace',
        level: 34,
        type: 'wm',
        xp: 105000,
        craftXp: 1575,
        value: 1800000,
        favor: 3077,
        time: 60000,
        atk: 560,
        def: 0,
        hp: 18,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 319,
        resource2: 'ironwood',
        r2Qty: 93,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-64',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-19',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'resource3-10',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1175,
        surcharge: 2615,
        suggest: 525,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 1,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-16',
        shardPrice: 300,
    },
    woodspear: {
        uid: 'woodspear',
        level: 3,
        type: 'wp',
        xp: 520,
        craftXp: 8,
        value: 190,
        favor: 10,
        time: 70,
        atk: 30,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:ironspear',
        upgrade3: 'resource1-2',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.5',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 25,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ironspear: {
        uid: 'ironspear',
        level: 5,
        type: 'wp',
        xp: 1400,
        craftXp: 21,
        value: 520,
        favor: 16,
        time: 160,
        atk: 50,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 14,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'resource1-3',
        upgrade3: 'bp:bladedspear',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 40,
        suggest: 10,
        speedup: 70,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    bladedspear: {
        uid: 'bladedspear',
        level: 8,
        type: 'wp',
        xp: 3650,
        craftXp: 55,
        value: 1400,
        favor: 27,
        time: 390,
        atk: 84,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 5,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 21,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:ranseur',
        upgrade4: 'resource1-4',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 30,
        surcharge: 65,
        suggest: 15,
        speedup: 135,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    pitchfork: {
        uid: 'pitchfork',
        level: 9,
        type: 'wp',
        xp: 4650,
        craftXp: 70,
        value: 2400,
        favor: 39,
        time: 520,
        atk: 96,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 26,
        resource2: 'iron',
        r2Qty: 13,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.8',
        upgrade3: 'resource1-5',
        upgrade4: 'time*0.8',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource2-13',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 85,
        suggest: 15,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-09-24',
        shardPrice: 75,
    },
    ranseur: {
        uid: 'ranseur',
        level: 11,
        type: 'wp',
        xp: 7150,
        craftXp: 107,
        value: 4850,
        favor: 50,
        time: 750,
        atk: 124,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 8,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 34,
        resource2: 'iron',
        r2Qty: 17,
        resource3: null,
        r3Qty: 0,
        component1: 'ironspear',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'arcanedust',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:trident',
        upgrade4: 'resource1-7',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-3',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 55,
        surcharge: 120,
        suggest: 25,
        speedup: 225,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    trident: {
        uid: 'trident',
        level: 14,
        type: 'wp',
        xp: 12000,
        craftXp: 180,
        value: 11500,
        favor: 106,
        time: 1710,
        atk: 164,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 60,
        resource2: 'ironwood',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-12',
        upgrade3: 'bp:pike',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 80,
        surcharge: 180,
        suggest: 35,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    heavenlyhalberd: {
        uid: 'heavenlyhalberd',
        level: 16,
        type: 'wp',
        xp: 16000,
        craftXp: 240,
        value: 23500,
        favor: 297,
        time: 2700,
        atk: 192,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 8,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 85,
        resource2: 'ironwood',
        r2Qty: 21,
        resource3: 'leather',
        r3Qty: 21,
        component1: 'livingroots',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-17',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-4',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-21',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 115,
        surcharge: 250,
        suggest: 50,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-01-22',
        shardPrice: 120,
    },
    pike: {
        uid: 'pike',
        level: 17,
        type: 'wp',
        xp: 18500,
        craftXp: 278,
        value: 29000,
        favor: 216,
        time: 3300,
        atk: 210,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 10,
        w3BuildingReq: 4,
        resource1: 'wood',
        r1Qty: 99,
        resource2: 'ironwood',
        r2Qty: 25,
        resource3: null,
        r3Qty: 0,
        component1: 'sharpfang',
        c1Qty: 4,
        c1Tags: null,
        component2: 'redpinecone',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:partisan',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 125,
        surcharge: 275,
        suggest: 55,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 16,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    desertspear: {
        uid: 'desertspear',
        level: 18,
        type: 'wp',
        xp: 20500,
        craftXp: 308,
        value: 34500,
        favor: 296,
        time: 3600,
        atk: 220,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 6,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'wood',
        r1Qty: 113,
        resource2: 'ironwood',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'smallmanapotion',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'mooncrystal',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-3',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'tidal',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 135,
        surcharge: 300,
        suggest: 60,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: 'desert',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    celticspear: {
        uid: 'celticspear',
        level: 19,
        type: 'wp',
        xp: 23000,
        craftXp: 345,
        value: 54000,
        favor: 559,
        time: 4800,
        atk: 240,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 16,
        w2BuildingReq: 12,
        w3BuildingReq: 6,
        resource1: 'wood',
        r1Qty: 127,
        resource2: 'ironwood',
        r2Qty: 33,
        resource3: 'leather',
        r3Qty: 32,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: 'livingroots',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-25',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-7',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-32',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 170,
        surcharge: 375,
        suggest: 75,
        speedup: 565,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-03-09',
        shardPrice: 140,
    },
    partisan: {
        uid: 'partisan',
        level: 21,
        type: 'wp',
        xp: 29000,
        craftXp: 435,
        value: 90000,
        favor: 518,
        time: 7200,
        atk: 270,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'wood',
        r1Qty: 155,
        resource2: 'ironwood',
        r2Qty: 39,
        resource3: 'oils',
        r3Qty: 10,
        component1: 'ironspear',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'starmetal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'bp:championlance',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 215,
        surcharge: 480,
        suggest: 95,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    yetispear: {
        uid: 'yetispear',
        level: 22,
        type: 'wp',
        xp: 32000,
        craftXp: 480,
        value: 120000,
        favor: 591,
        time: 9600,
        atk: 280,
        def: 70,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 19,
        w2BuildingReq: 15,
        w3BuildingReq: 9,
        resource1: 'wood',
        r1Qty: 170,
        resource2: 'ironwood',
        r2Qty: 43,
        resource3: null,
        r3Qty: 0,
        component1: 'evileye',
        c1Qty: 4,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-34',
        upgrade3: 'resource2-9',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-9',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 250,
        surcharge: 550,
        suggest: 110,
        speedup: 735,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-08-10',
        shardPrice: 160,
    },
    championlance: {
        uid: 'championlance',
        level: 24,
        type: 'wp',
        xp: 38500,
        craftXp: 578,
        value: 180000,
        favor: 771,
        time: 13800,
        atk: 320,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'wood',
        r1Qty: 199,
        resource2: 'ironwood',
        r2Qty: 51,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'timecrystal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'whitesand',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:wyvernglaive',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-5',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 300,
        surcharge: 665,
        suggest: 135,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    wyvernglaive: {
        uid: 'wyvernglaive',
        level: 27,
        type: 'wp',
        xp: 49500,
        craftXp: 743,
        value: 290000,
        favor: 1224,
        time: 18000,
        atk: 370,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'wood',
        r1Qty: 244,
        resource2: 'ironwood',
        r2Qty: 67,
        resource3: 'gems',
        r3Qty: 13,
        component1: 'cutlass',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'ghostflag',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:stellarspear',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'cquality1-1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 375,
        surcharge: 835,
        suggest: 165,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    sylvanspear: {
        uid: 'sylvanspear',
        level: 28,
        type: 'wp',
        xp: 54000,
        craftXp: 810,
        value: 420000,
        favor: 1589,
        time: 22200,
        atk: 380,
        def: 0,
        hp: 12,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 259,
        resource2: 'ironwood',
        r2Qty: 72,
        resource3: 'mana',
        r3Qty: 16,
        component1: 'ancientmarble',
        c1Qty: 4,
        c1Tags: null,
        component2: 'overgrownvine',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'walrus',
        tag: null,
        discount: 450,
        surcharge: 1000,
        suggest: 200,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-07-14',
        shardPrice: 200,
    },
    goldspear: {
        uid: 'goldspear',
        level: 28,
        type: 'wp',
        xp: 54000,
        craftXp: 810,
        value: 600000,
        favor: 1589,
        time: 22200,
        atk: 380,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 259,
        resource2: 'ironwood',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'tabletfragment',
        c1Qty: 5,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 400,
        surcharge: 890,
        suggest: 180,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-06-13',
        shardPrice: 0,
    },
    stellarspear: {
        uid: 'stellarspear',
        level: 30,
        type: 'wp',
        xp: 62500,
        craftXp: 938,
        value: 605000,
        favor: 1909,
        time: 31800,
        atk: 420,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 290,
        resource2: 'ironwood',
        r2Qty: 82,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'sirenshard',
        c1Qty: 4,
        c1Tags: null,
        component2: 'timecrystal',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:feyspear',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 805,
        surcharge: 1790,
        suggest: 360,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    templespear: {
        uid: 'templespear',
        level: 30,
        type: 'wp',
        xp: 62500,
        craftXp: 938,
        value: 655000,
        favor: 2128,
        time: 31200,
        atk: 420,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 290,
        resource2: 'ironwood',
        r2Qty: 82,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'sunpendant',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'goldshell',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-16',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'sacred',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 835,
        surcharge: 1855,
        suggest: 370,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: 'temple',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    thanksgivingspear: {
        uid: 'thanksgivingspear',
        level: 31,
        type: 'wp',
        xp: 67000,
        craftXp: 1005,
        value: 675000,
        favor: 2451,
        time: 37800,
        atk: 440,
        def: 110,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 306,
        resource2: 'ironwood',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-8',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 850,
        surcharge: 1885,
        suggest: 375,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-11-18',
        shardPrice: 250,
    },
    feyspear: {
        uid: 'feyspear',
        level: 33,
        type: 'wp',
        xp: 115000,
        craftXp: 1725,
        value: 1200000,
        favor: 2889,
        time: 54000,
        atk: 540,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 338,
        resource2: 'ironwood',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-9',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 970,
        surcharge: 2155,
        suggest: 430,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    circusspear: {
        uid: 'circusspear',
        level: 33,
        type: 'wp',
        xp: 115000,
        craftXp: 1725,
        value: 1600000,
        favor: 3218,
        time: 54000,
        atk: 540,
        def: 0,
        hp: 17,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 338,
        resource2: 'ironwood',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'ghostflag',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-9',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1110,
        surcharge: 2470,
        suggest: 495,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-06-16',
        shardPrice: 300,
    },
    christmasspear: {
        uid: 'christmasspear',
        level: 34,
        type: 'wp',
        xp: 125000,
        craftXp: 1875,
        value: 1800000,
        favor: 3671,
        time: 64800,
        atk: 560,
        def: 140,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: 'master',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 354,
        resource2: 'ironwood',
        r2Qty: 103,
        resource3: 'gems',
        r3Qty: 34,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'goldshell',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-71',
        upgrade3: 'time*0.8',
        upgrade4: 'resource2-31',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'resource3-10',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 1175,
        surcharge: 2615,
        suggest: 525,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2021-12-14',
        shardPrice: 300,
    },
    shortbow: {
        uid: 'shortbow',
        level: 2,
        type: 'wb',
        xp: 265,
        craftXp: 4,
        value: 220,
        favor: 1,
        time: 40,
        atk: 22,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 7,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'component1-1',
        upgrade2: 'bp:longbow',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-5',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 25,
        suggest: 5,
        speedup: 25,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    forestbow: {
        uid: 'forestbow',
        level: 3,
        type: 'wb',
        xp: 495,
        craftXp: 7,
        value: 410,
        favor: 11,
        time: 70,
        atk: 32,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 1,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-2',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'quality+1',
        supgrade2: 'multi+0.2',
        supgrade3: 'component1-1',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'breeze',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 15,
        surcharge: 35,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: 'forest',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    longbow: {
        uid: 'longbow',
        level: 4,
        type: 'wb',
        xp: 840,
        craftXp: 13,
        value: 290,
        favor: 13,
        time: 100,
        atk: 42,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 12,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:hunterbow',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-9',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 15,
        surcharge: 30,
        suggest: 5,
        speedup: 55,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    hunterbow: {
        uid: 'hunterbow',
        level: 7,
        type: 'wb',
        xp: 2600,
        craftXp: 39,
        value: 1150,
        favor: 21,
        time: 290,
        atk: 76,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 17,
        resource2: 'herbs',
        r2Qty: 4,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:strikerbow',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 60,
        suggest: 10,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    siabow: {
        uid: 'siabow',
        level: 9,
        type: 'wb',
        xp: 4400,
        craftXp: 66,
        value: 2350,
        favor: 39,
        time: 500,
        atk: 104,
        def: 0,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 26,
        resource2: 'leather',
        r2Qty: 13,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-5',
        upgrade4: 'resource1-5',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource2-13',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 85,
        suggest: 15,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-07-02',
        shardPrice: 75,
    },
    strikerbow: {
        uid: 'strikerbow',
        level: 10,
        type: 'wb',
        xp: 5500,
        craftXp: 83,
        value: 2800,
        favor: 42,
        time: 660,
        atk: 116,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 7,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 30,
        resource2: 'leather',
        r2Qty: 15,
        resource3: null,
        r3Qty: 0,
        component1: 'webbedwings',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:spikedbow',
        upgrade4: 'resource1-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 90,
        suggest: 20,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    mundrabow: {
        uid: 'mundrabow',
        level: 12,
        type: 'wb',
        xp: 8150,
        craftXp: 244,
        value: 9700,
        favor: 71,
        time: 1050,
        atk: 144,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'moondragon',
        worker2: null,
        worker3: null,
        w1BuildingReq: 9,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 41,
        resource2: 'ironwood',
        r2Qty: 8,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.8',
        upgrade2: 'value*1.25',
        upgrade3: 'bp:mundraarmor',
        upgrade4: 'resource1-8',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: 'dragon',
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 75,
        surcharge: 165,
        suggest: 35,
        speedup: 260,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    spikedbow: {
        uid: 'spikedbow',
        level: 13,
        type: 'wb',
        xp: 9650,
        craftXp: 145,
        value: 8300,
        favor: 81,
        time: 1290,
        atk: 160,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 49,
        resource2: 'ironwood',
        r2Qty: 10,
        resource3: null,
        r3Qty: 0,
        component1: 'longbow',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'livingroots',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'value*1.25',
        upgrade3: 'bp:wingedbow',
        upgrade4: 'resource1-10',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 150,
        suggest: 30,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    halloweenbow: {
        uid: 'halloweenbow',
        level: 15,
        type: 'wb',
        xp: 13000,
        craftXp: 195,
        value: 13500,
        favor: 150,
        time: 2040,
        atk: 188,
        def: 47,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 71,
        resource2: 'ironwood',
        r2Qty: 16,
        resource3: null,
        r3Qty: 0,
        component1: 'livingroots',
        c1Qty: 1,
        c1Tags: null,
        component2: 'webbedwings',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'value*1.25',
        upgrade3: 'resource1-14',
        upgrade4: 'resource2-3',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 85,
        surcharge: 190,
        suggest: 40,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-10-19',
        shardPrice: 120,
    },
    wingedbow: {
        uid: 'wingedbow',
        level: 17,
        type: 'wb',
        xp: 17000,
        craftXp: 255,
        value: 27000,
        favor: 216,
        time: 3300,
        atk: 220,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 1,
        w3BuildingReq: 4,
        resource1: 'wood',
        r1Qty: 99,
        resource2: 'ironwood',
        r2Qty: 25,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: 'glowingmushrooms',
        c2Qty: 7,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:compositebow',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-5',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'eagle',
        tag: null,
        discount: 120,
        surcharge: 270,
        suggest: 55,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    desertbow: {
        uid: 'desertbow',
        level: 18,
        type: 'wb',
        xp: 19500,
        craftXp: 293,
        value: 38500,
        favor: 296,
        time: 3600,
        atk: 230,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 6,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'wood',
        r1Qty: 113,
        resource2: 'ironwood',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'studdedleathergloves',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'sharpfang',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-3',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'gale',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'eagle',
        tag: 'elemental',
        discount: 145,
        surcharge: 320,
        suggest: 65,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: 'desert',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    compositebow: {
        uid: 'compositebow',
        level: 21,
        type: 'wb',
        xp: 27000,
        craftXp: 405,
        value: 72500,
        favor: 732,
        time: 7200,
        atk: 280,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'wood',
        r1Qty: 155,
        resource2: 'ironwood',
        r2Qty: 39,
        resource3: 'herbs',
        r3Qty: 39,
        component1: 'silkscarab',
        c1Qty: 4,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 1,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'bp:yumi',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 195,
        surcharge: 430,
        suggest: 85,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    yumi: {
        uid: 'yumi',
        level: 25,
        type: 'wb',
        xp: 39500,
        craftXp: 593,
        value: 190000,
        favor: 876,
        time: 15600,
        atk: 350,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'wood',
        r1Qty: 214,
        resource2: 'ironwood',
        r2Qty: 57,
        resource3: 'gems',
        r3Qty: 6,
        component1: 'oakstaff',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'overgrownvine',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'bp:bramblebane',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'cquality1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 310,
        surcharge: 685,
        suggest: 135,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    valentinebow: {
        uid: 'valentinebow',
        level: 26,
        type: 'wb',
        xp: 43000,
        craftXp: 645,
        value: 245000,
        favor: 1146,
        time: 20400,
        atk: 370,
        def: 0,
        hp: 12,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 13,
        resource1: 'wood',
        r1Qty: 229,
        resource2: 'ironwood',
        r2Qty: 62,
        resource3: 'gems',
        r3Qty: 8,
        component1: 'timecrystal',
        c1Qty: 3,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-46',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-12',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-6',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 345,
        surcharge: 770,
        suggest: 155,
        speedup: 995,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-02-07',
        shardPrice: 180,
    },
    bramblebane: {
        uid: 'bramblebane',
        level: 27,
        type: 'wb',
        xp: 46500,
        craftXp: 698,
        value: 305000,
        favor: 1190,
        time: 16200,
        atk: 390,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'wood',
        r1Qty: 244,
        resource2: 'ironwood',
        r2Qty: 67,
        resource3: 'gems',
        r3Qty: 10,
        component1: 'bloodvine',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'ectoplasm',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:twinstrand',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 385,
        surcharge: 860,
        suggest: 170,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    fallbow: {
        uid: 'fallbow',
        level: 28,
        type: 'wb',
        xp: 50500,
        craftXp: 758,
        value: 425000,
        favor: 1538,
        time: 21600,
        atk: 410,
        def: 103,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 259,
        resource2: 'ironwood',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 12,
        component1: 'ghostflag',
        c1Qty: 4,
        c1Tags: null,
        component2: 'webbedwings',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 450,
        surcharge: 1005,
        suggest: 200,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-09-08',
        shardPrice: 200,
    },
    twinstrand: {
        uid: 'twinstrand',
        level: 30,
        type: 'wb',
        xp: 58500,
        craftXp: 878,
        value: 630000,
        favor: 1835,
        time: 30600,
        atk: 450,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 290,
        resource2: 'ironwood',
        r2Qty: 82,
        resource3: 'gems',
        r3Qty: 17,
        component1: 'deepcoral',
        c1Qty: 4,
        c1Tags: null,
        component2: 'sewingthread',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:stormrend',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 820,
        surcharge: 1825,
        suggest: 365,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    christmasbow: {
        uid: 'christmasbow',
        level: 31,
        type: 'wb',
        xp: 63000,
        craftXp: 945,
        value: 655000,
        favor: 2352,
        time: 36600,
        atk: 470,
        def: 0,
        hp: 15,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 306,
        resource2: 'ironwood',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 19,
        component1: 'sirenshard',
        c1Qty: 4,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 835,
        surcharge: 1855,
        suggest: 370,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2020-12-15',
        shardPrice: 250,
    },
    stormrend: {
        uid: 'stormrend',
        level: 34,
        type: 'wb',
        xp: 115000,
        craftXp: 1725,
        value: 1500000,
        favor: 3109,
        time: 63000,
        atk: 600,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 354,
        resource2: 'ironwood',
        r2Qty: 103,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-71',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-21',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-8',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 1080,
        surcharge: 2395,
        suggest: 480,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldbow: {
        uid: 'goldbow',
        level: 34,
        type: 'wb',
        xp: 115000,
        craftXp: 1725,
        value: 2900000,
        favor: 3479,
        time: 63000,
        atk: 600,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 354,
        resource2: 'ironwood',
        r2Qty: 103,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'tabletjewel',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-71',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-21',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-8',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 1110,
        surcharge: 2470,
        suggest: 495,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-04-17',
        shardPrice: 0,
    },
    cincobow: {
        uid: 'cincobow',
        level: 34,
        type: 'wb',
        xp: 115000,
        craftXp: 1725,
        value: 1750000,
        favor: 3458,
        time: 56400,
        atk: 600,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 354,
        resource2: 'ironwood',
        r2Qty: 103,
        resource3: 'mana',
        r3Qty: 24,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'yumi',
        c2Qty: 1,
        c2Tags: 'common',
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-71',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-21',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1160,
        surcharge: 2580,
        suggest: 515,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-05-04',
        shardPrice: 300,
    },
    newyearbow: {
        uid: 'newyearbow',
        level: 35,
        type: 'wb',
        xp: 125000,
        craftXp: 1875,
        value: 1900000,
        favor: 3494,
        time: 75600,
        atk: 620,
        def: 155,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'carpenter',
        w1BuildingReq: 28,
        w2BuildingReq: 24,
        w3BuildingReq: 21,
        resource1: 'iron',
        r1Qty: 334,
        resource2: 'steel',
        r2Qty: 97,
        resource3: 'gems',
        r3Qty: 27,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'goldshell',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'resource1-67',
        upgrade2: 'resource2-29',
        upgrade3: 'time*0.8',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1210,
        surcharge: 2685,
        suggest: 535,
        speedup: 3120,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-12-28',
        shardPrice: 300,
    },
    frogbow: {
        uid: 'frogbow',
        level: 35,
        type: 'wb',
        xp: 125000,
        craftXp: 1875,
        value: 1950000,
        favor: 3957,
        time: 75600,
        atk: 620,
        def: 0,
        hp: 20,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 28,
        w2BuildingReq: 24,
        w3BuildingReq: 21,
        resource1: 'wood',
        r1Qty: 371,
        resource2: 'ironwood',
        r2Qty: 108,
        resource3: 'gems',
        r3Qty: 27,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-74',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-22',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-8',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 1225,
        surcharge: 2720,
        suggest: 545,
        speedup: 3120,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-01-12',
        shardPrice: 300,
    },
    woodbranch: {
        uid: 'woodbranch',
        level: 1,
        type: 'wt',
        xp: 140,
        craftXp: 2,
        value: 60,
        favor: 1,
        time: 15,
        atk: 16,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:shortbow',
        upgrade2: 'bp:cane',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-4',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 15,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    cane: {
        uid: 'cane',
        level: 3,
        type: 'wt',
        xp: 540,
        craftXp: 8,
        value: 200,
        favor: 10,
        time: 70,
        atk: 32,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'bp:oakstaff',
        upgrade2: 'bp:woodspear',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-7',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 25,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    oakstaff: {
        uid: 'oakstaff',
        level: 5,
        type: 'wt',
        xp: 1450,
        craftXp: 22,
        value: 710,
        favor: 16,
        time: 160,
        atk: 52,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 14,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 6,
        u2Req: 16,
        u3Req: 28,
        u4Req: 45,
        u5Req: 70,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:bo',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 45,
        suggest: 10,
        speedup: 70,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lilustaff: {
        uid: 'lilustaff',
        level: 8,
        type: 'wt',
        xp: 3800,
        craftXp: 57,
        value: 1950,
        favor: 30,
        time: 400,
        atk: 88,
        def: 0,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 21,
        resource2: 'herbs',
        r2Qty: 11,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'value*1.25',
        upgrade4: 'resource1-4',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource2-11',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'owl',
        tag: null,
        discount: 35,
        surcharge: 75,
        suggest: 15,
        speedup: 135,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-07-02',
        shardPrice: 75,
    },
    goldstaff: {
        uid: 'goldstaff',
        level: 8,
        type: 'wt',
        xp: 3800,
        craftXp: 57,
        value: 10000,
        favor: 30,
        time: 400,
        atk: 88,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 3,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 5,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 21,
        resource2: 'herbs',
        r2Qty: 11,
        resource3: null,
        r3Qty: 0,
        component1: 'tabletfragment',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-4',
        upgrade3: 'resource2-2',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.80',
        supgrade2: 'resource1-2',
        supgrade3: 'multi+0.1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 55,
        surcharge: 125,
        suggest: 25,
        speedup: 135,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-04-18',
        shardPrice: 0,
    },
    bo: {
        uid: 'bo',
        level: 9,
        type: 'wt',
        xp: 4850,
        craftXp: 73,
        value: 2800,
        favor: 35,
        time: 490,
        atk: 104,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 26,
        resource2: 'iron',
        r2Qty: 6,
        resource3: null,
        r3Qty: 0,
        component1: 'cane',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'arcanedust',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:scepter',
        upgrade4: 'resource1-5',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 90,
        suggest: 20,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    starterstaff: {
        uid: 'starterstaff',
        level: 11,
        type: 'wt',
        xp: 7450,
        craftXp: 112,
        value: 4800,
        favor: 56,
        time: 870,
        atk: 128,
        def: 32,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 34,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'value*1.25',
        upgrade4: 'resource1-7',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 50,
        surcharge: 115,
        suggest: 25,
        speedup: 225,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 100,
    },
    scepter: {
        uid: 'scepter',
        level: 13,
        type: 'wt',
        xp: 10500,
        craftXp: 158,
        value: 9500,
        favor: 81,
        time: 1380,
        atk: 160,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 49,
        resource2: 'ironwood',
        r2Qty: 10,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-10',
        upgrade3: 'bp:wizardstaff',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 160,
        suggest: 30,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    swampstaff: {
        uid: 'swampstaff',
        level: 14,
        type: 'wt',
        xp: 12500,
        craftXp: 188,
        value: 13000,
        favor: 119,
        time: 1740,
        atk: 172,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 5,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 60,
        resource2: 'ironwood',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'livingroots',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-12',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-2',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'wild',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 85,
        surcharge: 190,
        suggest: 40,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: 'swamp',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    wiccanstaff: {
        uid: 'wiccanstaff',
        level: 14,
        type: 'wt',
        xp: 12500,
        craftXp: 188,
        value: 18500,
        favor: 119,
        time: 1740,
        atk: 172,
        def: 0,
        hp: 5,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 60,
        resource2: 'ironwood',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'livingroots',
        c1Qty: 4,
        c1Tags: null,
        component2: 'elvenwood',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-12',
        upgrade3: 'component2-1',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 100,
        surcharge: 225,
        suggest: 45,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-03-08',
        shardPrice: 120,
    },
    wizardstaff: {
        uid: 'wizardstaff',
        level: 17,
        type: 'wt',
        xp: 19000,
        craftXp: 285,
        value: 32500,
        favor: 216,
        time: 3300,
        atk: 220,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 10,
        w3BuildingReq: 4,
        resource1: 'wood',
        r1Qty: 99,
        resource2: 'ironwood',
        r2Qty: 25,
        resource3: null,
        r3Qty: 0,
        component1: 'mooncrystal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:celestialrod',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 135,
        surcharge: 295,
        suggest: 60,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 18,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    leafstaff: {
        uid: 'leafstaff',
        level: 18,
        type: 'wt',
        xp: 21500,
        craftXp: 323,
        value: 45000,
        favor: 296,
        time: 4200,
        atk: 230,
        def: 0,
        hp: 0,
        eva: '0.05',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'wood',
        r1Qty: 113,
        resource2: 'ironwood',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 3,
        c1Tags: null,
        component2: 'livingroots',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 12,
        u2Req: 30,
        u3Req: 54,
        u4Req: 85,
        u5Req: 135,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource1-23',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 155,
        surcharge: 340,
        suggest: 70,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-11-19',
        shardPrice: 140,
    },
    celestialrod: {
        uid: 'celestialrod',
        level: 20,
        type: 'wt',
        xp: 27000,
        craftXp: 405,
        value: 60500,
        favor: 498,
        time: 6600,
        atk: 270,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'wood',
        r1Qty: 141,
        resource2: 'ironwood',
        r2Qty: 36,
        resource3: 'oils',
        r3Qty: 18,
        component1: 'evileye',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:imperialaquila',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 180,
        surcharge: 395,
        suggest: 80,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    mundrastaff: {
        uid: 'mundrastaff',
        level: 22,
        type: 'wt',
        xp: 33000,
        craftXp: 990,
        value: 77000,
        favor: 765,
        time: 9600,
        atk: 300,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.05',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'moondragon',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 19,
        w2BuildingReq: 15,
        w3BuildingReq: 9,
        resource1: 'wood',
        r1Qty: 170,
        resource2: 'ironwood',
        r2Qty: 43,
        resource3: 'oils',
        r3Qty: 22,
        component1: 'mooncrystal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'arcanedust',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 12,
        u2Req: 30,
        u3Req: 54,
        u4Req: 85,
        u5Req: 135,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-34',
        upgrade3: 'bp:mundrahammer',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-9',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: 'dragon',
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 200,
        surcharge: 445,
        suggest: 90,
        speedup: 735,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    imperialaquila: {
        uid: 'imperialaquila',
        level: 24,
        type: 'wt',
        xp: 40000,
        craftXp: 600,
        value: 180000,
        favor: 771,
        time: 14400,
        atk: 340,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'wood',
        r1Qty: 199,
        resource2: 'ironwood',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 7,
        component1: 'timecrystal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:transcendance',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-10',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'eagle',
        tag: null,
        discount: 300,
        surcharge: 665,
        suggest: 135,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ruinsstaff: {
        uid: 'ruinsstaff',
        level: 25,
        type: 'wt',
        xp: 44000,
        craftXp: 660,
        value: 210000,
        favor: 1001,
        time: 15000,
        atk: 350,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 8,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'wood',
        r1Qty: 214,
        resource2: 'ironwood',
        r2Qty: 57,
        resource3: 'mana',
        r3Qty: 8,
        component1: 'circlet',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'timecrystal',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'cquality1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'flaming',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'eagle',
        tag: 'elemental',
        discount: 320,
        surcharge: 715,
        suggest: 145,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: 'ruins',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    transcendance: {
        uid: 'transcendance',
        level: 27,
        type: 'wt',
        xp: 51500,
        craftXp: 773,
        value: 300000,
        favor: 1224,
        time: 18000,
        atk: 390,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'wood',
        r1Qty: 244,
        resource2: 'ironwood',
        r2Qty: 67,
        resource3: 'mana',
        r3Qty: 13,
        component1: 'doublering',
        c1Qty: 4,
        c1Tags: 'common',
        component2: 'ghostflag',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:conflux',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-2',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 385,
        surcharge: 850,
        suggest: 170,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    castlestaff: {
        uid: 'castlestaff',
        level: 28,
        type: 'wt',
        xp: 56000,
        craftXp: 840,
        value: 365000,
        favor: 1589,
        time: 22200,
        atk: 410,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 259,
        resource2: 'ironwood',
        r2Qty: 72,
        resource3: 'mana',
        r3Qty: 16,
        component1: 'ectoplasm',
        c1Qty: 4,
        c1Tags: null,
        component2: 'starmetal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'flood',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 420,
        surcharge: 935,
        suggest: 185,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: 'castle',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lemonstaff: {
        uid: 'lemonstaff',
        level: 28,
        type: 'wt',
        xp: 56000,
        craftXp: 840,
        value: 430000,
        favor: 1589,
        time: 22200,
        atk: 410,
        def: 103,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 259,
        resource2: 'ironwood',
        r2Qty: 72,
        resource3: 'mana',
        r3Qty: 16,
        component1: 'sewingthread',
        c1Qty: 4,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 455,
        surcharge: 1010,
        suggest: 200,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-05-19',
        shardPrice: 200,
    },
    conflux: {
        uid: 'conflux',
        level: 30,
        type: 'wt',
        xp: 65000,
        craftXp: 975,
        value: 645000,
        favor: 1909,
        time: 32400,
        atk: 450,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 290,
        resource2: 'ironwood',
        r2Qty: 82,
        resource3: 'mana',
        r3Qty: 22,
        component1: 'sirenshard',
        c1Qty: 5,
        c1Tags: null,
        component2: 'ancientmarble',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:penumbra',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 830,
        surcharge: 1845,
        suggest: 370,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    christmasstaff: {
        uid: 'christmasstaff',
        level: 31,
        type: 'wt',
        xp: 69500,
        craftXp: 1043,
        value: 675000,
        favor: 2451,
        time: 36600,
        atk: 470,
        def: 118,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 306,
        resource2: 'ironwood',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'wizardstaff',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'deepcoral',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 850,
        surcharge: 1885,
        suggest: 375,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2020-12-16',
        shardPrice: 250,
    },
    penumbra: {
        uid: 'penumbra',
        level: 33,
        type: 'wt',
        xp: 120000,
        craftXp: 1800,
        value: 1400000,
        favor: 2889,
        time: 55200,
        atk: 580,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 338,
        resource2: 'ironwood',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1045,
        surcharge: 2320,
        suggest: 465,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    yetistaff: {
        uid: 'yetistaff',
        level: 33,
        type: 'wt',
        xp: 120000,
        craftXp: 1800,
        value: 1650000,
        favor: 2812,
        time: 55200,
        atk: 580,
        def: 0,
        hp: 18,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 300,
        resource2: 'steel',
        r2Qty: 90,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-60',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-18',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-9',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 1130,
        surcharge: 2510,
        suggest: 500,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-08-10',
        shardPrice: 300,
    },
    valentinestaff: {
        uid: 'valentinestaff',
        level: 34,
        type: 'wt',
        xp: 130000,
        craftXp: 1950,
        value: 1800000,
        favor: 3420,
        time: 66000,
        atk: 600,
        def: 150,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 338,
        resource2: 'ironwood',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'value*1.25',
        upgrade3: 'resource1-68',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1175,
        surcharge: 2615,
        suggest: 525,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-02-08',
        shardPrice: 300,
    },
    baton: {
        uid: 'baton',
        level: 3,
        type: 'ww',
        xp: 785,
        craftXp: 12,
        value: 350,
        favor: 11,
        time: 80,
        atk: 36,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 1,
        combo: 9,
        worker1: 'academy',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-2',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-5',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 15,
        surcharge: 35,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    twirled: {
        uid: 'twirled',
        level: 6,
        type: 'ww',
        xp: 3200,
        craftXp: 48,
        value: 1400,
        favor: 21,
        time: 270,
        atk: 74,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 2,
        combo: 9,
        worker1: 'academy',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 16,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-3',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 30,
        surcharge: 65,
        suggest: 15,
        speedup: 90,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    backscratcher: {
        uid: 'backscratcher',
        level: 9,
        type: 'ww',
        xp: 7500,
        craftXp: 113,
        value: 3250,
        favor: 39,
        time: 630,
        atk: 120,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'academy',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 26,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:steelrod',
        upgrade4: 'resource1-5',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'quality+2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-4',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 45,
        surcharge: 95,
        suggest: 20,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    steelrod: {
        uid: 'steelrod',
        level: 11,
        type: 'ww',
        xp: 11500,
        craftXp: 173,
        value: 7650,
        favor: 56,
        time: 1050,
        atk: 152,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'academy',
        worker2: null,
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 34,
        resource2: 'iron',
        r2Qty: 9,
        resource3: null,
        r3Qty: 0,
        component1: 'ironcap',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'arcanedust',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:starrod',
        upgrade4: 'resource1-7',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 65,
        surcharge: 145,
        suggest: 30,
        speedup: 225,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    starrod: {
        uid: 'starrod',
        level: 14,
        type: 'ww',
        xp: 19500,
        craftXp: 293,
        value: 18500,
        favor: 156,
        time: 2160,
        atk: 200,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'academy',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 60,
        resource2: 'ironwood',
        r2Qty: 12,
        resource3: 'oils',
        r3Qty: 9,
        component1: 'icypearl',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-12',
        upgrade3: 'bp:sylvan',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 100,
        surcharge: 225,
        suggest: 45,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    christmaswand: {
        uid: 'christmaswand',
        level: 15,
        type: 'ww',
        xp: 22500,
        craftXp: 338,
        value: 20000,
        favor: 204,
        time: 2700,
        atk: 220,
        def: 55,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'academy',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 71,
        resource2: 'ironwood',
        r2Qty: 16,
        resource3: 'oils',
        r3Qty: 12,
        component1: 'antidote',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'livingroots',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-14',
        upgrade3: 'value*1.25',
        upgrade4: 'component1-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 105,
        surcharge: 230,
        suggest: 45,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2020-12-16',
        shardPrice: 120,
    },
    sylvan: {
        uid: 'sylvan',
        level: 17,
        type: 'ww',
        xp: 29500,
        craftXp: 443,
        value: 53500,
        favor: 376,
        time: 4200,
        atk: 260,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 8,
        worker1: 'academy',
        worker2: 'herbalist',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 10,
        w3BuildingReq: 4,
        resource1: 'wood',
        r1Qty: 99,
        resource2: 'herbs',
        r2Qty: 25,
        resource3: 'ironwood',
        r3Qty: 25,
        component1: 'mooncrystal',
        c1Qty: 5,
        c1Tags: null,
        component2: 'elvenwood',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:rubyrod',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-6',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 165,
        surcharge: 370,
        suggest: 75,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    rubyrod: {
        uid: 'rubyrod',
        level: 20,
        type: 'ww',
        xp: 42000,
        craftXp: 630,
        value: 100000,
        favor: 485,
        time: 7800,
        atk: 330,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'academy',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'wood',
        r1Qty: 141,
        resource2: 'ironwood',
        r2Qty: 36,
        resource3: 'steel',
        r3Qty: 9,
        component1: 'evileye',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:goldwand',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 225,
        surcharge: 500,
        suggest: 100,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    liluwand: {
        uid: 'liluwand',
        level: 21,
        type: 'ww',
        xp: 46500,
        craftXp: 698,
        value: 105000,
        favor: 574,
        time: 9600,
        atk: 350,
        def: 0,
        hp: 11,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'academy',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'wood',
        r1Qty: 155,
        resource2: 'ironwood',
        r2Qty: 39,
        resource3: 'steel',
        r3Qty: 10,
        component1: 'evileye',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-8',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'owl',
        tag: null,
        discount: 230,
        surcharge: 515,
        suggest: 105,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-11-04',
        shardPrice: 160,
    },
    goldwand: {
        uid: 'goldwand',
        level: 24,
        type: 'ww',
        xp: 62500,
        craftXp: 938,
        value: 245000,
        favor: 862,
        time: 16200,
        atk: 410,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 7,
        worker1: 'academy',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'wood',
        r1Qty: 199,
        resource2: 'ironwood',
        r2Qty: 51,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'songofvalor',
        c1Qty: 2,
        c1Tags: 'common',
        component2: 'ancientmarble',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:flowerwand',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 345,
        surcharge: 770,
        suggest: 155,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    flowerwand: {
        uid: 'flowerwand',
        level: 28,
        type: 'ww',
        xp: 87000,
        craftXp: 1305,
        value: 345000,
        favor: 1589,
        time: 27600,
        atk: 490,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'academy',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 259,
        resource2: 'ironwood',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'ectoplasm',
        c1Qty: 4,
        c1Tags: null,
        component2: 'livingroots',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.6',
        upgrade2: 'resource1-52',
        upgrade3: 'bp:moonwand',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 910,
        suggest: 180,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    fallwand: {
        uid: 'fallwand',
        level: 29,
        type: 'ww',
        xp: 94000,
        craftXp: 1410,
        value: 400000,
        favor: 1847,
        time: 33000,
        atk: 520,
        def: 0,
        hp: 16,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'academy',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'wood',
        r1Qty: 275,
        resource2: 'ironwood',
        r2Qty: 77,
        resource3: 'gems',
        r3Qty: 19,
        component1: 'ghostflag',
        c1Qty: 3,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.6',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 440,
        surcharge: 975,
        suggest: 195,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-09-09',
        shardPrice: 200,
    },
    moonwand: {
        uid: 'moonwand',
        level: 31,
        type: 'ww',
        xp: 110000,
        craftXp: 1650,
        value: 665000,
        favor: 2451,
        time: 46200,
        atk: 560,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'academy',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 306,
        resource2: 'ironwood',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'scrollofice',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'sirenshard',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'bp:dragoneye',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 840,
        surcharge: 1870,
        suggest: 375,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    wizardwand: {
        uid: 'wizardwand',
        level: 31,
        type: 'ww',
        xp: 110000,
        craftXp: 1650,
        value: 700000,
        favor: 2451,
        time: 47400,
        atk: 560,
        def: 140,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'academy',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 306,
        resource2: 'ironwood',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: 'timecrystal',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 865,
        surcharge: 1920,
        suggest: 385,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-10-09',
        shardPrice: 250,
    },
    dragoneye: {
        uid: 'dragoneye',
        level: 33,
        type: 'ww',
        xp: 185000,
        craftXp: 2775,
        value: 1700000,
        favor: 3218,
        time: 67800,
        atk: 700,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'academy',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 338,
        resource2: 'ironwood',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1145,
        surcharge: 2545,
        suggest: 510,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lcogwand: {
        uid: 'lcogwand',
        level: 33,
        type: 'ww',
        xp: 185000,
        craftXp: 2775,
        value: 3450000,
        favor: 3218,
        time: 67800,
        atk: 700,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 5,
        worker1: 'academy',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 338,
        resource2: 'ironwood',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'tabletjewel',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 1205,
        surcharge: 2680,
        suggest: 535,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-08-07',
        shardPrice: 0,
    },
    sailorwand: {
        uid: 'sailorwand',
        level: 34,
        type: 'ww',
        xp: 200000,
        craftXp: 3000,
        value: 2000000,
        favor: 3263,
        time: 81000,
        atk: 720,
        def: 0,
        hp: 22,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'academy',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 319,
        resource2: 'steel',
        r2Qty: 93,
        resource3: 'gems',
        r3Qty: 34,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-64',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-19',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1240,
        surcharge: 2750,
        suggest: 550,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-09-22',
        shardPrice: 300,
    },
    lightcrossbow: {
        uid: 'lightcrossbow',
        level: 2,
        type: 'wc',
        xp: 385,
        craftXp: 6,
        value: 210,
        favor: 1,
        time: 50,
        atk: 24,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 1,
        combo: 10,
        worker1: 'engineer',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 8,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-2',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 25,
        suggest: 5,
        speedup: 25,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    handcrossbow: {
        uid: 'handcrossbow',
        level: 4,
        type: 'wc',
        xp: 1400,
        craftXp: 21,
        value: 560,
        favor: 16,
        time: 130,
        atk: 46,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 2,
        combo: 9,
        worker1: 'engineer',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 13,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-3',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 40,
        suggest: 10,
        speedup: 55,
        buyAnimIdOverride: 15,
        questAnimIdOverride: 6,
        slotOverride: 'left_hip',
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    crossbow: {
        uid: 'crossbow',
        level: 7,
        type: 'wc',
        xp: 4400,
        craftXp: 66,
        value: 2000,
        favor: 26,
        time: 370,
        atk: 84,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'engineer',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 19,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:heavycrossbow',
        upgrade4: 'resource1-4',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 35,
        surcharge: 75,
        suggest: 15,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    heavycrossbow: {
        uid: 'heavycrossbow',
        level: 10,
        type: 'wc',
        xp: 9400,
        craftXp: 141,
        value: 5650,
        favor: 52,
        time: 840,
        atk: 128,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 9,
        worker1: 'engineer',
        worker2: null,
        worker3: null,
        w1BuildingReq: 7,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 33,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'webbedwings',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:huntercrossbow',
        upgrade4: 'resource1-7',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 55,
        surcharge: 125,
        suggest: 25,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    thanksgivingcrossbow: {
        uid: 'thanksgivingcrossbow',
        level: 12,
        type: 'wc',
        xp: 14000,
        craftXp: 210,
        value: 9050,
        favor: 79,
        time: 1350,
        atk: 160,
        def: 0,
        hp: 5,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'engineer',
        worker2: null,
        worker3: null,
        w1BuildingReq: 9,
        w2BuildingReq: 10,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 45,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 3,
        c1Tags: null,
        component2: 'elvenwood',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'value*1.25',
        upgrade4: 'resource1-9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-3',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 160,
        suggest: 30,
        speedup: 260,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-11-18',
        shardPrice: 100,
    },
    huntercrossbow: {
        uid: 'huntercrossbow',
        level: 13,
        type: 'wc',
        xp: 16500,
        craftXp: 248,
        value: 15500,
        favor: 101,
        time: 1650,
        atk: 176,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'engineer',
        worker2: null,
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 54,
        resource2: 'herbs',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'antidote',
        c1Qty: 2,
        c1Tags: 'common',
        component2: 'livingroots',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-11',
        upgrade3: 'bp:scorpion',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-2',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 90,
        surcharge: 205,
        suggest: 40,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    scorpion: {
        uid: 'scorpion',
        level: 17,
        type: 'wc',
        xp: 29500,
        craftXp: 443,
        value: 35000,
        favor: 271,
        time: 4200,
        atk: 240,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 8,
        worker1: 'engineer',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 14,
        w3BuildingReq: 4,
        resource1: 'wood',
        r1Qty: 109,
        resource2: 'ironwood',
        r2Qty: 25,
        resource3: null,
        r3Qty: 0,
        component1: 'mooncrystal',
        c1Qty: 3,
        c1Tags: null,
        component2: 'webbedwings',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-22',
        upgrade3: 'bp:doublecrossbow',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 135,
        surcharge: 305,
        suggest: 60,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 16,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    troblincrossbow: {
        uid: 'troblincrossbow',
        level: 19,
        type: 'wc',
        xp: 37500,
        craftXp: 563,
        value: 56500,
        favor: 398,
        time: 6600,
        atk: 280,
        def: 70,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'engineer',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 16,
        w2BuildingReq: 17,
        w3BuildingReq: 6,
        resource1: 'wood',
        r1Qty: 139,
        resource2: 'ironwood',
        r2Qty: 33,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: 'livingroots',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'component1-1',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-13',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 170,
        surcharge: 380,
        suggest: 75,
        speedup: 565,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-07-14',
        shardPrice: 140,
    },
    doublecrossbow: {
        uid: 'doublecrossbow',
        level: 21,
        type: 'wc',
        xp: 46500,
        craftXp: 698,
        value: 130000,
        favor: 565,
        time: 9000,
        atk: 320,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'engineer',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'wood',
        r1Qty: 171,
        resource2: 'ironwood',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'crossbow',
        c1Qty: 2,
        c1Tags: 'common',
        component2: 'silkscarab',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-34',
        upgrade3: 'bp:chukonu',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component1-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 255,
        surcharge: 570,
        suggest: 115,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    chukonu: {
        uid: 'chukonu',
        level: 24,
        type: 'wc',
        xp: 62500,
        craftXp: 938,
        value: 210000,
        favor: 1145,
        time: 17400,
        atk: 380,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 7,
        worker1: 'engineer',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'wood',
        r1Qty: 219,
        resource2: 'ironwood',
        r2Qty: 51,
        resource3: 'steel',
        r3Qty: 26,
        component1: 'timecrystal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-44',
        upgrade3: 'bp:triplecrossbow',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-10',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 320,
        surcharge: 715,
        suggest: 145,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    dragoncrossbow: {
        uid: 'dragoncrossbow',
        level: 25,
        type: 'wc',
        xp: 68000,
        craftXp: 1020,
        value: 230000,
        favor: 1324,
        time: 21600,
        atk: 400,
        def: 0,
        hp: 12,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 7,
        worker1: 'engineer',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'wood',
        r1Qty: 235,
        resource2: 'ironwood',
        r2Qty: 57,
        resource3: 'steel',
        r3Qty: 28,
        component1: 'overgrownvine',
        c1Qty: 4,
        c1Tags: null,
        component2: 'whitesand',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-47',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-11',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 340,
        surcharge: 750,
        suggest: 150,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-12-30',
        shardPrice: 180,
    },
    triplecrossbow: {
        uid: 'triplecrossbow',
        level: 28,
        type: 'wc',
        xp: 87000,
        craftXp: 1305,
        value: 355000,
        favor: 1775,
        time: 25800,
        atk: 460,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'engineer',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 285,
        resource2: 'ironwood',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'heavycrossbow',
        c1Qty: 3,
        c1Tags: 'common',
        component2: 'sewingthread',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.6',
        upgrade2: 'resource1-57',
        upgrade3: 'bp:handballista',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 415,
        surcharge: 925,
        suggest: 185,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    handballista: {
        uid: 'handballista',
        level: 30,
        type: 'wc',
        xp: 100000,
        craftXp: 1500,
        value: 655000,
        favor: 2374,
        time: 39600,
        atk: 500,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'engineer',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 319,
        resource2: 'ironwood',
        r2Qty: 82,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'deepcoral',
        c1Qty: 4,
        c1Tags: null,
        component2: 'ancientmarble',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-64',
        upgrade3: 'bp:wyrmthrower',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 835,
        surcharge: 1855,
        suggest: 370,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    batcrossbow: {
        uid: 'batcrossbow',
        level: 30,
        type: 'wc',
        xp: 100000,
        craftXp: 1500,
        value: 690000,
        favor: 2374,
        time: 39600,
        atk: 500,
        def: 125,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'engineer',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 319,
        resource2: 'ironwood',
        r2Qty: 82,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: 'ghostflag',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-64',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-16',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 855,
        surcharge: 1905,
        suggest: 380,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-10-21',
        shardPrice: 250,
    },
    wyrmthrower: {
        uid: 'wyrmthrower',
        level: 33,
        type: 'wc',
        xp: 185000,
        craftXp: 2775,
        value: 1900000,
        favor: 3592,
        time: 67800,
        atk: 650,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'engineer',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 372,
        resource2: 'ironwood',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-74',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'shark',
        tag: null,
        discount: 1210,
        surcharge: 2685,
        suggest: 535,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldcrossbow: {
        uid: 'goldcrossbow',
        level: 33,
        type: 'wc',
        xp: 185000,
        craftXp: 2775,
        value: 3500000,
        favor: 3592,
        time: 67800,
        atk: 650,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 5,
        worker1: 'engineer',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 372,
        resource2: 'ironwood',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'tabletjewel',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-74',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 1215,
        surcharge: 2700,
        suggest: 540,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-26',
        shardPrice: 0,
    },
    titancrossbow: {
        uid: 'titancrossbow',
        level: 34,
        type: 'wc',
        xp: 200000,
        craftXp: 3000,
        value: 2100000,
        favor: 4103,
        time: 81000,
        atk: 680,
        def: 0,
        hp: 21,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'engineer',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 390,
        resource2: 'ironwood',
        r2Qty: 103,
        resource3: 'gems',
        r3Qty: 34,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-117',
        upgrade3: 'time*0.7',
        upgrade4: 'resource2-31',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1265,
        surcharge: 2815,
        suggest: 565,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-26',
        shardPrice: 300,
    },
    flintlockpistol: {
        uid: 'flintlockpistol',
        level: 3,
        type: 'wg',
        xp: 730,
        craftXp: 11,
        value: 400,
        favor: 12,
        time: 80,
        atk: 38,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 1,
        combo: 9,
        worker1: 'engineer',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 10,
        resource2: 'wood',
        r2Qty: 5,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-2',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 15,
        surcharge: 35,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    pistol: {
        uid: 'pistol',
        level: 5,
        type: 'wg',
        xp: 2000,
        craftXp: 30,
        value: 840,
        favor: 19,
        time: 190,
        atk: 64,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 2,
        combo: 9,
        worker1: 'engineer',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 15,
        resource2: 'wood',
        r2Qty: 7,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-3',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 50,
        suggest: 10,
        speedup: 70,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    flintlockrifle: {
        uid: 'flintlockrifle',
        level: 8,
        type: 'wg',
        xp: 5400,
        craftXp: 81,
        value: 2450,
        favor: 33,
        time: 470,
        atk: 108,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'engineer',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 23,
        resource2: 'wood',
        r2Qty: 11,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:huntingrifle',
        upgrade4: 'resource1-5',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 85,
        suggest: 15,
        speedup: 135,
        buyAnimIdOverride: 16,
        questAnimIdOverride: 12,
        slotOverride: 'weapon_1',
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    huntingrifle: {
        uid: 'huntingrifle',
        level: 11,
        type: 'wg',
        xp: 10500,
        craftXp: 158,
        value: 9200,
        favor: 61,
        time: 1020,
        atk: 160,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'engineer',
        worker2: null,
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 37,
        resource2: 'wood',
        r2Qty: 17,
        resource3: null,
        r3Qty: 0,
        component1: 'livingroots',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:blunderbuss',
        upgrade4: 'resource1-7',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 160,
        suggest: 30,
        speedup: 225,
        buyAnimIdOverride: 16,
        questAnimIdOverride: 12,
        slotOverride: 'weapon_1',
        soundType: 'hMetal',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    wintergun: {
        uid: 'wintergun',
        level: 12,
        type: 'wg',
        xp: 13000,
        craftXp: 195,
        value: 11500,
        favor: 79,
        time: 1290,
        atk: 176,
        def: 44,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'engineer',
        worker2: null,
        worker3: null,
        w1BuildingReq: 9,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 45,
        resource2: 'wood',
        r2Qty: 20,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-9',
        upgrade3: 'time*0.75',
        upgrade4: 'resource1-9',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 80,
        surcharge: 180,
        suggest: 35,
        speedup: 260,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-12-02',
        shardPrice: 100,
    },
    blunderbuss: {
        uid: 'blunderbuss',
        level: 15,
        type: 'wg',
        xp: 21000,
        craftXp: 315,
        value: 21000,
        favor: 170,
        time: 2700,
        atk: 230,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'engineer',
        worker2: null,
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 79,
        resource2: 'steel',
        r2Qty: 16,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 4,
        c1Tags: null,
        component2: 'elvenwood',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-16',
        upgrade3: 'bp:doublebarrel',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-5',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 110,
        surcharge: 240,
        suggest: 50,
        speedup: 375,
        buyAnimIdOverride: 16,
        questAnimIdOverride: 12,
        slotOverride: 'weapon_1',
        soundType: 'hMetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    doublebarrel: {
        uid: 'doublebarrel',
        level: 18,
        type: 'wg',
        xp: 31000,
        craftXp: 465,
        value: 46000,
        favor: 330,
        time: 4800,
        atk: 290,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'engineer',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 1,
        w3BuildingReq: 5,
        resource1: 'iron',
        r1Qty: 124,
        resource2: 'steel',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'mooncrystal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-25',
        upgrade3: 'bp:kenora',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 155,
        surcharge: 345,
        suggest: 70,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    kenora: {
        uid: 'kenora',
        level: 21,
        type: 'wg',
        xp: 43000,
        craftXp: 645,
        value: 110000,
        favor: 860,
        time: 9000,
        atk: 350,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'engineer',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'iron',
        r1Qty: 171,
        resource2: 'wood',
        r2Qty: 78,
        resource3: 'steel',
        r3Qty: 39,
        component1: 'starmetal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'whitesand',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-34',
        upgrade3: 'bp:gatling',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 235,
        surcharge: 525,
        suggest: 105,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    gatling: {
        uid: 'gatling',
        level: 24,
        type: 'wg',
        xp: 57500,
        craftXp: 863,
        value: 210000,
        favor: 898,
        time: 16800,
        atk: 420,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 7,
        worker1: 'engineer',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'iron',
        r1Qty: 219,
        resource2: 'steel',
        r2Qty: 51,
        resource3: null,
        r3Qty: 0,
        component1: 'pistol',
        c1Qty: 4,
        c1Tags: 'common',
        component2: 'overgrownvine',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'component1-2',
        upgrade3: 'bp:dragonator',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-10',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 320,
        surcharge: 715,
        suggest: 145,
        speedup: 860,
        buyAnimIdOverride: 16,
        questAnimIdOverride: 12,
        slotOverride: 'weapon_1',
        soundType: 'hMetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    dragonator: {
        uid: 'dragonator',
        level: 29,
        type: 'wg',
        xp: 86500,
        craftXp: 1298,
        value: 370000,
        favor: 1943,
        time: 30000,
        atk: 530,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'engineer',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'iron',
        r1Qty: 302,
        resource2: 'steel',
        r2Qty: 77,
        resource3: 'gems',
        r3Qty: 11,
        component1: 'ember',
        c1Qty: 2,
        c1Tags: 'common',
        component2: 'ectoplasm',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.6',
        upgrade2: 'resource1-60',
        upgrade3: 'bp:hermes',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 425,
        surcharge: 940,
        suggest: 190,
        speedup: 1220,
        buyAnimIdOverride: 16,
        questAnimIdOverride: 12,
        slotOverride: 'weapon_1',
        soundType: 'hMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    barrelgun: {
        uid: 'barrelgun',
        level: 29,
        type: 'wg',
        xp: 86500,
        craftXp: 1298,
        value: 425000,
        favor: 1943,
        time: 31800,
        atk: 530,
        def: 133,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'engineer',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'iron',
        r1Qty: 302,
        resource2: 'steel',
        r2Qty: 77,
        resource3: 'gems',
        r3Qty: 11,
        component1: 'ghostflag',
        c1Qty: 3,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.6',
        upgrade2: 'resource1-60',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 450,
        surcharge: 1005,
        suggest: 200,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-09-23',
        shardPrice: 200,
    },
    hermes: {
        uid: 'hermes',
        level: 31,
        type: 'wg',
        xp: 100000,
        craftXp: 1500,
        value: 665000,
        favor: 2569,
        time: 45600,
        atk: 580,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'engineer',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 337,
        resource2: 'steel',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 15,
        component1: 'sirenshard',
        c1Qty: 4,
        c1Tags: null,
        component2: 'ancientmarble',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-67',
        upgrade3: 'bp:disintegratorgun',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 840,
        surcharge: 1870,
        suggest: 375,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lunargun: {
        uid: 'lunargun',
        level: 32,
        type: 'wg',
        xp: 105000,
        craftXp: 1575,
        value: 675000,
        favor: 2933,
        time: 54600,
        atk: 610,
        def: 153,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'engineer',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 26,
        w2BuildingReq: 22,
        w3BuildingReq: 19,
        resource1: 'iron',
        r1Qty: 354,
        resource2: 'steel',
        r2Qty: 93,
        resource3: 'gems',
        r3Qty: 17,
        component1: 'goldshell',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-71',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-19',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'ox',
        tag: null,
        discount: 850,
        surcharge: 1885,
        suggest: 375,
        speedup: 2635,
        buyAnimIdOverride: 16,
        questAnimIdOverride: 12,
        slotOverride: 'weapon_1',
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-02-10',
        shardPrice: 250,
    },
    disintegratorgun: {
        uid: 'disintegratorgun',
        level: 33,
        type: 'wg',
        xp: 170000,
        craftXp: 2550,
        value: 1700000,
        favor: 3351,
        time: 65400,
        atk: 720,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'engineer',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 372,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 19,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-74',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1145,
        surcharge: 2545,
        suggest: 510,
        speedup: 2790,
        buyAnimIdOverride: 16,
        questAnimIdOverride: 12,
        slotOverride: 'weapon_1',
        soundType: 'hMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldgun: {
        uid: 'goldgun',
        level: 33,
        type: 'wg',
        xp: 170000,
        craftXp: 2550,
        value: 3400000,
        favor: 3351,
        time: 65400,
        atk: 720,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 5,
        worker1: 'engineer',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 372,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 19,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'tabletjewel',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-74',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 1200,
        surcharge: 2665,
        suggest: 535,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-06-12',
        shardPrice: 0,
    },
    doggun: {
        uid: 'doggun',
        level: 33,
        type: 'wg',
        xp: 170000,
        craftXp: 2550,
        value: 2000000,
        favor: 3351,
        time: 65400,
        atk: 720,
        def: 0,
        hp: 23,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'engineer',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 372,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 19,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-74',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'wolf',
        tag: null,
        discount: 1240,
        surcharge: 2750,
        suggest: 550,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-08-25',
        shardPrice: 300,
    },
    antidote: {
        uid: 'antidote',
        level: 3,
        type: 'uh',
        xp: 455,
        craftXp: 7,
        value: 180,
        favor: 10,
        time: 60,
        atk: 8,
        def: 0,
        hp: 2,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 9,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'bp:tea',
        upgrade2: 'bp:grass',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.5',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 25,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    grass: {
        uid: 'grass',
        level: 5,
        type: 'uh',
        xp: 1200,
        craftXp: 18,
        value: 700,
        favor: 16,
        time: 140,
        atk: 13,
        def: 0,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 14,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'bp:powder',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.5',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 45,
        suggest: 10,
        speedup: 70,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    powder: {
        uid: 'powder',
        level: 8,
        type: 'uh',
        xp: 3100,
        craftXp: 47,
        value: 2350,
        favor: 27,
        time: 340,
        atk: 21,
        def: 0,
        hp: 5,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 5,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 21,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'antidote',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'webbedwings',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:healingsalve',
        upgrade4: 'resource1-4',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 85,
        suggest: 15,
        speedup: 135,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 4,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    christmasflower: {
        uid: 'christmasflower',
        level: 9,
        type: 'uh',
        xp: 3950,
        craftXp: 59,
        value: 3150,
        favor: 39,
        time: 480,
        atk: 36,
        def: 0,
        hp: 6,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 26,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.8',
        upgrade3: 'resource1-5',
        upgrade4: 'time*0.8',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 45,
        surcharge: 95,
        suggest: 20,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2019-12-09',
        shardPrice: 75,
    },
    healingsalve: {
        uid: 'healingsalve',
        level: 11,
        type: 'uh',
        xp: 6100,
        craftXp: 92,
        value: 4100,
        favor: 50,
        time: 780,
        atk: 31,
        def: 0,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 8,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 34,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'livingroots',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:silverthistle',
        upgrade4: 'resource1-7',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 50,
        surcharge: 110,
        suggest: 20,
        speedup: 225,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    thanksgivingherb: {
        uid: 'thanksgivingherb',
        level: 12,
        type: 'uh',
        xp: 7300,
        craftXp: 110,
        value: 8650,
        favor: 71,
        time: 930,
        atk: 51,
        def: 0,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 9,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 41,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'grass',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'icypearl',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'value*1.25',
        upgrade4: 'resource1-8',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 155,
        suggest: 30,
        speedup: 260,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-11-17',
        shardPrice: 100,
    },
    silverthistle: {
        uid: 'silverthistle',
        level: 14,
        type: 'uh',
        xp: 10000,
        craftXp: 150,
        value: 10000,
        favor: 106,
        time: 1590,
        atk: 41,
        def: 0,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 60,
        resource2: 'oils',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: 'elvenwood',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-12',
        upgrade3: 'bp:bloodvine',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 75,
        surcharge: 165,
        suggest: 35,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    harvest: {
        uid: 'harvest',
        level: 15,
        type: 'uh',
        xp: 12000,
        craftXp: 180,
        value: 16000,
        favor: 150,
        time: 2040,
        atk: 66,
        def: 0,
        hp: 11,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 71,
        resource2: 'oils',
        r2Qty: 16,
        resource3: null,
        r3Qty: 0,
        component1: 'livingroots',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-14',
        upgrade3: 'value*1.25',
        upgrade4: 'resource1-14',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 95,
        surcharge: 210,
        suggest: 40,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-09-24',
        shardPrice: 120,
    },
    bloodvine: {
        uid: 'bloodvine',
        level: 17,
        type: 'uh',
        xp: 15500,
        craftXp: 233,
        value: 24500,
        favor: 216,
        time: 3000,
        atk: 53,
        def: 0,
        hp: 13,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 10,
        w3BuildingReq: 4,
        resource1: 'herbs',
        r1Qty: 99,
        resource2: 'oils',
        r2Qty: 25,
        resource3: null,
        r3Qty: 0,
        component1: 'sharpfang',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:mandragoraroot',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 115,
        surcharge: 255,
        suggest: 50,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    bouquet: {
        uid: 'bouquet',
        level: 18,
        type: 'uh',
        xp: 17500,
        craftXp: 263,
        value: 37000,
        favor: 296,
        time: 3600,
        atk: 83,
        def: 0,
        hp: 14,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'herbs',
        r1Qty: 113,
        resource2: 'oils',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'mooncrystal',
        c1Qty: 3,
        c1Tags: null,
        component2: 'arcanedust',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 12,
        u2Req: 30,
        u3Req: 54,
        u4Req: 85,
        u5Req: 135,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 140,
        surcharge: 310,
        suggest: 60,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-07-14',
        shardPrice: 140,
    },
    mandragoraroot: {
        uid: 'mandragoraroot',
        level: 20,
        type: 'uh',
        xp: 22000,
        craftXp: 330,
        value: 68500,
        favor: 379,
        time: 6000,
        atk: 63,
        def: 0,
        hp: 16,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'herbs',
        r1Qty: 141,
        resource2: 'oils',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'silkscarab',
        c1Qty: 4,
        c1Tags: null,
        component2: 'livingroots',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:wolfsbane',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 190,
        surcharge: 420,
        suggest: 85,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    valentineherb: {
        uid: 'valentineherb',
        level: 22,
        type: 'uh',
        xp: 27000,
        craftXp: 405,
        value: 100000,
        favor: 591,
        time: 8400,
        atk: 70,
        def: 0,
        hp: 27,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 19,
        w2BuildingReq: 15,
        w3BuildingReq: 9,
        resource1: 'herbs',
        r1Qty: 170,
        resource2: 'oils',
        r2Qty: 43,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 6,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-34',
        upgrade3: 'component2-2',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-9',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-2',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 225,
        surcharge: 500,
        suggest: 100,
        speedup: 735,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-02-08',
        shardPrice: 160,
    },
    wolfsbane: {
        uid: 'wolfsbane',
        level: 24,
        type: 'uh',
        xp: 32500,
        craftXp: 488,
        value: 180000,
        favor: 753,
        time: 11400,
        atk: 80,
        def: 0,
        hp: 20,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'herbs',
        r1Qty: 199,
        resource2: 'oils',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 5,
        component1: 'witchrobe',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'timecrystal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:yggdrasilbranch',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 300,
        surcharge: 665,
        suggest: 135,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ruinsherb: {
        uid: 'ruinsherb',
        level: 25,
        type: 'uh',
        xp: 35500,
        craftXp: 533,
        value: 215000,
        favor: 981,
        time: 16200,
        atk: 83,
        def: 0,
        hp: 21,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 8,
        combo: 7,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'herbs',
        r1Qty: 214,
        resource2: 'oils',
        r2Qty: 57,
        resource3: 'mana',
        r3Qty: 6,
        component1: 'overgrownvine',
        c1Qty: 5,
        c1Tags: null,
        component2: 'icypearl',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'tidal',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 325,
        surcharge: 725,
        suggest: 145,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 0,
        chest: 'ruins',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    yggdrasilbranch: {
        uid: 'yggdrasilbranch',
        level: 27,
        type: 'uh',
        xp: 42000,
        craftXp: 630,
        value: 305000,
        favor: 1190,
        time: 16200,
        atk: 93,
        def: 0,
        hp: 23,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'herbs',
        r1Qty: 244,
        resource2: 'oils',
        r2Qty: 67,
        resource3: 'mana',
        r3Qty: 10,
        component1: 'healingsalve',
        c1Qty: 2,
        c1Tags: 'common',
        component2: 'sewingthread',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:wyrmblood',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 385,
        surcharge: 860,
        suggest: 170,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldenclover: {
        uid: 'goldenclover',
        level: 28,
        type: 'uh',
        xp: 45500,
        craftXp: 683,
        value: 370000,
        favor: 1538,
        time: 20400,
        atk: 143,
        def: 0,
        hp: 24,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'herbs',
        r1Qty: 259,
        resource2: 'oils',
        r2Qty: 72,
        resource3: 'mana',
        r3Qty: 12,
        component1: 'overgrownvine',
        c1Qty: 6,
        c1Tags: null,
        component2: 'arcanedust',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-2',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 425,
        surcharge: 940,
        suggest: 190,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-03-10',
        shardPrice: 200,
    },
    goldherb: {
        uid: 'goldherb',
        level: 29,
        type: 'uh',
        xp: 49000,
        craftXp: 735,
        value: 595000,
        favor: 1777,
        time: 24600,
        atk: 100,
        def: 0,
        hp: 25,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'herbs',
        r1Qty: 275,
        resource2: 'oils',
        r2Qty: 77,
        resource3: 'mana',
        r3Qty: 14,
        component1: 'tabletfragment',
        c1Qty: 5,
        c1Tags: null,
        component2: 'ectoplasm',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 400,
        surcharge: 885,
        suggest: 175,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-05-16',
        shardPrice: 0,
    },
    wyrmblood: {
        uid: 'wyrmblood',
        level: 31,
        type: 'uh',
        xp: 56500,
        craftXp: 848,
        value: 635000,
        favor: 2103,
        time: 34800,
        atk: 110,
        def: 0,
        hp: 27,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'herbs',
        r1Qty: 306,
        resource2: 'oils',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 19,
        component1: 'deepcoral',
        c1Qty: 5,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'bp:eldritchtendrils',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 825,
        surcharge: 1830,
        suggest: 365,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    couplebouquet: {
        uid: 'couplebouquet',
        level: 32,
        type: 'uh',
        xp: 61000,
        craftXp: 915,
        value: 650000,
        favor: 2394,
        time: 40800,
        atk: 173,
        def: 0,
        hp: 29,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 26,
        w2BuildingReq: 22,
        w3BuildingReq: 19,
        resource1: 'wood',
        r1Qty: 290,
        resource2: 'ironwood',
        r2Qty: 93,
        resource3: 'mana',
        r3Qty: 22,
        component1: 'bo',
        c1Qty: 2,
        c1Tags: 'uncommon',
        component2: 'sirenshard',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-19',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 835,
        surcharge: 1850,
        suggest: 370,
        speedup: 2635,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-02-24',
        shardPrice: 250,
    },
    eldritchtendrils: {
        uid: 'eldritchtendrils',
        level: 33,
        type: 'uh',
        xp: 97500,
        craftXp: 1463,
        value: 1300000,
        favor: 2736,
        time: 50400,
        atk: 135,
        def: 0,
        hp: 34,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 338,
        resource2: 'oils',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 23,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1005,
        surcharge: 2235,
        suggest: 445,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    peakherbs: {
        uid: 'peakherbs',
        level: 33,
        type: 'uh',
        xp: 97500,
        craftXp: 1463,
        value: 1550000,
        favor: 3061,
        time: 50400,
        atk: 135,
        def: 0,
        hp: 34,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 5,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 338,
        resource2: 'oils',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 23,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'primal',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 1095,
        surcharge: 2435,
        suggest: 485,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 0,
        chest: 'peak',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    circusherb: {
        uid: 'circusherb',
        level: 33,
        type: 'uh',
        xp: 97500,
        craftXp: 1463,
        value: 1700000,
        favor: 3061,
        time: 50400,
        atk: 135,
        def: 0,
        hp: 51,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 338,
        resource2: 'oils',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 23,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 1145,
        surcharge: 2545,
        suggest: 510,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-06-15',
        shardPrice: 300,
    },
    candyherb: {
        uid: 'candyherb',
        level: 34,
        type: 'uh',
        xp: 105000,
        craftXp: 1575,
        value: 1650000,
        favor: 3479,
        time: 60000,
        atk: 210,
        def: 0,
        hp: 35,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 354,
        resource2: 'oils',
        r2Qty: 103,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'ancientmarble',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-71',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-26',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'resource2-26',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1130,
        surcharge: 2510,
        suggest: 500,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-09-08',
        shardPrice: 300,
    },
    tea: {
        uid: 'tea',
        level: 4,
        type: 'up',
        xp: 810,
        craftXp: 12,
        value: 280,
        favor: 13,
        time: 100,
        atk: 0,
        def: 0,
        hp: 5,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 9,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 12,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:smallhealthpotion',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.5',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 15,
        surcharge: 30,
        suggest: 5,
        speedup: 55,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    smallhealthpotion: {
        uid: 'smallhealthpotion',
        level: 7,
        type: 'up',
        xp: 2500,
        craftXp: 38,
        value: 980,
        favor: 21,
        time: 280,
        atk: 0,
        def: 0,
        hp: 9,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 17,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:smallmanapotion',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.5',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 55,
        suggest: 10,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    smallmanapotion: {
        uid: 'smallmanapotion',
        level: 10,
        type: 'up',
        xp: 5300,
        craftXp: 80,
        value: 3850,
        favor: 42,
        time: 630,
        atk: 0,
        def: 0,
        hp: 14,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 7,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 30,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'webbedwings',
        c1Qty: 3,
        c1Tags: null,
        component2: 'glowingmushrooms',
        c2Qty: 1,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:healthpotion',
        upgrade4: 'resource1-6',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 45,
        surcharge: 105,
        suggest: 20,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    scienceexperiment: {
        uid: 'scienceexperiment',
        level: 11,
        type: 'up',
        xp: 6450,
        craftXp: 97,
        value: 4050,
        favor: 56,
        time: 630,
        atk: 16,
        def: 0,
        hp: 15,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 8,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 34,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'smallhealthpotion',
        c1Qty: 1,
        c1Tags: 'common',
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.8',
        upgrade3: 'resource1-7',
        upgrade4: 'time*0.8',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 50,
        surcharge: 110,
        suggest: 20,
        speedup: 225,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-08-27',
        shardPrice: 75,
    },
    healthpotion: {
        uid: 'healthpotion',
        level: 13,
        type: 'up',
        xp: 9250,
        craftXp: 139,
        value: 7000,
        favor: 81,
        time: 1290,
        atk: 0,
        def: 0,
        hp: 19,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 8,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 49,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'livingroots',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:magicpotion',
        upgrade4: 'resource1-10',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 65,
        surcharge: 140,
        suggest: 30,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    victorianpotion: {
        uid: 'victorianpotion',
        level: 15,
        type: 'up',
        xp: 12500,
        craftXp: 188,
        value: 12000,
        favor: 150,
        time: 2040,
        atk: 0,
        def: 44,
        hp: 22,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 71,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 2,
        c1Tags: null,
        component2: 'glowingmushrooms',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-11',
        upgrade3: 'time*0.75',
        upgrade4: 'resource1-11',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 80,
        surcharge: 180,
        suggest: 35,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-04-06',
        shardPrice: 100,
    },
    magicpotion: {
        uid: 'magicpotion',
        level: 16,
        type: 'up',
        xp: 14500,
        craftXp: 218,
        value: 13500,
        favor: 173,
        time: 2400,
        atk: 0,
        def: 0,
        hp: 24,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'herbalist',
        worker2: null,
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 8,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 85,
        resource2: 'oils',
        r2Qty: 21,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'value*1.25',
        upgrade3: 'bp:largehealthpotion',
        upgrade4: 'resource1-17',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-2',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 85,
        surcharge: 190,
        suggest: 40,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    poloniapotion: {
        uid: 'poloniapotion',
        level: 18,
        type: 'up',
        xp: 18500,
        craftXp: 278,
        value: 25000,
        favor: 296,
        time: 4200,
        atk: 28,
        def: 0,
        hp: 28,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 7,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'herbs',
        r1Qty: 113,
        resource2: 'oils',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 3,
        c1Tags: null,
        component2: 'arcanedust',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 12,
        u2Req: 30,
        u3Req: 54,
        u4Req: 85,
        u5Req: 135,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-9',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 115,
        surcharge: 260,
        suggest: 50,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-04-21',
        shardPrice: 120,
    },
    largehealthpotion: {
        uid: 'largehealthpotion',
        level: 18,
        type: 'up',
        xp: 18500,
        craftXp: 278,
        value: 31500,
        favor: 265,
        time: 4200,
        atk: 0,
        def: 0,
        hp: 28,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 7,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'herbs',
        r1Qty: 113,
        resource2: 'oils',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'mooncrystal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'redpinecone',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'bp:vialofrenewal',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-5',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 130,
        surcharge: 290,
        suggest: 60,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    cincopotion: {
        uid: 'cincopotion',
        level: 19,
        type: 'up',
        xp: 21000,
        craftXp: 315,
        value: 54500,
        favor: 357,
        time: 4200,
        atk: 0,
        def: 60,
        hp: 30,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 16,
        w2BuildingReq: 12,
        w3BuildingReq: 6,
        resource1: 'herbs',
        r1Qty: 127,
        resource2: 'oils',
        r2Qty: 33,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 5,
        c1Tags: null,
        component2: 'tea',
        c2Qty: 1,
        c2Tags: 'uncommon',
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-25',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 170,
        surcharge: 375,
        suggest: 75,
        speedup: 565,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-05-04',
        shardPrice: 140,
    },
    vialofrenewal: {
        uid: 'vialofrenewal',
        level: 20,
        type: 'up',
        xp: 23500,
        craftXp: 353,
        value: 72500,
        favor: 379,
        time: 6000,
        atk: 0,
        def: 0,
        hp: 32,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'herbs',
        r1Qty: 141,
        resource2: 'oils',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'evileye',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:purplebomb',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 195,
        surcharge: 430,
        suggest: 85,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    pyramidpotion: {
        uid: 'pyramidpotion',
        level: 21,
        type: 'up',
        xp: 26000,
        craftXp: 390,
        value: 94000,
        favor: 502,
        time: 7200,
        atk: 0,
        def: 0,
        hp: 34,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 7,
        combo: 7,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'herbs',
        r1Qty: 155,
        resource2: 'oils',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'silkscarab',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'holy',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 220,
        surcharge: 490,
        suggest: 100,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: 'pyramid',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    purplebomb: {
        uid: 'purplebomb',
        level: 24,
        type: 'up',
        xp: 34500,
        craftXp: 518,
        value: 190000,
        favor: 771,
        time: 13200,
        atk: 40,
        def: 0,
        hp: 40,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'herbs',
        r1Qty: 199,
        resource2: 'oils',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 7,
        component1: 'timecrystal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:soulfireextract',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-4',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 310,
        surcharge: 685,
        suggest: 135,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    pumpkinpotion: {
        uid: 'pumpkinpotion',
        level: 25,
        type: 'up',
        xp: 38000,
        craftXp: 570,
        value: 225000,
        favor: 1001,
        time: 15600,
        atk: 83,
        def: 0,
        hp: 42,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 7,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'herbs',
        r1Qty: 214,
        resource2: 'oils',
        r2Qty: 57,
        resource3: 'mana',
        r3Qty: 8,
        component1: 'healthpotion',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'overgrownvine',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-11',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 335,
        surcharge: 740,
        suggest: 150,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-11-19',
        shardPrice: 180,
    },
    soulfireextract: {
        uid: 'soulfireextract',
        level: 27,
        type: 'up',
        xp: 45000,
        craftXp: 675,
        value: 295000,
        favor: 1224,
        time: 16200,
        atk: 0,
        def: 0,
        hp: 46,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'herbs',
        r1Qty: 244,
        resource2: 'oils',
        r2Qty: 67,
        resource3: 'mana',
        r3Qty: 13,
        component1: 'light',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'ectoplasm',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:gaiatonic',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'cquality1-1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 380,
        surcharge: 845,
        suggest: 170,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lemonpotion: {
        uid: 'lemonpotion',
        level: 28,
        type: 'up',
        xp: 48500,
        craftXp: 728,
        value: 405000,
        favor: 1589,
        time: 21000,
        atk: 95,
        def: 0,
        hp: 48,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'herbs',
        r1Qty: 259,
        resource2: 'oils',
        r2Qty: 72,
        resource3: 'mana',
        r3Qty: 16,
        component1: 'sewingthread',
        c1Qty: 3,
        c1Tags: null,
        component2: 'overgrownvine',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 445,
        surcharge: 985,
        suggest: 195,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-05-19',
        shardPrice: 200,
    },
    carpenterpotion: {
        uid: 'carpenterpotion',
        level: 29,
        type: 'up',
        xp: 52500,
        craftXp: 788,
        value: 410000,
        favor: 1847,
        time: 25200,
        atk: 0,
        def: 100,
        hp: 50,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'wood',
        r1Qty: 275,
        resource2: 'ironwood',
        r2Qty: 77,
        resource3: 'mana',
        r3Qty: 19,
        component1: 'ectoplasm',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 445,
        surcharge: 990,
        suggest: 200,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-07-15',
        shardPrice: 200,
    },
    gaiatonic: {
        uid: 'gaiatonic',
        level: 30,
        type: 'up',
        xp: 56500,
        craftXp: 848,
        value: 620000,
        favor: 1909,
        time: 30000,
        atk: 0,
        def: 0,
        hp: 53,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'herbs',
        r1Qty: 290,
        resource2: 'oils',
        r2Qty: 82,
        resource3: 'mana',
        r3Qty: 22,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: 'overgrownvine',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:starextract',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 815,
        surcharge: 1810,
        suggest: 360,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    batpotion: {
        uid: 'batpotion',
        level: 30,
        type: 'up',
        xp: 56500,
        craftXp: 848,
        value: 645000,
        favor: 2128,
        time: 30000,
        atk: 105,
        def: 0,
        hp: 53,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'herbs',
        r1Qty: 290,
        resource2: 'oils',
        r2Qty: 82,
        resource3: 'mana',
        r3Qty: 22,
        component1: 'sirenshard',
        c1Qty: 4,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-16',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 830,
        surcharge: 1845,
        suggest: 370,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-10-21',
        shardPrice: 250,
    },
    goldpotion: {
        uid: 'goldpotion',
        level: 31,
        type: 'up',
        xp: 60500,
        craftXp: 908,
        value: 1550000,
        favor: 2451,
        time: 36000,
        atk: 0,
        def: 0,
        hp: 55,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 6,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'herbs',
        r1Qty: 306,
        resource2: 'oils',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'tabletjewel',
        c1Qty: 4,
        c1Tags: null,
        component2: 'sirenshard',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 950,
        surcharge: 2110,
        suggest: 420,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-12-25',
        shardPrice: 0,
    },
    starextract: {
        uid: 'starextract',
        level: 33,
        type: 'up',
        xp: 105000,
        craftXp: 1575,
        value: 1600000,
        favor: 2889,
        time: 51600,
        atk: 0,
        def: 0,
        hp: 68,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 338,
        resource2: 'oils',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1110,
        surcharge: 2470,
        suggest: 495,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    yetipotion: {
        uid: 'yetipotion',
        level: 33,
        type: 'up',
        xp: 105000,
        craftXp: 1575,
        value: 1800000,
        favor: 3218,
        time: 51600,
        atk: 0,
        def: 135,
        hp: 68,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 338,
        resource2: 'oils',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'sirenshard',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 1175,
        surcharge: 2615,
        suggest: 525,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-08-11',
        shardPrice: 300,
    },
    frogpotion: {
        uid: 'frogpotion',
        level: 34,
        type: 'up',
        xp: 110000,
        craftXp: 1650,
        value: 1700000,
        favor: 3047,
        time: 61800,
        atk: 140,
        def: 0,
        hp: 71,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'herbalist',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 300,
        resource2: 'oils',
        r2Qty: 103,
        resource3: 'mana',
        r3Qty: 34,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'overgrownvine',
        c2Qty: 10,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource2-21',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 1145,
        surcharge: 2545,
        suggest: 510,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Glass',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-01-11',
        shardPrice: 300,
    },
    scrolloffire: {
        uid: 'scrolloffire',
        level: 3,
        type: 'us',
        xp: 495,
        craftXp: 7,
        value: 320,
        favor: 10,
        time: 70,
        atk: 44,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 9,
        worker1: 'wizard',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'bp:scrollofthunder',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 15,
        surcharge: 30,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    forestscroll: {
        uid: 'forestscroll',
        level: 4,
        type: 'us',
        xp: 840,
        craftXp: 13,
        value: 690,
        favor: 15,
        time: 100,
        atk: 60,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 1,
        combo: 9,
        worker1: 'wizard',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 12,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-2',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'light',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 20,
        surcharge: 45,
        suggest: 10,
        speedup: 55,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: 'forest',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    scrollofthunder: {
        uid: 'scrollofthunder',
        level: 6,
        type: 'us',
        xp: 1900,
        craftXp: 29,
        value: 1050,
        favor: 19,
        time: 200,
        atk: 96,
        def: 24,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'wizard',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 16,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'cuirass',
        c1Qty: 1,
        c1Tags: 'common',
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:scrollofprotection',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 55,
        suggest: 10,
        speedup: 90,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    scrollofprotection: {
        uid: 'scrollofprotection',
        level: 9,
        type: 'us',
        xp: 4400,
        craftXp: 66,
        value: 2750,
        favor: 35,
        time: 500,
        atk: 156,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'wizard',
        worker2: null,
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 26,
        resource2: 'leather',
        r2Qty: 13,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:songofvalor',
        upgrade4: 'resource1-5',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 90,
        suggest: 20,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 4,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    songofvalor: {
        uid: 'songofvalor',
        level: 12,
        type: 'us',
        xp: 8150,
        craftXp: 122,
        value: 6900,
        favor: 93,
        time: 1050,
        atk: 220,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 8,
        worker1: 'wizard',
        worker2: null,
        worker3: null,
        w1BuildingReq: 9,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 41,
        resource2: 'leather',
        r2Qty: 20,
        resource3: 'oils',
        r3Qty: 8,
        component1: 'livingroots',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:scrollofice',
        upgrade4: 'resource1-8',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 65,
        surcharge: 140,
        suggest: 30,
        speedup: 260,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    correspondence: {
        uid: 'correspondence',
        level: 13,
        type: 'us',
        xp: 9650,
        craftXp: 145,
        value: 13500,
        favor: 129,
        time: 1320,
        atk: 240,
        def: 48,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'wizard',
        worker2: null,
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 49,
        resource2: 'leather',
        r2Qty: 25,
        resource3: 'oils',
        r3Qty: 10,
        component1: 'arcanedust',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-10',
        upgrade4: 'resource2-5',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-2',
        supgrade2: 'component1-2',
        supgrade3: 'multi+0.1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 85,
        surcharge: 190,
        suggest: 40,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-06-02',
        shardPrice: 100,
    },
    scrollofice: {
        uid: 'scrollofice',
        level: 14,
        type: 'us',
        xp: 11500,
        craftXp: 173,
        value: 15000,
        favor: 156,
        time: 1680,
        atk: 270,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 60,
        resource2: 'leather',
        r2Qty: 30,
        resource3: 'oils',
        r3Qty: 12,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'value*1.25',
        upgrade3: 'bp:scrollofinvisibility',
        upgrade4: 'resource1-12',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 90,
        surcharge: 200,
        suggest: 40,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 12,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    swampscroll: {
        uid: 'swampscroll',
        level: 15,
        type: 'us',
        xp: 13000,
        craftXp: 195,
        value: 20500,
        favor: 150,
        time: 2040,
        atk: 290,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 5,
        combo: 8,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 71,
        resource2: 'leather',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'value*1.25',
        upgrade3: 'resource1-14',
        upgrade4: 'resource2-7',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'unholy',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 105,
        surcharge: 235,
        suggest: 45,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: 'swamp',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    tomeofknowledge: {
        uid: 'tomeofknowledge',
        level: 15,
        type: 'us',
        xp: 13000,
        craftXp: 195,
        value: 22000,
        favor: 223,
        time: 2040,
        atk: 290,
        def: 58,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 71,
        resource2: 'leather',
        r2Qty: 36,
        resource3: 'oils',
        r3Qty: 16,
        component1: 'livingroots',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-14',
        upgrade3: 'value*1.25',
        upgrade4: 'component1-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-18',
        supgrade2: 'multi+0.1',
        supgrade3: 'resource3-8',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'owl',
        tag: null,
        discount: 110,
        surcharge: 245,
        suggest: 50,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-08-27',
        shardPrice: 120,
    },
    scrollofinvisibility: {
        uid: 'scrollofinvisibility',
        level: 17,
        type: 'us',
        xp: 17000,
        craftXp: 255,
        value: 32500,
        favor: 349,
        time: 3300,
        atk: 340,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 10,
        w3BuildingReq: 4,
        resource1: 'herbs',
        r1Qty: 99,
        resource2: 'leather',
        r2Qty: 49,
        resource3: 'oils',
        r3Qty: 25,
        component1: 'mooncrystal',
        c1Qty: 2,
        c1Tags: null,
        component2: 'whitesand',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:scrolloflevitation',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 135,
        surcharge: 295,
        suggest: 60,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 17,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    christmascroll: {
        uid: 'christmascroll',
        level: 18,
        type: 'us',
        xp: 19500,
        craftXp: 293,
        value: 44500,
        favor: 465,
        time: 3600,
        atk: 370,
        def: 0,
        hp: 12,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'herbs',
        r1Qty: 113,
        resource2: 'leather',
        r2Qty: 56,
        resource3: 'oils',
        r3Qty: 29,
        component1: 'christmasflower',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'whitesand',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 155,
        surcharge: 340,
        suggest: 70,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2019-12-09',
        shardPrice: 140,
    },
    scrolloflevitation: {
        uid: 'scrolloflevitation',
        level: 20,
        type: 'us',
        xp: 24500,
        craftXp: 368,
        value: 71000,
        favor: 622,
        time: 6000,
        atk: 420,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'herbs',
        r1Qty: 141,
        resource2: 'leather',
        r2Qty: 70,
        resource3: 'oils',
        r3Qty: 36,
        component1: 'scrollofprotection',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'evileye',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:tarot',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 190,
        surcharge: 425,
        suggest: 85,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    fireworksscroll: {
        uid: 'fireworksscroll',
        level: 21,
        type: 'us',
        xp: 27000,
        craftXp: 405,
        value: 115000,
        favor: 791,
        time: 7200,
        atk: 450,
        def: 90,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'herbs',
        r1Qty: 155,
        resource2: 'leather',
        r2Qty: 78,
        resource3: 'oils',
        r3Qty: 39,
        component1: 'starmetal',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-16',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 240,
        surcharge: 535,
        suggest: 105,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2019-06-27',
        shardPrice: 160,
    },
    tarot: {
        uid: 'tarot',
        level: 24,
        type: 'us',
        xp: 36000,
        craftXp: 540,
        value: 195000,
        favor: 771,
        time: 13800,
        atk: 530,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'herbs',
        r1Qty: 199,
        resource2: 'oils',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 7,
        component1: 'ancientmarble',
        c1Qty: 4,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:grimoireaeternum',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 310,
        surcharge: 690,
        suggest: 140,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    vipscroll: {
        uid: 'vipscroll',
        level: 25,
        type: 'us',
        xp: 39500,
        craftXp: 593,
        value: 225000,
        favor: 1001,
        time: 16800,
        atk: 560,
        def: 0,
        hp: 17,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 7,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'herbs',
        r1Qty: 214,
        resource2: 'oils',
        r2Qty: 57,
        resource3: 'mana',
        r3Qty: 8,
        component1: 'timecrystal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'livingroots',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 335,
        surcharge: 740,
        suggest: 150,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-01-12',
        shardPrice: 180,
    },
    grimoireaeternum: {
        uid: 'grimoireaeternum',
        level: 27,
        type: 'us',
        xp: 46500,
        craftXp: 698,
        value: 320000,
        favor: 1224,
        time: 18000,
        atk: 620,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'herbs',
        r1Qty: 244,
        resource2: 'oils',
        r2Qty: 67,
        resource3: 'mana',
        r3Qty: 13,
        component1: 'sewingthread',
        c1Qty: 5,
        c1Tags: null,
        component2: 'arcanedust',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:celestialscroll',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 395,
        surcharge: 880,
        suggest: 175,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    waxtablet: {
        uid: 'waxtablet',
        level: 28,
        type: 'us',
        xp: 50500,
        craftXp: 758,
        value: 425000,
        favor: 1589,
        time: 21600,
        atk: 640,
        def: 128,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'herbs',
        r1Qty: 259,
        resource2: 'oils',
        r2Qty: 72,
        resource3: 'mana',
        r3Qty: 16,
        component1: 'ectoplasm',
        c1Qty: 4,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-6',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 450,
        surcharge: 1005,
        suggest: 200,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-07-28',
        shardPrice: 200,
    },
    goldscroll: {
        uid: 'goldscroll',
        level: 29,
        type: 'us',
        xp: 54500,
        craftXp: 818,
        value: 610000,
        favor: 1847,
        time: 25800,
        atk: 670,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'herbs',
        r1Qty: 275,
        resource2: 'oils',
        r2Qty: 77,
        resource3: 'mana',
        r3Qty: 19,
        component1: 'tabletfragment',
        c1Qty: 5,
        c1Tags: null,
        component2: 'ancientmarble',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 405,
        surcharge: 895,
        suggest: 180,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-09-05',
        shardPrice: 0,
    },
    celestialscroll: {
        uid: 'celestialscroll',
        level: 30,
        type: 'us',
        xp: 58500,
        craftXp: 878,
        value: 620000,
        favor: 1909,
        time: 27000,
        atk: 700,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: 'priest',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'herbs',
        r1Qty: 290,
        resource2: 'oils',
        r2Qty: 82,
        resource3: 'mana',
        r3Qty: 22,
        component1: 'scrolloflevitation',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'goldshell',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:angelscroll',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 815,
        surcharge: 1810,
        suggest: 360,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    priestbook: {
        uid: 'priestbook',
        level: 30,
        type: 'us',
        xp: 58500,
        craftXp: 878,
        value: 655000,
        favor: 2128,
        time: 30600,
        atk: 700,
        def: 140,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: 'priest',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'herbs',
        r1Qty: 290,
        resource2: 'oils',
        r2Qty: 82,
        resource3: 'mana',
        r3Qty: 22,
        component1: 'sirenshard',
        c1Qty: 4,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-16',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 835,
        surcharge: 1855,
        suggest: 370,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-10-08',
        shardPrice: 250,
    },
    celticscroll: {
        uid: 'celticscroll',
        level: 31,
        type: 'us',
        xp: 63000,
        craftXp: 945,
        value: 705000,
        favor: 2451,
        time: 33000,
        atk: 730,
        def: 0,
        hp: 23,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: 'priest',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'herbs',
        r1Qty: 306,
        resource2: 'oils',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'noblering',
        c1Qty: 2,
        c1Tags: 'uncommon',
        component2: 'sirenshard',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 865,
        surcharge: 1925,
        suggest: 385,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-03-10',
        shardPrice: 250,
    },
    angelscroll: {
        uid: 'angelscroll',
        level: 33,
        type: 'us',
        xp: 110000,
        craftXp: 1650,
        value: 1400000,
        favor: 2889,
        time: 52800,
        atk: 910,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 338,
        resource2: 'oils',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 1045,
        surcharge: 2320,
        suggest: 465,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    peakscroll: {
        uid: 'peakscroll',
        level: 33,
        type: 'us',
        xp: 110000,
        craftXp: 1650,
        value: 1550000,
        favor: 3218,
        time: 52800,
        atk: 910,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 5,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 338,
        resource2: 'oils',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'goldshell',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.75',
        upgrade2: 'value*1.25',
        upgrade3: 'resource1-68',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'blaze',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 1095,
        surcharge: 2435,
        suggest: 485,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: 'peak',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    freedomscroll: {
        uid: 'freedomscroll',
        level: 34,
        type: 'us',
        xp: 115000,
        craftXp: 1725,
        value: 1600000,
        favor: 3047,
        time: 63000,
        atk: 950,
        def: 0,
        hp: 30,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 300,
        resource2: 'fabric',
        r2Qty: 90,
        resource3: 'mana',
        r3Qty: 34,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'sewingthread',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-60',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-18',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-10',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1110,
        surcharge: 2470,
        suggest: 495,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-06-30',
        shardPrice: 300,
    },
    lunarscroll: {
        uid: 'lunarscroll',
        level: 34,
        type: 'us',
        xp: 115000,
        craftXp: 1725,
        value: 1750000,
        favor: 3671,
        time: 63000,
        atk: 950,
        def: 190,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 354,
        resource2: 'oils',
        r2Qty: 103,
        resource3: 'mana',
        r3Qty: 34,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'goldshell',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-71',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-21',
        supgrade2: 'multi+0.1',
        supgrade3: 'resource3-10',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'tiger',
        tag: null,
        discount: 1160,
        surcharge: 2580,
        suggest: 515,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-01-26',
        shardPrice: 300,
    },
    allknowledge: {
        uid: 'allknowledge',
        level: 35,
        type: 'us',
        xp: 125000,
        craftXp: 1875,
        value: 2000000,
        favor: 4167,
        time: 65400,
        atk: 990,
        def: 198,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'special',
        tier: 11,
        combo: 5,
        worker1: 'wizard',
        worker2: 'herbalist',
        worker3: 'priest',
        w1BuildingReq: 28,
        w2BuildingReq: 24,
        w3BuildingReq: 21,
        resource1: 'herbs',
        r1Qty: 371,
        resource2: 'oils',
        r2Qty: 108,
        resource3: 'mana',
        r3Qty: 36,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'archivistglasses',
        c2Qty: 1,
        c2Tags: 'common',
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'value*1.25',
        upgrade3: 'resource1-74',
        upgrade4: 'resource2-22',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'resource3-7',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1240,
        surcharge: 2750,
        suggest: 550,
        speedup: 3120,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Paper',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    cuirass: {
        uid: 'cuirass',
        level: 1,
        type: 'ah',
        xp: 140,
        craftXp: 2,
        value: 60,
        favor: 1,
        time: 15,
        atk: 0,
        def: 8,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:ironcap',
        upgrade2: 'bp:ringmail',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-4',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 15,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ringmail: {
        uid: 'ringmail',
        level: 4,
        type: 'ah',
        xp: 920,
        craftXp: 14,
        value: 430,
        favor: 13,
        time: 110,
        atk: 0,
        def: 22,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 12,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'bp:scalemail',
        upgrade2: 'component1-1',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-9',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 15,
        surcharge: 35,
        suggest: 5,
        speedup: 55,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    scalemail: {
        uid: 'scalemail',
        level: 7,
        type: 'ah',
        xp: 2850,
        craftXp: 43,
        value: 1600,
        favor: 21,
        time: 300,
        atk: 0,
        def: 40,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 17,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:chainmail',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'quality+2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 30,
        surcharge: 70,
        suggest: 15,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    chainmail: {
        uid: 'chainmail',
        level: 10,
        type: 'ah',
        xp: 6100,
        craftXp: 92,
        value: 3650,
        favor: 42,
        time: 600,
        atk: 0,
        def: 62,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 7,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 30,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'ringmail',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'preciousgem',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:breastplate',
        upgrade4: 'resource1-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 45,
        surcharge: 105,
        suggest: 20,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    breastplate: {
        uid: 'breastplate',
        level: 13,
        type: 'ah',
        xp: 10500,
        craftXp: 158,
        value: 8450,
        favor: 81,
        time: 1380,
        atk: 0,
        def: 84,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 49,
        resource2: 'steel',
        r2Qty: 10,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-10',
        upgrade3: 'bp:halfplate',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 155,
        suggest: 30,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    swampheavyarmor: {
        uid: 'swampheavyarmor',
        level: 14,
        type: 'ah',
        xp: 12500,
        craftXp: 188,
        value: 11500,
        favor: 119,
        time: 1740,
        atk: 0,
        def: 92,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 60,
        resource2: 'steel',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-12',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-2',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'holy',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 80,
        surcharge: 180,
        suggest: 35,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'swamp',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    centurionarmor: {
        uid: 'centurionarmor',
        level: 16,
        type: 'ah',
        xp: 16500,
        craftXp: 248,
        value: 19000,
        favor: 193,
        time: 2700,
        atk: 0,
        def: 105,
        hp: 7,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 8,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 85,
        resource2: 'leather',
        r2Qty: 43,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 3,
        c1Tags: null,
        component2: 'redpinecone',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-17',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 100,
        surcharge: 225,
        suggest: 45,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-07-28',
        shardPrice: 120,
    },
    halfplate: {
        uid: 'halfplate',
        level: 17,
        type: 'ah',
        xp: 19000,
        craftXp: 285,
        value: 23500,
        favor: 216,
        time: 3000,
        atk: 0,
        def: 115,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 1,
        w3BuildingReq: 4,
        resource1: 'iron',
        r1Qty: 99,
        resource2: 'steel',
        r2Qty: 25,
        resource3: null,
        r3Qty: 0,
        component1: 'scalemail',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'sharpfang',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:ronindo',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 115,
        surcharge: 250,
        suggest: 50,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    beeheavyarmor: {
        uid: 'beeheavyarmor',
        level: 18,
        type: 'ah',
        xp: 21500,
        craftXp: 323,
        value: 34500,
        favor: 296,
        time: 4200,
        atk: 31,
        def: 125,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 1,
        w3BuildingReq: 5,
        resource1: 'iron',
        r1Qty: 113,
        resource2: 'steel',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'ringmail',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'sharpfang',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 135,
        surcharge: 300,
        suggest: 60,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-06-01',
        shardPrice: 140,
    },
    ronindo: {
        uid: 'ronindo',
        level: 21,
        type: 'ah',
        xp: 30000,
        craftXp: 450,
        value: 84000,
        favor: 732,
        time: 7800,
        atk: 0,
        def: 150,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'iron',
        r1Qty: 155,
        resource2: 'leather',
        r2Qty: 78,
        resource3: 'steel',
        r3Qty: 39,
        component1: 'starmetal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'arcanedust',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'bp:generalplate',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-5',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 205,
        surcharge: 460,
        suggest: 90,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    pyramidheavyarmor: {
        uid: 'pyramidheavyarmor',
        level: 22,
        type: 'ah',
        xp: 33000,
        craftXp: 495,
        value: 105000,
        favor: 938,
        time: 9000,
        atk: 0,
        def: 160,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 19,
        w2BuildingReq: 15,
        w3BuildingReq: 9,
        resource1: 'iron',
        r1Qty: 170,
        resource2: 'leather',
        r2Qty: 85,
        resource3: 'steel',
        r3Qty: 43,
        component1: 'scholartunic',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'silkscarab',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-34',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-9',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'tidal',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 230,
        surcharge: 515,
        suggest: 105,
        speedup: 735,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'pyramid',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    atlab_heavyarmor: {
        uid: 'atlab_heavyarmor',
        level: 22,
        type: 'ah',
        xp: 33000,
        craftXp: 495,
        value: 145000,
        favor: 591,
        time: 9600,
        atk: 40,
        def: 160,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 19,
        w2BuildingReq: 15,
        w3BuildingReq: 9,
        resource1: 'iron',
        r1Qty: 170,
        resource2: 'leather',
        r2Qty: 85,
        resource3: null,
        r3Qty: 0,
        component1: 'silkscarab',
        c1Qty: 4,
        c1Tags: null,
        component2: 'whitesand',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-34',
        upgrade3: 'value*1.25',
        upgrade4: 'component1-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-17',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 270,
        surcharge: 600,
        suggest: 120,
        speedup: 735,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-17',
        shardPrice: 160,
    },
    lunararmor: {
        uid: 'lunararmor',
        level: 23,
        type: 'ah',
        xp: 36500,
        craftXp: 548,
        value: 165000,
        favor: 1098,
        time: 12000,
        atk: 0,
        def: 170,
        hp: 11,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 20,
        w2BuildingReq: 16,
        w3BuildingReq: 10,
        resource1: 'iron',
        r1Qty: 184,
        resource2: 'leather',
        r2Qty: 92,
        resource3: 'steel',
        r3Qty: 47,
        component1: 'starmetal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-37',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-18',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-9',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 290,
        surcharge: 640,
        suggest: 130,
        speedup: 795,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-01-22',
        shardPrice: 160,
    },
    generalplate: {
        uid: 'generalplate',
        level: 24,
        type: 'ah',
        xp: 40000,
        craftXp: 600,
        value: 185000,
        favor: 771,
        time: 14400,
        atk: 0,
        def: 180,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'iron',
        r1Qty: 199,
        resource2: 'steel',
        r2Qty: 51,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'ancientmarble',
        c1Qty: 6,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:berserkerarmor',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 305,
        surcharge: 675,
        suggest: 135,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    oktoberfestplate: {
        uid: 'oktoberfestplate',
        level: 25,
        type: 'ah',
        xp: 44000,
        craftXp: 660,
        value: 225000,
        favor: 1001,
        time: 17400,
        atk: 0,
        def: 190,
        hp: 12,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'iron',
        r1Qty: 214,
        resource2: 'steel',
        r2Qty: 57,
        resource3: 'gems',
        r3Qty: 8,
        component1: 'timecrystal',
        c1Qty: 3,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 335,
        surcharge: 740,
        suggest: 150,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-10-11',
        shardPrice: 180,
    },
    berserkerarmor: {
        uid: 'berserkerarmor',
        level: 27,
        type: 'ah',
        xp: 51500,
        craftXp: 773,
        value: 310000,
        favor: 1224,
        time: 18600,
        atk: 0,
        def: 205,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'iron',
        r1Qty: 244,
        resource2: 'steel',
        r2Qty: 67,
        resource3: 'gems',
        r3Qty: 13,
        component1: 'ghostflag',
        c1Qty: 4,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:juggernaut',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 390,
        surcharge: 865,
        suggest: 175,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    castleheavyarmor: {
        uid: 'castleheavyarmor',
        level: 28,
        type: 'ah',
        xp: 56000,
        craftXp: 840,
        value: 365000,
        favor: 1589,
        time: 22200,
        atk: 0,
        def: 215,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'iron',
        r1Qty: 259,
        resource2: 'steel',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'ghostflag',
        c1Qty: 4,
        c1Tags: null,
        component2: 'livingroots',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'primal',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 420,
        surcharge: 935,
        suggest: 185,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'castle',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    freedomplate: {
        uid: 'freedomplate',
        level: 29,
        type: 'ah',
        xp: 60500,
        craftXp: 908,
        value: 425000,
        favor: 1847,
        time: 27000,
        atk: 0,
        def: 225,
        hp: 14,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'iron',
        r1Qty: 275,
        resource2: 'steel',
        r2Qty: 77,
        resource3: 'gems',
        r3Qty: 19,
        component1: 'sewingthread',
        c1Qty: 4,
        c1Tags: null,
        component2: 'darkscale',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-6',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 450,
        surcharge: 1005,
        suggest: 200,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-06-30',
        shardPrice: 200,
    },
    juggernaut: {
        uid: 'juggernaut',
        level: 31,
        type: 'ah',
        xp: 69500,
        craftXp: 1043,
        value: 610000,
        favor: 2200,
        time: 38400,
        atk: 0,
        def: 245,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'tailor',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 306,
        resource2: 'steel',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'deepcoral',
        c1Qty: 4,
        c1Tags: null,
        component2: 'timecrystal',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'bp:celestialplate',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 810,
        surcharge: 1795,
        suggest: 360,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    templearmor: {
        uid: 'templearmor',
        level: 31,
        type: 'ah',
        xp: 69500,
        craftXp: 1043,
        value: 640000,
        favor: 2451,
        time: 33600,
        atk: 0,
        def: 245,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'tailor',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 306,
        resource2: 'steel',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'largehealthpotion',
        c1Qty: 2,
        c1Tags: 'common',
        component2: 'goldshell',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'blaze',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 825,
        surcharge: 1835,
        suggest: 365,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'temple',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldplate: {
        uid: 'goldplate',
        level: 31,
        type: 'ah',
        xp: 69500,
        craftXp: 1043,
        value: 1500000,
        favor: 2451,
        time: 38400,
        atk: 0,
        def: 245,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'tailor',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 306,
        resource2: 'steel',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'tabletjewel',
        c1Qty: 4,
        c1Tags: null,
        component2: 'sirenshard',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 935,
        surcharge: 2075,
        suggest: 415,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-11-28',
        shardPrice: 0,
    },
    celticheavyarmor: {
        uid: 'celticheavyarmor',
        level: 31,
        type: 'ah',
        xp: 69500,
        craftXp: 1043,
        value: 685000,
        favor: 2451,
        time: 38400,
        atk: 0,
        def: 245,
        hp: 15,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'tailor',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 306,
        resource2: 'steel',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'deepcoral',
        c1Qty: 4,
        c1Tags: null,
        component2: 'timecrystal',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 855,
        surcharge: 1900,
        suggest: 380,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-03-09',
        shardPrice: 250,
    },
    celestialplate: {
        uid: 'celestialplate',
        level: 33,
        type: 'ah',
        xp: 120000,
        craftXp: 1800,
        value: 1550000,
        favor: 2889,
        time: 55200,
        atk: 0,
        def: 305,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'tailor',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 338,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 1095,
        surcharge: 2435,
        suggest: 485,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    dogheavyarmor: {
        uid: 'dogheavyarmor',
        level: 33,
        type: 'ah',
        xp: 120000,
        craftXp: 1800,
        value: 1750000,
        favor: 3120,
        time: 55200,
        atk: 76,
        def: 305,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'tailor',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 338,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 26,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'ghostflag',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'wolf',
        tag: null,
        discount: 1160,
        surcharge: 2580,
        suggest: 515,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-08-24',
        shardPrice: 300,
    },
    atlab_fireplate: {
        uid: 'atlab_fireplate',
        level: 33,
        type: 'ah',
        xp: 120000,
        craftXp: 1800,
        value: 1800000,
        favor: 3240,
        time: 48000,
        atk: 0,
        def: 305,
        hp: 19,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'tailor',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 340,
        resource2: 'steel',
        r2Qty: 100,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'blaze',
        c2Qty: 1,
        c2Tags: 'common',
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.6',
        upgrade2: 'resource1-68',
        upgrade3: 'resource2-20',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 1175,
        surcharge: 2615,
        suggest: 525,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-03',
        shardPrice: 300,
    },
    leatherarmor: {
        uid: 'leatherarmor',
        level: 1,
        type: 'am',
        xp: 140,
        craftXp: 2,
        value: 60,
        favor: 1,
        time: 15,
        atk: 0,
        def: 8,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:padded',
        upgrade2: 'bp:leathercap',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-4',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 15,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    padded: {
        uid: 'padded',
        level: 3,
        type: 'am',
        xp: 515,
        craftXp: 8,
        value: 190,
        favor: 10,
        time: 70,
        atk: 0,
        def: 16,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'bp:doublet',
        upgrade2: 'value*1.5',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-7',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 25,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    doublet: {
        uid: 'doublet',
        level: 6,
        type: 'am',
        xp: 1950,
        craftXp: 29,
        value: 1100,
        favor: 19,
        time: 220,
        atk: 0,
        def: 32,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 16,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:studded',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 60,
        suggest: 10,
        speedup: 90,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    studded: {
        uid: 'studded',
        level: 9,
        type: 'am',
        xp: 4600,
        craftXp: 69,
        value: 2500,
        favor: 35,
        time: 510,
        atk: 0,
        def: 52,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 26,
        resource2: 'iron',
        r2Qty: 6,
        resource3: null,
        r3Qty: 0,
        component1: 'webbedwings',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:hide',
        upgrade4: 'resource1-5',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 85,
        suggest: 15,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 5,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    winterarmor: {
        uid: 'winterarmor',
        level: 11,
        type: 'am',
        xp: 7000,
        craftXp: 105,
        value: 6000,
        favor: 56,
        time: 840,
        atk: 16,
        def: 64,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 34,
        resource2: 'iron',
        r2Qty: 9,
        resource3: null,
        r3Qty: 0,
        component1: 'arcanedust',
        c1Qty: 3,
        c1Tags: null,
        component2: 'redpinecone',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-7',
        upgrade4: 'component1-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 60,
        surcharge: 130,
        suggest: 25,
        speedup: 225,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-12-01',
        shardPrice: 100,
    },
    hide: {
        uid: 'hide',
        level: 13,
        type: 'am',
        xp: 10000,
        craftXp: 150,
        value: 8900,
        favor: 81,
        time: 1350,
        atk: 0,
        def: 80,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 49,
        resource2: 'fabric',
        r2Qty: 10,
        resource3: null,
        r3Qty: 0,
        component1: 'livingroots',
        c1Qty: 3,
        c1Tags: null,
        component2: 'elvenwood',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'value*1.25',
        upgrade3: 'bp:savagearmor',
        upgrade4: 'resource1-10',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-4',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 155,
        suggest: 30,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    mundraarmor: {
        uid: 'mundraarmor',
        level: 14,
        type: 'am',
        xp: 12000,
        craftXp: 360,
        value: 16500,
        favor: 119,
        time: 1710,
        atk: 22,
        def: 86,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'moondragon',
        worker2: null,
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 60,
        resource2: 'fabric',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 3,
        c1Tags: null,
        component2: 'webbedwings',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:mundrastaff',
        upgrade4: 'resource1-12',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: 'dragon',
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 95,
        surcharge: 210,
        suggest: 40,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    savagearmor: {
        uid: 'savagearmor',
        level: 17,
        type: 'am',
        xp: 18000,
        craftXp: 270,
        value: 24500,
        favor: 349,
        time: 3300,
        atk: 0,
        def: 110,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 1,
        w3BuildingReq: 4,
        resource1: 'leather',
        r1Qty: 99,
        resource2: 'herbs',
        r2Qty: 49,
        resource3: 'fabric',
        r3Qty: 25,
        component1: 'sharpfang',
        c1Qty: 3,
        c1Tags: null,
        component2: 'webbedwings',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:ninjagarb',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'lizard',
        tag: null,
        discount: 115,
        surcharge: 255,
        suggest: 50,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    desertmediumarmor: {
        uid: 'desertmediumarmor',
        level: 18,
        type: 'am',
        xp: 20500,
        craftXp: 308,
        value: 33500,
        favor: 465,
        time: 4200,
        atk: 0,
        def: 115,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 6,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 1,
        w3BuildingReq: 5,
        resource1: 'leather',
        r1Qty: 113,
        resource2: 'herbs',
        r2Qty: 56,
        resource3: 'fabric',
        r3Qty: 29,
        component1: 'horned',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'whitesand',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'wild',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'lizard',
        tag: 'elemental',
        discount: 135,
        surcharge: 295,
        suggest: 60,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'desert',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    pumpkinarmor: {
        uid: 'pumpkinarmor',
        level: 18,
        type: 'am',
        xp: 20500,
        craftXp: 308,
        value: 39500,
        favor: 459,
        time: 4200,
        atk: 29,
        def: 115,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'tailor',
        worker2: 'blacksmith',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'leather',
        r1Qty: 113,
        resource2: 'herbs',
        r2Qty: 56,
        resource3: 'iron',
        r3Qty: 28,
        component1: 'mooncrystal',
        c1Qty: 3,
        c1Tags: null,
        component2: 'webbedwings',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-3',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 145,
        surcharge: 320,
        suggest: 65,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-10-28',
        shardPrice: 140,
    },
    ninjagarb: {
        uid: 'ninjagarb',
        level: 21,
        type: 'am',
        xp: 28500,
        craftXp: 428,
        value: 86000,
        favor: 590,
        time: 7800,
        atk: 0,
        def: 140,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'leather',
        r1Qty: 155,
        resource2: 'fabric',
        r2Qty: 39,
        resource3: 'oils',
        r3Qty: 20,
        component1: 'silkscarab',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'bp:windrunnerarmor',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 210,
        surcharge: 465,
        suggest: 95,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    batarmor: {
        uid: 'batarmor',
        level: 22,
        type: 'am',
        xp: 31500,
        craftXp: 473,
        value: 115000,
        favor: 765,
        time: 9000,
        atk: 38,
        def: 150,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 19,
        w2BuildingReq: 15,
        w3BuildingReq: 9,
        resource1: 'leather',
        r1Qty: 170,
        resource2: 'fabric',
        r2Qty: 43,
        resource3: 'oils',
        r3Qty: 22,
        component1: 'evileye',
        c1Qty: 4,
        c1Tags: null,
        component2: 'darkscale',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 12,
        u2Req: 30,
        u3Req: 54,
        u4Req: 85,
        u5Req: 135,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-34',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 240,
        surcharge: 535,
        suggest: 105,
        speedup: 735,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-10-20',
        shardPrice: 160,
    },
    windrunnerarmor: {
        uid: 'windrunnerarmor',
        level: 24,
        type: 'am',
        xp: 37500,
        craftXp: 563,
        value: 205000,
        favor: 1184,
        time: 13800,
        atk: 0,
        def: 170,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'leather',
        r1Qty: 199,
        resource2: 'herbs',
        r2Qty: 99,
        resource3: 'fabric',
        r3Qty: 51,
        component1: 'studded',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'overgrownvine',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:dragonarmor',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-10',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 320,
        surcharge: 710,
        suggest: 140,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    atlab_armor: {
        uid: 'atlab_armor',
        level: 25,
        type: 'am',
        xp: 41000,
        craftXp: 615,
        value: 245000,
        favor: 922,
        time: 16800,
        atk: 0,
        def: 175,
        hp: 11,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'leather',
        r1Qty: 214,
        resource2: 'fabric',
        r2Qty: 57,
        resource3: null,
        r3Qty: 0,
        component1: 'doublet',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'timecrystal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'value*1.25',
        upgrade3: 'resource1-43',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-17',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 345,
        surcharge: 770,
        suggest: 155,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-16',
        shardPrice: 180,
    },
    dragonarmor: {
        uid: 'dragonarmor',
        level: 27,
        type: 'am',
        xp: 48500,
        craftXp: 728,
        value: 320000,
        favor: 1190,
        time: 18000,
        atk: 0,
        def: 195,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'leather',
        r1Qty: 244,
        resource2: 'fabric',
        r2Qty: 67,
        resource3: 'mana',
        r3Qty: 10,
        component1: 'sewingthread',
        c1Qty: 5,
        c1Tags: null,
        component2: 'webbedwings',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:captainarmor',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 395,
        surcharge: 880,
        suggest: 175,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    smithapron: {
        uid: 'smithapron',
        level: 28,
        type: 'am',
        xp: 52500,
        craftXp: 788,
        value: 425000,
        favor: 1538,
        time: 21600,
        atk: 51,
        def: 205,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'leather',
        r1Qty: 259,
        resource2: 'fabric',
        r2Qty: 72,
        resource3: 'mana',
        r3Qty: 12,
        component1: 'ghostflag',
        c1Qty: 3,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 450,
        surcharge: 1005,
        suggest: 200,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-06-19',
        shardPrice: 200,
    },
    captainarmor: {
        uid: 'captainarmor',
        level: 31,
        type: 'am',
        xp: 65500,
        craftXp: 983,
        value: 600000,
        favor: 2103,
        time: 37800,
        atk: 0,
        def: 235,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 306,
        resource2: 'fabric',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 19,
        component1: 'deepcoral',
        c1Qty: 4,
        c1Tags: null,
        component2: 'sewingthread',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'bp:jackalopearmor',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 800,
        surcharge: 1780,
        suggest: 355,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    siaarmor: {
        uid: 'siaarmor',
        level: 31,
        type: 'am',
        xp: 65500,
        craftXp: 983,
        value: 655000,
        favor: 2352,
        time: 37800,
        atk: 59,
        def: 235,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 306,
        resource2: 'fabric',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 19,
        component1: 'goldshell',
        c1Qty: 3,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 835,
        surcharge: 1855,
        suggest: 370,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-11-04',
        shardPrice: 250,
    },
    lunarmedarmor: {
        uid: 'lunarmedarmor',
        level: 32,
        type: 'am',
        xp: 70500,
        craftXp: 1058,
        value: 680000,
        favor: 2705,
        time: 39000,
        atk: 0,
        def: 240,
        hp: 15,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 26,
        w2BuildingReq: 22,
        w3BuildingReq: 19,
        resource1: 'leather',
        r1Qty: 322,
        resource2: 'fabric',
        r2Qty: 93,
        resource3: 'mana',
        r3Qty: 22,
        component1: 'ninjagarb',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'deepcoral',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-64',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-19',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 850,
        surcharge: 1890,
        suggest: 380,
        speedup: 2635,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-02-09',
        shardPrice: 250,
    },
    jackalopearmor: {
        uid: 'jackalopearmor',
        level: 33,
        type: 'am',
        xp: 115000,
        craftXp: 1725,
        value: 1400000,
        favor: 2736,
        time: 54000,
        atk: 0,
        def: 290,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 23,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1045,
        surcharge: 2320,
        suggest: 465,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldroguearmor: {
        uid: 'goldroguearmor',
        level: 33,
        type: 'am',
        xp: 115000,
        craftXp: 1725,
        value: 3000000,
        favor: 3042,
        time: 54000,
        atk: 0,
        def: 290,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'tabletjewel',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 1125,
        surcharge: 2505,
        suggest: 500,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-05-15',
        shardPrice: 0,
    },
    troblinarmor: {
        uid: 'troblinarmor',
        level: 33,
        type: 'am',
        xp: 115000,
        craftXp: 1725,
        value: 1650000,
        favor: 3995,
        time: 54000,
        atk: 73,
        def: 290,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'carpenter',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 304,
        resource2: 'herbs',
        r2Qty: 169,
        resource3: 'ironwood',
        r3Qty: 88,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource3-18',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'resource3-35',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 1130,
        surcharge: 2510,
        suggest: 500,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-07-13',
        shardPrice: 300,
    },
    catarmor: {
        uid: 'catarmor',
        level: 34,
        type: 'am',
        xp: 120000,
        craftXp: 1800,
        value: 1750000,
        favor: 3479,
        time: 64200,
        atk: 0,
        def: 300,
        hp: 0,
        eva: '0.05',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 354,
        resource2: 'fabric',
        r2Qty: 103,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-71',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-21',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'cat',
        tag: null,
        discount: 1160,
        surcharge: 2580,
        suggest: 515,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-07-27',
        shardPrice: 300,
    },
    tunic: {
        uid: 'tunic',
        level: 1,
        type: 'al',
        xp: 135,
        craftXp: 2,
        value: 50,
        favor: 1,
        time: 15,
        atk: 0,
        def: 7,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 4,
        u2Req: 10,
        u3Req: 18,
        u4Req: 30,
        u5Req: 45,
        upgrade1: 'bp:robe',
        upgrade2: 'bp:leatherarmor',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-4',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 15,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    robe: {
        uid: 'robe',
        level: 3,
        type: 'al',
        xp: 455,
        craftXp: 7,
        value: 280,
        favor: 10,
        time: 60,
        atk: 0,
        def: 15,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:druidic',
        upgrade3: 'resource1-2',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 15,
        surcharge: 30,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    druidic: {
        uid: 'druidic',
        level: 6,
        type: 'al',
        xp: 1700,
        craftXp: 26,
        value: 1100,
        favor: 19,
        time: 200,
        atk: 0,
        def: 30,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 16,
        resource2: 'herbs',
        r2Qty: 8,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:disciplerobe',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 60,
        suggest: 10,
        speedup: 90,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    disciplerobe: {
        uid: 'disciplerobe',
        level: 9,
        type: 'al',
        xp: 3950,
        craftXp: 59,
        value: 2550,
        favor: 35,
        time: 480,
        atk: 0,
        def: 48,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 26,
        resource2: 'herbs',
        r2Qty: 13,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:scholartunic',
        upgrade4: 'resource1-5',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-2',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 85,
        suggest: 15,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    scholartunic: {
        uid: 'scholartunic',
        level: 13,
        type: 'al',
        xp: 8700,
        craftXp: 131,
        value: 8800,
        favor: 100,
        time: 1260,
        atk: 0,
        def: 74,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 49,
        resource2: 'fabric',
        r2Qty: 10,
        resource3: 'oils',
        r3Qty: 5,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-10',
        upgrade3: 'bp:witchrobe',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 155,
        suggest: 30,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 11,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    exploreroutfit: {
        uid: 'exploreroutfit',
        level: 14,
        type: 'al',
        xp: 10000,
        craftXp: 150,
        value: 15000,
        favor: 119,
        time: 1590,
        atk: 21,
        def: 82,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 60,
        resource2: 'fabric',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'livingroots',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-12',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.8',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 90,
        surcharge: 200,
        suggest: 40,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-08-13',
        shardPrice: 120,
    },
    tailorarmor: {
        uid: 'tailorarmor',
        level: 15,
        type: 'al',
        xp: 12000,
        craftXp: 180,
        value: 21500,
        favor: 150,
        time: 2040,
        atk: 0,
        def: 88,
        hp: 6,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 71,
        resource2: 'fabric',
        r2Qty: 16,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-14',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-3',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 110,
        surcharge: 240,
        suggest: 50,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-06-18',
        shardPrice: 120,
    },
    witchrobe: {
        uid: 'witchrobe',
        level: 17,
        type: 'al',
        xp: 15500,
        craftXp: 233,
        value: 24000,
        favor: 279,
        time: 3000,
        atk: 0,
        def: 105,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 10,
        w3BuildingReq: 4,
        resource1: 'leather',
        r1Qty: 99,
        resource2: 'fabric',
        r2Qty: 25,
        resource3: 'oils',
        r3Qty: 12,
        component1: 'robe',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'whitesand',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:midnightapparel',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 115,
        surcharge: 255,
        suggest: 50,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    desertlightarmor: {
        uid: 'desertlightarmor',
        level: 18,
        type: 'al',
        xp: 17500,
        craftXp: 263,
        value: 37500,
        favor: 296,
        time: 3600,
        atk: 0,
        def: 110,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 6,
        combo: 7,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'leather',
        r1Qty: 113,
        resource2: 'fabric',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'sharpfang',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-3',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'holy',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 140,
        surcharge: 315,
        suggest: 65,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'desert',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    wizardrobe: {
        uid: 'wizardrobe',
        level: 19,
        type: 'al',
        xp: 19500,
        craftXp: 293,
        value: 40500,
        favor: 456,
        time: 4800,
        atk: 30,
        def: 120,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 16,
        w2BuildingReq: 12,
        w3BuildingReq: 6,
        resource1: 'leather',
        r1Qty: 127,
        resource2: 'fabric',
        r2Qty: 33,
        resource3: 'oils',
        r3Qty: 16,
        component1: 'robe',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'whitesand',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-25',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-7',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 145,
        surcharge: 325,
        suggest: 65,
        speedup: 565,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-10-08',
        shardPrice: 140,
    },
    midnightapparel: {
        uid: 'midnightapparel',
        level: 20,
        type: 'al',
        xp: 22000,
        craftXp: 330,
        value: 70000,
        favor: 379,
        time: 5400,
        atk: 0,
        def: 125,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'leather',
        r1Qty: 141,
        resource2: 'fabric',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'scrolloffire',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'silkscarab',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:shamanvestment',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'cquality1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 190,
        surcharge: 425,
        suggest: 85,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    atlab_robe: {
        uid: 'atlab_robe',
        level: 21,
        type: 'al',
        xp: 24500,
        craftXp: 368,
        value: 110000,
        favor: 502,
        time: 7200,
        atk: 0,
        def: 135,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'leather',
        r1Qty: 155,
        resource2: 'fabric',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'breeze',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'silkscarab',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'cquality1-1',
        upgrade2: 'resource1-31',
        upgrade3: 'time*0.75',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 235,
        surcharge: 525,
        suggest: 105,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-02',
        shardPrice: 160,
    },
    shamanvestment: {
        uid: 'shamanvestment',
        level: 25,
        type: 'al',
        xp: 35500,
        craftXp: 533,
        value: 190000,
        favor: 896,
        time: 16200,
        atk: 0,
        def: 165,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'leather',
        r1Qty: 214,
        resource2: 'fabric',
        r2Qty: 57,
        resource3: 'mana',
        r3Qty: 8,
        component1: 'overgrownvine',
        c1Qty: 4,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 7,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'bp:astravestimenta',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-3',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 310,
        surcharge: 685,
        suggest: 135,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldvestments: {
        uid: 'goldvestments',
        level: 27,
        type: 'al',
        xp: 42000,
        craftXp: 630,
        value: 400000,
        favor: 1118,
        time: 16800,
        atk: 0,
        def: 185,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 8,
        combo: 6,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'leather',
        r1Qty: 214,
        resource2: 'fabric',
        r2Qty: 57,
        resource3: 'gems',
        r3Qty: 8,
        component1: 'tabletfragment',
        c1Qty: 4,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-4',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 330,
        surcharge: 730,
        suggest: 145,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-11-23',
        shardPrice: 0,
    },
    astravestimenta: {
        uid: 'astravestimenta',
        level: 27,
        type: 'al',
        xp: 42000,
        craftXp: 630,
        value: 335000,
        favor: 1224,
        time: 16800,
        atk: 0,
        def: 185,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'leather',
        r1Qty: 244,
        resource2: 'fabric',
        r2Qty: 67,
        resource3: 'mana',
        r3Qty: 13,
        component1: 'sewingthread',
        c1Qty: 4,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:majesticraiments',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 405,
        surcharge: 900,
        suggest: 180,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    easterarmor2020: {
        uid: 'easterarmor2020',
        level: 28,
        type: 'al',
        xp: 45500,
        craftXp: 683,
        value: 385000,
        favor: 1589,
        time: 20400,
        atk: 48,
        def: 190,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'leather',
        r1Qty: 259,
        resource2: 'fabric',
        r2Qty: 72,
        resource3: 'mana',
        r3Qty: 16,
        component1: 'ghostflag',
        c1Qty: 3,
        c1Tags: null,
        component2: 'whitesand',
        c2Qty: 7,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 430,
        surcharge: 960,
        suggest: 190,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2020-04-07',
        shardPrice: 200,
    },
    arabiantunic: {
        uid: 'arabiantunic',
        level: 29,
        type: 'al',
        xp: 49000,
        craftXp: 735,
        value: 410000,
        favor: 1847,
        time: 24600,
        atk: 0,
        def: 200,
        hp: 13,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'leather',
        r1Qty: 275,
        resource2: 'fabric',
        r2Qty: 77,
        resource3: 'mana',
        r3Qty: 19,
        component1: 'ectoplasm',
        c1Qty: 4,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 445,
        surcharge: 990,
        suggest: 200,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-08-11',
        shardPrice: 200,
    },
    majesticraiments: {
        uid: 'majesticraiments',
        level: 31,
        type: 'al',
        xp: 56500,
        craftXp: 848,
        value: 595000,
        favor: 2200,
        time: 34800,
        atk: 0,
        def: 220,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 306,
        resource2: 'fabric',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'sirenshard',
        c1Qty: 4,
        c1Tags: null,
        component2: 'ghostflag',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'bp:djinnrobes',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 800,
        surcharge: 1775,
        suggest: 355,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    indiadayarmor: {
        uid: 'indiadayarmor',
        level: 31,
        type: 'al',
        xp: 56500,
        craftXp: 848,
        value: 620000,
        favor: 2451,
        time: 34800,
        atk: 0,
        def: 220,
        hp: 14,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 306,
        resource2: 'fabric',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 815,
        surcharge: 1810,
        suggest: 360,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-01-27',
        shardPrice: 250,
    },
    djinnrobes: {
        uid: 'djinnrobes',
        level: 33,
        type: 'al',
        xp: 97500,
        craftXp: 1463,
        value: 1350000,
        favor: 2889,
        time: 50400,
        atk: 0,
        def: 270,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource1-68',
        supgrade2: 'resource3-8',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1025,
        surcharge: 2280,
        suggest: 455,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    moonrobes: {
        uid: 'moonrobes',
        level: 33,
        type: 'al',
        xp: 97500,
        craftXp: 1463,
        value: 1550000,
        favor: 3218,
        time: 50400,
        atk: 68,
        def: 270,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'sirenshard',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-85',
        upgrade3: 'resource2-25',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-8',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1095,
        surcharge: 2435,
        suggest: 485,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-09-21',
        shardPrice: 300,
    },
    lunarrobe: {
        uid: 'lunarrobe',
        level: 34,
        type: 'al',
        xp: 105000,
        craftXp: 1575,
        value: 1700000,
        favor: 3479,
        time: 60000,
        atk: 0,
        def: 280,
        hp: 18,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'priest',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 354,
        resource2: 'fabric',
        r2Qty: 103,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'resource1-89',
        upgrade2: 'resource2-26',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.7',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'tiger',
        tag: null,
        discount: 1145,
        surcharge: 2545,
        suggest: 510,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-01-25',
        shardPrice: 300,
    },
    ironcap: {
        uid: 'ironcap',
        level: 1,
        type: 'hh',
        xp: 140,
        craftXp: 2,
        value: 60,
        favor: 1,
        time: 15,
        atk: 0,
        def: 8,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:helmet',
        upgrade3: 'resource1-1',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-3',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 15,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    helmet: {
        uid: 'helmet',
        level: 4,
        type: 'hh',
        xp: 905,
        craftXp: 14,
        value: 350,
        favor: 13,
        time: 110,
        atk: 0,
        def: 23,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 12,
        resource2: 'leather',
        r2Qty: 6,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:horned',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-9',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 15,
        surcharge: 35,
        suggest: 5,
        speedup: 55,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    horned: {
        uid: 'horned',
        level: 7,
        type: 'hh',
        xp: 2800,
        craftXp: 42,
        value: 1250,
        favor: 21,
        time: 300,
        atk: 0,
        def: 42,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 17,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:vikinghelm',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 60,
        suggest: 10,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    vikinghelm: {
        uid: 'vikinghelm',
        level: 9,
        type: 'hh',
        xp: 4750,
        craftXp: 71,
        value: 3200,
        favor: 35,
        time: 520,
        atk: 0,
        def: 56,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 26,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'arcanedust',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:knighthelm',
        upgrade4: 'resource1-5',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 45,
        surcharge: 95,
        suggest: 20,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    knighthelm: {
        uid: 'knighthelm',
        level: 13,
        type: 'hh',
        xp: 10500,
        craftXp: 158,
        value: 9450,
        favor: 81,
        time: 1320,
        atk: 0,
        def: 88,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 49,
        resource2: 'steel',
        r2Qty: 10,
        resource3: null,
        r3Qty: 0,
        component1: 'helmet',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'darkscale',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-10',
        upgrade3: 'bp:paladinhelm',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 160,
        suggest: 30,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    swamphelm: {
        uid: 'swamphelm',
        level: 14,
        type: 'hh',
        xp: 12500,
        craftXp: 188,
        value: 13500,
        favor: 119,
        time: 1740,
        atk: 0,
        def: 96,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 60,
        resource2: 'steel',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-12',
        upgrade3: 'value*1.25',
        upgrade4: 'resource1-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'tidal',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 85,
        surcharge: 190,
        suggest: 40,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'swamp',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    eaglecowl: {
        uid: 'eaglecowl',
        level: 14,
        type: 'hh',
        xp: 12500,
        craftXp: 188,
        value: 15000,
        favor: 119,
        time: 1740,
        atk: 0,
        def: 96,
        hp: 6,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 60,
        resource2: 'leather',
        r2Qty: 30,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.80',
        upgrade2: 'resource1-12',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.80',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-15',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'eagle',
        tag: null,
        discount: 90,
        surcharge: 200,
        suggest: 40,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-06-27',
        shardPrice: 120,
    },
    paladinhelm: {
        uid: 'paladinhelm',
        level: 17,
        type: 'hh',
        xp: 18500,
        craftXp: 278,
        value: 26000,
        favor: 216,
        time: 3300,
        atk: 0,
        def: 120,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 10,
        w3BuildingReq: 4,
        resource1: 'iron',
        r1Qty: 99,
        resource2: 'steel',
        r2Qty: 25,
        resource3: null,
        r3Qty: 0,
        component1: 'sharpfang',
        c1Qty: 3,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:kabuto',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-4',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 120,
        surcharge: 265,
        suggest: 55,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    couplehelmet: {
        uid: 'couplehelmet',
        level: 18,
        type: 'hh',
        xp: 21000,
        craftXp: 315,
        value: 37500,
        favor: 296,
        time: 4200,
        atk: 0,
        def: 130,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'iron',
        r1Qty: 113,
        resource2: 'steel',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'mooncrystal',
        c1Qty: 3,
        c1Tags: null,
        component2: 'elvenwood',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 140,
        surcharge: 315,
        suggest: 65,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-02-23',
        shardPrice: 140,
    },
    atlab_firehelm: {
        uid: 'atlab_firehelm',
        level: 19,
        type: 'hh',
        xp: 23500,
        craftXp: 353,
        value: 39500,
        favor: 357,
        time: 4800,
        atk: 35,
        def: 140,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'tailor',
        worker3: null,
        w1BuildingReq: 16,
        w2BuildingReq: 12,
        w3BuildingReq: 6,
        resource1: 'iron',
        r1Qty: 127,
        resource2: 'steel',
        r2Qty: 33,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 3,
        c1Tags: null,
        component2: 'icypearl',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 12,
        u2Req: 30,
        u3Req: 54,
        u4Req: 85,
        u5Req: 135,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-25',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-10',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 145,
        surcharge: 320,
        suggest: 65,
        speedup: 565,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-03',
        shardPrice: 140,
    },
    kabuto: {
        uid: 'kabuto',
        level: 21,
        type: 'hh',
        xp: 29500,
        craftXp: 443,
        value: 86000,
        favor: 447,
        time: 7800,
        atk: 0,
        def: 160,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'iron',
        r1Qty: 155,
        resource2: 'steel',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'starmetal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'bp:generalheaume',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 210,
        surcharge: 465,
        suggest: 95,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    gladiatorhelm: {
        uid: 'gladiatorhelm',
        level: 22,
        type: 'hh',
        xp: 32500,
        craftXp: 488,
        value: 115000,
        favor: 591,
        time: 9600,
        atk: 0,
        def: 170,
        hp: 11,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 19,
        w2BuildingReq: 15,
        w3BuildingReq: 9,
        resource1: 'iron',
        r1Qty: 170,
        resource2: 'steel',
        r2Qty: 43,
        resource3: null,
        r3Qty: 0,
        component1: 'evileye',
        c1Qty: 3,
        c1Tags: null,
        component2: 'starmetal',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-34',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-9',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'bear',
        tag: null,
        discount: 240,
        surcharge: 535,
        suggest: 105,
        speedup: 735,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-07-30',
        shardPrice: 160,
    },
    generalheaume: {
        uid: 'generalheaume',
        level: 24,
        type: 'hh',
        xp: 39500,
        craftXp: 593,
        value: 185000,
        favor: 771,
        time: 14400,
        atk: 0,
        def: 190,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'iron',
        r1Qty: 199,
        resource2: 'steel',
        r2Qty: 51,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'ancientmarble',
        c1Qty: 6,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:berserkerhelm',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-10',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 305,
        surcharge: 675,
        suggest: 135,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ruinshelm: {
        uid: 'ruinshelm',
        level: 25,
        type: 'hh',
        xp: 43000,
        craftXp: 645,
        value: 200000,
        favor: 1001,
        time: 17400,
        atk: 0,
        def: 200,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 8,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'iron',
        r1Qty: 214,
        resource2: 'steel',
        r2Qty: 57,
        resource3: 'gems',
        r3Qty: 8,
        component1: 'starmetal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-11',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'holy',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 315,
        surcharge: 700,
        suggest: 140,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'ruins',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lunarhelmet: {
        uid: 'lunarhelmet',
        level: 26,
        type: 'hh',
        xp: 46500,
        craftXp: 698,
        value: 255000,
        favor: 1168,
        time: 21000,
        atk: 0,
        def: 210,
        hp: 13,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 13,
        resource1: 'iron',
        r1Qty: 229,
        resource2: 'steel',
        r2Qty: 62,
        resource3: 'gems',
        r3Qty: 10,
        component1: 'featheredhat',
        c1Qty: 2,
        c1Tags: 'common',
        component2: 'timecrystal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-46',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-12',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-12',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-2',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 355,
        surcharge: 785,
        suggest: 155,
        speedup: 995,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-01-22',
        shardPrice: 180,
    },
    berserkerhelm: {
        uid: 'berserkerhelm',
        level: 27,
        type: 'hh',
        xp: 50500,
        craftXp: 758,
        value: 325000,
        favor: 1224,
        time: 18600,
        atk: 0,
        def: 220,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'iron',
        r1Qty: 244,
        resource2: 'steel',
        r2Qty: 67,
        resource3: 'gems',
        r3Qty: 13,
        component1: 'ghostflag',
        c1Qty: 4,
        c1Tags: null,
        component2: 'darkscale',
        c2Qty: 10,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:juggernauthelm',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-5',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 400,
        surcharge: 885,
        suggest: 175,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    centurionhelmet: {
        uid: 'centurionhelmet',
        level: 28,
        type: 'hh',
        xp: 55000,
        craftXp: 825,
        value: 425000,
        favor: 1589,
        time: 22200,
        atk: 58,
        def: 230,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'iron',
        r1Qty: 259,
        resource2: 'steel',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'sewingthread',
        c1Qty: 4,
        c1Tags: null,
        component2: 'icypearl',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 450,
        surcharge: 1005,
        suggest: 200,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-07-29',
        shardPrice: 200,
    },
    juggernauthelm: {
        uid: 'juggernauthelm',
        level: 30,
        type: 'hh',
        xp: 63500,
        craftXp: 953,
        value: 600000,
        favor: 1909,
        time: 31800,
        atk: 0,
        def: 250,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 290,
        resource2: 'steel',
        r2Qty: 82,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'deepcoral',
        c1Qty: 5,
        c1Tags: null,
        component2: 'starmetal',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:celestialhelm',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 800,
        surcharge: 1780,
        suggest: 355,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    thanksgivinghelmet: {
        uid: 'thanksgivinghelmet',
        level: 31,
        type: 'hh',
        xp: 68500,
        craftXp: 1028,
        value: 675000,
        favor: 2451,
        time: 38400,
        atk: 0,
        def: 260,
        hp: 16,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 306,
        resource2: 'steel',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: 'webbedwings',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-8',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 850,
        surcharge: 1885,
        suggest: 375,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-11-17',
        shardPrice: 250,
    },
    goldhelmet: {
        uid: 'goldhelmet',
        level: 31,
        type: 'hh',
        xp: 68500,
        craftXp: 1028,
        value: 1650000,
        favor: 2451,
        time: 38400,
        atk: 0,
        def: 260,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 306,
        resource2: 'steel',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'tabletjewel',
        c1Qty: 5,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 975,
        surcharge: 2170,
        suggest: 435,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-02-20',
        shardPrice: 0,
    },
    celestialhelm: {
        uid: 'celestialhelm',
        level: 33,
        type: 'hh',
        xp: 115000,
        craftXp: 1725,
        value: 1200000,
        favor: 2889,
        time: 54600,
        atk: 0,
        def: 325,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 338,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 970,
        surcharge: 2155,
        suggest: 430,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    doghelm: {
        uid: 'doghelm',
        level: 33,
        type: 'hh',
        xp: 115000,
        craftXp: 1725,
        value: 1400000,
        favor: 3061,
        time: 54000,
        atk: 81,
        def: 325,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 338,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 23,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'bo',
        c2Qty: 3,
        c2Tags: 'common',
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'wolf',
        tag: null,
        discount: 1045,
        surcharge: 2320,
        suggest: 465,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-08-25',
        shardPrice: 300,
    },
    valentinehelm: {
        uid: 'valentinehelm',
        level: 34,
        type: 'hh',
        xp: 125000,
        craftXp: 1875,
        value: 1750000,
        favor: 3671,
        time: 65400,
        atk: 0,
        def: 340,
        hp: 21,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 354,
        resource2: 'steel',
        r2Qty: 103,
        resource3: 'gems',
        r3Qty: 34,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-106',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-21',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'resource3-7',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1160,
        surcharge: 2580,
        suggest: 515,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-02-09',
        shardPrice: 300,
    },
    leathercap: {
        uid: 'leathercap',
        level: 1,
        type: 'hm',
        xp: 135,
        craftXp: 2,
        value: 60,
        favor: 1,
        time: 15,
        atk: 0,
        def: 8,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:leathercone',
        upgrade2: 'bp:hat',
        upgrade3: 'resource1-1',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-3',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 15,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    forestroguehat: {
        uid: 'forestroguehat',
        level: 3,
        type: 'hm',
        xp: 495,
        craftXp: 7,
        value: 270,
        favor: 11,
        time: 70,
        atk: 0,
        def: 16,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 1,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 9,
        resource2: 'herbs',
        r2Qty: 2,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-2',
        upgrade3: 'resource2-2',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-5',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'natural',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 15,
        surcharge: 30,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'forest',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    hat: {
        uid: 'hat',
        level: 3,
        type: 'hm',
        xp: 495,
        craftXp: 7,
        value: 190,
        favor: 10,
        time: 70,
        atk: 0,
        def: 16,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:featheredhat',
        upgrade3: 'resource1-2',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-5',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 25,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    featheredhat: {
        uid: 'featheredhat',
        level: 6,
        type: 'hm',
        xp: 1900,
        craftXp: 29,
        value: 860,
        favor: 19,
        time: 210,
        atk: 0,
        def: 32,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 16,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:tricorn',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 50,
        suggest: 10,
        speedup: 90,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    tricorn: {
        uid: 'tricorn',
        level: 8,
        type: 'hm',
        xp: 3450,
        craftXp: 52,
        value: 2250,
        favor: 27,
        time: 380,
        atk: 0,
        def: 44,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 5,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 21,
        resource2: 'iron',
        r2Qty: 11,
        resource3: null,
        r3Qty: 0,
        component1: 'webbedwings',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:hood',
        upgrade4: 'resource1-4',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 35,
        surcharge: 80,
        suggest: 15,
        speedup: 135,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    hood: {
        uid: 'hood',
        level: 12,
        type: 'hm',
        xp: 8150,
        craftXp: 122,
        value: 7400,
        favor: 64,
        time: 1050,
        atk: 0,
        def: 72,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 9,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 41,
        resource2: 'fabric',
        r2Qty: 8,
        resource3: null,
        r3Qty: 0,
        component1: 'leathercap',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'livingroots',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-8',
        upgrade3: 'bp:piratehat',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 65,
        surcharge: 145,
        suggest: 30,
        speedup: 260,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    christmasroguehat: {
        uid: 'christmasroguehat',
        level: 13,
        type: 'hm',
        xp: 9650,
        craftXp: 145,
        value: 13500,
        favor: 91,
        time: 1320,
        atk: 0,
        def: 80,
        hp: 5,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 49,
        resource2: 'fabric',
        r2Qty: 10,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-10',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-2',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.8',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 85,
        surcharge: 190,
        suggest: 40,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2020-12-15',
        shardPrice: 120,
    },
    piratehat: {
        uid: 'piratehat',
        level: 16,
        type: 'hm',
        xp: 15000,
        craftXp: 225,
        value: 24000,
        favor: 173,
        time: 2700,
        atk: 0,
        def: 100,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 85,
        resource2: 'fabric',
        r2Qty: 21,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: 'elvenwood',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-17',
        upgrade3: 'bp:nightcowl',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-4',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 115,
        surcharge: 255,
        suggest: 50,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    valentinehat: {
        uid: 'valentinehat',
        level: 17,
        type: 'hm',
        xp: 17000,
        craftXp: 255,
        value: 40500,
        favor: 242,
        time: 3300,
        atk: 0,
        def: 110,
        hp: 7,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 8,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 1,
        w3BuildingReq: 4,
        resource1: 'leather',
        r1Qty: 99,
        resource2: 'fabric',
        r2Qty: 25,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: 'arcanedust',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'value*1.25',
        upgrade4: 'component1-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-3',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 145,
        surcharge: 325,
        suggest: 65,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-02-07',
        shardPrice: 140,
    },
    nightcowl: {
        uid: 'nightcowl',
        level: 20,
        type: 'hm',
        xp: 24500,
        craftXp: 368,
        value: 65000,
        favor: 379,
        time: 6000,
        atk: 0,
        def: 135,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'leather',
        r1Qty: 141,
        resource2: 'fabric',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'silkscarab',
        c1Qty: 3,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:windrunnerhat',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 185,
        surcharge: 410,
        suggest: 80,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    pyramidroguehat: {
        uid: 'pyramidroguehat',
        level: 21,
        type: 'hm',
        xp: 27000,
        craftXp: 405,
        value: 93000,
        favor: 502,
        time: 6600,
        atk: 0,
        def: 140,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 7,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'leather',
        r1Qty: 155,
        resource2: 'fabric',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'hood',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'silkscarab',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-8',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'gale',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 220,
        surcharge: 485,
        suggest: 95,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'pyramid',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    farmerhood: {
        uid: 'farmerhood',
        level: 22,
        type: 'hm',
        xp: 30000,
        craftXp: 450,
        value: 120000,
        favor: 591,
        time: 9000,
        atk: 38,
        def: 150,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 19,
        w2BuildingReq: 15,
        w3BuildingReq: 9,
        resource1: 'leather',
        r1Qty: 170,
        resource2: 'fabric',
        r2Qty: 43,
        resource3: null,
        r3Qty: 0,
        component1: 'evileye',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-34',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-9',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 250,
        surcharge: 550,
        suggest: 110,
        speedup: 735,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-09-24',
        shardPrice: 160,
    },
    windrunnerhat: {
        uid: 'windrunnerhat',
        level: 24,
        type: 'hm',
        xp: 36000,
        craftXp: 540,
        value: 185000,
        favor: 753,
        time: 13800,
        atk: 0,
        def: 170,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'leather',
        r1Qty: 199,
        resource2: 'herbs',
        r2Qty: 50,
        resource3: 'mana',
        r3Qty: 5,
        component1: 'overgrownvine',
        c1Qty: 4,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:dragonhat',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 305,
        surcharge: 675,
        suggest: 135,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    atlab_spiritmask: {
        uid: 'atlab_spiritmask',
        level: 25,
        type: 'hm',
        xp: 39500,
        craftXp: 593,
        value: 245000,
        favor: 863,
        time: 16800,
        atk: 44,
        def: 175,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'special',
        tier: 8,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'wood',
        r1Qty: 192,
        resource2: 'ironwood',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 6,
        component1: 'timecrystal',
        c1Qty: 5,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-38',
        upgrade3: 'resource2-10',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 345,
        surcharge: 770,
        suggest: 155,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-02',
        shardPrice: 180,
    },
    dragonhat: {
        uid: 'dragonhat',
        level: 28,
        type: 'hm',
        xp: 50500,
        craftXp: 758,
        value: 320000,
        favor: 1375,
        time: 20400,
        atk: 0,
        def: 205,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'leather',
        r1Qty: 259,
        resource2: 'fabric',
        r2Qty: 72,
        resource3: 'mana',
        r3Qty: 12,
        component1: 'savagegloves',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'ghostflag',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'bp:captainhat',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 395,
        surcharge: 880,
        suggest: 175,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    castleroguehat: {
        uid: 'castleroguehat',
        level: 29,
        type: 'hm',
        xp: 54500,
        craftXp: 818,
        value: 360000,
        favor: 1777,
        time: 21600,
        atk: 0,
        def: 215,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'leather',
        r1Qty: 275,
        resource2: 'fabric',
        r2Qty: 77,
        resource3: 'mana',
        r3Qty: 14,
        component1: 'wingedbow',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'sewingthread',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'cquality1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'tempest',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 420,
        surcharge: 930,
        suggest: 185,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'castle',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    oktoberhat: {
        uid: 'oktoberhat',
        level: 29,
        type: 'hm',
        xp: 54500,
        craftXp: 818,
        value: 435000,
        favor: 1777,
        time: 25800,
        atk: 54,
        def: 215,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'leather',
        r1Qty: 275,
        resource2: 'fabric',
        r2Qty: 77,
        resource3: 'mana',
        r3Qty: 14,
        component1: 'ghostflag',
        c1Qty: 2,
        c1Tags: null,
        component2: 'sewingthread',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 455,
        surcharge: 1015,
        suggest: 205,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-09-23',
        shardPrice: 200,
    },
    captainhat: {
        uid: 'captainhat',
        level: 30,
        type: 'hm',
        xp: 58500,
        craftXp: 878,
        value: 605000,
        favor: 1835,
        time: 30600,
        atk: 0,
        def: 225,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 290,
        resource2: 'fabric',
        r2Qty: 82,
        resource3: 'mana',
        r3Qty: 17,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: 'ectoplasm',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:jackalopehat',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 805,
        surcharge: 1790,
        suggest: 360,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    indiadayhat: {
        uid: 'indiadayhat',
        level: 31,
        type: 'hm',
        xp: 63000,
        craftXp: 945,
        value: 625000,
        favor: 2352,
        time: 36600,
        atk: 0,
        def: 235,
        hp: 15,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 306,
        resource2: 'fabric',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 19,
        component1: 'deepcoral',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 815,
        surcharge: 1815,
        suggest: 365,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-01-26',
        shardPrice: 250,
    },
    jackalopehat: {
        uid: 'jackalopehat',
        level: 33,
        type: 'hm',
        xp: 110000,
        craftXp: 1650,
        value: 1250000,
        favor: 2736,
        time: 52800,
        atk: 0,
        def: 290,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 23,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'time*0.8',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 990,
        surcharge: 2195,
        suggest: 440,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    peakroguehat: {
        uid: 'peakroguehat',
        level: 33,
        type: 'hm',
        xp: 110000,
        craftXp: 1650,
        value: 1400000,
        favor: 3061,
        time: 52800,
        atk: 0,
        def: 290,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 23,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'time*0.8',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'nightmare',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 1045,
        surcharge: 2320,
        suggest: 465,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'peak',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    cincoroguehat: {
        uid: 'cincoroguehat',
        level: 33,
        type: 'hm',
        xp: 110000,
        craftXp: 1650,
        value: 1500000,
        favor: 3061,
        time: 52800,
        atk: 73,
        def: 290,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 23,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'goldshell',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'time*0.8',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1080,
        surcharge: 2395,
        suggest: 480,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-05-05',
        shardPrice: 300,
    },
    catroguehat: {
        uid: 'catroguehat',
        level: 34,
        type: 'hm',
        xp: 115000,
        craftXp: 1725,
        value: 1650000,
        favor: 3479,
        time: 58200,
        atk: 0,
        def: 300,
        hp: 19,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 354,
        resource2: 'fabric',
        r2Qty: 103,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'nightcowl',
        c2Qty: 1,
        c2Tags: 'uncommon',
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-71',
        upgrade3: 'value*1.25',
        upgrade4: 'cquality2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-21',
        supgrade2: 'resource3-5',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'cat',
        tag: null,
        discount: 1130,
        surcharge: 2510,
        suggest: 500,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-07-28',
        shardPrice: 300,
    },
    goldroguehat: {
        uid: 'goldroguehat',
        level: 34,
        type: 'hm',
        xp: 115000,
        craftXp: 1725,
        value: 2900000,
        favor: 3479,
        time: 63000,
        atk: 0,
        def: 300,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 354,
        resource2: 'fabric',
        r2Qty: 103,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'tabletjewel',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-71',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-21',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 1110,
        surcharge: 2470,
        suggest: 495,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-07-10',
        shardPrice: 0,
    },
    leathercone: {
        uid: 'leathercone',
        level: 1,
        type: 'hl',
        xp: 135,
        craftXp: 2,
        value: 60,
        favor: 1,
        time: 15,
        atk: 0,
        def: 7,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:blackcowl',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-3',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 15,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    blackcowl: {
        uid: 'blackcowl',
        level: 3,
        type: 'hl',
        xp: 495,
        craftXp: 7,
        value: 190,
        favor: 10,
        time: 70,
        atk: 0,
        def: 15,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:laurels',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-5',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 25,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    laurels: {
        uid: 'laurels',
        level: 6,
        type: 'hl',
        xp: 1900,
        craftXp: 29,
        value: 690,
        favor: 19,
        time: 210,
        atk: 0,
        def: 29,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 16,
        resource2: 'herbs',
        r2Qty: 4,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:circlet',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 45,
        suggest: 10,
        speedup: 90,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    startertiara: {
        uid: 'startertiara',
        level: 7,
        type: 'hl',
        xp: 2600,
        craftXp: 39,
        value: 1950,
        favor: 23,
        time: 290,
        atk: 0,
        def: 34,
        hp: 2,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 17,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'resource1-3',
        upgrade3: 'time*0.75',
        upgrade4: 'component1-1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'quality+1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 35,
        surcharge: 75,
        suggest: 15,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 75,
    },
    circlet: {
        uid: 'circlet',
        level: 8,
        type: 'hl',
        xp: 3450,
        craftXp: 52,
        value: 2050,
        favor: 27,
        time: 380,
        atk: 0,
        def: 39,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 5,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 21,
        resource2: 'herbs',
        r2Qty: 5,
        resource3: null,
        r3Qty: 0,
        component1: 'arcanedust',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:wizardcone',
        upgrade4: 'resource1-4',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 35,
        surcharge: 80,
        suggest: 15,
        speedup: 135,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    wizardcone: {
        uid: 'wizardcone',
        level: 12,
        type: 'hl',
        xp: 8150,
        craftXp: 122,
        value: 8000,
        favor: 93,
        time: 1050,
        atk: 0,
        def: 64,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 9,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 41,
        resource2: 'herbs',
        r2Qty: 10,
        resource3: 'fabric',
        r3Qty: 8,
        component1: 'icypearl',
        c1Qty: 2,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-8',
        upgrade3: 'bp:witchhat',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 150,
        suggest: 30,
        speedup: 260,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 9,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    swamphat: {
        uid: 'swamphat',
        level: 13,
        type: 'hl',
        xp: 9650,
        craftXp: 145,
        value: 11000,
        favor: 91,
        time: 1320,
        atk: 0,
        def: 70,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 5,
        combo: 8,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 49,
        resource2: 'herbs',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'livingroots',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-10',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-2',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'gale',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 80,
        surcharge: 175,
        suggest: 35,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'swamp',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    bunnyhat: {
        uid: 'bunnyhat',
        level: 14,
        type: 'hl',
        xp: 11500,
        craftXp: 173,
        value: 14000,
        favor: 169,
        time: 1680,
        atk: 19,
        def: 76,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 60,
        resource2: 'herbs',
        r2Qty: 15,
        resource3: 'fabric',
        r3Qty: 12,
        component1: 'icypearl',
        c1Qty: 2,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-12',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-3',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 90,
        surcharge: 195,
        suggest: 40,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2021-03-23',
        shardPrice: 120,
    },
    witchhat: {
        uid: 'witchhat',
        level: 16,
        type: 'hl',
        xp: 15000,
        craftXp: 225,
        value: 18500,
        favor: 276,
        time: 2400,
        atk: 0,
        def: 90,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 8,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 85,
        resource2: 'herbs',
        r2Qty: 21,
        resource3: 'fabric',
        r3Qty: 21,
        component1: 'ironamulet',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'whitesand',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-17',
        upgrade3: 'bp:midnighthat',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 100,
        surcharge: 225,
        suggest: 45,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    frogmagehat: {
        uid: 'frogmagehat',
        level: 17,
        type: 'hl',
        xp: 17000,
        craftXp: 255,
        value: 25500,
        favor: 242,
        time: 3000,
        atk: 25,
        def: 98,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 8,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 10,
        w3BuildingReq: 4,
        resource1: 'herbs',
        r1Qty: 99,
        resource2: 'leather',
        r2Qty: 99,
        resource3: null,
        r3Qty: 0,
        component1: 'grass',
        c1Qty: 2,
        c1Tags: 'common',
        component2: 'livingroots',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-30',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-30',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 115,
        surcharge: 260,
        suggest: 50,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-01-11',
        shardPrice: 140,
    },
    liluhat: {
        uid: 'liluhat',
        level: 18,
        type: 'hl',
        xp: 19500,
        craftXp: 293,
        value: 37000,
        favor: 465,
        time: 4200,
        atk: 0,
        def: 105,
        hp: 7,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'leather',
        r1Qty: 113,
        resource2: 'herbs',
        r2Qty: 28,
        resource3: 'fabric',
        r3Qty: 29,
        component1: 'mooncrystal',
        c1Qty: 3,
        c1Tags: null,
        component2: 'glowingmushrooms',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-6',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'owl',
        tag: null,
        discount: 140,
        surcharge: 310,
        suggest: 60,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-11-03',
        shardPrice: 140,
    },
    midnighthat: {
        uid: 'midnighthat',
        level: 20,
        type: 'hl',
        xp: 24500,
        craftXp: 368,
        value: 62000,
        favor: 379,
        time: 6000,
        atk: 0,
        def: 120,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'leather',
        r1Qty: 141,
        resource2: 'fabric',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'silkscarab',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:shamanhat',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 180,
        surcharge: 400,
        suggest: 80,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    summonerhat: {
        uid: 'summonerhat',
        level: 21,
        type: 'hl',
        xp: 27000,
        craftXp: 405,
        value: 105000,
        favor: 502,
        time: 7200,
        atk: 31,
        def: 125,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'leather',
        r1Qty: 155,
        resource2: 'fabric',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'starmetal',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 230,
        surcharge: 515,
        suggest: 105,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-08-13',
        shardPrice: 160,
    },
    shamanhat: {
        uid: 'shamanhat',
        level: 24,
        type: 'hl',
        xp: 36000,
        craftXp: 540,
        value: 195000,
        favor: 771,
        time: 13200,
        atk: 0,
        def: 150,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'leather',
        r1Qty: 199,
        resource2: 'fabric',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 7,
        component1: 'silverband',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'timecrystal',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:astralhat',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 310,
        surcharge: 690,
        suggest: 140,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldhat: {
        uid: 'goldhat',
        level: 27,
        type: 'hl',
        xp: 46500,
        craftXp: 698,
        value: 435000,
        favor: 1366,
        time: 18000,
        atk: 0,
        def: 170,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 8,
        combo: 6,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'leather',
        r1Qty: 244,
        resource2: 'fabric',
        r2Qty: 67,
        resource3: 'mana',
        r3Qty: 13,
        component1: 'tabletfragment',
        c1Qty: 4,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-13',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 340,
        surcharge: 760,
        suggest: 150,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-02-22',
        shardPrice: 0,
    },
    astralhat: {
        uid: 'astralhat',
        level: 28,
        type: 'hl',
        xp: 50500,
        craftXp: 758,
        value: 325000,
        favor: 1425,
        time: 19200,
        atk: 0,
        def: 180,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'leather',
        r1Qty: 259,
        resource2: 'fabric',
        r2Qty: 72,
        resource3: 'mana',
        r3Qty: 16,
        component1: 'scrollofinvisibility',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'sewingthread',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'bp:tiara',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 400,
        surcharge: 885,
        suggest: 175,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    plaguemask: {
        uid: 'plaguemask',
        level: 29,
        type: 'hl',
        xp: 54500,
        craftXp: 818,
        value: 410000,
        favor: 1847,
        time: 24600,
        atk: 48,
        def: 190,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'leather',
        r1Qty: 275,
        resource2: 'fabric',
        r2Qty: 77,
        resource3: 'mana',
        r3Qty: 19,
        component1: 'silverthistle',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'ectoplasm',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.7',
        upgrade2: 'cquality1-1',
        upgrade3: 'value*1.25',
        upgrade4: 'resource1-55',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-31',
        supgrade2: 'resource3-4',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'eagle',
        tag: null,
        discount: 445,
        surcharge: 990,
        suggest: 200,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-06-02',
        shardPrice: 200,
    },
    schoolhat2020: {
        uid: 'schoolhat2020',
        level: 29,
        type: 'hl',
        xp: 54500,
        craftXp: 818,
        value: 420000,
        favor: 1847,
        time: 24000,
        atk: 0,
        def: 190,
        hp: 12,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'leather',
        r1Qty: 275,
        resource2: 'fabric',
        r2Qty: 77,
        resource3: 'mana',
        r3Qty: 19,
        component1: 'scrollofice',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'ghostflag',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.7',
        upgrade2: 'cquality1-1',
        upgrade3: 'value*1.25',
        upgrade4: 'resource1-55',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 450,
        surcharge: 1000,
        suggest: 200,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-08-26',
        shardPrice: 200,
    },
    tiara: {
        uid: 'tiara',
        level: 30,
        type: 'hl',
        xp: 58500,
        craftXp: 878,
        value: 600000,
        favor: 1909,
        time: 30600,
        atk: 0,
        def: 195,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 290,
        resource2: 'fabric',
        r2Qty: 82,
        resource3: 'mana',
        r3Qty: 22,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: 'sewingthread',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:djinnhat',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 800,
        surcharge: 1780,
        suggest: 355,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    bathat: {
        uid: 'bathat',
        level: 30,
        type: 'hl',
        xp: 58500,
        craftXp: 878,
        value: 670000,
        favor: 2128,
        time: 30600,
        atk: 49,
        def: 195,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 290,
        resource2: 'fabric',
        r2Qty: 82,
        resource3: 'mana',
        r3Qty: 22,
        component1: 'sirenshard',
        c1Qty: 3,
        c1Tags: null,
        component2: 'ghostflag',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-16',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 845,
        surcharge: 1880,
        suggest: 375,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-10-20',
        shardPrice: 250,
    },
    djinnhat: {
        uid: 'djinnhat',
        level: 33,
        type: 'hl',
        xp: 110000,
        craftXp: 1650,
        value: 1450000,
        favor: 2889,
        time: 52800,
        atk: 0,
        def: 255,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1060,
        surcharge: 2355,
        suggest: 470,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    victorianmagehat: {
        uid: 'victorianmagehat',
        level: 33,
        type: 'hl',
        xp: 110000,
        craftXp: 1650,
        value: 1500000,
        favor: 3218,
        time: 52800,
        atk: 0,
        def: 255,
        hp: 16,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'goldshell',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1080,
        surcharge: 2395,
        suggest: 480,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-04-07',
        shardPrice: 300,
    },
    snowmagehat: {
        uid: 'snowmagehat',
        level: 34,
        type: 'hl',
        xp: 115000,
        craftXp: 1725,
        value: 1850000,
        favor: 3671,
        time: 63000,
        atk: 66,
        def: 265,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 354,
        resource2: 'fabric',
        r2Qty: 103,
        resource3: 'mana',
        r3Qty: 34,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'value*1.25',
        upgrade3: 'resource1-71',
        upgrade4: 'resource2-21',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-21',
        supgrade2: 'resource3-7',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 1195,
        surcharge: 2650,
        suggest: 530,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-12-01',
        shardPrice: 300,
    },
    ironbracers: {
        uid: 'ironbracers',
        level: 1,
        type: 'gh',
        xp: 135,
        craftXp: 2,
        value: 60,
        favor: 1,
        time: 15,
        atk: 0,
        def: 7,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:lightgauntlets',
        upgrade3: 'resource1-1',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-3',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 15,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lightgauntlets: {
        uid: 'lightgauntlets',
        level: 4,
        type: 'gh',
        xp: 855,
        craftXp: 13,
        value: 410,
        favor: 13,
        time: 100,
        atk: 0,
        def: 20,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 12,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'component1-1',
        upgrade2: 'bp:gauntlets',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-6',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 15,
        surcharge: 35,
        suggest: 5,
        speedup: 55,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    gauntlets: {
        uid: 'gauntlets',
        level: 7,
        type: 'gh',
        xp: 2650,
        craftXp: 40,
        value: 1300,
        favor: 21,
        time: 290,
        atk: 0,
        def: 36,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 17,
        resource2: 'leather',
        r2Qty: 9,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:demigauntlets',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 60,
        suggest: 10,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    demigauntlets: {
        uid: 'demigauntlets',
        level: 10,
        type: 'gh',
        xp: 5600,
        craftXp: 84,
        value: 4250,
        favor: 42,
        time: 590,
        atk: 0,
        def: 54,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 7,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 30,
        resource2: 'leather',
        r2Qty: 15,
        resource3: null,
        r3Qty: 0,
        component1: 'lightgauntlets',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'arcanedust',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-6',
        upgrade3: 'bp:knightgauntlets',
        upgrade4: 'time*0.75',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 50,
        surcharge: 110,
        suggest: 20,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 7,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    pumpkingauntlets: {
        uid: 'pumpkingauntlets',
        level: 11,
        type: 'gh',
        xp: 6900,
        craftXp: 104,
        value: 6000,
        favor: 56,
        time: 840,
        atk: 16,
        def: 62,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 34,
        resource2: 'herbs',
        r2Qty: 9,
        resource3: null,
        r3Qty: 0,
        component1: 'webbedwings',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-7',
        upgrade3: 'time*0.75',
        upgrade4: 'resource1-7',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-9',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 60,
        surcharge: 130,
        suggest: 25,
        speedup: 225,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-10-28',
        shardPrice: 100,
    },
    knightgauntlets: {
        uid: 'knightgauntlets',
        level: 14,
        type: 'gh',
        xp: 11500,
        craftXp: 173,
        value: 11500,
        favor: 106,
        time: 1680,
        atk: 0,
        def: 82,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 60,
        resource2: 'steel',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-12',
        upgrade3: 'bp:paladingauntlets',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 80,
        surcharge: 180,
        suggest: 35,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    christmasgauntlets: {
        uid: 'christmasgauntlets',
        level: 15,
        type: 'gh',
        xp: 13500,
        craftXp: 203,
        value: 16000,
        favor: 150,
        time: 2160,
        atk: 0,
        def: 88,
        hp: 6,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 71,
        resource2: 'steel',
        r2Qty: 16,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-14',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.8',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 95,
        surcharge: 210,
        suggest: 40,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2021-12-14',
        shardPrice: 120,
    },
    paladingauntlets: {
        uid: 'paladingauntlets',
        level: 17,
        type: 'gh',
        xp: 17500,
        craftXp: 263,
        value: 22500,
        favor: 349,
        time: 3300,
        atk: 0,
        def: 105,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 10,
        w3BuildingReq: 4,
        resource1: 'iron',
        r1Qty: 99,
        resource2: 'herbs',
        r2Qty: 25,
        resource3: 'steel',
        r3Qty: 25,
        component1: 'sharpfang',
        c1Qty: 3,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:kote',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 110,
        surcharge: 245,
        suggest: 50,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    desertgauntlets: {
        uid: 'desertgauntlets',
        level: 18,
        type: 'gh',
        xp: 20000,
        craftXp: 300,
        value: 36000,
        favor: 296,
        time: 4200,
        atk: 0,
        def: 110,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 6,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'iron',
        r1Qty: 113,
        resource2: 'steel',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-3',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'flaming',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 140,
        surcharge: 310,
        suggest: 60,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'desert',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    oktoberfestgauntlets: {
        uid: 'oktoberfestgauntlets',
        level: 18,
        type: 'gh',
        xp: 20000,
        craftXp: 300,
        value: 48000,
        favor: 296,
        time: 4200,
        atk: 0,
        def: 110,
        hp: 7,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'iron',
        r1Qty: 113,
        resource2: 'steel',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'thiefgloves',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'mooncrystal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 160,
        surcharge: 355,
        suggest: 70,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-10-11',
        shardPrice: 140,
    },
    kote: {
        uid: 'kote',
        level: 20,
        type: 'gh',
        xp: 25000,
        craftXp: 375,
        value: 69000,
        favor: 622,
        time: 6000,
        atk: 0,
        def: 125,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'iron',
        r1Qty: 141,
        resource2: 'leather',
        r2Qty: 70,
        resource3: 'steel',
        r3Qty: 36,
        component1: 'starmetal',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:generalgauntlets',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 190,
        surcharge: 420,
        suggest: 85,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    indiadaygauntlets: {
        uid: 'indiadaygauntlets',
        level: 21,
        type: 'gh',
        xp: 27500,
        craftXp: 413,
        value: 82500,
        favor: 791,
        time: 7800,
        atk: 0,
        def: 135,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'iron',
        r1Qty: 155,
        resource2: 'leather',
        r2Qty: 78,
        resource3: 'steel',
        r3Qty: 39,
        component1: 'evileye',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-16',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-16',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 205,
        surcharge: 460,
        suggest: 90,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-01-26',
        shardPrice: 160,
    },
    generalgauntlets: {
        uid: 'generalgauntlets',
        level: 25,
        type: 'gh',
        xp: 40500,
        craftXp: 608,
        value: 185000,
        favor: 896,
        time: 16800,
        atk: 0,
        def: 165,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'iron',
        r1Qty: 214,
        resource2: 'steel',
        r2Qty: 57,
        resource3: 'gems',
        r3Qty: 8,
        component1: 'ancientmarble',
        c1Qty: 5,
        c1Tags: null,
        component2: 'whitesand',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'bp:berserkergauntlets',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-4',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 305,
        surcharge: 675,
        suggest: 135,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    berserkergauntlets: {
        uid: 'berserkergauntlets',
        level: 27,
        type: 'gh',
        xp: 47500,
        craftXp: 713,
        value: 305000,
        favor: 1224,
        time: 18000,
        atk: 0,
        def: 185,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'iron',
        r1Qty: 244,
        resource2: 'steel',
        r2Qty: 67,
        resource3: 'gems',
        r3Qty: 13,
        component1: 'ghostflag',
        c1Qty: 4,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:juggernautgauntlets',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-4',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 385,
        surcharge: 860,
        suggest: 170,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldgauntlets: {
        uid: 'goldgauntlets',
        level: 28,
        type: 'gh',
        xp: 51500,
        craftXp: 773,
        value: 530000,
        favor: 1589,
        time: 21600,
        atk: 0,
        def: 190,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'iron',
        r1Qty: 259,
        resource2: 'steel',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'tabletfragment',
        c1Qty: 5,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 16,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-8',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 380,
        surcharge: 840,
        suggest: 170,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-03-21',
        shardPrice: 0,
    },
    juggernautgauntlets: {
        uid: 'juggernautgauntlets',
        level: 30,
        type: 'gh',
        xp: 60000,
        craftXp: 900,
        value: 600000,
        favor: 1909,
        time: 31200,
        atk: 0,
        def: 210,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 290,
        resource2: 'steel',
        r2Qty: 82,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'deepcoral',
        c1Qty: 5,
        c1Tags: null,
        component2: 'overgrownvine',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:celestialgauntlets',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-4',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 800,
        surcharge: 1780,
        suggest: 355,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    wintergauntlets: {
        uid: 'wintergauntlets',
        level: 31,
        type: 'gh',
        xp: 64500,
        craftXp: 968,
        value: 645000,
        favor: 2451,
        time: 37200,
        atk: 55,
        def: 220,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 306,
        resource2: 'steel',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'sirenshard',
        c1Qty: 4,
        c1Tags: null,
        component2: 'darkscale',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-4',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 830,
        surcharge: 1845,
        suggest: 370,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-12-01',
        shardPrice: 250,
    },
    celestialgauntlets: {
        uid: 'celestialgauntlets',
        level: 33,
        type: 'gh',
        xp: 110000,
        craftXp: 1650,
        value: 1200000,
        favor: 2889,
        time: 53400,
        atk: 0,
        def: 270,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 338,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 970,
        surcharge: 2155,
        suggest: 430,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    naturegauntlets: {
        uid: 'naturegauntlets',
        level: 33,
        type: 'gh',
        xp: 110000,
        craftXp: 1650,
        value: 1300000,
        favor: 3218,
        time: 53400,
        atk: 0,
        def: 270,
        hp: 17,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 338,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'ancientmarble',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 1005,
        surcharge: 2235,
        suggest: 445,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-04-21',
        shardPrice: 300,
    },
    fallgauntlets: {
        uid: 'fallgauntlets',
        level: 34,
        type: 'gh',
        xp: 120000,
        craftXp: 1800,
        value: 1500000,
        favor: 9057,
        time: 63600,
        atk: 70,
        def: 280,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 354,
        resource2: 'leather',
        r2Qty: 304,
        resource3: 'herbs',
        r3Qty: 254,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-71',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-61',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-51',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 1080,
        surcharge: 2395,
        suggest: 480,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-10-05',
        shardPrice: 300,
    },
    aegirgauntlets: {
        uid: 'aegirgauntlets',
        level: 34,
        type: 'gh',
        xp: 120000,
        craftXp: 1800,
        value: 1700000,
        favor: 3671,
        time: 63600,
        atk: 0,
        def: 280,
        hp: 18,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'jeweler',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 354,
        resource2: 'steel',
        r2Qty: 103,
        resource3: 'gems',
        r3Qty: 34,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'sirenshard',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-106',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-31',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 1145,
        surcharge: 2545,
        suggest: 510,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-02-22',
        shardPrice: 300,
    },
    leathergloves: {
        uid: 'leathergloves',
        level: 1,
        type: 'gl',
        xp: 135,
        craftXp: 2,
        value: 60,
        favor: 1,
        time: 15,
        atk: 0,
        def: 7,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:ironbracers',
        upgrade2: 'bp:bracers',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-4',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 15,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    bracers: {
        uid: 'bracers',
        level: 3,
        type: 'gl',
        xp: 480,
        craftXp: 7,
        value: 180,
        favor: 10,
        time: 60,
        atk: 0,
        def: 14,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:thiefgloves',
        upgrade3: 'resource1-2',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-5',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 25,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    thiefgloves: {
        uid: 'thiefgloves',
        level: 5,
        type: 'gl',
        xp: 1250,
        craftXp: 19,
        value: 720,
        favor: 16,
        time: 150,
        atk: 0,
        def: 22,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 14,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 6,
        u2Req: 16,
        u3Req: 28,
        u4Req: 45,
        u5Req: 70,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:studdedleathergloves',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 45,
        suggest: 10,
        speedup: 70,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    grottogloves: {
        uid: 'grottogloves',
        level: 7,
        type: 'gl',
        xp: 2500,
        craftXp: 38,
        value: 1200,
        favor: 23,
        time: 240,
        atk: 0,
        def: 32,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 3,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 17,
        resource2: 'herbs',
        r2Qty: 4,
        resource3: null,
        r3Qty: 0,
        component1: 'bracers',
        c1Qty: 1,
        c1Tags: 'common',
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-3',
        upgrade4: 'time*0.9',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource2-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'breeze',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 25,
        surcharge: 60,
        suggest: 10,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'grotto',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    patchworkgloves: {
        uid: 'patchworkgloves',
        level: 8,
        type: 'gl',
        xp: 3300,
        craftXp: 50,
        value: 2350,
        favor: 30,
        time: 380,
        atk: 0,
        def: 37,
        hp: 0,
        eva: '0.05',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 5,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 21,
        resource2: 'herbs',
        r2Qty: 5,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.8',
        upgrade3: 'resource1-4',
        upgrade4: 'time*0.8',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 85,
        suggest: 15,
        speedup: 135,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-11-19',
        shardPrice: 75,
    },
    studdedleathergloves: {
        uid: 'studdedleathergloves',
        level: 9,
        type: 'gl',
        xp: 4200,
        craftXp: 63,
        value: 3350,
        favor: 35,
        time: 490,
        atk: 0,
        def: 42,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 26,
        resource2: 'iron',
        r2Qty: 6,
        resource3: null,
        r3Qty: 0,
        component1: 'webbedwings',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:vambraces',
        upgrade4: 'resource1-5',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 45,
        surcharge: 100,
        suggest: 20,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lunargloves: {
        uid: 'lunargloves',
        level: 11,
        type: 'gl',
        xp: 6450,
        craftXp: 97,
        value: 5100,
        favor: 56,
        time: 810,
        atk: 0,
        def: 54,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'tailor',
        worker2: 'blacksmith',
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 34,
        resource2: 'iron',
        r2Qty: 9,
        resource3: null,
        r3Qty: 0,
        component1: 'ironbracers',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'preciousgem',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'resource1-7',
        upgrade2: 'time*0.75',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-5',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 55,
        surcharge: 120,
        suggest: 25,
        speedup: 225,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-01-25',
        shardPrice: 100,
    },
    vambraces: {
        uid: 'vambraces',
        level: 13,
        type: 'gl',
        xp: 9250,
        craftXp: 139,
        value: 8750,
        favor: 81,
        time: 1290,
        atk: 0,
        def: 66,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 49,
        resource2: 'fabric',
        r2Qty: 10,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-10',
        upgrade3: 'bp:savagegloves',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 155,
        suggest: 30,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    eastergloves2020: {
        uid: 'eastergloves2020',
        level: 14,
        type: 'gl',
        xp: 11000,
        craftXp: 165,
        value: 15500,
        favor: 119,
        time: 1650,
        atk: 18,
        def: 72,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 60,
        resource2: 'fabric',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'livingroots',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-12',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 90,
        surcharge: 205,
        suggest: 40,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2020-04-07',
        shardPrice: 120,
    },
    savagegloves: {
        uid: 'savagegloves',
        level: 16,
        type: 'gl',
        xp: 14500,
        craftXp: 218,
        value: 19000,
        favor: 276,
        time: 2400,
        atk: 0,
        def: 84,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 85,
        resource2: 'herbs',
        r2Qty: 21,
        resource3: 'fabric',
        r3Qty: 21,
        component1: 'sharpfang',
        c1Qty: 2,
        c1Tags: null,
        component2: 'livingroots',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-17',
        upgrade3: 'bp:ninjagloves',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'lizard',
        tag: null,
        discount: 100,
        surcharge: 225,
        suggest: 45,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    desertgloves: {
        uid: 'desertgloves',
        level: 18,
        type: 'gl',
        xp: 18500,
        craftXp: 278,
        value: 29000,
        favor: 465,
        time: 4200,
        atk: 0,
        def: 98,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 6,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 1,
        w3BuildingReq: 5,
        resource1: 'leather',
        r1Qty: 113,
        resource2: 'herbs',
        r2Qty: 28,
        resource3: 'fabric',
        r3Qty: 29,
        component1: 'sharpfang',
        c1Qty: 2,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'unholy',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 125,
        surcharge: 275,
        suggest: 55,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'desert',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ninjagloves: {
        uid: 'ninjagloves',
        level: 20,
        type: 'gl',
        xp: 23500,
        craftXp: 353,
        value: 65500,
        favor: 379,
        time: 6000,
        atk: 0,
        def: 110,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'leather',
        r1Qty: 141,
        resource2: 'fabric',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'evileye',
        c1Qty: 3,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:windrunnergloves',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 185,
        surcharge: 410,
        suggest: 80,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    catgloves: {
        uid: 'catgloves',
        level: 21,
        type: 'gl',
        xp: 26000,
        craftXp: 390,
        value: 90000,
        favor: 502,
        time: 7200,
        atk: 29,
        def: 115,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'leather',
        r1Qty: 155,
        resource2: 'fabric',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'sharpfang',
        c1Qty: 5,
        c1Tags: null,
        component2: 'thiefgloves',
        c2Qty: 2,
        c2Tags: 'common',
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'cat',
        tag: null,
        discount: 215,
        surcharge: 480,
        suggest: 95,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-07-27',
        shardPrice: 160,
    },
    windrunnergloves: {
        uid: 'windrunnergloves',
        level: 24,
        type: 'gl',
        xp: 34500,
        craftXp: 518,
        value: 175000,
        favor: 771,
        time: 12000,
        atk: 0,
        def: 140,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'leather',
        r1Qty: 199,
        resource2: 'fabric',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 7,
        component1: 'hunterbow',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'timecrystal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:dragongloves',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'cquality1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 295,
        surcharge: 655,
        suggest: 130,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    dragongloves: {
        uid: 'dragongloves',
        level: 27,
        type: 'gl',
        xp: 45000,
        craftXp: 675,
        value: 305000,
        favor: 1224,
        time: 17400,
        atk: 0,
        def: 160,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'leather',
        r1Qty: 244,
        resource2: 'fabric',
        r2Qty: 67,
        resource3: 'mana',
        r3Qty: 13,
        component1: 'sewingthread',
        c1Qty: 4,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:captaingloves',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-13',
        supgrade2: 'component2-4',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 385,
        surcharge: 860,
        suggest: 170,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    oktobergloves: {
        uid: 'oktobergloves',
        level: 28,
        type: 'gl',
        xp: 48500,
        craftXp: 728,
        value: 415000,
        favor: 1589,
        time: 21000,
        atk: 0,
        def: 170,
        hp: 0,
        eva: '0.05',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'leather',
        r1Qty: 259,
        resource2: 'fabric',
        r2Qty: 72,
        resource3: 'mana',
        r3Qty: 16,
        component1: 'ectoplasm',
        c1Qty: 4,
        c1Tags: null,
        component2: 'overgrownvine',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-14',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 450,
        surcharge: 995,
        suggest: 200,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-09-22',
        shardPrice: 200,
    },
    captaingloves: {
        uid: 'captaingloves',
        level: 30,
        type: 'gl',
        xp: 56500,
        craftXp: 848,
        value: 610000,
        favor: 1909,
        time: 30000,
        atk: 0,
        def: 185,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 290,
        resource2: 'fabric',
        r2Qty: 82,
        resource3: 'mana',
        r3Qty: 22,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: 'ghostflag',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:jackalopegloves',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 810,
        surcharge: 1795,
        suggest: 360,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    vipgloves: {
        uid: 'vipgloves',
        level: 31,
        type: 'gl',
        xp: 60500,
        craftXp: 908,
        value: 655000,
        favor: 2451,
        time: 36000,
        atk: 48,
        def: 190,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 306,
        resource2: 'fabric',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'ghostflag',
        c1Qty: 4,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-17',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 835,
        surcharge: 1855,
        suggest: 370,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-01-12',
        shardPrice: 250,
    },
    goldgloves: {
        uid: 'goldgloves',
        level: 31,
        type: 'gl',
        xp: 60500,
        craftXp: 908,
        value: 1750000,
        favor: 2451,
        time: 36000,
        atk: 0,
        def: 190,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 306,
        resource2: 'fabric',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'tabletjewel',
        c1Qty: 5,
        c1Tags: null,
        component2: 'goldshell',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 1005,
        surcharge: 2230,
        suggest: 445,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-03-20',
        shardPrice: 0,
    },
    jackalopegloves: {
        uid: 'jackalopegloves',
        level: 33,
        type: 'gl',
        xp: 105000,
        craftXp: 1575,
        value: 1300000,
        favor: 2889,
        time: 51600,
        atk: 0,
        def: 240,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1005,
        surcharge: 2235,
        suggest: 445,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    birdgloves: {
        uid: 'birdgloves',
        level: 33,
        type: 'gl',
        xp: 105000,
        craftXp: 1575,
        value: 1600000,
        favor: 3218,
        time: 51600,
        atk: 0,
        def: 240,
        hp: 0,
        eva: '0.05',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'eagle',
        tag: null,
        discount: 1110,
        surcharge: 2470,
        suggest: 495,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-05-18',
        shardPrice: 300,
    },
    halloweengloves: {
        uid: 'halloweengloves',
        level: 34,
        type: 'gl',
        xp: 110000,
        craftXp: 1650,
        value: 1700000,
        favor: 3564,
        time: 61800,
        atk: 0,
        def: 245,
        hp: 15,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 354,
        resource2: 'fabric',
        r2Qty: 103,
        resource3: 'gems',
        r3Qty: 29,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'ghostflag',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-71',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-21',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1145,
        surcharge: 2545,
        suggest: 510,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-10-20',
        shardPrice: 300,
    },
    shinguards: {
        uid: 'shinguards',
        level: 1,
        type: 'bh',
        xp: 135,
        craftXp: 2,
        value: 55,
        favor: 1,
        time: 15,
        atk: 0,
        def: 8,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:longboots',
        upgrade2: 'value*1.5',
        upgrade3: 'resource1-1',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-3',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 15,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    longboots: {
        uid: 'longboots',
        level: 3,
        type: 'bh',
        xp: 505,
        craftXp: 8,
        value: 230,
        favor: 10,
        time: 70,
        atk: 0,
        def: 17,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 9,
        resource2: 'leather',
        r2Qty: 5,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'bp:grieveboots',
        upgrade2: 'value*1.5',
        upgrade3: 'resource1-2',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-5',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 25,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    grieveboots: {
        uid: 'grieveboots',
        level: 5,
        type: 'bh',
        xp: 1350,
        craftXp: 20,
        value: 710,
        favor: 16,
        time: 150,
        atk: 0,
        def: 28,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 14,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 6,
        u2Req: 16,
        u3Req: 28,
        u4Req: 45,
        u5Req: 70,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:heavyboots',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 45,
        suggest: 10,
        speedup: 70,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    heroboots: {
        uid: 'heroboots',
        level: 6,
        type: 'bh',
        xp: 1900,
        craftXp: 29,
        value: 1200,
        favor: 21,
        time: 210,
        atk: 0,
        def: 34,
        hp: 2,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 16,
        resource2: 'leather',
        r2Qty: 8,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.8',
        upgrade3: 'resource1-3',
        upgrade4: 'time*0.8',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 60,
        suggest: 10,
        speedup: 90,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-09-10',
        shardPrice: 75,
    },
    heavyboots: {
        uid: 'heavyboots',
        level: 8,
        type: 'bh',
        xp: 3500,
        craftXp: 53,
        value: 2150,
        favor: 27,
        time: 390,
        atk: 0,
        def: 47,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 5,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 21,
        resource2: 'leather',
        r2Qty: 11,
        resource3: null,
        r3Qty: 0,
        component1: 'arcanedust',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:knightboots',
        upgrade4: 'resource1-4',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 35,
        surcharge: 80,
        suggest: 15,
        speedup: 135,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    circusboots: {
        uid: 'circusboots',
        level: 9,
        type: 'bh',
        xp: 4500,
        craftXp: 68,
        value: 3350,
        favor: 39,
        time: 490,
        atk: 0,
        def: 54,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 9,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 26,
        resource2: 'leather',
        r2Qty: 13,
        resource3: null,
        r3Qty: 0,
        component1: 'shinguards',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'leatherboots',
        c2Qty: 1,
        c2Tags: 'common',
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-5',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'time*0.75',
        supgrade3: 'multi+0.1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 45,
        surcharge: 100,
        suggest: 20,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-06-15',
        shardPrice: 100,
    },
    knightboots: {
        uid: 'knightboots',
        level: 13,
        type: 'bh',
        xp: 9850,
        craftXp: 148,
        value: 8150,
        favor: 81,
        time: 1350,
        atk: 0,
        def: 84,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 49,
        resource2: 'steel',
        r2Qty: 10,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-10',
        upgrade3: 'bp:paladinboots',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 150,
        suggest: 30,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    swampboots: {
        uid: 'swampboots',
        level: 14,
        type: 'bh',
        xp: 11500,
        craftXp: 173,
        value: 12000,
        favor: 119,
        time: 1650,
        atk: 0,
        def: 92,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 60,
        resource2: 'steel',
        r2Qty: 12,
        resource3: null,
        r3Qty: 0,
        component1: 'scrolloffire',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'icypearl',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-12',
        upgrade3: 'value*1.25',
        upgrade4: 'component1-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'flaming',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 80,
        surcharge: 180,
        suggest: 35,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'swamp',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lunargreaves: {
        uid: 'lunargreaves',
        level: 15,
        type: 'bh',
        xp: 13500,
        craftXp: 203,
        value: 14500,
        favor: 150,
        time: 2160,
        atk: 25,
        def: 100,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'special',
        tier: 5,
        combo: 8,
        worker1: 'blacksmith',
        worker2: null,
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 71,
        resource2: 'steel',
        r2Qty: 16,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 4,
        c1Tags: null,
        component2: 'redpinecone',
        c2Qty: 1,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-14',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 90,
        surcharge: 200,
        suggest: 40,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-01-25',
        shardPrice: 120,
    },
    paladinboots: {
        uid: 'paladinboots',
        level: 17,
        type: 'bh',
        xp: 17500,
        craftXp: 263,
        value: 23500,
        favor: 349,
        time: 3000,
        atk: 0,
        def: 115,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'blacksmith',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 10,
        w3BuildingReq: 4,
        resource1: 'iron',
        r1Qty: 99,
        resource2: 'herbs',
        r2Qty: 25,
        resource3: 'steel',
        r3Qty: 25,
        component1: 'grieveboots',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'mooncrystal',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:haidate',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 115,
        surcharge: 250,
        suggest: 50,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    dogboots: {
        uid: 'dogboots',
        level: 18,
        type: 'bh',
        xp: 20000,
        craftXp: 300,
        value: 29500,
        favor: 296,
        time: 4200,
        atk: 31,
        def: 125,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'iron',
        r1Qty: 113,
        resource2: 'steel',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 6,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'wolf',
        tag: null,
        discount: 125,
        surcharge: 280,
        suggest: 55,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-08-24',
        shardPrice: 140,
    },
    haidate: {
        uid: 'haidate',
        level: 20,
        type: 'bh',
        xp: 25000,
        craftXp: 375,
        value: 69000,
        favor: 622,
        time: 6000,
        atk: 0,
        def: 140,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'iron',
        r1Qty: 141,
        resource2: 'leather',
        r2Qty: 70,
        resource3: 'steel',
        r3Qty: 36,
        component1: 'starmetal',
        c1Qty: 3,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:generalgreaves',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 190,
        surcharge: 420,
        suggest: 85,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    bfheavyboots: {
        uid: 'bfheavyboots',
        level: 22,
        type: 'bh',
        xp: 30500,
        craftXp: 458,
        value: 85000,
        favor: 591,
        time: 9000,
        atk: 0,
        def: 160,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 19,
        w2BuildingReq: 15,
        w3BuildingReq: 9,
        resource1: 'iron',
        r1Qty: 170,
        resource2: 'gems',
        r2Qty: 5,
        resource3: null,
        r3Qty: 0,
        component1: 'starmetal',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-34',
        upgrade3: 'value*1.25',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 210,
        surcharge: 465,
        suggest: 95,
        speedup: 735,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-26',
        shardPrice: 160,
    },
    generalgreaves: {
        uid: 'generalgreaves',
        level: 24,
        type: 'bh',
        xp: 37000,
        craftXp: 555,
        value: 195000,
        favor: 771,
        time: 13800,
        atk: 0,
        def: 180,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'iron',
        r1Qty: 199,
        resource2: 'steel',
        r2Qty: 51,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'ancientmarble',
        c1Qty: 5,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:berserkerboots',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 310,
        surcharge: 690,
        suggest: 140,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    berserkerboots: {
        uid: 'berserkerboots',
        level: 27,
        type: 'bh',
        xp: 47500,
        craftXp: 713,
        value: 310000,
        favor: 1224,
        time: 16800,
        atk: 0,
        def: 205,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'iron',
        r1Qty: 244,
        resource2: 'steel',
        r2Qty: 67,
        resource3: 'gems',
        r3Qty: 13,
        component1: 'knightboots',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'ghostflag',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'cquality1-1',
        upgrade3: 'bp:juggernautboots',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 390,
        surcharge: 865,
        suggest: 175,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldboots: {
        uid: 'goldboots',
        level: 28,
        type: 'bh',
        xp: 51500,
        craftXp: 773,
        value: 605000,
        favor: 1589,
        time: 21600,
        atk: 0,
        def: 215,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'iron',
        r1Qty: 259,
        resource2: 'steel',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'tabletfragment',
        c1Qty: 5,
        c1Tags: null,
        component2: 'ghostflag',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 405,
        surcharge: 895,
        suggest: 180,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-08-08',
        shardPrice: 0,
    },
    fallboots: {
        uid: 'fallboots',
        level: 29,
        type: 'bh',
        xp: 55500,
        craftXp: 833,
        value: 410000,
        favor: 1847,
        time: 25800,
        atk: 0,
        def: 225,
        hp: 14,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'iron',
        r1Qty: 275,
        resource2: 'steel',
        r2Qty: 77,
        resource3: 'gems',
        r3Qty: 19,
        component1: 'ghostflag',
        c1Qty: 3,
        c1Tags: null,
        component2: 'starmetal',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 445,
        surcharge: 990,
        suggest: 200,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-09-09',
        shardPrice: 200,
    },
    juggernautboots: {
        uid: 'juggernautboots',
        level: 30,
        type: 'bh',
        xp: 60000,
        craftXp: 900,
        value: 625000,
        favor: 1909,
        time: 31200,
        atk: 0,
        def: 235,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'tailor',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 290,
        resource2: 'steel',
        r2Qty: 82,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'deepcoral',
        c1Qty: 5,
        c1Tags: null,
        component2: 'timecrystal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:celestialboots',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 815,
        surcharge: 1815,
        suggest: 365,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    bunnyboots: {
        uid: 'bunnyboots',
        level: 31,
        type: 'bh',
        xp: 64500,
        craftXp: 968,
        value: 670000,
        favor: 2451,
        time: 37200,
        atk: 61,
        def: 245,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'tailor',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 306,
        resource2: 'steel',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 845,
        surcharge: 1880,
        suggest: 375,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2021-03-24',
        shardPrice: 250,
    },
    celestialboots: {
        uid: 'celestialboots',
        level: 33,
        type: 'bh',
        xp: 110000,
        craftXp: 1650,
        value: 1250000,
        favor: 2889,
        time: 53400,
        atk: 0,
        def: 305,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'tailor',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 338,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'sirenshard',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 990,
        surcharge: 2195,
        suggest: 440,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    freedomboots: {
        uid: 'freedomboots',
        level: 33,
        type: 'bh',
        xp: 110000,
        craftXp: 1650,
        value: 1650000,
        favor: 3218,
        time: 53400,
        atk: 0,
        def: 305,
        hp: 19,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'tailor',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 338,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-12',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1130,
        surcharge: 2510,
        suggest: 500,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-06-29',
        shardPrice: 300,
    },
    christmasboots: {
        uid: 'christmasboots',
        level: 34,
        type: 'bh',
        xp: 120000,
        craftXp: 1800,
        value: 1800000,
        favor: 3671,
        time: 63600,
        atk: 80,
        def: 320,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'blacksmith',
        worker2: 'master',
        worker3: 'tailor',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 354,
        resource2: 'steel',
        r2Qty: 103,
        resource3: 'gems',
        r3Qty: 34,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'goldshell',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'resource1-71',
        upgrade2: 'resource2-21',
        upgrade3: 'time*0.75',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'resource3-10',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1175,
        surcharge: 2615,
        suggest: 525,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2021-12-15',
        shardPrice: 300,
    },
    leatherboots: {
        uid: 'leatherboots',
        level: 1,
        type: 'bl',
        xp: 135,
        craftXp: 2,
        value: 60,
        favor: 1,
        time: 15,
        atk: 0,
        def: 7,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 5,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:shinguards',
        upgrade2: 'bp:sandals',
        upgrade3: 'value*1.5',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-4',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 5,
        surcharge: 15,
        suggest: 5,
        speedup: 15,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    forestshoes: {
        uid: 'forestshoes',
        level: 3,
        type: 'bl',
        xp: 480,
        craftXp: 7,
        value: 250,
        favor: 11,
        time: 60,
        atk: 0,
        def: 15,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 1,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'resource1-2',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-5',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'ember',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 15,
        surcharge: 30,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'forest',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    sandals: {
        uid: 'sandals',
        level: 3,
        type: 'bl',
        xp: 480,
        craftXp: 7,
        value: 180,
        favor: 10,
        time: 60,
        atk: 0,
        def: 15,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 9,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:softshoes',
        upgrade3: 'resource1-2',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-5',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 25,
        suggest: 5,
        speedup: 40,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    softshoes: {
        uid: 'softshoes',
        level: 5,
        type: 'bl',
        xp: 1250,
        craftXp: 19,
        value: 660,
        favor: 16,
        time: 150,
        atk: 0,
        def: 25,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 14,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 6,
        u2Req: 16,
        u3Req: 28,
        u4Req: 45,
        u5Req: 70,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:thiefshoes',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 45,
        suggest: 10,
        speedup: 70,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    romansandals: {
        uid: 'romansandals',
        level: 6,
        type: 'bl',
        xp: 1800,
        craftXp: 27,
        value: 1150,
        favor: 21,
        time: 210,
        atk: 8,
        def: 30,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 16,
        resource2: 'wood',
        r2Qty: 8,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.8',
        upgrade3: 'resource1-3',
        upgrade4: 'time*0.8',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 60,
        suggest: 10,
        speedup: 90,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-07-30',
        shardPrice: 75,
    },
    thiefshoes: {
        uid: 'thiefshoes',
        level: 9,
        type: 'bl',
        xp: 4200,
        craftXp: 63,
        value: 2800,
        favor: 35,
        time: 450,
        atk: 0,
        def: 48,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 26,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'dagger',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'webbedwings',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:elvenshoes',
        upgrade4: 'resource1-5',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 90,
        suggest: 20,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    flowersandals: {
        uid: 'flowersandals',
        level: 10,
        type: 'bl',
        xp: 5300,
        craftXp: 80,
        value: 5000,
        favor: 47,
        time: 630,
        atk: 0,
        def: 54,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 9,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 7,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 30,
        resource2: 'herbs',
        r2Qty: 7,
        resource3: null,
        r3Qty: 0,
        component1: 'arcanedust',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-6',
        upgrade4: 'resource2-7',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource1-6',
        supgrade2: 'time*0.75',
        supgrade3: 'multi+0.1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 55,
        surcharge: 120,
        suggest: 25,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-03-24',
        shardPrice: 100,
    },
    elvenshoes: {
        uid: 'elvenshoes',
        level: 12,
        type: 'bl',
        xp: 7800,
        craftXp: 117,
        value: 6950,
        favor: 93,
        time: 1020,
        atk: 0,
        def: 68,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 9,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 41,
        resource2: 'herbs',
        r2Qty: 10,
        resource3: 'fabric',
        r3Qty: 8,
        component1: 'livingroots',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-8',
        upgrade3: 'bp:savageshoes',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 65,
        surcharge: 140,
        suggest: 30,
        speedup: 260,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    moonshoes: {
        uid: 'moonshoes',
        level: 13,
        type: 'bl',
        xp: 9250,
        craftXp: 139,
        value: 13000,
        favor: 129,
        time: 1290,
        atk: 0,
        def: 74,
        hp: 5,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'tailor',
        worker2: null,
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 49,
        resource2: 'iron',
        r2Qty: 12,
        resource3: 'fabric',
        r3Qty: 10,
        component1: 'mooncrystal',
        c1Qty: 2,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'value*1.2',
        upgrade3: 'resource1-10',
        upgrade4: 'value*1.2',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'resource2-12',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 85,
        surcharge: 190,
        suggest: 40,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-09-21',
        shardPrice: 120,
    },
    savageshoes: {
        uid: 'savageshoes',
        level: 16,
        type: 'bl',
        xp: 14500,
        craftXp: 218,
        value: 20500,
        favor: 276,
        time: 2400,
        atk: 0,
        def: 96,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 85,
        resource2: 'herbs',
        r2Qty: 21,
        resource3: 'fabric',
        r3Qty: 21,
        component1: 'sharpfang',
        c1Qty: 3,
        c1Tags: null,
        component2: 'webbedwings',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-17',
        upgrade3: 'bp:ninjatabi',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'lizard',
        tag: null,
        discount: 105,
        surcharge: 235,
        suggest: 45,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    arabianshoes: {
        uid: 'arabianshoes',
        level: 18,
        type: 'bl',
        xp: 18500,
        craftXp: 278,
        value: 36500,
        favor: 465,
        time: 4200,
        atk: 28,
        def: 110,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 1,
        w3BuildingReq: 5,
        resource1: 'leather',
        r1Qty: 113,
        resource2: 'herbs',
        r2Qty: 28,
        resource3: 'fabric',
        r3Qty: 29,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 140,
        surcharge: 310,
        suggest: 60,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-08-11',
        shardPrice: 140,
    },
    ninjatabi: {
        uid: 'ninjatabi',
        level: 19,
        type: 'bl',
        xp: 21000,
        craftXp: 315,
        value: 57000,
        favor: 319,
        time: 4800,
        atk: 0,
        def: 120,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 16,
        w2BuildingReq: 12,
        w3BuildingReq: 6,
        resource1: 'leather',
        r1Qty: 127,
        resource2: 'fabric',
        r2Qty: 33,
        resource3: null,
        r3Qty: 0,
        component1: 'sandals',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'evileye',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-25',
        upgrade3: 'bp:windrunnerboots',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 175,
        surcharge: 385,
        suggest: 75,
        speedup: 565,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    windrunnerboots: {
        uid: 'windrunnerboots',
        level: 24,
        type: 'bl',
        xp: 34500,
        craftXp: 518,
        value: 175000,
        favor: 771,
        time: 13200,
        atk: 0,
        def: 160,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'leather',
        r1Qty: 199,
        resource2: 'fabric',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 7,
        component1: 'overgrownvine',
        c1Qty: 5,
        c1Tags: null,
        component2: 'darkscale',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:dragonshoes',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-4',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 295,
        surcharge: 655,
        suggest: 130,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ruinsshoes: {
        uid: 'ruinsshoes',
        level: 25,
        type: 'bl',
        xp: 38000,
        craftXp: 570,
        value: 220000,
        favor: 1001,
        time: 16200,
        atk: 0,
        def: 165,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 8,
        combo: 7,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'leather',
        r1Qty: 214,
        resource2: 'fabric',
        r2Qty: 57,
        resource3: 'mana',
        r3Qty: 8,
        component1: 'timecrystal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'gale',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 330,
        surcharge: 735,
        suggest: 145,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'ruins',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    dragonshoes: {
        uid: 'dragonshoes',
        level: 27,
        type: 'bl',
        xp: 45000,
        craftXp: 675,
        value: 320000,
        favor: 1224,
        time: 17400,
        atk: 0,
        def: 185,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'leather',
        r1Qty: 244,
        resource2: 'fabric',
        r2Qty: 67,
        resource3: 'mana',
        r3Qty: 13,
        component1: 'sewingthread',
        c1Qty: 5,
        c1Tags: null,
        component2: 'icypearl',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:captainshoes',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 395,
        surcharge: 880,
        suggest: 175,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    castleshoe: {
        uid: 'castleshoe',
        level: 28,
        type: 'bl',
        xp: 48500,
        craftXp: 728,
        value: 345000,
        favor: 1589,
        time: 21000,
        atk: 0,
        def: 190,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'leather',
        r1Qty: 259,
        resource2: 'fabric',
        r2Qty: 72,
        resource3: 'mana',
        r3Qty: 16,
        component1: 'ghostflag',
        c1Qty: 3,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'nightmare',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 410,
        surcharge: 910,
        suggest: 180,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'castle',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    cincoshoes2020: {
        uid: 'cincoshoes2020',
        level: 28,
        type: 'bl',
        xp: 48500,
        craftXp: 728,
        value: 425000,
        favor: 1589,
        time: 21000,
        atk: 48,
        def: 190,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'leather',
        r1Qty: 259,
        resource2: 'fabric',
        r2Qty: 72,
        resource3: 'mana',
        r3Qty: 16,
        component1: 'ghostflag',
        c1Qty: 3,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 450,
        surcharge: 1005,
        suggest: 200,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-05-05',
        shardPrice: 200,
    },
    captainshoes: {
        uid: 'captainshoes',
        level: 30,
        type: 'bl',
        xp: 56500,
        craftXp: 848,
        value: 605000,
        favor: 1909,
        time: 30000,
        atk: 0,
        def: 210,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 290,
        resource2: 'fabric',
        r2Qty: 82,
        resource3: 'mana',
        r3Qty: 22,
        component1: 'deepcoral',
        c1Qty: 4,
        c1Tags: null,
        component2: 'ghostflag',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:jackalopeshoes',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 805,
        surcharge: 1790,
        suggest: 360,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lunarboots: {
        uid: 'lunarboots',
        level: 31,
        type: 'bl',
        xp: 60500,
        craftXp: 908,
        value: 650000,
        favor: 2451,
        time: 36000,
        atk: 0,
        def: 220,
        hp: 14,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 306,
        resource2: 'fabric',
        r2Qty: 87,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'sirenshard',
        c1Qty: 3,
        c1Tags: null,
        component2: 'deepcoral',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component1-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 835,
        surcharge: 1850,
        suggest: 370,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-02-10',
        shardPrice: 250,
    },
    jackalopeshoes: {
        uid: 'jackalopeshoes',
        level: 33,
        type: 'bl',
        xp: 105000,
        craftXp: 1575,
        value: 1350000,
        favor: 2889,
        time: 51600,
        atk: 0,
        def: 270,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1025,
        surcharge: 2280,
        suggest: 455,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    birdshoes: {
        uid: 'birdshoes',
        level: 33,
        type: 'bl',
        xp: 105000,
        craftXp: 1575,
        value: 1600000,
        favor: 3218,
        time: 49800,
        atk: 68,
        def: 270,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'wingedbow',
        c2Qty: 2,
        c2Tags: 'common',
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'resource2-20',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'eagle',
        tag: null,
        discount: 1110,
        surcharge: 2470,
        suggest: 495,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-05-19',
        shardPrice: 300,
    },
    goldshoes: {
        uid: 'goldshoes',
        level: 33,
        type: 'bl',
        xp: 105000,
        craftXp: 1575,
        value: 2750000,
        favor: 3218,
        time: 51600,
        atk: 0,
        def: 270,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 5,
        worker1: 'tailor',
        worker2: 'master',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 338,
        resource2: 'fabric',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 31,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'tabletjewel',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 1080,
        surcharge: 2405,
        suggest: 480,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-10-01',
        shardPrice: 0,
    },
    woodshield: {
        uid: 'woodshield',
        level: 2,
        type: 'xs',
        xp: 250,
        craftXp: 4,
        value: 110,
        favor: 1,
        time: 30,
        atk: 0,
        def: 12,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 10,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 7,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'bp:buckler',
        upgrade2: 'value*1.5',
        upgrade3: 'resource1-1',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-4',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 10,
        surcharge: 20,
        suggest: 5,
        speedup: 25,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    buckler: {
        uid: 'buckler',
        level: 5,
        type: 'xs',
        xp: 1200,
        craftXp: 18,
        value: 490,
        favor: 16,
        time: 140,
        atk: 0,
        def: 28,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 2,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 14,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'elvenwood',
        c1Qty: 1,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.5',
        upgrade2: 'bp:oakshield',
        upgrade3: 'resource1-3',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 40,
        suggest: 10,
        speedup: 70,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 1,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    oakshield: {
        uid: 'oakshield',
        level: 7,
        type: 'xs',
        xp: 2350,
        craftXp: 35,
        value: 1200,
        favor: 21,
        time: 270,
        atk: 0,
        def: 40,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 3,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 17,
        resource2: 'herbs',
        r2Qty: 9,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.5',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:ironshield',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 60,
        suggest: 10,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 3,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ironshield: {
        uid: 'ironshield',
        level: 10,
        type: 'xs',
        xp: 4950,
        craftXp: 74,
        value: 3350,
        favor: 42,
        time: 630,
        atk: 0,
        def: 62,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 9,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 7,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 30,
        resource2: 'iron',
        r2Qty: 15,
        resource3: null,
        r3Qty: 0,
        component1: 'arcanedust',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:kiteshield',
        upgrade4: 'resource1-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 45,
        surcharge: 100,
        suggest: 20,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    grottoshield: {
        uid: 'grottoshield',
        level: 11,
        type: 'xs',
        xp: 6100,
        craftXp: 92,
        value: 6100,
        favor: 56,
        time: 780,
        atk: 0,
        def: 68,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 4,
        combo: 8,
        worker1: 'carpenter',
        worker2: null,
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 34,
        resource2: 'iron',
        r2Qty: 17,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-7',
        upgrade4: 'resource2-9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'light',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 60,
        surcharge: 130,
        suggest: 25,
        speedup: 225,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'grotto',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    kiteshield: {
        uid: 'kiteshield',
        level: 13,
        type: 'xs',
        xp: 8700,
        craftXp: 131,
        value: 9700,
        favor: 81,
        time: 1260,
        atk: 0,
        def: 84,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 49,
        resource2: 'ironwood',
        r2Qty: 10,
        resource3: null,
        r3Qty: 0,
        component1: 'darkscale',
        c1Qty: 3,
        c1Tags: null,
        component2: 'webbedwings',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-10',
        upgrade3: 'bp:largebuckler',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 75,
        surcharge: 165,
        suggest: 35,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    imperialscutum: {
        uid: 'imperialscutum',
        level: 14,
        type: 'xs',
        xp: 10000,
        craftXp: 150,
        value: 14500,
        favor: 169,
        time: 1590,
        atk: 0,
        def: 92,
        hp: 6,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 60,
        resource2: 'iron',
        r2Qty: 30,
        resource3: 'ironwood',
        r3Qty: 12,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-12',
        upgrade3: 'value*1.25',
        upgrade4: 'resource3-3',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'resource2-30',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 90,
        surcharge: 200,
        suggest: 40,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'wMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-07-30',
        shardPrice: 120,
    },
    argonshield: {
        uid: 'argonshield',
        level: 15,
        type: 'xs',
        xp: 12000,
        craftXp: 180,
        value: 17500,
        favor: 150,
        time: 1920,
        atk: 0,
        def: 100,
        hp: 6,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 71,
        resource2: 'iron',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'pendant',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'icypearl',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-14',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-7',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-11',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 100,
        surcharge: 220,
        suggest: 45,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-12-27',
        shardPrice: 120,
    },
    largebuckler: {
        uid: 'largebuckler',
        level: 16,
        type: 'xs',
        xp: 13500,
        craftXp: 203,
        value: 21500,
        favor: 221,
        time: 2400,
        atk: 0,
        def: 105,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 8,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 85,
        resource2: 'ironwood',
        r2Qty: 21,
        resource3: 'steel',
        r3Qty: 10,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-17',
        upgrade3: 'bp:towershield',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 110,
        surcharge: 240,
        suggest: 50,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    aegirshield: {
        uid: 'aegirshield',
        level: 17,
        type: 'xs',
        xp: 15500,
        craftXp: 233,
        value: 28000,
        favor: 305,
        time: 3000,
        atk: 29,
        def: 115,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 8,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 10,
        w3BuildingReq: 4,
        resource1: 'wood',
        r1Qty: 99,
        resource2: 'ironwood',
        r2Qty: 25,
        resource3: 'steel',
        r3Qty: 12,
        component1: 'sharpfang',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'resource1-20',
        upgrade2: 'resource2-8',
        upgrade3: 'time*0.75',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 125,
        surcharge: 275,
        suggest: 55,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-02-22',
        shardPrice: 140,
    },
    natureshield: {
        uid: 'natureshield',
        level: 18,
        type: 'xs',
        xp: 17500,
        craftXp: 263,
        value: 36000,
        favor: 376,
        time: 3600,
        atk: 0,
        def: 125,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 11,
        w3BuildingReq: 5,
        resource1: 'wood',
        r1Qty: 113,
        resource2: 'ironwood',
        r2Qty: 29,
        resource3: 'steel',
        r3Qty: 14,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 140,
        surcharge: 310,
        suggest: 60,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-04-20',
        shardPrice: 140,
    },
    towershield: {
        uid: 'towershield',
        level: 20,
        type: 'xs',
        xp: 22000,
        craftXp: 330,
        value: 66000,
        favor: 498,
        time: 6000,
        atk: 0,
        def: 140,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'wood',
        r1Qty: 141,
        resource2: 'ironwood',
        r2Qty: 36,
        resource3: 'steel',
        r3Qty: 18,
        component1: 'starmetal',
        c1Qty: 3,
        c1Tags: null,
        component2: 'darkscale',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:ancestortotem',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-4',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 185,
        surcharge: 410,
        suggest: 80,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    fallshield: {
        uid: 'fallshield',
        level: 22,
        type: 'xs',
        xp: 27000,
        craftXp: 405,
        value: 105000,
        favor: 938,
        time: 8400,
        atk: 0,
        def: 160,
        hp: 0,
        eva: '0.05',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 19,
        w2BuildingReq: 15,
        w3BuildingReq: 9,
        resource1: 'wood',
        r1Qty: 170,
        resource2: 'herbs',
        r2Qty: 85,
        resource3: 'ironwood',
        r3Qty: 43,
        component1: 'oakshield',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'silkscarab',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'resource2-43',
        upgrade2: 'resource1-34',
        upgrade3: 'time*0.75',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-42',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 230,
        surcharge: 515,
        suggest: 105,
        speedup: 735,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-10-05',
        shardPrice: 160,
    },
    ancestortotem: {
        uid: 'ancestortotem',
        level: 24,
        type: 'xs',
        xp: 32500,
        craftXp: 488,
        value: 195000,
        favor: 771,
        time: 12600,
        atk: 0,
        def: 180,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'wood',
        r1Qty: 199,
        resource2: 'ironwood',
        r2Qty: 51,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'tomahawk',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'ancientmarble',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'bp:zealouspavise',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 310,
        surcharge: 690,
        suggest: 140,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    zealouspavise: {
        uid: 'zealouspavise',
        level: 27,
        type: 'xs',
        xp: 42000,
        craftXp: 630,
        value: 320000,
        favor: 1224,
        time: 16800,
        atk: 0,
        def: 205,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'wood',
        r1Qty: 244,
        resource2: 'ironwood',
        r2Qty: 67,
        resource3: 'gems',
        r3Qty: 13,
        component1: 'ghostflag',
        c1Qty: 4,
        c1Tags: null,
        component2: 'whitesand',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:prideshield',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-4',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 395,
        surcharge: 880,
        suggest: 175,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    castleshield: {
        uid: 'castleshield',
        level: 28,
        type: 'xs',
        xp: 45500,
        craftXp: 683,
        value: 360000,
        favor: 1589,
        time: 20400,
        atk: 0,
        def: 215,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 259,
        resource2: 'ironwood',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'ectoplasm',
        c1Qty: 4,
        c1Tags: null,
        component2: 'starmetal',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-2',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'blaze',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 420,
        surcharge: 930,
        suggest: 185,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'castle',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    stpatrickshield: {
        uid: 'stpatrickshield',
        level: 28,
        type: 'xs',
        xp: 45500,
        craftXp: 683,
        value: 380000,
        favor: 1589,
        time: 20400,
        atk: 0,
        def: 215,
        hp: 14,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 259,
        resource2: 'ironwood',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'ghostflag',
        c1Qty: 3,
        c1Tags: null,
        component2: 'icypearl',
        c2Qty: 10,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-5',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 430,
        surcharge: 955,
        suggest: 190,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-03-10',
        shardPrice: 200,
    },
    eastershield2020: {
        uid: 'eastershield2020',
        level: 28,
        type: 'xs',
        xp: 45500,
        craftXp: 683,
        value: 400000,
        favor: 1589,
        time: 20400,
        atk: 0,
        def: 215,
        hp: 0,
        eva: '0.05',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 259,
        resource2: 'ironwood',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'sewingthread',
        c1Qty: 3,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 440,
        surcharge: 975,
        suggest: 195,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2020-04-07',
        shardPrice: 200,
    },
    goldshield: {
        uid: 'goldshield',
        level: 29,
        type: 'xs',
        xp: 49000,
        craftXp: 735,
        value: 600000,
        favor: 1847,
        time: 24600,
        atk: 0,
        def: 225,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'wood',
        r1Qty: 275,
        resource2: 'ironwood',
        r2Qty: 77,
        resource3: 'gems',
        r3Qty: 19,
        component1: 'tabletfragment',
        c1Qty: 6,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.70',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 400,
        surcharge: 890,
        suggest: 180,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-07-11',
        shardPrice: 0,
    },
    prideshield: {
        uid: 'prideshield',
        level: 30,
        type: 'xs',
        xp: 53000,
        craftXp: 795,
        value: 610000,
        favor: 1909,
        time: 29400,
        atk: 0,
        def: 235,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 290,
        resource2: 'ironwood',
        r2Qty: 82,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'goldshell',
        c1Qty: 5,
        c1Tags: null,
        component2: 'whitesand',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:paragon',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'lion',
        tag: null,
        discount: 810,
        surcharge: 1795,
        suggest: 360,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    dragonshield: {
        uid: 'dragonshield',
        level: 31,
        type: 'xs',
        xp: 56500,
        craftXp: 848,
        value: 655000,
        favor: 2451,
        time: 34800,
        atk: 0,
        def: 245,
        hp: 15,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: 'blacksmith',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'wood',
        r1Qty: 306,
        resource2: 'ironwood',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'deepcoral',
        c1Qty: 4,
        c1Tags: null,
        component2: 'ghostflag',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-8',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 835,
        surcharge: 1855,
        suggest: 370,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-12-29',
        shardPrice: 250,
    },
    paragon: {
        uid: 'paragon',
        level: 33,
        type: 'xs',
        xp: 97500,
        craftXp: 1463,
        value: 1300000,
        favor: 2889,
        time: 50400,
        atk: 0,
        def: 305,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 338,
        resource2: 'ironwood',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource1-68',
        supgrade2: 'resource3-6',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 1005,
        surcharge: 2235,
        suggest: 445,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    beeshield: {
        uid: 'beeshield',
        level: 33,
        type: 'xs',
        xp: 97500,
        craftXp: 1463,
        value: 1700000,
        favor: 3218,
        time: 50400,
        atk: 0,
        def: 305,
        hp: 19,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 338,
        resource2: 'ironwood',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-9',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1145,
        surcharge: 2545,
        suggest: 510,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-06-02',
        shardPrice: 300,
    },
    snowshield: {
        uid: 'snowshield',
        level: 34,
        type: 'xs',
        xp: 105000,
        craftXp: 1575,
        value: 1900000,
        favor: 3564,
        time: 60000,
        atk: 80,
        def: 320,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'carpenter',
        worker2: 'jeweler',
        worker3: 'blacksmith',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 354,
        resource2: 'ironwood',
        r2Qty: 103,
        resource3: 'mana',
        r3Qty: 29,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-71',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-21',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'resource3-6',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 1210,
        surcharge: 2685,
        suggest: 535,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-30',
        shardPrice: 300,
    },
    squirecloak: {
        uid: 'squirecloak',
        level: 7,
        type: 'xc',
        xp: 3450,
        craftXp: 52,
        value: 2650,
        favor: 26,
        time: 330,
        atk: 0,
        def: 52,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'elven',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 19,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:wanderercloak',
        upgrade4: 'resource1-4',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'quality+1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 90,
        suggest: 20,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    wanderercloak: {
        uid: 'wanderercloak',
        level: 10,
        type: 'xc',
        xp: 7300,
        craftXp: 110,
        value: 8350,
        favor: 52,
        time: 750,
        atk: 0,
        def: 78,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 9,
        worker1: 'elven',
        worker2: null,
        worker3: null,
        w1BuildingReq: 4,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 33,
        resource2: 'iron',
        r2Qty: 24,
        resource3: null,
        r3Qty: 0,
        component1: 'arcanedust',
        c1Qty: 3,
        c1Tags: null,
        component2: 'redpinecone',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:druidcloak',
        upgrade4: 'resource1-7',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 155,
        suggest: 30,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    druidcloak: {
        uid: 'druidcloak',
        level: 13,
        type: 'xc',
        xp: 13000,
        craftXp: 195,
        value: 19000,
        favor: 144,
        time: 1470,
        atk: 0,
        def: 105,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'elven',
        worker2: 'tailor',
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 54,
        resource2: 'herbs',
        r2Qty: 39,
        resource3: 'fabric',
        r3Qty: 11,
        component1: 'laurels',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'livingroots',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-11',
        upgrade3: 'bp:empirecloak',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-2',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 100,
        surcharge: 225,
        suggest: 45,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    empirecloak: {
        uid: 'empirecloak',
        level: 16,
        type: 'xc',
        xp: 20000,
        craftXp: 300,
        value: 41000,
        favor: 217,
        time: 3000,
        atk: 0,
        def: 140,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 8,
        worker1: 'elven',
        worker2: 'tailor',
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 8,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 94,
        resource2: 'fabric',
        r2Qty: 23,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: 'icypearl',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-19',
        upgrade3: 'bp:elvencloak',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 145,
        surcharge: 325,
        suggest: 65,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    elvencloak: {
        uid: 'elvencloak',
        level: 20,
        type: 'xc',
        xp: 32500,
        craftXp: 488,
        value: 105000,
        favor: 756,
        time: 7200,
        atk: 0,
        def: 185,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'elven',
        worker2: 'tailor',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'leather',
        r1Qty: 155,
        resource2: 'herbs',
        r2Qty: 113,
        resource3: 'fabric',
        r3Qty: 40,
        component1: 'silkscarab',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'bp:furcloak',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-23',
        supgrade2: 'multi+0.1',
        supgrade3: 'resource3-8',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 230,
        surcharge: 515,
        suggest: 105,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    furcloak: {
        uid: 'furcloak',
        level: 24,
        type: 'xc',
        xp: 48500,
        craftXp: 728,
        value: 165000,
        favor: 954,
        time: 15600,
        atk: 0,
        def: 230,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 7,
        worker1: 'elven',
        worker2: 'tailor',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'leather',
        r1Qty: 219,
        resource2: 'fabric',
        r2Qty: 57,
        resource3: 'gems',
        r3Qty: 6,
        component1: 'timecrystal',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-44',
        upgrade3: 'bp:scalecloak',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-17',
        supgrade2: 'multi+0.1',
        supgrade3: 'resource3-2',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'mammoth',
        tag: null,
        discount: 290,
        surcharge: 640,
        suggest: 130,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    scalecloak: {
        uid: 'scalecloak',
        level: 27,
        type: 'xc',
        xp: 62500,
        craftXp: 938,
        value: 390000,
        favor: 1488,
        time: 18000,
        atk: 0,
        def: 270,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'elven',
        worker2: 'tailor',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'leather',
        r1Qty: 268,
        resource2: 'fabric',
        r2Qty: 74,
        resource3: 'gems',
        r3Qty: 10,
        component1: 'savagearmor',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'darkscale',
        c2Qty: 10,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'resource1-54',
        upgrade2: 'value*1.25',
        upgrade3: 'bp:bishopcloak',
        upgrade4: 'cquality1-1',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'component2-5',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 435,
        surcharge: 965,
        suggest: 195,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    bishopcloak: {
        uid: 'bishopcloak',
        level: 30,
        type: 'xc',
        xp: 78500,
        craftXp: 1178,
        value: 615000,
        favor: 2311,
        time: 35400,
        atk: 0,
        def: 310,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'elven',
        worker2: 'priest',
        worker3: 'tailor',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'leather',
        r1Qty: 319,
        resource2: 'fabric',
        r2Qty: 91,
        resource3: 'gems',
        r3Qty: 18,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: 'ancientmarble',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-64',
        upgrade3: 'bp:starcloak',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 810,
        surcharge: 1800,
        suggest: 360,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    starcloak: {
        uid: 'starcloak',
        level: 33,
        type: 'xc',
        xp: 145000,
        craftXp: 2175,
        value: 1650000,
        favor: 3471,
        time: 60000,
        atk: 0,
        def: 400,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'elven',
        worker2: 'tailor',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 372,
        resource2: 'fabric',
        r2Qty: 107,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-74',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-21',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'resource3-5',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1130,
        surcharge: 2510,
        suggest: 500,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldcloak: {
        uid: 'goldcloak',
        level: 33,
        type: 'xc',
        xp: 145000,
        craftXp: 2175,
        value: 3200000,
        favor: 3471,
        time: 60000,
        atk: 0,
        def: 400,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 5,
        worker1: 'elven',
        worker2: 'tailor',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'leather',
        r1Qty: 372,
        resource2: 'fabric',
        r2Qty: 107,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'tabletjewel',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-74',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-21',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'multi+0.1',
        supgrade2: 'resource3-5',
        supgrade3: 'component2-1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 1165,
        surcharge: 2585,
        suggest: 515,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-01-21',
        shardPrice: 0,
    },
    frogcloak: {
        uid: 'frogcloak',
        level: 34,
        type: 'xc',
        xp: 155000,
        craftXp: 2325,
        value: 1950000,
        favor: 3387,
        time: 72000,
        atk: 0,
        def: 415,
        hp: 0,
        eva: '0.05',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'elven',
        worker2: 'tailor',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 337,
        resource2: 'oils',
        r2Qty: 98,
        resource3: 'mana',
        r3Qty: 30,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-67',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'resource3-6',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 1225,
        surcharge: 2720,
        suggest: 545,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Cloth',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-01-12',
        shardPrice: 300,
    },
    ironring: {
        uid: 'ironring',
        level: 5,
        type: 'xr',
        xp: 1200,
        craftXp: 18,
        value: 690,
        favor: 16,
        time: 140,
        atk: 29,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 9,
        worker1: 'jeweler',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 14,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-3',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-7',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 45,
        suggest: 10,
        speedup: 70,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    doublering: {
        uid: 'doublering',
        level: 7,
        type: 'xr',
        xp: 2350,
        craftXp: 35,
        value: 1200,
        favor: 21,
        time: 270,
        atk: 42,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'jeweler',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 17,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-6',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-6',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 60,
        suggest: 10,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    fatring: {
        uid: 'fatring',
        level: 10,
        type: 'xr',
        xp: 4950,
        craftXp: 74,
        value: 3350,
        favor: 42,
        time: 630,
        atk: 64,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'jeweler',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 30,
        resource2: 'herbs',
        r2Qty: 15,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:silverband',
        upgrade4: 'resource1-6',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'quality+2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 45,
        surcharge: 100,
        suggest: 20,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    grottoring: {
        uid: 'grottoring',
        level: 11,
        type: 'xr',
        xp: 6100,
        craftXp: 92,
        value: 5750,
        favor: 56,
        time: 780,
        atk: 72,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 3,
        combo: 8,
        worker1: 'jeweler',
        worker2: null,
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 34,
        resource2: 'herbs',
        r2Qty: 17,
        resource3: null,
        r3Qty: 0,
        component1: 'arcanedust',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-7',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource2-17',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'bubble',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 60,
        surcharge: 130,
        suggest: 25,
        speedup: 225,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: 'grotto',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    silverband: {
        uid: 'silverband',
        level: 13,
        type: 'xr',
        xp: 8700,
        craftXp: 131,
        value: 6850,
        favor: 81,
        time: 1260,
        atk: 88,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 8,
        worker1: 'jeweler',
        worker2: null,
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 49,
        resource2: 'herbs',
        r2Qty: 25,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:noblering',
        upgrade4: 'resource1-10',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 65,
        surcharge: 140,
        suggest: 30,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    valentinering: {
        uid: 'valentinering',
        level: 14,
        type: 'xr',
        xp: 10000,
        craftXp: 150,
        value: 11000,
        favor: 119,
        time: 1590,
        atk: 96,
        def: 0,
        hp: 6,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'jeweler',
        worker2: null,
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 60,
        resource2: 'herbs',
        r2Qty: 30,
        resource3: null,
        r3Qty: 0,
        component1: 'webbedwings',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-12',
        upgrade4: 'resource2-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource1-30',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 80,
        surcharge: 175,
        suggest: 35,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-02-07',
        shardPrice: 100,
    },
    noblering: {
        uid: 'noblering',
        level: 15,
        type: 'xr',
        xp: 12000,
        craftXp: 180,
        value: 14000,
        favor: 135,
        time: 1920,
        atk: 105,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'jeweler',
        worker2: null,
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 71,
        resource2: 'steel',
        r2Qty: 16,
        resource3: null,
        r3Qty: 0,
        component1: 'ironring',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'icypearl',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-14',
        upgrade3: 'bp:knightsignet',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 90,
        surcharge: 195,
        suggest: 40,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    cincoring2020: {
        uid: 'cincoring2020',
        level: 15,
        type: 'xr',
        xp: 12000,
        craftXp: 180,
        value: 20000,
        favor: 150,
        time: 2040,
        atk: 105,
        def: 0,
        hp: 7,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'jeweler',
        worker2: null,
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 71,
        resource2: 'steel',
        r2Qty: 16,
        resource3: null,
        r3Qty: 0,
        component1: 'icypearl',
        c1Qty: 3,
        c1Tags: null,
        component2: 'glowingmushrooms',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-14',
        upgrade3: 'resource1-14',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 105,
        surcharge: 230,
        suggest: 45,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-05-05',
        shardPrice: 120,
    },
    knightsignet: {
        uid: 'knightsignet',
        level: 17,
        type: 'xr',
        xp: 15500,
        craftXp: 233,
        value: 23500,
        favor: 349,
        time: 2700,
        atk: 120,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 1,
        w3BuildingReq: 4,
        resource1: 'iron',
        r1Qty: 99,
        resource2: 'herbs',
        r2Qty: 49,
        resource3: 'steel',
        r3Qty: 25,
        component1: 'fatring',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'mooncrystal',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-20',
        upgrade3: 'bp:batrachitestone',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 115,
        surcharge: 250,
        suggest: 50,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 15,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    freedomring: {
        uid: 'freedomring',
        level: 18,
        type: 'xr',
        xp: 17500,
        craftXp: 263,
        value: 44500,
        favor: 296,
        time: 3600,
        atk: 130,
        def: 33,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 7,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 15,
        w2BuildingReq: 1,
        w3BuildingReq: 5,
        resource1: 'iron',
        r1Qty: 113,
        resource2: 'steel',
        r2Qty: 29,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: 'redpinecone',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'resource2-9',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource1-23',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'eagle',
        tag: null,
        discount: 155,
        surcharge: 340,
        suggest: 70,
        speedup: 515,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-06-29',
        shardPrice: 140,
    },
    batrachitestone: {
        uid: 'batrachitestone',
        level: 20,
        type: 'xr',
        xp: 22000,
        craftXp: 330,
        value: 59000,
        favor: 379,
        time: 6000,
        atk: 150,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'iron',
        r1Qty: 141,
        resource2: 'steel',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'evileye',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'bp:sulfuritecoil',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-7',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 175,
        surcharge: 390,
        suggest: 80,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    pyramidring: {
        uid: 'pyramidring',
        level: 21,
        type: 'xr',
        xp: 24500,
        craftXp: 368,
        value: 88500,
        favor: 502,
        time: 7200,
        atk: 160,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 7,
        combo: 7,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'iron',
        r1Qty: 155,
        resource2: 'steel',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'starmetal',
        c1Qty: 3,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-8',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'unholy',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 215,
        surcharge: 475,
        suggest: 95,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: 'pyramid',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    chosenring: {
        uid: 'chosenring',
        level: 22,
        type: 'xr',
        xp: 27000,
        craftXp: 405,
        value: 120000,
        favor: 765,
        time: 8400,
        atk: 170,
        def: 43,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 19,
        w2BuildingReq: 15,
        w3BuildingReq: 9,
        resource1: 'iron',
        r1Qty: 170,
        resource2: 'steel',
        r2Qty: 43,
        resource3: 'oils',
        r3Qty: 22,
        component1: 'starmetal',
        c1Qty: 4,
        c1Tags: null,
        component2: 'icypearl',
        c2Qty: 1,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-34',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-9',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 250,
        surcharge: 550,
        suggest: 110,
        speedup: 735,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-09-10',
        shardPrice: 160,
    },
    sulfuritecoil: {
        uid: 'sulfuritecoil',
        level: 25,
        type: 'xr',
        xp: 35500,
        craftXp: 533,
        value: 185000,
        favor: 896,
        time: 16200,
        atk: 200,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 7,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'iron',
        r1Qty: 214,
        resource2: 'steel',
        r2Qty: 57,
        resource3: 'gems',
        r3Qty: 8,
        component1: 'ancientmarble',
        c1Qty: 5,
        c1Tags: null,
        component2: 'whitesand',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'bp:feyring',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-4',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 305,
        surcharge: 675,
        suggest: 135,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    feyring: {
        uid: 'feyring',
        level: 27,
        type: 'xr',
        xp: 42000,
        craftXp: 630,
        value: 280000,
        favor: 1224,
        time: 16800,
        atk: 220,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 6,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'iron',
        r1Qty: 244,
        resource2: 'steel',
        r2Qty: 67,
        resource3: 'gems',
        r3Qty: 13,
        component1: 'ectoplasm',
        c1Qty: 5,
        c1Tags: null,
        component2: 'timecrystal',
        c2Qty: 1,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-49',
        upgrade3: 'bp:borealis',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 370,
        surcharge: 825,
        suggest: 165,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    springflower: {
        uid: 'springflower',
        level: 28,
        type: 'xr',
        xp: 45500,
        craftXp: 683,
        value: 380000,
        favor: 1589,
        time: 20400,
        atk: 230,
        def: 0,
        hp: 14,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'iron',
        r1Qty: 259,
        resource2: 'steel',
        r2Qty: 72,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'ectoplasm',
        c1Qty: 4,
        c1Tags: null,
        component2: 'mooncrystal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-52',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-14',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-4',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 430,
        surcharge: 955,
        suggest: 190,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-03-24',
        shardPrice: 200,
    },
    schoolring2020: {
        uid: 'schoolring2020',
        level: 29,
        type: 'xr',
        xp: 49000,
        craftXp: 735,
        value: 420000,
        favor: 1777,
        time: 24600,
        atk: 240,
        def: 60,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 6,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'iron',
        r1Qty: 275,
        resource2: 'steel',
        r2Qty: 77,
        resource3: 'mana',
        r3Qty: 14,
        component1: 'sewingthread',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 450,
        surcharge: 1000,
        suggest: 200,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-08-25',
        shardPrice: 200,
    },
    goldring: {
        uid: 'goldring',
        level: 29,
        type: 'xr',
        xp: 49000,
        craftXp: 735,
        value: 550000,
        favor: 1777,
        time: 24600,
        atk: 240,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 9,
        combo: 6,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 24,
        w2BuildingReq: 20,
        w3BuildingReq: 17,
        resource1: 'iron',
        r1Qty: 275,
        resource2: 'steel',
        r2Qty: 77,
        resource3: 'mana',
        r3Qty: 14,
        component1: 'tabletfragment',
        c1Qty: 6,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-55',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-3',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 385,
        surcharge: 855,
        suggest: 170,
        speedup: 1220,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-10-03',
        shardPrice: 0,
    },
    borealis: {
        uid: 'borealis',
        level: 30,
        type: 'xr',
        xp: 53000,
        craftXp: 795,
        value: 625000,
        favor: 1909,
        time: 29400,
        atk: 250,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 6,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: 'wizard',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 290,
        resource2: 'steel',
        r2Qty: 82,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'deepcoral',
        c1Qty: 6,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-58',
        upgrade3: 'bp:mysteriousring',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 815,
        surcharge: 1815,
        suggest: 365,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    templering: {
        uid: 'templering',
        level: 31,
        type: 'xr',
        xp: 56500,
        craftXp: 848,
        value: 650000,
        favor: 2451,
        time: 34800,
        atk: 260,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 6,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: 'wizard',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 306,
        resource2: 'steel',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'sirenshard',
        c1Qty: 5,
        c1Tags: null,
        component2: 'timecrystal',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-17',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'tempest',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 835,
        surcharge: 1850,
        suggest: 370,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: 'temple',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    winterring: {
        uid: 'winterring',
        level: 31,
        type: 'xr',
        xp: 56500,
        craftXp: 848,
        value: 690000,
        favor: 2451,
        time: 30000,
        atk: 260,
        def: 65,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: 'wizard',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 306,
        resource2: 'steel',
        r2Qty: 87,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'tidal',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'sirenshard',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-61',
        upgrade3: 'value*1.25',
        upgrade4: 'component1-1',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-17',
        supgrade2: 'resource3-5',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 855,
        surcharge: 1905,
        suggest: 380,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-12-02',
        shardPrice: 250,
    },
    bunnyring: {
        uid: 'bunnyring',
        level: 32,
        type: 'xr',
        xp: 61000,
        craftXp: 915,
        value: 695000,
        favor: 2831,
        time: 42000,
        atk: 270,
        def: 0,
        hp: 17,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 6,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: 'wizard',
        w1BuildingReq: 26,
        w2BuildingReq: 22,
        w3BuildingReq: 19,
        resource1: 'iron',
        r1Qty: 322,
        resource2: 'steel',
        r2Qty: 93,
        resource3: 'gems',
        r3Qty: 29,
        component1: 'goldshell',
        c1Qty: 4,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-64',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-19',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 860,
        surcharge: 1910,
        suggest: 380,
        speedup: 2635,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: true,
        releaseAt: '2021-03-23',
        shardPrice: 250,
    },
    mysteriousring: {
        uid: 'mysteriousring',
        level: 33,
        type: 'xr',
        xp: 97500,
        craftXp: 1463,
        value: 1400000,
        favor: 2889,
        time: 50400,
        atk: 325,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 5,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 338,
        resource2: 'steel',
        r2Qty: 98,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-68',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-20',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1045,
        surcharge: 2320,
        suggest: 465,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    candyring: {
        uid: 'candyring',
        level: 34,
        type: 'xr',
        xp: 105000,
        craftXp: 1575,
        value: 1700000,
        favor: 3077,
        time: 60000,
        atk: 340,
        def: 85,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 319,
        resource2: 'oils',
        r2Qty: 73,
        resource3: 'mana',
        r3Qty: 25,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-64',
        upgrade3: 'value*1.25',
        upgrade4: 'component2-1',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1145,
        surcharge: 2545,
        suggest: 510,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-09-07',
        shardPrice: 300,
    },
    newyearring: {
        uid: 'newyearring',
        level: 34,
        type: 'xr',
        xp: 105000,
        craftXp: 1575,
        value: 1800000,
        favor: 3671,
        time: 60000,
        atk: 340,
        def: 0,
        hp: 21,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 5,
        worker1: 'jeweler',
        worker2: 'master',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 354,
        resource2: 'steel',
        r2Qty: 103,
        resource3: 'gems',
        r3Qty: 34,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'goldshell',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'resource1-71',
        upgrade2: 'resource2-31',
        upgrade3: 'time*0.8',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 1175,
        surcharge: 2615,
        suggest: 525,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-12-29',
        shardPrice: 300,
    },
    ironamulet: {
        uid: 'ironamulet',
        level: 4,
        type: 'xa',
        xp: 920,
        craftXp: 14,
        value: 550,
        favor: 10,
        time: 110,
        atk: 0,
        def: 23,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 9,
        worker1: 'priest',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 9,
        resource2: 'leather',
        r2Qty: 6,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-3',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-3',
        supgrade2: 'multi+0.3',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 20,
        surcharge: 40,
        suggest: 10,
        speedup: 55,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    pendant: {
        uid: 'pendant',
        level: 6,
        type: 'xa',
        xp: 2050,
        craftXp: 31,
        value: 1050,
        favor: 14,
        time: 220,
        atk: 0,
        def: 35,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 9,
        worker1: 'priest',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 12,
        resource2: 'leather',
        r2Qty: 8,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-4',
        upgrade3: 'time*0.75',
        upgrade4: 'quality+1',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'resource1-4',
        supgrade2: 'multi+0.2',
        supgrade3: 'quality+2',
        su1Cost: 5,
        su2Cost: 5,
        su3Cost: 5,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 25,
        surcharge: 55,
        suggest: 10,
        speedup: 90,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    chain: {
        uid: 'chain',
        level: 8,
        type: 'xa',
        xp: 3800,
        craftXp: 57,
        value: 2450,
        favor: 20,
        time: 400,
        atk: 0,
        def: 49,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 9,
        worker1: 'priest',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 16,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'redpinecone',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:sunpendant',
        upgrade4: 'resource1-3',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 40,
        surcharge: 85,
        suggest: 15,
        speedup: 135,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    mundraamulet: {
        uid: 'mundraamulet',
        level: 9,
        type: 'xa',
        xp: 4850,
        craftXp: 146,
        value: 5300,
        favor: 27,
        time: 520,
        atk: 14,
        def: 56,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'moondragon',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 19,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'glowingmushrooms',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.8',
        upgrade3: 'bp:mundrabow',
        upgrade4: 'resource1-4',
        upgrade5: 'quality+2',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: 'dragon',
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 55,
        surcharge: 125,
        suggest: 25,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    sunpendant: {
        uid: 'sunpendant',
        level: 11,
        type: 'xa',
        xp: 7450,
        craftXp: 112,
        value: 5950,
        favor: 37,
        time: 870,
        atk: 0,
        def: 72,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 8,
        worker1: 'priest',
        worker2: null,
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 26,
        resource2: 'leather',
        r2Qty: 17,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'bp:noblechain',
        upgrade4: 'resource1-5',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 60,
        surcharge: 130,
        suggest: 25,
        speedup: 225,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 6,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    grottoamulet: {
        uid: 'grottoamulet',
        level: 12,
        type: 'xa',
        xp: 9000,
        craftXp: 135,
        value: 8600,
        favor: 52,
        time: 1110,
        atk: 0,
        def: 80,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 4,
        combo: 8,
        worker1: 'priest',
        worker2: null,
        worker3: null,
        w1BuildingReq: 9,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 31,
        resource2: 'leather',
        r2Qty: 20,
        resource3: null,
        r3Qty: 0,
        component1: 'webbedwings',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-6',
        upgrade4: 'resource2-4',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-10',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'corrupted',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 70,
        surcharge: 155,
        suggest: 30,
        speedup: 260,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: 'grotto',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    medal: {
        uid: 'medal',
        level: 12,
        type: 'xa',
        xp: 9000,
        craftXp: 135,
        value: 9000,
        favor: 52,
        time: 1110,
        atk: 0,
        def: 80,
        hp: 5,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 8,
        worker1: 'priest',
        worker2: null,
        worker3: null,
        w1BuildingReq: 9,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 31,
        resource2: 'leather',
        r2Qty: 20,
        resource3: null,
        r3Qty: 0,
        component1: 'arcanedust',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-9',
        upgrade4: 'resource2-4',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'eagle',
        tag: null,
        discount: 70,
        surcharge: 160,
        suggest: 30,
        speedup: 260,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-06-30',
        shardPrice: 100,
    },
    noblechain: {
        uid: 'noblechain',
        level: 13,
        type: 'xa',
        xp: 10500,
        craftXp: 158,
        value: 10000,
        favor: 89,
        time: 1380,
        atk: 0,
        def: 88,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 8,
        worker1: 'priest',
        worker2: null,
        worker3: null,
        w1BuildingReq: 10,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 37,
        resource2: 'leather',
        r2Qty: 25,
        resource3: 'steel',
        r3Qty: 8,
        component1: 'icypearl',
        c1Qty: 2,
        c1Tags: null,
        component2: 'glowingmushrooms',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-7',
        upgrade3: 'bp:holysymbol',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-2',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 75,
        surcharge: 165,
        suggest: 35,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    stpatrickmedallion: {
        uid: 'stpatrickmedallion',
        level: 14,
        type: 'xa',
        xp: 12500,
        craftXp: 188,
        value: 19000,
        favor: 122,
        time: 1740,
        atk: 24,
        def: 96,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 11,
        w2BuildingReq: 4,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 45,
        resource2: 'leather',
        r2Qty: 30,
        resource3: 'steel',
        r3Qty: 9,
        component1: 'livingroots',
        c1Qty: 2,
        c1Tags: null,
        component2: 'preciousgem',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-9',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-6',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-3',
        supgrade2: 'component2-3',
        supgrade3: 'multi+0.1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 100,
        surcharge: 225,
        suggest: 45,
        speedup: 335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-03-10',
        shardPrice: 120,
    },
    goldamulet: {
        uid: 'goldamulet',
        level: 16,
        type: 'xa',
        xp: 16500,
        craftXp: 248,
        value: 50000,
        favor: 210,
        time: 2700,
        atk: 0,
        def: 115,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 5,
        combo: 8,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 64,
        resource2: 'leather',
        r2Qty: 43,
        resource3: 'steel',
        r3Qty: 15,
        component1: 'tabletfragment',
        c1Qty: 1,
        c1Tags: null,
        component2: 'glowingmushrooms',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-13',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-8',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 120,
        surcharge: 270,
        suggest: 55,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-12-21',
        shardPrice: 0,
    },
    holysymbol: {
        uid: 'holysymbol',
        level: 16,
        type: 'xa',
        xp: 16500,
        craftXp: 248,
        value: 29500,
        favor: 194,
        time: 1920,
        atk: 0,
        def: 115,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 8,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 64,
        resource2: 'leather',
        r2Qty: 43,
        resource3: 'steel',
        r3Qty: 15,
        component1: 'ironamulet',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'whitesand',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-13',
        upgrade3: 'bp:titaniumtorc',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'cquality1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 125,
        surcharge: 280,
        suggest: 55,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 17,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    oktoberamulet: {
        uid: 'oktoberamulet',
        level: 17,
        type: 'xa',
        xp: 19000,
        craftXp: 285,
        value: 40500,
        favor: 269,
        time: 2400,
        atk: 29,
        def: 0,
        hp: 31,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 8,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 74,
        resource2: 'leather',
        r2Qty: 49,
        resource3: 'steel',
        r3Qty: 19,
        component1: 'mooncrystal',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-15',
        upgrade3: 'time*0.9',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource3-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 125,
        surcharge: 325,
        suggest: 55,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-09-22',
        shardPrice: 140,
    },
    titaniumtorc: {
        uid: 'titaniumtorc',
        level: 21,
        type: 'xa',
        xp: 30000,
        craftXp: 450,
        value: 78500,
        favor: 450,
        time: 7800,
        atk: 0,
        def: 160,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 7,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'iron',
        r1Qty: 116,
        resource2: 'steel',
        r2Qty: 29,
        resource3: 'oils',
        r3Qty: 20,
        component1: 'starmetal',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-23',
        upgrade3: 'bp:magatama',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 200,
        surcharge: 445,
        suggest: 90,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 25,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    timelocket: {
        uid: 'timelocket',
        level: 22,
        type: 'xa',
        xp: 33000,
        craftXp: 495,
        value: 115000,
        favor: 578,
        time: 9600,
        atk: 43,
        def: 170,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 19,
        w2BuildingReq: 15,
        w3BuildingReq: 9,
        resource1: 'iron',
        r1Qty: 127,
        resource2: 'steel',
        r2Qty: 32,
        resource3: 'oils',
        r3Qty: 22,
        component1: 'silkscarab',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-25',
        upgrade3: 'value*1.25',
        upgrade4: 'time*0.9',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-6',
        supgrade2: 'multi+0.1',
        supgrade3: 'resource3-4',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 240,
        surcharge: 535,
        suggest: 105,
        speedup: 735,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2019-08-27',
        shardPrice: 160,
    },
    magatama: {
        uid: 'magatama',
        level: 24,
        type: 'xa',
        xp: 40000,
        craftXp: 600,
        value: 200000,
        favor: 553,
        time: 14400,
        atk: 0,
        def: 190,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'iron',
        r1Qty: 149,
        resource2: 'steel',
        r2Qty: 39,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'laurels',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'timecrystal',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-30',
        upgrade3: 'bp:phylactery',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 315,
        surcharge: 700,
        suggest: 140,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 35,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    dragonamulet: {
        uid: 'dragonamulet',
        level: 25,
        type: 'xa',
        xp: 44000,
        craftXp: 660,
        value: 245000,
        favor: 715,
        time: 17400,
        atk: 0,
        def: 200,
        hp: 12,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'iron',
        r1Qty: 160,
        resource2: 'steel',
        r2Qty: 42,
        resource3: 'gems',
        r3Qty: 8,
        component1: 'ancientmarble',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-32',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-8',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 345,
        surcharge: 770,
        suggest: 155,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-12-29',
        shardPrice: 180,
    },
    archivistglasses: {
        uid: 'archivistglasses',
        level: 26,
        type: 'xa',
        xp: 47500,
        craftXp: 713,
        value: 260000,
        favor: 739,
        time: 21000,
        atk: 35,
        def: 210,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'special',
        tier: 8,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 13,
        resource1: 'iron',
        r1Qty: 172,
        resource2: 'steel',
        r2Qty: 46,
        resource3: null,
        r3Qty: 0,
        component1: 'circlet',
        c1Qty: 2,
        c1Tags: 'common',
        component2: 'timecrystal',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-34',
        upgrade3: 'value*1.25',
        upgrade4: 'component1-1',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource2-14',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 360,
        surcharge: 795,
        suggest: 160,
        speedup: 995,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    phylactery: {
        uid: 'phylactery',
        level: 27,
        type: 'xa',
        xp: 51500,
        craftXp: 773,
        value: 305000,
        favor: 884,
        time: 18000,
        atk: 0,
        def: 220,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'iron',
        r1Qty: 183,
        resource2: 'steel',
        r2Qty: 50,
        resource3: 'gems',
        r3Qty: 13,
        component1: 'chain',
        c1Qty: 3,
        c1Tags: 'common',
        component2: 'ectoplasm',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-37',
        upgrade3: 'bp:alimyriad',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-2',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 385,
        surcharge: 860,
        suggest: 170,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 45,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    rudoamulet: {
        uid: 'rudoamulet',
        level: 28,
        type: 'xa',
        xp: 56000,
        craftXp: 840,
        value: 415000,
        favor: 1061,
        time: 22200,
        atk: 58,
        def: 230,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: null,
        w1BuildingReq: 23,
        w2BuildingReq: 19,
        w3BuildingReq: 16,
        resource1: 'wood',
        r1Qty: 181,
        resource2: 'ironwood',
        r2Qty: 47,
        resource3: 'gems',
        r3Qty: 16,
        component1: 'oakstaff',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'sewingthread',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-36',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-9',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-5',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'bear',
        tag: null,
        discount: 450,
        surcharge: 995,
        suggest: 200,
        speedup: 1140,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-04-21',
        shardPrice: 200,
    },
    alimyriad: {
        uid: 'alimyriad',
        level: 30,
        type: 'xa',
        xp: 65000,
        craftXp: 975,
        value: 610000,
        favor: 1392,
        time: 32400,
        atk: 0,
        def: 250,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 10,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: 'wizard',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 218,
        resource2: 'steel',
        r2Qty: 62,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'goldshell',
        c1Qty: 5,
        c1Tags: null,
        component2: 'sharpfang',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-44',
        upgrade3: 'bp:brisingamen',
        upgrade4: 'value*1.25',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 810,
        surcharge: 1795,
        suggest: 360,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    priestamulet: {
        uid: 'priestamulet',
        level: 30,
        type: 'xa',
        xp: 65000,
        craftXp: 975,
        value: 630000,
        favor: 1545,
        time: 32400,
        atk: 63,
        def: 250,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: 'wizard',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 218,
        resource2: 'steel',
        r2Qty: 62,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'sirenshard',
        c1Qty: 4,
        c1Tags: null,
        component2: 'silkscarab',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-44',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-12',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component2-2',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'light',
        spiritAffinity: null,
        tag: null,
        discount: 820,
        surcharge: 1825,
        suggest: 365,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2020-10-09',
        shardPrice: 250,
    },
    vipamulet: {
        uid: 'vipamulet',
        level: 31,
        type: 'xa',
        xp: 69500,
        craftXp: 1043,
        value: 650000,
        favor: 1781,
        time: 38400,
        atk: 0,
        def: 260,
        hp: 16,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: 'wizard',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 230,
        resource2: 'steel',
        r2Qty: 66,
        resource3: 'gems',
        r3Qty: 25,
        component1: 'deepcoral',
        c1Qty: 4,
        c1Tags: null,
        component2: 'evileye',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-46',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-13',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-8',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 835,
        surcharge: 1850,
        suggest: 370,
        speedup: 2480,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-01-13',
        shardPrice: 250,
    },
    brisingamen: {
        uid: 'brisingamen',
        level: 33,
        type: 'xa',
        xp: 120000,
        craftXp: 1800,
        value: 1550000,
        favor: 2111,
        time: 55200,
        atk: 0,
        def: 325,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 254,
        resource2: 'steel',
        r2Qty: 73,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-51',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-9',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'fire',
        spiritAffinity: null,
        tag: null,
        discount: 1095,
        surcharge: 2435,
        suggest: 485,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 85,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    peakamulet: {
        uid: 'peakamulet',
        level: 33,
        type: 'xa',
        xp: 120000,
        craftXp: 1800,
        value: 1700000,
        favor: 2340,
        time: 55200,
        atk: 0,
        def: 325,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: 'wizard',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'iron',
        r1Qty: 254,
        resource2: 'steel',
        r2Qty: 73,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'icybreeze',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-51',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-15',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-9',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: 'flood',
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: 'elemental',
        discount: 1145,
        surcharge: 2545,
        suggest: 510,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: 'peak',
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    troblinamulet: {
        uid: 'troblinamulet',
        level: 34,
        type: 'xa',
        xp: 130000,
        craftXp: 1950,
        value: 1800000,
        favor: 2397,
        time: 66000,
        atk: 85,
        def: 340,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: 'carpenter',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 248,
        resource2: 'ironwood',
        r2Qty: 67,
        resource3: 'mana',
        r3Qty: 30,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'livingroots',
        c2Qty: 10,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-50',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-13',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-9',
        supgrade2: 'component2-4',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'earth',
        spiritAffinity: null,
        tag: null,
        discount: 1175,
        surcharge: 2615,
        suggest: 525,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-07-14',
        shardPrice: 300,
    },
    atlab_amulet: {
        uid: 'atlab_amulet',
        level: 34,
        type: 'xa',
        xp: 130000,
        craftXp: 1950,
        value: 1650000,
        favor: 2823,
        time: 61800,
        atk: 0,
        def: 340,
        hp: 21,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: 'carpenter',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'wood',
        r1Qty: 280,
        resource2: 'ironwood',
        r2Qty: 75,
        resource3: 'gems',
        r3Qty: 34,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'gale',
        c2Qty: 1,
        c2Tags: 'common',
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-84',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-23',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-14',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 1130,
        surcharge: 2510,
        suggest: 500,
        speedup: 2950,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Wood',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-11-02',
        shardPrice: 300,
    },
    wiccanamulet: {
        uid: 'wiccanamulet',
        level: 35,
        type: 'xa',
        xp: 135000,
        craftXp: 2025,
        value: 1950000,
        favor: 2929,
        time: 79200,
        atk: 88,
        def: 350,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 11,
        combo: 0,
        worker1: 'priest',
        worker2: 'jeweler',
        worker3: 'wizard',
        w1BuildingReq: 28,
        w2BuildingReq: 24,
        w3BuildingReq: 21,
        resource1: 'iron',
        r1Qty: 278,
        resource2: 'steel',
        r2Qty: 81,
        resource3: 'mana',
        r3Qty: 32,
        component1: 'sparksigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'grimtalon',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-56',
        upgrade3: 'value*1.25',
        upgrade4: 'resource2-16',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-10',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 1225,
        surcharge: 2720,
        suggest: 545,
        speedup: 3120,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'pMetal',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-03-09',
        shardPrice: 300,
    },
    troblin: {
        uid: 'troblin',
        level: 7,
        type: 'xf',
        xp: 4050,
        craftXp: 61,
        value: 3800,
        favor: 29,
        time: 350,
        atk: 82,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 3,
        combo: 9,
        worker1: 'elven',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 21,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'club',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'redpinecone',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'value*1.25',
        upgrade2: 'resource1-4',
        upgrade3: 'quality+1',
        upgrade4: 'bp:yeti',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+2',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 45,
        surcharge: 105,
        suggest: 20,
        speedup: 110,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 0,
        chest: null,
        firstOfLine: true,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    yeti: {
        uid: 'yeti',
        level: 10,
        type: 'xf',
        xp: 8700,
        craftXp: 131,
        value: 9650,
        favor: 59,
        time: 810,
        atk: 125,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 9,
        worker1: 'elven',
        worker2: null,
        worker3: null,
        w1BuildingReq: 4,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 37,
        resource2: 'leather',
        r2Qty: 15,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 3,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'value*1.25',
        upgrade2: 'resource2-3',
        upgrade3: 'resource1-7',
        upgrade4: 'bp:toadwitch',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'time*0.75',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 15,
        su2Cost: 15,
        su3Cost: 15,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 75,
        surcharge: 165,
        suggest: 35,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 10,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    toadwitch: {
        uid: 'toadwitch',
        level: 13,
        type: 'xf',
        xp: 15500,
        craftXp: 233,
        value: 21500,
        favor: 220,
        time: 1650,
        atk: 175,
        def: 175,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 5,
        combo: 8,
        worker1: 'elven',
        worker2: null,
        worker3: null,
        w1BuildingReq: 8,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 62,
        resource2: 'leather',
        r2Qty: 25,
        resource3: 'iron',
        r3Qty: 25,
        component1: 'livingroots',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 8,
        u2Req: 20,
        u3Req: 36,
        u4Req: 55,
        u5Req: 85,
        upgrade1: 'resource3-25',
        upgrade2: 'resource1-12',
        upgrade3: 'value*1.25',
        upgrade4: 'bp:mushgoon',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 110,
        surcharge: 240,
        suggest: 50,
        speedup: 295,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    mushgoon: {
        uid: 'mushgoon',
        level: 16,
        type: 'xf',
        xp: 24000,
        craftXp: 360,
        value: 48500,
        favor: 250,
        time: 3300,
        atk: 230,
        def: 0,
        hp: 23,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 6,
        combo: 8,
        worker1: 'elven',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 106,
        resource2: 'oils',
        r2Qty: 26,
        resource3: null,
        r3Qty: 0,
        component1: 'whitesand',
        c1Qty: 4,
        c1Tags: null,
        component2: 'glowingmushrooms',
        c2Qty: 1,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'resource1-21',
        upgrade2: 'resource2-8',
        upgrade3: 'value*1.25',
        upgrade4: 'bp:anubis',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-5',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 25,
        su2Cost: 25,
        su3Cost: 25,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 160,
        surcharge: 355,
        suggest: 70,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 30,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    anubis: {
        uid: 'anubis',
        level: 21,
        type: 'xf',
        xp: 43000,
        craftXp: 645,
        value: 160000,
        favor: 658,
        time: 9000,
        atk: 0,
        def: 320,
        hp: 32,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 7,
        worker1: 'elven',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'herbs',
        r1Qty: 194,
        resource2: 'oils',
        r2Qty: 49,
        resource3: null,
        r3Qty: 0,
        component1: 'silkscarab',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'resource1-39',
        upgrade2: 'resource2-15',
        upgrade3: 'value*1.25',
        upgrade4: 'bp:cyclops',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'resource2-10',
        supgrade2: 'multi+0.1',
        supgrade3: 'component1-1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'wolf',
        tag: null,
        discount: 285,
        surcharge: 630,
        suggest: 125,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 40,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    cyclops: {
        uid: 'cyclops',
        level: 24,
        type: 'xf',
        xp: 57500,
        craftXp: 863,
        value: 260000,
        favor: 1121,
        time: 16800,
        atk: 0,
        def: 0,
        hp: 38,
        eva: '0.10',
        crit: '0',
        excl: 'pack',
        tier: 8,
        combo: 0,
        worker1: 'elven',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'herbs',
        r1Qty: 249,
        resource2: 'oils',
        r2Qty: 64,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'circlet',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'timecrystal',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'resource1-50',
        upgrade2: 'resource2-19',
        upgrade3: 'value*1.25',
        upgrade4: 'bp:tyrantking',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'component2-1',
        su1Cost: 35,
        su2Cost: 35,
        su3Cost: 35,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: 'crab',
        tag: null,
        discount: 360,
        surcharge: 795,
        suggest: 160,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'lMetal',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    tyrantking: {
        uid: 'tyrantking',
        level: 27,
        type: 'xf',
        xp: 74000,
        craftXp: 1110,
        value: 380000,
        favor: 1773,
        time: 22200,
        atk: 0,
        def: 445,
        hp: 45,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 0,
        worker1: 'elven',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 22,
        w2BuildingReq: 18,
        w3BuildingReq: 15,
        resource1: 'herbs',
        r1Qty: 305,
        resource2: 'oils',
        r2Qty: 84,
        resource3: 'gems',
        r3Qty: 13,
        component1: 'ectoplasm',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'resource1-61',
        upgrade2: 'resource2-25',
        upgrade3: 'value*1.25',
        upgrade4: 'bp:mermaid',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'dark',
        spiritAffinity: null,
        tag: null,
        discount: 430,
        surcharge: 955,
        suggest: 190,
        speedup: 1065,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    mermaid: {
        uid: 'mermaid',
        level: 30,
        type: 'xf',
        xp: 93500,
        craftXp: 1403,
        value: 855000,
        favor: 2759,
        time: 37200,
        atk: 510,
        def: 510,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 0,
        worker1: 'elven',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'herbs',
        r1Qty: 363,
        resource2: 'oils',
        r2Qty: 103,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'trident',
        c1Qty: 1,
        c1Tags: 'uncommon',
        component2: 'sirenshard',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'resource1-73',
        upgrade2: 'resource2-31',
        upgrade3: 'value*1.25',
        upgrade4: 'bp:harpy',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'cquality1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 45,
        su2Cost: 45,
        su3Cost: 45,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'water',
        spiritAffinity: null,
        tag: null,
        discount: 950,
        surcharge: 2110,
        suggest: 420,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 80,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    harpy: {
        uid: 'harpy',
        level: 33,
        type: 'xf',
        xp: 170000,
        craftXp: 2550,
        value: 2300000,
        favor: 4172,
        time: 65400,
        atk: 660,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0.10',
        excl: 'pack',
        tier: 11,
        combo: 0,
        worker1: 'elven',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 423,
        resource2: 'oils',
        r2Qty: 122,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'gracesigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'crystalegg',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'resource1-85',
        upgrade2: 'resource2-37',
        upgrade3: 'value*1.25',
        upgrade4: 'resource3-3',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'air',
        spiritAffinity: null,
        tag: null,
        discount: 1325,
        surcharge: 2940,
        suggest: 590,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Vegetal',
        unlock: 100,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldgolem: {
        uid: 'goldgolem',
        level: 33,
        type: 'xf',
        xp: 170000,
        craftXp: 2550,
        value: 4400000,
        favor: 4172,
        time: 65400,
        atk: 660,
        def: 0,
        hp: 66,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 11,
        combo: 0,
        worker1: 'elven',
        worker2: 'wizard',
        worker3: 'priest',
        w1BuildingReq: 27,
        w2BuildingReq: 23,
        w3BuildingReq: 20,
        resource1: 'herbs',
        r1Qty: 423,
        resource2: 'oils',
        r2Qty: 122,
        resource3: 'gems',
        r3Qty: 31,
        component1: 'mightsigil',
        c1Qty: 1,
        c1Tags: null,
        component2: 'tabletjewel',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'resource1-85',
        upgrade2: 'resource2-37',
        upgrade3: 'value*1.25',
        upgrade4: 'resource3-3',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-6',
        supgrade2: 'component2-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: 'gold',
        spiritAffinity: null,
        tag: null,
        discount: 1355,
        surcharge: 3010,
        suggest: 600,
        speedup: 2790,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'hMetal',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-12-24',
        shardPrice: 0,
    },
    chipped: {
        uid: 'chipped',
        level: 8,
        type: 'xu',
        xp: 3100,
        craftXp: 47,
        value: 4400,
        favor: 30,
        time: 660,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 0,
        worker1: 'academy',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 21,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'webbedwings',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'time*0.9',
        upgrade2: 'resource1-2',
        upgrade3: 'resource1-4',
        upgrade4: 'time*0.9',
        upgrade5: 'bp:flawed',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'time*0.8',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 50,
        surcharge: 110,
        suggest: 45,
        speedup: 135,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    flawed: {
        uid: 'flawed',
        level: 16,
        type: 'xu',
        xp: 13500,
        craftXp: 203,
        value: 57500,
        favor: 193,
        time: 4800,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 0,
        worker1: 'academy',
        worker2: null,
        worker3: null,
        w1BuildingReq: 13,
        w2BuildingReq: 8,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 85,
        resource2: 'oils',
        r2Qty: 21,
        resource3: null,
        r3Qty: 0,
        component1: 'starmetal',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.9',
        upgrade2: 'resource1-9',
        upgrade3: 'resource1-17',
        upgrade4: 'resource2-4',
        upgrade5: 'bp:chiseled',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'time*0.8',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 175,
        surcharge: 385,
        suggest: 155,
        speedup: 420,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 30,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    chiseled: {
        uid: 'chiseled',
        level: 24,
        type: 'xu',
        xp: 32500,
        craftXp: 488,
        value: 160000,
        favor: 862,
        time: 15000,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 9,
        combo: 0,
        worker1: 'academy',
        worker2: 'master',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'herbs',
        r1Qty: 199,
        resource2: 'oils',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 7,
        component1: 'chipped',
        c1Qty: 3,
        c1Tags: 'common',
        component2: 'ectoplasm',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-40',
        upgrade3: 'resource2-26',
        upgrade4: 'resource1-60',
        upgrade5: 'component1-1',
        upgradeBonus: 2,
        supgrade1: 'resource3-7',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 285,
        surcharge: 630,
        suggest: 250,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lessermoon: {
        uid: 'lessermoon',
        level: 9,
        type: 'xm',
        xp: 3950,
        craftXp: 118,
        value: 6700,
        favor: 39,
        time: 870,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 4,
        combo: 0,
        worker1: 'moondragon',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 26,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'preciousgem',
        c1Qty: 5,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'time*0.9',
        upgrade2: 'resource1-3',
        upgrade3: 'resource1-5',
        upgrade4: 'component1-1',
        upgrade5: 'bp:flawedmoon',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'time*0.8',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 60,
        surcharge: 135,
        suggest: 55,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    flawedmoon: {
        uid: 'flawedmoon',
        level: 17,
        type: 'xm',
        xp: 15500,
        craftXp: 466,
        value: 52000,
        favor: 242,
        time: 6000,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 7,
        combo: 0,
        worker1: 'moondragon',
        worker2: null,
        worker3: null,
        w1BuildingReq: 14,
        w2BuildingReq: 10,
        w3BuildingReq: 4,
        resource1: 'herbs',
        r1Qty: 99,
        resource2: 'oils',
        r2Qty: 25,
        resource3: null,
        r3Qty: 0,
        component1: 'evileye',
        c1Qty: 4,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.9',
        upgrade2: 'resource1-10',
        upgrade3: 'resource1-20',
        upgrade4: 'resource2-5',
        upgrade5: 'bp:greatermoon',
        upgradeBonus: 1,
        supgrade1: 'component1-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'time*0.8',
        su1Cost: 40,
        su2Cost: 40,
        su3Cost: 40,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 165,
        surcharge: 365,
        suggest: 145,
        speedup: 465,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 30,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    greatermoon: {
        uid: 'greatermoon',
        level: 25,
        type: 'xm',
        xp: 35500,
        craftXp: 1066,
        value: 170000,
        favor: 1001,
        time: 15000,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: 'pack',
        tier: 10,
        combo: 0,
        worker1: 'moondragon',
        worker2: 'sundragon',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'herbs',
        r1Qty: 214,
        resource2: 'oils',
        r2Qty: 57,
        resource3: 'gems',
        r3Qty: 8,
        component1: 'lessermoon',
        c1Qty: 3,
        c1Tags: 'common',
        component2: 'ghostflag',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.8',
        upgrade2: 'resource1-43',
        upgrade3: 'resource2-29',
        upgrade4: 'resource1-64',
        upgrade5: 'component1-1',
        upgradeBonus: 2,
        supgrade1: 'resource3-8',
        supgrade2: 'component1-1',
        supgrade3: 'multi+0.1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 435,
        surcharge: 970,
        suggest: 390,
        speedup: 1665,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 60,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ember: {
        uid: 'ember',
        level: 9,
        type: 'z',
        xp: 4850,
        craftXp: 73,
        value: 8150,
        favor: 35,
        time: 520,
        atk: 14,
        def: 10,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: 'wizard',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 26,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'preciousgem',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 3,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:breeze',
        upgrade2: 'time*0.75',
        upgrade3: 'component2-1',
        upgrade4: 'bp:flaming',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'fire+5',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 150,
        suggest: 60,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    flaming: {
        uid: 'flaming',
        level: 20,
        type: 'z',
        xp: 27000,
        craftXp: 405,
        value: 105000,
        favor: 379,
        time: 6600,
        atk: 38,
        def: 25,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'iron',
        r1Qty: 141,
        resource2: 'steel',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'flawed',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'whitesand',
        c2Qty: 10,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'component2-1',
        upgrade4: 'bp:blaze',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'fire+10',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 230,
        surcharge: 515,
        suggest: 205,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    blaze: {
        uid: 'blaze',
        level: 24,
        type: 'z',
        xp: 40000,
        craftXp: 600,
        value: 350000,
        favor: 771,
        time: 14400,
        atk: 48,
        def: 32,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'iron',
        r1Qty: 199,
        resource2: 'steel',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 7,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'timecrystal',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-10',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'fire+15',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 915,
        suggest: 365,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    bubble: {
        uid: 'bubble',
        level: 9,
        type: 'z',
        xp: 4850,
        craftXp: 73,
        value: 7400,
        favor: 35,
        time: 520,
        atk: 14,
        def: 10,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: 'wizard',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 26,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'arcanedust',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 3,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:natural',
        upgrade2: 'time*0.75',
        upgrade3: 'component2-1',
        upgrade4: 'bp:tidal',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'water+5',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 65,
        surcharge: 145,
        suggest: 60,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    tidal: {
        uid: 'tidal',
        level: 20,
        type: 'z',
        xp: 27000,
        craftXp: 405,
        value: 100000,
        favor: 379,
        time: 6600,
        atk: 38,
        def: 25,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'wood',
        r1Qty: 141,
        resource2: 'ironwood',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'flawed',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'mooncrystal',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'component2-1',
        upgrade4: 'bp:flood',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'water+10',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 225,
        surcharge: 500,
        suggest: 200,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    flood: {
        uid: 'flood',
        level: 24,
        type: 'z',
        xp: 40000,
        craftXp: 600,
        value: 350000,
        favor: 771,
        time: 14400,
        atk: 48,
        def: 32,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'wood',
        r1Qty: 199,
        resource2: 'ironwood',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 7,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'ectoplasm',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-10',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'time*0.9',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'water+15',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 915,
        suggest: 365,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    breeze: {
        uid: 'breeze',
        level: 9,
        type: 'z',
        xp: 4850,
        craftXp: 73,
        value: 8550,
        favor: 35,
        time: 520,
        atk: 14,
        def: 10,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: 'wizard',
        worker2: null,
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 26,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'webbedwings',
        c2Qty: 7,
        c2Tags: null,
        u1Req: 3,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:light',
        upgrade2: 'time*0.75',
        upgrade3: 'component2-1',
        upgrade4: 'bp:gale',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'air+5',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 155,
        suggest: 60,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 5,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    gale: {
        uid: 'gale',
        level: 20,
        type: 'z',
        xp: 27000,
        craftXp: 405,
        value: 99500,
        favor: 379,
        time: 6600,
        atk: 38,
        def: 25,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'leather',
        r1Qty: 141,
        resource2: 'fabric',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'flawed',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'starmetal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'component2-1',
        upgrade4: 'bp:tempest',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'air+10',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 225,
        surcharge: 500,
        suggest: 200,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    tempest: {
        uid: 'tempest',
        level: 24,
        type: 'z',
        xp: 40000,
        craftXp: 600,
        value: 350000,
        favor: 771,
        time: 14400,
        atk: 48,
        def: 32,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'leather',
        r1Qty: 199,
        resource2: 'fabric',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 7,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'sewingthread',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-10',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'time*0.9',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'air+15',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 915,
        suggest: 365,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    natural: {
        uid: 'natural',
        level: 9,
        type: 'z',
        xp: 4850,
        craftXp: 73,
        value: 9800,
        favor: 35,
        time: 520,
        atk: 14,
        def: 10,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: 'wizard',
        worker2: null,
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 26,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'livingroots',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 3,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:corrupted',
        upgrade2: 'time*0.75',
        upgrade3: 'component2-1',
        upgrade4: 'bp:wild',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'earth+5',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 75,
        surcharge: 165,
        suggest: 65,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 5,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    wild: {
        uid: 'wild',
        level: 20,
        type: 'z',
        xp: 27000,
        craftXp: 405,
        value: 96500,
        favor: 379,
        time: 6600,
        atk: 38,
        def: 25,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'herbs',
        r1Qty: 141,
        resource2: 'oils',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'flawed',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'sharpfang',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'component2-1',
        upgrade4: 'bp:primal',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'earth+10',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 225,
        surcharge: 495,
        suggest: 200,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    primal: {
        uid: 'primal',
        level: 24,
        type: 'z',
        xp: 40000,
        craftXp: 600,
        value: 350000,
        favor: 771,
        time: 14400,
        atk: 48,
        def: 32,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'herbs',
        r1Qty: 199,
        resource2: 'oils',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 7,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'overgrownvine',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-10',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'earth+15',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 915,
        suggest: 365,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    light: {
        uid: 'light',
        level: 9,
        type: 'z',
        xp: 4850,
        craftXp: 73,
        value: 9600,
        favor: 35,
        time: 520,
        atk: 14,
        def: 10,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 26,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'icypearl',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 65,
        u5Req: 100,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-5',
        upgrade3: 'component2-1',
        upgrade4: 'bp:holy',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'light+5',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 75,
        surcharge: 165,
        suggest: 65,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 5,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    holy: {
        uid: 'holy',
        level: 20,
        type: 'z',
        xp: 27000,
        craftXp: 405,
        value: 96500,
        favor: 379,
        time: 6600,
        atk: 38,
        def: 25,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'herbs',
        r1Qty: 141,
        resource2: 'oils',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'flawed',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'silkscarab',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'component2-1',
        upgrade4: 'bp:sacred',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'light+10',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 225,
        surcharge: 495,
        suggest: 200,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    sacred: {
        uid: 'sacred',
        level: 24,
        type: 'z',
        xp: 40000,
        craftXp: 600,
        value: 350000,
        favor: 771,
        time: 14400,
        atk: 48,
        def: 32,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'herbs',
        r1Qty: 199,
        resource2: 'oils',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 7,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'ancientmarble',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-10',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'light+15',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 915,
        suggest: 365,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    corrupted: {
        uid: 'corrupted',
        level: 9,
        type: 'z',
        xp: 4850,
        craftXp: 73,
        value: 10500,
        favor: 35,
        time: 520,
        atk: 14,
        def: 10,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 6,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 26,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'darkscale',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 7,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-5',
        upgrade3: 'component2-1',
        upgrade4: 'bp:unholy',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'dark+5',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 75,
        surcharge: 170,
        suggest: 70,
        speedup: 165,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 5,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    unholy: {
        uid: 'unholy',
        level: 20,
        type: 'z',
        xp: 27000,
        craftXp: 405,
        value: 105000,
        favor: 379,
        time: 6600,
        atk: 38,
        def: 25,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 17,
        w2BuildingReq: 13,
        w3BuildingReq: 7,
        resource1: 'herbs',
        r1Qty: 141,
        resource2: 'oils',
        r2Qty: 36,
        resource3: null,
        r3Qty: 0,
        component1: 'flawed',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'evileye',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-28',
        upgrade3: 'component2-1',
        upgrade4: 'bp:nightmare',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'dark+10',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 230,
        surcharge: 515,
        suggest: 205,
        speedup: 620,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    nightmare: {
        uid: 'nightmare',
        level: 24,
        type: 'z',
        xp: 40000,
        craftXp: 600,
        value: 350000,
        favor: 771,
        time: 14400,
        atk: 48,
        def: 32,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'herbs',
        r1Qty: 199,
        resource2: 'oils',
        r2Qty: 51,
        resource3: 'mana',
        r3Qty: 7,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'ghostflag',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 11,
        u2Req: 28,
        u3Req: 50,
        u4Req: 80,
        u5Req: 125,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-10',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'time*0.9',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'dark+15',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 915,
        suggest: 365,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    luxuriouselement: {
        uid: 'luxuriouselement',
        level: 15,
        type: 'z',
        xp: 14500,
        craftXp: 218,
        value: 50000,
        favor: 150,
        time: 2160,
        atk: 26,
        def: 18,
        hp: 5,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 5,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 71,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'tabletfragment',
        c2Qty: 1,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.85',
        upgrade2: 'resource1-11',
        upgrade3: 'time*0.85',
        upgrade4: 'resource1-11',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'quality+1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'gold+5',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 120,
        surcharge: 270,
        suggest: 110,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-10-29',
        shardPrice: 0,
    },
    opulentelement: {
        uid: 'opulentelement',
        level: 30,
        type: 'z',
        xp: 65000,
        craftXp: 975,
        value: 1000000,
        favor: 2128,
        time: 32400,
        atk: 63,
        def: 42,
        hp: 13,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 0,
        worker1: 'wizard',
        worker2: 'priest',
        worker3: null,
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'herbs',
        r1Qty: 290,
        resource2: 'oils',
        r2Qty: 82,
        resource3: 'mana',
        r3Qty: 22,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'tabletjewel',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-58',
        upgrade3: 'resource2-12',
        upgrade4: 'resource2-12',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 2,
        elements: 'gold+15',
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 765,
        surcharge: 1705,
        suggest: 680,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2021-10-29',
        shardPrice: 0,
    },
    turtle: {
        uid: 'turtle',
        level: 10,
        type: 'z',
        xp: 6100,
        craftXp: 92,
        value: 9400,
        favor: 42,
        time: 690,
        atk: 16,
        def: 11,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: 'priest',
        worker2: null,
        worker3: null,
        w1BuildingReq: 1,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 30,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'preciousgem',
        c2Qty: 7,
        c2Tags: null,
        u1Req: 3,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:wolf',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-6',
        upgrade4: 'bp:rhino',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_turtle',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 160,
        suggest: 65,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    wolf: {
        uid: 'wolf',
        level: 10,
        type: 'z',
        xp: 6100,
        craftXp: 92,
        value: 9200,
        favor: 42,
        time: 690,
        atk: 16,
        def: 11,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: 'priest',
        worker2: null,
        worker3: null,
        w1BuildingReq: 4,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 30,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'arcanedust',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 3,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:ox',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-6',
        upgrade4: 'bp:owl',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-3',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_wolf',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 70,
        surcharge: 160,
        suggest: 65,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 5,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ox: {
        uid: 'ox',
        level: 10,
        type: 'z',
        xp: 6100,
        craftXp: 92,
        value: 10000,
        favor: 42,
        time: 690,
        atk: 16,
        def: 11,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: 'priest',
        worker2: null,
        worker3: null,
        w1BuildingReq: 7,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'wood',
        r1Qty: 30,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'livingroots',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 3,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:eagle',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-6',
        upgrade4: 'bp:armadillo',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_ox',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 75,
        surcharge: 165,
        suggest: 65,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 5,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    eagle: {
        uid: 'eagle',
        level: 10,
        type: 'z',
        xp: 6100,
        craftXp: 92,
        value: 9750,
        favor: 42,
        time: 690,
        atk: 16,
        def: 11,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: 'priest',
        worker2: null,
        worker3: null,
        w1BuildingReq: 7,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 30,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'webbedwings',
        c2Qty: 8,
        c2Tags: null,
        u1Req: 3,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:viper',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-6',
        upgrade4: 'bp:lizard',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_eagle',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 75,
        surcharge: 165,
        suggest: 65,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 5,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    viper: {
        uid: 'viper',
        level: 10,
        type: 'z',
        xp: 6100,
        craftXp: 92,
        value: 11000,
        favor: 42,
        time: 690,
        atk: 16,
        def: 11,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: 'priest',
        worker2: null,
        worker3: null,
        w1BuildingReq: 7,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'herbs',
        r1Qty: 30,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'darkscale',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 3,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'bp:cat',
        upgrade2: 'time*0.75',
        upgrade3: 'resource1-6',
        upgrade4: 'bp:crab',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_viper',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 80,
        surcharge: 175,
        suggest: 70,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 5,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    cat: {
        uid: 'cat',
        level: 10,
        type: 'z',
        xp: 6100,
        craftXp: 92,
        value: 12000,
        favor: 42,
        time: 690,
        atk: 16,
        def: 11,
        hp: 3,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: 'priest',
        worker2: null,
        worker3: null,
        w1BuildingReq: 7,
        w2BuildingReq: 1,
        w3BuildingReq: 1,
        resource1: 'leather',
        r1Qty: 30,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'icypearl',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 3,
        u2Req: 18,
        u3Req: 32,
        u4Req: 50,
        u5Req: 80,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-6',
        upgrade3: 'component2-1',
        upgrade4: 'bp:hippo',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 10,
        su2Cost: 10,
        su3Cost: 10,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_cat',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 80,
        surcharge: 180,
        suggest: 70,
        speedup: 190,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 5,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    luxuriousspirit: {
        uid: 'luxuriousspirit',
        level: 15,
        type: 'z',
        xp: 14500,
        craftXp: 218,
        value: 50000,
        favor: 150,
        time: 2160,
        atk: 26,
        def: 18,
        hp: 5,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 5,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 12,
        w2BuildingReq: 6,
        w3BuildingReq: 1,
        resource1: 'iron',
        r1Qty: 71,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: 'chipped',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'tabletfragment',
        c2Qty: 1,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.85',
        upgrade2: 'resource1-11',
        upgrade3: 'time*0.85',
        upgrade4: 'resource1-11',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'quality+1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 20,
        su2Cost: 20,
        su3Cost: 20,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_luxuriousspirit',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 120,
        surcharge: 270,
        suggest: 110,
        speedup: 375,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 0,
        chest: 'goldcity',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-02-18',
        shardPrice: 0,
    },
    rhino: {
        uid: 'rhino',
        level: 21,
        type: 'z',
        xp: 30000,
        craftXp: 450,
        value: 110000,
        favor: 447,
        time: 7800,
        atk: 41,
        def: 27,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'iron',
        r1Qty: 155,
        resource2: 'steel',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'flawed',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'sharpfang',
        c2Qty: 10,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'component2-2',
        upgrade4: 'bp:shark',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_rhino',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 235,
        surcharge: 525,
        suggest: 210,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    owl: {
        uid: 'owl',
        level: 21,
        type: 'z',
        xp: 30000,
        craftXp: 450,
        value: 110000,
        favor: 447,
        time: 7800,
        atk: 41,
        def: 27,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'leather',
        r1Qty: 155,
        resource2: 'fabric',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'flawed',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'mooncrystal',
        c2Qty: 9,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'component2-1',
        upgrade4: 'bp:walrus',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_owl',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 235,
        surcharge: 525,
        suggest: 210,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    armadillo: {
        uid: 'armadillo',
        level: 21,
        type: 'z',
        xp: 30000,
        craftXp: 450,
        value: 115000,
        favor: 447,
        time: 7800,
        atk: 41,
        def: 27,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'herbs',
        r1Qty: 155,
        resource2: 'oils',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'flawed',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'whitesand',
        c2Qty: 12,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'component2-2',
        upgrade4: 'bp:mammoth',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-2',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_armadillo',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 240,
        surcharge: 535,
        suggest: 215,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lizard: {
        uid: 'lizard',
        level: 21,
        type: 'z',
        xp: 30000,
        craftXp: 450,
        value: 130000,
        favor: 447,
        time: 7800,
        atk: 41,
        def: 27,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'herbs',
        r1Qty: 155,
        resource2: 'oils',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'flawed',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'evileye',
        c2Qty: 7,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'component2-1',
        upgrade4: 'bp:dinosaur',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_lizard',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 255,
        surcharge: 570,
        suggest: 230,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    crab: {
        uid: 'crab',
        level: 21,
        type: 'z',
        xp: 30000,
        craftXp: 450,
        value: 125000,
        favor: 447,
        time: 7800,
        atk: 41,
        def: 27,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'wood',
        r1Qty: 155,
        resource2: 'ironwood',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'flawed',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'silkscarab',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'component2-1',
        upgrade4: 'bp:bear',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_crab',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 250,
        surcharge: 560,
        suggest: 225,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    hippo: {
        uid: 'hippo',
        level: 21,
        type: 'z',
        xp: 30000,
        craftXp: 450,
        value: 130000,
        favor: 447,
        time: 7800,
        atk: 41,
        def: 27,
        hp: 8,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 18,
        w2BuildingReq: 14,
        w3BuildingReq: 8,
        resource1: 'wood',
        r1Qty: 155,
        resource2: 'ironwood',
        r2Qty: 39,
        resource3: null,
        r3Qty: 0,
        component1: 'flawed',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'starmetal',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 10,
        u2Req: 24,
        u3Req: 44,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-31',
        upgrade3: 'component2-1',
        upgrade4: 'bp:lion',
        upgrade5: 'quality+1',
        upgradeBonus: 1,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 30,
        su2Cost: 30,
        su3Cost: 30,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_hippo',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 255,
        surcharge: 570,
        suggest: 230,
        speedup: 675,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 20,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    shark: {
        uid: 'shark',
        level: 24,
        type: 'z',
        xp: 40000,
        craftXp: 600,
        value: 350000,
        favor: 771,
        time: 14400,
        atk: 48,
        def: 32,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'iron',
        r1Qty: 199,
        resource2: 'steel',
        r2Qty: 51,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'ectoplasm',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-10',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'time*0.9',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_shark',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 915,
        suggest: 365,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    walrus: {
        uid: 'walrus',
        level: 24,
        type: 'z',
        xp: 40000,
        craftXp: 600,
        value: 350000,
        favor: 771,
        time: 14400,
        atk: 48,
        def: 32,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'leather',
        r1Qty: 199,
        resource2: 'fabric',
        r2Qty: 51,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'ancientmarble',
        c2Qty: 5,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-10',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_walrus',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 915,
        suggest: 365,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    lion: {
        uid: 'lion',
        level: 24,
        type: 'z',
        xp: 40000,
        craftXp: 600,
        value: 350000,
        favor: 771,
        time: 14400,
        atk: 48,
        def: 32,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'wood',
        r1Qty: 199,
        resource2: 'ironwood',
        r2Qty: 51,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'sewingthread',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-10',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'time*0.9',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_lion',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 915,
        suggest: 365,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    bear: {
        uid: 'bear',
        level: 24,
        type: 'z',
        xp: 40000,
        craftXp: 600,
        value: 350000,
        favor: 771,
        time: 14400,
        atk: 48,
        def: 32,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'wood',
        r1Qty: 199,
        resource2: 'ironwood',
        r2Qty: 51,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'overgrownvine',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-10',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_bear',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 915,
        suggest: 365,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    mammoth: {
        uid: 'mammoth',
        level: 24,
        type: 'z',
        xp: 40000,
        craftXp: 600,
        value: 350000,
        favor: 771,
        time: 14400,
        atk: 48,
        def: 32,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'herbs',
        r1Qty: 199,
        resource2: 'oils',
        r2Qty: 51,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'timecrystal',
        c2Qty: 4,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-10',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_mammoth',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 915,
        suggest: 365,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    dinosaur: {
        uid: 'dinosaur',
        level: 24,
        type: 'z',
        xp: 40000,
        craftXp: 600,
        value: 350000,
        favor: 771,
        time: 14400,
        atk: 48,
        def: 32,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 11,
        resource1: 'herbs',
        r1Qty: 199,
        resource2: 'oils',
        r2Qty: 51,
        resource3: 'gems',
        r3Qty: 7,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'starmetal',
        c2Qty: 6,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-40',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-10',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'component2-1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_dinosaur',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 915,
        suggest: 365,
        speedup: 860,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    dragon: {
        uid: 'dragon',
        level: 25,
        type: 'z',
        xp: 44000,
        craftXp: 1320,
        value: 300000,
        favor: 896,
        time: 17400,
        atk: 50,
        def: 33,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: 'moondragon',
        worker2: null,
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'wood',
        r1Qty: 214,
        resource2: 'ironwood',
        r2Qty: 57,
        resource3: 'gems',
        r3Qty: 8,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'elvenwood',
        c2Qty: 1,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'multi+0.1',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_dragon',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 410,
        surcharge: 850,
        suggest: 365,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 50,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    tiger: {
        uid: 'tiger',
        level: 25,
        type: 'z',
        xp: 44000,
        craftXp: 660,
        value: 375000,
        favor: 1001,
        time: 17400,
        atk: 50,
        def: 33,
        hp: 10,
        eva: '0',
        crit: '0',
        excl: 'special',
        tier: 9,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 21,
        w2BuildingReq: 17,
        w3BuildingReq: 12,
        resource1: 'wood',
        r1Qty: 214,
        resource2: 'ironwood',
        r2Qty: 57,
        resource3: 'gems',
        r3Qty: 8,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'ectoplasm',
        c2Qty: 3,
        c2Tags: null,
        u1Req: 9,
        u2Req: 22,
        u3Req: 40,
        u4Req: 60,
        u5Req: 95,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-43',
        upgrade3: 'component2-1',
        upgrade4: 'resource2-11',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'time*0.9',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_tiger',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 425,
        surcharge: 945,
        suggest: 380,
        speedup: 925,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-01-25',
        shardPrice: 200,
    },
    opulentspirit: {
        uid: 'opulentspirit',
        level: 30,
        type: 'z',
        xp: 65000,
        craftXp: 975,
        value: 1000000,
        favor: 2128,
        time: 32400,
        atk: 63,
        def: 42,
        hp: 13,
        eva: '0',
        crit: '0',
        excl: 'chest',
        tier: 10,
        combo: 0,
        worker1: 'priest',
        worker2: 'wizard',
        worker3: null,
        w1BuildingReq: 25,
        w2BuildingReq: 21,
        w3BuildingReq: 18,
        resource1: 'iron',
        r1Qty: 290,
        resource2: 'steel',
        r2Qty: 82,
        resource3: 'gems',
        r3Qty: 22,
        component1: 'chiseled',
        c1Qty: 1,
        c1Tags: 'common',
        component2: 'tabletjewel',
        c2Qty: 2,
        c2Tags: null,
        u1Req: 10,
        u2Req: 26,
        u3Req: 46,
        u4Req: 70,
        u5Req: 110,
        upgrade1: 'time*0.75',
        upgrade2: 'resource1-58',
        upgrade3: 'resource2-12',
        upgrade4: 'resource2-12',
        upgrade5: 'quality+1',
        upgradeBonus: 2,
        supgrade1: 'resource3-4',
        supgrade2: 'multi+0.1',
        supgrade3: 'quality+1',
        su1Cost: 60,
        su2Cost: 60,
        su3Cost: 60,
        restrict: null,
        reqTags: null,
        tagIndex: 3,
        elements: null,
        skill: 'i_opulentspirit',
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 765,
        surcharge: 1705,
        suggest: 680,
        speedup: 2335,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: 'Stone',
        unlock: 0,
        chest: 'goldcity2',
        firstOfLine: false,
        prohibited: false,
        releaseAt: '2022-02-18',
        shardPrice: 0,
    },
    tabletfragment: {
        uid: 'tabletfragment',
        level: 25,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 20000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    tabletjewel: {
        uid: 'tabletjewel',
        level: 25,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 80000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    elvenwood: {
        uid: 'elvenwood',
        level: 1,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 100,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    redpinecone: {
        uid: 'redpinecone',
        level: 3,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 110,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    glowingmushrooms: {
        uid: 'glowingmushrooms',
        level: 8,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 120,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 1,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    arcanedust: {
        uid: 'arcanedust',
        level: 5,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 400,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    webbedwings: {
        uid: 'webbedwings',
        level: 9,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 450,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    preciousgem: {
        uid: 'preciousgem',
        level: 12,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 500,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 2,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    livingroots: {
        uid: 'livingroots',
        level: 11,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 900,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    darkscale: {
        uid: 'darkscale',
        level: 15,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 1000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    icypearl: {
        uid: 'icypearl',
        level: 18,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 1150,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 3,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    whitesand: {
        uid: 'whitesand',
        level: 13,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 2000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    sharpfang: {
        uid: 'sharpfang',
        level: 16,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 2250,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    mooncrystal: {
        uid: 'mooncrystal',
        level: 19,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 2500,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 4,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    evileye: {
        uid: 'evileye',
        level: 20,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 5000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    silkscarab: {
        uid: 'silkscarab',
        level: 22,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 5500,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    starmetal: {
        uid: 'starmetal',
        level: 24,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 6000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 5,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ancientmarble: {
        uid: 'ancientmarble',
        level: 21,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 8000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    overgrownvine: {
        uid: 'overgrownvine',
        level: 23,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 9000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    timecrystal: {
        uid: 'timecrystal',
        level: 25,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 10000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 6,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ectoplasm: {
        uid: 'ectoplasm',
        level: 24,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 12500,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    sewingthread: {
        uid: 'sewingthread',
        level: 26,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 15000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    ghostflag: {
        uid: 'ghostflag',
        level: 28,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 17500,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 7,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    deepcoral: {
        uid: 'deepcoral',
        level: 25,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 20000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    sirenshard: {
        uid: 'sirenshard',
        level: 27,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 22000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    goldshell: {
        uid: 'goldshell',
        level: 29,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 30000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 8,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    grimtalon: {
        uid: 'grimtalon',
        level: 30,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 45000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    crystalegg: {
        uid: 'crystalegg',
        level: 32,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 50000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    icybreeze: {
        uid: 'icybreeze',
        level: 34,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 60000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 9,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    mightsigil: {
        uid: 'mightsigil',
        level: 33,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 200000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    gracesigil: {
        uid: 'gracesigil',
        level: 33,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 200000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    sparksigil: {
        uid: 'sparksigil',
        level: 33,
        type: 'm',
        xp: 0,
        craftXp: 0,
        value: 200000,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 11,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 0,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    uncommon: {
        uid: 'uncommon',
        level: 1,
        type: 'z',
        xp: 0,
        craftXp: 0,
        value: 0,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 0,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 1,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    flawless: {
        uid: 'flawless',
        level: 2,
        type: 'z',
        xp: 0,
        craftXp: 0,
        value: 0,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 0,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 1,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    epic: {
        uid: 'epic',
        level: 3,
        type: 'z',
        xp: 0,
        craftXp: 0,
        value: 0,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 0,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 1,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
    legendary: {
        uid: 'legendary',
        level: 4,
        type: 'z',
        xp: 0,
        craftXp: 0,
        value: 0,
        favor: 0,
        time: 0,
        atk: 0,
        def: 0,
        hp: 0,
        eva: '0',
        crit: '0',
        excl: null,
        tier: 0,
        combo: 0,
        worker1: null,
        worker2: null,
        worker3: null,
        w1BuildingReq: 0,
        w2BuildingReq: 0,
        w3BuildingReq: 0,
        resource1: null,
        r1Qty: 0,
        resource2: null,
        r2Qty: 0,
        resource3: null,
        r3Qty: 0,
        component1: null,
        c1Qty: 0,
        c1Tags: null,
        component2: null,
        c2Qty: 0,
        c2Tags: null,
        u1Req: 0,
        u2Req: 0,
        u3Req: 0,
        u4Req: 0,
        u5Req: 0,
        upgrade1: null,
        upgrade2: null,
        upgrade3: null,
        upgrade4: null,
        upgrade5: null,
        upgradeBonus: 0,
        supgrade1: null,
        supgrade2: null,
        supgrade3: null,
        su1Cost: 0,
        su2Cost: 0,
        su3Cost: 0,
        restrict: null,
        reqTags: null,
        tagIndex: 1,
        elements: null,
        skill: null,
        lTag2: null,
        lTag3: null,
        elementAffinity: null,
        spiritAffinity: null,
        tag: null,
        discount: 0,
        surcharge: 0,
        suggest: 0,
        speedup: 0,
        buyAnimIdOverride: 0,
        questAnimIdOverride: 0,
        slotOverride: null,
        soundType: null,
        unlock: 0,
        chest: null,
        firstOfLine: false,
        prohibited: false,
        releaseAt: null,
        shardPrice: 0,
    },
};
