import { ItemTypes, RackTypes } from '@app/constants';
import React from 'react';
import { AppTypes, useAppSelector, useAppDispatch } from 'Contexts/app';
import { MultiSelect } from 'Components/inputs';
import { FilterWrapper } from '../filterWrapper';

const itemTypeListOptions = [
    { name: 'Sword', value: ItemTypes.SWORD },
    { name: 'Axe', value: ItemTypes.AXE },
    { name: 'Dagger', value: ItemTypes.DAGGER },
    { name: 'Mace', value: ItemTypes.MACE },
    { name: 'Spear', value: ItemTypes.POLEARM },
    { name: 'Bow', value: ItemTypes.BOW },
    { name: 'Wand', value: ItemTypes.WAND },
    { name: 'Staff', value: ItemTypes.STAFF },
    { name: 'Gun', value: ItemTypes.GUN },
    { name: 'Crossbow', value: ItemTypes.CROSSBOW },
    { name: 'Heavy armor', value: ItemTypes.ARMOR_HEAVY },
    { name: 'Light armor', value: ItemTypes.ARMOR_MEDIUM },
    { name: 'Clothes', value: ItemTypes.ARMOR_LIGHT },
    { name: 'Helmet', value: ItemTypes.HELMET_HEAVY },
    { name: 'Rogue hat', value: ItemTypes.HELMET_MEDIUM },
    { name: 'Magician hat', value: ItemTypes.HELMET_LIGHT },
    { name: 'Gauntlet', value: ItemTypes.GLOVES_HEAVY },
    { name: 'Gloves', value: ItemTypes.GLOVES_LIGHT },
    { name: 'Boots', value: ItemTypes.BOOTS_HEAVY },
    { name: 'Shoes', value: ItemTypes.BOOTS_LIGHT },
    { name: 'Medicine', value: ItemTypes.HERB },
    { name: 'Potion', value: ItemTypes.POTION },
    { name: 'Spell', value: ItemTypes.SCROLL },
    { name: 'Shield', value: ItemTypes.SHIELD },
    { name: 'Ring', value: ItemTypes.RING },
    { name: 'Amulet', value: ItemTypes.AMULET },
    { name: 'Cloak', value: ItemTypes.CLOAK },
    { name: 'Familiar', value: ItemTypes.FAMILIAR },
    { name: 'Runestone', value: ItemTypes.RUNESTONE },
    { name: 'Moonstone', value: ItemTypes.MOONSTONE },
    { name: 'Enchant', value: ItemTypes.ENCHANT },
    { name: 'Component', value: ItemTypes.COMPONENT },
];

const rackTypesListOptions = [
    { name: 'Mannequin', value: RackTypes.Mannequin.ID },
    { name: 'Shelf', value: RackTypes.Shelf.ID },
    { name: 'Table', value: RackTypes.Table.ID },
    { name: 'Vertical rack', value: RackTypes.Vertical.ID },
    { name: 'Enchants', value: RackTypes.Enchants.ID },
    { name: 'Stones', value: RackTypes.Stones.ID },
];

export const TypeFilter = () => {
    const itemTypes = useAppSelector(state => state.itemTypes);
    const rackTypes = useAppSelector(state => state.rackTypes);
    const dispatch = useAppDispatch();

    const handleItemTypesChange = (_, rawItemTypes) => {
        const itemTypes = rawItemTypes.map(itemType => itemType?.value ?? itemType);
        dispatch({
            type: AppTypes.SET_ITEM_TYPES,
            payload: { itemTypes },
        });
    };

    const handleRackTypesChange = (_, rawRackTypes) => {
        const rackTypes = rawRackTypes.map(rackType => rackType?.value ?? rackType);
        dispatch({
            type: AppTypes.SET_RACK_TYPES,
            payload: { rackTypes },
        });
    };

    return (
        <FilterWrapper id="type" title="Type">
            <MultiSelect
                id="item-types-select"
                name="item-types-select"
                label="Item type"
                value={itemTypes ?? []}
                onChange={handleItemTypesChange}
                options={itemTypeListOptions}
                sx={{ minWidth: 240 }}
                getOptionSelected={(option, value) => option.value === value}
                size="small"
            />

            <MultiSelect
                id="rack-types-select"
                name="rack-types-select"
                label="Rack type"
                value={rackTypes ?? []}
                onChange={handleRackTypesChange}
                options={rackTypesListOptions}
                sx={{ minWidth: 240 }}
                getOptionSelected={(option, value) => option.value === value}
                size="small"
            />
        </FilterWrapper>
    );
};
