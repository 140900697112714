import { Columns } from './table/columns';

export const Filters = {
    GENERAL: 'GENERAL',
    TYPE: 'TYPE', // TODO; implement filter
    MARKET_PRICE: 'MARKET_PRICE',
    DISCOUNT: 'DISCOUNT',
    SURCHARGE: 'SURCHARGE',
    KING_SALE: 'KING_SALE', // TODO; implement filter
    ENCHANTS: 'ENCHANTS',
};

const commonColumns = [
    Columns.IMAGE.id,
    Columns.NAME.id,
    Columns.RARITY.id,
    Columns.TIER.id,
    Columns.TYPE.id,
    Columns.RACK_TYPE.id,
];

export const Layouts = [
    {
        id: 'default-layout-general',
        name: 'General',
        filters: [
            Filters.GENERAL,
            Filters.TYPE,
            Filters.MARKET_PRICE,
            Filters.DISCOUNT,
            Filters.SURCHARGE,
            Filters.KING_SALE,
            Filters.ENCHANTS,
        ],
        defaultFilterState: {},
        columns: [
            ...Object.values(Columns)
                .map(column => column?.id)
                .filter(columnId => columnId !== Columns.UID),
        ],
    },
    {
        id: 'default-layout-enchants',
        name: 'Enchants',
        // prettier-ignore
        filters: [
            Filters.GENERAL,
            Filters.ENCHANTS,
        ],
        columns: [
            ...commonColumns,
            Columns.GOLD_PRICE.id,
            Columns.SURCHARGE_PROFIT.id,
            Columns.SURCHARGE.id,
            Columns.SURCHARGE_VALUE.id,
        ],
    },
    {
        id: 'default-layout-surcharge',
        name: 'Surcharge',
        // prettier-ignore
        filters: [
            Filters.GENERAL,
            Filters.TYPE,
            Filters.SURCHARGE,
            Filters.MARKET_PRICE,
        ],
        columns: [
            ...commonColumns,
            Columns.GOLD_PRICE.id,
            Columns.SURCHARGE_PROFIT.id,
            Columns.SURCHARGE_PROFIT_PER_ENERGY.id,
            Columns.SURCHARGE.id,
            Columns.SURCHARGE_VALUE.id,
            Columns.ELEMENT_AFFINITY.id,
            // Columns.SPIRIT_AFFINITY.id,
        ],
    },
    {
        id: 'default-layout-fodder',
        name: 'Fodder',
        // prettier-ignore
        filters: [
            Filters.GENERAL,
            Filters.TYPE,
            Filters.DISCOUNT,
            Filters.MARKET_PRICE,
        ],
        columns: [
            ...commonColumns,
            Columns.GOLD_PRICE.id,
            Columns.DISCOUNT_COST_PER_ENERGY.id,
            Columns.DISCOUNT.id,
            Columns.DISCOUNT_COST.id,
        ],
    },
    {
        id: 'default-layout-king',
        name: 'King sale',
        // prettier-ignore
        filters: [
            Filters.GENERAL,
            Filters.TYPE,
            Filters.KING_SALE,
        ],
        columns: [
            ...commonColumns,
            Columns.KINGS_SALE_PROFIT.id,
            Columns.GOLD_PRICE.id,
            Columns.GEMS_PRICE.id,
            Columns.ELEMENT_AFFINITY.id,
            // Columns.SPIRIT_AFFINITY.id,
        ],
    },
    {
        id: 'default-layout-flipping',
        name: 'Flipping',
        // prettier-ignore
        filters: [
            Filters.GENERAL,
            Filters.MARKET_PRICE,
        ],
        columns: [
            ...commonColumns,
            Columns.GEM_GOLD_RATIO.id,
            Columns.GEM_GOLD_RATIO_AVERAGE.id,
            Columns.GOLD_PRICE.id,
            Columns.GOLD_AVERAGE_PRICE.id,
            Columns.GOLD_QUANTITY.id,
            Columns.GEMS_PRICE.id,
            Columns.GEMS_AVERAGE_PRICE.id,
            Columns.GEMS_QUANTITY.id,
            // Columns.SPIRIT_AFFINITY.id,
        ],
    },
];

export default {
    Layouts,
    Filters,
};
