import React, { useState, useMemo } from 'react';
import MarketConstants from 'Views/dashboard/constants';
import { useAppSelector, AppTypes, useAppDispatch } from 'Contexts/app';
import { useIsMobile } from 'App/theme';
import { Box, Button, Paper } from '@mui/material';
import { SwipeableEdgeDrawer } from 'Components/swipeableEdgeDrawer';
import { FiltersHeader } from './filtersHeader';
import { GeneralFilter } from './types/generalFilter';
import { TypeFilter } from './types/typeFilter';
import { MarketPriceFilter } from './types/marketPriceFilter';
import { SurchargeFilter } from './types/surchargeFilter';
import { DiscountFilter } from './types/discountFilter';
import { EnchantFilter } from './types/enchantFilter';

export const Filter = ({ filterId }) => {
    if (filterId === MarketConstants.Filters.GENERAL) {
        return <GeneralFilter />;
    }

    if (filterId === MarketConstants.Filters.TYPE) {
        return <TypeFilter />;
    }

    if (filterId === MarketConstants.Filters.MARKET_PRICE) {
        return <MarketPriceFilter />;
    }

    if (filterId === MarketConstants.Filters.SURCHARGE) {
        return <SurchargeFilter />;
    }

    if (filterId === MarketConstants.Filters.DISCOUNT) {
        return <DiscountFilter />;
    }

    if (filterId === MarketConstants.Filters.ENCHANTS) {
        return <EnchantFilter />;
    }

    return null;
};

export const Filters = ({ contentRef }) => {
    const activeLayoutId = useAppSelector(state => state.activeLayoutId);
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const filterIds = useMemo(() => {
        const layout = MarketConstants.Layouts.find(({ id }) => {
            return id === activeLayoutId;
        });

        return layout?.filters ?? [];
    }, [activeLayoutId]);

    const handleToggleDrawerOpen = open => {
        setDrawerOpen(current => open ?? !current);
    };

    const handleResetFilters = () => {
        dispatch({ type: AppTypes.RESET_FILTERS });
    };

    if (isMobile) {
        return (
            <SwipeableEdgeDrawer
                title="Filters"
                open={drawerOpen}
                onToggle={handleToggleDrawerOpen}
                container={contentRef}
                headerContent={
                    <Button
                        variant="text"
                        color="neutral"
                        onClick={handleResetFilters}
                        aria-label="reset filters"
                    >
                        Reset
                    </Button>
                }
            >
                {filterIds.map(filterId => (
                    <Filter key={filterId} filterId={filterId} />
                ))}
            </SwipeableEdgeDrawer>
        );
    }

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                overflow: 'hidden',
                gridArea: 'filters',
            }}
        >
            <FiltersHeader />

            <Paper
                variant="outlined"
                sx={{
                    p: 2,
                    height: '100%',
                    width: '100%',
                    boxSizing: 'border-box',
                    overflow: 'auto',
                }}
            >
                {filterIds.map(filterId => (
                    <Filter key={filterId} filterId={filterId} />
                ))}
            </Paper>
        </Box>
    );
};
