import React from 'react';
import { Box, Typography } from '@mui/material';

export const FilterWrapper = ({ title, children }) => (
    <Box
        sx={{
            marginBottom: 2,
        }}
    >
        <Typography
            sx={{
                margin: 0,
                backgroundColor: '#fff',
                fontSize: '14px',
                color: '#555',
                fontWeight: 600,
            }}
        >
            {title}
        </Typography>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
                paddingTop: 2,
                paddingBottom: 2,
                '& > *': {
                    width: '100%',
                    marginBottom: 2,
                    '&:last-child': {
                        marginBottom: 0,
                    },
                },
            }}
        >
            {children}
        </Box>
    </Box>
);
