import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { AppProvider } from 'Contexts/app/provider';
import { ApiProvider } from 'Contexts/api/provider';
import { ThemeProvider } from '@mui/material';
import { Dashboard } from 'Views/dashboard';
import { NotFound } from 'Views/notFound';
// import { SocketInitializer } from './socketInitializer';
import theme from './theme';

const App = () => {
    // socket?.emit('get-market-data', response => {
    //     console.log('response =>', response);
    // });

    return (
        <SnackbarProvider maxSnack={3} preventDuplicate>
            <ApiProvider>
                <AppProvider>
                    <ThemeProvider theme={theme}>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </BrowserRouter>
                        {/* <SocketInitializer /> */}
                    </ThemeProvider>
                </AppProvider>
            </ApiProvider>
        </SnackbarProvider>
    );
};

export default App;
