import React from 'react';
import { AppTypes, useAppSelector, useAppDispatch } from 'Contexts/app';
import { NumberWithToggle } from 'Components/inputs';
import { CurrencyTitle } from 'Components/currencyTitle';
import { styled } from '@mui/material';
import { FilterWrapper } from '../filterWrapper';

export const SurchargeFilter = () => {
    const minSurchargeProfit = useAppSelector(state => state.minSurchargeProfit);
    const useMinSurchargeProfit = useAppSelector(state => state.useMinSurchargeProfit);
    const maxSurchargeCost = useAppSelector(state => state.maxSurchargeCost);
    const useMaxSurchargeCost = useAppSelector(state => state.useMaxSurchargeCost);
    const minSurchargeProfitPerEnergy = useAppSelector(
        state => state.minSurchargeProfitPerEnergy
    );
    const useMinSurchargeProfitPerEnergy = useAppSelector(
        state => state.useMinSurchargeProfitPerEnergy
    );
    const dispatch = useAppDispatch();

    const handleMinSurchargeProfitChange = ({ on, value }) => {
        dispatch({
            type: AppTypes.SET_MIN_SURCHARGE_PROFIT,
            payload: {
                minSurchargeProfit: value ?? 0,
                useMinSurchargeProfit: on ?? false,
            },
        });
    };

    const handleMaxSurchargeCostChange = ({ on, value }) => {
        dispatch({
            type: AppTypes.SET_MAX_SURCHARGE_COST,
            payload: {
                maxSurchargeCost: value ?? 0,
                useMaxSurchargeCost: on ?? false,
            },
        });
    };

    const handleMinSurchargeProfitPerEnergy = ({ on, value }) => {
        dispatch({
            type: AppTypes.SET_MIN_SURCHARGE_PROFIT_PER_ENERGY,
            payload: {
                minSurchargeProfitPerEnergy: value,
                useMinSurchargeProfitPerEnergy: on,
            },
        });
    };

    return (
        <StyledFilterWrapper id="surcharge" title="Surcharge">
            <NumberWithToggle
                on={useMinSurchargeProfit}
                value={minSurchargeProfit}
                onChange={handleMinSurchargeProfitChange}
                label={
                    <CurrencyTitle
                        type="gold"
                        title="Minimum surcharge profit"
                        disableTitleStyles
                    />
                }
            />

            <NumberWithToggle
                on={useMaxSurchargeCost}
                value={maxSurchargeCost}
                onChange={handleMaxSurchargeCostChange}
                label={
                    <CurrencyTitle
                        type="energy"
                        title="Maximum surcharge cost"
                        disableTitleStyles
                    />
                }
            />

            <NumberWithToggle
                on={useMinSurchargeProfitPerEnergy}
                value={minSurchargeProfitPerEnergy}
                onChange={handleMinSurchargeProfitPerEnergy}
                label={
                    <CurrencyTitle
                        type="gold-per-energy"
                        title="Minimum surcharge profit per energy"
                        disableTitleStyles
                    />
                }
            />
        </StyledFilterWrapper>
    );
};

const StyledFilterWrapper = styled(FilterWrapper)(() => ({
    '& .MuiAccordionDetails-root > .MuiBox-root': {
        paddingLeft: 0,
        // paddingTop: 0,
    },
}));
