import React from 'react';
import { Slider as MuiSlider, styled } from '@mui/material';
import numeral from 'numeral';

const formatNumber = value => {
    return numeral(value).format('0,0');
};

export const Slider = ({ value, onChange, min, max }) => {
    return (
        <Root>
            <div className="slider-wrapper">
                <MuiSlider
                    value={value}
                    onChange={onChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={formatNumber}
                    min={min}
                    max={max}
                    // size="small"
                />
            </div>

            {Array.isArray(value) ? (
                <div className="slider-labels">
                    <p>{formatNumber(value[0])}</p>
                    <p>{formatNumber(value[1])}</p>
                </div>
            ) : null}
        </Root>
    );
};

const Root = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',

    '& .slider-wrapper': {
        paddingLeft: 8,
        paddingRight: 8,
        width: '100%',
        boxSizing: 'border-box',
    },

    '& .slider-labels': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        '& > p': {
            margin: 0,
        },
    },
}));
