import React, { useReducer } from 'react';
import { ApiContext } from './context';
import { reducer, initialState } from './reducer';

export const ApiProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ApiContext.Provider value={{ state, dispatch }}>
            {children}
        </ApiContext.Provider>
    );
};
