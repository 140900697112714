import React from 'react';
import { AppTypes, useAppSelector, useAppDispatch } from 'Contexts/app';
import { MultiSelect } from 'Components/inputs';
import { FilterWrapper } from '../filterWrapper';

const tierListOptions = [
    { name: '1', value: 1 },
    { name: '2', value: 2 },
    { name: '3', value: 3 },
    { name: '4', value: 4 },
    { name: '5', value: 5 },
    { name: '6', value: 6 },
    { name: '7', value: 7 },
    { name: '8', value: 8 },
    { name: '9', value: 9 },
    { name: '10', value: 10 },
    { name: '11', value: 11 },
    { name: '12', value: 12 },
];

const rarityListOptions = [
    { name: 'Common', value: 'common' },
    { name: 'Uncommon', value: 'uncommon' },
    { name: 'Rare', value: 'rare' },
    { name: 'Epic', value: 'epic' },
    { name: 'Legendary', value: 'legendary' },
];

export const GeneralFilter = () => {
    const tiers = useAppSelector(state => state.tiers);
    const rarities = useAppSelector(state => state.rarities);
    const dispatch = useAppDispatch();

    const handleTiersChange = (_, rawTiers) => {
        const tiers = rawTiers.map(tier => tier?.value ?? tier);
        dispatch({
            type: AppTypes.SET_TIERS,
            payload: { tiers },
        });
    };

    const handleRaritiesChange = (_, rawRarities) => {
        const rarities = rawRarities.map(rarity => rarity?.value ?? rarity);
        dispatch({
            type: AppTypes.SET_RARITIES,
            payload: { rarities },
        });
    };

    return (
        <FilterWrapper id="general" title="General">
            <MultiSelect
                id="tiers-select"
                name="tiers-select"
                label="Tiers"
                value={tiers ?? []}
                onChange={handleTiersChange}
                options={tierListOptions}
                sx={{ minWidth: 240 }}
                getOptionSelected={(option, value) => option.value === value}
                size="small"
            />

            <MultiSelect
                id="rarities-select"
                name="rarities-select"
                label="Rarities"
                value={rarities ?? []}
                onChange={handleRaritiesChange}
                options={rarityListOptions}
                sx={{ minWidth: 240 }}
                getOptionSelected={(option, value) => option.value === value}
                size="small"
            />
        </FilterWrapper>
    );
};
