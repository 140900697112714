import React from 'react';
import { Layouts } from 'Views/dashboard/constants';
import { AppTypes, useAppSelector, useAppDispatch } from 'Contexts/app';
import { Box, MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MediaQuery } from 'Components/mediaQuery';
import { Select } from 'Components/inputs';
import { LastUpdated } from './lastUpdated';

export const Controls = () => {
    const activeLayoutId = useAppSelector(state => state.activeLayoutId);
    const dispatch = useAppDispatch();

    const handleActiveLayoutChange = activeLayoutId => {
        dispatch({
            type: AppTypes.SET_ACTIVE_LAYOUT,
            payload: { activeLayoutId },
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginBottom: '12px',
                marginTop: '12px',
                columnGap: 2,
            }}
        >
            <MediaQuery size="md">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <ToggleButtonGroup
                        color="primary"
                        onChange={(_, value) => handleActiveLayoutChange(value)}
                        value={activeLayoutId}
                        size="small"
                        exclusive
                        sx={{
                            '& .MuiToggleButton-root': {
                                textTransform: 'none',
                                padding: '5px 16px',
                                fontSize: '14px',
                            },
                        }}
                    >
                        {Layouts.map(layout => (
                            <ToggleButton key={layout.id} value={layout.id}>
                                {layout.name}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>

                    <LastUpdated />
                </Box>
            </MediaQuery>

            <MediaQuery not size="md">
                <Select
                    label="Layout"
                    value={activeLayoutId}
                    onChange={event => handleActiveLayoutChange(event.target.value)}
                    wrapperSx={{ width: '100%' }}
                >
                    {Layouts.map(layout => (
                        <MenuItem key={layout.id} value={layout.id}>
                            {layout.name}
                        </MenuItem>
                    ))}
                </Select>
            </MediaQuery>
        </Box>
    );
};
