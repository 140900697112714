import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import NoImage from 'Assets/images/nophoto.png';

export const ItemImage = ({ uid, ...other }) => {
    const [src, setSrc] = useState(NoImage);

    useEffect(() => {
        let mounted = true;

        (async () => {
            try {
                // eslint-disable-next-line import/no-dynamic-require,global-require
                const image = require(`Assets/game/items/${uid}.png`);
                if (mounted) {
                    setSrc(image);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(`missing image asset for ${uid}`);
            }
        })();

        return () => {
            mounted = false;
        };
    }, [uid]);

    return <Image src={src} alt="temp" {...other} />;
};

const Image = styled('img')(() => ({
    '&': {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));
