import React, { useEffect, useState } from 'react';
import { AppTypes, useAppDispatch, useAppSelector } from 'Contexts/app';
import { Spinner } from 'Components/spinner';
import { Box, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Controls } from './controls';
import { useLayout } from './useLayout';

const initialTableState = {
    columns: {
        // hide columns by default
        columnVisibilityModel: {
            uid: false,
        },
    },
};

// removes third-click unsorting column
const sortingOrder = ['asc', 'desc'];

export const Table = () => {
    const items = useAppSelector(state => state.items);
    const totalCount = useAppSelector(state => state.totalCount);
    const loading = useAppSelector(state => state.loading);
    const sortModel = useAppSelector(state => state.sortModel);
    const pageSize = useAppSelector(state => state.pageSize);
    const pageIndex = useAppSelector(state => state.pageIndex);
    const dispatch = useAppDispatch();
    const layout = useLayout();

    const handlePageSizeChange = pageSize => {
        dispatch({
            type: AppTypes.SET_PAGE_SIZE,
            payload: { pageSize },
        });
    };

    const handlePageIndexChange = pageIndex => {
        dispatch({
            type: AppTypes.SET_PAGE_INDEX,
            payload: { pageIndex },
        });
    };

    const handleSortModelChange = sortModel => {
        dispatch({
            type: AppTypes.SET_SORT_MODEL,
            payload: { sortModel },
        });
    };

    return (
        <Box
            sx={{
                flex: 1,
                width: '100%',
                maxWidth: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'normal',
                gridArea: 'table',
            }}
        >
            <div style={{ position: 'absolute', inset: 0 }}>
                <Spinner on={loading || layout.loading} />
            </div>

            <Controls />

            <StyledDataGrid
                rows={items ?? []}
                columns={layout.columns}
                pageSize={pageSize}
                page={pageIndex}
                rowCount={totalCount}
                initialState={initialTableState}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePageIndexChange}
                rowsPerPageOptions={[5, 25, 50, 100]}
                sortingMode="server"
                paginationMode="server"
                sortingOrder={sortingOrder}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                disableColumnFilter
                disableColumnMenu
                disableVirtualization
                height="100%"
            />
        </Box>
    );
};

const StyledDataGrid = styled(DataGrid)(() => ({
    // height: 'fit-content',
    // maxHeight: '100%',
}));
