const ItemTypes = require('../itemTypes');
const RackTypes = require('../rackTypes');

module.exports = {
    ItemTypes: {
        [ItemTypes.SWORD]: 'Sword',
        [ItemTypes.AXE]: 'Axe',
        [ItemTypes.DAGGER]: 'Dagger',
        [ItemTypes.MACE]: 'Mace',
        [ItemTypes.POLEARM]: 'Spear',
        [ItemTypes.BOW]: 'Bow',
        [ItemTypes.WAND]: 'Wand',
        [ItemTypes.STAFF]: 'Staff',
        [ItemTypes.GUN]: 'Gun',
        [ItemTypes.CROSSBOW]: 'Crossbow',
        [ItemTypes.ARMOR_HEAVY]: 'Heavy armor',
        [ItemTypes.ARMOR_MEDIUM]: 'Light armor',
        [ItemTypes.ARMOR_LIGHT]: 'Clothes',
        [ItemTypes.HELMET_HEAVY]: 'Helmet',
        [ItemTypes.HELMET_MEDIUM]: 'Rogue hat',
        [ItemTypes.HELMET_LIGHT]: 'Magician hat',
        [ItemTypes.GLOVES_HEAVY]: 'Gauntlet',
        [ItemTypes.GLOVES_LIGHT]: 'Gloves',
        [ItemTypes.BOOTS_HEAVY]: 'Boots',
        [ItemTypes.BOOTS_LIGHT]: 'Shoes',
        [ItemTypes.HERB]: 'Medicine',
        [ItemTypes.POTION]: 'Potion',
        [ItemTypes.SCROLL]: 'Spell',
        [ItemTypes.SHIELD]: 'Shield',
        [ItemTypes.RING]: 'Ring',
        [ItemTypes.AMULET]: 'Amulet',
        [ItemTypes.CLOAK]: 'Cloak',
        [ItemTypes.FAMILIAR]: 'Familiar',
        [ItemTypes.RUNESTONE]: 'Runestone',
        [ItemTypes.MOONSTONE]: 'Moonstone',
        [ItemTypes.ENCHANT]: 'Enchant',
        [ItemTypes.COMPONENT]: 'Component',
    },
    RackTypes: {
        [RackTypes.Mannequin.ID]: 'Mannequin',
        [RackTypes.Shelf.ID]: 'Shelf',
        [RackTypes.Table.ID]: 'Table',
        [RackTypes.Vertical.ID]: 'Vertical rack',
        [RackTypes.Enchants.ID]: 'Enchants',
        [RackTypes.Stones.ID]: 'Stones',
    },
};
