const ItemTypes = require('./itemTypes');

const RackTypes = {
    Shelf: {
        ID: 'SHELF',
        ITEMS_HELD: [
            ItemTypes.AMULET,
            ItemTypes.RING,
            ItemTypes.POTION,
            ItemTypes.SCROLL,
            ItemTypes.HERB,
            ItemTypes.FAMILIAR,
        ],
    },
    Vertical: {
        ID: 'VERTICAL',
        ITEMS_HELD: [
            ItemTypes.SWORD,
            ItemTypes.MACE,
            ItemTypes.AXE,
            ItemTypes.POLEARM,
            ItemTypes.STAFF,
        ],
    },
    Table: {
        ID: 'TABLE',
        ITEMS_HELD: [
            ItemTypes.DAGGER,
            ItemTypes.WAND,
            ItemTypes.BOW,
            ItemTypes.CROSSBOW,
            ItemTypes.SHIELD,
            ItemTypes.GUN,
        ],
    },
    Mannequin: {
        ID: 'MANNEQUIN',
        ITEMS_HELD: [
            ItemTypes.CLOAK,
            ItemTypes.HELMET_HEAVY,
            ItemTypes.HELMET_MEDIUM,
            ItemTypes.HELMET_LIGHT,
            ItemTypes.ARMOR_HEAVY,
            ItemTypes.ARMOR_MEDIUM,
            ItemTypes.ARMOR_LIGHT,
            ItemTypes.GLOVES_HEAVY,
            ItemTypes.GLOVES_MEDIUM,
            ItemTypes.GLOVES_LIGHT,
            ItemTypes.BOOTS_HEAVY,
            ItemTypes.BOOTS_MEDIUM,
            ItemTypes.BOOTS_LIGHT,
        ],
    },
    Enchants: {
        ID: 'ENCHANTS',
        ITEMS_HELD: [
            ItemTypes.ENCHANT,
        ],
    },
    Stones: {
        ID: 'STONES',
        ITEMS_HELD: [
            ItemTypes.RUNESTONE,
            ItemTypes.MOONSTONE,
        ],
    },
};

module.exports = RackTypes;
