import { Checkbox, styled } from '@mui/material';
import React, { useMemo } from 'react';
import {
    CheckBox as CheckBoxIcon,
    IndeterminateCheckBox as IndeterminateCheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material';
import propTypes from 'prop-types';

const Props = {
    name: propTypes.string.isRequired,
    options: propTypes.array.isRequired,
    value: propTypes.array.isRequired,
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const indeterminateIcon = <IndeterminateCheckBoxIcon fontSize="small" />;

const SelectAllOption = ({ options, value, name }) => {
    const filteredValue = useMemo(() => {
        if (!value?.length) {
            return [];
        }

        return value.filter(value => value !== 'select-all');
    }, [value]);

    return (
        <>
            <StyledCheckbox
                icon={icon}
                checkedIcon={checkedIcon}
                indeterminateIcon={indeterminateIcon}
                checked={options.length === filteredValue.length}
                indeterminate={
                    filteredValue.length && options.length !== filteredValue.length
                }
            />

            <span>{name}</span>
        </>
    );
};

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    '&': {
        marginRight: theme.spacing(2),
    },
}));

SelectAllOption.propTypes = Props;
export default SelectAllOption;
