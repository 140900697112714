import React, {
    createContext,
    useContext,
    forwardRef,
    cloneElement,
    useRef,
    useEffect,
} from 'react';
import { ListSubheader } from '@mui/material';
import { VariableSizeList } from 'react-window';

/* All of this jazz basically for virtualization */
// basically copypasta from @mui autocomplete docs
const LISTBOX_PADDING = 8;
const itemSize = 50;

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = data => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current !== null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);

    return ref;
};

const renderRow = ({ data, index, style }) => {
    return cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
};

const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
    }

    return itemSize;
};

// eslint-disable-next-line react/prop-types
const ListBoxComponent = forwardRef(({ children, ...rest }, ref) => {
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={rest}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={index => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

export default ListBoxComponent;
