import React, { useMemo } from 'react';
import Constants from '@app/constants';
import { capitalize } from '@app/utils';
import { Chip, ThemeProvider, createTheme } from '@mui/material';

const getColor = rarity => {
    if (!rarity) {
        return '#fff';
    }

    const uppercase = `${rarity}`.toUpperCase();
    const value = Constants.Colors.Rarities[uppercase];

    if (value) {
        return value;
    }

    return '#fff';
};

export const ItemRarity = ({ rarity = 'common', size = 'medium' }) => {
    const theme = useMemo(() => {
        const color = getColor(rarity);
        const palette = { primary: { main: color } };
        const theme = createTheme({ palette });
        return theme;
    }, [rarity]);

    const label = useMemo(() => {
        return capitalize(rarity);
    }, [rarity]);

    return (
        <ThemeProvider theme={theme}>
            <Chip label={label} color="primary" variant="filled" size={size} />
        </ThemeProvider>
    );
};
