import { useEffect, useRef, useState } from 'react';
import { AppTypes, useAppDispatch, useAppSelector } from 'Contexts/app';
import { useNotifications } from 'notifications';
import { isEqual } from 'lodash';
import { generateId } from '@app/utils';
import { searchMarket } from 'Api/searchMarket';

const defaultSort = { field: 'goldPrice', sort: 'desc' };

const filterSelectAll = value => {
    if (!value?.length) {
        return [];
    }

    return value.filter(value => value !== 'select-all');
};

const useMakePayload = () => {
    const pageIndex = useAppSelector(state => state.pageIndex);
    const pageSize = useAppSelector(state => state.pageSize);
    const { field: sortBy, sort: sortOrder } = useAppSelector(state => {
        return state.sortModel[0] ?? defaultSort;
    });

    const tiers = useAppSelector(state => state.tiers);
    const rarities = useAppSelector(state => state.rarities);

    const itemTypes = useAppSelector(state => state.itemTypes);
    const rackTypes = useAppSelector(state => state.rackTypes);

    const goldCostRange = useAppSelector(state => state.goldCostRange);
    const useGoldCostRange = useAppSelector(state => state.useGoldCostRange);
    const gemCostRange = useAppSelector(state => state.gemCostRange);
    const useGemCostRange = useAppSelector(state => state.useGemCostRange);
    const gemGoldRatioRange = useAppSelector(state => state.gemGoldRatioRange);
    const useGemGoldRatioRange = useAppSelector(state => state.useGemGoldRatioRange);

    const minSurchargeProfit = useAppSelector(state => state.minSurchargeProfit);
    const useMinSurchargeProfit = useAppSelector(state => state.useMinSurchargeProfit);
    const maxSurchargeCost = useAppSelector(state => state.maxSurchargeCost);
    const useMaxSurchargeCost = useAppSelector(state => state.useMaxSurchargeCost);
    const minSurchargeProfitPerEnergy = useAppSelector(
        state => state.minSurchargeProfitPerEnergy
    );
    const useMinSurchargeProfitPerEnergy = useAppSelector(
        state => state.useMinSurchargeProfitPerEnergy
    );

    const minDiscountEnergy = useAppSelector(state => state.minDiscountEnergy);
    const useMinDiscountEnergy = useAppSelector(state => state.useMinDiscountEnergy);
    const maxDiscountCostPerEnergy = useAppSelector(
        state => state.maxDiscountCostPerEnergy
    );
    const useMaxDiscountCostPerEnergy = useAppSelector(
        state => state.useMaxDiscountCostPerEnergy
    );

    const elementAffinities = useAppSelector(state => state.elementAffinities);
    const spiritAffinities = useAppSelector(state => state.spiritAffinities);

    const [payload, setPayload] = useState({});

    useEffect(() => {
        let isMounted = true;

        const timeout = setTimeout(() => {
            let next = {
                pageIndex,
                pageSize,
                sortBy: sortBy ?? 'goldPrice',
                sortOrder: sortOrder ?? 'desc',
                tiers: filterSelectAll(tiers),
                rarities: filterSelectAll(rarities),
                itemTypes: filterSelectAll(itemTypes),
                rackTypes: filterSelectAll(rackTypes),
                elementAffinities: filterSelectAll(elementAffinities),
                spiritAffinities: filterSelectAll(spiritAffinities),
            };

            if (useGoldCostRange) {
                const [minGoldCost, maxGoldCost] = goldCostRange;
                next.minGoldCost = minGoldCost;
                next.maxGoldCost = maxGoldCost;
            }

            if (useGemCostRange) {
                const [minGemCost, maxGemCost] = gemCostRange;
                next.minGemCost = minGemCost;
                next.maxGemCost = maxGemCost;
            }

            if (useGemGoldRatioRange) {
                const [minGemGoldRatio, maxGemGoldRatio] = gemGoldRatioRange;
                next.minGemGoldRatio = minGemGoldRatio;
                next.maxGemGoldRatio = maxGemGoldRatio;
            }

            if (useMinSurchargeProfit) {
                next.minSurchargeProfit = minSurchargeProfit;
            }

            if (useMaxSurchargeCost) {
                next.maxSurchargeCost = maxSurchargeCost;
            }

            if (useMinSurchargeProfitPerEnergy) {
                next.minSurchargeProfitPerEnergy = minSurchargeProfitPerEnergy;
            }

            if (useMinDiscountEnergy) {
                next.minDiscountEnergy = minDiscountEnergy;
            }

            if (useMaxDiscountCostPerEnergy) {
                next.maxDiscountCostPerEnergy = maxDiscountCostPerEnergy;
            }

            if (!isEqual(payload, next) && isMounted) {
                setPayload(next);
            }
        }, 500);

        return () => {
            isMounted = false;
            clearTimeout(timeout);
        };
    }, [
        // Do NOT add `payload` to useEffect
        // but keep warning in case of other missed props
        pageIndex,
        pageSize,
        sortBy,
        sortOrder,

        tiers,
        rarities,

        itemTypes,
        rackTypes,

        goldCostRange,
        useGoldCostRange,
        gemCostRange,
        useGemCostRange,
        gemGoldRatioRange,
        useGemGoldRatioRange,

        minSurchargeProfit,
        useMinSurchargeProfit,
        maxSurchargeCost,
        useMaxSurchargeCost,
        minSurchargeProfitPerEnergy,
        useMinSurchargeProfitPerEnergy,

        minDiscountEnergy,
        useMinDiscountEnergy,
        maxDiscountCostPerEnergy,
        useMaxDiscountCostPerEnergy,

        elementAffinities,
        spiritAffinities,
    ]);

    return payload;
};

export const MarketDataFetcher = () => {
    const dispatch = useAppDispatch();
    const notify = useNotifications();

    const payload = useMakePayload();
    const activeRequestIdRef = useRef(null);

    useEffect(() => {
        if (!dispatch || !notify) {
            return () => {};
        }

        const requestId = generateId();
        activeRequestIdRef.current = requestId;

        let isMounted = true;

        dispatch({
            type: AppTypes.SET_LOADING,
            payload: { loading: true },
        });

        (async () => {
            console.log('searching...');
            const { data, error } = await searchMarket(payload);
            console.log('got response!');

            if (error) {
                console.error(error);
                notify({
                    variant: 'error',
                    message: 'Uh oh! We hit an error while processing your request',
                });

                return;
            }

            if (!isMounted || activeRequestIdRef.current !== requestId) {
                return;
            }

            const { items, totalCount, lastUpdated } = data;

            dispatch({
                type: AppTypes.SET_LAST_UPDATED,
                payload: { lastUpdated },
            });

            dispatch({
                type: AppTypes.SET_ITEMS,
                payload: { items, totalCount },
            });

            dispatch({
                type: AppTypes.SET_LOADING,
                payload: { loading: false },
            });
        })();

        return () => {
            isMounted = false;
        };
    }, [dispatch, notify, payload]);

    return null;
};
