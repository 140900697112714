import React, { useMemo } from 'react';
import Constants from '@app/constants';
import { ItemImage, ItemRarity } from 'Components/item';
import { CurrencyValue } from 'Components/currencyValue';
import { Enchant } from 'Components/enchant';
import numeral from 'numeral';

export const Columns = {
    IMAGE: {
        id: 'IMAGE',
        field: 'image',
        headerName: '',
        sortable: false,
        width: 62,
        renderCell: params => {
            return <ItemImage uid={params.row.uid} />;
        },
    },
    UID: {
        id: 'UID',
        field: 'uid',
        headerName: 'ID',
        width: 120,
    },
    NAME: {
        id: 'NAME',
        field: 'name',
        headerName: 'Name',
        width: 160,
    },
    TYPE: {
        id: 'TYPE',
        field: 'type',
        headerName: 'Item type',
        width: 100,
        renderCell: params => Constants.Translation.ItemTypes[params.row.type],
    },
    RACK_TYPE: {
        id: 'RACK_TYPE',
        field: 'rackType',
        headerName: 'Rack type',
        width: 100,
        renderCell: params => Constants.Translation.RackTypes[params.row.rackType],
    },
    TIER: {
        id: 'TIER',
        field: 'tier',
        headerName: 'Tier',
        type: 'number',
        width: 60,
        valueGetter: params => params.row.tier,
    },
    RARITY: {
        id: 'RARITY',
        field: 'rarity',
        headerName: 'Rarity',
        width: 120,
        renderCell: params => <ItemRarity rarity={params.row.rarity} />,
        valueGetter: params => params.row.rarity,
    },
    GOLD_PRICE: {
        id: 'GOLD_PRICE',
        field: 'goldPrice',
        headerName: 'Market gold price',
        type: 'number',
        width: 150,
        renderCell: params => <CurrencyValue type="gold" amount={params.row.goldPrice} />,
        valueGetter: params => params.row.goldPrice,
    },
    GOLD_QUANTITY: {
        id: 'GOLD_QUANTITY',
        field: 'goldQuantity',
        headerName: 'Gold qty',
        type: 'number',
        width: 150,
        valueGetter: params => params.row.goldQuantity,
    },
    GOLD_AVERAGE_PRICE: {
        id: 'GOLD_AVERAGE_PRICE',
        headerName: 'Gold 30d averages: Low / Avg / High',
        type: 'number',
        width: 300,
        valueGetter: params => {
            const { longTermRecords } = params.row.history ?? {};

            if (!longTermRecords?.length) {
                return '- / - / -';
            }

            const data = longTermRecords.reduce(
                (data, { goldHigh, goldLow, goldTotalPrice, goldTotalQuantity }) => {
                    if (goldHigh) {
                        data.highTotal += goldHigh;
                    }

                    if (goldLow) {
                        data.lowTotal += goldLow;
                    }

                    if (goldTotalPrice && goldTotalQuantity) {
                        data.totalPrice += goldTotalPrice;
                        data.totalQuantity += goldTotalQuantity;
                    }

                    return data;
                },
                {
                    highTotal: null,
                    lowTotal: null,
                    totalPrice: 0,
                    totalQuantity: 0,
                }
            );

            const makeNumber = num => {
                if (!num) {
                    return '-';
                }

                return numeral(num).format('0,0');
            };

            const low = makeNumber(data.lowTotal / longTermRecords.length);
            const avg = makeNumber(data.totalPrice / data.totalQuantity);
            const high = makeNumber(data.highTotal / longTermRecords.length);

            return `${low} / ${avg} / ${high}`;
        },
    },
    GEMS_PRICE: {
        id: 'GEMS_PRICE',
        field: 'gemsPrice',
        headerName: 'Market gems price',
        type: 'number',
        width: 150,
        renderCell: params => <CurrencyValue type="gem" amount={params.row.gemsPrice} />,
        valueGetter: params => params.row.gemsPrice,
    },
    GEMS_QUANTITY: {
        id: 'GEMS_QUANTITY',
        field: 'gemsQuantity',
        headerName: 'Gems qty',
        type: 'number',
        width: 150,
        valueGetter: params => params.row.gemsQuantity,
    },
    GEMS_AVERAGE_PRICE: {
        id: 'GEMS_AVERAGE_PRICE',
        headerName: 'Gem 30d averages: Low / Avg / High',
        type: 'number',
        width: 300,
        valueGetter: params => {
            const { longTermRecords } = params.row.history ?? {};

            if (!longTermRecords?.length) {
                return '- / - / -';
            }

            const data = longTermRecords.reduce(
                (data, { gemHigh, gemLow, gemTotalPrice, gemTotalQuantity }) => {
                    if (gemHigh) {
                        data.highTotal += gemHigh;
                    }

                    if (gemLow) {
                        data.lowTotal += gemLow;
                    }

                    if (gemTotalPrice && gemTotalQuantity) {
                        data.totalPrice += gemTotalPrice;
                        data.totalQuantity += gemTotalQuantity;
                    }

                    return data;
                },
                {
                    highTotal: null,
                    lowTotal: null,
                    totalQuantity: 0,
                    totalPrice: 0,
                }
            );

            const makeNumber = num => {
                if (!num) {
                    return '-';
                }

                return numeral(num).format('0,0');
            };

            const low = makeNumber(data.lowTotal / longTermRecords.length);
            const avg = makeNumber(data.totalPrice / data.totalQuantity);
            const high = makeNumber(data.highTotal / longTermRecords.length);

            return `${low} / ${avg} / ${high}`;
        },
    },
    GEM_GOLD_RATIO: {
        id: 'GEM_GOLD_RATIO',
        field: 'gemGoldRatio',
        headerName: 'Gems gold ratio',
        type: 'number',
        width: 150,
        valueGetter: params => params.row.gemGoldRatio,
        renderCell: params => (
            <CurrencyValue
                type="gems-per-gold"
                amount={params.row.gemGoldRatio}
                format="0,0.00"
            />
        ),
    },
    GEM_GOLD_RATIO_AVERAGE: {
        id: 'GEM_GOLD_RATIO_AVERAGE',
        headerName: 'Gems gold ratio 30d avg',
        type: 'number',
        width: 200,
        renderCell: params => {
            const longTermRecords = params.row.history?.longTermRecords;

            // eslint-disable-next-line
            const value = useMemo(() => {
                if (!longTermRecords?.length) {
                    return 0;
                }

                const data = longTermRecords.reduce(
                    (data, { gemTotalPrice, goldTotalPrice }) => {
                        if (gemTotalPrice) {
                            data.totalGemPrice += gemTotalPrice;
                        }

                        if (goldTotalPrice) {
                            data.totalGoldPrice += goldTotalPrice;
                        }

                        return data;
                    },
                    {
                        totalGoldPrice: 0,
                        totalGemPrice: 0,
                    }
                );

                const weightedGemAverage = data.totalGemPrice * 1_000_000;
                return weightedGemAverage / data.totalGoldPrice;
            }, [longTermRecords]);

            return <CurrencyValue type="gems-per-gold" amount={value} format="0,0.00" />;
        },
    },
    KINGS_SALE_PROFIT: {
        id: 'KINGS_SALE_PROFIT',
        field: 'kingsSaleProfit',
        headerName: 'Kings sale profit',
        type: 'number',
        width: 150,
        renderCell: params => (
            <CurrencyValue type="gold" amount={params.row.kingsSaleProfit} />
        ),
        valueGetter: params => params.row.kingsSaleProfit ?? 0,
    },
    SURCHARGE_PROFIT: {
        id: 'SURCHARGE_PROFIT',
        field: 'surchargeProfit',
        headerName: 'Surcharge profit',
        type: 'number',
        width: 150,
        renderCell: params => (
            <CurrencyValue type="gold" amount={params.row.surchargeProfit} />
        ),
        valueGetter: params => params.row.surchargeProfit ?? 0,
    },
    SURCHARGE_PROFIT_PER_ENERGY: {
        id: 'SURCHARGE_PROFIT_PER_ENERGY',
        field: 'surchargeProfitPerEnergy',
        headerName: 'Surcharge profit per energy',
        type: 'number',
        width: 150,
        renderCell: params => (
            <CurrencyValue
                type="gold-per-energy"
                amount={params.row.surchargeProfitPerEnergy ?? 0}
            />
        ),
        valueGetter: params => params.row.surchargeProfitPerEnergy ?? 0,
    },
    SURCHARGE: {
        id: 'SURCHARGE',
        field: 'surcharge',
        headerName: 'Surcharge cost',
        type: 'number',
        width: 150,
        renderCell: params => <CurrencyValue type="energy" amount={params.row.surcharge} />,
        valueGetter: params => params.row.surcharge ?? 0,
    },
    SURCHARGE_VALUE: {
        id: 'SURCHARGE_VALUE',
        field: 'surchargeValue',
        headerName: 'Surcharge value',
        type: 'number',
        width: 150,
        renderCell: params => (
            <CurrencyValue type="gold" amount={params.row.surchargeValue} />
        ),
        valueGetter: params => params.row.surchargeValue ?? 0,
    },
    DISCOUNT_COST_PER_ENERGY: {
        id: 'DISCOUNT_COST_PER_ENERGY',
        field: 'discountCostPerEnergy',
        headerName: 'Cost per energy',
        type: 'number',
        width: 150,
        renderCell: params => {
            const value = params.row.discountCostPerEnergy
                ? Math.round(params.row.discountCostPerEnergy)
                : 0;

            return <CurrencyValue type="gold-per-energy" amount={value} />;
        },
        valueGetter: params => params.row.discountCostPerEnergy ?? 0,
    },
    DISCOUNT: {
        id: 'DISCOUNT',
        field: 'discount',
        headerName: 'Discount energy',
        type: 'number',
        width: 150,
        renderCell: params => <CurrencyValue type="energy" amount={params.row.discount} />,
        valueGetter: params => params.row.discount,
    },
    DISCOUNT_COST: {
        id: 'DISCOUNT_COST',
        field: 'discountCost',
        headerName: 'Discount cost',
        type: 'number',
        width: 150,
        renderCell: params => (
            <CurrencyValue type="gold" amount={params.row.discountCost} />
        ),
        valueGetter: params => params.row.discountCost,
    },
    VALUE: {
        id: 'VALUE',
        field: 'value',
        headerName: 'Base value',
        type: 'number',
        width: 150,
        renderCell: params => <CurrencyValue type="gold" amount={params.row.value} />,
        valueGetter: params => params.row.value,
    },
    ELEMENT_AFFINITY: {
        id: 'ELEMENT_AFFINITY',
        field: 'elementAffinity',
        headerName: 'Element affinity',
        width: 150,
        renderCell: params =>
            params.row.elementAffinity ? (
                <Enchant
                    type={params.row.elementAffinity}
                    sx={{
                        width: '100%',
                        height: '100%',

                        '& img': {
                            maxWidth: '100%',
                            maxHeight: '100%',
                        },
                    }}
                />
            ) : null,
        valueGetter: params => params.row.elementAffinity,
    },
    // SPIRIT_AFFINITY: {
    //     id: 'SPIRIT_AFFINITY',
    //     field: 'spiritAffinity',
    //     headerName: 'Spirit affinity',
    //     width: 150,
    //     renderCell: params =>
    //         params.row.spiritAffinity ? (
    //             <Enchant
    //                 type={params.row.spiritAffinity}
    //                 sx={{
    //                     width: '100%',
    //                     height: '100%',

    //                     '& img': {
    //                         maxWidth: '100%',
    //                         maxHeight: '100%',
    //                     },
    //                 }}
    //             />
    //         ) : null,
    //     valueGetter: params => params.row.spiritAffinity,
    // },
};
