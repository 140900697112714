module.exports = {
    COMPONENT: 'm',
    SWORD: 'ws',
    AXE: 'wa',
    DAGGER: 'wd',
    MACE: 'wm',
    POLEARM: 'wp',
    BOW: 'wb',
    STAFF: 'wt',
    WAND: 'ww',
    CROSSBOW: 'wc',
    GUN: 'wg',
    HERB: 'uh',
    POTION: 'up',
    SCROLL: 'us',
    ARMOR_HEAVY: 'ah',
    ARMOR_MEDIUM: 'am',
    ARMOR_LIGHT: 'al',
    HELMET_HEAVY: 'hh',
    HELMET_MEDIUM: 'hm',
    HELMET_LIGHT: 'hl',
    GLOVES_HEAVY: 'gh',
    // GLOVES_MEDIUM: 'gm', // Not a thing
    GLOVES_LIGHT: 'gl',
    BOOTS_HEAVY: 'bh',
    // BOOTS_MEDIUM: 'bm', // Not a thing
    BOOTS_LIGHT: 'bl',
    SHIELD: 'xs',
    CLOAK: 'xc',
    RING: 'xr',
    AMULET: 'xa',
    FAMILIAR: 'xf',
    RUNESTONE: 'xu',
    MOONSTONE: 'xm',
    ENCHANT: 'z',
};
