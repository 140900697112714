import axios from 'axios';

export const searchMarket = async payload => {
    const url = 'api/search';

    try {
        const { data } = await axios.post(url, payload);
        return { data };
    } catch (error) {
        return { error };
    }
};
