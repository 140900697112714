import React from 'react';
import { Affinities } from '@app/constants';
import { capitalize } from '@app/utils';
import { AppTypes, useAppSelector, useAppDispatch } from 'Contexts/app';
import { MultiSelect } from 'Components/inputs';
import { FilterWrapper } from '../filterWrapper';

const elementAffinitiesListOptions = Affinities.ELEMENTS.map(element => ({
    name: capitalize(element),
    value: element,
}));

const spiritAffinitiesListOptions = Affinities.SPIRITS.map(spirit => ({
    name: capitalize(spirit),
    value: spirit,
}));

export const EnchantFilter = () => {
    const elementAffinities = useAppSelector(state => state.elementAffinities);
    const spiritAffinities = useAppSelector(state => state.spiritAffinities);
    const dispatch = useAppDispatch();

    const handleElementAffinitiesChange = (_, rawElementAffinities) => {
        const elementAffinities = rawElementAffinities.map(
            affinity => affinity?.value ?? affinity
        );
        dispatch({
            type: AppTypes.SET_ELEMENT_AFFINITIES,
            payload: { elementAffinities },
        });
    };

    const handleSpiritAffinitiesChange = (_, rawSpiritAffinities) => {
        const spiritAffinities = rawSpiritAffinities.map(
            affinity => affinity?.value ?? affinity
        );
        dispatch({
            type: AppTypes.SET_SPIRIT_AFFINITIES,
            payload: { spiritAffinities },
        });
    };

    return (
        <FilterWrapper id="enchant" title="Enchants">
            <MultiSelect
                id="element-affinities-select"
                name="element-affinities-select"
                label="Element affinities"
                value={elementAffinities ?? []}
                onChange={handleElementAffinitiesChange}
                options={elementAffinitiesListOptions}
                sx={{ minWidth: 240 }}
                getOptionSelected={(option, value) => option.value === value}
                size="small"
            />

            {/* <MultiSelect
                id="spirit-affinities-select"
                name="spirit-affinities-select"
                label="Spirit affinities"
                value={spiritAffinities ?? []}
                onChange={handleSpiritAffinitiesChange}
                options={spiritAffinitiesListOptions}
                sx={{ minWidth: 240 }}
                getOptionSelected={(option, value) => option.value === value}
                size="small"
            /> */}
        </FilterWrapper>
    );
};
